<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">
    <h2>{{'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE' | translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!-- transmissionMethod -->
<!--    <app-odc-field *ngIf="!isSourceCaisse()"
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE' | translate"
        [choices]="[{label: 'TRANSMISSION_METHOD_ESIGN' | translate, value: getEsignChoiceValue()}, {label: 'TRANSMISSION_METHOD_MAIL' | translate, value: constants.TRANSMISSION_MAIL}]"
        [id]="'transmissionMethod'"
        [name]="'transmissionMethod'"
        [path]="'transmissionMethod'"
        [binding]="'transmissionMethod'"
    ></app-odc-field>-->
    <app-odc-field *ngIf="!isSourceCaisse()"
                   [type]="constants.FIELD_TYPES.RADIO"
                   [form]="form"
                   [submitted]="isFormSubmitted()"
                   [label]="'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE' | translate"
                   [choices]="[{label: 'TRANSMISSION_METHOD_ESIGN' | translate, value: getEsignChoiceValue()}]"
                   [id]="'transmissionMethod'"
                   [name]="'transmissionMethod'"
                   [path]="'transmissionMethod'"
                   [binding]="'transmissionMethod'"
    ></app-odc-field>

    <!-- Caisse choice -->
    <fieldset *ngIf="isSourceCaisse()" class="form-group">
        <div class="row equal">
            <div class="col-md-12 stretch">
                <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
                    <div class="panel-body">
                        <div class="panel-content" id="panel-radio-content1">
                            <label class="panel-label" for="panel-radio-horizontal1">{{ 'TRANSMISSION_METHOD_ESIGN' | translate }}</label>
                            <p>{{ 'TRANSMISSION_METHOD_ESIGN_TITLE' | translate }}</p>
                            <ul>
                                <li>{{ 'TRANSMISSION_METHOD_ESIGN_LINE1' | translate }}</li>
                                <li>{{ 'TRANSMISSION_METHOD_ESIGN_LINE2' | translate }}</li>
                                <li [innerHTML]="'TRANSMISSION_METHOD_ESIGN_LINE3' | translate "></li>
                            </ul>
                        </div>
                        <input class="panel-input" type="radio" [name]="'transmissionMethod'" [value]="getEsignChoiceValue()" (click)="onlineSelected()" id="panel-radio-horizontal1" aria-describedby="panel-radio-content1"
                               [attr.checked]="isEsignTransmission()? true: null">
                        <div class="panel-check">
                            <div class="c-input c-radio">
                                <span class="c-indicator"></span>
                            </div>
                        </div>
                        <div class="panel-active"></div>
                        <div class="panel-focus"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 stretch">
                <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
                    <div class="panel-body">
                        <div class="panel-content" id="panel-radio-content2">
                            <label class="panel-label" for="panel-radio-horizontal2">{{ 'TRANSMISSION_METHOD_MAIL_CAISSE' | translate }}</label>
                            <p>{{ 'TRANSMISSION_METHOD_MAIL_TITLE' | translate }}</p>
                            <ul>
                                <li>{{ 'TRANSMISSION_METHOD_MAIL_LINE1' | translate }}</li>
                                <li [innerHTML]="'TRANSMISSION_METHOD_MAIL_LINE2' | translate "></li>
                            </ul>
                        </div>
                        <input class="panel-input" type="radio" [name]="'transmissionMethod'" [value]="constants.TRANSMISSION_MAIL" (click)="mailSelected()" id="panel-radio-horizontal2" aria-describedby="panel-radio-content2"
                               [attr.checked]="isMailTransmission()? true: null">
                        <div class="panel-check">
                            <div class="c-input c-radio">
                                <span class="c-indicator"></span>
                            </div>
                        </div>
                        <div class="panel-active"></div>
                        <div class="panel-focus"></div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <!-- End of Caisse choice -->

    <div class="btn-resp-col btn-resp-demi m-t-4">
        <button type="submit"
                class="btn btn-primary loadable"
                [class.loading]="false"
                [attr.disabled]="null">
            {{'BTN_NEXT' | translate}}
        </button>
    </div>


</form>
