<div *ngIf="getErrors().length > 0" class="has-error">
    <div id="boite-erreurs" class="error-group" tabindex="0">
        <span class="help-block">{{ 'ERROR_SUMMARY_INTRO' | translate }}</span>
        <ul>
            <li *ngFor="let item of getErrors()">
                <a href="#"
                   (click)="focusToField(item.name)">{{ 'ERROR_FIELD_' + item.label | translate | prefixerror : item.label:item.index:context}}</a>
            </li>
        </ul>
    </div>
</div>
