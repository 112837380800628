import {Component} from '@angular/core';
import {ReviewClientIdentityComponent} from './review-client-identity.component';

@Component({
    selector: 'app-review-client-identity-second-holder',
    templateUrl: './review-client-identity.component.html'
})
export class ReviewClientIdentitySecondHolderComponent extends ReviewClientIdentityComponent {

    public requesterIndex = 1;

}
