import {AccountTransferSecurity} from './accountTransferSecurity';
import {AttachedDocument} from './attachedDocument';
import {Functions} from '../utils/functions';
import {VmdConstants} from '../constants/vmd-constants';

export class AccountTransferRequest {

    actType: string;
    actTypeCurrency: string;
    bankName: string;
    bankResourceFirstName: string;
    bankResourceLastName: string;
    bankPhone: number;

    addressCity: string;
    addressPostalCode: string;
    addressCountry: string = VmdConstants.COUNTRIES.CANADA;
    addressProv: string;
    addressStreet: string;
    addressUnit: string;

    accountNumber: number;
    accountNumberSec: number;
    accountType: string;
    accountTypeSec: string;
    accountCurrency: string;
    accountCurrencySec: string;

    transferType: string;
    transferInstructionType: string;

    transferCash: string;

    securities: AccountTransferSecurity[];
    accountStatement: AttachedDocument;

    constructor(obj?: any) {

        this.actType = obj && obj.actType || null;
        this.actTypeCurrency = obj && obj.actTypeCurrency || null;
        this.bankName = obj && obj.bankName || null;
        this.bankResourceFirstName = obj && obj.bankResourceFirstName || null;
        this.bankResourceLastName = obj && obj.bankResourceLastName || null;
        this.bankPhone = obj && obj.bankPhone || null;

        this.addressCity = obj ? Functions.initByLength(obj.addressCity, 32) : null;
        this.addressPostalCode = obj && obj.addressPostalCode || null;
        this.addressCountry = obj ? Functions.initCountryAddress(obj.addressCountry) : VmdConstants.COUNTRIES.CANADA;
        this.addressProv = obj && obj.addressProv || null;
        this.addressStreet = obj && obj.addressStreet || null;
        this.addressUnit = obj ? Functions.initByLength(obj.addressUnit, 8) : null;

        this.accountNumber = obj && obj.accountNumber || null;
        this.accountNumberSec = obj && obj.accountNumberSec || null;
        this.accountType = obj && obj.accountType || null;
        this.accountTypeSec = obj && obj.accountTypeSec || null;
        this.accountCurrency = obj && obj.accountCurrency || null;
        this.accountCurrencySec = obj && obj.accountCurrencySec || null;

        this.transferType = obj && obj.transferType || null;
        this.transferInstructionType = obj && obj.transferInstructionType || null;

        this.transferCash = obj && obj.transferCash || null;

        this.securities = [];
        if (obj && obj.securities) {

            for (const security of obj.securities) {

                this.securities.push(new AccountTransferSecurity(security));
            }
        }

        this.accountStatement = new AttachedDocument(obj && obj.accountStatement || null);
    }

}
