<div id="modal-create-profile" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label"
     aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <form [formGroup]="form" ngForm novalidate (submit)="submitForm()" role="form">
            <div class="modal-content" role="document" tabindex="0">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                        {{ 'ODC_MODAL_CONNEXION_TITLE' | translate }}
                    </h2>
                </div>
                <div class="modal-body">
                    <div *ngIf="displayCreateProfileFormGroup" formGroupName="authenticatePasswordGroup">

                        <div *ngIf="showNewEmailSentAlert"
                             class="alert alert-info">{{ 'ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_ALERT_DESC' | translate }}</div>

                        <app-http-error [errors]="httpErrors"></app-http-error>

                        <div class="form-horizontal">
                            <h3>{{'ODC_MODAL_CONNEXION_TEMP_PASSWORD_TITLE' | translate}}</h3>
                            <p [innerHTML]="'ODC_MODAL_CONNEXION_TEMP_PASSWORD_DESC' | translate: {email: email}"></p>
                            <br>
                            <div class="form-group">
                                <label for="password" class="col-md-6">
                                    <b>{{'PROFIL_OLD_PASSWORD' | translate}}</b>
                                </label>
                                <div class="col-md-18" [class.has-error]="isFieldInError('authenticatePasswordGroup.password')">
                                    <label *ngIf="isFieldInError('authenticatePasswordGroup.password')" for="password">
                                        <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                   [path]="'authenticatePasswordGroup.password'"></app-error>
                                    </label>
                                    <input id="password" class="form-control"
                                           name="password" type="password"
                                           formControlName="password" maxlength="64">
                                </div>
                            </div>

                        </div>
                        <app-recaptcha *ngIf="renderRecaptcha" [containerId]="'recaptcha-create-profile'"
                                       [langChange]="translate.onLangChange" [lang]="translate.currentLang"></app-recaptcha>
                        <br>

                        <p>{{'ODC_MODAL_CONNEXION_TEMP_PASSWORD_CHECK_SPAM_DESC' | translate}}</p>

                        <a href="javascript:void(0)" class="lien-action"
                           [class.disabled]="loading ? true : null"
                           (click)="resetTempPassword()">{{'ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_LINK' | translate }}</a>

                    </div>
                    <ng-container *ngIf="displayDefinePasswordGroup">

                        <!--authenticated with temp password & set new password-->
                        <div formGroupName="definePasswordGroup">

                            <h3>{{'ODC_MODAL_CREATE_PROFILE_NEW_PASSWORD_TITLE' | translate}}</h3>

                            <div [class.has-error]="httpErrors != null">
                                <label *ngIf="httpErrors != null" for="newPassword">
                                    <span class="help-block" tabindex="0">
                                        <app-http-error [errors]="httpErrors" [isGenericBlocError]="false"></app-http-error>
                                    </span>
                                </label>
                            </div>

                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="newPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_NEW_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('definePasswordGroup.newPassword') || isFieldInError('passwordStrength')">
                                        <label
                                            *ngIf="isFieldInError('definePasswordGroup.newPassword') || isFieldInError('passwordStrength')"
                                            for="newPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'definePasswordGroup.newPassword'"></app-error>
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'passwordStrength'"></app-error>
                                        </label>
                                        <input id="newPassword" class="form-control" name="newPassword" type="password"
                                               formControlName="newPassword" maxlength="32">
                                        <app-password-strength
                                            [password]="this.form.get('definePasswordGroup.newPassword').value"
                                            (strengthChanges)="onPasswordStrengthChanges($event)"
                                        ></app-password-strength>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="confirmNewPassword" class="col-md-6 control-label">
                                        <b>{{'PROFIL_CONFIRM_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18"
                                         [class.has-error]="isFieldInError('definePasswordGroup.confirmNewPassword')">
                                        <label
                                            *ngIf="isFieldInError('definePasswordGroup.confirmNewPassword')"
                                            for="confirmNewPassword">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'definePasswordGroup.confirmNewPassword'"></app-error>
                                        </label>
                                        <input id="confirmNewPassword" class="form-control" name="confirmNewPassword"
                                               type="password"
                                               formControlName="confirmNewPassword" maxlength="32">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>

                <div class="modal-footer">
                    <div class="btn-resp btn-resp-1colonne-inline">
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{ (displayDefinePasswordGroup ?
                                    'ODC_MODAL_DEFINE_NEW_PASSWORD_CONFIRMATION_BUTTON_LABEL' :
                                    'BTN_CONTINUE') | translate }}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null">
                                {{ (displayDefinePasswordGroup ?
                                'ODC_MODAL_DEFINE_NEW_PASSWORD_CONFIRMATION_BUTTON_LABEL' :
                                'BTN_CONTINUE') | translate }}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}" type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
            </div>
        </form>
    </div>
</div>
