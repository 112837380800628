import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HtmlSelectKV} from '../models/html-select-k-v';

@Injectable()
export class TranslateSortService {

    constructor(private translateService: TranslateService) {
    }

    getTranslatedList(list: { key: string, label: string }[]): { key: string, label: string }[] {
        const newList: { key: string, label: string }[] = [];

        for (const item of list) {
            this.translateService.get(item.key).subscribe(
                translation => {
                    newList.push({key: translation, label: item.label});
                });
        }

        newList.sort((a, b) => {
            return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        });
        return newList;
    }

    getTranslatedKVList(list: HtmlSelectKV[]): HtmlSelectKV[] {
        const newList: HtmlSelectKV[] = [];

        for (const item of list) {
            this.translateService.get(item.itemLabel).subscribe(
                translation => {
                    newList.push({itemValue: item.itemValue, itemLabel: translation});
                });
        }
        return newList;
    }
}
