<form [formGroup]="form" novalidate ngForm (submit)="submitForm()">

    <div class="form-group" [class.has-error]="isFormSubmitted() && form.get('managementType').hasError('required')">

        <app-error [form]="form" [ngFormRef]="ngForm" [path]="'investmentAdvice'"></app-error>

        <div *ngIf="isFormSubmitted() && form.get('managementType').hasError('required')">
            <span class="help-block">{{'GLOBAL_ERROR_CHOOSE_AN_OPTION'|translate}}</span>
        </div>

        <ng-container *ngIf="!isGpdOrSfd()">
            <h2>{{'INVESTMENT_ADVICE' | translate}}</h2>

            <div class="m-b-3">
                <label class="c-input c-radio">
                    <input type="radio"
                           id="investmentAdviceComissionBased"
                           name="managementType"
                           formControlName="managementType"
                           value={{managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES}}
                           [attr.value]="managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES"
                    >
                    <span class="c-indicator"></span>
                    {{ 'INVESTMENT_ADVICE_COMMISSION_BASED' | translate }}
                </label>
                <app-help [templateRefFr]="help1" [templateRefEn]="help1"></app-help>
            </div>

            <app-odc-field
                [type]="constants.FIELD_TYPES.CHECKBOX"
                [form]="form"
                [className]="'m-l-4'"
                [submitted]="isFormSubmitted()"
                [label]="'INVESTMENT_ADVICE_FEE_BASED' | translate"
                [choices]="[
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_BASIC' | translate,
                        id: 'investmentAdviceEliteBasicCommissionBased',
                        helpRefFr: this.help2, helpRefEn: this.help2,
                        binding: 'investmentAdviceEliteBasic',
                        hidden: isSsd()
                    },
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_INTERMEDIATE' | translate,
                        id: 'investmentAdviceEliteIntermediateCommissionBased',
                        helpRefFr: this.help3, helpRefEn: this.help3,
                        binding: 'investmentAdviceEliteIntermediate',
                        hidden: isSsd()
                    },
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_FLEXIBLE' | translate,
                        id: 'investmentAdviceEliteFlexibleCommissionBased',
                        helpRefFr: this.help4, helpRefEn: this.help4,
                        binding: 'investmentAdviceEliteFlexible'
                    }
                ]"
                [stacked]="true"
            ></app-odc-field>
        </ng-container>

        <h2>{{'PORTFOLIO_MANAGEMENT' | translate}}</h2>

        <div class="m-b-3">
            <label class="c-input c-radio">
                <input type="radio"
                       id="portfolioManagementAdvisorProgram"
                       name="managementType"
                       formControlName="managementType"
                       value={{managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM}}
                       [attr.value]="managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM"
                >
                <span class="c-indicator"></span>
                <ng-container *ngIf="isGpdOrSfd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_GPD' | translate }}</ng-container>
                <ng-container *ngIf="isSsd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_SSD' | translate }}</ng-container>
                <ng-container *ngIf="!isSsd() && !isGpdOrSfd()">{{'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM' | translate }}</ng-container>
            </label>
            <app-help [templateRefFr]="help5" [templateRefEn]="help5"></app-help>
        </div>

        <app-odc-field
            *ngIf="!isGpdOrSfd()"
            [type]="constants.FIELD_TYPES.CHECKBOX"
            [form]="form"
            [className]="'m-l-4'"
            [submitted]="isFormSubmitted()"
            [label]="'INVESTMENT_ADVICE_FEE_BASED' | translate"
            [choices]="[
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_BASIC' | translate,
                        id: 'investmentAdviceEliteBasicPMAP',
                        helpRefFr: this.help8, helpRefEn: this.help8,
                        binding: 'investmentAdviceEliteBasic',
                        hidden: isSsd()
                    },
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_INTERMEDIATE' | translate,
                        id: 'investmentAdviceEliteIntermediatePMAP',
                        helpRefFr: this.help9, helpRefEn: this.help9,
                        binding: 'investmentAdviceEliteIntermediate',
                        hidden: isSsd()
                    },
                    {
                        label: 'INVESTMENT_ADVICE_ELITE_FLEXIBLE' | translate,
                        id: 'investmentAdviceEliteFlexiblePMAP',
                        helpRefFr: this.help10, helpRefEn: this.help10,
                        binding: 'investmentAdviceEliteFlexible'
                    }
                ]"
            [stacked]="true"
        ></app-odc-field>

        <app-odc-field
            *ngIf="!isGpdOrSfd()"
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [name]="'managementType'"
            [controlName]="'managementType'"
            [submitted]="isFormSubmitted()"
            [label]="isSsd() ? '' : ('PORTFOLIO_MANAGEMENT_DISCRETIONARY_SERVICE' | translate)"
            [choices]="[
                {
                    id: 'portfolioManagementMandateOnly',
                    label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : 'PORTFOLIO_MANAGEMENT_MANDATE_ONLY') | translate,
                    helpRefFr: this.help6, helpRefEn: this.help6,
                    hidden: isGpdOrSfd(),
                    value: managementTypeEnum.MANDATE_ONLY
                }
            ]"
            [stacked]="true"
        ></app-odc-field>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [hideBackBtn]="hideBackBtn()"></app-questionnaire-btn>

</form>

<ng-template #help1>
    <h2>{{ 'INVESTMENT_ADVICE_COMMISSION_BASED' | translate }}</h2>
    <p>{{(isSsd() ? 'INVESTMENT_ADVICE_COMMISSION_BASED_FORM_SSD' : 'INVESTMENT_ADVICE_COMMISSION_BASED_FORM')| translate}}</p>
</ng-template>
<ng-template #help2>
    <h2>{{ 'INVESTMENT_ADVICE_ELITE_BASIC' | translate }}</h2>
    <p>{{'INVESTMENT_ADVICE_ELITE_BASIC_FORM' | translate}}</p>
</ng-template>
<ng-template #help3>
    <h2>{{ 'INVESTMENT_ADVICE_ELITE_INTERMEDIATE' | translate }}</h2>
    <p>{{'INVESTMENT_ADVICE_ELITE_INTERMEDIATE_FORM' | translate}}</p>
</ng-template>
<ng-template #help4>
    <h2>{{ 'INVESTMENT_ADVICE_ELITE_FLEXIBLE' | translate }}</h2>
    <p>{{(isSsd() ? 'INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM_SSD' : 'INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM') | translate}}</p>
</ng-template>
<ng-template #help5>
    <h2 *ngIf="isGpdOrSfd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_GPD' | translate }}</h2>
    <h2 *ngIf="isSsd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_SSD' | translate }}</h2>
    <h2 *ngIf="!isGpdOrSfd() && !isSsd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM' | translate }}</h2>

    <p *ngIf="isGpdOrSfd() && !isEntity()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD' | translate}}</p>
    <p *ngIf="isGpdOrSfd() && isEntity()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_ENTITY' | translate}}</p>
    <p *ngIf="isSsd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_SSD' | translate}}</p>
    <p *ngIf="!isGpdOrSfd() && !isSsd()">{{ 'PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM' | translate}}</p>
</ng-template>
<ng-template #help6>
    <h2>{{ (isSsd() ? 'SECURITIES_PORTFOLIOS' : 'PORTFOLIO_MANAGEMENT_MANDATE_ONLY') | translate }}</h2>
    <p>{{ (isSsd() ? 'SECURITIES_PORTFOLIOS_FORM' : 'PORTFOLIO_MANAGEMENT_MANDATE_ONLY_FORM') | translate }}</p>
</ng-template>
<ng-template #help8>
    <h2>{{'INVESTMENT_ADVICE_ELITE_BASIC' | translate}}</h2>
    <p>{{'INVESTMENT_ADVICE_ELITE_BASIC_PCG_FORM' | translate}}</p>
</ng-template>
<ng-template #help9>
    <h2>{{ 'INVESTMENT_ADVICE_ELITE_INTERMEDIATE' | translate }}</h2>
    <p>{{'INVESTMENT_ADVICE_ELITE_INTERMEDIATE_PCG_FORM' | translate}}</p>
</ng-template>
<ng-template #help10>
    <h2>{{'INVESTMENT_ADVICE_ELITE_FLEXIBLE' | translate}}</h2>
    <p>{{(isSsd() ? 'INVESTMENT_ADVICE_ELITE_FLEXIBLE_GDF_FORM' : 'INVESTMENT_ADVICE_ELITE_FLEXIBLE_PCG_FORM') | translate}}</p>
</ng-template>
