import {AbstractControl, FormArray, FormGroup, NgForm} from '@angular/forms';

export class VmdForm {

    static getLastPath(path: string): string {
        if (null == path) {
            return null;
        }

        const paths: string[] = path.split('.');
        return paths[paths.length - 1];
    }

    static isFieldInError(control: AbstractControl, submitted: boolean, keysOnSubmitOnly: string[] = []): boolean {
        return control && control.touched && control.invalid
            && ((control.hasError('required') && submitted) || !control.hasError('required'))
            && this.showErrorsBySubmitOnly(control, submitted, keysOnSubmitOnly);
    }

    static markAsTouched(control: FormGroup): void {
        if (control) {
            Object.keys(control.controls).forEach(path => {
                if (control.get(path) instanceof FormGroup || control.get(path) instanceof FormArray) {
                    this.markAsTouched(control.get(path) as FormGroup);
                } else {
                    control.get(path).markAsTouched();
                }
            });
        }
    }

    private static showErrorsBySubmitOnly(control: AbstractControl, submitted: boolean, keysOnSubmitOnly: string[]) {

        let show = true;

        if (control != null && control.errors != null) {
            Object.keys(control.errors).forEach(key => {
                if (control.hasError(key)) {

                    if (keysOnSubmitOnly != null && -1 !== keysOnSubmitOnly.indexOf(key)) {
                        show = show && submitted;
                    }

                }
            });
        }

        return show;
    }
}
