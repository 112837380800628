import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {Functions} from '../../components/utils/functions';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {ModalService} from '../../components/services/modal.service';

@Component({
    selector: 'app-occupation',
    templateUrl: './occupation.component.html',
    providers: [TranslatePipe]
})
export class OccupationComponent extends BaseNavComponent implements OnInit {

    jobStatusList: HtmlSelectKV[];
    linkList: HtmlSelectKV[];
    isBrokerList: HtmlSelectKV[];
    employerActivityList: HtmlSelectKV[];
    userEmployerNameOss: string;
    userEmployerIsBrokerOSS: string;
    userJobTitleOss: string;
    showPopUpEmployerIsBroker: boolean;
    isBrokerVMDSession: boolean;


    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private cd: ChangeDetectorRef,
                private listModelService: ListModelService,
                private translatePipe: TranslatePipe,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.translate.onLangChange.subscribe(() => {
            if (this.userIsSelfEmployed() || this.userEmployerIsBrokerVmd()) {
                this.setEmployerNameInit();
                this.setJobTitleInit();
            }
            this.setUserSameAddressWithBrokerEmployeeFirmNameInit(this.userSameAddressWithBrokerEmployeeVmd());
        });

        this.form = this.fb.group({
            userJobStatus: [this.formService.getForm().requesters[this.requesterIndex].userJobStatus, [this.requiredValidator('userJobStatus')]],

            employer: this.fb.group({
                userEmployerIsBroker: [this.formService.getForm().requesters[this.requesterIndex].userEmployerIsBroker, [this.requiredValidator('userEmployerIsBroker')]],

                userIsInvestmentAdvisorIndicator: [this.formService.getForm().requesters[this.requesterIndex].userIsInvestmentAdvisorIndicator, [this.requiredValidator('userIsInvestmentAdvisorIndicator')]],

                userEmployerName: [this.formService.getForm().requesters[this.requesterIndex].userEmployerName, [this.requiredValidator('userEmployerName')]],
                userJobTitle: [this.formService.getForm().requesters[this.requesterIndex].userJobTitle, [this.requiredValidator('userJobTitle')]],
                userEmployerActivity: [this.formService.getForm().requesters[this.requesterIndex].userEmployerActivity, [this.requiredValidator('userEmployerActivity')]]
            }),

            userSameAddressWithBrokerEmployeeIndicator: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeIndicator, [this.requiredValidator('userSameAddressWithBrokerEmployeeIndicator')]],

            userSameAddressWithBrokerEmployee: this.fb.group({
                userSameAddressWithBrokerEmployeeVmdIndicator: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeVmdIndicator, [this.requiredValidator('userSameAddressWithBrokerEmployeeVmdIndicator')]],
                userSameAddressWithBrokerEmployeeFirmName: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeFirmName, [this.requiredValidator('userSameAddressWithBrokerEmployeeFirmName')]],
                userSameAddressWithBrokerEmployeeFirstName: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeFirstName, [this.requiredValidator('userSameAddressWithBrokerEmployeeFirstName')]],
                userSameAddressWithBrokerEmployeeLastName: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeLastName, [this.requiredValidator('userSameAddressWithBrokerEmployeeLastName')]],
                userSameAddressWithBrokerEmployeeLink: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeLink, [this.requiredValidator('userSameAddressWithBrokerEmployeeLink')]],
                userSameAddressWithBrokerEmployeeLinkList: [this.formService.getForm().requesters[this.requesterIndex].userSameAddressWithBrokerEmployeeLinkList, [this.requiredValidator('userSameAddressWithBrokerEmployeeLinkList')]]
            }),

            percentageOwner: [this.formService.getForm().requesters[this.requesterIndex].percentageOwner, [this.requiredValidator('percentageOwner'), Validators.min(1), Validators.max(100)]]
        });

        this.cd.detectChanges();

        this.listModelService.getListModel('statutEmploiList', (data: any) => this.jobStatusList = data);
        this.listModelService.getListModel('userSameAddressWithBrokerEmployeeVmdLinkList', (data: any) => this.linkList = data);
        this.listModelService.getListModel('userEmployerIsBrokerList', (data: any) => this.isBrokerList = data);
        this.listModelService.getListModel('userEmployerActivityList', (data: any) => this.employerActivityList = data);

        this.form.get('userJobStatus').valueChanges.subscribe(() => {
            this.updateControlEnabled(this.form.get('employer'), this.userIsPaidEmployeeOrSelfEmployed());

            if (this.form.get('employer').disabled) {
                this.form.get('employer.userEmployerIsBroker').reset();
                this.formService.setFormProperty(this.getRequesterPath('userEmployerIsBroker'), this.form.get('employer.userEmployerIsBroker').value);
            }
            this.updateControlEnabled(this.form.get('employer.userEmployerIsBroker'), this.form.get('employer').enabled);

            if (!this.isUserEmployerBroker()) {
                this.form.get('employer.userEmployerActivity').reset();
            }

            this.setEmployerName();
            this.setJobTitle();
        });
        this.updateControlEnabled(this.form.get('employer'), this.userIsPaidEmployeeOrSelfEmployed());
        this.updateControlEnabled(this.form.get('employer.userIsInvestmentAdvisorIndicator'), this.userEmployerIsBrokerVmd());
        this.updateControlEnabled(this.form.get('employer.userEmployerIsBroker'), this.form.get('employer').enabled);


        this.form.get('employer.userEmployerIsBroker').valueChanges.subscribe(value => {
            this.hideSubTabFinancialInfos(value);

            if (!this.userEmployerIsBrokerVmd()) {
                this.form.get('employer.userIsInvestmentAdvisorIndicator').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('userIsInvestmentAdvisorIndicator'), null);
            }

            this.setEmployerName();
            this.setJobTitle();
            this.setSectorActivity();
            this.setBrokerVmd();
            this.showPopUpBrokerChangeUpdateRequest();
        });
        this.setBrokerVmd();
        this.showPopUpBrokerChangeUpdateRequest();

        this.form.get('employer.userIsInvestmentAdvisorIndicator').valueChanges.subscribe(() => {
            this.setJobTitle();
        });

        this.form.get('userSameAddressWithBrokerEmployee.userSameAddressWithBrokerEmployeeVmdIndicator').valueChanges.subscribe(value => {
            this.setUserSameAddressWithBrokerEmployeeFirmName(value);
        });

        this.setEmployerNameEnabled();
        this.setJobTitleEnabled();
        this.setSectorActivityEnabled();
        this.setUserSameAddressWithBrokerEmployeeFirmNameEnabled();
        this.setJobTitleInit();
        this.setEmployerNameInit();
        this.setPercentageOwner();

        this.setTempEmployerName();
        this.setTempJobTitle();

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            if (context === 'USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE') {
                this.form.get('userSameAddressWithBrokerEmployeeIndicator').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('userSameAddressWithBrokerEmployeeIndicator'), null);
            }
        }));

        this.form.get('userSameAddressWithBrokerEmployeeIndicator').valueChanges.subscribe(value => {
            const userSameAddressWithBrokerEmployee = this.form.get('userSameAddressWithBrokerEmployee');
            this.updateControlEnabled(userSameAddressWithBrokerEmployee, value);
            this.setUserSameAddressWithBrokerEmployeeFirmName(userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeVmdIndicator').value);
            this.formService.setFormProperty('userSameAddressWithBrokerEmployeeFirmName', userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeFirmName').value);
            this.formService.setFormProperty('userSameAddressWithBrokerEmployeeFirstName', userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeFirstName').value);
            this.formService.setFormProperty('userSameAddressWithBrokerEmployeeLastName', userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeLastName').value);
            this.formService.setFormProperty('userSameAddressWithBrokerEmployeeLink', userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeLink').value);
            this.formService.setFormProperty('userSameAddressWithBrokerEmployeeLinkList', userSameAddressWithBrokerEmployee.get('userSameAddressWithBrokerEmployeeLinkList').value);
            if (value !== null
                && this.isUpdateAccount()
                && this.isPilotMemberOrModeUpdateAutomatedOpeningActive()) {
                this.modalService.openModalChangingSignificantField('USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE');
            }
        });
        this.updateControlEnabled(this.form.get('userSameAddressWithBrokerEmployee'), this.form.get('userSameAddressWithBrokerEmployeeIndicator').value);
    }

    showPopUpBrokerChangeUpdateRequest() {
        let isBrokerVmd = this.userEmployerIsBrokerVmd();

        let isNonBrokerToBroker: boolean = this.isBrokerVMDSession && !isBrokerVmd;

        let isBrokerToNonBroker: boolean = !this.isBrokerVMDSession && isBrokerVmd;

        this.showPopUpEmployerIsBroker = this.isUpdateAccount() && (isNonBrokerToBroker || isBrokerToNonBroker);
        this.formService.isSaveDisabled = this.showPopUpEmployerIsBroker;
    }

    forceSectorActivityToFinanceAndInsurance(): boolean {
        return this.userEmployerIsBrokerVmd()
            || this.isUserBrokerNotVMD();
    }

    getUserEmployerIsBrokerHelp(): string {
        if (this.isUserBrokerNotVMD()) {
            return this.translatePipe.transform('USER_EMPLOYER_AUTORIZATION');
        }
        return null;
    }

    setEmployerName(): void {
        let userEmployerName = this.isUpdateAccount() ? this.form.get('employer.userEmployerName').value : null;

        if (this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
            userEmployerName = this.getMessage('EMPLOYMENT_STATUS_SELF_EMPLOYED');
        } else if (this.userEmployerIsBrokerVmd()) {
            userEmployerName = this.getMessage('USER_EMPLOYER_VMD');
        }

        if (this.isUpdateAccount() && this.isUserBroker() && !this.isUserSelfEmployed()) {
            userEmployerName = this.userEmployerNameOss;
        }

        if (this.formService.getDdpRequester() && this.formService.getDdpRequester().userEmployerName) {
            userEmployerName = this.formService.getDdpRequester().userEmployerName;
        }

        this.form.get('employer').patchValue({
            userEmployerName
        });
        this.formService.setFormProperty(this.getRequesterPath('userEmployerName'), userEmployerName);

        this.setEmployerNameEnabled();
    }

    setEmployerNameInit(): void {
        let userEmployerName = null;
        if (this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
            userEmployerName = this.getMessage('EMPLOYMENT_STATUS_SELF_EMPLOYED');
        } else if (this.userEmployerIsBrokerVmd()) {
            userEmployerName = this.getMessage('USER_EMPLOYER_VMD');
        }

        if (userEmployerName !== null) {
            this.form.get('employer.userEmployerName').setValue(userEmployerName);
            this.formService.setFormProperty(this.getRequesterPath('userEmployerName'), userEmployerName);

            this.setEmployerNameEnabled();

        }
    }

    setJobTitle(): void {
        this.updateControlEnabled(this.form.get('employer.userIsInvestmentAdvisorIndicator'), this.userEmployerIsBrokerVmd());

        let userJobTitle = this.isUpdateAccount() ? this.form.get('employer.userJobTitle').value : null;

        if (this.form.get('employer.userIsInvestmentAdvisorIndicator').value) {
            userJobTitle = this.getMessage('USER_JOB_TITLE_INVESTMENT_ADVISOR');
        }

        if (this.formService.getDdpRequester() && this.formService.getDdpRequester().userJobTitle) {
            userJobTitle = this.formService.getDdpRequester().userJobTitle;
        }

        if (this.isUpdateAccount() && (this.isUserBroker() || this.isUserBrokerNotVMD())) {
            userJobTitle = this.userJobTitleOss;
        }

        this.form.get('employer.userJobTitle').setValue(userJobTitle);
        this.formService.setFormProperty(this.getRequesterPath('userJobTitle'), userJobTitle);

        this.setJobTitleEnabled();
    }

    setUserSameAddressWithBrokerEmployeeFirmName(isVMDEmployee: boolean): void {
        let userSameAddressWithBrokerEmployeeFirmName = null;

        if (isVMDEmployee) {
            userSameAddressWithBrokerEmployeeFirmName = this.getMessage('USER_EMPLOYER_VMD');
        }

        this.form.get('userSameAddressWithBrokerEmployee').patchValue({
            userSameAddressWithBrokerEmployeeFirmName
        });
        this.formService.setFormProperty(this.getRequesterPath('userSameAddressWithBrokerEmployeeFirmName'), userSameAddressWithBrokerEmployeeFirmName);

        this.setUserSameAddressWithBrokerEmployeeFirmNameEnabled();
    }

    setUserSameAddressWithBrokerEmployeeFirmNameInit(isVMDEmployee: boolean): void {
        let userSameAddressWithBrokerEmployeeFirmName = null;

        if (isVMDEmployee) {
            userSameAddressWithBrokerEmployeeFirmName = this.getMessage('USER_EMPLOYER_VMD');
        }

        if (userSameAddressWithBrokerEmployeeFirmName !== null) {
            this.form.get('userSameAddressWithBrokerEmployee').patchValue({
                userSameAddressWithBrokerEmployeeFirmName
            });
            this.formService.setFormProperty(this.getRequesterPath('userSameAddressWithBrokerEmployeeFirmName'), userSameAddressWithBrokerEmployeeFirmName);
        }
        this.setUserSameAddressWithBrokerEmployeeFirmNameEnabled();
    }

    setJobTitleInit(): void {
        this.updateControlEnabled(this.form.get('employer.userIsInvestmentAdvisorIndicator'), this.userEmployerIsBrokerVmd());

        let userJobTitle = null;
        if (this.form.get('employer.userIsInvestmentAdvisorIndicator').value) {
            userJobTitle = this.getMessage('USER_JOB_TITLE_INVESTMENT_ADVISOR');
        }

        if (userJobTitle !== null) {
            this.form.get('employer.userJobTitle').setValue(userJobTitle);
            this.formService.setFormProperty(this.getRequesterPath('userJobTitle'), userJobTitle);
        }


        this.setJobTitleEnabled();
    }

    setEmployerNameEnabled(): void {
        if (this.form.get('employer').enabled) {
            if (
                this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED
                || this.userEmployerIsBrokerVmd()
            ) {
                this.form.get('employer.userEmployerName').disable();
            } else {
                this.form.get('employer.userEmployerName').enable();
            }
        }
    }

    setJobTitleEnabled(): void {
        if (this.form.get('employer').enabled) {
            if (
                this.form.get('employer.userIsInvestmentAdvisorIndicator').value
                && this.userEmployerIsBrokerVmd()
            ) {
                this.form.get('employer.userJobTitle').disable();
            } else {
                this.form.get('employer.userJobTitle').enable();
            }
        }
    }

    setUserSameAddressWithBrokerEmployeeFirmNameEnabled(): void {

        if (!this.form.get('userSameAddressWithBrokerEmployeeIndicator').value || this.userSameAddressWithBrokerEmployeeVmd()) {
            this.form.get('userSameAddressWithBrokerEmployee.userSameAddressWithBrokerEmployeeFirmName').disable();
        } else if (this.form.get('userSameAddressWithBrokerEmployeeIndicator').value &&
            !this.userSameAddressWithBrokerEmployeeVmd()) {
            this.form.get('userSameAddressWithBrokerEmployee.userSameAddressWithBrokerEmployeeFirmName').enable();
        }
    }

    setSectorActivity(): void {
        let userEmployerActivity = null;
        if (this.forceSectorActivityToFinanceAndInsurance()) {
            userEmployerActivity = this.constants.USER_EMPLOYER_ACTIVITY_FINANCE_AND_INSURANCE_KEY;
        }

        if (this.formService.getDdpRequester() && this.formService.getDdpRequester().userEmployerActivity) {
            userEmployerActivity = this.formService.getDdpRequester().userEmployerActivity;
        }

        this.form.get('employer.userEmployerActivity').patchValue(userEmployerActivity);
        this.formService.setFormProperty(this.getRequesterPath('userEmployerActivity'), userEmployerActivity);

        this.setSectorActivityEnabled();
    }

    setSectorActivityEnabled(): void {
        if (this.form.get('employer').enabled && this.userIsPaidEmployeeOrSelfEmployed()) {
            setTimeout(() => {
                if (this.formService.isFromSmartD() || !this.formService.isCaisseContext()) {
                    if (this.forceSectorActivityToFinanceAndInsurance()) {
                        this.form.get('employer.userEmployerActivity').disable();
                    } else {
                        this.form.get('employer.userEmployerActivity').enable();
                    }
                }
            }, 0);
        }
    }

    userEmployerIsBrokerVmd(): boolean {
        return this.userIsPaidEmployeeOrSelfEmployed()
            && this.form.get('employer').get('userEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD;
    }

    userIsPaidEmployeeOrSelfEmployed(): boolean {
        return this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_PAID_EMPLOYEE
            || this.userIsSelfEmployed();
    }

    userIsSelfEmployed(): boolean {
        return this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED;
    }

    userSameAddressWithBrokerEmployeeVmd(): boolean {
        return this.form.get('userSameAddressWithBrokerEmployee.userSameAddressWithBrokerEmployeeVmdIndicator').value;
    }

    showPercentageOwnerFieldOnSpecificClientTypes(): boolean {

        const clientType = this.formService.getClientType();

        return Functions.isSpecificClientTypes(clientType, VmdConstants.CLT_TYPE_ASSOCIATION,
            VmdConstants.CLT_TYPE_CLUB,
            VmdConstants.CLT_TYPE_COOPERATIVE,
            VmdConstants.CLT_TYPE_CORPORATION,
            VmdConstants.CLT_TYPE_CO_UNIQUE,
            VmdConstants.CLT_TYPE_ORG_GOUV,
            VmdConstants.CLT_TYPE_OSBL,
            VmdConstants.CLT_TYPE_SENC,
            VmdConstants.CLT_TYPE_SOC_COMM);
    }

    isCorporationClientTypeAndUniqueShareHolder(): boolean {
        return this.formService.getClientType() === VmdConstants.CLT_TYPE_CORPORATION
            && this.formService.getForm().otherOneShareholder === VmdConstants.UNIQUE_CORPORATION_ACCOUNT_TYPE;
    }

    setPercentageOwner(): void {

        if (this.isCorporationClientTypeAndUniqueShareHolder()) {

            this.form.get('percentageOwner').setValue(100);
            this.formService.getForm().requesters[this.requesterIndex].percentageOwner = 100;
            this.form.get('percentageOwner').disable();
        } else {
            this.updateControlEnabled(this.form.get('percentageOwner'), this.showPercentageOwnerFieldOnSpecificClientTypes());
        }
    }

    setTempEmployerName(): void {
        this.userEmployerNameOss = this.form.get('employer.userEmployerName').value;
    }

    setTempJobTitle(): void {
        this.userJobTitleOss = this.form.get('employer.userJobTitle').value;
    }

    submit() {
        if (this.showPopUpEmployerIsBroker) {
            this.modalService.openModal('ODC_STEP_OCCUPATION_MODAL_TITTLE', 'ODC_STEP_OCCUPATION_MODAL_TEXT');
            return;
        }
        this.submitForm();
    }

    private setBrokerVmd() {
        this.isBrokerVMDSession = sessionStorage.getItem(this.constants.OSS_ORIGINAL_OCCUPATION_SESSION_KEY) == this.constants.USER_EMPLOYER_IS_BROKER_VMD;
    }

    private isUserSelfEmployed() {
        return (this.form.get('userJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED);
    }

    private isUserBrokerNotVMD() {
        return this.form.get('employer.userEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER;
    }

    private isUserBroker() {
        return this.form.get('employer.userEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_NO;
    }

    private isUserEmployerBroker(): boolean {
        const userEmployerIsBroker = this.form.get('employer.userEmployerIsBroker');

        return this.userIsPaidEmployeeOrSelfEmployed()
            && (userEmployerIsBroker.value === this.constants.USER_EMPLOYER_IS_BROKER_VMD
                || userEmployerIsBroker.value === this.constants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER);
    }
}
