import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ModalService} from '../../components/services/modal.service';

@Component({
    selector: 'app-attorney',
    templateUrl: './attorney.component.html',
    providers: [TranslatePipe]
})
export class AttorneyComponent extends BaseNavComponent implements OnInit, AfterContentChecked {

    relationList: HtmlSelectKV[];
    jobStatusList: HtmlSelectKV[];
    isBrokerList: HtmlSelectKV[];
    employerActivityList: HtmlSelectKV[];
    bankList: HtmlSelectKV[];
    isModalActive = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService,
                private translatePipe: TranslatePipe,
                private modalService: ModalService,
                private changeDetectorRef: ChangeDetectorRef) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(lang => {
            if (this.attorneyIsSelfEmployed() || this.attorneyEmployerIsBrokerVmd()) {
                this.setAttorneyEmployerName();
            }
        });

        this.form = this.fb.group({
            userOtherTradingPersonIndicator: [this.formService.getForm().requesters[this.requesterIndex].userOtherTradingPersonIndicator, [this.requiredValidator('userOtherTradingPersonIndicator')]],
            attorney: this.fb.group({
                attorneyGender: [this.formService.getForm().attorneyGender, [this.requiredValidator('attorneyGender')]],
                attorneyFirstName: [this.formService.getForm().attorneyFirstName, [this.requiredValidator('attorneyFirstName')]],
                attorneyLastName: [this.formService.getForm().attorneyLastName, [this.requiredValidator('attorneyLastName')]],
                attorneyDOB: [this.formService.getForm().attorneyDOB, [this.requiredValidator('attorneyDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.date]],
                attorneySIN: [this.formService.getForm().attorneySIN, [this.requiredValidator('attorneySIN'), VmdValidators.sin]],
                phoneGroup: this.fb.group({
                    attorneyHomePhone: [this.formService.getForm().attorneyHomePhone, [this.requiredValidator('attorneyHomePhone'), VmdValidators.phone]],
                    attorneyOtherPhone: [this.formService.getForm().attorneyOtherPhone, [this.requiredValidator('attorneyOtherPhone'), VmdValidators.phone]]
                }, {validator: [VmdValidators.attorneyPhoneMatchValidator, this.requiredAttorneyPhoneGroupValidator(VmdValidators.attorneyPhoneRequired)]}),
                attorneyClientNumber: [this.formService.getForm().attorneyClientNumber, [this.requiredValidator('attorneyClientNumber'), this.getClientNumberValidator()]],
                attorneyClientRelationship: [this.formService.getForm().attorneyClientRelationship, [this.requiredValidator('attorneyClientRelationship')]],

                attorneyAddressStreet: [this.formService.getForm().attorneyAddressStreet, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('attorneyAddressStreet'))]],
                attorneyAddressUnit: [this.formService.getForm().attorneyAddressUnit, [this.requiredValidator('attorneyAddressUnit'), VmdValidators.alphanumericWithSpace]],
                attorneyAddressCity: [this.formService.getForm().attorneyAddressCity, [this.requiredValidator('attorneyAddressCity')]],
                attorneyAddressProv: [this.formService.getForm().attorneyAddressProv, [this.requiredValidator('attorneyAddressProv')]],
                attorneyAddressPostalCode: [this.formService.getForm().attorneyAddressPostalCode, [this.requiredValidator('attorneyAddressPostalCode')]],
                attorneyAddressCountry: [this.formService.getForm().attorneyAddressCountry, [this.requiredValidator('attorneyAddressCountry')]],

                attorneyJobStatus: [this.formService.getForm().attorneyJobStatus, [this.requiredValidator('attorneyJobStatus')]],
                attorneyEmployer: this.fb.group({
                    attorneyEmployerIsBroker: [this.formService.getForm().attorneyEmployerIsBroker, [this.requiredValidator('attorneyEmployerIsBroker')]],
                    attorneyEmployerName: [this.formService.getForm().attorneyEmployerName, [this.requiredValidator('attorneyEmployerName')]],
                    attorneyJobTitle: [this.formService.getForm().attorneyJobTitle, [this.requiredValidator('attorneyJobTitle')]],
                    attorneyEmployerActivity: [this.formService.getForm().attorneyEmployerActivity, [this.requiredValidator('attorneyEmployerActivity')]]
                }),

                attorneyInitieIndicator: [this.formService.getForm().attorneyInitieIndicator, [this.requiredValidator('attorneyInitieIndicator')]],
                attorneyInitie: this.fb.group({
                    attorneyInitieCompanyName: [this.formService.getForm().attorneyInitieCompanyName, [this.requiredValidator('attorneyInitieCompanyName')]],
                    attorneyInitieCompanySymbol: [this.formService.getForm().attorneyInitieCompanySymbol, [this.requiredValidator('attorneyInitieCompanySymbol')]],
                    attorneyInitieMarket: [this.formService.getForm().attorneyInitieMarket, [this.requiredValidator('attorneyInitieMarket')]]
                }),

                attorneyMainShareholderIndicator: [this.formService.getForm().attorneyMainShareholderIndicator, [this.requiredValidator('attorneyMainShareholderIndicator')]],
                attorneyMainShareholder: this.fb.group({
                    attorneyMainShareholderCompanyName: [this.formService.getForm().attorneyMainShareholderCompanyName, [this.requiredValidator('attorneyMainShareholderCompanyName')]],
                    attorneyMainShareholderCompanySymbol: [this.formService.getForm().attorneyMainShareholderCompanySymbol, [this.requiredValidator('attorneyMainShareholderCompanySymbol')]],
                    attorneyMainShareholderMarket: [this.formService.getForm().attorneyMainShareholderMarket, [this.requiredValidator('attorneyMainShareholderMarket')]]
                })
            }, {validator: [VmdValidators.attorneyAddressZipCodeValidator, this.requiredAddressGroupValidator(VmdValidators.attorneyAddressZipCodeRequired)]}),
        });

        this.listModelService.getListModel('attorneyRelationList', data => this.relationList = data);
        this.listModelService.getListModel('statutEmploiList', data => this.jobStatusList = data);
        this.listModelService.getListModel('attorneyEmployerIsBrokerList', data => this.isBrokerList = data);
        this.listModelService.getListModel('attorneyEmployerActivityList', data => this.employerActivityList = data);
        this.listModelService.getListModel('bankIdList', data => this.bankList = data);

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe(() => {
            this.isModalActive = false;
            this.formService.setFormProperty(this.getRequesterPath('userOtherTradingPersonIndicator'), false);
            this.form.get('userOtherTradingPersonIndicator').patchValue(false);
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe(() => {
            this.isModalActive = false;
            this.updateAttorneyControlStatus(true);
            this.updateControlEnabled(this.form.get('attorney.attorneyInitie'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyInitieIndicator').value);
            this.updateControlEnabled(this.form.get('attorney.attorneyMainShareholder'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyMainShareholderIndicator').value);
        }));

        this.form.get('userOtherTradingPersonIndicator').valueChanges.subscribe(value => {
            if (!this.isPleinEx() && value) {
                this.isModalActive = true;
                this.modalService.openModal('ATTORNEY_CHOICE_TITLE', 'ATTORNEY_CHOICE_MESSAGE',
                    '#modal-alert-choice-attorney');
            } else {
                this.updateControlEnabled(this.form.get('attorney.attorneyEmployer'), this.form.get('userOtherTradingPersonIndicator').value && this.attorneyIsPaidEmployeeOrSelfEmployed());
                this.updateControlEnabled(this.form.get('attorney.attorneyInitie'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyInitieIndicator').value);
                this.updateControlEnabled(this.form.get('attorney.attorneyMainShareholder'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyMainShareholderIndicator').value);

                this.updateAttorneyControlStatus(value);

                this.setAttorneyEmployerName();

                this.initHelpClick();
            }
        });
        this.updateControlEnabled(this.form.get('attorney'), this.form.get('userOtherTradingPersonIndicator').value);

        this.form.get('attorney.attorneyJobStatus').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('attorney.attorneyEmployer'), this.form.get('userOtherTradingPersonIndicator').value && this.attorneyIsPaidEmployeeOrSelfEmployed());
            this.setAttorneyEmployerName();
        });
        this.updateControlEnabled(this.form.get('attorney.attorneyEmployer'), this.form.get('userOtherTradingPersonIndicator').value && this.attorneyIsPaidEmployeeOrSelfEmployed());

        this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').valueChanges.subscribe(value => {
            this.setAttorneyEmployerName();
            this.setAttorneySectorActivity();
        });

        this.form.get('attorney.attorneyInitieIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('attorney.attorneyInitie'), value);
        });
        this.updateControlEnabled(this.form.get('attorney.attorneyInitie'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyInitieIndicator').value);

        this.form.get('attorney.attorneyMainShareholderIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('attorney.attorneyMainShareholder'), value);
        });
        this.updateControlEnabled(this.form.get('attorney.attorneyMainShareholder'), this.form.get('userOtherTradingPersonIndicator').value && this.form.get('attorney.attorneyMainShareholderIndicator').value);

        this.setAttorneyEmployerNameEnabled();
        this.setAttorneySectorActivityEnabled();

        if (!this.attorneyIsPaidEmployeeOrSelfEmployed()) {
            this.form.get('attorney.attorneyEmployer').patchValue({attorneyJobTitle: null});
            this.formService.setFormProperty('attorney.attorneyEmployer.attorneyJobTitle', null);
        }

        // OCE-7747
        this.setAttorneyEmployerName();
    }

    ngAfterContentChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    updateAttorneyControlStatus(value: boolean): void {
        this.updateControlEnabled(this.form.get('attorney'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyGender'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyFirstName'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyLastName'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyDOB'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneySIN'), value);
        this.updateControlEnabled(this.form.get('attorney.phoneGroup.attorneyHomePhone'), value);
        this.updateControlEnabled(this.form.get('attorney.phoneGroup.attorneyOtherPhone'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyClientNumber'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressStreet'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressUnit'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressCity'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressProv'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressPostalCode'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyAddressCountry'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyJobStatus'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyInitieIndicator'), value);
        this.updateControlEnabled(this.form.get('attorney.attorneyMainShareholderIndicator'), value);
    }

    forceAttorneyEmployerNameToVmd(): boolean {
        return this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD;
    }

    forceAttorneySectorActivityToFinanceAndInsurance(): boolean {
        return this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD
            || this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER;
    }

    getAttorneyEmployerIsBrokerHelp(): string {
        if (this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER) {
            return this.translatePipe.transform('USER_EMPLOYER_AUTORIZATION');
        }
        return null;
    }

    setAttorneyEmployerName(): void {
        const selfEmployedMsg = this.getMessage('EMPLOYMENT_STATUS_SELF_EMPLOYED');
        const vmdEmployedMsg = this.getMessage('USER_EMPLOYER_VMD');
        let attorneyEmployerName = null;
        if (this.form.get('attorney.attorneyJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
            attorneyEmployerName = selfEmployedMsg;
        } else if (this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD) {
            attorneyEmployerName = vmdEmployedMsg;
        } else {
            const odcName = this.formService.getForm().attorneyEmployerName;
            attorneyEmployerName = odcName === selfEmployedMsg || odcName === vmdEmployedMsg ? '' : odcName;
        }

        this.form.get('attorney.attorneyEmployer').patchValue({
            attorneyEmployerName
        });
        this.formService.setFormProperty('attorneyEmployerName', attorneyEmployerName);

        this.setAttorneyEmployerNameEnabled();
    }

    setAttorneyEmployerNameEnabled(): void {
        setTimeout(() => {
            if (this.form.get('attorney.attorneyEmployer').enabled) {
                if (
                    this.form.get('attorney.attorneyJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED
                    || this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD
                ) {
                    this.form.get('attorney.attorneyEmployer.attorneyEmployerName').disable();
                } else {
                    this.form.get('attorney.attorneyEmployer.attorneyEmployerName').enable();
                }
            }
        }, 0);
    }

    setAttorneySectorActivity(): void {
        let attorneyEmployerActivity = null;
        if (this.forceAttorneySectorActivityToFinanceAndInsurance()) {
            attorneyEmployerActivity = this.constants.USER_EMPLOYER_ACTIVITY_FINANCE_AND_INSURANCE_KEY;
        }

        this.form.get('attorney.attorneyEmployer').patchValue({
            attorneyEmployerActivity
        });
        this.formService.setFormProperty('attorneyEmployerActivity', attorneyEmployerActivity);

        this.setAttorneySectorActivityEnabled();
    }

    setAttorneySectorActivityEnabled(): void {
        setTimeout(() => {
            if (this.form.get('attorney.attorneyEmployer').enabled && this.attorneyIsPaidEmployeeOrSelfEmployed()) {
                if (this.forceAttorneySectorActivityToFinanceAndInsurance()) {
                    this.form.get('attorney.attorneyEmployer.attorneyEmployerActivity').disable();
                } else {
                    this.form.get('attorney.attorneyEmployer.attorneyEmployerActivity').enable();
                }
            }
        }, 0);
    }

    attorneyEmployerIsBrokerVmd(): boolean {
        return this.attorneyIsPaidEmployeeOrSelfEmployed()
            && this.form.get('attorney.attorneyEmployer.attorneyEmployerIsBroker').value === this.constants.USER_EMPLOYER_IS_BROKER_VMD;
    }

    attorneyIsPaidEmployeeOrSelfEmployed(): boolean {
        return this.form.get('attorney.attorneyJobStatus').value === this.constants.EMPLOYMENT_STATUS_PAID_EMPLOYEE
            || this.attorneyIsSelfEmployed();
    }

    attorneyIsSelfEmployed(): boolean {
        return this.form.get('attorney.attorneyJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED;
    }

    requiredAddressGroupValidator(validator: ValidatorFn): ValidatorFn | null {

        if (this.isPleinEx()) {
            return Validators.nullValidator;
        } else {
            return validator;
        }
    }

    getClientNumberValidator(): ValidatorFn {
        return VmdValidators.clientNumber;
    }
}

