import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {VmdForm} from '../../../components/utils/vmd-form';

declare let $: any;

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    providers: [VmdForm]
})
export class ErrorComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() ngFormRef: NgForm;
    @Input() submitted: boolean;
    @Input() path: string;
    @Input() nameSuffix = '';

    @Input() onSubmitOnly: string[] = null;

    control: AbstractControl;
    lastPath: string;

    ngOnInit() {
        this.control = this.form.get(this.path);
        this.lastPath = VmdForm.getLastPath(this.path);

        if (this.onSubmitOnly) {

        }
    }

    getErrors(): { key: string, params: any }[] {
        const errors: { key: string, params: any }[] = [];

        if (this.control != null && this.control.errors != null) {
            Object.keys(this.control.errors).forEach(key => {
                if (this.control.errors[key]) {
                    errors.push({key, params: this.control.errors[key]});
                }
            });
        }

        return errors;
    }

    showErrors(key: string): boolean {
        return VmdForm.isFieldInError(this.control, this.submitted, this.onSubmitOnly);
    }
}
