<div *ngIf="this.password != null && this.password.length > 0" class="progress-container progress-default" aria-hidden="true"
     id="password-strength-progress">
    <div class="progress-inner">
        <span class="progress-valeur">{{ progressBarStrengthText | translate }}</span>
        <div class="progress-wrap">
            <div class="progress-thermometre" [style.background-color]="passwordStrength < passwordStrengthLimit ? '#CC0000' : ''"></div>
            <progress class="progress" max="4"></progress>
        </div>
    </div>
</div>
<p>Suggestions</p>
<ul>
    <li>{{ 'PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_MIN_8' | translate}}</li>
    <li>{{ 'PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_UPPERCASE_LETTER' | translate}}</li>
    <li>{{ 'PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_LOWERCASE_LETTER' | translate}}</li>
    <li>{{ 'PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_SPECIAL_CHARACTER' | translate}}</li>
    <li>{{ 'PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_DIGIT' | translate}}</li>
</ul>
