import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-successor',
  templateUrl: './review-successor.component.html'
})
export class ReviewSuccessorComponent implements OnInit {

    @Input() label: string;

    @Input() name: string;
    @Input() sin: string;
    @Input() part: string;

  ngOnInit() {

  }
}
