import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {WebException} from '../../components/models';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {ModalService} from '../../components/services/modal.service';
import {ApiEndpoints} from "../../components/enums/api-endpoints.enum";

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent extends BaseNavComponent implements OnInit {

    protected loadingDownload = false;
    protected loadingEmail = false;

    constructor(public navigationService: NavigationService,
                public fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);

    }

    ngOnInit() {
    }

    hasTransfer(): boolean {
        return this.formService.getForm().transfer ||
            this.formService.getForm().rrspTransfer ||
            this.formService.getForm().tfsaTransfer ||
            this.formService.getForm().fhsaTransfer ||
            this.formService.getForm().respTransfer ||
            this.formService.getForm().liraTransfer ||
            this.formService.getForm().rrifTransfer ||
            this.formService.getForm().lifTransfer ||
            this.formService.getForm().jointTransfer ||
            this.formService.getForm().otherTransfer;
    }

    downloadPDF() {
        const isAdditional = (
            this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU
            && this.formService.getForm().transmissionMethod === this.constants.TRANSMISSION_ESIGN_MAIL
        );

        this.modalService.openModal(null, null, '#modal-pdf');
        this.modalService.emitPdfLoading(true);
        this.modalService.emitPdfLink(isAdditional);
    }

    emailPDF() {
        this.loadingEmail = true;
        this.error = null;
        this.formService.emailAdditionForm(this.getPathEmailUrl()).subscribe(() => {
            this.error = null;
            this.loadingEmail = false;
        }, (errors: WebException) => {
            this.loadingEmail = false;
            this.error = errors;
        });

    }

    getPathEmailUrl(): string {
        const encodedRequestId = encodeURIComponent(this.formService.getForm().requestID);
        const pathPdfESignEmail = this.formService.isFromSmartD() || this.formService.isCaisseContext()
            ? `${ApiEndpoints.InternalPdfESignEmail}?encodedRequestId=${encodedRequestId}`
            : `${ApiEndpoints.ExternalPdfESignEmail}?encodedRequestId=${encodedRequestId}`

        const pathPdfTypeEmail = this.formService.isFromSmartD() || this.formService.isCaisseContext()
            ? `${ApiEndpoints.InternalPdfTypeEmail}?encodedRequestId=${encodedRequestId}`
            : `${ApiEndpoints.ExternalPdfTypeEmail}?encodedRequestId=${encodedRequestId}`

        if (this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU &&
            this.formService.getForm().transmissionMethod === this.constants.TRANSMISSION_ESIGN_MAIL) {
            return pathPdfESignEmail;
        } else {
            return pathPdfTypeEmail;
        }
    }

    showMailButton(): boolean {
        return this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU;
    }
}
