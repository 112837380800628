<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">


    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-house"></span>

        {{'ODC_BENEFICIARY_TFSA_TITLE' | translate}}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>


    <!--Answer-->
    <div *ngIf="hasSpouse()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_BENEFICIARY_TFSA_QUESTION' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'beneficiaryCeliConjointTitulaire'"
            [name]="'beneficiaryCeliConjointTitulaire'"
            [path]="'beneficiaryCeliConjointTitulaire'"
            [binding]="'beneficiaryCeliConjointTitulaire'"
        ></app-odc-field>
    </div>

    <!--Answer-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_BENEFICIARY_RRSP_QUESTION' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'beneficiaryCeliActiveIndicator'"
        [name]="'beneficiaryCeliActiveIndicator'"
        [path]="'beneficiaryCeliActiveIndicator'"
        [binding]="'beneficiaryCeliActiveIndicator'"
    ></app-odc-field>



    <div *ngIf="isBeneficiaryPriIndicator">

        <app-beneficiary
            [form]="form"
            [submitted]="isFormSubmitted()"
            [groupName]="'beneficiary'"
            [gender]="'beneficiaryCeliGender'"
            [firstName]="'beneficiaryCeliFirstName'"
            [lastName]="'beneficiaryCeliLastName'"
            [DOB]="'beneficiaryCeliDOB'"
            [SIN]="'beneficiaryCeliSIN'"
            [addressStreet]="'beneficiaryCeliAdressStreet'"
            [addressUnit]="'beneficiaryCeliAdressUnit'"
            [addressCity]="'beneficiaryCeliAdressCity'"
            [addressProv]="'beneficiaryCeliAdressProv'"
            [addressPostalCode]="'beneficiaryCeliAdressPostalCode'"
            [addressCountry]="'beneficiaryCeliAdressCountry'"
            [parentLink]="'beneficiaryCeliParentLink'"
            [part]="'beneficiaryCeliPart'"
            [deleteVisible]="isBeneficiarySecIndicator"
            (toggle)="removePriBeneficiary()"
        ></app-beneficiary>

        <div class="m-b-1">
            <ng-container *ngIf="!isBeneficiarySecIndicator">
                <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiarySec()">
                    <span class="icon-circle-plus"></span>
                    {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                </a>
            </ng-container>

        </div>

        <div *ngIf="isBeneficiarySecIndicator" >

            <app-beneficiary
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiarySec'"
                [gender]="'beneficiaryCeliGenderSec'"
                [firstName]="'beneficiaryCeliFirstNameSec'"
                [lastName]="'beneficiaryCeliLastNameSec'"
                [DOB]="'beneficiaryCeliDOBSec'"
                [SIN]="'beneficiaryCeliSINSec'"
                [addressStreet]="'beneficiaryCeliAdressStreetSec'"
                [addressUnit]="'beneficiaryCeliAdressUnitSec'"
                [addressCity]="'beneficiaryCeliAdressCitySec'"
                [addressProv]="'beneficiaryCeliAdressProvSec'"
                [addressPostalCode]="'beneficiaryCeliAdressPostalCodeSec'"
                [addressCountry]="'beneficiaryCeliAdressCountrySec'"
                [parentLink]="'beneficiaryCeliParentLinkSec'"
                [part]="'beneficiaryCeliPartSec'"
                [deleteVisible]="!isBeneficiaryTerIndicator"
                (toggle)="removeSecBeneficiary()"
            ></app-beneficiary>



            <div class="m-b-1">
                <ng-container *ngIf="!isBeneficiaryTerIndicator">
                    <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiaryTer()">
                        <span class="icon-circle-plus"></span>
                        {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                    </a>
                </ng-container>

            </div>

        </div>

        <div *ngIf="isBeneficiaryTerIndicator">
            <app-beneficiary
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiaryTer'"
                [gender]="'beneficiaryCeliGenderTer'"
                [firstName]="'beneficiaryCeliFirstNameTer'"
                [lastName]="'beneficiaryCeliLastNameTer'"
                [DOB]="'beneficiaryCeliDOBTer'"
                [SIN]="'beneficiaryCeliSINTer'"
                [addressStreet]="'beneficiaryCeliAdressStreetTer'"
                [addressUnit]="'beneficiaryCeliAdressUnitTer'"
                [addressCity]="'beneficiaryCeliAdressCityTer'"
                [addressProv]="'beneficiaryCeliAdressProvTer'"
                [addressPostalCode]="'beneficiaryCeliAdressPostalCodeTer'"
                [addressCountry]="'beneficiaryCeliAdressCountryTer'"
                [parentLink]="'beneficiaryCeliParentLinkTer'"
                [part]="'beneficiaryCeliPartTer'"
                [deleteVisible]="true"
                (toggle)="removeTerBeneficiary()"
            ></app-beneficiary>
        </div>


    </div>

    <p *ngIf="isBeneficiaryPriIndicator" class="m-b-2 m-t-1" tabindex="0">{{ 'ODC_BENEFICIARY_RRSP_SHARE_WITHOUT_BENEFICIARY' | translate }}</p>


    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>

<ng-template #helpFr>
    <h2>Qu'est-ce qu'un titulaire remplaçant?</h2>
    <p>Dans les provinces et territoires qui reconnaissent une désignation de bénéficiaire de CELI, l'époux ou le conjoint de fait du titulaire, au moment de son décès, qui est désigné comme le titulaire remplaçant du CELI, et acquiert tous les droits du titulaire selon l'arrangement, y compris le droit de révoquer toute désignation de bénéficiaire, l'époux ou le conjoint de fait devient le nouveau titulaire du compte.</p>

    <h2>Pourquoi nommer un bénéficiaire?</h2>
    <p>Les actifs du CELI pourront ainsi être transférés directement aux bénéficiaires que vous désignez dans la documentation du compte CELI conformément à la législation applicable.</p>
</ng-template>
<ng-template #helpEn>
    <h2>What is a successor holder?</h2>
    <p>Applies in provinces and territories that recognize a TFSA beneficiary designation. Upon the holder's death, the spouse or common-law partner of the holder who is designated as the successor holder of the TFSA acquires all the rights of the holder, including the right to revoke any beneficiary designation. The spouse or common-law partner becomes the new account holder.</p>

    <h2>Why name a beneficiary?</h2>
    <p>TFSA assets will therefore be transferred directly to the beneficiaries you designate (in the TFSA account documentation) in accordance with the applicable legislation.</p>
</ng-template>
