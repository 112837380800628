import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {BeneficiaryRespBean} from '../../components/models/BeneficiaryRespBean';
import {ModalService} from '../../components/services/modal.service';

const MODAL_CONTEXT_BEC_INFORMATION = 'MODAL_CONTEXT_BEC_INFORMATION';

@Component({
    selector: 'app-beneficiary-resp',
    templateUrl: './beneficiary-resp.component.html'
})
export class BeneficiaryRespComponent extends BaseNavComponent implements OnInit {

    currentBeneficiaryList: BeneficiaryRespBean [];

    beneficiariesAreNotSiblings = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            if (MODAL_CONTEXT_BEC_INFORMATION === context) {
                // cancel
                this.checkAllRespBeneficiariesCLBInputs(true);
            }
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe((context: string) => {
            if (MODAL_CONTEXT_BEC_INFORMATION === context) {
                // continue
                this.checkCESGInput(false);
            }
        }));

        this.currentBeneficiaryList = this.formService.getForm().beneficiaryRespList;

        this.form = this.fb.group({

            beneficiaryGroup: this.fb.array(this.createBeneficiaries()),

            beneficiaryRespBrotherAndSisterOption: [this.formService.getForm().beneficiaryRespBrotherAndSisterOption, [this.requiredValidator('beneficiaryRespBrotherAndSisterOption')]],
            beneficiaryRespReason: [this.formService.getForm().beneficiaryRespReason, [this.requiredValidator('beneficiaryRespReason')]],

            requestCESGForResp: [this.formService.getForm().requestCESGForResp, [this.requiredValidator('requestCESGForResp')]]
        }, {validator: !this.formService.isPrintMode() && this.validBeneficiaryResp});

        this.manageCaregiverFieldsRequirement(!this.formService.getForm().requestCESGForResp);

        if (!this.isFamilyAccount()) {
            this.disablePartForIndividualRespField();
        }

        if (!this.isFamilyAccount() || !this.hasMoreThanOneBeneficiary()) {
            this.disableBeneficiariesAreBroAndSisField();
            this.removeSiblingsChoice();
        }

        const beneficiaryRespBrotherAndSisterOptionValue = this.formService.getForm().beneficiaryRespBrotherAndSisterOption;
        if (beneficiaryRespBrotherAndSisterOptionValue !== null) {
            this.beneficiariesAreNotSiblings = !beneficiaryRespBrotherAndSisterOptionValue;

            if (this.isPleinEx() && (this.isPersonalAccount() || this.isJointAccount()) && !beneficiaryRespBrotherAndSisterOptionValue) {
                this.validFormForSCEEAndBEC(beneficiaryRespBrotherAndSisterOptionValue);
            }
        }
        this.form.get('beneficiaryRespBrotherAndSisterOption').valueChanges.subscribe(value => {
            if (!value) {
                this.beneficiariesAreNotSiblings = !value;
                this.updateControlEnabled(this.form.get('beneficiaryRespReason'), true);
            } else {
                this.removeBeneficiaryReason();
            }

            if (this.isPleinEx() && (this.isPersonalAccount() || this.isJointAccount())) {
                this.validFormForSCEEAndBEC(value);
            }
        });

        const requestCESGForRespValue = this.formService.getForm().requestCESGForResp;
        if (requestCESGForRespValue !== null) {

            if (this.isPleinEx() && (this.isPersonalAccount() || this.isJointAccount())) {
                if (requestCESGForRespValue) {

                    if (this.checkBeneficiaryCLBOptionsAndSiblingOption()) {
                        this.showCLBLimitationMessage();
                    }
                }
            }
        }
        this.form.get('requestCESGForResp').valueChanges.subscribe(value => {
            this.formService.setEmitBeneficiarySCEE(!value);

            this.manageCaregiverFieldsRequirement(!value);

            if (this.isPleinEx() && (this.isPersonalAccount() || this.isJointAccount())) {
                if (value) {

                    if (this.checkBeneficiaryCLBOptionsAndSiblingOption()) {
                        this.showCLBLimitationMessage();
                    }
                }
            }
        });

        this.createSubscriberForAddressChanges();

        if (this.isPleinEx() && (this.isPersonalAccount() || this.isJointAccount())) {

            for (let i = 0; i < this.getBeneficiaryGroupLength(); i++) {
                this.getControl('beneficiaryGroup.' + i).get('beneficiaryRespRequestCLBForResp')
                    .valueChanges.subscribe(value => {
                    if (!value) {
                        if (this.checkBeneficiaryCLBOptionsAndSiblingOption()) {
                            this.showCLBLimitationMessage();
                        }
                    }
                });
            }
        }
    }

    createBeneficiaries(): AbstractControl[] {

        // For recovery purpose
        if (this.formService.getForm().beneficiaryRespList.length > 0) {
            return this.populateBeneficiaries();
        }

        const beneficiaries = [];

        if (this.isFamilyAccount()) {
            for (let i = 0; i < this.formService.getForm().respNumberOfBeneficiaries; i++) {
                this.createEmptyBeneficiaryBean(beneficiaries);
            }
        } else {
            this.createEmptyBeneficiaryBean(beneficiaries);
        }

        if (!this.isFamilyAccount() && this.getBeneficiaryRespList().length === 1) {
            this.getBeneficiaryRespList()[0].beneficiaryRespDistributionRatio = '100';
        }

        return beneficiaries;
    }

    createEmptyBeneficiaryBean(beneficiaries: FormGroup[]) {
        const beneficiary = new BeneficiaryRespBean();
        beneficiaries.push(this.buildBeneficiary(beneficiary));
        this.getBeneficiaryRespList().push(beneficiary);
    }

    populateBeneficiaries(): AbstractControl[] {

        const beneficiaries = [];

        const recoveryBeneficiaryRespBeanList = this.getBeneficiaryRespList();

        if (this.isFamilyAccount()) {

            // newBeneficiaryList in order to manage respNumberOfBeneficiaries modification
            const newBeneficiaryList = [];

            for (let i = 0; i < this.formService.getForm().respNumberOfBeneficiaries; i++) {

                const beneficiary = recoveryBeneficiaryRespBeanList[i] || new BeneficiaryRespBean();
                beneficiaries.push(this.buildBeneficiary(beneficiary));
                newBeneficiaryList.push(beneficiary);
            }
            this.formService.getForm().beneficiaryRespList = newBeneficiaryList;

        } else {
            beneficiaries.push(this.buildBeneficiary(recoveryBeneficiaryRespBeanList[0]));
        }
        return beneficiaries;
    }

    createSubscriberForAddressChanges() {

        if (this.isFamilyAccount()) {
            for (let i = 0; i < this.formService.getForm().respNumberOfBeneficiaries; i++) {

                this.createSubscriberForAddressChangesForOneBeneficiary(i);
            }
        } else {
            this.createSubscriberForAddressChangesForOneBeneficiary(0);
        }
    }

    private createSubscriberForAddressChangesForOneBeneficiary(index: number) {
        const actualOptionValue = !!this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespAddressSameAsSubscriber').value;
        this.toggleAddressFieldsFromSpecificBeneficiary(index, actualOptionValue);
        this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespAddressSameAsSubscriber').valueChanges.subscribe(value => {
            this.toggleAddressFieldsFromSpecificBeneficiary(index, value);
        });
    }

    buildBeneficiary(beneficiary: BeneficiaryRespBean): FormGroup {
        return this.fb.group({
            beneficiaryRespGender: [beneficiary.beneficiaryRespGender, [this.requiredValidator('beneficiaryRespGender')]],
            beneficiaryRespFirstName: [beneficiary.beneficiaryRespFirstName, [this.requiredValidator('beneficiaryRespFirstName')]],
            beneficiaryRespLastName: [beneficiary.beneficiaryRespLastName, [this.requiredValidator('beneficiaryRespLastName')]],
            beneficiaryRespDOB: [beneficiary.beneficiaryRespDOB, [this.requiredValidator('beneficiaryRespDOB'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
            beneficiaryRespSIN: [beneficiary.beneficiaryRespSIN, [this.requiredValidator('beneficiaryRespSIN'), VmdValidators.sin]],
            beneficiaryRespAddressSameAsSubscriber: [beneficiary.beneficiaryRespAddressSameAsSubscriber, [this.requiredValidator('beneficiaryRespAddressSameAsSubscriber')]],
            beneficiaryRespAddress: this.fb.group({
                beneficiaryRespAddressStreet: [beneficiary.beneficiaryRespAddressStreet, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryRespAddressStreet'))]],
                beneficiaryRespAddressUnit: [beneficiary.beneficiaryRespAddressUnit, [VmdValidators.alphanumericWithSpace]],
                beneficiaryRespAddressProv: [beneficiary.beneficiaryRespAddressProv, [this.requiredValidator('beneficiaryRespAddressProv')]],
                beneficiaryRespAddressCity: [beneficiary.beneficiaryRespAddressCity, [this.requiredValidator('beneficiaryRespAddressCity')]],
                beneficiaryRespAddressPostalCode: [beneficiary.beneficiaryRespAddressPostalCode, [this.requiredValidator('beneficiaryRespAddressPostalCode')]],
                beneficiaryRespAddressCountry: [beneficiary.beneficiaryRespAddressCountry, [this.requiredValidator('beneficiaryRespAddressCountry')]]
            }, {validator: [VmdValidators.beneficiaryRespAddressZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryRespAddressZipCodeRequired)]}),
            beneficiaryRespParentLink: [beneficiary.beneficiaryRespParentLink, [this.requiredValidator('beneficiaryRespParentLink')]],
            beneficiaryRespDistributionRatio: [beneficiary.beneficiaryRespDistributionRatio, [this.requiredValidator('beneficiaryRespDistributionRatio'), VmdValidators.digit, VmdValidators.positiveDigit]],

            beneficiaryRespCaregiverParentFirstName: [beneficiary.beneficiaryRespCaregiverParentFirstName, [this.requiredValidator('beneficiaryRespCaregiverParentFirstName')]],
            beneficiaryRespCaregiverParentLastName: [beneficiary.beneficiaryRespCaregiverParentLastName, [this.requiredValidator('beneficiaryRespCaregiverParentLastName')]],
            beneficiaryRespCaregiverParentSin: [beneficiary.beneficiaryRespCaregiverParentSin, [this.requiredValidator('beneficiaryRespCaregiverParentSin'), VmdValidators.sin]],
            beneficiaryRespRequestCLBForResp: [beneficiary.beneficiaryRespRequestCLBForResp, [this.requiredValidator('beneficiaryRespRequestCLBForResp')]],

            beneficiaryRespCustodialParentGender: [beneficiary.beneficiaryRespCustodialParentGender, [this.requiredValidator('beneficiaryRespCustodialParentGender')]],
            beneficiaryRespCustodialParentFirstName: [beneficiary.beneficiaryRespCustodialParentFirstName, [this.requiredValidator('beneficiaryRespCustodialParentFirstName')]],
            beneficiaryRespCustodialParentLastName: [beneficiary.beneficiaryRespCustodialParentLastName, [this.requiredValidator('beneficiaryRespCustodialParentLastName')]],
            beneficiaryRespCustodialParentSecGender: [beneficiary.beneficiaryRespCustodialParentSecGender, [this.requiredValidator('beneficiaryRespCustodialParentSecGender')]],
            beneficiaryRespCustodialParentSecFirstName: [beneficiary.beneficiaryRespCustodialParentSecFirstName, [this.requiredValidator('beneficiaryRespCustodialParentSecFirstName')]],
            beneficiaryRespCustodialParentSecLastName: [beneficiary.beneficiaryRespCustodialParentSecLastName, [this.requiredValidator('beneficiaryRespCustodialParentSecLastName')]]
        });
    }

    validFormForSCEEAndBEC(value: boolean) {

        if (value != null) {
            if (value) {
                this.checkCESGInput(false);
                this.checkAllRespBeneficiariesCLBInputs(false);
                this.disableCESGInput(false);
                this.enableAllRespBeneficiariesCLBInputs();

                if (this.checkBeneficiaryCLBOptionsAndSiblingOption()) {

                    this.showCLBLimitationMessage();
                }
            } else {
                this.checkCESGInput(true);
                this.disableCESGInput(true);
                this.checkAllRespBeneficiariesCLBInputs(true);
                this.disableAllRespBeneficiariesCLBInputs();
            }
        }
    }

    checkBeneficiaryCLBOptionsAndSiblingOption(): boolean {
        const beneficiaryRespBrotherAndSisterOption = this.getControl('beneficiaryRespBrotherAndSisterOption');
        const requestCESGForRespOption = this.getControl('requestCESGForResp');

        return ((beneficiaryRespBrotherAndSisterOption.value || !this.isFamilyAccount())
            && requestCESGForRespOption.value
            && this.isCLBOptionIsNotCheckedForABeneficiary());
    }

    showCLBLimitationMessage() {
        this.modalService.openModal('REVIEW_BENEFICIARY_MODAL_TITLE',
            'REVIEW_BENEFICIARY_MODAL_BODY',
            '#modal-alert-personal-information',
            MODAL_CONTEXT_BEC_INFORMATION);
    }

    isCLBOptionIsNotCheckedForABeneficiary(): boolean {

        for (let i = 0; i < this.getBeneficiaryGroupLength(); i++) {
            if (this.form.get('beneficiaryGroup.' + i).get('beneficiaryRespRequestCLBForResp').value !== true) {

                return true;
            }
        }
    }

    // OCE-7881
    validBeneficiaryResp(form: FormGroup) {

        const beneficiaries = form.get('beneficiaryGroup') as FormArray;

        if (beneficiaries.length > 1) {
            const beneficiaryRespBrotherAndSisterOption = form.get('beneficiaryRespBrotherAndSisterOption');
            const beneficiaryRespReason = form.get('beneficiaryRespReason');

            if (!beneficiaryRespBrotherAndSisterOption.value && !beneficiaryRespReason.value) {

                return VmdValidators.beneficiaryRespReasonRequired(beneficiaryRespReason, beneficiaryRespBrotherAndSisterOption.value);
            }
        }

        let controls: AbstractControl[];

        controls = [];
        for (let i = 0; i < beneficiaries.length; i++) {
            controls.push(form.get('beneficiaryGroup.' + i).get('beneficiaryRespDistributionRatio'));
        }

        return VmdValidators.validPercentageCumul(controls, 100);
    }

    manageCaregiverFieldsRequirement(activated: boolean) {

        if (this.isFamilyAccount()) {

            for (let i = 0; i < this.formService.getForm().respNumberOfBeneficiaries; i++) {

                this.toggleCaregiverFieldsRequirement(i, activated);
            }
        } else {
            this.toggleCaregiverFieldsRequirement(0, activated);
        }
    }

    toggleCaregiverFieldsRequirement(index: number, activated: boolean) {
        this.updateControlEnabled(this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespCaregiverParentFirstName'), activated);
        this.updateControlEnabled(this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespCaregiverParentLastName'), activated);
        this.updateControlEnabled(this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespCaregiverParentSin'), activated);
    }

    toggleAddressFieldsFromSpecificBeneficiary(index: number, show: boolean) {
        this.updateControlEnabled(this.form.get('beneficiaryGroup.' + index).get('beneficiaryRespAddress'), show);
    }

    removeBeneficiaryReason() {
        this.beneficiariesAreNotSiblings = false;
        this.form.get('beneficiaryRespReason').setValue(null);
        this.formService.setFormProperty('beneficiaryRespReason', null);
    }

    removeSiblingsChoice() {
        this.getControl('beneficiaryRespBrotherAndSisterOption').setValue(null);
        this.formService.getForm().beneficiaryRespBrotherAndSisterOption = null;
    }

    disablePartForIndividualRespField() {
        this.updateControlEnabled(this.form.get('beneficiaryGroup.0').get('beneficiaryRespDistributionRatio'), false);
    }

    disableBeneficiariesAreBroAndSisField() {
        this.updateControlEnabled(this.form.get('beneficiaryRespBrotherAndSisterOption'), false);
    }

    isFamilyAccount(): boolean {
        return this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY;
    }

    private isPersonalAccount(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU;
    }

    private isJointAccount(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT;
    }

    get beneficiaryGroup(): FormArray {
        return this.form.get('beneficiaryGroup') as FormArray;
    }

    private checkCESGInput(checked: boolean) {
        this.form.get('requestCESGForResp').setValue(checked);
        this.formService.getForm().requestCESGForResp = checked;
    }

    private disableCESGInput(disabled: boolean) {
        if (disabled) {
            this.form.get('requestCESGForResp').disable();
        } else {
            this.form.get('requestCESGForResp').enable();
        }
    }

    private checkAllRespBeneficiariesCLBInputs(checked: boolean) {

        const recoveryBeneficiaryRespBeanList = this.getBeneficiaryRespList();

        for (let i = 0; i < this.getBeneficiaryGroupLength(); i++) {

            const beneficiary = recoveryBeneficiaryRespBeanList[i];
            beneficiary.beneficiaryRespRequestCLBForResp = checked;
            this.form.get('beneficiaryGroup.' + i).get('beneficiaryRespRequestCLBForResp').setValue(checked);
        }
    }

    private disableAllRespBeneficiariesCLBInputs() {

        for (let i = 0; i < this.getBeneficiaryGroupLength(); i++) {
            this.form.get('beneficiaryGroup.' + i).get('beneficiaryRespRequestCLBForResp').disable();
        }
    }

    private enableAllRespBeneficiariesCLBInputs() {

        for (let i = 0; i < this.getBeneficiaryGroupLength(); i++) {
            this.form.get('beneficiaryGroup.' + i).get('beneficiaryRespRequestCLBForResp').enable();
        }
    }

    getBeneficiaryRespList() {
        if (!this.formService.getForm().beneficiaryRespList) {
            this.formService.getForm().beneficiaryRespList = [];
        }
        return this.formService.getForm().beneficiaryRespList;
    }

    hasMoreThanOneBeneficiary() {
        return this.formService.getForm().respNumberOfBeneficiaries > 1;
    }

    getBeneficiaryGroupLength() {
        return this.beneficiaryGroup.length;
    }

    submit() {

    }
}
