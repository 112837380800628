import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ErrorCodeConstants} from '../../../components/constants/error-code-constants';
import {WebException} from '../../../components/models';

declare let $: any;

@Component({
    selector: 'app-http-error',
    templateUrl: './http-error.component.html'
})
export class HttpErrorComponent implements OnChanges {

    @ViewChild('errorBloc') errorBloc: ElementRef;
    @Input() errors: WebException | any;
    @Input() isGenericBlocError = true;
    errMsg: string;

    private warningCodeToErrorMessageCode: { [key: string]: string } = {
        'ODC_LOGIN_ERR_LIST_OBSOLETE': 'ODC_LOGIN_ERR_LIST_OBSOLETE',
        'ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND': 'ODC_LOGIN_ERR_QUESTIONNAIRE_NOT_FOUND',
        'TEMPORARY_PASSWORD_INVALID': 'ODC_RESET_PASSWORD_ERR_PASSWORD',
        'PASSWORD_INVALID_HISTORY': 'PASSWORD_INVALID_HISTORY',
        'RECAPTCHA_INVALID': 'RECAPTCHA_INVALID',
        'CLIENT_PROFILE_EXISTS': 'CLIENT_PROFILE_EXISTS'
    };

    ngOnChanges(changes: SimpleChanges): void {
        this.onErrorsTriggered();
    }

    onErrorsTriggered() {
        this.printHttpError();
    }

    printHttpError() {

        this.errMsg = this.errors ? this.getErrorCode(this.errors) : null;

        if (this.errMsg) {
            setTimeout(() => {

                const errorSection = $(this.errorBloc);

                if (errorSection.length > 0) {
                    errorSection.focus();
                }
            }, 0);
        }
    };

    private getErrorCode(error: any): string {

        if (error.listWarning && error.listWarning.length > 0) {
            const warning = error.listWarning[0];

            if (warning.message) {
                return this.warningCodeToErrorMessageCode[warning.message];
            }

            return warning;
        }

        return ErrorCodeConstants.GLOBAL_ERROR_MSG;
    }

    reset() {
        this.errMsg = null;
    }
}
