export class VmdFormatZipCode {

    formatAsZipCode(valueToFormat: string): string {
        if (!!valueToFormat) {
            const postalCodeNoSpace = this.undoFormatAsZipCode(valueToFormat);

            return (
                postalCodeNoSpace.slice(0, 3) + ' ' +
                postalCodeNoSpace.slice(3, 6)
            ).trim().toUpperCase();
        }
        return valueToFormat;
    }

    formatAsUsaZipCode(valueToFormat: string): string {
        if (!!valueToFormat) {
            const postalCodeNoDash = this.undoFormatAsZipCode(valueToFormat);

            if (postalCodeNoDash.length > 5) {
                return (postalCodeNoDash.slice(0, 5) + '-' + postalCodeNoDash.slice(5, 9)).trim().toUpperCase();
            } else {
                return postalCodeNoDash.trim().toUpperCase();
            }
        }
        return valueToFormat;
    }

    undoFormatAsZipCode(valueToUnformat: string): string {
        if (!!valueToUnformat) {
            return valueToUnformat.replace(/ /g, '').replace(/-/g, '');
        }
        return valueToUnformat;
    }
}
