<form (submit)="submitForm()" [formGroup]="form" ngForm novalidate>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <h2 class="clearfix">
        {{'USER_INVESTMENT_OBJECTIVES_INTENDED_USE_TITLE' | translate}}
    </h2>

    <app-odc-field
        [binding]="'userInvestmentObjectivesIntendedUse'"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [form]="form"
        [id]="'userInvestmentObjectivesIntendedUse'"
        [label]="'USER_INVESTMENT_OBJECTIVES_INTENDED_USE' | translate"
        [list]="codeIntendedUsage"
        [name]="'userInvestmentObjectivesIntendedUse'"
        [path]="'userInvestmentObjectivesIntendedUse'"
        [submitted]="isFormSubmitted()"
        [type]="constants.FIELD_TYPES.SELECT"
    ></app-odc-field>

    <h2 class="clearfix">
        {{'USER_INVESTMENT_OBJECTIVES_INVESTMENT_HORIZON_TITLE' | translate}}
    </h2>

    <app-odc-field
        [binding]="'userInvestmentObjectivesNonRegInvHorizon'"
        [className]="''"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [form]="form"
        [id]="'userInvestmentObjectivesNonRegInvHorizon'"
        [label]="'USER_INVESTMENT_OBJECTIVES_NON_REG_INV_HORIZON' | translate"
        [list]="codeInvestmentHorizon"
        [name]="'userInvestmentObjectivesNonRegInvHorizon'"
        [path]="'userInvestmentObjectivesNonRegInvHorizon'"
        [submitted]="isFormSubmitted()"
        [type]="constants.FIELD_TYPES.SELECT"
    ></app-odc-field>

    <app-odc-field *ngIf="shouldDisplayRegisteredAccount()"
                   [binding]="'userInvestmentObjectivesRegInvHorizon'"
                   [className]="''"
                   [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                   [form]="form.get('registered')"
                   [id]="'userInvestmentObjectivesRegInvHorizon'"
                   [label]="'USER_INVESTMENT_OBJECTIVES_REG_INV_HORIZON' | translate"
                   [list]="codeInvestmentHorizon"
                   [name]="'userInvestmentObjectivesRegInvHorizon'"
                   [path]="'userInvestmentObjectivesRegInvHorizon'"
                   [submitted]="isFormSubmitted()"
                   [type]="constants.FIELD_TYPES.SELECT"
    ></app-odc-field>

    <h2 class="clearfix">
        {{'USER_INVESTMENT_OBJECTIVES_RISK_TOLERANCE_TITLE' | translate}}
    </h2>

    <app-odc-field
        [binding]="'userInvestmentObjectivesRskTol'"
        [className]="''"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [form]="form"
        [id]="'userInvestmentObjectivesRskTol'"
        [label]="'USER_INVESTMENT_OBJECTIVES_RSK_TOL' | translate"
        [list]="codeRiskTolerance"
        [name]="'userInvestmentObjectivesRskTol'"
        [path]="'userInvestmentObjectivesRskTol'"
        [submitted]="isFormSubmitted()"
        [type]="constants.FIELD_TYPES.SELECT"
    ></app-odc-field>

    <h2 class="clearfix">
        {{'USER_INVESTMENT_OBJECTIVES_TITLE' | translate}}
    </h2>

    <table class="table tableau-donnees">
        <thead>
        <tr>
            <th class="va-top">
            </th>
            <th class="va-top">
                {{'USER_INVESTMENT_OBJECTIVES_TABLE_COL1'|translate}}
            </th>
            <th *ngIf="shouldDisplayRegisteredAccount()" class="va-top">
                {{'USER_INVESTMENT_OBJECTIVES_TABLE_COL2'|translate}}
            </th>
        </tr>
        </thead>
        <tbody [className]="''">
        <tr>
            <th class="p-t-4">{{'USER_INVESTMENT_OBJECTIVES_TABLE_ROW1'|translate}}</th>
            <td class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesLowRiskNonReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form"
                    [id]="'userInvestmentObjectivesLowRiskNonReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesLowRiskNonReg'"
                    [path]="'userInvestmentObjectivesLowRiskNonReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
            <td *ngIf="shouldDisplayRegisteredAccount()" class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesLowRiskReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form.get('registered')"
                    [id]="'userInvestmentObjectivesLowRiskReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesLowRiskReg'"
                    [path]="'userInvestmentObjectivesLowRiskReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
        </tr>
        <tr>
            <th class="p-t-4">{{'USER_INVESTMENT_OBJECTIVES_TABLE_ROW2'|translate}}</th>
            <td class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesModerateRiskNonReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form"
                    [id]="'userInvestmentObjectivesModerateRiskNonReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesModerateRiskNonReg'"
                    [path]="'userInvestmentObjectivesModerateRiskNonReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
            <td *ngIf="shouldDisplayRegisteredAccount()" class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesModerateRiskReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form.get('registered')"
                    [id]="'userInvestmentObjectivesModerateRiskReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesModerateRiskReg'"
                    [path]="'userInvestmentObjectivesModerateRiskReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
        </tr>
        <tr>
            <th class="p-t-4">{{'USER_INVESTMENT_OBJECTIVES_TABLE_ROW3'|translate}}</th>
            <td class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesModHighRiskNonReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form"
                    [id]="'userInvestmentObjectivesModHighRiskNonReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesModHighRiskNonReg'"
                    [path]="'userInvestmentObjectivesModHighRiskNonReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
            <td *ngIf="shouldDisplayRegisteredAccount()" class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesModHighRiskReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form.get('registered')"
                    [id]="'userInvestmentObjectivesModHighRiskReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesModHighRiskReg'"
                    [path]="'userInvestmentObjectivesModHighRiskReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
        </tr>
        <tr>
            <th class="p-t-4">{{'USER_INVESTMENT_OBJECTIVES_TABLE_ROW4'|translate}}</th>
            <td class="p-t-0">
                <app-odc-field
                    [attr]="getSpeculativeStrategiesAttributes()"
                    [binding]="'userInvestmentObjectivesSpeculativeStrategiesNonReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form"
                    [id]="'userInvestmentObjectivesSpeculativeStrategiesNonReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesSpeculativeStrategiesNonReg'"
                    [path]="'userInvestmentObjectivesSpeculativeStrategiesNonReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
            <td *ngIf="shouldDisplayRegisteredAccount()" class="p-t-0">
                <app-odc-field
                    [attr]="{maxlength: 3}"
                    [binding]="'userInvestmentObjectivesSpeculativeStrategiesReg'"
                    [className]="''"
                    [errorOnSubmitOnly]="['min', 'max', 'investmentObjectives']"
                    [form]="form.get('registered')"
                    [id]="'userInvestmentObjectivesSpeculativeStrategiesReg'"
                    [integerFormat]="true"
                    [name]="'userInvestmentObjectivesSpeculativeStrategiesReg'"
                    [path]="'userInvestmentObjectivesSpeculativeStrategiesReg'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </td>
        </tr>
        </tbody>
    </table>

    <app-odc-field
        [attrDisabled]="true"
        [choices]="[{label: 'USER_INVESTMENT_INCLUDE_INVESTOR_PROFILE' | translate, id: 'includeInvestorProfile', binding:'includeInvestorProfile'}]"
        [form]="form"
        [hidden]="!isPleinEx() || isGpdOrSfd()"
        [path]="'includeInvestorProfile'"
        [stacked]="true"
        [submitted]="isFormSubmitted()"
        [type]="constants.FIELD_TYPES.CHECKBOX"
    ></app-odc-field>

    <app-questionnaire-btn [formRef]="formRef" [form]="form"></app-questionnaire-btn>
</form>
