import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '../../../components/services/modal.service';
import {TokenService} from '../../../components/services/token.service';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';
import {FormService} from '../../../components/services/form.service';
import {ApiEndpoints} from "../../../components/enums/api-endpoints.enum";

declare let $: any;

@Component({
    selector: 'app-modal-pdf',
    templateUrl: './modal-pdf.component.html',
    styleUrls: ['modal-pdf-component.css']
})

export class ModalPdfComponent implements OnInit, OnDestroy {

    loading = false;
    error: string = null;

    isClientEnglishSpeakingQuebecResident: boolean;
    isPdfLangActivate: boolean;

    protected subscriptions: any[] = [];

    private iframeSelector = '#modal-pdf .modal-body iframe';

    constructor(
        protected modalService: ModalService,
        private tokenService: TokenService,
        private formService: FormService,
        private cdRef: ChangeDetectorRef,
        @Inject(ODC_CONFIG) private config: IOdcConfig
    ) {
    }

    ngOnInit() {

        this.subscriptions.push(this.modalService.closeModalAlertEmitted.subscribe(() => {
            this.loading = false;
            this.error = null;
            setTimeout(() => {

                $(this.iframeSelector).unbind('load');

                $(this.iframeSelector).prop('src', 'about:blank');
            }, 0);
        }));

        this.subscriptions.push(this.modalService.errorgEmitted.subscribe((value: string) => {
            this.loading = false;
            this.error = value;
        }));

        this.subscriptions.push(this.modalService.pdfLinkEmitted.subscribe((isAdditional: boolean) => {
            this.loading = true;
            this.error = null;

            setTimeout(() => {
                this.isClientEnglishSpeakingQuebecResident = this.isEnglishSpeakingQuebecResident();

                this.cdRef.detectChanges();

                $(this.iframeSelector).unbind('load');

                $(this.iframeSelector).bind('load', () => {
                    this.loading = false;
                });

                const encodedRequestId = encodeURIComponent(this.formService.getForm().requestID);
                const formPath = isAdditional
                    ? `${ApiEndpoints.InternalPdfDownloadAdditionalForms}?encodedRequestId=${encodedRequestId}`
                    : `${ApiEndpoints.InternalPdfDownloadForms}?encodedRequestId=${encodedRequestId}`

                if (this.isPdfLangActivate && this.isClientEnglishSpeakingQuebecResident) {
                    $(this.iframeSelector).eq(0).prop('src', `${formPath}&lang=fr`).addClass('active');
                    $(this.iframeSelector).eq(1).prop('src', `${formPath}&lang=en`).removeClass('active');
                } else {
                    $(this.iframeSelector).prop('src', formPath);
                }

                // OCE-3466 - force loadind to true because bind load does not work
                if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
                    this.loading = false;
                }
            }, 0);
        }));

        this.subscriptions.push(this.modalService.pdfLoadingEmitted.subscribe((value: boolean) => {
            this.loading = value;
            this.error = null;
        }));

        setTimeout(() => {
            $(this.iframeSelector).prop('src', 'about:blank');
        }, 0);

        this.isClientEnglishSpeakingQuebecResident = this.isEnglishSpeakingQuebecResident();
        this.isPdfLangActivate = this.config.TOGGLE_PDF_LANG;
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }

    isEnglishSpeakingQuebecResident(): boolean {
        const englishSpeaking = this.formService.isClientEnglishSpeaking();
        const isQuebecResident = this.formService.isQuebecResident();

        return englishSpeaking && isQuebecResident;
    }
}
