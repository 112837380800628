import {
    AfterContentChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {BaseInformationComponent} from './base-information.component';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {ModalService} from '../../components/services/modal.service';
import {Router} from '@angular/router';

const MODAL_MODIFY_CURATORSHIP_CONTEXT = 'MODAL_MODIFY_CURATORSHIP_CONTEXT';

@Component({
    selector: 'app-account-holder-information',
    templateUrl: './account-holder-information.component.html',
    providers: [TranslatePipe]
})
export class AccountHolderInformationComponent extends BaseInformationComponent implements OnInit, AfterContentChecked, AfterViewInit {

    provinces: HtmlSelectKV[];
    companyName: string;
    federalBusinessNumber: string;
    provincialBusinessNumber: string;
    provinceOfBusinessNumber: string;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                private router: Router,
                private translatePipe: TranslatePipe,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService,
                protected modalService: ModalService,
                private cdr: ChangeDetectorRef) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.initRequester();

        this.form = this.fb.group({
            formLang: [this.formService.getForm().requesters[this.requesterIndex].formLang],
            gender: [this.formService.getForm().requesters[this.requesterIndex].gender, [this.requiredValidator('gender')]],
            userFirstName: [this.formService.getForm().requesters[this.requesterIndex].userFirstName, [this.requiredValidator('userFirstName')]],
            userLastName: [this.formService.getForm().requesters[this.requesterIndex].userLastName, [this.requiredValidator('userLastName')]],
            companyName: [this.formService.getForm().accountHolderCompanyName, [this.requiredValidator('accountHolderCompanyName')]],
            phoneGroup: this.fb.group({
                userHomePhone: [this.formService.getForm().requesters[this.requesterIndex].userHomePhone, [this.requiredValidator('userHomePhone'), VmdValidators.phone]],
                userOtherPhone: [this.formService.getForm().requesters[this.requesterIndex].userOtherPhone, [this.requiredValidator('userOtherPhone'), VmdValidators.phone]],
                userMobilePhone: [this.formService.getForm().requesters[this.requesterIndex].userMobilePhone, [this.requiredValidator('userMobilePhone'), VmdValidators.phone]]
            }, {validator: [VmdValidators.accountHolderPhonesMatchValidator, this.requiredGroupValidator(VmdValidators.accountHolderPhonesRequiredValidator)]}),
            userEmail: [this.formService.getForm().requesters[this.requesterIndex].userEmail, [this.requiredValidator('userEmail'), VmdValidators.email]],
            otherFirstName: [this.formService.getForm().otherFirstName, [this.requiredValidator('otherFirstName')]],
            otherLastName: [this.formService.getForm().otherLastName, [this.requiredValidator('otherLastName')]],
            userDOB: [this.formService.getForm().requesters[this.requesterIndex].userDOB],
            userSIN: [this.formService.getForm().requesters[this.requesterIndex].userSIN, [this.requiredValidator('userSIN'), VmdValidators.sin]],
            addressGroup: this.fb.group({
                homeAddress: this.fb.group({
                    userAddressStreet: [this.formService.getForm().requesters[this.requesterIndex].userAddressStreet,
                        [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('userAddressStreet'))]],
                    userAddressUnit: [this.formService.getForm().requesters[this.requesterIndex].userAddressUnit, [this.requiredValidator('userAddressUnit'), VmdValidators.alphanumericWithSpace]],
                    userAddressCity: [this.formService.getForm().requesters[this.requesterIndex].userAddressCity, [this.requiredValidator('userAddressCity')]],
                    userAddressProv: [this.formService.getForm().requesters[this.requesterIndex].userAddressProv, [this.requiredValidator('userAddressProv')]],
                    userAddressPostalCode: [this.formService.getForm().requesters[this.requesterIndex].userAddressPostalCode, [this.requiredValidator('userAddressPostalCode')]],
                    userAddressCountry: [this.formService.getForm().requesters[this.requesterIndex].userAddressCountry, [this.requiredValidator('userAddressCountry')]]
                }, {validator: [VmdValidators.homeAddressZipCodeValidator, this.requiredGroupValidator(VmdValidators.homeAddressZipCodeRequired)]}),
                mailingAddress: this.fb.group({
                    userAddressStreetSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressStreetSec,
                        [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('userAddressStreetSec'))]],
                    userAddressUnitSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressUnitSec, [this.requiredValidator('userAddressUnitSec'), VmdValidators.alphanumericWithSpace]],
                    userAddressCitySec: [this.formService.getForm().requesters[this.requesterIndex].userAddressCitySec, [this.requiredValidator('userAddressCitySec')]],
                    userAddressProvSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressProvSec, [this.requiredValidator('userAddressProvSec')]],
                    userAddressPostalCodeSec: [this.formService.getForm().requesters[this.requesterIndex].userAddressPostalCodeSec, [this.requiredValidator('userAddressPostalCodeSec')]],
                    userAddressCountrySec: [this.formService.getForm().requesters[this.requesterIndex].userAddressCountrySec, [this.requiredValidator('userAddressCountrySec')]]
                }, {validator: [VmdValidators.mailingAddressZipCodeValidator, this.requiredGroupValidator(VmdValidators.mailingAddressZipCodeRequired)]}),
            }, {validator: VmdValidators.addressMatchValidator}),
            businessNumberGroup: this.fb.group({
                federalBusinessNumber: [
                    this.formService.getForm().accountHolderFederalBusinessNumber && this.formService.getForm().accountHolderFederalBusinessNumber.substring(0, 9),
                    {
                        validators: [
                            Validators.minLength(9), Validators.maxLength(9),
                            VmdValidators.digit
                        ],
                        updateOn: 'blur'
                    }
                ],
                federalBusinessNumberExtension: [
                    this.formService.getForm().accountHolderFederalBusinessNumber && this.formService.getForm().accountHolderFederalBusinessNumber.substring(9),
                    {
                        validators: [
                            Validators.maxLength(6),
                            VmdValidators.alphanumeric
                        ],
                        updateOn: 'blur'
                    }
                ],
                provincialBusinessNumber: [this.formService.getForm().accountHolderProvincialBusinessNumber, [this.requiredValidator('accountHolderProvincialBusinessNumber')]],
                provinceOfBusinessNumber: [this.formService.getForm().accountHolderProvinceOfBusinessNumber, [this.requiredValidator('accountHolderProvinceOfBusinessNumber')]]
            }),
            fiscalPeriodEndDate: [this.formService.getForm().fiscalPeriodEndDate, [this.requiredValidator('fiscalPeriodEndDate'), VmdValidators.date, VmdValidators.dateInFuture]]
        });

        this.onChanges();

        this.listModelService.getListModel('provincesList', data => this.provinces = data);

        const mailingAddressHasValues = this.form.get('addressGroup.mailingAddress.userAddressStreetSec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressCitySec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressProvSec').value ||
            this.form.get('addressGroup.mailingAddress.userAddressPostalCodeSec').value;
        this.toggleMailingAddress(mailingAddressHasValues);

        this.updateControlEnabled(this.form.get('businessNumberGroup'), this.displayCompanyInformation());

        if (this.displayCompanyInformation()) {

            this.form.get('businessNumberGroup').setValidators(
                [this.requiredGroupValidatorOnSpecificOthersClientTypes(VmdValidators.provincialNumberAndProvinceRequired),
                    this.requiredGroupValidatorOnSpecificOthersClientTypes(VmdValidators.businessNumberRequired)]);

            this.form.get('businessNumberGroup.provincialBusinessNumber').setValidators([Validators.minLength(10), VmdValidators.digit]);
        }

        if (this.isOtherAccountEsQualitesTuteur()) {
            this.form.get('userDOB').setValidators([this.requiredValidator('userDOB'), VmdValidators.dateOfBirthChild, VmdValidators.date]);
        } else {
            this.form.get('userDOB').setValidators([this.requiredValidator('userDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.dateOfBirthYoung, VmdValidators.notADateOfBirthYoung, VmdValidators.date]);
        }

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe(context => {
            if (context === MODAL_MODIFY_CURATORSHIP_CONTEXT) {
                this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH')]);
            }
        }));
    }

    beforeShowDay = (date: any) => {
        const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return date.getUTCDate() === lastDayOfMonth.getUTCDate();
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();

        this.updateFederalBusinessNumberExtensionAvailability();
    }

    public displayFiscalPeriodEndDate(): boolean {
        const odcForm = this.formService.getForm();

        return odcForm.cltType === this.constants.CLT_TYPE_FIDUCIE
            || odcForm.cltType === this.constants.CLT_TYPE_ASSOCIATION
            || odcForm.cltType === this.constants.CLT_TYPE_COOPERATIVE
            || odcForm.cltType === this.constants.CLT_TYPE_CORPORATION
            || odcForm.cltType === this.constants.CLT_TYPE_CO_UNIQUE
            || odcForm.cltType === this.constants.CLT_TYPE_ORG_GOUV
            || odcForm.cltType === this.constants.CLT_TYPE_OSBL
            || odcForm.cltType === this.constants.CLT_TYPE_SOC_COMM
            || odcForm.cltType === this.constants.CLT_TYPE_SENC;
    }

    public displayCompanyName(): boolean {
        return this.displayCompanyInformation();
    }

    public displayCompanyInformation(): boolean {
        return true;
    }

    public displayAddress(): boolean {
        return true;
    }

    public displayOtherName(): boolean {
        return false;
    }

    public displayParentDobAndSin(): boolean {
        return false;
    }

    public displayChildDobAndSin(): boolean {
        return false;
    }

    public getDOBLabel(): string {
        return null;
    }

    // OCE-7827
    public displaySoleProprietorsSin(): boolean {
        return this.isPleinEx() && this.formService.getForm().cltType === VmdConstants.CLT_TYPE_CO_UNIQUE;
    }

    public getSINLabel(): string {
        if (this.isPleinEx() && this.formService.getForm().cltType === VmdConstants.CLT_TYPE_CO_UNIQUE) {
            return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_SOLE_PROPRIETOR_SIN';
        } else {
            return null;
        }
    }

    public getUserFirstNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FIRST_NAME';
    }

    public getUserLastNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LAST_NAME';
    }

    public getHomeAddressLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_ADDRESS_TITLE';
    }

    public getMailingAddressLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MAIL_ADDRESS_TITLE';
    }

    public getMailingAddressAddLabel(): string {
        return 'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_ADD';
    }

    public getMailingAddressRemoveLabel(): string {
        return 'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_REMOVE';
    }

    public getOtherFirstNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_FIRST_NAME';
    }

    public getOtherLastNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_LAST_NAME';
    }

    submit() {
        if (this.formService.clientIsResidentOfQuebec() && this.formService.isClientTypeOtherAccount()) {
            this.modalService.showCuratorshipResidentQuebecModal();
            return;
        }
        this.submitForm();
    }

    private onChanges() {

        this.form.get('businessNumberGroup.federalBusinessNumber').valueChanges.subscribe(numberValue => {

            this.updateFederalBusinessNumberExtensionAvailability();

            const numberControl = this.form.get('businessNumberGroup.federalBusinessNumber');
            const extensionControl = this.form.get('businessNumberGroup.federalBusinessNumberExtension');
            const extensionValue = extensionControl.value || '';

            const accountHolderFederalBusinessNumber = numberValue && !numberControl.errors
                ? numberValue + extensionValue
                : numberValue;

            this.formService.setFormProperty('accountHolderFederalBusinessNumber', accountHolderFederalBusinessNumber);
        });

        this.form.get('businessNumberGroup.federalBusinessNumberExtension').valueChanges.subscribe(extensionValue => {
            const numberControl = this.form.get('businessNumberGroup.federalBusinessNumber');
            const numberValue = numberControl.value || '';

            const accountHolderFederalBusinessNumber = numberValue && !numberControl.errors
                ? numberValue + extensionValue
                : numberValue;

            this.formService.setFormProperty('accountHolderFederalBusinessNumber', accountHolderFederalBusinessNumber);
        });
    }

    private updateFederalBusinessNumberExtensionAvailability(): void {
        const extensionControl = this.form.get('businessNumberGroup.federalBusinessNumberExtension');

        this.updateControlEnabled(extensionControl, this.isFederalBusinessNumberExtensionEnabled());
        if (extensionControl.disabled) {
            extensionControl.setValue('', {emitEvent: false});
        }
    }

    private isFederalBusinessNumberExtensionEnabled(): boolean {
        const groupControl = this.form.get('businessNumberGroup');
        const numberControl = this.form.get('businessNumberGroup.federalBusinessNumber');

        return groupControl.enabled && numberControl.value && !numberControl.errors;
    }
}
