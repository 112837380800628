import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {ModalService} from '../../components/services/modal.service';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {Functions} from '../../components/utils/functions';
import {WebException} from '../../components/models';

const MODAL_NAME_EMAIL = 'EMAIL';

@Component({
    selector: 'app-shareholder',
    templateUrl: './shareholder.component.html'
})
export class ShareholderComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;
    error: WebException = null;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe((context: string) => {
            if (context === MODAL_NAME_EMAIL) {
                this.form.get('userElectronicDocumentSecuritiesHolderIndicator').patchValue(false);
                this.formService.setFormProperty(this.getRequesterPath('userElectronicDocumentSecuritiesHolderIndicator'), false);
            }
        }));

        this.form = this.fb.group({
            userDisclaimerIndicator: [this.formService.getForm().requesters[this.requesterIndex].userDisclaimerIndicator, this.requiredValidator('userDisclaimerIndicator')],
            userReceiptDocumentSecuritiesHolderIndicator: [this.formService.getForm().requesters[this.requesterIndex].userReceiptDocumentSecuritiesHolderIndicator, this.requiredValidator('userReceiptDocumentSecuritiesHolderIndicator')],
            userElectronicDocumentSecuritiesHolderIndicator: [this.formService.getForm().requesters[this.requesterIndex].userElectronicDocumentSecuritiesHolderIndicator, this.requiredValidator('userElectronicDocumentSecuritiesHolderIndicator')],
            authorizeClientInfoPresence: [this.formService.getForm().requesters[this.requesterIndex].authorizeClientInfoPresence, [this.requiredValidator('authorizeClientInfoPresence')]]
        });

        this.updateControlsAvailability();
    }

    submit() {
        if (this.isPleinEx() && !this.formService.isPrintMode() &&
            this.formService.getForm().requesters[this.requesterIndex].userElectronicDocumentSecuritiesHolderIndicator &&
            Functions.isNullOrEmpty(this.formService.getForm().requesters[this.requesterIndex].userEmail)) {

            this.modalService.openModal(
                'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_TITLE',
                'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_DESC',
                '#modal-alert-personal-information',
                MODAL_NAME_EMAIL
            );
        } else {

            this.submitForm();
        }
    }

    submitForm() {
        super.submitForm();
    }

    isLegalShareHolderMsgWarning(): boolean {
        return this.formService.getForm().requesters[this.requesterIndex].legalShareHolderMsgWarning;

    }

    private updateControlsAvailability() {
        const beneficialOwnersShown = this.formService.isEligibleToShowCommunicationBeneficialOwnersSection();
        this.updateControlEnabled(this.form.get('userDisclaimerIndicator'), beneficialOwnersShown);
        this.updateControlEnabled(this.form.get('userReceiptDocumentSecuritiesHolderIndicator'), beneficialOwnersShown);
        this.updateControlEnabled(this.form.get('userElectronicDocumentSecuritiesHolderIndicator'), beneficialOwnersShown);

        this.updateControlEnabled(this.form.get('authorizeClientInfoPresence'), this.isGpdOrSfd());
    }
}
