import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {VmdValidators} from '../../../components/validators/vmd-validators';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';
import {VmdConstants} from '../../../components/constants/vmd-constants';

@Component({
    template: '',
})
export class BaseHoldingAccountRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    public fieldPrefix: string;
    protected entity;

    ngOnInit() {
        this.fieldPrefix = this.getFieldPrefix();

        this.form = this.fb.group(
            this.getFormControlsConfig()
        );

        this.form.get(this.getFieldPrefix() + 'HoldingAccount').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get(this.getFieldPrefix() + 'LinkedAccount'), value === false);
        });
        this.updateControlEnabled(this.form.get(this.getFieldPrefix() + 'LinkedAccount'), this.form.get(this.getFieldPrefix() + 'HoldingAccount').value === false);
        this.entity = this.formService.getForm().cltType;
    }

    private getFormControlsConfig(): { [key: string]: any } {
        const controlsConfig: { [key: string]: any } = {};

        if (this.hasActPeople()) {
            // OCE-6402
            controlsConfig[this.getFieldPrefix() + 'ActAmericanPeople'] = [
                this.formService.getForm().actAmericanPeople, [
                    Validators.required,
                    VmdValidators.digit,
                    Validators.min(0),
                    Validators.max(this.getMaxActPeople())
                ]
            ];
            controlsConfig[this.getFieldPrefix() + 'ActPeople'] = [
                this.formService.getForm().otherActPeople, [
                    Validators.required,
                    VmdValidators.digit,
                    Validators.min(0),
                    Validators.max(this.getMaxActPeople())
                ]
            ];
        }

        if (this.hasShareholders()) {
            controlsConfig[this.getFieldPrefix() + 'OneShareholder'] = [this.formService.getForm().otherOneShareholder, [this.requiredValidator('otherOneShareholder')]];
        }

        controlsConfig[this.getFieldPrefix() + 'HoldingAccount'] = [this.formService.getForm().otherHoldingAccount, [this.requiredValidator('otherHoldingAccount')]];
        controlsConfig[this.getFieldPrefix() + 'LinkedAccount'] = [this.formService.getForm().otherLinkedAccount, [this.requiredValidator('otherLinkedAccount')]];

        return controlsConfig;
    }

    isAnAssociate(): boolean {
        return this.entity === VmdConstants.CLT_TYPE_ASSOCIATION;
    }

    isAClubPerson(): boolean {
        return this.entity === VmdConstants.CLT_TYPE_CLUB;
    }

    isSenc(): boolean {
        return this.entity === VmdConstants.CLT_TYPE_SENC;
    }

    isSocComm(): boolean {
        return this.entity === VmdConstants.CLT_TYPE_SOC_COMM;
    }

    protected getPrefixedLabelKey(begin: string, end: string): string {
        return begin + '_' + this.getFieldPrefix().toUpperCase() + '_' + end;
    }

    public getFieldPrefix(): string {
        return null;
    }

    public getMaxActPeople(): number {
        return null;
    }

    public hasActPeople(): boolean {
        return null;
    }

    public hasLegActPeople(): boolean {
        return null;
    }

    public hasShareholders(): boolean {
        return null;
    }

}
