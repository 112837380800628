import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-field-list',
  templateUrl: './review-field-list.component.html'
})
export class ReviewFieldListComponent implements OnInit {

    @Input() label: string;
    @Input() values: string[];

    ngOnInit() {

    }

}
