export const I18N_BTN_MESSAGES_FR: any = {
    BTN_ABANDON: 'Abandonner',
    BTN_BACK: 'Page précédente',
    BTN_BACK_PLATFORM_DISNAT: 'Retourner au choix de plateforme',
    BTN_BEGIN: 'Faire une demande',
    BTN_CANCEL: 'Annuler',
    BTN_CHOOSE_DISNAT_CLASSIC: 'Sélectionner Disnat Classique',
    BTN_CHOOSE_DISNAT_DIRECT: 'Sélectionner Disnat Direct',
    BTN_CLOSE: 'Fermer',
    BTN_CONTINUE: 'Continuer',
    BTN_MODIFY: 'Modifier',
    BTN_CHANGE: 'Changer',
    BTN_EXTEND: 'Prolonger',
    BTN_MODIFY_ACCOUNT_TYPE: 'Modifier - Choix de compte',
    BTN_MODIFY_FINANCIAL_INFORMATION: 'Modifier - Informations financières',
    BTN_MODIFY_MARITAL_STATUS_AND_DESIGNATION: 'Modifier - État civil et désignation',
    BTN_MODIFY_OCCUPATION: 'Modifier - Occupation',
    BTN_MODIFY_PERSONAL_INFORMATION: 'Modifier - Informations personnelles',
    BTN_MODIFY_REGULATORY_QUESTIONS: 'Modifier - Questions réglementaires',
    BTN_MODIFY_REQUEST: 'Modifier la demande',
    BTN_MODIFY_TRANSFER_REQUEST: 'Modifier - Demande de transfert',
    BTN_NEXT: 'Continuer',
    BTN_PASSWORD_VALIDATION: 'Continuer',
    BTN_REMOVE_ACCOUNT: 'Retirer le compte',
    BTN_REMOVE_FILE: 'Retirer le fichier',
    BTN_SIGN: 'Signer les documents',
    BTN_SUBMIT: 'Soumettre',
    BTN_SEARCH: 'Rechercher',
    BTN_HELP_WARNING: 'Mise en garde',
    BTN_OK: 'OK'
};
