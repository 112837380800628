<form (submit)="submit()" [formGroup]="form" ngForm novalidate>

    <div *ngIf="isNotConjoint() || isConjointPleinEx()" class="m-b-5">

        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

        <h2>{{ 'ACCOUNT_CHOICE_TITLE' | translate }}</h2>

        <fieldset class="form-group">
            <legend class="sr-only"><b>{{ 'ACCOUNT_CHOICE_TITLE' | translate }}</b></legend>
            <div [ngClass]="{'panel panel-default panel-noborder' : !isGpdOrSfd()}" id="panelDefault">
                <div [ngClass]="{'panel-body' : !isGpdOrSfd()}">
                    <!--new account/add account-->
                    <ng-container *ngIf="!isRedocumented()">
                        <app-odc-field
                            [binding]="'newExistAct'"
                            [choices]="[
                            {label: (!isPleinEx() ? 'DCL_NEW_ACCOUNT' : 'NEW_ACCOUNT') | translate, value: constants.ACCOUNT_NEW_CLIENT},
                            {label: (!isPleinEx() ? 'DCL_ADD_ACCOUNT' : 'ADD_ACCOUNT') | translate, value: constants.ACCOUNT_ADD_ACCOUNT, templateRef: cltNo}]"
                            [errorOnSubmitOnly]="['pattern']"
                            [form]="form"
                            [id]="'newExistAct'"
                            [label]="'REQUEST_TYPE' | translate"
                            [name]="'newExistAct'"
                            [path]="'newExistAct'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </ng-container>
                    <ng-template #cltNo>
                        <div *ngIf="form.get('newExistAct').value === constants.ACCOUNT_ADD_ACCOUNT && !isPleinEx()"
                             class="m-l-4">

                            <!--client number-->
                            <app-odc-field
                                [attr]="{maxlength: 5}"
                                [binding]="'cltNo'"
                                [form]="form.get('addAccount')"
                                [id]="'cltNo'"
                                [label]="(!isPleinEx() ? 'DCL_CLIENT_NUMBER' : 'CLIENT_NUMBER') | translate"
                                [name]="'cltNo'"
                                [path]="'cltNo'"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.TEXT"
                            ></app-odc-field>
                        </div>
                    </ng-template>

                    <!--cash account/margin account-->
                    <div class="m-t-3">
                        <div id="account-types">
                            <app-odc-field
                                *ngIf="((isNotOther() && !isGpdOrSfd())
                                            || (!isNotOther() && isPleinEx() && showMarginAccountForOtherClientType()))
                                            && !(isGpdOrSfd() && isPleinEx() && !isNotOther())"
                                [binding]="'actType'"
                                [choices]="[{label: 'CASH_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_CASH, helpRefFr: helpCashAccount, helpRefEn: helpCashAccount},
                                            {label: 'MARGIN_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_MARGIN, hidden: !showMarginAccount(), helpRefFr: helpMarginAccount, helpRefEn: helpMarginAccount}]"
                                [form]="form"
                                [id]="'actType'"
                                [label]="'ACCOUNT_TYPE' | translate"
                                [name]="'actType'"
                                [path]="'actType'"
                                [stacked]="true"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.RADIO"
                            ></app-odc-field>

                            <app-odc-field
                                *ngIf="(isNotOther() && (isGpdOrSfd()) && !showMarginAccount())
                                            || (!isNotOther() && isPleinEx() && !showMarginAccountForOtherClientType())
                                            || (isGpdOrSfd() && isPleinEx() && !isNotOther())"
                                [binding]="'actType'"
                                [choices]="[{label: 'CASH_ACCOUNT' | translate, value: constants.ACCOUNT_TYPE_CASH, helpRefFr: helpCashAccount, helpRefEn: helpCashAccount}]"
                                [form]="form"
                                [id]="'actType'"
                                [label]="'ACCOUNT_TYPE' | translate"
                                [name]="'actType'"
                                [path]="'actType'"
                                [stacked]="true"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.RADIO"
                            ></app-odc-field>
                        </div>
                        <div *ngIf="showMarginAccount() && form.get('actType').value === constants.ACCOUNT_TYPE_MARGIN"
                             class="m-l-4">

                            <!--optionAct/shortSellAct-->
                            <app-odc-field
                                [choices]="[
                                    {label: (!isPleinEx() ? 'DCL_WITH_OPTIONS' : 'WITH_OPTIONS')  | translate, id: 'optionAct', helpRefFr: helpMarginAccountWithOptions, helpRefEn: helpMarginAccountWithOptions},
                                    {label: (!isPleinEx() ? 'DCL_WITH_SHORT_SELLING' : 'WITH_SHORT_SELLING') | translate, id: 'shortSellAct', hidden: showMarginAccountWithShortSelling(), helpRefFr: helpMarginAccountWithShortSelling, helpRefEn: helpMarginAccountWithShortSelling}]"
                                [form]="form.get('marginAccount')"
                                [label]="(!isPleinEx() ? 'DCL_ACCOUNT_TYPE' : 'ACCOUNT_TYPE') | translate"
                                [stacked]="true"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.CHECKBOX"
                            ></app-odc-field>
                        </div>
                        <div
                            *ngIf="showManagementTypeChoices()"
                            class="m-1-4">
                            <app-odc-field
                                [binding]="'cashAccountMgntType'"
                                [choices]="[
                                {
                                    label: 'MGNT_TYPE_COM' | translate,
                                    value: constants.MGNT_TYPE_COM,
                                    hidden: isGpdOrSfd()
                                },
                                {
                                    label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                    value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                }]"
                                [errorOnSubmitOnly]="['invalidMgntType']"
                                [form]="form"
                                [id]="'cashAccountMgntType'"
                                [label]="'MGNT_TYPE' | translate"
                                [name]="'cashAccountMgntType'"
                                [path]="'cashAccountMgntType'"
                                [stacked]="true"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.RADIO"
                            ></app-odc-field>
                        </div>
                    </div>

                    <div class="m-t-3">
                        <!--currency-->
                        <app-odc-field
                            [binding]="'currency'"
                            [choices]="[{label: 'CA_ONLY' | translate, value: constants.CURRENCY_CAD}, {label: 'US_AND_CA' | translate, value: constants.CURRENCY_USD}]"
                            [form]="form"
                            [id]="'currency'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'currency'"
                            [path]="'currency'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>

                    <div *ngIf="isSfd()" class="m-t-3">

                        <h3>
                            {{ 'ACCOUNT_CHOICE_FIDUCIARY_TRANSACTION_ACCOUNTS_TITLE' | translate }}
                            <app-help [templateRefEn]="helpFiduciaryTranactionAccountsEn"
                                      [templateRefFr]="helpFiduciaryTranactionAccountsFr"></app-help>
                        </h3>

                        <div class="row align-items-baseline" formGroupName="fiduciaryTransactionAccountsCad">
                            <div class="col-md-10">
                                <app-odc-field
                                    [attr]="{maxlength: 7}"
                                    [binding]="'accountNumberCad'"
                                    [form]="form.get('fiduciaryTransactionAccountsCad')"
                                    [id]="'accountNumberCad'"
                                    [label]="isCad() ? ('SFD_ACCOUNT_NUMBER' | translate) : ('SFD_ACCOUNT_NUMBER_CAD' | translate)"
                                    [name]="'accountNumberCad'"
                                    [numericFormat]="true"
                                    [path]="'accountNumberCad'"
                                    [submitted]="isFormSubmitted()"
                                    [type]="constants.FIELD_TYPES.TEXT"
                                ></app-odc-field>
                            </div>
                            <div class="col-md-6">
                                <app-odc-field
                                    [attr]="{maxlength: 2}"
                                    [binding]="'productNumberCad'"
                                    [form]="form.get('fiduciaryTransactionAccountsCad')"
                                    [id]="'productNumberCad'"
                                    [label]="'SFD_PRODUCT_NUMBER' | translate"
                                    [name]="'productNumberCad'"
                                    [numericFormat]="true"
                                    [path]="'productNumberCad'"
                                    [submitted]="isFormSubmitted()"
                                    [type]="constants.FIELD_TYPES.TEXT"
                                ></app-odc-field>
                            </div>
                        </div>

                        <div *ngIf="isUsd()" class="row align-items-baseline"
                             formGroupName="fiduciaryTransactionAccountsUsd">
                            <div class="col-md-10">
                                <app-odc-field
                                    [attr]="{maxlength: 7}"
                                    [binding]="'accountNumberUsd'"
                                    [form]="form.get('fiduciaryTransactionAccountsUsd')"
                                    [id]="'accountNumberUsd'"
                                    [label]="'SFD_ACCOUNT_NUMBER_USD' | translate"
                                    [name]="'accountNumberUsd'"
                                    [numericFormat]="true"
                                    [path]="'accountNumberUsd'"
                                    [submitted]="isFormSubmitted()"
                                    [type]="constants.FIELD_TYPES.TEXT"
                                ></app-odc-field>
                            </div>
                            <div class="col-md-6">
                                <app-odc-field
                                    [attr]="{maxlength: 2}"
                                    [binding]="'productNumberUsd'"
                                    [form]="form.get('fiduciaryTransactionAccountsUsd')"
                                    [id]="'productNumberUsd'"
                                    [label]="'SFD_PRODUCT_NUMBER' | translate"
                                    [name]="'productNumberUsd'"
                                    [numericFormat]="true"
                                    [path]="'productNumberUsd'"
                                    [submitted]="isFormSubmitted()"
                                    [type]="constants.FIELD_TYPES.TEXT"
                                ></app-odc-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>

    <div *ngIf="isConjointPeopleNumberValid() && isNotOther() && showRegisteredAccountTitleForGpd">
        <h2>{{ 'REGISTERED_ACCOUNT_CHOICE_TITLE' | translate }}</h2>
    </div>

    <fieldset *ngIf="isNotOther()" class="form-group">
        <legend class="sr-only"><b>{{ 'REGISTERED_ACCOUNT_CHOICE_TITLE' | translate }}</b></legend>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--TFSA-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addTFSA'"
                           aria-describedby="tfsa-account-desc"
                           formControlName="addTFSA"
                           id="addTFSA"
                           name="addTFSA"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'TFSA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpTFSA" [templateRefFr]="helpTFSA"></app-help>
            </div>
            <div class="m-l-3" id="tfsa-account-desc">{{ 'TFSA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addTFSA').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- tfsaAccountMgntType-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'tfsaAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('tfsaAccount')"
                                   [id]="'tfsaAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'tfsaAccountMgntType'"
                                   [path]="'tfsaAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--tfsa currency-->
                    <app-odc-field
                        [binding]="'tfsaCurrency'"
                        [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                        [form]="form.get('tfsaAccount')"
                        [id]="'tfsaCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'tfsaCurrency'"
                        [path]="'tfsaCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                </div>
            </div>
        </div>

        <div *ngIf="this.isNotConjointCtlType && this.config.TOGGLE_CELI_APP" class="m-t-3">
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addFHSA'"
                           aria-describedby="fhsa-account-desc"
                           formControlName="addFHSA"
                           id="addFHSA"
                           name="addFHSA"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'FHSA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpFHSA" [templateRefFr]="helpFHSA"></app-help>
            </div>
            <div class="m-l-3" id="fhsa-account-desc">{{ 'FHSA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="showAddFhsaButton()" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- fhsaAccountMgntType-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'fhsaAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('fhsaAccount')"
                                   [id]="'fhsaAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'fhsaAccountMgntType'"
                                   [path]="'fhsaAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--hsfa currency-->
                    <app-odc-field
                        [binding]="'fhsaCurrency'"
                        [choices]="
                            this.config.TOGGLE_CELI_APP_USD ?
                            [
                                {
                                    label: 'CA_ONLY' | translate,
                                    value: constants.CURRENCY_CAD
                                },
                                {
                                    label: 'US_AND_CA' | translate,
                                    value: constants.CURRENCY_USD
                                }
                            ]
                            :
                             [
                                {
                                    label: 'CA_ONLY' | translate,
                                    value: constants.CURRENCY_CAD
                                }
                            ]"
                        [form]="form.get('fhsaAccount')"
                        [id]="'fhsaCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'fhsaCurrency'"
                        [path]="'fhsaCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <app-odc-field
                        [choices]="[
                            {
                                label: 'FHSA_ELIGIBILITY_CONFIRMATION' | translate,
                                id: 'fhsaEligibility',
                                binding: 'fhsaEligibility',
                                helpRefFr: helpFHSAEligibility,
                                helpRefEn: helpFHSAEligibility
                            }
                        ]"
                        [form]="form.get('fhsaAccount')"
                        [label]="'FHSA_ELIGIBILITY' | translate"
                        [path]="'fhsaEligibility'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.CHECKBOX"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--RRSP-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addRRSP'"
                           aria-describedby="rrsp-account-desc"
                           formControlName="addRRSP"
                           id="addRRSP"
                           name="addRRSP"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'RRSP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpRRSP" [templateRefFr]="helpRRSP"></app-help>
            </div>
            <div class="m-l-3" id="rrsp-account-desc">{{ 'RRSP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRRSP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- rrsp management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'rrspAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('rrspAccount')"
                                   [id]="'rrspAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'rrspAccountMgntType'"
                                   [path]="'rrspAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--rrsp currency-->
                    <app-odc-field
                        [binding]="'rrspCurrency'"
                        [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                        [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        [form]="form.get('rrspAccount')"
                        [id]="'rrspCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'rrspCurrency'"
                        [path]="'rrspCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--rrsp Annuitant-->
                    <app-odc-field
                        [binding]="'rrspAnnuitant'"
                        [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                        [form]="form.get('rrspAccount')"
                        [id]="'rrspAnnuitant'"
                        [label]="'RRSP_ANNUITANT' | translate"
                        [name]="'rrspAnnuitant'"
                        [path]="'rrspAnnuitant'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddRrspSecButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addRRSPSec').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'RRSP_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddRrspSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrsp management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [binding]="'rrspAccountMgntTypeSec'"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                                       [form]="form.get('rrspAccountSec')"
                                       [id]="'rrspAccountMgntTypeSec'"
                                       [label]="'MGNT_TYPE' | translate"
                                       [name]="'rrspAccountMgntTypeSec'"
                                       [path]="'rrspAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [submitted]="isFormSubmitted()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--rrsp currency-->
                        <app-odc-field
                            [binding]="'rrspCurrencySec'"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                            [form]="form.get('rrspAccountSec')"
                            [id]="'rrspCurrencySec'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'rrspCurrencySec'"
                            [path]="'rrspCurrencySec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--rrsp Annuitant-->
                        <app-odc-field
                            [binding]="'rrspAnnuitantSec'"
                            [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [form]="form.get('rrspAccountSec')"
                            [id]="'rrspAnnuitantSec'"
                            [label]="'RRSP_ANNUITANT' | translate"
                            [name]="'rrspAnnuitantSec'"
                            [path]="'rrspAnnuitantSec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addRRSPSec').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'RRSP_DELETE'|translate }}
                    </a>
                </div>
            </div>

            <div *ngIf="showAddRrspTerButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addRRSPTer').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'RRSP_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddRrspTerSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrsp management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [binding]="'rrspAccountMgntTypeTer'"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                                       [form]="form.get('rrspAccountTer')"
                                       [id]="'rrspAccountMgntTypeTer'"
                                       [label]="'MGNT_TYPE' | translate"
                                       [name]="'rrspAccountMgntTypeTer'"
                                       [path]="'rrspAccountMgntTypeTer'"
                                       [stacked]="true"
                                       [submitted]="isFormSubmitted()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--rrsp currency-->
                        <app-odc-field
                            [binding]="'rrspCurrencyTer'"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                            [form]="form.get('rrspAccountTer')"
                            [id]="'rrspCurrencyTer'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'rrspCurrencyTer'"
                            [path]="'rrspCurrencyTer'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--rrsp Annuitant-->
                        <app-odc-field
                            [binding]="'rrspAnnuitantTer'"
                            [choices]="[{label: 'RRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [form]="form.get('rrspAccountTer')"
                            [id]="'rrspAnnuitantTer'"
                            [label]="'RRSP_ANNUITANT' | translate"
                            [name]="'rrspAnnuitantTer'"
                            [path]="'rrspAnnuitantTer'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addRRSPTer').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'RRSP_DELETE'|translate }}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--LIRA-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addLIRA'"
                           aria-describedby="lira-account-desc"
                           formControlName="addLIRA"
                           id="addLIRA"
                           name="addLIRA"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'LIRA_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpLIRA" [templateRefFr]="helpLIRA"></app-help>
            </div>
            <div class="m-l-3" id="lira-account-desc">{{ 'LIRA_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addLIRA').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- lira management type-->
                    <app-odc-field
                        *ngIf="showManagementTypeChoices()"
                        [binding]="'liraAccountMgntType'"
                        [choices]="[
                        {
                            label: 'MGNT_TYPE_COM' | translate,
                            value: constants.MGNT_TYPE_COM,
                            hidden: isGpdOrSfd()
                        },
                        {
                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                        }]"
                        [errorOnSubmitOnly]="['invalidMgntType']"
                        [form]="form.get('liraAccount')"
                        [id]="'liraAccountMgntType'"
                        [label]="'MGNT_TYPE' | translate"
                        [name]="'liraAccountMgntType'"
                        [path]="'liraAccountMgntType'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--lira currency-->
                    <app-odc-field
                        [binding]="'liraCurrency'"
                        [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                        [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        [form]="form.get('liraAccount')"
                        [id]="'liraCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'liraCurrency'"
                        [path]="'liraCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--lira jurisdiction-->
                    <app-odc-field
                        [binding]="'liraJurisdiction'"
                        [defaultOptionLabel]="'LIRA_JURISDICTION_DEFAULT_OPTION' | translate"
                        [errorOnSubmitOnly]="['notSameJuridiction']"
                        [form]="form.get('liraAccount')"
                        [id]="'liraJurisdiction'"
                        [label]="'LIRA_JURISDICTION' | translate"
                        [list]="jurisdictions"
                        [name]="'liraJurisdiction'"
                        [path]="'liraJurisdiction'"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.SELECT"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddLiraSecButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addLIRASec').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'LIRA_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddLiraSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- lira management type-->
                        <app-odc-field
                            *ngIf="showManagementTypeChoices()"
                            [binding]="'liraAccountMgntTypeSec'"
                            [choices]="[
                            {
                                label: 'MGNT_TYPE_COM' | translate,
                                value: constants.MGNT_TYPE_COM,
                                hidden: isGpdOrSfd()
                            },
                            {
                                label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                            }]"
                            [form]="form.get('liraAccountSec')"
                            [id]="'liraAccountMgntTypeSec'"
                            [label]="'MGNT_TYPE' | translate"
                            [name]="'liraAccountMgntTypeSec'"
                            [path]="'liraAccountMgntTypeSec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--lira currency-->
                        <app-odc-field
                            [binding]="'liraCurrencySec'"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                            [form]="form.get('liraAccountSec')"
                            [id]="'liraCurrencySec'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'liraCurrencySec'"
                            [path]="'liraCurrencySec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--lira jurisdiction-->
                        <app-odc-field
                            [binding]="'liraJurisdictionSec'"
                            [defaultOptionLabel]="'LIRA_JURISDICTION_DEFAULT_OPTION' | translate"
                            [errorOnSubmitOnly]="['notSameJuridiction']"
                            [form]="form.get('liraAccountSec')"
                            [id]="'liraJurisdictionSec'"
                            [label]="'LIRA_JURISDICTION' | translate"
                            [list]="jurisdictions"
                            [name]="'liraJurisdictionSec'"
                            [path]="'liraJurisdictionSec'"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.SELECT"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addLIRASec').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'LIRA_DELETE'|translate }}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--RRIF-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addRRIF'"
                           aria-describedby="rrif-account-desc"
                           formControlName="addRRIF"
                           id="addRRIF"
                           name="addRRIF"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'RRIF_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpRRIF" [templateRefFr]="helpRRIF"></app-help>
            </div>
            <div class="m-l-3" id="rrif-account-desc">{{ 'RRIF_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRRIF').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- rrif management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'rrifAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('rrifAccount')"
                                   [id]="'rrifAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'rrifAccountMgntType'"
                                   [path]="'rrifAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <app-odc-field
                        [binding]="'rrifCurrency'"
                        [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                        [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                        [form]="form.get('rrifAccount')"
                        [id]="'rrifCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'rrifCurrency'"
                        [path]="'rrifCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--rrif Annuitant-->
                    <app-odc-field *ngIf="isPleinEx()"
                                   [binding]="'rrifAnnuitant'"
                                   [choices]="[{label: 'RRIF_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRIF_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                                   [form]="form.get('rrifAccount')"
                                   [id]="'rrifAnnuitant'"
                                   [label]="'RRIF_ANNUITANT' | translate"
                                   [name]="'rrifAnnuitant'"
                                   [path]="'rrifAnnuitant'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddRrifSecButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addRRIFSec').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'RRIF_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddRrifSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- rrif management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [binding]="'rrifAccountMgntTypeSec'"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                                       [form]="form.get('rrifAccountSec')"
                                       [id]="'rrifAccountMgntTypeSec'"
                                       [label]="'MGNT_TYPE' | translate"
                                       [name]="'rrifAccountMgntTypeSec'"
                                       [path]="'rrifAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [submitted]="isFormSubmitted()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <app-odc-field
                            [binding]="'rrifCurrencySec'"
                            [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                            [errorOnSubmitOnly]="['onlyOneAccountTypeInUSD']"
                            [form]="form.get('rrifAccountSec')"
                            [id]="'rrifCurrencySec'"
                            [label]="'ACCOUNT_CURRENCY' | translate"
                            [name]="'rrifCurrencySec'"
                            [path]="'rrifCurrencySec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--rrif Annuitant-->
                        <app-odc-field
                            [binding]="'rrifAnnuitantSec'"
                            [choices]="[{label: 'RRIF_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'RRIF_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [form]="form.get('rrifAccountSec')"
                            [id]="'rrifAnnuitantSec'"
                            [label]="'RRIF_ANNUITANT' | translate"
                            [name]="'rrifAnnuitantSec'"
                            [path]="'rrifAnnuitantSec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addRRIFSec').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'RRIF_DELETE'|translate }}
                    </a>
                </div>
            </div>
        </div>

        <div *ngIf="isNotConjoint()" class="m-t-3">
            <!--LIF-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addLIF'"
                           aria-describedby="lif-account-desc"
                           formControlName="addLIF"
                           id="addLIF"
                           name="addLIF"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'LIF_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpLIF" [templateRefFr]="helpLIF"></app-help>
            </div>
            <div class="m-l-3" id="lif-account-desc">{{ 'LIF_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addLIF').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- lif management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'lifAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('lifAccount')"
                                   [id]="'lifAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'lifAccountMgntType'"
                                   [path]="'lifAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <app-odc-field
                        [binding]="'lifCurrency'"
                        [choices]="[
                            {
                                label: 'CA_ONLY' | translate,
                                value: constants.CURRENCY_CAD
                            },
                            {
                                label: 'US_AND_CA' | translate,
                                value: constants.CURRENCY_USD
                            }
                        ]"
                        [form]="form.get('lifAccount')"
                        [id]="'lifCurrency'"
                        [label]="'ACCOUNT_CURRENCY' | translate"
                        [name]="'lifCurrency'"
                        [path]="'lifCurrency'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>


                    <!--lif jurisdiction-->
                    <app-odc-field
                        [binding]="'lifJurisdiction'"
                        [defaultOptionLabel]="'LIF_JURISDICTION_DEFAULT_OPTION' | translate"
                        [form]="form.get('lifAccount')"
                        [id]="'lifJurisdiction'"
                        [label]="'LIF_JURISDICTION' | translate"
                        [list]="jurisdictions"
                        [name]="'lifJurisdiction'"
                        [path]="'lifJurisdiction'"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.SELECT"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isConjointPeopleNumberValid() && showREEEOptionForGpd" class="m-t-3">
            <!--RESP-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addRESP'"
                           aria-describedby="resp-account-desc"
                           formControlName="addRESP"
                           id="addRESP"
                           name="addRESP"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'RESP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpRESP" [templateRefFr]="helpRESP"></app-help>
            </div>
            <div class="m-l-3" id="resp-account-desc">{{ 'RESP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addRESP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- resp management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'respAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('respAccount')"
                                   [id]="'respAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'respAccountMgntType'"
                                   [path]="'respAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--resp type-->
                    <app-odc-field
                        [binding]="'respType'"
                        [choices]="[{label: 'RESP_TYPE_FAMILY' | translate, value: constants.RESP_TYPE_FAMILY, templateRef: respNumberOfBeneficiaries}, {label: 'RESP_TYPE_INDIVIDUAL' | translate, value: constants.RESP_TYPE_INDIVIDUAL}]"
                        [form]="form.get('respAccount')"
                        [id]="'respType'"
                        [label]="'RESP_TYPE' | translate"
                        [name]="'respType'"
                        [path]="'respType'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <ng-template #respNumberOfBeneficiaries>
                        <div *ngIf="showRespNumberOfBeneficiariesField()" class="m-l-4">
                            <!--respNumberOfBeneficiaries-->
                            <app-odc-field
                                [attr]="{maxlength: 2}"
                                [binding]="'respNumberOfBeneficiaries'"
                                [form]="form.get('respAccount.respTypeFamily')"
                                [id]="'respNumberOfBeneficiaries'"
                                [integerFormat]="true"
                                [label]="'RESP_NUMBER_OF_BENEFICIARIES' | translate"
                                [name]="'respNumberOfBeneficiaries'"
                                [path]="'respNumberOfBeneficiaries'"
                                [submitted]="isFormSubmitted()"
                                [type]="constants.FIELD_TYPES.TEXT"
                            ></app-odc-field>
                        </div>
                    </ng-template>

                    <!--resp subscriber-->
                    <app-odc-field
                        [binding]="'respIsSubscriberResponsible'"
                        [choices]="[{label: 'GLOBAL_YES' | translate, value: true}, {label: 'GLOBAL_NO' | translate, value: false}]"
                        [form]="form.get('respAccount')"
                        [id]="'respIsSubscriberResponsible'"
                        [label]="'RESP_IS_SUBSCRIBER_RESPONSIBLE' | translate"
                        [name]="'respIsSubscriberResponsible'"
                        [path]="'respIsSubscriberResponsible'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>
                </div>
            </div>
        </div>

        <div *ngIf="isPleinEx() && formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU" class="m-t-3">
            <!--FTARRSP-->
            <div>
                <label class="c-input c-checkbox">
                    <input [appFormBinding]="'addFTARRSP'"
                           aria-describedby="ftarrsp-account-desc"
                           formControlName="addFTARRSP"
                           id="addFTARRSP"
                           name="addFTARRSP"
                           type="checkbox">
                    <span class="c-indicator"></span>
                    {{ 'FTARRSP_ACCOUNT' | translate }}
                </label>
                <app-help [templateRefEn]="helpFTARRSP" [templateRefFr]="helpFTARRSP"></app-help>
            </div>
            <div class="m-l-3" id="ftarrsp-account-desc">{{ 'FTARRSP_ACCOUNT_DESC' | translate }}</div>

            <div *ngIf="form.get('addFTARRSP').value" class="m-l-3 panel panel-default panel-noborder">
                <div class="panel-body">
                    <!-- FTARRSP management type-->
                    <app-odc-field *ngIf="showManagementTypeChoices()"
                                   [binding]="'ftarrspAccountMgntType'"
                                   [choices]="[
                                   {
                                        label: 'MGNT_TYPE_COM' | translate,
                                        value: constants.MGNT_TYPE_COM,
                                        hidden: isGpdOrSfd()
                                   },
                                   {
                                        label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                        value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                   }]"
                                   [errorOnSubmitOnly]="['invalidMgntType']"
                                   [form]="form.get('ftarrspAccount')"
                                   [id]="'ftarrspAccountMgntType'"
                                   [label]="'MGNT_TYPE' | translate"
                                   [name]="'ftarrspAccountMgntType'"
                                   [path]="'ftarrspAccountMgntType'"
                                   [stacked]="true"
                                   [submitted]="isFormSubmitted()"
                                   [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--FTARRSP Annuitant-->
                    <app-odc-field
                        [binding]="'ftarrspAnnuitant'"
                        [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                        [form]="form.get('ftarrspAccount')"
                        [id]="'ftarrspAnnuitant'"
                        [label]="'FTARRSP_ANNUITANT' | translate"
                        [name]="'ftarrspAnnuitant'"
                        [path]="'ftarrspAnnuitant'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>

                    <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                    <app-odc-field
                        [binding]="'ftarrspRevocableBeneficiaryIndicator'"
                        [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                        [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                        [form]="form.get('ftarrspAccount')"
                        [id]="'ftarrspRevocableBeneficiaryIndicator'"
                        [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                        [name]="'ftarrspRevocableBeneficiaryIndicator'"
                        [path]="'ftarrspRevocableBeneficiaryIndicator'"
                        [stacked]="true"
                        [submitted]="isFormSubmitted()"
                        [type]="constants.FIELD_TYPES.RADIO"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showAddFtarrspSecButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addFTARRSPSec').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'FTARRSP_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddFtarrspSecSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- FTARRSP management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [binding]="'ftarrspAccountMgntTypeSec'"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                                       [form]="form.get('ftarrspAccountSec')"
                                       [id]="'ftarrspAccountMgntTypeSec'"
                                       [label]="'MGNT_TYPE' | translate"
                                       [name]="'ftarrspAccountMgntTypeSec'"
                                       [path]="'ftarrspAccountMgntTypeSec'"
                                       [stacked]="true"
                                       [submitted]="isFormSubmitted()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--FTARRSP Annuitant-->
                        <app-odc-field
                            [binding]="'ftarrspAnnuitantSec'"
                            [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [form]="form.get('ftarrspAccountSec')"
                            [id]="'ftarrspAnnuitantSec'"
                            [label]="'FTARRSP_ANNUITANT' | translate"
                            [name]="'ftarrspAnnuitantSec'"
                            [path]="'ftarrspAnnuitantSec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                        <app-odc-field
                            [binding]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                            [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                            [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                            [form]="form.get('ftarrspAccountSec')"
                            [id]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                            [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                            [name]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                            [path]="'ftarrspRevocableBeneficiaryIndicatorSec'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addFTARRSPSec').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'FTARRSP_DELETE'|translate }}
                    </a>
                </div>
            </div>

            <div *ngIf="showAddFtarrspTerButton()" class="m-l-3 m-b-1">
                <a (click)="form.get('addFTARRSPTer').setValue(true)" class="lien-sans-soulignement"
                   href="javascript:void(0)">
                    <span class="icon-circle-plus"></span>
                    {{ 'FTARRSP_ADD'|translate }}
                </a>
            </div>

            <div *ngIf="showAddFtarrspTerSection()">
                <div class="m-l-3 panel panel-default panel-noborder">
                    <div class="panel-body">
                        <!-- FTARRSP management type-->
                        <app-odc-field *ngIf="showManagementTypeChoices()"
                                       [binding]="'ftarrspAccountMgntTypeTer'"
                                       [choices]="[
                                       {
                                            label: 'MGNT_TYPE_COM' | translate,
                                            value: constants.MGNT_TYPE_COM,
                                            hidden: isGpdOrSfd()
                                       },
                                       {
                                            label: (isSsd() ? 'SECURITIES_PORTFOLIOS' : (isGpdOrSfd() ? 'MGNT_TYPE_DISCRETIONARY' : 'MGNT_TYPE_MAND')) | translate,
                                            value: isGpdOrSfd() ? constants.MGNT_TYPE_COM : constants.MGNT_TYPE_MAND
                                       }]"
                                       [errorOnSubmitOnly]="['invalidMgntType']"
                                       [form]="form.get('ftarrspAccountTer')"
                                       [id]="'ftarrspAccountMgntTypeTer'"
                                       [label]="'MGNT_TYPE' | translate"
                                       [name]="'ftarrspAccountMgntTypeTer'"
                                       [path]="'ftarrspAccountMgntTypeTer'"
                                       [stacked]="true"
                                       [submitted]="isFormSubmitted()"
                                       [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--FTARRSP Annuitant-->
                        <app-odc-field
                            [binding]="'ftarrspAnnuitantTer'"
                            [choices]="[{label: 'FTARRSP_ANNUITANT_PERSONAL' | translate, value: constants.ANNUITANT_PERSONAL}, {label: 'FTARRSP_ANNUITANT_SPOUSAL' | translate, value: constants.ANNUITANT_SPOUSE}]"
                            [form]="form.get('ftarrspAccountTer')"
                            [id]="'ftarrspAnnuitantTer'"
                            [label]="'FTARRSP_ANNUITANT' | translate"
                            [name]="'ftarrspAnnuitantTer'"
                            [path]="'ftarrspAnnuitantTer'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>

                        <!--FTARRSP ftarrspRevocableBeneficiaryIndicator-->
                        <app-odc-field
                            [binding]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                            [choices]="[{label: 'FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE' | translate, value: true}, {label: 'FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE' | translate, value: false}]"
                            [errorOnSubmitOnly]="['maxTwoSameDesignationOfBeneficiary']"
                            [form]="form.get('ftarrspAccountTer')"
                            [id]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                            [label]="'FTARRSP_REVOCABLE_BENEFICIARY' | translate"
                            [name]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                            [path]="'ftarrspRevocableBeneficiaryIndicatorTer'"
                            [stacked]="true"
                            [submitted]="isFormSubmitted()"
                            [type]="constants.FIELD_TYPES.RADIO"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-l-3 m-b-1">
                    <a (click)="form.get('addFTARRSPTer').setValue(false)" class="lien-sans-soulignement"
                       href="javascript:void(0)">
                        <span class="icon-close rouge"></span>
                        {{ 'FTARRSP_DELETE'|translate }}
                    </a>
                </div>
            </div>
        </div>
    </fieldset>

    <app-questionnaire-btn [formRef]="formRef" [form]="form" [hideBackBtn]="hideBackBtn()"></app-questionnaire-btn>
</form>


<ng-template #helpCashAccount>
    <h2>{{ 'CASH_ACCOUNT'|translate }}</h2>
    <p>{{ (!isPleinEx() ? 'CASH_ACCOUNT_HELP_DCL' : 'CASH_ACCOUNT_HELP')|translate }}</p>
</ng-template>
<ng-template #helpMarginAccount>
    <h2>{{ 'MARGIN_ACCOUNT'|translate }}</h2>
    <p>{{ 'MARGIN_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpMarginAccountWithOptions>
    <h2>{{ 'MARGIN_ACCOUNT_WITH_OPTIONS'|translate }}</h2>
    <p>{{ 'WITH_OPTIONS_HELP'|translate }}</p>
</ng-template>
<ng-template #helpMarginAccountWithShortSelling>
    <h2>{{ 'MARGIN_ACCOUNT_WITH_SHORT_SELLING'|translate }}</h2>
    <p>{{ 'WITH_SHORT_SELLING_HELP'|translate }}</p>
</ng-template>
<ng-template #helpRRSP>
    <h2>{{ 'RRSP_ACCOUNT'|translate }}</h2>
    <p>{{ 'RRSP_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpTFSA>
    <h2>{{ 'TFSA_ACCOUNT'|translate }}</h2>
    <p>{{ 'TFSA_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpFHSA>
    <h2>{{ 'FHSA_ACCOUNT'|translate }}</h2>
    <p [innerHTML]="'FHSA_ACCOUNT_HELP'|translate"></p>
</ng-template>
<ng-template #helpFHSAEligibility>
    <p [innerHTML]="'FHSA_HELP_ELIGIBILITY'|translate"></p>
</ng-template>
<ng-template #helpRESP>
    <h2>{{ 'RESP_ACCOUNT'|translate }}</h2>
    <p>{{ 'RESP_ACCOUNT_HELP'|translate }}</p>
</ng-template>

<ng-template #helpLIRA>
    <h2>{{ 'LIRA_ACCOUNT'|translate }}</h2>
    <p>{{ 'LIRA_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpRRIF>
    <h2>{{ 'RRIF_ACCOUNT'|translate }}</h2>
    <p>{{ 'RRIF_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpLIF>
    <h2>{{ 'LIF_ACCOUNT'|translate }}</h2>
    <p>{{ 'LIF_ACCOUNT_HELP'|translate }}</p>
</ng-template>
<ng-template #helpFTARRSP>
    <h2>{{ 'FTARRSP_ACCOUNT'|translate }}</h2>
    <p>{{ 'FTARRSP_ACCOUNT_HELP'|translate }}</p>
</ng-template>

<ng-template #helpFiduciaryTranactionAccountsFr>
    <h2>Comptes opérations fiduciaires</h2>
    <p>Le numéro de compte et le numéro de produit correspondent au numéro de compte fourni par les Services
        fiduciaires.</p>
    <p>Le numéro de compte est composé de 7 chiffres.</p>
    <p>Le numéro de produit est composé de 2 chiffres, soit 60, 61 ou 69.</p>
</ng-template>
<ng-template #helpFiduciaryTranactionAccountsEn>
    <h2>Fiduciary Transaction Accounts</h2>
    <p>The account number and product number are the same as the account number provided by Trust services.</p>
    <p>The account number has 7 digits.</p>
    <p>The product number has 2 digits, either 60, 61 or 69.</p>
</ng-template>
