<header class="m-t-3">
    <div id="access-links">
        <a href="#content" class="sr-only sr-only-focusable"
           (click)="goToMainContent()">{{ 'SR_ONLY_GO_TO_MAIN_CONTENT' | translate }}</a>
    </div>

    <div class="container">
        <nav class="navbar navbar-default" role="navigation">
            <div class="container-fluid">
                <div class="navbar-header">
                    <a *ngIf="!isPleinEx()" href="{{ 'HEADER_LOGO_LINK_URL' | translate }}">
                        <img [src]="getLogoSrc()" [attr.alt]="getLogoAlt()">
                    </a>
                    <img *ngIf="isPleinEx()" [src]="getLogoSrc()" [attr.alt]="getLogoAlt()">
                </div>
                <ul class="nav navbar-nav navbar-right">
                    <li *ngIf="showMode()" class="navbar-form">
                        <form [formGroup]="form" novalidate>
                            <div class="form-group">
                                <label for="change-mode">{{ 'CURRENT_MODE'|translate }}</label>
                                <select
                                    class="custom-select form-control"
                                    id="change-mode"
                                    name="change-mode"
                                    formControlName="mode"
                                    (change)="useMode()">
                                    <option
                                        [ngValue]="constants.MODES.PRINT">{{ 'LIST_MODE_PRINT'|translate }}
                                    </option>
                                    <option *ngIf="isModeAutoEnabled()"
                                            [ngValue]="constants.MODES.AUTOMATED">{{ (isUpdateAccount() && isModeUpdate() ? 'LIST_MODE_UPD_AUTO' : 'LIST_MODE_AUTO') | translate }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </li>
                    <li *ngIf="!formService.completed">
                        <a href="#" id="link-switch-lang-request" (click)="useLang(switchLang)" lang="{{switchLang}}">
                            {{ 'HEADER_LANGUAGE_LABEL' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
