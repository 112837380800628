<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!-- Type of document Select -->
    <div class="h5" tabindex="0" [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL' | translate"></div>
    <ul>
        <li [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_ITEM_CHECK_SAMPLE_LABEL' | translate"></li>
        <li [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL' | translate"></li>
    </ul>

    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE' | translate"
        [id]="'userIdentityCode'"
        [name]="'userIdentityCode'"
        [path]="'userIdentityCode'"
        [list]="userIdentityCodeList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userIdentityCode')"
    ></app-odc-field>

    <!-- Attachments -->
    <ng-container formArrayName="attachedDocuments"
                  *ngFor="let item of getAttachedDocumentsControl().controls; let i = index;">
        <ng-container [formGroupName]="i">
            <fieldset class="form-group">
                <legend [class.has-error]="isFieldInError('attachedDocuments.' + i)">
                    <div>
                        <label [attr.for]="item.get('attachedDocumentName').value + 'Type'">
                            <b class="sr-only">{{'TRANSMISSION_ATTACHED_DOCUMENT_TYPE'|translate}}</b>
                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                       [path]="'attachedDocuments.' + i + '.attachedDocumentName'"></app-error>
                        </label>
                    </div>
                    <div>
                        <label [attr.for]="item.get('attachedDocumentName').value">
                            <b class="sr-only">{{getInputFileLabelKey(i)|translate}}</b>
                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                       [path]="'attachedDocuments.' + i + '.attachedDocument'"></app-error>
                        </label>
                    </div>
                </legend>
                <div class="row">
                    <div class="col-md-6">
                        <div *ngIf="!isCaisseContext()">
                            <label [attr.for]="item.get('attachedDocumentName').value">
                                <b>{{getInputFileLabelKey(i)|translate}}</b>
                            </label>
                        </div>
                        <div *ngIf="isCaisseContext()" class="form-group"
                             [class.has-error]="isFieldInError('attachedDocuments.' + i + '.attachedDocumentName')">
                            <label class="sr-only" [attr.for]="item.get('attachedDocumentName').value + 'Type'">
                                <b>{{'TRANSMISSION_ATTACHED_DOCUMENT_TYPE'|translate}}</b>
                            </label>

                            <select
                                class="form-control custom-select"
                                [attr.id]="item.get('attachedDocumentName').value + 'Type'"
                                [attr.name]="item.get('attachedDocumentName').value + 'Type'"
                                name="attachedDocumentName_{{i}}"
                                formControlName="attachedDocumentName"
                            >
                                <option [ngValue]="null">{{'GLOBAL_SELECT' | translate}}</option>
                                <option *ngFor="let item of attachedDocumentTypeList" [value]="item.itemValue">
                                    {{item.itemLabel|translate}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-12 form-group"
                         [class.has-error]="isFieldInError('attachedDocuments.' + i + '.attachedDocument')">
                        <input type="file"
                               [attr.id]="item.get('attachedDocumentName').value"
                               [attr.disabled]="isRequestSaving() ? true : null"
                               class="sr-only index-{{i}}"
                               (change)="onFileChange($event, 'attachedDocument_' + currentIndex)"
                               accept=".pdf,.jpg,.jpeg,.png"
                               formControlName="attachedDocumentFile"
                               [attr.name]="item.get('attachedDocumentName').value + 'Upload'"
                               [attr.aria-hidden]="'true'"/>

                        <div [class.input-group]="item.get('attachedDocument').value">
                            <label [attr.for]="item.get('attachedDocumentName').value + '-text'"
                                   class="sr-only">{{getInputFileLabelKey(i)|translate}}</label>
                            <input type="text"
                                   [attr.id]="item.get('attachedDocumentName').value + '-text'"
                                   class="form-control"
                                   (click)="selectFile(i)"
                                   formControlName="attachedDocument"
                                   name="attachedDocument_{{i}}"
                                   readonly
                            />
                            <span class="input-group-addon file-upload-remove"
                                  *ngIf="item.get('attachedDocument').value"
                                  (click)="removeFile(i)"
                                  tabindex="0"
                            >
                            <span class="sr-only">{{'BTN_REMOVE_FILE'|translate}}</span>
                            &times;
                        </span>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-primary btn-block btn-upload"
                                [attr.disabled]="isRequestSaving() ? true : null"
                                (click)="selectFile(i)">
                            <span class="sr-only">{{getInputFileLabelKey(i)|translate}} - </span>
                            {{'TRANSMISSION_ATTACHED_DOCUMENT_UPLOAD'|translate}}
                        </button>
                    </div>
                </div>
            </fieldset>

            <div class="m-b-1" *ngIf="displayRemoveAttachedDocumentButton()">
                <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="removeAttachedDocumentForm(i)">
                    <span class="icon-close rouge"></span>
                    {{'TRANSMISSION_ATTACHED_DOCUMENT_REMOVE'|translate}}
                </a>
            </div>
        </ng-container>
    </ng-container>

    <div class="m-b-1" *ngIf="displayAddAttachedDocumentButton()">
        <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addAttachedDocumentForm()">
            <span class="icon-circle-plus"></span>
            {{'TRANSMISSION_ATTACHED_DOCUMENT_ADD'|translate}}
        </a>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="loading"></app-questionnaire-btn>

</form>
