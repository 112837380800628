import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {WebException} from '../../components/models';
import {VmdValidators} from '../../components/validators/vmd-validators';

@Component({
    selector: 'app-specific-consents',
    templateUrl: './specific-consents.component.html',
    providers: [TranslatePipe],
    styles: ['hr {height:1px; background-color:#333;}']
})
export class SpecificConsentsComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;
    pleinEx: boolean = false;
    error: WebException = null;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                public translate: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({
            personalInformationSpecificConsentIndicator:
                [this.formService.getForm().requesters[this.requesterIndex].personalInformationSpecificConsentIndicator,
                    [this.requiredValidator('personalInformationSpecificConsentIndicator')]]
        });

        if ((this.isPleinEx() && this.isUpdateAccount())) {
            this.form.get('personalInformationSpecificConsentIndicator').setValidators([VmdValidators.required(false)]);
        }

        this.pleinEx = this.isPleinEx();
    }
}
