export const PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'TypeOfManagementComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'AccountsChoiceComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'PersonalInformationComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'ResidentialAddressComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_OCCUPATION',
        items: [
            {
                label: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION',
                path: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'OccupationComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_CIVIL_STATUS',
        items: [
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'SpouseInformationComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'BeneficiaryRespComponent',
                saveAvailable: true
            },
            {
                disable: true,
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'BeneficiaryFhsaComponent',
                aside: 'ASIDE_CONSULT_ALL',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH',
                path: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReferredAccountComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_LEGAL',
        items: [
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'BrokerageAccountsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'AttorneyComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX',
        items: [
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'FinancialInformationComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'InvestmentObjectivesAndRiskFactorsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER',
                path: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'ShareholderComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT',
                path: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'SpecificConsentsComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'OptionAccountComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'TransferRequestChoiceComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_PLEIN_EX_PATH',
                component: 'ReviewClientIdentityComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PRINT',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'TransmissionKitDownloadComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_SUBMIT',
        items: [
            {
                label: 'ODC_NAV_STEP_SUBMIT',
                path: 'ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_PLEIN_EX_PATH',
                component: 'SubmissionComponent',
                saveAvailable: false
            }
        ]
    }
];
