import {OssClientAddress} from '../models/ossClientAddress';
import {ListModelService} from '../services/list-model.service';
import {VmdConstants} from '../constants/vmd-constants';
import {HtmlSelectKV} from '../models/html-select-k-v';
import {VmdFormatZipCode} from './vmd-format-zip-code';

export class VmdFormatAddress {

    countries: HtmlSelectKV[];

    formatAsOssClientAddress(clientAddress: OssClientAddress, listModelService: ListModelService): string {

        listModelService.getListModel('paysList', data => this.countries = data);
        const vmdFormatZipCode = new VmdFormatZipCode();

        const userAddressStreet: string = clientAddress.clientAddressStreet;
        const userAddressUnit: string = clientAddress.clientAddressUnit;
        const userAddressZipCode = vmdFormatZipCode.formatAsZipCode(clientAddress.clientAddressPostalCode);
        const userAddressProvince = (!!clientAddress.clientAddressProv) ? clientAddress.clientAddressProv.replace(clientAddress.clientAddressCountry, VmdConstants.EMPTY) : VmdConstants.EMPTY;
        const userAddressCountry = (ListModelService.getLabelFromList(this.countries, clientAddress.clientAddressCountry) || VmdConstants.EMPTY);

        return ((!!userAddressStreet) ? userAddressStreet.toLowerCase() : VmdConstants.EMPTY) + VmdConstants.SPACE
            + ((!!userAddressUnit) ? userAddressUnit.toLowerCase() : VmdConstants.EMPTY) + VmdConstants.BREAK
            + clientAddress.clientAddressCity + VmdConstants.SPACE
            + userAddressProvince + ',' + VmdConstants.SPACE
            + userAddressZipCode + VmdConstants.BREAK
            + userAddressCountry;
    }
}
