import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {FormService} from '../../components/services/form.service';
import {NavigationService} from '../../components/services/navigation.service';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';

declare let $: any;

enum ManagementTypeEnum {
    COMMISSION_BASED_ADVISORY_SERVICES = 'investmentAdviceComissionBased',
    PORTFOLIO_MANAGER_ADVISOR_PROGRAM = 'portfolioManagementAdvisorProgram',
    MANDATE_ONLY = 'portfolioManagementMandateOnly'
}

@Component({
    templateUrl: './type-of-management.component.html'
})
export class TypeOfManagementComponent extends BaseNavComponent implements OnInit {

    managementTypeEnum = ManagementTypeEnum;
    managementType: FormControl = new FormControl('', this.requiredValidator('managementType'));

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            investmentAdviceEliteBasicCommissionBased: [this.formService.getForm().investmentAdviceComissionBased && this.formService.getForm().investmentAdviceEliteBasic],
            investmentAdviceEliteIntermediateCommissionBased: [this.formService.getForm().investmentAdviceComissionBased && this.formService.getForm().investmentAdviceEliteIntermediate],
            investmentAdviceEliteFlexibleCommissionBased: [this.formService.getForm().investmentAdviceComissionBased && this.formService.getForm().investmentAdviceEliteFlexible],

            investmentAdviceEliteBasicPMAP: [this.formService.getForm().portfolioManagementAdvisorProgram && this.formService.getForm().investmentAdviceEliteBasic],
            investmentAdviceEliteIntermediatePMAP: [this.formService.getForm().portfolioManagementAdvisorProgram && this.formService.getForm().investmentAdviceEliteIntermediate],
            investmentAdviceEliteFlexiblePMAP: [this.formService.getForm().portfolioManagementAdvisorProgram && this.formService.getForm().investmentAdviceEliteFlexible],

            managementType: this.managementType
        });

        this.onChanges();

        this.setInitialInvestorProfileValueWhenEmpty(true);
        this.setManagementTypeFormControlValue();
        this.resetCashAccountMgntType();
    }

    setManagementTypeFormControlValue() {
        const form = this.formService.getForm();
        if (this.isUpdateAccount()
            && (this.isSsd() || this.isPleinEx())
            && !this.isGpdOrSfd()
            && this.isNotPortfolioManagementMandateOnlyAndNotPortfolioManagementAdvisorProgram()) {
            form.investmentAdviceComissionBased = true;
        }

        if (form.investmentAdviceComissionBased) {
            this.managementType.setValue(this.managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES, {emitEvent: false});
        } else if (form.portfolioManagementAdvisorProgram) {
            this.managementType.setValue(this.managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM, {emitEvent: false});
        } else if (form.portfolioManagementMandateOnly) {
            this.managementType.setValue(this.managementTypeEnum.MANDATE_ONLY, {emitEvent: false});
        } else if (this.formService.isConvertedRequest()
            || this.constants.ACCOUNT_CONVERTED === this.formService.getForm().newExistAct
            || this.constants.ACCOUNT_REDOCUMENT === this.formService.getForm().newExistAct) {
            this.managementType.setValue(this.managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM, {emitEvent: true});
        }
    }

    isNotPortfolioManagementMandateOnlyAndNotPortfolioManagementAdvisorProgram() {
        return !this.formService.getForm().portfolioManagementMandateOnly && !this.formService.getForm().portfolioManagementAdvisorProgram;
    }

    onSelectManagementType(formControlName: string): void {
        const typeOfManagementControlNames = [
            'investmentAdviceComissionBased',
            'portfolioManagementAdvisorProgram',
            'portfolioManagementMandateOnly'
        ];
        typeOfManagementControlNames
            .filter(controlName => this.formService.getForm()[controlName] === true)
            .forEach(controlName => this.formService.setFormProperty(controlName, false));

        this.formService.setFormProperty(formControlName, true);

        if (formControlName !== 'investmentAdviceComissionBased') {
            this.resetCheckboxes([
                'investmentAdviceEliteBasicCommissionBased',
                'investmentAdviceEliteIntermediateCommissionBased',
                'investmentAdviceEliteFlexibleCommissionBased'
            ]);
        }
        if (formControlName !== 'portfolioManagementAdvisorProgram') {
            this.resetCheckboxes([
                'investmentAdviceEliteBasicPMAP',
                'investmentAdviceEliteIntermediatePMAP',
                'investmentAdviceEliteFlexiblePMAP'
            ]);
        }
        if (formControlName !== 'investmentAdviceComissionBased' && formControlName !== 'portfolioManagementAdvisorProgram') {
            ['investmentAdviceEliteBasic', 'investmentAdviceEliteIntermediate', 'investmentAdviceEliteFlexible'].forEach(controlName => {
                this.formService.setFormProperty(controlName, false);
            });
        }
    }

    /**
     * Reset checkboxes given their form control names as an array
     * @param controlNames an array of form control names (must be checkboxes)
     */
    resetCheckboxes(controlNames) {
        controlNames
            .filter(controlName => this.form.get(controlName).value === true)
            .forEach(controlName => {
                this.form.get(controlName).setValue(false, {emitEvent: false});
            });

        this.formService.getForm().investmentAdviceEliteBasic = false;
        this.formService.getForm().investmentAdviceEliteIntermediate = false;
        this.formService.getForm().investmentAdviceEliteFlexible = false;
    }

    isExcludeStepNavigation(): boolean {
        return this.isOtherAccountPleinex() || this.isJointAccountPleinex()
            || this.isJointAccountGpd() || this.isOtherAccountGpd()
            || this.isJointAccountSsd() || this.isOtherAccountSsd();
    }

    isJointAccountPleinex(): boolean {
        return this.constants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isJointAccountGpd(): boolean {
        return this.constants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isJointAccountSsd(): boolean {
        return this.constants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isOtherAccountPleinex(): boolean {
        return this.constants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isOtherAccountSsd(): boolean {
        return this.constants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isOtherAccountGpd(): boolean {
        return this.constants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    isEntity(): boolean {
        const clt = this.formService.getForm().cltType;
        return clt === this.constants.CLT_TYPE_FIDUCIE
            || clt === this.constants.CLT_TYPE_ASSOCIATION
            || clt === this.constants.CLT_TYPE_COOPERATIVE
            || clt === this.constants.CLT_TYPE_CORPORATION
            || clt === this.constants.CLT_TYPE_CO_UNIQUE
            || clt === this.constants.CLT_TYPE_ORG_GOUV
            || clt === this.constants.CLT_TYPE_OSBL
            || clt === this.constants.CLT_TYPE_SOC_COMM
            || clt === this.constants.CLT_TYPE_SENC;
    }

    isPrintMode(): boolean {
        return this.formService.isPrintMode();
    }

    showBack() {
        return super.showBack()
            && (this.isJointAccountPleinex() || this.isOtherAccountPleinex()
                || this.isJointAccountGpd() || this.isOtherAccountGpd()
                || this.isJointAccountSsd() || this.isOtherAccountSsd());
    }

    public hideBackBtn() {
        return (this.formService.getMode() === VmdConstants.MODES.PRINT && this.formService.isRecoveredRequest());
    }

    private onChanges() {
        /*
        Commission-Based Advisory Services
         */
        this.form.get('investmentAdviceEliteBasicCommissionBased').valueChanges.subscribe(value => {
            if (!this.formService.getForm().investmentAdviceComissionBased) {
                this.managementType.setValue(this.managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES);

                ['investmentAdviceEliteIntermediate', 'investmentAdviceEliteFlexible'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        this.form.get('investmentAdviceEliteIntermediateCommissionBased').valueChanges.subscribe(value => {
            if (!this.formService.getForm().investmentAdviceComissionBased) {
                this.managementType.setValue(this.managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES);

                ['investmentAdviceEliteBasic', 'investmentAdviceEliteFlexible'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        this.form.get('investmentAdviceEliteFlexibleCommissionBased').valueChanges.subscribe(value => {
            if (!this.formService.getForm().investmentAdviceComissionBased) {
                this.managementType.setValue(this.managementTypeEnum.COMMISSION_BASED_ADVISORY_SERVICES);

                ['investmentAdviceEliteBasic', 'investmentAdviceEliteIntermediate'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        /*
        Portfolio Manager Advisor Program
        */
        this.form.get('investmentAdviceEliteBasicPMAP').valueChanges.subscribe(value => {
            if (!this.formService.getForm().portfolioManagementAdvisorProgram) {
                this.managementType.setValue(this.managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM);

                ['investmentAdviceEliteIntermediate', 'investmentAdviceEliteFlexible'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        this.form.get('investmentAdviceEliteIntermediatePMAP').valueChanges.subscribe(value => {
            if (!this.formService.getForm().portfolioManagementAdvisorProgram) {
                this.managementType.setValue(this.managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM);

                ['investmentAdviceEliteBasic', 'investmentAdviceEliteFlexible'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        this.form.get('investmentAdviceEliteFlexiblePMAP').valueChanges.subscribe(value => {
            if (!this.formService.getForm().portfolioManagementAdvisorProgram) {
                this.managementType.setValue(this.managementTypeEnum.PORTFOLIO_MANAGER_ADVISOR_PROGRAM);

                ['investmentAdviceEliteBasic', 'investmentAdviceEliteIntermediate'].forEach(controlName => {
                    this.formService.setFormProperty(controlName, false);
                });
            }
        });

        this.managementType.valueChanges.subscribe(value => {
            this.onSelectManagementType(value);
            this.formService.applyRules('mgntType');
        });
    }

    private setInitialInvestorProfileValueWhenEmpty(value: boolean): void {
        if (this.isPleinEx() && !this.isGpdOrSfd()) {

            const includeInvestorProfile = this.formService.getForm().includeInvestorProfile;
            if (includeInvestorProfile === undefined || includeInvestorProfile === null) {
                this.formService.setFormProperty('includeInvestorProfile', value);
            }
        }
    }

    private resetCashAccountMgntType() {
        if (this.isPleinEx() && !this.isGpdOrSfd() && this.formService.getForm().cashAccountMgntType) {
            if (!this.isSsd()) {
                this.formService.getForm().investmentAdviceEliteBasic = false;
                this.formService.getForm().investmentAdviceEliteIntermediate = false;
            }
            this.formService.getForm().cashAccountMgntType = null;
            this.formService.getForm().investmentAdviceEliteFlexible = false;

            this.resetCheckboxes([
                'investmentAdviceEliteBasicCommissionBased',
                'investmentAdviceEliteIntermediateCommissionBased',
                'investmentAdviceEliteFlexibleCommissionBased',
                'investmentAdviceEliteBasicPMAP',
                'investmentAdviceEliteIntermediatePMAP',
                'investmentAdviceEliteFlexiblePMAP'
            ]);
        }
    }
}
