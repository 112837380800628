<div [formGroup]="getFormGroup()" class="separator-top">

    <h2>
        {{'ODC_BENEFICIARY_TITLE' | translate}}
    </h2>
    <fieldset>
        <legend class="sr-only">{{'ODC_BENEFICIARY_TITLE' | translate}}</legend>

        <!--Gender-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get(groupName)"
            [submitted]="submitted"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
            [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
            [id]="gender"
            [name]="gender"
            [path]="gender"
            [binding]="gender"
        ></app-odc-field>



        <!--firstname & lastname--------------------------------->
        <div class="row">
            <div class="col-md-12">
                <!--spouseFirstName --------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                    [id]="firstName"
                    [name]="firstName"
                    [path]="firstName"
                    [binding]="firstName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <!--spouseLastName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                    [id]="lastName"
                    [name]="lastName"
                    [path]="lastName"
                    [binding]="lastName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
        </div>

        <!--DOB & SIN-------------------------------------------------------->
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                    [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                    [id]="DOB"
                    [name]="DOB"
                    [path]="DOB"
                    [binding]="DOB"
                    [attr]="{maxlength: 10}"
                    [dateFormat]="true"
                    [templateRefFr]="helpDOB"
                    [templateRefEn]="helpDOB"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [autoComplete]="'off'"
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_BENEFICIARY_SIN' | translate"
                    [id]="SIN"
                    [name]="SIN"
                    [path]="SIN"
                    [binding]="SIN"
                    [attr]="{maxlength: 11}"
                    [sinFormat]="true"
                    [templateRefFr]="helpSIN"
                    [templateRefEn]="helpSIN"
                ></app-odc-field>
            </div>
        </div>



        <!--Address--------------------------------------------------------------------------->
        <div class="grid-12">
            <app-address-form
                [legend]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_ADDRESS_TITLE'|translate"
                [form]="form"
                [index]="''"
                [submitted]="submitted"
                [bindPath]="''"
                [path]="groupName"
                [street]="addressStreet"
                [unit]="addressUnit"
                [city]="addressCity"
                [province]="addressProv"
                [postalcode]="addressPostalCode"
                [country]="addressCountry"
            ></app-address-form>
        </div>




        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.SELECT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_BENEFICIARY_PARENT_LINK' | translate"
                    [id]="parentLink"
                    [name]="parentLink"
                    [path]="parentLink"
                    [list]="parentLinks"
                    [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                    [binding]="parentLink"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_BENEFICIARY_PART' | translate"
                    [id]="part"
                    [name]="part"
                    [path]="part"
                    [binding]="part"
                    [percentFormat]="true"
                    [attr]="{maxlength: 3}"
                ></app-odc-field>
            </div>
        </div>



        <div class="m-b-1">
            <ng-container *ngIf="deleteVisible">
                <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="deleteBeneficiary()">
                    <span class="icon-close rouge"></span>
                    {{'ODC_STEP_BENEFICIARY_DELETE'|translate}}
                </a>
            </ng-container>

        </div>

    </fieldset>

</div>


<ng-template #helpSIN>
    <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
    <p>{{'HELP_SIN_BODY' | translate}}</p>
</ng-template>

<ng-template #helpDOB>
    <h2>{{'HELP_DOB_TITLE' | translate}}</h2>
    <p>{{'HELP_DOB_BODY' | translate}}</p>
</ng-template>
