import {Component, Inject, OnInit} from '@angular/core';
import {FormsComparisonService} from '../../../components/services/forms-comparison.service';
import {RequestsComparisonResult} from '../../../components/models/requests-comparison-result.model';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';

@Component({
    selector: 'app-review-update-page',
    templateUrl: './review-update-page.component.html'
})
export class ReviewUpdatePageComponent implements OnInit {

    formDelta: RequestsComparisonResult[];

    constructor(private formsComparisonService: FormsComparisonService, @Inject(ODC_CONFIG) private config: IOdcConfig) {
    }

    ngOnInit(): void {

        this.formsComparisonService.getRequestDiff(this.config.ENCRYPTED_URL).subscribe((recoveryData: any) => {
                this.formDelta = recoveryData.payload;
            },
            () => {
            }
        );
    }

    isPleinEx(): boolean {

        return this.config.IS_PLEIN_EX;
    }

    getClientId(): string {
        return this.config.CLIENT_ID;
    }
}
