import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ListModelService} from '../../../components/services/list-model.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {FormService} from '../../../components/services/form.service';
import {Subscription} from 'rxjs';

declare let $: any;

@Component({
    selector: 'app-beneficiary-pleinex',
    templateUrl: './beneficiary-pleinex.component.html'
})
export class BeneficiaryPleinExComponent implements OnInit, OnDestroy {

    @Input() form: FormGroup;
    @Input() index: string;
    @Input() path: string;
    @Input() bindPath: string;
    @Input() submitted: boolean;
    @Input() groupName: string;
    @Input() addressGroupName: string;
    @Input() gender: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() DOB: Date;
    @Input() SIN: string;
    @Input() addressStreet: string;
    @Input() addressUnit: string;
    @Input() addressCity: string;
    @Input() addressProv: string;
    @Input() addressPostalCode: string;
    @Input() addressCountry: string;
    @Input() parentLink: string;
    @Input() deleteVisible: boolean;
    @Input() beneficiaryAddressSameAsSubscriber: boolean;
    @Input() distributionRatio: string;
    @Input() caregiverParentFirstName: string;
    @Input() caregiverParentLastName: string;
    @Input() caregiverParentSin: string;
    @Input() requestCLBForResp: boolean;
    @Input() custodialParentGender: string;
    @Input() custodialParentFirstName: string;
    @Input() custodialParentLastName: string;
    @Input() custodialParentSecGender: string;
    @Input() custodialParentSecFirstName: string;
    @Input() custodialParentSecLastName: string;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();


    constants: any = VmdConstants;
    parentLinks: HtmlSelectKV[] = [];
    formGroup: FormGroup;

    showAddress: false;
    showCaregiver: boolean;

    showCustodialParentSecFields = false;

    beneficiarySCEEESubscription: Subscription;

    constructor(private listModelService: ListModelService,
                public formService: FormService) {
    }

    ngOnInit() {
        this.showCaregiver = !this.form.get('requestCESGForResp').value;
        this.listModelService.getListModel('beneficiaryRespRelationList', data => this.setParentLinks(data));

        this.formGroup  = this.form.get(this.path + '.' + this.index) as FormGroup;
        this.showAddress = this.formGroup.get('beneficiaryRespAddressSameAsSubscriber').value;
        this.formGroup.get('beneficiaryRespAddressSameAsSubscriber').valueChanges.subscribe(value => {
            if (value) {
                this.showAddress = value;
            } else {
                this.removeAddressBlock();
            }
        });

        this.beneficiarySCEEESubscription = this.formService.beneficiarySCEEEmitted.subscribe( (val) => {
            this.showCaregiver = val;
        } );

        this.showCustodialParentSecFields = (this.formGroup.get('beneficiaryRespCustodialParentSecFirstName').value !== null);
    }

    addCustodialParentSec() {
        this.showCustodialParentSecFields = true;
        return false;
    }

    deleteCustodialParentSec() {
        this.showCustodialParentSecFields = false;

        this.formGroup.get('beneficiaryRespCustodialParentSecGender').patchValue(null);
        this.formService.setFormProperty(this.getBindPath() + 'beneficiaryRespCustodialParentSecGender', null);
        this.formGroup.get('beneficiaryRespCustodialParentSecFirstName').patchValue(null);
        this.formService.setFormProperty(this.getBindPath() + 'beneficiaryRespCustodialParentSecFirstName', null);
        this.formGroup.get('beneficiaryRespCustodialParentSecLastName').patchValue(null);
        this.formService.setFormProperty(this.getBindPath() + 'beneficiaryRespCustodialParentSecLastName', null);

        return false;
    }


    isFieldInError(fieldName: string) {
        const control: AbstractControl = this.form.get(this.path + fieldName);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid && ((control.hasError('required') && this.submitted) || !control.hasError('required'));
    }

    modifyParentLinks(data: Array<any>): Array<any> {
        const parentLinkToSave = ['01', '02', '04'];
        const parentLinkForFamilyRespType = [];
        if (this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY) {
            for (const parentLink of data) {
                if (-1 !== parentLinkToSave.indexOf(parentLink.itemValue)) {
                    parentLinkForFamilyRespType.push(parentLink);
                }
            }
            return parentLinkForFamilyRespType;
        } else {
            return data;
        }
    }

    setParentLinks(data: any): void {
        this.parentLinks = this.modifyParentLinks(data);
        const that = this;
        setTimeout(() => {
            // Initialize province
            $('#' + that.parentLink + ' option[value="' + that.formGroup.get(that.parentLink).value + '"]').attr('selected', 'selected');
        }, 0);
    }

    removeAddressBlock() {
        this.showAddress = false;
    }

    getFormPath(): string {
        let newPath: string;
        if (this.path) {
            newPath = this.path + '.' + this.index + '.';
        }
        return newPath;
    }

    getBindPath(): string {
        let newPath: string;
        if (this.bindPath) {
            newPath = this.bindPath + '.' + this.index + '.';
        }
        return newPath;
    }

    isFamilyAccount(): boolean {
        return this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY;
    }

    ngOnDestroy(): void {
        this.beneficiarySCEEESubscription.unsubscribe();
    }

}
