import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';

@Component({
    selector: 'app-option-account',
    templateUrl: './option-account.component.html'
})
export class OptionAccountComponent extends BaseNavComponent implements OnInit {

    optionCodeList: HtmlSelectKV[] = [
        new HtmlSelectKV('1', '1'),
        new HtmlSelectKV('2', '2'),
        new HtmlSelectKV('3', '3'),
        new HtmlSelectKV('4', '4')
    ];

    ngOnInit() {
        this.form = this.fb.group({
            optionCode: [this.formService.getForm().optionCode, [this.requiredValidator('optionCode')]],
            registeredOptionCode: [this.formService.getForm().registeredOptionCode, [this.requiredValidator('registeredOptionCode')]]
        });

        this.updateControlEnabled(this.form.get('registeredOptionCode'), this.hasRegisteredAccount());
    }

    hasRegisteredAccount(): boolean {
        return this.formService.getForm().hasRegisteredAccount();
    }

}
