<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">


    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-house"></span>

        {{'ODC_BENEFICIARY_FHSA_TITLE' | translate}}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>


    <!--Answer-->
    <div *ngIf="hasSpouse()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_BENEFICIARY_FHSA_QUESTION' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'successorHolderCeliAppActiveIndicator'"
            [name]="'successorHolderCeliAppActiveIndicator'"
            [path]="'successorHolderCeliAppActiveIndicator'"
            [binding]="'successorHolderCeliAppActiveIndicator'"
        ></app-odc-field>
    </div>


    <!--Answer-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_BENEFICIARY_FHSA_QUESTION_2' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'beneficiaryCeliAppActiveIndicator'"
        [name]="'beneficiaryCeliAppActiveIndicator'"
        [path]="'beneficiaryCeliAppActiveIndicator'"
        [binding]="'beneficiaryCeliAppActiveIndicator'"
    ></app-odc-field>

    <div *ngIf="isSuccessorHolderIndicator && this.hasSpouse()">

        <app-successor
            [form]="form"
            [submitted]="isFormSubmitted()"
            [groupName]="'successor'"
            [gender]="'successorHolderCeliAppGender'"
            [firstName]="'successorHolderCeliAppFirstName'"
            [lastName]="'successorHolderCeliAppLastName'"
            [SIN]="'successorHolderCeliAppSIN'"
            [part]="'successorHolderCeliAppPart'"
            (toggle)="removeSuccessor()"
        ></app-successor>
    </div>

    <div *ngIf="isBeneficiaryPriIndicator">

        <app-beneficiary-celiapp
            [form]="form"
            [submitted]="isFormSubmitted()"
            [groupName]="'beneficiary'"
            [gender]="'beneficiaryCeliAppGender'"
            [firstName]="'beneficiaryCeliAppFirstName'"
            [lastName]="'beneficiaryCeliAppLastName'"
            [DOB]="'beneficiaryCeliAppDOB'"
            [SIN]="'beneficiaryCeliAppSIN'"
            [parentLink]="'beneficiaryCeliAppParentLink'"
            [part]="'beneficiaryCeliAppPart'"
            [deleteVisible]="isBeneficiarySecIndicator"
            (toggle)="removePriBeneficiary()"
        ></app-beneficiary-celiapp>

        <div class="m-b-1">
            <ng-container *ngIf="!isBeneficiarySecIndicator">
                <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiarySec()">
                    <span class="icon-circle-plus"></span>
                    {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                </a>
            </ng-container>

        </div>

        <div *ngIf="isBeneficiarySecIndicator">

            <app-beneficiary-celiapp
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiarySec'"
                [gender]="'beneficiaryCeliAppGenderSec'"
                [firstName]="'beneficiaryCeliAppFirstNameSec'"
                [lastName]="'beneficiaryCeliAppLastNameSec'"
                [DOB]="'beneficiaryCeliAppDOBSec'"
                [SIN]="'beneficiaryCeliAppSINSec'"
                [parentLink]="'beneficiaryCeliAppParentLinkSec'"
                [part]="'beneficiaryCeliAppPartSec'"
                [deleteVisible]="true"
                (toggle)="removeSecBeneficiary()"
            ></app-beneficiary-celiapp>


            <div class="m-b-1">
                <ng-container *ngIf="!isBeneficiaryTerIndicator">
                    <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiaryTer()">
                        <span class="icon-circle-plus"></span>
                        {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                    </a>
                </ng-container>

            </div>

        </div>

        <div *ngIf="isBeneficiaryTerIndicator">
            <app-beneficiary-celiapp
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiaryTer'"
                [gender]="'beneficiaryCeliAppGenderTer'"
                [firstName]="'beneficiaryCeliAppFirstNameTer'"
                [lastName]="'beneficiaryCeliAppLastNameTer'"
                [DOB]="'beneficiaryCeliAppDOBTer'"
                [SIN]="'beneficiaryCeliAppSINTer'"
                [parentLink]="'beneficiaryCeliAppParentLinkTer'"
                [part]="'beneficiaryCeliAppPartTer'"
                [deleteVisible]="true"
                (toggle)="removeTerBeneficiary()"
            ></app-beneficiary-celiapp>
        </div>


    </div>

    <p *ngIf="isBeneficiaryPriIndicator" class="m-b-2 m-t-1"
       tabindex="0">{{ 'ODC_BENEFICIARY_RRSP_SHARE_WITHOUT_BENEFICIARY' | translate }}</p>


    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>

<ng-template #helpFr>
    <h2>Qu'est-ce qu'un titulaire remplaçant?</h2>
    <p>Dans les provinces et territoires qui reconnaissent une désignation de bénéficiaire de CELIAPP, l'époux ou le
        conjoint de fait du titulaire, au moment de son décès, qui est désigné comme le titulaire remplaçant du CELIAPP,
        et acquiert tous les droits du titulaire selon l'arrangement, y compris le droit de révoquer toute désignation
        de bénéficiaire, l'époux ou le conjoint de fait devient le nouveau titulaire du compte.</p>

    <h2>Pourquoi nommer un bénéficiaire?</h2>
    <p>Les actifs du CELIAPP pourront ainsi être transférés directement aux bénéficiaires que vous désignez dans la
        documentation du compte CELIAPP conformément à la législation applicable.</p>
</ng-template>
<ng-template #helpEn>
    <h2>What is a successor holder?</h2>
    <p>Applies in provinces and territories that recognize a FHSA beneficiary designation. Upon the holder's death, the
        spouse or common-law partner of the holder who is designated as the successor holder of the TFSA acquires all
        the rights of the holder, including the right to revoke any beneficiary designation. The spouse or common-law
        partner becomes the new account holder.</p>

    <h2>Why name a beneficiary?</h2>
    <p>FHSA assets will therefore be transferred directly to the beneficiaries you designate (in the FHSA account
        documentation) in accordance with the applicable legislation.</p>
</ng-template>
