import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {VmdValidators} from '../../components/validators/vmd-validators';


@Component({
    selector: 'app-referred-account',
    templateUrl: './referred-account.component.html'
})
export class ReferredAccountComponent extends BaseNavComponent implements OnInit {

    bankList: HtmlSelectKV[];


    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({
            userBankId: [this.formService.getForm().requesters[this.requesterIndex].userBankId, [this.requiredValidator('userBankId')]],
            userBankBranchNumber: [this.formService.getForm().requesters[this.requesterIndex].userBankBranchNumber, [this.requiredValidator('userBankBranchNumber'), VmdValidators.transit]],
            userIdentityAdvisorFamilyName: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFamilyName, [this.requiredValidator('userIdentityAdvisorFamilyName')]],
            userIdentityAdvisorFirstName: [this.formService.getForm().requesters[this.requesterIndex].userIdentityAdvisorFirstName, [this.requiredValidator('userIdentityAdvisorFirstName')]],
        });

        const serviceName = this.isPleinEx() ? 'bankIdListPleinEx' : 'bankIdList';
        this.listModelService.getListModel(serviceName, data => this.bankList = data);
        this.updateControlEnabled(this.form.get('userIdentityAdvisorFamilyName'), this.isGpdOrSfd());
        this.updateControlEnabled(this.form.get('userIdentityAdvisorFirstName'), this.isGpdOrSfd());

        const userBankBranchNumber = this.formService.getForm().requesters[this.requesterIndex].userBankBranchNumber;
        if (userBankBranchNumber != null) {
            const userBankBranch = userBankBranchNumber.toString().trim();
            if ((userBankBranch.length < 5) && (userBankBranch.length > 0)) {
                const leadingZeroStr: string = this.padLeft(userBankBranch, '0', 5);
                this.form.get('userBankBranchNumber').setValue(leadingZeroStr);
                this.formService.setFormProperty(this.getRequesterPath('userBankBranchNumber'), leadingZeroStr);
            }
        }
    }

    padLeft(text: string, padChar: string, size: number): string {
        return (String(padChar).repeat(size) + text).substr((size * -1), size);
    }
}
