import {Component} from '@angular/core';
import {ConfirmationComponent} from './confirmation.component';

@Component({
    selector: 'app-confirmation-caisse',
    templateUrl: './confirmation-caisse.component.html'
})
export class ConfirmationCaisseComponent extends ConfirmationComponent {

}
