<div id="modal-add-entity" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-add-entity-label"
     aria-describedby="modal-add-entity-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <form [formGroup]="form" ngForm novalidate (submit)="submit()">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-add-entity-label" data-loop="start" tabindex="0">
                        {{'ACCOUNT_TYPE_ENTITIES_QUESTIONS_TITLE' | translate}}</h2>
                </div>

                <div class="modal-body" id="modal-add-entity-desc">
                    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.SELECT"
                        [form]="form"
                        [submitted]="isFormSubmitted()"
                        [label]="'ENTITY_TYPE' | translate"
                        [id]="'userOtherEntityType'"
                        [name]="'userOtherEntityType'"
                        [path]="'userOtherEntityType'"
                        [list]="entityTypeList"
                        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                        [binding]="null"
                    ></app-odc-field>

                    <app-odc-field *ngIf="entityNameIsShowable()"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form"
                        [submitted]="isFormSubmitted()"
                        [label]="'ENTITY_NAME' | translate"
                        [id]="'userOtherEntityName'"
                        [name]="'userOtherEntityName'"
                        [path]="'userOtherEntityName'"
                        [binding]="null"
                        [attr]="{maxlength: 64}"
                    ></app-odc-field>

                    <app-odc-field *ngIf="physicalPersonsUsIsShowable()"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form"
                        [submitted]="isFormSubmitted()"
                        [label]="'AMERICAN_INDIVIDUALS' | translate"
                        [id]="'userOtherAmericanIndividualsNumber'"
                        [name]="'userOtherAmericanIndividualsNumber'"
                        [path]="'userOtherAmericanIndividualsNumber'"
                        [binding]="null"
                        [integerFormat]="true"
                        [attr]="{maxlength: 2}"
                    ></app-odc-field>

                    <app-odc-field  *ngIf="physicalPersonsOtherThanUsIsShowable()"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form"
                        [submitted]="isFormSubmitted()"
                        [label]="'OTHER_THAN_AMERICAN_INDIVIDUALS' | translate"
                        [id]="'userOtherThanAmericanIndividualsNumber'"
                        [name]="'userOtherThanAmericanIndividualsNumber'"
                        [path]="'userOtherThanAmericanIndividualsNumber'"
                        [binding]="null"
                        [integerFormat]="true"
                        [attr]="{maxlength: 2}"
                    ></app-odc-field>

                    <app-odc-field  *ngIf="isUsResidentIsShowable()"
                        [type]="constants.FIELD_TYPES.RADIO"
                        [form]="form"
                        [submitted]="isFormSubmitted()"
                        [label]="getAmericanResidentLabel() | translate"
                        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                        [id]="'userOtherAmericanResidentIndicator'"
                        [name]="'userOtherAmericanResidentIndicator'"
                        [path]="'userOtherAmericanResidentIndicator'"
                        [binding]="null"
                    ></app-odc-field>
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-default" title="{{'BTN_OPEN_ADD_ENTITY'|translate}}">
                        {{'BTN_OPEN_ADD_ENTITY'|translate}}
                    </button>
                    <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                            type="button"
                            (click)="modalService.emitValidClickOnChoice1()">
                        {{'BTN_CANCEL'|translate}}
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>
