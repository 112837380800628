import {Component} from '@angular/core';
import {BrokerageAccountsComponent} from './brokerage-accounts.component';

@Component({
    selector: 'app-legal-second-holder',
    templateUrl: './brokerage-accounts.component.html'
})
export class BrokerageAccountsComponentSecondHolderComponent extends BrokerageAccountsComponent {

    public requesterIndex = 1;

}
