import {Component} from '@angular/core';
import {ResidentialAddressComponent} from './residential-address.component';

@Component({
    selector: 'app-residential-address-third-holder',
    templateUrl: './residential-address.component.html'
})
export class ResidentialAddressThirdHolderComponent extends ResidentialAddressComponent {

    public requesterIndex = 2;

}
