import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {FormArray, FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {ListModelService} from '../../components/services/list-model.service';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {AttachedDocument, WebException} from '../../components/models';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {CountryCompleterService} from '../../components/services/country-completer.service';
import {Functions} from '../../components/utils/functions';

declare let $: any;

@Component({
    selector: 'app-transmission-attachments-lba',
    templateUrl: './transmission-attachments-lba.component.html'
})
export class TransmissionAttachmentsLbaComponent extends BaseNavComponent implements OnInit, AfterViewInit {

    FIRST_ATTACHED_DOCUMENT_INDEX = 0;
    SECOND_ATTACHED_DOCUMENT_INDEX = 1;
    ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX = 2;

    userIdentityFile: string;
    userSpecimenFile: string;
    accountStatement: string;
    loading: boolean;

    showProvinceFirstIdentityFile: boolean;
    showProvinceSecondIdentityFile: boolean;
    showCountryFirstIdentityFile: boolean;
    showCountrySecondIdentityFile: boolean;
    showDateIssuanceExpiryFirstIdentityFileField: boolean;
    showDateIssuanceExpirySecondIdentityFileField: boolean;
    isDriverLicenseFirstIdentityFileSelected: boolean;
    isDriverLicenseSecondIdentityFileSelected: boolean;

    isFirstDocumentInfosValid: boolean;

    userIdentityCodeCompleteList: HtmlSelectKV[];
    userIdentityCodeList: HtmlSelectKV[];
    userIdentityCodeSecondList: HtmlSelectKV[];
    userIdentityCodeFirstListAllowed: HtmlSelectKV[];
    userIdentityCodeSecondListAllowed: HtmlSelectKV[];
    provincesList: HtmlSelectKV[] = [];
    secondProvincesList: HtmlSelectKV[] = [];
    provincesListComplete: HtmlSelectKV[] = [];
    provincesListForHealthInsuranceCard: HtmlSelectKV[] = [];
    countries: HtmlSelectKV[] = [];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private fileValidatorService: FileValidatorService,
                private listModelService: ListModelService,
                private countryCompleter: CountryCompleterService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.formService.setTransmitted(false);
        this.navigationService.emitIsBackDisabled(true);

        this.form = this.fb.group({

            firstIdentityInfos: this.fb.group({
                firstIdentityCode: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].code, [this.requiredValidator('userIdentityCode')]],
                firstIdentityIDNumber: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].idNumber, [this.requiredValidator('userIdentityIDNumber')]],
                firstIdentityExpirationDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].expirationDate, [this.requiredValidator('userIdentityExpirationDate'), VmdValidators.date, VmdValidators.dateInFuture]],
                firstIdentityDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].expirationDate, [this.requiredValidator('userIdentityExpirationDate'), VmdValidators.date]],
                firstIdentityProvince: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].province, [this.requiredValidator('userIdentityProvince')]],
                firstIdentityCountry: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.FIRST_ATTACHED_DOCUMENT_INDEX].country, [this.requiredValidator('userIdentityCountry')]],
            }),

            secondIdentityInfos: this.fb.group({
                secondIdentityCode: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].code, [this.requiredValidator('userIdentityCode')]],
                secondIdentityIDNumber: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].idNumber, [this.requiredValidator('userIdentityIDNumber')]],
                secondIdentityExpirationDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].expirationDate, [this.requiredValidator('userIdentityExpirationDate'), VmdValidators.date, VmdValidators.dateInFuture]],
                secondIdentityDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].expirationDate, [this.requiredValidator('userIdentityExpirationDate'), VmdValidators.date]],
                secondIdentityProvince: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].province, [this.requiredValidator('userIdentityProvince')]],
                secondIdentityCountry: [this.formService.getForm().requesters[this.requesterIndex].userIdentities[this.SECOND_ATTACHED_DOCUMENT_INDEX].country, [this.requiredValidator('userIdentityCountry')]],
            }),

            attachedDocuments: this.fb.array([], VmdValidators.attachedDocumentUnique()),
        });

        this.initLists();

        this.attachValuesChangesOnFirstIdentityCode();
        this.attachValuesChangesOnSecondIdentityCode();

        this.showProvinceFirstIdentityFile = this.isProvincialDocumentType(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.showCountryFirstIdentityFile = this.isNationalDocumentType(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.showDateIssuanceExpiryFirstIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.isDriverLicenseFirstIdentityFileSelected = this.form.get('firstIdentityInfos.firstIdentityCode').value === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;

        this.showProvinceSecondIdentityFile = this.isProvincialDocumentType(this.form.get('secondIdentityInfos.secondIdentityCode').value);
        this.showCountrySecondIdentityFile = this.isNationalDocumentType(this.form.get('secondIdentityInfos.secondIdentityCode').value);
        this.showDateIssuanceExpirySecondIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(this.form.get('secondIdentityInfos.secondIdentityCode').value);
        this.isDriverLicenseSecondIdentityFileSelected = this.form.get('secondIdentityInfos.secondIdentityCode').value === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;

        this.manageControls();
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();

        this.form.get('firstIdentityInfos').statusChanges.subscribe(s => {
            this.firstDocumentInfosValid();
        });

        this.form.get('attachedDocuments.' + this.FIRST_ATTACHED_DOCUMENT_INDEX + '.attachedDocument').statusChanges.subscribe(s => {
            this.firstDocumentInfosValid();
        });

        this.form.get('attachedDocuments.' + this.FIRST_ATTACHED_DOCUMENT_INDEX + '.attachedDocumentBack').statusChanges.subscribe(s => {
            this.firstDocumentInfosValid();
        });

        this.firstDocumentInfosValid();
    }

    setCountries(list: HtmlSelectKV[]): void {
        this.countries = list;
        this.initializeCountryTypeahead();
    }

    initializeCountryTypeahead(): void {
        setTimeout(() => {
            if ($('#firstIdentityCountry').length > 0 && this.countries.length > 0) {
                this.countryCompleter.initialize($('#firstIdentityCountry'), this.countries);
            }

            if ($('#secondIdentityCountry').length > 0 && this.countries.length > 0) {
                this.countryCompleter.initialize($('#secondIdentityCountry'), this.countries);
            }
        }, 0);

    }

    setProvincesLists(list: HtmlSelectKV[]) {
        this.provincesListComplete = list;
        this.provincesListForHealthInsuranceCard = [];
        for (const item of list) {
            if (!this.isNotProvinceForHealthInsurance(item.itemValue)) {
                this.provincesListForHealthInsuranceCard.push(item);
            }
        }
        this.provincesList = this.chooseProvincesList(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.secondProvincesList = this.chooseProvincesList(this.form.get('secondIdentityInfos.secondIdentityCode').value);
    }

    chooseProvincesList(identityCode: string): HtmlSelectKV[] {
        if (identityCode === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD) {
            return this.provincesListForHealthInsuranceCard;
        }
        return this.provincesListComplete;
    }

    isNotProvinceForHealthInsurance(province: string): boolean {
        for (const item of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (province === item) {
                return true;
            }
        }
        return false;
    }

    getAttachedDocumentsControl(): FormArray {
        return this.form.get('attachedDocuments') as FormArray;
    }

    setTypeDocumentLists(list: HtmlSelectKV[]) {
        this.userIdentityCodeCompleteList = list;
        this.userIdentityCodeList = list;
        this.userIdentityCodeSecondList = list;

        this.setUserIdentityCodeFirstList(this.constants.USER_IDENTITY_CODE_BANK_STATEMENT,
            this.constants.USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT,
            this.constants.USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT,
            this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE,
            this.constants.USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT,
            this.constants.USER_IDENTITY_CODE_INVEST_ACCT_STATEMENT,
            this.constants.USER_IDENTITY_CODE_PUBLIC_UTILITY_BILL,
            this.form.get('secondIdentityInfos.secondIdentityCode').value);

        this.setUserIdentityCodeSecondList(this.form.get('firstIdentityInfos.firstIdentityCode').value);
    }

    setUserIdentityCodeFirstList(...filteredValues: string[]) {
        this.userIdentityCodeList = this.userIdentityCodeCompleteList;

        if (this.isHealthInsuranceException()) {
            this.userIdentityCodeList = this.userIdentityCodeList.filter(item => item.itemValue !== this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD);
        }

        this.userIdentityCodeList = this.userIdentityCodeList.filter(item => !Functions.stringListHasValue(filteredValues, item.itemValue));
        this.userIdentityCodeFirstListAllowed = this.setUserIdentityCodeListAllowed(this.form.get('secondIdentityInfos.secondIdentityCode').value);
    }

    setUserIdentityCodeSecondList(...filteredValues: string[]) {
        this.userIdentityCodeSecondList = this.userIdentityCodeCompleteList;

        if (this.isHealthInsuranceException()) {
            this.userIdentityCodeSecondList = this.userIdentityCodeSecondList.filter(item => item.itemValue !== this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD);
        }

        this.userIdentityCodeSecondList = this.userIdentityCodeSecondList.filter(item => !Functions.stringListHasValue(filteredValues, item.itemValue));
        this.userIdentityCodeSecondListAllowed = this.setUserIdentityCodeListAllowed(filteredValues[0]);
    }

    isHealthInsuranceException(): boolean {
        for (const province of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (this.formService.getForm().requesters[this.requesterIndex].userAddressProv === province) {
                return true;
            }
        }
        return false;
    }

    submit() {
        let identityFileList: AttachedDocument[] = [];
        if (this.form.valid) {
            identityFileList = this.collectIdentityFiles();
        }

        if (identityFileList.length) {
            this.formSubmitted = true;
            this.markAsTouched(this.form);
            this.loading = true;
            this.fileValidatorService.validateFile(identityFileList, this.fileValidatorService.getVerifyIdentityFilePath()).subscribe(data => {
                if (this.form.valid) {
                    this.loading = false;
                    this.submitForm();
                }
            }, (errors: WebException) => {
                this.loading = false;

                if (errors.listWarning && errors.listWarning.length) {
                    for (const warning of errors.listWarning) {

                        const path = this.getAttachedDocumentsPath(warning.id);

                        if (warning.message === 'file.infected') {
                            this.form.get(path).setErrors({fileInfected: true});
                        } else if (warning.message === 'antivirus.notavailable') {
                            this.form.get(path).setErrors({antivirusNotAvailable: true});
                        } else if (warning.message === 'file.weight.invalid') {
                            this.form.get(path).setErrors({weightInvalid: true});
                        } else if (warning.message === 'file.pdf.encrypted') {
                            this.form.get(path).setErrors({fileEncrypted: true});
                        } else if (warning.message === 'file.extension.invalid') {
                            this.form.get(path).setErrors({invalidFormat: true});
                        } else if (warning.message === 'file.invalid.document') {
                            this.form.get(path).setErrors({invalidDocument: true});
                        }
                    }
                }
                this.focusOnErrorsList();
            });
        } else {
            this.submitForm();
        }
    }

    private initLists() {
        let identityPaperListId = this.determinateIdentityPaperListId();
        this.listModelService.getListModel(identityPaperListId, (data: HtmlSelectKV[]) => this.setTypeDocumentLists(data));
        this.listModelService.getListModel('provincesList', data => this.setProvincesLists(data));
        this.listModelService.getListModel('paysList', data => this.setCountries(data));
    }

    private attachValuesChangesOnFirstIdentityCode() {
        this.form.get('firstIdentityInfos.firstIdentityCode').valueChanges.subscribe(code => {

            this.showProvinceFirstIdentityFile = this.isProvincialDocumentType(code);
            this.provincesList = this.chooseProvincesList(code);
            this.showCountryFirstIdentityFile = this.isNationalDocumentType(code);
            this.showDateIssuanceExpiryFirstIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(code);

            this.isDriverLicenseFirstIdentityFileSelected = code === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;
            if (!this.isDriverLicenseFirstIdentityFileSelected) {
                this.form.get('attachedDocuments.' + this.FIRST_ATTACHED_DOCUMENT_INDEX + '.attachedDocumentDifferentAddress').setValue(false);
            }

            this.manageControls();
            if (this.showCountryFirstIdentityFile) {

                this.initializeCountryTypeahead();
            }

            this.setUserIdentityCodeSecondList(code);
        });
    }

    private attachValuesChangesOnSecondIdentityCode() {
        this.form.get('secondIdentityInfos.secondIdentityCode').valueChanges.subscribe(code => {

            this.showProvinceSecondIdentityFile = this.isProvincialDocumentType(code);
            this.secondProvincesList = this.chooseProvincesList(code);
            this.showCountrySecondIdentityFile = this.isNationalDocumentType(code);
            this.showDateIssuanceExpirySecondIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(code);

            this.isDriverLicenseSecondIdentityFileSelected = code === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;
            if (!this.isDriverLicenseSecondIdentityFileSelected) {
                this.form.get('attachedDocuments.' + this.SECOND_ATTACHED_DOCUMENT_INDEX + '.attachedDocumentDifferentAddress').setValue(false);
            }

            this.manageControls();
            if (this.showCountrySecondIdentityFile) {

                this.initializeCountryTypeahead();
            }

            this.setUserIdentityCodeFirstList(this.constants.USER_IDENTITY_CODE_BANK_STATEMENT,
                this.constants.USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT,
                this.constants.USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT,
                this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE,
                this.constants.USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT,
                this.constants.USER_IDENTITY_CODE_INVEST_ACCT_STATEMENT,
                this.constants.USER_IDENTITY_CODE_PUBLIC_UTILITY_BILL,
                code);
        });
    }

    private firstDocumentInfosValid() {
        this.isFirstDocumentInfosValid = this.form.get('firstIdentityInfos').valid &&
            this.form.get('attachedDocuments.0.attachedDocument').valid &&
            (this.form.get('attachedDocuments.0.attachedDocumentBack').disabled || this.form.get('attachedDocuments.0.attachedDocumentBack').valid);
    }

    private manageControls() {
        this.updateControlEnabled(this.form.get('firstIdentityInfos.firstIdentityProvince'), this.showProvinceFirstIdentityFile);
        this.updateControlEnabled(this.form.get('firstIdentityInfos.firstIdentityCountry'), this.showCountryFirstIdentityFile);
        this.updateControlEnabled(this.form.get('firstIdentityInfos.firstIdentityDate'), this.showDateIssuanceExpiryFirstIdentityFileField);
        this.updateControlEnabled(this.form.get('firstIdentityInfos.firstIdentityExpirationDate'), !this.showDateIssuanceExpiryFirstIdentityFileField);

        this.updateControlEnabled(this.form.get('secondIdentityInfos.secondIdentityProvince'), this.showProvinceSecondIdentityFile);
        this.updateControlEnabled(this.form.get('secondIdentityInfos.secondIdentityCountry'), this.showCountrySecondIdentityFile);
        this.updateControlEnabled(this.form.get('secondIdentityInfos.secondIdentityDate'), this.showDateIssuanceExpirySecondIdentityFileField);
        this.updateControlEnabled(this.form.get('secondIdentityInfos.secondIdentityExpirationDate'), !this.showDateIssuanceExpirySecondIdentityFileField);

        this.clearProvinceField(this.showProvinceFirstIdentityFile, this.FIRST_ATTACHED_DOCUMENT_INDEX, 'firstIdentityInfos.firstIdentityProvince');
        this.clearProvinceField(this.showProvinceSecondIdentityFile, this.SECOND_ATTACHED_DOCUMENT_INDEX, 'secondIdentityInfos.secondIdentityProvince');

        this.clearCountryField(this.showCountryFirstIdentityFile, this.FIRST_ATTACHED_DOCUMENT_INDEX, 'firstIdentityInfos.firstIdentityCountry');
        this.clearCountryField(this.showCountrySecondIdentityFile, this.SECOND_ATTACHED_DOCUMENT_INDEX, 'secondIdentityInfos.secondIdentityCountry');
    }

    private clearProvinceField(provinceIsShowing: boolean, attachedDocumentIndex: number, controlPath: string) {
        if (!provinceIsShowing) {
            this.formService.setFormProperty(this.getRequesterPath('userIdentities.' + attachedDocumentIndex + '.province'), null);
            this.form.get(controlPath).setValue(null);
        }
    }

    private clearCountryField(countryIsShowing: boolean, attachedDocumentIndex: number, controlPath: string) {
        if (!countryIsShowing) {
            this.formService.setFormProperty(this.getRequesterPath('userIdentities.' + attachedDocumentIndex + '.country'), null);
            this.form.get(controlPath).setValue(null);
        }
    }

    private isProvincialDocumentType(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE
            || code === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD
            || code === this.constants.USER_IDENTITY_CODE_PROVINCIAL_TERRITORIAL_CARD
            || code === this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE;
    }

    private isNationalDocumentType(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_PASSPORT
            || code === this.constants.USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT;
    }

    private documentTypeHasIssuanceOrExpirationDate(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE
            || code === this.constants.USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_INVEST_ACCT_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_PUBLIC_UTILITY_BILL
            || code === this.constants.USER_IDENTITY_CODE_BANK_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT;
    }

    private determinateIdentityPaperListId() {
        if (this.isGpdOrSfd()) {
            return 'identityPaperGpdList';
        }

        return 'identityPaperDclWebList';
    }

    private collectIdentityFiles(): AttachedDocument[] {
        const identityFileList: AttachedDocument[] = [];

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            let name = control.get('attachedDocumentName').value;
            if (control.get('attachedDocument').value && control.get('attachedDocumentContent').value) {
                const accountStatement = new AttachedDocument();
                accountStatement.name = control.get('attachedDocument').value;
                accountStatement.content = control.get('attachedDocumentContent').value;
                accountStatement.validation.contentHash = control.get('attachedDocumentContentHash').value;
                accountStatement.validation.contentHashCalculated = control.get('attachedDocumentContentHashCalculated').value;
                accountStatement.validation.fileName = control.get('attachedDocumentName').value
                accountStatement.id = name;
                identityFileList.push(accountStatement);
            }

            name = control.get('attachedDocumentNameBack').value;
            if (control.get('attachedDocumentBack').value && control.get('attachedDocumentContentBack').value) {
                const accountStatement = new AttachedDocument();
                accountStatement.name = control.get('attachedDocumentBack').value;
                accountStatement.content = control.get('attachedDocumentContentBack').value;
                accountStatement.validation.contentHash = control.get('attachedDocumentContentHashBack').value;
                accountStatement.validation.contentHashCalculated = control.get('attachedDocumentContentHashCalculatedBack').value;
                accountStatement.id = name;
                identityFileList.push(accountStatement);
            }
        });

        return identityFileList;
    }

    private getAttachedDocumentsPath(name: string): string {
        let path = null;

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            if (control.get('attachedDocumentName').value === name) {
                path = 'attachedDocuments.' + index + '.attachedDocument';
            }

            if (control.get('attachedDocumentNameBack').value === name) {
                path = 'attachedDocuments.' + index + '.attachedDocumentBack';
            }
        });

        return path;
    }

    private getFilteredUserIdentityCodeListAllowed(filteredValues: string[]) {
        return this.userIdentityCodeCompleteList.filter(item => Functions.stringListHasValue(filteredValues, item.itemValue));
    }

    private setUserIdentityCodeListAllowed(userIdentityCode: string) {
        let filteredValues: string[] = [];
        if (this.constants.USER_IDENTITY_CODE_CATEGORY_12.includes(userIdentityCode)) {
            filteredValues = [...this.constants.USER_IDENTITY_CODE_CATEGORY_1,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_2,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_3];
            return this.getFilteredUserIdentityCodeListAllowed(filteredValues);
        }

        if (this.constants.USER_IDENTITY_CODE_CATEGORY_1.includes(userIdentityCode)) {
            filteredValues = [...this.constants.USER_IDENTITY_CODE_CATEGORY_12,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_2,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_3];
            return this.getFilteredUserIdentityCodeListAllowed(filteredValues);
        }

        if (this.constants.USER_IDENTITY_CODE_CATEGORY_2.includes(userIdentityCode)) {
            filteredValues = [...this.constants.USER_IDENTITY_CODE_CATEGORY_12,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_1,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_3];
            return this.getFilteredUserIdentityCodeListAllowed(filteredValues);
        }

        if (this.constants.USER_IDENTITY_CODE_CATEGORY_3.includes(userIdentityCode)) {
            filteredValues = [...this.constants.USER_IDENTITY_CODE_CATEGORY_12,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_1,
                ...this.constants.USER_IDENTITY_CODE_CATEGORY_2];
            return this.getFilteredUserIdentityCodeListAllowed(filteredValues);
        }
    }
}
