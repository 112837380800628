<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">
    <h2>
        {{'ACCOUNT_TYPE_ENTITIES_QUESTIONS_TITLE' | translate}}
    </h2>

    <div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th class="va-top text-center">{{'ENTITY_TYPE' | translate}}</th>
                <th class="va-top text-center">{{'ENTITY_NAME' | translate}}</th>
                <th class="va-top text-center">{{'AMERICAN_INDIVIDUALS_TABLE' | translate}}</th>
                <th class="va-top text-center">{{'OTHER_THAN_AMERICAN_INDIVIDUALS_TABLE' | translate}}</th>
                <th class="va-top text-center">{{'AMERICAN_RESIDENT' | translate}}</th>
                <th class="va-top text-center"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let entity of formService.getForm().entities; let i = index;">
                <tr class="entity-parent-row">
                    <td [ngClass]="{'text-center' : true, 'shadow' : entityHasChild(entity)}" [innerHTML]="'ENTITY_TYPE_' + entity.entityType | translate"></td>
                    <td [ngClass]="{'text-center' : true, 'shadow' : entityHasChild(entity)}">{{entity.entityName}}</td>
                    <td [ngClass]="{'text-center' : true, 'shadow' : entityHasChild(entity)}">{{entity.physicalPersonsUs}}</td>
                    <td [ngClass]="{'text-center' : true, 'shadow' : entityHasChild(entity)}">{{entity.physicalPersonsOtherThanUs}}</td>
                    <td [ngClass]="{'text-center' : true, 'shadow' : entityHasChild(entity)}">
                        <span *ngIf="null !== entity.isUsResident">{{getIsUsResidentBoolean(entity.isUsResident) | translate}}</span>
                    </td>

                    <td [ngClass]="{'shadow' : entityHasChild(entity)}" class="text-nowrap">
                        <button
                            *ngIf="isAllowedToAddSubEntity(entity.entityType)"
                            type="button"
                            class="btn btn-primary btn-entity"
                            title="{{ 'BTN_OPEN_ADD_SUB_ENTITY' | translate }}"
                            (click)="modalService.emitOpenAddEntity(entity)"
                        >
                            <span aria-hidden="true">+</span>
                            <span class="sr-only">{{ 'BTN_OPEN_ADD_SUB_ENTITY' | translate }}</span>
                        </button>
                        <button
                            type="button"
                            class="btn btn-default btn-entity"
                            title="{{ 'BTN_OPEN_DELETE_ENTITY' | translate }}"
                            (click)="deleteRow(i, null)"
                        >
                            <span aria-hidden="true">-</span>
                            <span class="sr-only">{{ 'BTN_OPEN_DELETE_ENTITY' | translate }}</span>
                        </button>
                    </td>
                </tr>
                <ng-container *ngFor="let subentity of entity.entities; let j = index;">
                    <tr class="entity-child-row">
                        <td class="text-center" [innerHTML]="'ENTITY_TYPE_' + subentity.entityType | translate"></td>
                        <td class="text-center">{{subentity.entityName}}</td>
                        <td class="text-center">{{subentity.physicalPersonsUs}}</td>
                        <td class="text-center">{{subentity.physicalPersonsOtherThanUs}}</td>
                        <td class="text-center">
                            <span *ngIf="null !== subentity.isUsResident">{{getIsUsResidentBoolean(subentity.isUsResident) | translate}}</span>
                        </td>
                        <td>
                            <button
                                type="button"
                                class="btn btn-default btn-entity"
                                title="{{ 'BTN_OPEN_DELETE_SUB_ENTITY' | translate }}"
                                (click)="deleteRow(i, j)"
                            >
                                <span aria-hidden="true">-</span>
                                <span class="sr-only">{{ 'BTN_OPEN_DELETE_SUB_ENTITY' | translate }}</span>
                            </button>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            </tbody>
        </table>

    </div>

    <span *ngIf="isPleinEx() && isSubEntitySENC_FIDU_S_SOC_COMM(formService.getForm().entities)" class="text-danger">{{'ENTITY_MSG_PLEINEX_CONTACT_DISTRIBUTOR_SERVICE' | translate}}</span>
    <span *ngIf="!isPleinEx() && isSubEntitySENC_FIDU_S_SOC_COMM(formService.getForm().entities)" class="text-danger">{{'ENTITY_MSG_DISNAT_CONTACT_CUSTOMER_SERVICE' | translate}}</span>
    <br><br>

    <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_OPEN_ADD_ENTITY'|translate}}" type="button"
            (click)="modalService.emitOpenAddEntity(null)">
        {{'BTN_OPEN_ADD_ENTITY'|translate}}
    </button>
    <span class="sr-only">{{ 'BTN_OPEN_ADD_ENTITY' | translate }}</span>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
