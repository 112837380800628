import {Inject, Injectable} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {FormService} from './form.service';
import {VmdValidators} from '../validators/vmd-validators';
import {FormControl} from '@angular/forms';
import {VmdConstants} from '../constants/vmd-constants';

@Injectable()
export class AccountRulesService {

    constructor(
        private formService: FormService,
        @Inject(ODC_CONFIG) private config: IOdcConfig
    ) {
    }

    howOld(): number {
        const now = new Date();
        return this.howOldAtOneDate(now.getMonth(), now.getDate());
    }

    howOldAtOneDate(month: number, day: number): number {
        if (this.isValidDOBAndNotNull()) {
            const dateElements = this.formService.getForm().requesters[0].userDOB.split('-');

            const dob = new Date();
            dob.setFullYear(parseInt(dateElements[0], 10), parseInt(dateElements[1], 10) - 1, parseInt(dateElements[2], 10));
            const now = new Date();
            now.setMonth(month, day);

            return moment(now).diff(moment(dob), 'years');
        }

        return null;
    }

    isValidDOBAndNotNull(): boolean {
        if (
            this.formService.getForm().requesters !== null
            && this.formService.getForm().requesters.length > 0
            && this.formService.getForm().requesters[0].userDOB === null
        ) {
            return false;
        }
        const control = new FormControl();
        control.setValue(this.formService.getForm().requesters[0].userDOB);
        return VmdValidators.date(control) === null;
    }

    processFRVRule4AB_BC(): boolean {
        return this.formService.getForm().addLIF === true
            && (
                this.formService.getForm().lifJurisdiction === VmdConstants.ALBERTA
                || this.formService.getForm().lifJurisdiction === VmdConstants.BRITISH_COLUMBIA
            )
            && this.isValidDOBAndNotNull()
            && this.howOld() < 50;
    }

    processFRVRule4NS_ON(): boolean {
        return this.formService.getForm().addLIF === true
            && (
                this.formService.getForm().lifJurisdiction === VmdConstants.NOVA_SCOTIA
                || this.formService.getForm().lifJurisdiction === VmdConstants.ONTARIO
            )
            && this.isValidDOBAndNotNull()
            && this.howOld() < 55;
    }

    processCRIRule(): boolean {
        return this.formService.getForm().addLIRA === true
            && this.isValidDOBAndNotNull()
            && this.howOldAtOneDate(11, 31) > 71;
    }

    processREERRule(): boolean {
        return (
            this.formService.getForm().addRRSP === true
            && this.isValidDOBAndNotNull()
            && this.howOldAtOneDate(11, 31) > 71
        );
    }

    processFHSARule(): boolean {
        return (
            this.config.TOGGLE_CELI_APP
            && this.formService.getForm().addFHSA === true
            && this.isValidDOBAndNotNull()
            && this.howOldAtOneDate(11, 31) > 71
        );
    }

    processFHSARuleCountry(): boolean {
        return (
            this.config.TOGGLE_CELI_APP
            && this.formService.getForm().addFHSA === true
            && this.formService.getForm().requesters !== null
            && this.formService.getForm().requesters.length > 0
            && this.formService.getForm().requesters[0].userAddressCountry !== VmdConstants.COUNTRIES.CANADA
        );
    }


    processRESPSecondAccountholderSpouseRule(): boolean {
        return (
            this.formService.getForm().cltType === VmdConstants.CLT_TYPE_CONJOINT
            && this.formService.getForm().addRESP === true
            && this.formService.getForm().jointActPeople === 2
            && this.formService.getForm().jointSecondAccountholderSpouse === false
        );
    }

    processRRNVRule(): boolean {
        return (
            this.formService.getForm().addFTARRSP === true
            && this.formService.getForm().requesters !== null
            && this.formService.getForm().requesters.length > 0
            && this.formService.getForm().requesters[0].userAddressProv !== null
            && this.formService.getForm().requesters[0].userAddressProv !== VmdConstants.QUEBEC
        );
    }
}
