<h1>{{ getErrorTitle() | translate }}</h1>


<div class="row">
    <div class="col-md-16">
            <p>{{ getErrorDescription() | translate }}</p>
    </div>
    <div class="col-md-8">
        <app-aside-caisse [showConsult]="false" [showSave]="false"></app-aside-caisse>
    </div>
</div>
