import {Directive, HostListener} from '@angular/core';

import {VmdKeyboard} from '../utils/vmd-keyboard';

@Directive({
    selector: '[appNumericFormat]',
    providers: [
        VmdKeyboard
    ]
})
export class NumericFormatDirective {

    constructor(private keyboard: VmdKeyboard) {
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (!this.keyboard.isKeyDownAllowed(event)) {
            event.preventDefault();
        }
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (this.keyboard.isKeyPressNumeric(event)) {
            return;
        }

        event.preventDefault();
    }

}
