export type ClientLoginBeanType = {
    email: string;
    password: string;
    reCaptchaResponse: string;
}

export class ClientLoginBean {

    constructor(public email: string,
                public password: string,
                public reCaptchaResponse: string) {
    }

    static fromObject(obj?: ClientLoginBeanType): ClientLoginBean {
        if (!obj) {
            return new ClientLoginBean(null, null, null)
        }

        return new ClientLoginBean(obj.email, obj.password, obj.reCaptchaResponse)
    }
}
