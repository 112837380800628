import {VmdFormatDate} from './vmd-format-date';
import {VmdFormatNumeric} from './vmd-format-numeric';
import {VmdFormatPhone} from './vmd-format-phone';
import {VmdFormatSin} from './vmd-format-sin';
import {VmdFormatZipCode} from './vmd-format-zip-code';
import {VmdFormatAddress} from './vmd-format-address';

export class VmdFormat {
    static date: VmdFormatDate = new VmdFormatDate();
    static numeric: VmdFormatNumeric = new VmdFormatNumeric();
    static phone: VmdFormatPhone = new VmdFormatPhone();
    static sin: VmdFormatSin = new VmdFormatSin();
    static zipCode: VmdFormatZipCode = new VmdFormatZipCode();
    static address: VmdFormatAddress = new VmdFormatAddress();

    static removeAccents(text: string): string {
        const accentsMap = [
            'áàâéêèëÉíÎîïóôúç',
            'aaaeeeeeiiiioouc'
        ];

        let ret = '';
        if (!text) {
            return ret;
        }

        for (let i = 0; i < text.length; i++) {
            const pos = accentsMap[0].indexOf(text.charAt(i));
            if (pos !== -1) {
                ret += accentsMap[1].charAt(pos);
            } else {
                ret += text.charAt(i);
            }
        }
        return ret;
    }
}
