import {Component} from '@angular/core';
import {ModalService} from '../../../components/services/modal.service';

@Component({
    selector: 'app-modal-personal-information-second-accountholder-spouse',
    template: `
        <div id="modal-alert-personal-information-second-accountholder-spouse" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-alert-personal-information-second-accountholder-spouse-label" aria-describedby="modal-alert-personal-information-second-accountholder-spouse-desc">
            <div class="modal-dialog">
                <div class="modal-content" role="document" tabindex="0">
                    <div class="modal-header">
                        <h2 class="modal-title" id="modal-alert-personal-information-second-accountholder-spouse-label" data-loop="start" tabindex="0"></h2>
                    </div>
                    <div class="modal-body" id="modal-alert-personal-information-second-accountholder-spouse-desc">{{'ODC_STEP_PERSONAL_INFORMATION_MODAL_RESP_JOINT_SECOND_ACCOUNTHOLDER_SPOUSE'|translate}}</div>
                    <div class="modal-footer">
                        <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_REMOVE_ACCOUNT'|translate}}" type="submit"
                                (click)="modalService.emitValidClickOnChoice2()">
                            {{'BTN_REMOVE_ACCOUNT'|translate}}
                        </button>
                        <button class="btn btn-primary" data-dismiss="modal" title="{{'BTN_MODIFY_REQUEST'|translate}}" type="submit"
                                (click)="modalService.emitValidClickOnChoice1()">
                            {{'BTN_MODIFY_REQUEST'|translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ModalPersonalInformationSecondAccountholderSpouseComponent {

    constructor(public modalService: ModalService) {
    }
}
