<h5 *ngIf="!isConjointDCL">
    <span *ngIf="formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU && !isSourceCaisse()">
        {{'ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL' | translate}}
    </span>
    <span *ngIf="formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU && isSourceCaisse()">
        {{'ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL_CAISSE' | translate}}
    </span>
    <span *ngIf="formService.getForm().cltType === constants.CLT_TYPE_CONJOINT">
        {{'ADDITIONAL_FORM_INFORMATION_TITLE_JOINT' | translate}}
    </span>
</h5>

<ul *ngIf="formService.getAdditionalFormsList().length && formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU">
    <li *ngFor="let additionForm of formService.getAdditionalFormsList()">
        {{additionForm | translate}}
    </li>
</ul>

<div *ngIf="!isConjointDCL" class="panel panel-default panel-noborder m-t-5">
    <div class="panel-body">
        <div class="row">
            <div *ngIf="!isOtherAccountDCL" class="col-md-6">
                <img [src]="'assets/img/pdf-download.png'" alt="">
            </div>
            <div *ngIf="!isOtherAccountDCL" class="col-md-16">
                <!--                <div>
                                    <button class="btn btn-primary btn-block loadable"
                                            type="button"
                                            (click)="downloadPDF()"
                                            [class.loading]="loadingDownload"
                                            [class.m-t-5]="isSourceCaisse()? true: formService.getForm().requesters[0].userOtherTradingPersonIndicator"
                                            [attr.disabled]="loadingDownload ? true : null">
                                        <i class="fa fa-arrow-circle-down"></i>
                                        {{'TRANSMISSION_ESIGN_MAIL_DOWNLOAD_BTN' | translate}}
                                    </button>
                                </div>-->
                <!--                <div *ngIf="!formService.getForm().requesters[0].userOtherTradingPersonIndicator && !isSourceCaisse()">
                                  &lt;!&ndash;  <div class="m-t-3 m-b-3 text-center">{{'ODC_OR' | translate}}</div>- &ndash;&gt;
                                    <button class="m-t-5 btn btn-default btn-block loadable"
                                            type="button"
                                            (click)="emailPDF()"
                                            [class.loading]="loadingEmail"
                                            [attr.disabled]="loadingEmail ? true : null">
                                        <i class="fa fa-envelope"></i>
                                        {{'TRANSMISSION_ESIGN_MAIL_EMAIL_BTN' | translate}}
                                    </button>
                                </div>-->

                <div>
                    <div class="m-t-5 text-center">{{'ODC_PDF_LIST_TEXT' | translate}}</div>
                    <div class="m-t-5 text-center">
                        <a href="{{'ODC_PDF_LIST_LINK' | translate}}" class="lien-externe" target="_blank">
                            {{'ODC_PDF_LIST' | translate}}
                        </a>
                    </div>
                </div>
                <div *ngIf="error !== null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>
            </div>
            <div *ngIf="isOtherAccountDCL" class="text-center" [innerHTML]="'ODC_PDF_LIST_OTHER_ACCOUNT_TEXT' | translate"></div>
        </div>
    </div>
</div>

<ol *ngIf="formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU" class="m-t-5">
    <li>{{'TRANSMISSION_MAIL_STEP2' | translate}}</li>
    <li>{{'TRANSMISSION_MAIL_STEP3' | translate}}</li>
    <li *ngIf="!isSourceCaisse()">{{'TRANSMISSION_MAIL_STEP4' | translate}}</li>
    <li *ngIf="isSourceCaisse()">{{'TRANSMISSION_MAIL_STEP4_CAISSE' | translate}}</li>
</ol>

<div *ngIf="formService.getForm().cltType === constants.CLT_TYPE_CONJOINT" class="m-t-5">
    <h3>{{'TRANSMISSION_WHAT_NEXT'|translate}}</h3>
    <ol>
        <li [innerHTML]="(isConjointDCL ? 'TRANSMISSION_MAIL_JOINT_STEP1' : 'TRANSMISSION_MAIL_STEP1') | translate"></li>
        <li>{{'TRANSMISSION_MAIL_STEP2' | translate}}</li>
        <li>{{'TRANSMISSION_MAIL_JOINT_STEP3' | translate}}</li>
        <li>{{'TRANSMISSION_MAIL_JOINT_STEP4' | translate}}</li>
    </ol>
</div>

<h3 *ngIf="!isSourceCaisse()" class="m-t-5">{{'TRANSMISSION_MAIL_ADDRESS_TITLE' | translate}}</h3>
<h3 *ngIf="isSourceCaisse()" class="m-t-5">{{'TRANSMISSION_MAIL_ADDRESS_TITLE_CAISSE' | translate}}</h3>
<div class="row">
    <div class="col-md-12">
        <h4 class="h5">{{'TRANSMISSION_MAIL_ADDRESS_MONTREAL1' | translate}}</h4>
        <address>
            {{ 'TRANSMISSION_MAIL_ADDRESS_MONTREAL2' | translate }}<br/>
            {{ 'TRANSMISSION_MAIL_ADDRESS_MONTREAL3' | translate }}
        </address>
    </div>
    <div *ngIf="!isSourceCaisse()" class="col-md-12">
        <h4 class="h5">{{'TRANSMISSION_MAIL_ADRESS_QUEBEC1' | translate}}</h4>
        <address>
            {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC2' | translate }}<br/>
            {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC3' | translate }}<br/>
            {{ 'TRANSMISSION_MAIL_ADRESS_QUEBEC4' | translate }}
        </address>
    </div>
</div>

<div class="btn-resp btn-resp-1colonne-inline m-t-4">
    <div class="btn-resp-col btn-resp-demi">
        <button type="button"
                class="btn btn-primary loadable"
                [attr.disabled]="disableNext ? true : null"
                (click)="navNext()">
            {{'BTN_NEXT' | translate}}
        </button>
    </div>
    <div class="btn-resp-col btn-resp-demi">
        <button *ngIf="showBack() && formService.getForm().cltType === constants.CLT_TYPE_INDIVIDU"
                type="button"
                class="btn btn-default"
                (click)="navBack()">
            {{'BTN_BACK' | translate}}
        </button>
    </div>
</div>
