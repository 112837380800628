export const SummaryPathConstantsPleinEx = {
    2: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
    3: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
    4: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
    5: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
    6: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH'
};

export const SummaryPathConstantsSSD = {
    2: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH',
    3: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH',
    4: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH',
    5: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH',
    6: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH'
};

export const SummaryPathConstantsGPD = {
    2: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH',
    3: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH',
    4: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH',
    5: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH',
    6: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH'
};

export const SummaryPathConstants = {
    2: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PATH',
    3: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PATH',
    4: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH',
    5: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PATH',
    6: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PATH'
};

