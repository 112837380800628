export const clientsPersonalInformationPage = [
    'userLang',
    'userHomePhone',
    'userOtherPhone',
    'userMobilePhone',
    'userEmail',
    'homeAddress',
    'mailingAddress'
];

export const fiscalResidencyPage = [
    'fiscalUsCitizenshipIndicator',
    'UserFiscalRelationCanada',
    'UserFiscalRelationUsa',
    'userCitizenship',
    'userCitizenshipUS',
    'addUserOtherCitizenship',
    'UserOtherCitizenship',
    'userFiscalRelationCanada',
    'userFiscalRelationUsa',
    'UserFiscalRelationOther',
    'UsaNif',
    'UserNifMissingReason',
    'UserCountryNif2',
    'CodePremierAutreNif',
    'UserNifMissingReason2',
    'UserCountryNif3',
    'CodeSecondAutreNif',
    'UserNifMissingReason3'
];

export const occupationAndEmployer = [
    'userJobStatus',
    'userEmployerName',
    'userSameAddressWithBrokerEmployeeIndicator',
    'userJobTitle'
];

export const clientMaritalStatus = [
    'userMaritalStatus',
    'spouseGender',
    'spouseFirstName',
    'spouseLastName'
];

export const brokerageAccount = [
    'userInitieIndicator',
    'userMainShareholderIndicator',
    'userPoliticallyExposedForeignPersonIndicator',
    'userFinancialInterestIndicator',
    'userOtherGuarantingPersonIndicator',
    'userTrustedPersonIndicator',
    'userBrokerageKnowledge'
];

export const financialInformation = [
    'userAnnualIncome',
    'userTotalNetWorth',
    'borrowedForInvestmentPurposeIndicator',
    'borrowingReceivedAndReadBorrowingRisksIndicator'
];

export const investObjectivesAndRiskFactors = [
    'userInvestmentObjectivesIntendedUse',
    'userInvestmentObjectivesRskTol',
    'userInvestmentObjectivesNonRegInvHorizon',
    'userInvestmentObjectivesLowRiskReg',
    'userInvestmentObjectivesModerateRiskReg',
    'userInvestmentObjectivesModHighRiskReg',
    'userInvestmentObjectivesSpeculativeStrategiesReg'
];

export const beneficiary = [
    'userDisclaimerIndicator',
    'userReceiptDocumentSecuritiesHolderIndicator',
    'userElectronicDocumentSecuritiesHolderIndicator',
];

export const specificConsent = [
    'personalInformationSpecificConsentIndicator'
];
