export class AdvisorInvestmentMeetingCustomer {

    public title: string;
    public firstName: string;
    public lastName: string;
    public dateMeeting: string;

    constructor(obj?: any) {
        if (obj) {

            this.title = this.init(obj.title);
            this.firstName = this.init(obj.firstName);
            this.lastName = this.init(obj.lastName);
            this.dateMeeting = this.init(obj.dateMeeting);
        }
    }

    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }
}
