export const VmdConstants = {
    LANG_EN: 'en',
    LANG_FR: 'fr',
    LANG_FR_CA: 'fr-ca',
    ALLOWED_LANGUAGES_REGEX: /en|fr/,

    USERNAME_REGEXP: /^[0-9]([A-Za-z0-9]){4}$/,
    CLIENT_NUMBER_REGEXP: /^[0-9]([A-Za-z0-9]){4}$/,
    ALPHA_NUM_REGEXP: /^[0-9a-zA-Z]+$/,
    ALPHA_NUM_AND_DASH_REGEXP: /^[0-9a-zA-Z-]+$/,
    DIGIT_REGEXP: /^\d+$/,
    EMAIL_REGEXP: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    PHONE_REGEXP: /^\d{10}$/,
    CANADA_ZIP_CODE_REGEXP: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
    USA_ZIP_CODE_REGEXP: /^(?!0{5})(\d{5})(\d{4})?$/i,
    HAS_DIGIT_REGEXP: /^.*\d+.*$/,
    POST_BOX_REGEXP: /^.*(Casier postal|Case postale|C\.P\.|CP|BOX).*$/i,
    UPLOADED_FILE_LIMIT_ACCOUNNT_STATEMENTS: 1572864,
    UPLOADED_FILE_LIMIT: 614400,

    DATE_FORMAT_EN: 'MMM D, YYYY',
    DATE_FORMAT_FR: 'D MMMM YYYY',
    DATE_FORMAT_INPUT: 'YYYY-MM-DD',
    DATE_FORMAT_INPUT_ALT: 'YYYYMMDD',

    ACCOUNT_NEW_CLIENT: 'NEW_CLIENT',
    ACCOUNT_ADD_ACCOUNT: 'ADD_ACCOUNT',
    ACCOUNT_REDOCUMENT: 'CLT_REDOC',
    ACCOUNT_UPDATE_CLIENT: 'UPDATE',
    ACCOUNT_CONVERTED: 'CLT_CONVERT',
    ACCOUNT_TYPE_CASH: 'CASH',
    ACCOUNT_TYPE_MARGIN: 'MRGN',
    ACCOUNT_TYPE_RRSP: 'RRSP',
    ACCOUNT_TYPE_RRIF: 'RRIF',
    ACCOUNT_TYPE_FTARRSP: 'FTARRSP',
    RESP_TYPE_FAMILY: 'FAMILY',
    RESP_TYPE_INDIVIDUAL: 'INDIVIDUAL',
    RESP_TYPE_FAMILY_INDIVIDUAL: 'FAMILY_INDIVIDUAL',
    MGNT_TYPE_COM: 'COM',
    MGNT_TYPE_MAND: 'MAND',
    MGNT_TYPE_ASSET: 'ASSET',

    INVESTMENT_ADVICE_COMMISSION_BASED: 'COMMISSION_BASED',
    INVESTMENT_ADVICE_ELITE_BASIC: 'ELITE_BASIC',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE: 'ELITE_INTERMEDIATE',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE: 'ELITE_FLEXIBLE',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM: 'ADVISOR_PROGRAM',
    PORTFOLIO_MANAGEMENT_MANDATE_ONLY: 'MANDATE_ONLY',

    PERCENTAGE_FORMAT_EN: '%',
    PERCENTAGE_FORMAT_FR: ' %',
    INTEGER_FORMAT_EN: '0,0',
    INTEGER_FORMAT_FR: '0,0',
    CURRENCY_FORMAT_EN: '$0,0',
    CURRENCY_FORMAT_FR: '0,0 $',
    CURRENCY_DECIMAL_FORMAT_EN: '$0,0.00',
    CURRENCY_DECIMAL_FORMAT_FR: '0,0.00 $',
    CURRENCY_CAD: 'CAD',
    CURRENCY_USD: 'USD',
    SECURITY_QUANTITY_FORMAT_EN: '0,0.00000',
    SECURITY_QUANTITY_FORMAT_FR: '0,0.00000',
    INTEREST_RATE_FORMAT_EN: '0.00',
    INTEREST_RATE_FORMAT_FR: '0.00',

    ACCOUNT_TRANSFER_CASH: 'CASH',
    ACCOUNT_TRANSFER_RRSP: 'RRSP',
    ACCOUNT_TRANSFER_TFSA: 'TFSA',
    ACCOUNT_TRANSFER_FHSA: 'FHSA',
    ACCOUNT_TRANSFER_RESP: 'RESP',
    ACCOUNT_TRANSFER_LIRA: 'LIRA',
    ACCOUNT_TRANSFER_RRIF: 'RRIF',
    ACCOUNT_TRANSFER_LIF: 'LIF',

    TRANSFER_TYPE_TOTAL: 'TOTAL',
    TRANSFER_TYPE_PARTIAL: 'PARTIAL',
    TRANSFER_INSTRUCTION_CASH: 'CASH',
    TRANSFER_INSTRUCTION_KIND: 'KIND',
    TRANSFER_INSTRUCTION_MIXED: 'MIXED',

    MIN_AGE_ALL: 0,
    MIN_AGE: 18,
    MAX_AGE: 150,
    MAX_AGE_RRSP: 71,

    MRS: 'MRS',
    MR: 'MR',

    MARKET_CAN: 'CAN',
    MARKET_US: 'US',

    DISNAT_DIRECT: 'DIRECT',
    DISNAT_CLASSIC: 'CLASSIC',

    DIRECT_DEPOSIT: 'DIRECT_DEPOSIT',
    DIRECT_WITHDRAWAL: 'DIRECT_WITHDRAWAL',

    QUEBEC: 'CANQC',
    ALBERTA: 'CANAB',
    BRITISH_COLUMBIA: 'CANBC',
    MANITOBA: 'CANMB',
    NEW_BRUNSWICK: 'CANNB',
    NEWFOUNDLAND: 'CANNL',
    NORTHWEST_TERRITORIES: 'CANNT',
    NOVA_SCOTIA: 'CANNS',
    NUNAVUT: 'CANNU',
    ONTARIO: 'CANON',
    PRINCE_EDWARD_ISLAND: 'CANPE',
    SASKATCHEWAN: 'CANSK',
    YUKON: 'CANYT',
    FEDERAL_RESTRICTED: 'FEDERAL_RESTRICTED',
    FEDERAL: 'FEDERAL',
    PROVINCES_HEALTH_INSURANCE_EXCEPTION: ['CANON', 'CANNB', 'CANNS', 'CANMB', 'CANPE'],

    MARITAL_STATUS_LIVING_COMMON_LAW: 'LIVING_COMMON_LAW',
    MARITAL_STATUS_MARRIED: 'MARRIED',
    MARITAL_STATUS_SINGLE: 'SINGLE',

    EMPLOYMENT_STATUS_FULL_TIME_STUDENT: 'FULL_TIME_STUDENT',
    EMPLOYMENT_STATUS_RETIRED: 'RETIRED',
    EMPLOYMENT_STATUS_PAID_EMPLOYEE: 'PAID_EMPLOYEE',
    EMPLOYMENT_STATUS_UNEMPLOYED: 'UNEMPLOYED',
    EMPLOYMENT_STATUS_SELF_EMPLOYED: 'SELF_EMPLOYED',

    USER_EMPLOYER_IS_BROKER_NO: 'NO',
    USER_EMPLOYER_IS_BROKER_VMD: 'VMD',
    USER_EMPLOYER_IS_BROKER_OTHER_BROKER: 'OTHER_BROKER',
    USER_EMPLOYER_NAME_FIELDNAME: 'userEmployerName',
    USER_JOBTITLE_FIELDNAME: 'userJobTitle',
    USER_SAMEADRESS_WITH_BROKER_EMPLOYEE_VMD_YES: 'YES',

    USER_EMPLOYER_ACTIVITY_FINANCE_AND_INSURANCE_KEY: '10',

    PERSONAL_ACCOUNT_ROUTES_CONFIG_KEY: 'personalAccount',
    JOINT_ACCOUNT_ROUTES_CONFIG_KEY: 'jointAccount',
    OTHER_ACCOUNT_ROUTES_CONFIG_KEY: 'otherAccount',
    PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY: 'personalAccountPleinEx',
    JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY: 'jointAccountPleinEx',
    OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY: 'otherAccountPleinEx',
    ADD_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY: 'addAccountPleinEx',
    PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG_KEY: 'personalAccountGpd',
    JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY: 'jointAccountGpd',
    OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY: 'otherAccountGpd',
    PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG_KEY: 'personalAccountSsd',
    JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY: 'jointAccountSsd',
    OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY: 'otherAccountSsd',
    PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY: 'personalAccountUpdatePleinEx',
    PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY: 'personalAccountUpdateSsd',
    PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY: 'personalAccountUpdateGpd',
    PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY: 'personalAccountUpdate',
    JOINT_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY: 'jointAccountUpdatePleinEx',
    JOINT_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY: 'jointAccountUpdateSsd',
    JOINT_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY: 'jointAccountUpdateGpd',
    JOINT_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY: 'jointAccountUpdate',
    OTHER_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY: 'otherAccountUpdatePleinEx',
    OTHER_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY: 'otherAccountUpdateSsd',
    OTHER_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY: 'otherAccountUpdateGpd',
    OTHER_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY: 'otherAccountUpdate',

    STEP_ROUTES_CONFIG_KEYS: [
        'personalAccount',
        'jointAccount',
        'otherAccount',
        'personalAccountPleinEx',
        'jointAccountPleinEx',
        'otherAccountPleinEx',
        'addAccountPleinEx',
        'personalAccountGpd',
        'jointAccountGpd',
        'otherAccountGpd',
        'personalAccountSsd',
        'jointAccountSsd',
        'otherAccountSsd',
        'personalAccountUpdate',
        'personalAccountUpdatePleinEx',
        'personalAccountUpdateGpd',
        'personalAccountUpdateSfd',
        'personalAccountUpdateSsd',
        'jointAccountUpdatePleinEx',
        'jointAccountUpdateSsd',
        'jointAccountUpdateGpd',
        'jointAccountUpdate',
        'otherAccountUpdatePleinEx',
        'otherAccountUpdateSsd',
        'otherAccountUpdateGpd',
        'otherAccountUpdate'
    ],

    CLT_TYPE_INDIVIDU: 'INDIVIDU',
    CLT_TYPE_CONJOINT: 'CONJOINT',
    CLT_TYPE_OTHER: 'OTHER',
    CLT_TYPE_COMPAGNIE: 'COMPAGNIE',
    CLT_TYPE_ASSOCIATION: 'ASSOCIATION',
    CLT_TYPE_CLUB: 'CLUB',
    CLT_TYPE_COOPERATIVE: 'COOP',
    CLT_TYPE_CORPORATION: 'CORPO',
    CLT_TYPE_CO_UNIQUE: 'CO_UNIQUE',
    CLT_TYPE_CURATELLE: 'CURATELLE',
    CLT_TYPE_ENF_ES_QUALITES: 'ENF_ES_QUALITES',
    CLT_TYPE_ENF_REF: 'ENF_REF',
    CLT_TYPE_FIDUCIE: 'FIDUCIE',
    CLT_TYPE_ORG_GOUV: 'ORG_GOUV',
    CLT_TYPE_MANDATAIRE: 'MANDATAIRE',
    CLT_TYPE_OSBL: 'OSBL',
    CLT_TYPE_SENC: 'SENC',
    CLT_TYPE_SOC_COMM: 'SOC_COMM',
    CLT_TYPE_SUCC: 'SUCC',
    CLT_TYPE_TUTELLE: 'TUTELLE',
    CLT_TYPE_RRI: 'RRI',
    CLT_TYPE_OTHER_ACCOUNTS: 'OTHER_ACCOUNTS',

    BROKERAGE_PURPOSE_OTHER: 'OTHER',

    UNIQUE_CORPORATION_ACCOUNT_TYPE: 'UNIQUE',

    TRANSFER_REQUEST_TYPES: [
        'transfer',
        'tfsaTransfer',
        'fhsaTransfer',
        'rrspTransfer',
        'liraTransfer',
        'rrifTransfer',
        'lifTransfer',
        'respTransfer',
        'ftarrspTransfer'
    ],
    TRANSFER_REQUEST_TYPES_TOGGLE_CELIAPP_OFF: [
        'transfer',
        'tfsaTransfer',
        'rrspTransfer',
        'liraTransfer',
        'rrifTransfer',
        'lifTransfer',
        'respTransfer',
        'ftarrspTransfer'
    ],
    JOINT_TRANSFER_REQUEST_TYPES: [
        'jointTransfer',
        'respTransfer'
    ],
    JOINT_TRANSFER_REQUEST_TYPES_PLEINEX: [
        'transfer',
        'respTransfer'
    ],
    JOINT_TRANSFER_REQUEST_TYPES_GPD: [
        'transfer',
        'respTransfer'
    ],
    OTHER_TRANSFER_REQUEST_TYPES: [
        'otherTransfer'
    ],

    NON_REG_ACCOUNT_TYPES: ['CASH', 'MARGIN', 'MRG_OPT', 'MRG_SHT_SEL', 'MRG_OPT_SHT'],
    SECURITY_TYPE: ['KIND', 'SELL'],

    TRANSMISSION_MAIL: 'MAIL',
    TRANSMISSION_ESIGN: 'ESIGN',
    TRANSMISSION_ESIGN_MAIL: 'ESIGN_MAIL',
    TRANSMISSION_PRINT: 'PRINT',

    USER_IDENTITY_CODE_DRIVER_LICENSE: 'DRV_LIC',
    USER_IDENTITY_CODE_PASSPORT: 'PAS_PRT',
    USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD: 'HLT_INS_CRD',
    USER_IDENTITY_CODE_PROVINCIAL_TERRITORIAL_CARD: 'PHOTO_CRD',
    USER_IDENTITY_CODE_PERMANENT_RESIDENT_CARD: 'RES_CRD',
    USER_IDENTITY_CODE_INDIAN_STATUS: 'INDIAN_CRD',
    USER_IDENTITY_CODE_NEXUS: 'NEXUS',
    USER_IDENTITY_CODE_BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT: 'CPP_ACCOUNT_STATEMENT',
    USER_IDENTITY_CODE_INVEST_ACCT_STATEMENT: 'INVEST_ACCT_STATEMENT',
    USER_IDENTITY_CODE_PUBLIC_UTILITY_BILL: 'PUBLIC_UTILITY_BILL',
    USER_IDENTITY_CODE_BANK_STATEMENT: 'BANK_STATEMENT',
    USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT: 'CREDIT_CARD_STATEMENT',
    USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT: 'LOAN_ACCOUNT_STATEMENT',
    USER_IDENTITY_CODE_CATEGORY_12: ['DRV_LIC'],
    USER_IDENTITY_CODE_CATEGORY_1: ['HLT_INS_CRD', 'PAS_PRT', 'NEXUS', 'PHOTO_CRD', 'RES_CRD', 'INDIAN_CRD', 'BIRTH_CERTIFICATE'],
    USER_IDENTITY_CODE_CATEGORY_2: ['CPP_ACCOUNT_STATEMENT', 'INVEST_ACCT_STATEMENT', 'PUBLIC_UTILITY_BILL'],
    USER_IDENTITY_CODE_CATEGORY_3: ['BANK_STATEMENT', 'CREDIT_CARD_STATEMENT', 'LOAN_ACCOUNT_STATEMENT'],

    REQUEST_STATUS_COMPLETE: 'COMPLETE',
    REQUEST_STATUS_ENTRY: 'ENTRY',
    REQUEST_STATUS_ESIGNED: 'ESIGNED',
    REQUEST_STATUS_MANAGED: 'MANAGED',
    REQUEST_STATUS_PRINTED: 'PRINTED',
    REQUEST_STATUS_RECEIVED: 'RECEIVED',
    REQUEST_STATUS_SAVED: 'SAVED',
    REQUEST_STATUS_TRANSMIT: 'TRANSMIT',

    REQUEST_STATUS_PROCESS: 'PROCESS',
    REQUEST_STATUS_APPROVAL: 'APPROVAL',
    REQUEST_STATUS_AUTHORIZE: 'AUTHORIZE',
    REQUEST_STATUS_FORCED: 'FORCED',
    REQUEST_STATUS_AUTO: 'AUTO',
    REQUEST_STATUS_INJECTED: 'INJECTED',
    REQUEST_STATUS_READY: 'READY',
    REQUEST_STATUS_REJECTED: 'REJECTED',
    REQUEST_STATUS_TOCOMPLETE: 'TOCOMPLETE',
    REQUEST_STATUS_VALIDATE: 'VALIDATE',
    REQUEST_STATUS_FINALIZED: 'FINALIZED',
    REQUEST_STATUS_MANUAL: 'MANUAL',
    REQUEST_STATUS_DECLINED: 'DECLINED',
    REQUEST_STATUS_CANCELLED: 'CANCELLED',
    REQUEST_STATUS_CORRECT: 'CORRECT',

    OTHER_LINKED_ACCOUNT_PERSONAL: 'PRS',
    OTHER_LINKED_ACCOUNT_JOINT: 'JNT',
    OTHER_LINKED_ACCOUNT_NONE: 'NONE',

    OTHER_FIDUCIE_TYPE_COMPLEX: 'COMPLEX',
    OTHER_FIDUCIE_TYPE_GRANTOR_SIMPLE: 'SIMPLE',

    ANNUITANT_PERSONAL: 'PERSONAL',
    ANNUITANT_SPOUSE: 'SPOUSE',

    FIELD_TYPES: {
        CHECKBOX: 'checkbox',
        PASSWORD: 'password',
        RADIO: 'radio',
        SELECT: 'select',
        TEXT: 'text'
    },

    MODES: {
        PRINT: 'PRINT',
        AUTOMATED: 'AUTOMATED'
    },

    COUNTRIES: {
        CANADA: 'CAN',
        UNITED_STATES: 'USA'
    },

    DIVISIONS: {
        DISNAT: 'ESCPT',
        PLEIN_EX: 'PEVMD',
    },

    SUBDIVISIONS: {
        GPD: 'GPD',
        SSD: 'SSD'
    },

    // Entity Type
    ASSOCIATION: 'ASSOCIATION',
    SOC_COMM: 'SOC_COMM',
    SENC: 'SENC',
    FIDUCIE_S: 'FIDUCIE_S',
    FIDUCIE_C: 'FIDUCIE_C',
    CORPO: 'CORPO',
    OSBL: 'OSBL',
    SUCC: 'SUCC',
    RRI: 'RRI',


    ORDER_BY_FIELD_REQ_NUMBER: 'numeroDemande',
    ORDER_BY_FIELD_TYPE: 'codeClientele',
    ORDER_BY_FIELD_CLIENT_NAME: 'clientName',
    ORDER_BY_FIELD_CLIENT_NUMBER: 'assignedClientId',
    ORDER_BY_FIELD_STATUS: 'codeStatutDemande',
    ORDER_BY_FIELD_TRANSMISSION_MTHD: 'transmissionMethod',
    ORDER_BY_FIELD_CREATION: 'createTimeStamp',
    ORDER_BY_FIELD_ADVISOR: 'advisorCode',

    CAISSE_TO_CONFIRMATION_STATUS_TYPES: ['ACCEPTED', 'APPROVAL', 'AUTHORIZE', 'AUTO', 'CANCELLED', 'DECLINED', 'ESIGNED', 'FINALIZED', 'FORCED', 'INJECTED', 'MANAGED', 'PROCESS', 'REACTIVATED', 'READY', 'RECEIVED', 'REJECTED', 'TOCOMPLETE', 'VALIDATE'],
    CAISSE_INPROGRESS_STATUS_TYPES: ['ACCEPTED', 'APPROVAL', 'AUTHORIZE', 'AUTO', 'ESIGNED', 'FORCED', 'INJECTED', 'PROCESS', 'REACTIVATED', 'READY', 'RECEIVED', 'REJECTED', 'TOCOMPLETE', 'VALIDATE'],
    CAISSE_CANCELLED_STATUS_TYPES: ['CANCELLED'],
    CAISSE_COMPLETE_STATUS_TYPES: ['COMPLETE'],
    CAISSE_DECLINED_STATUS_TYPES: ['DECLINED'],
    CAISSE_FINALIZED_STATUS_TYPES: ['FINALIZED'],
    CAISSE_MANUAL_STATUS_TYPES: ['MANAGED', 'MANUAL'],
    CAISSE_SAVED_STATUS_TYPES: ['SAVED'],
    CAISSE_TRANSMIT_STATUS_TYPES: ['TRANSMIT'],

    BRANCH_ACT_NEW: 'NEW',
    BRANCH_ACT_LIST: 'LIST',
    SOURCE_CAISSE: 'CAISSE',
    CAISSE_CONTEXT_DECRYPTION_ERROR: 'caisseContextDecryptionError',
    REQUEST_CONVERTED: '1',

    QUERY_DECRYPTION_ERROR: 'ossqueryDecryptionError',

    SPACE: '&nbsp;',
    BREAK: '<br>',
    EMPTY: '',

    ESIGN_FUNCTIONAL_ERROR_EMAIL_NOT_VALID: 'ERR-V-0010',

    OSS_ORIGINAL_OCCUPATION_SESSION_KEY: 'userEmployerIsBroker',
    OSS_ADDRESS_TYPE_SESSION_KEY: 'ossAddressType',
    OSS_HOME_ADDRESS: 'homeAddress',
    OSS_MAILING_ADDRESS: 'mailingAddress',
    ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY: 'EnglishQuebecResidentModalTriggered',

    PROD_HOST_NAME_PRIV_DISNAT: 'ouverturedecompte-priv-disnat.desjardins.com',
    PROD_HOST_NAME_DISNAT: 'ouverturedecompte.disnat.com',
    PROD_HOST_NAME: 'ouverturedecompte.vmd.ca',
    PROD_HOST_NAME_EN: 'accountopening.disnat.com',
    PREPROD_HOST_NAME_VMD_PRIV: 'ouverturedecompte-vmd-priv.certif.desjardins.com',
    PREPROD_HOST_NAME_VMD: 'ouverturedecompte-vmd.certif.desjardins.com',
    PREPROD_HOST_NAME: 'ouverturedecompte.certif.desjardins.com',
    PREPROD_HOST_NAME_EN: 'accountopening.certif.desjardins.com',

    LAST_UPDATE_DATE_MAX: 'Fri Dec 31 2021 00:00:00 GMT-0500',
    PATH_IMPRESSION: 'impression',
    PATH_SOUMISSION: 'soumission',

    CONSULTATION_REQUEST_UPDATE_DIFF: 'request-diff',

    PASSWORD_LENGTH_MIN: 8,
    PASSWORD_STRENGTH_MIN: 2
};
