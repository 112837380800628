import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {AppRoutes} from '../../../app.routes';
import {ActivatedRoute, Event, ResolveEnd, Router} from '@angular/router';
import {I18N_NAV_MESSAGES_FR} from '../../../components/i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../../../components/i18n/nav-messages_en';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {NavigationService} from '../../../components/services/navigation.service';
import {Location} from '@angular/common';
import {FormService} from '../../../components/services/form.service';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';
import {ListModelService} from '../../../components/services/list-model.service';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';


declare let $: any;
declare let dataLayer: any;

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    providers: [TranslatePipe]
})
export class QuestionnaireComponent implements OnInit, AfterViewInit, OnDestroy {

    routesConfig: Array<any> = [];

    currentTab = 0;
    currentSubTab = 0;

    isBackDisabled: boolean;

    constants: any = VmdConstants;
    otherAccountList: HtmlSelectKV[];
    isSaving = false;
    protected subscriptions: any = [];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private navigationService: NavigationService,
                protected formService: FormService,
                private translate: TranslateService,
                private translatePipe: TranslatePipe,
                private location: Location,
                private listModelService: ListModelService,
                private cdr: ChangeDetectorRef,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
        this.isBackDisabled = this.navigationService.isBackDisabled;
    }

    ngOnInit() {
        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));
        this.subscriptions.push(this.navigationService.stepRoutesConfigKeysEmitted.subscribe((key: string) => {
            this.routesConfig = AppRoutes.getStepRoutesConfig(key);
        }));
        this.subscriptions.push(this.navigationService.nextEmitted.subscribe(() => {
            this.nextStep();
        }));
        this.subscriptions.push(this.navigationService.backEmitted.subscribe(() => {
            this.previousStep();
        }));
        this.subscriptions.push(this.navigationService.isBackDisabledEmitted.subscribe((value: boolean) => {
            setTimeout(() => {
                this.isBackDisabled = value;
            }, 0);
        }));
        this.subscriptions.push(this.navigationService.stepMetricFormEmitted.subscribe(() => {
            this.sendMetricsForm();
        }));
        this.subscriptions.push(this.router.events.subscribe((event: Event) => {
            if (event instanceof ResolveEnd) {
                setTimeout(() => {

                    if ((event.url === '/' + this.translatePipe.transform('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH')
                            || event.url === '/' + this.translatePipe.transform('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH'))
                        && this.formService.isTransmitted()) {
                        this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH')]);
                    }

                    if ((event.url === '/' + this.translatePipe.transform('ODC_NAV_STEP_CONSENT_CONSENT_PERSONAL_ACCOUNT_PATH'))
                        && this.formService.isCompleted()) {
                        this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH')]);
                    }

                    this.setCurrentTabAndSubTab(event.url);
                    this.init();

                    this.navigationService.goToMainContent();
                });

            }
        }));
        this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
            // update URL in browser
            this.location.replaceState('/' + this.getUrl());
        }));
        this.subscriptions.push(this.navigationService.afterViewInitEmitted.subscribe(() => {
            this.init();
        }));

        const routerStateKey = '_routerState';
        this.setCurrentTabAndSubTab(this.route[routerStateKey].snapshot.url);
        this.init();

        this.listModelService.getListModel(this.isPleinEx() ? 'otherAccountsListPleinEx' : 'otherAccountsList', data => this.otherAccountList = data);
    }

    ngAfterViewInit() {
        this.init();
        this.cdr.detectChanges();
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    getClientId(): string {
        return this.config.CLIENT_ID;
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }

    isBranch(): boolean {
        return this.formService.isCaisseContext();
    }

    isPilotMemberOrModeUpdateAutomatedOpeningActive() {
        return this.formService.isPilotMemberOrModeUpdateAutomatedOpeningActive();
    }

    isUpdateAccount(): boolean {
        return this.config.IS_UPDATE_ACCOUNT;
    }

    backToStepLink(path: string): void {
        if (path !== null) {
            this.router.navigate(['/', this.translatePipe.transform(path)]);
        }
    }

    getCurrentSubTabs(): any[] {
        return this.navigationService.getCurrentSubTabs();
    }

    getCurrentSubTab(): any {
        return this.navigationService.getCurrentSubTab();
    }

    getEnabledSubStepLength(index: number = null): number {
        if (index === null) {
            index = this.currentTab;
        }
        return AppRoutes.getEnabledSubStepLength(this.routesConfig, index);
    }

    getFirstEnabledSubStep(item: any): string {

        if (this.isInvalidForm() || this.processRules()) {
            this.formService.getCurrentComposant().clickSubmitButton();
            return null;
        }

        for (const subitem of item.items) {
            if (subitem && !subitem.disable) {
                return subitem.path;
            }
        }

        return null;
    }

    getStepSize(): number {
        let size = 0;

        for (let i = 0; i < this.routesConfig.length; i++) {
            if (this.routesConfig[i].showTab && this.getEnabledSubStepLength(i) > 0) {
                size++;
            }
        }

        return size;
    }

    getSubStepLink(subitem: any): string {

        if (this.isInvalidForm() || this.processRules()) {
            this.formService.getCurrentComposant().clickSubmitButton();
            return null;
        }

        return subitem.path;
    }

    getUrl(): string {
        const path: string = this.routesConfig[this.currentTab].items[this.currentSubTab].path;
        let url: string = I18N_NAV_MESSAGES_FR[path];
        if (this.translate.currentLang === VmdConstants.LANG_EN) {
            url = I18N_NAV_MESSAGES_EN[path];
        }

        return url;
    }

    init() {
        $('.etape-bulle').n3EtapeBulle({});
    }

    isActiveSubTab(subtab: number): boolean {
        return subtab === this.currentSubTab;
    }

    isActiveTab(tab: number): boolean {
        return tab === this.currentTab;
    }

    isDisabledSubTab(subtab: number): boolean {
        let disableSubTabForUpdateAccount;

        if (this.isPleinEx()) {
            if (this.formService.isPrintMode()) {
                return subtab !== this.currentSubTab && (this.isInvalidForm() || this.isExcludeStepNavigation());
            } else if (this.formService.isCompleted()) {
                return !this.isReadOnly();
            }
        } else if (!this.isPleinEx() && this.isUpdateAccount()) {
            disableSubTabForUpdateAccount = this.disableTabForUpdateAccount();
        }
        return disableSubTabForUpdateAccount ? disableSubTabForUpdateAccount : (subtab > this.currentSubTab);
    }

    isDisabledTab(tab: number): boolean {
        let disableTabForUpdateAccount;
        if (this.formService.addressFormTypeIsNotSelectedOnUpdateAccount()) {
            return true;
        }

        if (this.isPleinEx()) {
            if (this.formService.isPrintMode()) {
                return tab !== this.currentTab && (this.isInvalidForm() || this.isExcludeStepNavigation());
            } else if (this.formService.isCompleted()) {
                return !this.isReadOnly();
            }
        } else if (!this.isPleinEx() && this.isUpdateAccount()) {
            disableTabForUpdateAccount = this.disableTabForUpdateAccount();
        }
        return disableTabForUpdateAccount ?
            disableTabForUpdateAccount : (tab > this.currentTab || (this.isBackDisabled && tab !== this.currentTab));
    }

    disableTabForUpdateAccount(): boolean {
        if (!this.isPilotMemberOrModeUpdateAutomatedOpeningActive()) {
            return this.isReadOnly();
        } else if (this.formService.isCompleted()) {
            return !this.isReadOnly();
        }
    }

    isExcludeStepNavigation(): boolean {

        if (this.formService.getCurrentComposant()) {
            return this.formService.getCurrentComposant().isExcludeStepNavigation();
        } else {
            return false;
        }
    }

    isInvalidForm(): boolean {
        return this.isPleinEx()
            && this.formService.isPrintMode()
            && this.formService.getCurrentComposant()
            && this.formService.getCurrentComposant().isInvalidForm();
    }

    showAsideCaisseConsult(): boolean {
        return this.currentTab >= 1
            && !this.routesConfig[this.currentTab].items[this.currentSubTab].hideAsideConsult;
    }

    isCurrentStep2OrAfter(): boolean {
        return (this.currentTab >= 2 && !(this.routesConfig[this.currentTab].label === 'ODC_NAV_STEP_TRANSMISSION' || this.routesConfig[this.currentTab].label === 'ODC_NAV_STEP_CONFIRMATION'))
            || this.isDisnatTransmission();
    }

    getHelpWarningMessageKey(): string {
        return this.routesConfig[this.currentTab].items[this.currentSubTab].helpWarningMessage;
    }

    showAsideCaisseUpdateForm(): boolean {
        return (this.isCurrentStep2OrAfter() && this.formService.getClientType() === VmdConstants.CLT_TYPE_INDIVIDU
            && this.formService.isCaisseContext() && !this.formService.isFromSmartD()
            && (this.formService.getForm().requestStatus == null || this.formService.getForm().requestStatus === VmdConstants.REQUEST_STATUS_SAVED));
    }

    // OCE-7157
    isDisnatTransmission(): boolean {
        return !this.isPleinEx() && this.routesConfig[this.currentTab].label === 'ODC_NAV_STEP_TRANSMISSION';
    }

    isVisitedTab(tab: number): boolean {
        return tab <= this.currentTab;
    }

    nextStep() {
        if (this.currentSubTab < (this.routesConfig[this.currentTab].items.length - 1)) {
            this.currentSubTab++;
        } else if (this.currentTab < (this.routesConfig.length - 1)) {
            this.currentTab++;
            this.currentSubTab = 0;
        }


        if (this.routesConfig[this.currentTab].items[this.currentSubTab].disable) {
            if (this.currentSubTab === (this.routesConfig[this.currentTab].items.length - 1)
                && this.currentTab === (this.routesConfig.length - 1)) {
                this.previousStep();
            } else {
                this.nextStep();
            }
        }

        this.navigationService.currentTab = this.currentTab;
        this.navigationService.currentSubTab = this.currentSubTab;

        this.router.navigate([this.getUrl()]);
    }

    previousStep() {
        if (this.currentSubTab > 0) {
            this.currentSubTab--;
        } else if (this.currentTab > 0) {
            this.currentTab--;
            this.currentSubTab = this.routesConfig[this.currentTab].items.length - 1;
        }

        if (this.routesConfig[this.currentTab].items[this.currentSubTab].disable) {
            if (this.currentSubTab === 0 && this.currentTab === 0) {
                this.nextStep();
            } else {
                this.previousStep();
            }
        }

        this.navigationService.currentTab = this.currentTab;
        this.navigationService.currentSubTab = this.currentSubTab;

        this.router.navigate([this.getUrl()]);
    }

    processRules(): boolean {
        return this.formService.getCurrentComposant() && this.formService.getCurrentComposant().processRules();
    }

    showTab(): boolean {
        return this.routesConfig[this.currentTab] && this.routesConfig[this.currentTab].showTab;
    }

    showSubTab(): boolean {
        return this.showTab() && this.getEnabledSubStepLength() > 1;
    }

    setCurrentTabAndSubTab(url: string) {

        for (const stepRoutesConfigKey of VmdConstants.STEP_ROUTES_CONFIG_KEYS) {

            this.routesConfig = AppRoutes.getStepRoutesConfig(stepRoutesConfigKey);

            for (let j = 0; j < this.routesConfig.length; j++) {

                if (this.routesConfig[j].items) {
                    for (let k = 0; k < this.routesConfig[j].items.length; k++) {

                        const pathFr: string = '/' + I18N_NAV_MESSAGES_FR[this.routesConfig[j].items[k].path];
                        const pathEn: string = '/' + I18N_NAV_MESSAGES_EN[this.routesConfig[j].items[k].path];

                        if (url === pathFr || url === pathEn) {
                            this.currentTab = j;
                            this.currentSubTab = k;

                            this.navigationService.currentTab = this.currentTab;
                            this.navigationService.currentSubTab = this.currentSubTab;
                            this.navigationService.emitStepRoutesConfigKeys(stepRoutesConfigKey);

                            return;
                        }
                    }
                }
            }
        }
    }

    getCltType(): string {
        return ListModelService.getLabelFromList(this.otherAccountList, this.formService.getClientType());
    }

    printCurrentCltType(): string {

        if (this.navigationService.stepRoutesConfigKey
            && this.navigationService.stepRoutesConfigKey.includes(VmdConstants.OTHER_ACCOUNT_ROUTES_CONFIG_KEY)
            && this.navigationService.currentTab > 0
            && !this.isUpdateAccount()) {

            return ' - ' + this.getCltType();
        }

        return '';
    }

    showTitleHelp(): boolean {
        return null !== this.getTitleHelpTemplateFr() && null !== this.getTitleHelpTemplateEn();
    }

    getTitleHelpTemplateFr(): TemplateRef<any> {
        if (this.formService.getCurrentComposant()) {
            return this.formService.getCurrentComposant().getTitleHelpTemplateFr();
        }
        return null;
    }

    getTitleHelpTemplateEn(): TemplateRef<any> {
        if (this.formService.getCurrentComposant()) {
            return this.formService.getCurrentComposant().getTitleHelpTemplateEn();
        }
        return null;
    }

    isReadOnly() {
        return this.config.READ_ONLY;
    }

    private sendMetricsForm(): void {

        let action = null;
        const label = 'Ouverture de compte - Desjardins Courtage en ligne';
        if (this.navigationService.currentTab === 0 && this.navigationService.currentSubTab === 1) {
            // debute
            action = 'debute';
        } else if (this.navigationService.currentTab === (this.routesConfig.length - 1) && this.navigationService.currentSubTab === (this.routesConfig[this.navigationService.currentTab].items.length - 1)) {
            // complete
            action = 'complete';
        } else if (this.navigationService.currentSubTab === 0) {
            // etape-x
            action = 'etape-' + this.routesConfig[this.navigationService.currentTab].step;
        }

        if (action !== null) {
            dataLayer.push({
                event: 'interractionsPages',
                category: 'Formulaire',
                action,
                label,
                value: 0,
                nonInteraction: false
            });
        }
    }
}
