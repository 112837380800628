import {Component, Inject, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {WebException} from '../../components/models';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {ModalService} from '../../components/services/modal.service';
import {PostLoginActionService} from '../../components/services/post-login-action.service';

@Component({
    selector: 'app-submission',
    templateUrl: './submission.component.html'
})
export class SubmissionComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;
    submitted = false;
    alreadySubmitError: string = null;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService,
                protected modalService: ModalService,
                private postLoginActionService: PostLoginActionService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));

        this.form = this.fb.group({
            confirm: [false]
        });

        if (
            (VmdConstants.MODES.AUTOMATED === this.formService.getForm().entryMode
                && !this.hasPrintedOrSavedOrCorrectStatus()) || this.config.TO_CORRECT
        ) {
            this.submitted = true;
        }
    }

    submitForm() {
        if (this.form.get('confirm').value && this.hasPrintedOrSavedOrCorrectStatus()) {

            // OCE-7596
            if (!this.formService.isCompleted()) {

                if (!this.formService.saveInProgress()) {

                    this.loading = true;
                    this.formService.emitIsSaving(true);
                    this.error = null;
                    this.alreadySubmitError = null;

                    this.formService.saveCompleteForm().subscribe(
                        (data: any) => {
                            if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                                this.formService.emitSaved();
                            }

                            this.formService.setCompleted(true);
                            this.submitted = true;
                        },
                        (error: WebException) => {
                            this.postLoginActionService.registerAction(() => this.submitForm());
                            this.onCompleteFormError(error);
                        }
                    ).add(() => {
                        this.loading = false;
                        this.formService.emitIsSaving(false);
                    });
                }
            } else {
                this.alreadySubmitError = 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED';
            }
        }
    }

    private hasPrintedOrSavedOrCorrectStatus(): boolean {
        return (
            null === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_PRINTED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_SAVED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_CORRECT === this.formService.getForm().requestStatus
        );
    }

}
