<ng-container *ngIf="isDefined()">
    <a #helpLink href="javascript:void(0);" (click)="display($event)" class="help-link" [class.sr-only]="srOnly">
        <ng-container *ngIf="label === null">
            <i class="{{iconClass}}"></i>
            <span class="sr-only">{{'HELP'|translate}}</span>
        </ng-container>
        <ng-container *ngIf="label !== null">
            <span>{{label|translate}}</span>
        </ng-container>
    </a>

    <div #helpContent class="hidden">
        <ng-container *ngIf="translate.currentLang === 'fr'">
            <ng-container *ngTemplateOutlet="templateRefFr"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="templateRefEn"></ng-container>
        </ng-container>
    </div>
</ng-container>
