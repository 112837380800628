import {Directive, ElementRef, forwardRef, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {VmdKeyboard} from '../utils/vmd-keyboard';
import {VmdFormat} from '../formats/vmd-format';
import {VmdConstants} from '../constants/vmd-constants';

@Directive({
    selector: '[appZipCodeFormat]',
    providers: [
        VmdKeyboard,
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ZipCodeFormatDirective), multi: true}
    ]
})
export class ZipCodeFormatDirective implements ControlValueAccessor {

    modelValue: string;
    country: string;

    constructor(private el: ElementRef, private keyboard: VmdKeyboard) {
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        const originalValue = this.el.nativeElement.value;

        const country = this.el.nativeElement.getAttribute('usemap');
        if (country === VmdConstants.COUNTRIES.UNITED_STATES) {
            this.el.nativeElement.value = VmdFormat.zipCode.formatAsUsaZipCode(originalValue);
        } else if (country === VmdConstants.COUNTRIES.CANADA) {
            this.el.nativeElement.value = VmdFormat.zipCode.formatAsZipCode(originalValue);
        }

        this.country = country;
        this.writeValue(VmdFormat.zipCode.undoFormatAsZipCode(originalValue));

        this.onTouched();
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    @HostListener('focus', ['$event'])
    onFocus(event: KeyboardEvent) {

        const country = this.el.nativeElement.getAttribute('usemap');

        if (country === VmdConstants.COUNTRIES.UNITED_STATES) {
            const value = VmdFormat.zipCode.undoFormatAsZipCode(this.el.nativeElement.value);
            if (!!value) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsUsaZipCode(value);
            }
        } else if (country === VmdConstants.COUNTRIES.CANADA) {
            const value = VmdFormat.zipCode.undoFormatAsZipCode(this.el.nativeElement.value);
            if (!!value) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsZipCode(value);
            }
        }

        this.country = country;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (!this.keyboard.isKeyDownAllowed(event)) {
            event.preventDefault();
        }
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        const country = this.el.nativeElement.getAttribute('usemap');

        if (country === VmdConstants.COUNTRIES.UNITED_STATES) {
            if (this.keyboard.isKeyPressNumeric(event)) {
                return;
            }
        } else if (country === VmdConstants.COUNTRIES.CANADA) {
            if (this.keyboard.isKeyPressNumeric(event) || this.keyboard.isKeyPressAlpha(event)) {
                return;
            }
        }

        event.preventDefault();

        if (country === VmdConstants.COUNTRIES.UNITED_STATES) {
            const value = VmdFormat.zipCode.undoFormatAsZipCode(this.el.nativeElement.value);
            if (!!value && value.length > 5) {
                this.el.nativeElement.value = value.substr(0, 5);
            }
        } else if (country === VmdConstants.COUNTRIES.CANADA) {
            const value = VmdFormat.zipCode.undoFormatAsZipCode(this.el.nativeElement.value);
            if (!!value && value.length > 7) {
                this.el.nativeElement.value = value.substr(0, 7);
            }
        }

        this.country = country;
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (!this.keyboard.isKeyArrow(event)) {
            const country = this.el.nativeElement.getAttribute('usemap');
            if (country === VmdConstants.COUNTRIES.UNITED_STATES) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsUsaZipCode(this.el.nativeElement.value);
            } else if (country === VmdConstants.COUNTRIES.CANADA) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsZipCode(this.el.nativeElement.value);
            }

            this.country = country;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.modelValue = obj;

        if (!!obj) {
            if (this.country === VmdConstants.COUNTRIES.UNITED_STATES) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsUsaZipCode(obj);
            } else if (this.country === VmdConstants.COUNTRIES.CANADA) {
                this.el.nativeElement.value = VmdFormat.zipCode.formatAsZipCode(obj);
            } else {
                if (this.isUsaZipCode(obj)) {
                    this.el.nativeElement.value = VmdFormat.zipCode.formatAsUsaZipCode(obj);
                } else {
                    this.el.nativeElement.value = VmdFormat.zipCode.formatAsZipCode(obj);
                }
            }
        } else {
            this.el.nativeElement.value = null;
        }

        this.onChange(obj);
    }

    isUsaZipCode(zipcode: string): boolean {
        if (!!zipcode) {
            return VmdConstants.USA_ZIP_CODE_REGEXP.test(zipcode);
        }

        return false;
    }
}
