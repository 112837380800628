export const I18N_NAV_MESSAGES_FR: any = {
    ODC_NAV_STEP: 'Étape {{step}}',
    ODC_NAV_OF: 'de {{size}}',

    ODC_NAV_LOGIN_PATH: 'reprise',
    ODC_NAV_FORGOT_PASSWORD_PATH: 'mot-de-passe-oublie',
    ODC_NAV_ERROR_BRANCH_PATH: 'caisse/caisse-erreur',
    ODC_NAV_INVALID_SESSION_PATH: 'session-invalide',

    ODC_NAV_STEP_PREPARATION: 'Préparation',
    ODC_NAV_STEP_PREPARATION_BEGIN: 'Avant de débuter',
    ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH: 'avant-de-debuter',
    ODC_NAV_STEP_PREPARATION_CLIENT_TYPE: 'Type de clientèle',
    ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH: 'type-de-clientele',
    ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE: 'Plateforme transactionnelle',
    ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH: 'compte-personnel-plateforme-transactionnelle',

    ODC_NAV_STEP_ACCOUNTS_CHOICE: 'Choix de compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS: 'Questions relatives au type de compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PATH: 'compte-joint-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH: 'compte-association-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH: 'compte-club-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH: 'compte-cooperative-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH: 'compte-corporation-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH: 'compte-co-unique-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH: 'compte-enfant-mineur-es-qualites-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH: 'compte-enfant-mineur-reference-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH: 'compte-fiducie-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH: 'compte-osbl-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH: 'compte-senc-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH: 'compte-commandite-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH: 'compte-succession-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT: 'Type de gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-type-de-gestion',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE: 'Choix de compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PATH: 'compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-conjoint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_UPDATE_PATH: 'mise-a-jour-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-conjoint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-conjoint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-conjoint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PATH: 'compte-joint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_PATH: 'autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-choix-de-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS: 'Entités',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH: 'questions-entites',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH: 'plein-ex-questions-entites',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_GPD_PATH: 'gpd-questions-entites',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_SSD_PATH: 'ssd-questions-entites',

    ODC_NAV_STEP_ACCOUNTS_CHOICE_ADD_ACCOUNT_SSD_PATH: 'ssd-ajout-compte-choix-de-compte',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_ADD_ACCOUNT_SSD_PATH: 'ssd-ajout-compte-informations-personnelles-du-client',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-ajout-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_ADD_ACCOUNT_SSD_PATH: 'ssd-ajout-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-ajout-compte-soumission',
    ODC_NAV_STEP_SUBMIT_ADD_ACCOUNT_SSD_PATH: 'ssd-ajout-compte-soumission',

    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-association-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_GPD_PATH: 'gpd-compte-association-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_SSD_PATH: 'ssd-compte-association-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-club-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_GPD_PATH: 'gpd-compte-club-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_SSD_PATH: 'ssd-compte-club-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-cooperative-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_GPD_PATH: 'gpd-compte-cooperative-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_SSD_PATH: 'ssd-compte-cooperative-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-corporation-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_GPD_PATH: 'gpd-compte-corporation-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_SSD_PATH: 'ssd-compte-corporation-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-co-unique-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_GPD_PATH: 'gpd-compte-co-unique-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_SSD_PATH: 'ssd-compte-co-unique-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-enfant-mineur-es-qualites-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_GPD_PATH: 'gpd-compte-enfant-mineur-es-qualites-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_SSD_PATH: 'ssd-compte-enfant-mineur-es-qualites-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-enfant-mineur-reference-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_GPD_PATH: 'gpd-compte-enfant-mineur-reference-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_SSD_PATH: 'ssd-compte-enfant-mineur-reference-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-fiducie-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_GPD_PATH: 'gpd-compte-fiducie-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_SSD_PATH: 'ssd-compte-fiducie-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-rri-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_GPD_PATH: 'gpd-compte-rri-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_SSD_PATH: 'ssd-compte-rri-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-osbl-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_GPD_PATH: 'gpd-compte-osbl-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_SSD_PATH: 'ssd-compte-osbl-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-senc-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_GPD_PATH: 'gpd-compte-senc-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_SSD_PATH: 'ssd-compte-senc-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-commandite-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_GPD_PATH: 'gpd-compte-commandite-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_SSD_PATH: 'ssd-compte-commandite-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-organisation-gouvernementale-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_GPD_PATH: 'gpd-compte-organisation-gouvernementale-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_SSD_PATH: 'ssd-compte-organisation-gouvernementale-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-succession-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_GPD_PATH: 'gpd-compte-succession-questions-relatives-type-compte',
    ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_SSD_PATH: 'ssd-compte-succession-questions-relatives-type-compte',

    ODC_NAV_STEP_PERSONAL_INFORMATION: 'Informations personnelles',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT: 'Informations personnelles du client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1: '1er titulaire - Informations',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2: '2e titulaire - Informations',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3: '3e titulaire - Informations',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PATH: 'compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_JOINT_ACCOUNT_PATH: 'compte-joint-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'compte-joint-informations-personnelles-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'compte-joint-informations-personnelles-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'compte-joint-informations-personnelles-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PATH: 'autre-compte-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS: 'Lieu de résidence aux fins de l\'impôt',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1: '1er titulaire - Résidence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2: '2e titulaire - Résidence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3: '3e titulaire - Résidence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PATH: 'compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-informations-personnelles-lieu-de-residence',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-informations-personnelles-lieu-de-residence-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-lieu-de-residence-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-lieu-de-residence-du-premier-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-informations-personnelles-lieu-de-residence-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-lieu-de-residence-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-lieu-de-residence-du-deuxieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-informations-personnelles-lieu-de-residence-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-informations-personnelles-lieu-de-residence-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-informations-personnelles-lieu-de-residence-du-troisieme-titulaire',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_PLEIN_EX_PATH: 'plein-ex-autre-compte-mandataire-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_GPD_PATH: 'gpd-autre-compte-mandataire-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_SSD_PATH: 'ssd-autre-compte-mandataire-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_PLEIN_EX_PATH: 'plein-ex-autre-compte-succession-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_GPD_PATH: 'gpd-autre-compte-succession-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_SSD_PATH: 'ssd-autre-compte-succession-informations-personnelles-du-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-autre-compte-enfant-informations-personnelles-du-premier-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-autre-compte-enfant-informations-personnelles-du-premier-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-autre-compte-enfant-informations-personnelles-du-premier-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-autre-compte-enfant-informations-personnelles-du-deuxieme-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-autre-compte-enfant-informations-personnelles-du-deuxieme-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-autre-compte-enfant-informations-personnelles-du-deuxieme-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_1: 'plein-ex-autre-compte-enfant-lieu-de-residence-premier-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_1: 'gpd-autre-compte-enfant-lieu-de-residence-premier-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_1: 'ssd-autre-compte-enfant-lieu-de-residence-premier-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_2: 'plein-ex-autre-compte-enfant-lieu-de-residence-deuxieme-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_GPD_PATH_2: 'gpd-autre-compte-enfant-lieu-de-residence-deuxieme-client',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_SSD_PATH_2: 'ssd-autre-compte-enfant-lieu-de-residence-deuxieme-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_PLEIN_EX_PATH: 'plein-ex-autre-compte-enfant-ref-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_GPD_PATH: 'gpd-autre-compte-enfant-ref-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_SSD_PATH: 'ssd-autre-compte-enfant-ref-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_PLEIN_EX_PATH: 'plein-ex-autre-compte-enfant-es-qualites-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_GPD_PATH: 'gpd-autre-compte-enfant-es-qualites-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_SSD_PATH: 'ssd-autre-compte-enfant-es-qualites-informations-personnelles-du-client',

    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_PLEIN_EX_PATH: 'plein-ex-autre-compte-tutelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_GPD_PATH: 'gpd-autre-compte-tutelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_SSD_PATH: 'ssd-autre-compte-tutelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_PLEIN_EX_PATH: 'plein-ex-autre-compte-curatelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_GPD_PATH: 'gpd-autre-compte-curatelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_SSD_PATH: 'ssd-autre-compte-curatelle-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_PLEIN_EX_PATH: 'plein-ex-autre-compte-rri-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_GPD_PATH: 'gpd-autre-compte-rri-informations-personnelles-du-client',
    ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_SSD_PATH: 'ssd-autre-compte-rri-informations-personnelles-du-client',

    ODC_NAV_STEP_OCCUPATION: 'Occupation',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION: 'Occupation et employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PATH: 'compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-occupation-et-employeur',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_1: '1er titulaire',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_2: '2e titulaire',
    ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_3: '3e titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-occupation-et-employeur-du-premier-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-occupation-et-employeur-du-premier-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-occupation-et-employeur-du-premier-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-occupation-et-employeur-du-deuxieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-occupation-et-employeur-du-deuxieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-occupation-et-employeur-du-deuxieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-occupation-et-employeur-du-troisieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-occupation-et-employeur-du-troisieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-occupation-et-employeur-du-troisieme-titulaire',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH: 'compte-autre-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH_GPD: 'gpd-compte-autre-occupation-et-employeur',
    ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH_SSD: 'ssd-autre-occupation-et-employeur',

    ODC_NAV_STEP_CIVIL_STATUS: 'État civil et désignation',
    ODC_NAV_STEP_CIVIL_STATUS_OTHER_ACCOUNT_PLEINEX: 'État civil et désignation',
    ODC_NAV_STEP_REFERENCE: 'Référence',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION: 'Informations sur le conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-etat-civil-informations',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-etat-civil-informations',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-etat-civil-informations',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH: 'plein-ex-autre-compte-enfant-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_GPD_PATH: 'gpd-autre-compte-enfant-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_SSD_PATH: 'ssd-autre-compte-enfant-etat-civil-informations-conjoint',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP: 'Désignation de bénéficiaires (REER)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RRSP_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-beneficiaire-reer',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA: 'Désignation de bénéficiaires (CELI)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_TFSA_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-beneficiaire-celi',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA: 'Désignation de bénéficiaires (CELIAPP)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-beneficiaire-celiapp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-etat-civil-beneficiaire-celiapp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-etat-civil-beneficiaire-celiapp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_FHSA_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-etat-civil-beneficiaire-celiapp',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP: 'Désignation de bénéficiaires (REEE)',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-etat-civil-beneficiaire-reee',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY: 'Désignation de mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PATH: 'compte-personnel-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-etat-civil-mandataire',
    ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-etat-civil-mandataire',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH: 'Compte référé',
    ODC_NAV_STEP_REFERRED_ACCOUNT_GPD_PATH: 'Compte référé',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-etat-civil-compte-refere',
    ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-etat-civil-compte-refere',

    ODC_NAV_STEP_LEGAL: 'Questions réglementaires',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS: 'Comptes de courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PATH: 'compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-questions-reglementaires-comptes-courtage',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1: '1er titulaire - Comptes de courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2: '2e titulaire - Comptes de courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3: '3e titulaire - Comptes de courtage',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-questions-reglementaires-comptes-courtage-du-premier-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-questions-reglementaires-comptes-courtage-du-deuxieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-questions-reglementaires-comptes-courtage-du-troisieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-questions-reglementaires-comptes-courtage-du-troisieme-titulaire',
    ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-questions-reglementaires-comptes-courtage-du-troisieme-titulaire',
    ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT: 'Compte d\'options',
    ODC_NAV_STEP_LEGAL_OPTIONS_ACCOUNT_PERSONAL_ACCOUNT_PATH: 'compte-personnel-questions-reglementaires-compte-options',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER: 'Communication avec les propriétaires véritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_GPD: 'Communication avec les propriétaires véritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PATH: 'compte-personnel-questions-reglementaires-actionnaires',

    ODC_NAV_STEP_FINANCIAL_INFORMATION: 'Informations financières',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX: 'Objectifs de placement et facteurs de risque',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_GPD: 'Objectifs de placement et facteurs de risque',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_SSD: 'Objectifs de placement et facteurs de risque',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION: 'Informations financières',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS: 'Objectifs de placement et facteurs de risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PATH: 'compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-autre-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_GPD_PATH: 'gpd-compte-autre-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_SSD_PATH: 'ssd-compte-autre-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-informations-financieres',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-autre-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_GPD_PATH: 'gpd-compte-autre-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_SSD_PATH: 'ssd-compte-autre-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-objectifs-de-placement-et-facteurs-de-risques',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT: 'Compte d\'options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-compte-options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-compte-options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-compte-options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-autre-compte-option',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_GPD_PATH: 'gpd-compte-autre-compte-option',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_SSD_PATH: 'ssd-compte-autre-compte-option',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-compte-options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-compte-options',
    ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-compte-options',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-proprietaires-veritables',
    ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-proprietaires-veritables',

    ODC_NAV_STEP_TRANSFER_REQUEST: 'Demande de transfert',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE: 'Demande de transfert',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-demande-transfert-choix',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CASH: 'Comptant',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_MARGIN: 'Marge',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED: 'Compte non enregistré',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-non-enregistre',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-non-enregistre',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_NON_REGISTERED_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-non-enregistre',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP: 'REER',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-reer',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-reer',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRSP_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-reer',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA: 'CELI',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-celi',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-celi',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_TFSA_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-celi',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA: 'CELIAPP',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-celiapp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-celiapp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_FHSA_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-celiapp',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP: 'REEE',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-reee',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-reee',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RESP_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-reee',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA: 'CRI',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-cri',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-cri',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIRA_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-cri',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF: 'FERR',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-ferr',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-ferr',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_RRIF_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-ferr',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF: 'FRV',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_PERSONAL_ACCOUNT_PATH: 'compte-personnel-demande-transfert-frv',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_JOINT_ACCOUNT_PATH: 'compte-joint-demande-transfert-frv',
    ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_LIF_OTHER_ACCOUNT_PATH: 'autre-compte-demande-transfert-frv',

    ODC_NAV_STEP_REVIEW: 'Révision',
    ODC_NAV_STEP_REVIEW_REVIEW: 'Révision',
    ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT: 'Sommaire des changements',
    ODC_NAV_STEP_REVIEW_REVIEW_PERSONAL_ACCOUNT_PATH: 'compte-personnel-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_JOINT_ACCOUNT_PATH: 'compte-joint-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_OTHER_ACCOUNT_PATH: 'autre-compte-revision',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY: 'Vérification d\'identité',
    ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_1: '1er titulaire - Vérification d\'identité',
    ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_2: '2e titulaire - Vérification d\'identité',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH: 'compte-personnel-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_PLEIN_EX_PATH: 'plein-ex-compte-personnel-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_GPD_PATH: 'gpd-compte-personnel-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_PERSONAL_ACCOUNT_PATH_SSD_PATH: 'ssd-compte-personnel-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_JOINT_ACCOUNT_PATH: 'compte-joint-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PATH: 'autre-compte-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-revision-identite-client',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-revision-identite-client-du-premier-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-revision-identite-client-du-deuxieme-titulaire',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PATH: 'mise-a-jour-compte-personnel-sommaire',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-sommaire',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-sommaire',
    ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-sommaire',
    ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT_PATH: 'mise-a-jour-sommaire',


    ODC_NAV_STEP_CONSENT: 'Consentements',
    ODC_NAV_STEP_CONSENT_CONSENT: 'Consentements',
    ODC_NAV_STEP_CONSENT_CONSENT_PERSONAL_ACCOUNT_PATH: 'compte-personnel-consentements',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT: 'Consentement spécifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PATH: 'compte-personnel-consentements-specifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-consentements-specifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-consentements-specifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_PATH: 'plein-ex-mise-a-jour-compte-personnel-consentements-specifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-consentements-specifique',
    ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-consentements-specifique',

    ODC_NAV_STEP_TRANSMISSION: 'Transmission',
    ODC_NAV_STEP_PRINT: 'Impression',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE: 'Choix de transmission',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_PERSONAL_ACCOUNT_PATH: 'compte-personnel-choix-transmission',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_JOINT_ACCOUNT_PATH: 'compte-joint-choix-transmission',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_CHOICE_OTHER_ACCOUNT_PATH: 'autre-compte-choix-transmission',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD: 'Téléchargement de la trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PATH: 'compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PATH: 'compte-joint-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-joint-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-joint-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-joint-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH: 'autre-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS: 'Pièces jointes',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_PATH: 'compte-personnel-transmission-pieces-jointes',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_PATH: 'compte-personnel-transmission-pieces-jointes-lba',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_PERSONAL_ACCOUNT_LBA_CAISSE_PATH: 'compte-personnel-transmission-pieces-jointes-lba-caisse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_JOINT_ACCOUNT_PATH: 'compte-joint-transmission-pieces-jointes',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ATTACHMENTS_OTHER_ACCOUNT_PATH: 'autre-compte-transmission-pieces-jointes',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS: 'Formulaires additionnels',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_PERSONAL_ACCOUNT_PATH: 'compte-personnel-transmission-formulaires-additionnels',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_JOINT_ACCOUNT_PATH: 'compte-joint-transmission-formulaires-additionnels',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ADDITIONAL_FORMS_OTHER_ACCOUNT_PATH: 'autre-compte-transmission-formulaires-additionnels',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN: 'Signature électronique',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_PERSONAL_ACCOUNT_PATH: 'compte-personnel-transmission-signature-electronique',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_JOINT_ACCOUNT_PATH: 'compte-joint-transmission-signature-electronique',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_ESIGN_OTHER_ACCOUNT_PATH: 'autre-compte-transmission-signature-electronique',

    ODC_NAV_STEP_SUBMIT: 'Soumission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_GPD_PATH: 'gpd-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_PERSONAL_ACCOUNT_SSD_PATH: 'ssd-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_GPD_PATH: 'gpd-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SSD_PATH: 'ssd-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-soumission',

    ODC_NAV_STEP_CONFIRMATION: 'Confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION: 'Confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_PERSONAL_ACCOUNT_PATH: 'compte-personnel-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_JOINT_ACCOUNT_PATH: 'compte-joint-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PATH: 'autre-compte-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_PERSONAL_ACCOUNT_PATH: 'compte-personnel-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_JOINT_ACCOUNT_PATH: 'compte-joint-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH: 'autre-compte-confirmation-caisse',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PLEIN_EX_PATH: 'plein-ex-autre-compte-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_GPD_PATH: 'gpd-autre-compte-confirmation',
    ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_SSD_PATH: 'ssd-autre-compte-confirmation',

    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION: 'Identification du titulaire',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1: '1er titulaire - Identification',
    ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_2: '2e titulaire - Identification',

    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_GPD_PATH: 'gpd-mise-a-jour-compte-personnel-transmission-telechargement-trousse',
    ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_SSD_PATH: 'ssd-mise-a-jour-compte-personnel-transmission-telechargement-trousse',

    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_UPDATE_PATH: 'mise-a-jour-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_UPDATE_PATH: 'mise-a-jour-autre-compte-soumission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_UPDATE_PLEIN_EX_PATH: 'plein-ex-mise-a-jour-autre-compte-soumission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH: 'plein-ex-gpd-mise-a-jour-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH: 'plein-ex-gpd-mise-a-jour-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH: 'plein-ex-gpd-mise-a-jour-autre-compte-soumission',
    ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH: 'plein-ex-ssd-mise-a-jour-compte-personnel-soumission',
    ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH: 'ssd-mise-a-jour-compte-conjoint-soumission',
    ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_UPDATE_PLEIN_EX_SSD_PATH: 'ssd-mise-a-jour-autre-compte-soumission',
};
