<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">


    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-house"></span>

        {{'ODC_BENEFICIARY_RESP_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>


    <!--Answer-->
    <div *ngIf="isFamilyAccount() && hasMoreThanOneBeneficiary()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_BENEFICIARY_RESP_QUESTION' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'beneficiaryRespBrotherAndSisterOption'"
            [name]="'beneficiaryRespBrotherAndSisterOption'"
            [path]="'beneficiaryRespBrotherAndSisterOption'"
            [binding]="'beneficiaryRespBrotherAndSisterOption'"
        ></app-odc-field>

        <div *ngIf="beneficiariesAreNotSiblings">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_BENEFICIARY_RESP_REASON' | translate"
                [id]="'beneficiaryRespReason'"
                [name]="'beneficiaryRespReason'"
                [path]="'beneficiaryRespReason'"
                [binding]="'beneficiaryRespReason'"
            ></app-odc-field>
            <br>
            <span>{{'REVIEW_BENEFICIARY_INFO_ADDITIONAL_CESG_CLB' | translate}}</span>
            <br><br>
        </div>
    </div>

    <app-odc-field
        [type]="constants.FIELD_TYPES.CHECKBOX"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [choices]="[{label: 'ODC_BENEFICIARY_CESG_FOR_RESP' | translate, id: 'requestCESGForResp'}]"
        [stacked]="true"
    ></app-odc-field>


    <div *ngIf="isFamilyAccount()">
        <div formArrayName="beneficiaryGroup"
             *ngFor="let beneficiary of beneficiaryGroup.controls; let i=index">
            <div [formGroupName]="i">
                <app-beneficiary-pleinex
                    [form]="form"
                    [index]="i"
                    [path]="'beneficiaryGroup'"
                    [bindPath]="'beneficiaryRespList'"
                    [submitted]="isFormSubmitted()"
                    [groupName]="'beneficiaryGroup'"
                    [addressGroupName]="'beneficiaryRespAddress'"
                    [gender]="'beneficiaryRespGender'"
                    [firstName]="'beneficiaryRespFirstName'"
                    [lastName]="'beneficiaryRespLastName'"
                    [DOB]="'beneficiaryRespDOB'"
                    [SIN]="'beneficiaryRespSIN'"
                    [addressStreet]="'beneficiaryRespAddressStreet'"
                    [addressUnit]="'beneficiaryRespAddressUnit'"
                    [addressProv]="'beneficiaryRespAddressProv'"
                    [addressCity]="'beneficiaryRespAddressCity'"
                    [addressPostalCode]="'beneficiaryRespAddressPostalCode'"
                    [addressCountry]="'beneficiaryRespAddressCountry'"
                    [beneficiaryAddressSameAsSubscriber]="'beneficiaryRespAddressSameAsSubscriber'"
                    [parentLink]="'beneficiaryRespParentLink'"
                    [distributionRatio]="'beneficiaryRespDistributionRatio'"
                    [caregiverParentFirstName]="'beneficiaryRespCaregiverParentFirstName'"
                    [caregiverParentLastName]="'beneficiaryRespCaregiverParentLastName'"
                    [caregiverParentSin]="'beneficiaryRespCaregiverParentSin'"
                    [requestCLBForResp]="'beneficiaryRespRequestCLBForResp'"
                    [custodialParentGender]="'beneficiaryRespCustodialParentGender'"
                    [custodialParentFirstName]="'beneficiaryRespCustodialParentFirstName'"
                    [custodialParentLastName]="'beneficiaryRespCustodialParentLastName'"
                    [custodialParentSecGender]="'beneficiaryRespCustodialParentSecGender'"
                    [custodialParentSecFirstName]="'beneficiaryRespCustodialParentSecFirstName'"
                    [custodialParentSecLastName]="'beneficiaryRespCustodialParentSecLastName'"
                ></app-beneficiary-pleinex>
            </div>
        </div>
    </div>

    <div *ngIf="!isFamilyAccount()">
        <app-beneficiary-pleinex
            [form]="form"
            [index]="0"
            [path]="'beneficiaryGroup'"
            [bindPath]="'beneficiaryRespList'"
            [submitted]="isFormSubmitted()"
            [groupName]="'beneficiaryGroup'"
            [addressGroupName]="'beneficiaryRespAddress'"
            [gender]="'beneficiaryRespGender'"
            [firstName]="'beneficiaryRespFirstName'"
            [lastName]="'beneficiaryRespLastName'"
            [DOB]="'beneficiaryRespDOB'"
            [SIN]="'beneficiaryRespSIN'"
            [addressStreet]="'beneficiaryRespAddressStreet'"
            [addressUnit]="'beneficiaryRespAddressUnit'"
            [addressProv]="'beneficiaryRespAddressProv'"
            [addressCity]="'beneficiaryRespAddressCity'"
            [addressPostalCode]="'beneficiaryRespAddressPostalCode'"
            [addressCountry]="'beneficiaryRespAddressCountry'"
            [beneficiaryAddressSameAsSubscriber]="'beneficiaryRespAddressSameAsSubscriber'"
            [parentLink]="'beneficiaryRespParentLink'"
            [distributionRatio]="'beneficiaryRespDistributionRatio'"
            [caregiverParentFirstName]="'beneficiaryRespCaregiverParentFirstName'"
            [caregiverParentLastName]="'beneficiaryRespCaregiverParentLastName'"
            [caregiverParentSin]="'beneficiaryRespCaregiverParentSin'"
            [requestCLBForResp]="'beneficiaryRespRequestCLBForResp'"
            [custodialParentGender]="'beneficiaryRespCustodialParentGender'"
            [custodialParentFirstName]="'beneficiaryRespCustodialParentFirstName'"
            [custodialParentLastName]="'beneficiaryRespCustodialParentLastName'"
            [custodialParentSecGender]="'beneficiaryRespCustodialParentSecGender'"
            [custodialParentSecFirstName]="'beneficiaryRespCustodialParentSecFirstName'"
            [custodialParentSecLastName]="'beneficiaryRespCustodialParentSecLastName'"
        ></app-beneficiary-pleinex>
    </div>



    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
