import {Component} from '@angular/core';
import {AccountHolderInformationChildComponent} from './account-holder-information-child.component';

@Component({
    templateUrl: './account-holder-information.component.html'
})
export class AccountHolderInformationChildRefComponent extends AccountHolderInformationChildComponent {

    public displayParentDobAndSin(): boolean {
        return true;
    }

    public getDOBLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_DOB';
    }

    public getSINLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_SIN';
    }

}
