<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_REGISTRATION_ACCOUNT' | translate"
        [id]="'ippRegistrationNumber'"
        [name]="'ippRegistrationNumber'"
        [path]="'ippRegistrationNumber'"
        [binding]="'otherIppRegistrationNumber'"
        [attr]="{maxlength: 32}"
    ></app-odc-field>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_TRADE_AUTHORIZED_PEOPLE' | translate"
        [id]="'ippAuthorizedTradePersonNumber'"
        [name]="'ippAuthorizedTradePersonNumber'"
        [path]="'ippAuthorizedTradePersonNumber'"
        [binding]="'otherActPeople'"
        [attr]="{maxlength: 2}"
        [integerFormat]="true"
    ></app-odc-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
