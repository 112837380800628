<h2>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}</h2>

<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--fiducieType-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE' | translate"
        [id]="'fiducieType'"
        [name]="'fiducieType'"
        [path]="'fiducieType'"
        [list]="fiducieTypeList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="'otherFiducieType'"
    ></app-odc-field>

    <div *ngIf="form.get('fiducieType').value === constants.OTHER_FIDUCIE_TYPE_GRANTOR_SIMPLE">
        <h4>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_GRANTOR_TITLE'|translate}}</h4>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_AMERICAN_PEOPLE' | translate"
            [id]="'fiducieActAmericanPeople'"
            [name]="'fiducieActAmericanPeople'"
            [path]="'fiducieActAmericanPeople'"
            [binding]="'actAmericanPeople'"
            [integerFormat]="true"
            [attr]="{maxlength: 2}"
        ></app-odc-field>

        <!--fiducieActPeople-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_PEOPLE' | translate"
            [id]="'fiducieActPeople'"
            [name]="'fiducieActPeople'"
            [path]="'fiducieActPeople'"
            [binding]="'otherActPeople'"
            [integerFormat]="true"
            [attr]="{maxlength: 2}"
        ></app-odc-field>
    </div>

    <!--fiducieHasAmericanResident-->
    <div *ngIf="form.get('fiducieType').value === constants.OTHER_FIDUCIE_TYPE_COMPLEX">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_IS_THERE_AMERICAN_RESIDENT' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'fiducieHasAmericanResident'"
            [name]="'fiducieHasAmericanResident'"
            [path]="'fiducieHasAmericanResident'"
            [binding]="'otherAccountHasAmericanResident'"
        ></app-odc-field>
    </div>

    <app-province-resident-question [form]="form" [submitted]="formSubmitted"></app-province-resident-question>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
