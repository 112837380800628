import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {WebException} from '../../components/models';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {PostLoginActionService} from '../../components/services/post-login-action.service';
import {VmdValidators} from "../../components/validators/vmd-validators";
import {AdvisorInvestmentMeetingCustomer} from "../../components/models/advisorInvestmentMeetingCustomer";

@Component({
    selector: 'app-add-account-submission',
    templateUrl: './add-account-submission.component.html'
})
export class AddAccountSubmissionComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;
    submitted = false;

    alreadySubmitError: string = null;
    submitLabel: string = null;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService,
                private translatePipe: TranslatePipe,
                private postLoginActionService: PostLoginActionService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.formService.getForm().advisorInvestmentMeetingCustomer = new AdvisorInvestmentMeetingCustomer();

        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));

        this.form = this.fb.group({
            advisorInvestmentTitle: [this.formService.getForm().advisorInvestmentMeetingCustomer?.title, [this.requiredValidator('advisorInvestmentTitle')]],
            advisorInvestmentFirstName: [this.formService.getForm().advisorInvestmentMeetingCustomer?.firstName, [this.requiredValidator('advisorInvestmentFirstName')]],
            advisorInvestmentLastName: [this.formService.getForm().advisorInvestmentMeetingCustomer?.lastName, [this.requiredValidator('advisorInvestmentLastName')]],
            advisorInvestmentDateMeeting: [this.formService.getForm().advisorInvestmentMeetingCustomer?.dateMeeting, [this.requiredValidator('advisorInvestmentDateMeeting'), VmdValidators.date, VmdValidators.dateInPast]],
            confirm: [false]
        });

        this.form.get('advisorInvestmentTitle').valueChanges.subscribe(value => {
            this.formService.setFormProperty('advisorInvestmentMeetingCustomer.title', value);
            console.log('advisorInvestmentTitle', this.formService.getForm().advisorInvestmentMeetingCustomer?.title)
        });
        this.form.get('advisorInvestmentFirstName').valueChanges.subscribe(value => {
            this.formService.setFormProperty('advisorInvestmentMeetingCustomer.firstName', value);
            console.log('advisorInvestmentFirstName', this.formService.getForm().advisorInvestmentMeetingCustomer?.firstName)
        });
        this.form.get('advisorInvestmentLastName').valueChanges.subscribe(value => {
            this.formService.setFormProperty('advisorInvestmentMeetingCustomer.lastName', value);
            console.log('advisorInvestmentLastName', this.formService.getForm().advisorInvestmentMeetingCustomer?.lastName)
        });
        this.form.get('advisorInvestmentDateMeeting').valueChanges.subscribe(value => {
            this.formService.setFormProperty('advisorInvestmentMeetingCustomer.dateMeeting', value);
            console.log('advisorInvestmentDateMeeting', this.formService.getForm().advisorInvestmentMeetingCustomer?.dateMeeting)
        });

        if (VmdConstants.MODES.AUTOMATED === this.formService.getForm().entryMode
            && !this.hasPrintedOrSavedOrCorrectStatus()) {
            this.submitted = true;
        }
        this.initSubmitLabel();

    }

    beforeShowDay = (date: any) => {
        return date <= new Date().setHours(0, 0, 0, 0);
    }

    submitForm() {
        this.markAsTouched(this.form);

        if (this.form.get('confirm').value
            && this.form.valid
            && this.hasPrintedOrSavedOrCorrectStatus()) {

            if (!this.formService.isCompleted()) {
                if (!this.formService.saveInProgress()) {

                    this.loading = true;
                    this.formService.emitIsSaving(true);
                    this.error = null;
                    this.alreadySubmitError = null;

                    this.formService.saveCompleteForm().subscribe(
                        (data: any) => {

                            if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {

                                if (!this.submitLabel) {
                                    this.formService.getForm().requestID = data.payload;
                                    this.initSubmitLabel();
                                }

                                this.formService.emitSaved();
                            }

                            this.formService.setCompleted(true);
                            this.submitted = true;
                        },
                        (error: WebException) => {
                            this.postLoginActionService.registerAction(() => this.submitForm());
                            this.onCompleteFormError(error);
                        }
                    ).add(() => {
                        this.loading = false;
                        this.formService.emitIsSaving(false);
                    });
                }
            } else {
                this.alreadySubmitError = 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED';
            }
        } else {
            this.focusOnErrorsList();
        }
    }

    navBack() {
        if (this.isPleinEx()
            && this.formService.isPrintMode()
            && (this.formService.getCurrentComposant() && this.formService.getCurrentComposant().processRules())
        ) {
            this.clickSubmitButton();
        } else {
            this.navigationService.emitBack();
        }
    }

    private initSubmitLabel() {
        return this.isUpdateAccount() && this.isPilotMemberOrModeUpdateAutomatedOpeningActive() ?
            this.submittedLabelForUpdateText() :
            this.submittedLabelText();
    }

    private hasPrintedOrSavedOrCorrectStatus(): boolean {
        return (
            null === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_PRINTED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_SAVED === this.formService.getForm().requestStatus
            || VmdConstants.REQUEST_STATUS_CORRECT === this.formService.getForm().requestStatus
        );
    }

    private submittedLabelForUpdateText() {
        if (this.isRecoveredRequest()) {
            this.submitLabel = this.translatePipe.transform('SUBMITTED_UPDATE_LABEL');
        } else if (this.formService.getForm().requestID) {
            this.makeSubmitLabelWithName();
        }
    }

    private submittedLabelText() {
        this.submitLabel = this.translatePipe.transform('SUBMITTED_LABEL');
    }

    private makeSubmitLabelWithName() {

        this.formService.getSessionUserName().subscribe((data: any) => {
                if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                    const updateSubmitUserInfos = data.payload;

                    this.submitLabel = this.translatePipe.transform('SUBMITTED_UPDATE_LABEL_WITH_NAME',
                        {date: updateSubmitUserInfos.submitDate, name: updateSubmitUserInfos.fullName});
                }
            },
            (error: WebException) => {
                this.error = error;
                this.loading = false;
            });
    }

}
