import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-sub-field',
  templateUrl: './review-sub-field.component.html'
})
export class ReviewSubFieldComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;

    delimiter: string;

  ngOnInit() {

  }

}
