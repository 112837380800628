import {Directive, ElementRef, forwardRef, HostListener} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VmdKeyboard} from '../utils/vmd-keyboard';
import {AddressCompleterService} from '../services/address-completer.service';

declare let $: any;

@Directive({
    selector: '[appStreetCompleter]',
    providers: [
        VmdKeyboard,
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StreetCompleterDirective), multi: true}
    ]
})
export class StreetCompleterDirective implements ControlValueAccessor {

    constructor(private el: ElementRef, private keyboard: VmdKeyboard, private addressCompleterService: AddressCompleterService) {
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        // OCE-8150
        if (this.el.nativeElement.value !== this.addressCompleterService.searchTerm) {

            this.writeValue(this.addressCompleterService.searchTerm);
        } else {

            this.writeValue(this.el.nativeElement.value);
        }

        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.el.nativeElement.value = obj;
        this.onChange(obj);
    }
}
