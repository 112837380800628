import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {ModalService} from '../../components/services/modal.service';
import {NavigationService} from '../../components/services/navigation.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {VmdValidators} from '../../components/validators/vmd-validators';

declare let $: any;
declare let _: any;

@Component({
    selector: 'app-platform-choice',
    templateUrl: './platform-choice.component.html'
})
export class PlatformChoiceComponent extends BaseNavComponent implements AfterViewInit, OnInit {

    private loadingView: boolean;
    private noteCaller;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            platformType: [this.formService.getForm().plateformDisnat, this.requiredValidator('plateformDisnat')]
        });

        this.subscriptions.push(this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            setTimeout(() => {
                this.initNotes();
            });
        }));

        this.subscriptions.push(this.modalService.validClickOnPlatformBack.subscribe(() => {
            // Nothing to do
        }));

        this.subscriptions.push(this.modalService.validClickOnPlatformContinue.subscribe(() => {
            this.submitForm();
        }));
        this.loadingView = true;
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.loadingView = false;

        this.initNotes();
    }

    initNotes(): void {
        $('.appel-note').off('click');
        $('.appel-note').on('click', (e) => {
            this.noteCaller = $(e.currentTarget);
            const noteId = $(e.currentTarget).attr('href');
            if ($(noteId).length > 0) {
                $(noteId).focus();
            }
            return false;
        });
    }

    noteGoBack(id): boolean {
        if ($(this.noteCaller).length > 0 && _.contains($('.appel-note[href="#' + id + '"]'), $(this.noteCaller)[0])) {
            $(this.noteCaller).focus();
        } else {
            $('.appel-note[href="#' + id + '"]').first().focus();
        }
        return false;
    }

    submit() {
        if (this.formService.getForm().plateformDisnat === this.constants.DISNAT_DIRECT) {
            this.modalService.openModal('ODC_STEP_PLATFORM_MODAL_TITLE', 'ODC_STEP_PLATFORM_MODAL_BODY', '#modal-alert-platform-disnat');
        } else {
            this.submitForm();
        }
    }

}
