import {AttachedDocumentValidation} from "./attachedDocumentValidation";

export class AttachedDocument {

    id: string;
    requestId: string;
    type: string;
    name: string;
    content: string;
    size: string;
    validation: AttachedDocumentValidation;

    constructor(obj?: any) {

        this.id = obj && obj.id || null;
        this.type = obj && obj.type || null;
        this.name = obj && obj.name || null;
        this.content = obj && obj.content || null;
        this.size = obj && obj.size || null;
        this.validation = new AttachedDocumentValidation(obj && obj.validation || null);
    }
}
