import {Component} from '@angular/core';
import {BaseAddSpouseRelatedQuestionsComponent} from './base-add-spouse-related-questions.component';

@Component({
    selector: 'app-enfant-ref-related-questions',
    templateUrl: './base-add-spouse-related-questions.component.html'
})
export class EnfantRefRelatedQuestionsComponent extends BaseAddSpouseRelatedQuestionsComponent {

    public getFieldPrefix(): string {
        return 'enfref';
    }

}
