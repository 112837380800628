import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AsideComponent} from './aside.component';

@Component({
    selector: 'app-aside-caisse',
    templateUrl: './aside-caisse.component.html'
})
export class AsideCaisseComponent extends AsideComponent implements OnInit, OnDestroy {

    caisseServiceOpen = false;
    deskServiceOpen = false;

    @Input() showConsult: boolean;
    @Input() showSave: boolean;
    @Input() showUpdateForm: boolean;
    fromLogin: any;

    caisseService() {
        this.caisseServiceOpen = !this.caisseServiceOpen;
        if (this.caisseServiceOpen) {
            this.deskServiceOpen = false;
        }
    }

    deskService() {
        this.deskServiceOpen = !this.deskServiceOpen;
        if (this.deskServiceOpen) {
            this.caisseServiceOpen = false;
        }
    }

    displayConsult() {
        return this.showConsult;
    }

    displaySave() {
        return this.showSave;
    }

    protected allowedAutoSavedCaisse(): boolean {
        return !this.formService.isPleinEx()
            && this.formService.isCaisseContext()
            && this.displaySave();
    }

}
