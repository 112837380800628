import {Component, Inject, Input, OnInit} from '@angular/core';
import {SummaryFieldsConstants} from '../../../components/constants/update-account-delta-review-page/summary-fields-constants';
import {SummaryFieldsTypeEnum} from '../../../components/enums/summary-fields-type.enum';
import {ListModelService} from '../../../components/services/list-model.service';
import {VmdFormat} from '../../../components/formats/vmd-format';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';
import {VmdConstants} from '../../../components/constants/vmd-constants';

@Component({
    selector: 'app-review-update-account-table',
    templateUrl: './review-update-account-table.component.html',
    styles: ['td {vertical-align: middle !important; word-break: break-word}']
})
export class ReviewUpdateAccountTableComponent implements OnInit {

    @Input() data: any;
    @Input() pageStep: number;

    private jobStatusList: HtmlSelectKV[] = [];
    private countryList: HtmlSelectKV[] = [];
    private nifReasons: HtmlSelectKV[] = [];
    private userMaritalStatusList: HtmlSelectKV[] = [];
    private codeInvestmentHorizon: HtmlSelectKV[] = [];
    private codeRiskClass: HtmlSelectKV[] = [];
    private codeRiskTolerance: HtmlSelectKV[] = [];
    private userBrokerageKnowledgeList: HtmlSelectKV[] = [];
    private intendedUsageList: HtmlSelectKV[] = [];
    private beneficiaryRelationList: HtmlSelectKV[] = [];
    private receiptDocumentSecuritiesHolderIndicator: {} = {};

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig,
                private listModelService: ListModelService) {
        this.receiptDocumentSecuritiesHolderIndicator = {
            ALL: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1_FORMATTED',
            NONE: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2_FORMATTED',
            ONLY: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3_FORMATTED',
        };
    }

    ngOnInit(): void {
        this.loadList();
    }

    loadList() {
        this.listModelService.getListModel('statutEmploiList', data => this.jobStatusList = data);
        this.listModelService.getListModel('paysList', data => this.countryList = data);
        this.listModelService.getListModel('statusNoNifList', data => this.nifReasons = data);
        this.listModelService.getListModel('listEtatCivil', data => this.userMaritalStatusList = data);
        this.listModelService.getListModel('codeInvestmentHorizon', data => this.codeInvestmentHorizon = data);
        this.listModelService.getListModel('codeRiskClass', data => this.codeRiskClass = data);
        this.listModelService.getListModel('codeRiskTolerance', data => this.codeRiskTolerance = data);
        this.listModelService.getListModel(this.config.IS_PLEIN_EX ? 'brokerageKnowledgePleinExList' : 'brokerageKnowledgeList', data => this.userBrokerageKnowledgeList = data);
        this.listModelService.getListModel('codeIntendedUsage', data => this.intendedUsageList = data);
        this.listModelService.getListModel('beneficiaryRespRelationList', data => this.beneficiaryRelationList = data);
    }

    transform(value: string, fieldName: string, after?: boolean): any {

        if (!value || value === 'null') {
            if (after) {
                return '';
            }
            return 'REVIEW_MISSING_DATA';
        }

        const fieldType = SummaryFieldsConstants[fieldName] ? SummaryFieldsConstants[fieldName][1] : '';

        if (fieldType === SummaryFieldsTypeEnum.FREE_FORM) {
            return value;
        }

        if (fieldType === SummaryFieldsTypeEnum.LANG) {
            return value === VmdConstants.LANG_EN ?
                'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_EN'
                : 'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_FR';
        }

        if (fieldType === SummaryFieldsTypeEnum.FINANCIAL_FORM) {
            return VmdFormat.numeric.formatAsCurrency(value);
        }

        if (fieldType === SummaryFieldsTypeEnum.PERCENTAGE_FORM) {
            return VmdFormat.numeric.formatAsPercentage(value);
        }

        if (fieldType === SummaryFieldsTypeEnum.PHONE) {
            return VmdFormat.phone.formatAsPhone(value);
        }

        if (fieldType === SummaryFieldsTypeEnum.BOOLEAN) {

            if (fieldName === 'UserFiscalRelationCanada' && value) {
                return 'GLOBAL_YES';
            }

            if (fieldName === 'UserFiscalRelationUsa' && value) {
                return 'GLOBAL_YES';
            }

            if (fieldName === 'UserFiscalRelationOther' && value) {
                return 'GLOBAL_YES';
            }

            return value === 'Y' || value === '1' ? 'GLOBAL_YES' : 'GLOBAL_NO';
        }

        if (fieldType === SummaryFieldsTypeEnum.NIF_LIST) {
            return ListModelService.getLabelFromList(this.nifReasons, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.COUNTRY_LIST) {
            this.listModelService.getListModel('paysList', data => this.countryList = data);
            return ListModelService.getLabelFromList(this.countryList, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.COUNTRIES_LIST) {
            this.listModelService.getListModel('paysList', data => this.countryList = data);
            const countries = JSON.parse(value);
            return ListModelService.getLabelsFromList(this.countryList, countries, ', ');
        }

        if (fieldType === SummaryFieldsTypeEnum.JOB_LIST) {
            return ListModelService.getLabelFromList(this.jobStatusList, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.MARITAL_STATUS_LIST) {
            return ListModelService.getLabelFromList(this.userMaritalStatusList, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.INVESTMENT_HORIZON_LIST) {
            return ListModelService.getLabelFromList(this.codeInvestmentHorizon, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.RISK_TOLERANCE_LIST) {
            return ListModelService.getLabelFromList(this.codeRiskTolerance, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.RISK_CLASS_LIST) {
            return ListModelService.getLabelFromList(this.codeRiskClass, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.BROKERAGE_KNOWLEDGE_LIST) {
            return ListModelService.getLabelFromList(this.userBrokerageKnowledgeList, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.INTENDED_USAGE_LIST) {
            return ListModelService.getLabelFromList(this.intendedUsageList, value);
        }

        if (fieldType === SummaryFieldsTypeEnum.DOCUMENT_SECURITY_HOLDER_LIST) {
            return this.receiptDocumentSecuritiesHolderIndicator[value];
        }

        return value;
    }
}
