<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <h2>
        <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
        {{'SPECIFIC_CONSENT_TITLE'|translate}}
    </h2>
    <br>
    <div class="panel panel-default panel-noborder">
        <div class="panel-body text-justify">

            <ng-container *ngIf="pleinEx">
                <ng-container *ngIf="translate.currentLang === 'fr'">
                    <ng-container *ngTemplateOutlet="agreementSpecificConsentPleinExFR"></ng-container>
                </ng-container>
                <ng-container *ngIf="translate.currentLang === 'en'">
                    <ng-container *ngTemplateOutlet="agreementSpecificConsentPleinExEN"></ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!pleinEx">
                <ng-container *ngIf="translate.currentLang === 'fr'">
                    <ng-container *ngTemplateOutlet="agreementSpecificConsentFR"></ng-container>
                </ng-container>
                <ng-container *ngIf="translate.currentLang === 'en'">
                    <ng-container *ngTemplateOutlet="agreementSpecificConsentEN"></ng-container>
                </ng-container>
            </ng-container>

        </div>
    </div>

    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'personalInformationSpecificConsentIndicator'"
        [name]="'personalInformationSpecificConsentIndicator'"
        [path]="'personalInformationSpecificConsentIndicator'"
        [binding]="getRequesterPath('personalInformationSpecificConsentIndicator')"
    ></app-odc-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
<ng-template #agreementSpecificConsentFR>
    <p>Afin de vous faire bénéficier de l’offre 360 de services personnalisés et adaptés à vos besoins du Mouvement Desjardins, notamment en matière
        d’épargne, de placement, de crédit, d’assurance, de planification financière et de services fiduciaires, Desjardins Courtage en ligne et les
        composantes du Mouvement Desjardins mentionnées ci-dessous doivent communiquer entre elles les renseignements personnels vous concernant
        qu’elles ont respectivement recueillis et recueilleront à l’occasion des différentes prestations de services à votre égard.
    </p>

    <p>Ces renseignements peuvent notamment comprendre des informations relatives à vos comptes, vos soldes et dépôts, vos opérations, vos produits
        détenus, votre profil ou plan financier, ou toute autre information pertinente à la prestation des services offerts.</p>
    <hr>
    <p>Dans le respect de la réglementation en matière de protection des renseignements personnels, j’autorise Valeurs mobilières Desjardins inc.
        (VMD) et les composantes du Mouvement Desjardins avec lesquelles je fais ou ferai affaire à se communiquer entre elles les renseignements
        personnels qu’elles ont recueillis ou recueilleront à mon sujet, lesquels sont nécessaires afin de me permettre de bénéficier de l’offre 360
        de services. </p>

    <p>Ce consentement est valable pour la durée nécessaire à la réalisation de l’offre de services de VMD à mon égard. Je peux y mettre fin en tout
        temps en avisant VMD.</p>
</ng-template>

<ng-template #agreementSpecificConsentEN>
    <p>The Desjardins components listed below and Desjardins Online Brokerage want to make sure they are able to provide you with personalized
        services tailored to your needs through our 360° service offer, including savings, investment, credit, insurance, financial planning and trust
        services. In order to do that, they need to share personal information about you with each other that they've collected and will collect when
        providing these services to you.</p>

    <p>This personal information may include information about your accounts, balances and deposits, transactions, the products you hold, your
        financial profile or plan, or any other information that is relevant to the delivery of the services offered by Desjardins Securities Inc.</p>
    <hr>
    <p>In accordance with privacy regulations, I hereby authorize Desjardins Securities Inc. and the Desjardins Group components with which I am or
        will be doing business to share the personal information they've collected or will collect about me with each other that is necessary to allow
        me to take advantage of the 360° service offer.</p>

    <p>This consent is valid for the period required by Desjardins Securities Inc. to perform the advisory services I have requested. I may withdraw
        my consent at any time by advising Desjardins Securities Inc.</p>
</ng-template>

<ng-template #agreementSpecificConsentPleinExFR>
    <p>Afin de vous faire bénéficier de l’offre 360 de services personnalisés et adaptés à vos besoins du Mouvement Desjardins, notamment en matière
        d’épargne, de placement, de crédit, d’assurance, de planification financière et de services fiduciaires, Desjardins Gestion de patrimoine
        Valeurs mobilières (VMD) et les composantes du Mouvement Desjardins mentionnées ci-dessous doivent communiquer entre elles les renseignements
        personnels vous concernant qu’elles ont respectivement recueillis et recueilleront à l’occasion des différentes prestations de services à
        votre égard.
    </p>

    <p>Ces renseignements peuvent notamment comprendre des informations relatives à vos comptes, vos soldes et
        dépôts, vos opérations, vos produits détenus, votre profil ou plan financier, ou toute autre information
        pertinente à la prestation des services offerts.</p>
    <hr>
    <p>Dans le respect de la réglementation en matière de protection des renseignements personnels, j’autorise VMD et les composantes du Mouvement
        Desjardins avec lesquelles je fais ou ferai affaire à se communiquer entre elles les renseignements personnels qu’elles ont recueillis ou
        recueilleront à mon sujet, lesquels sont nécessaires afin de me permettre de bénéficier de l’offre 360 de services.</p>

    <p>Ce consentement est valable pour la durée nécessaire à la réalisation de l’offre de services-conseils de VMD à mon égard. Je peux y mettre fin
        en tout temps en avisant VMD.</p>
</ng-template>

<ng-template #agreementSpecificConsentPleinExEN>
    <p>Desjardins Wealth Management Securities and the Desjardins components listed below want to make sure they are able to provide you with
        personalized services tailored to your needs through our 360° service offer, including savings, investment, credit, insurance, financial
        planning and trust services. In order to do that, they need to share personal information about you with each other that they've collected and
        will collect when providing these services to you.</p>

    <p>This personal information may include information about your accounts, balances and deposits, transactions, the
        products you hold, your financial profile or plan, or any other information that is relevant to the delivery of
        the services offered by Desjardins Securities Inc.</p>
    <hr>
    <p>In accordance with privacy regulations, I hereby authorize Desjardins Securities Inc. and the Desjardins Group
        components with which I am or will be doing business to share the personal information they've collected or will
        collect about me with each other that is necessary to allow me to take advantage of the 360° service offer.</p>

    <p>This consent is valid for the period required by Desjardins Securities Inc. to perform the advisory services I
        have requested. I may withdraw my consent at any time by advising Desjardins Securities Inc.</p>
</ng-template>
