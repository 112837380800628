import {Component, OnInit} from '@angular/core';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';


@Component({
    selector: 'app-joint-related-questions',
    templateUrl: './joint-related-questions.component.html'
})
export class JointRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    // TODO Desactivation volet 3 sans 8c : Retirer Résident de la province de Québec
    hidden = null;


    ngOnInit() {

        // Desactivation volet 3 sans 8c : Retirer Résident de la province de Québec
        if (this.isPleinEx()) {
            this.hidden = true;
        }

        this.form = this.fb.group({
            jointActPeople: [this.formService.getForm().jointActPeople, [this.requiredValidator('jointActPeople')]],
            resQc: [this.formService.getForm().resQc, [this.requiredValidator('resQc')]],
            survivorShip: [this.formService.getForm().survivorShip, [this.requiredValidator('survivorShip')]]
        });

        this.form.get('jointActPeople').valueChanges.subscribe(value => {
            this.formService.setFormProperty('jointActPeople', value);
            if (value === 3) {
                this.formService.setFormProperty('addRESP', false);
                this.formService.setFormProperty('respTransfer', null);
            }
        });

        this.form.get('resQc').valueChanges.subscribe((value) => {
                if (value) {
                    this.formService.setFormProperty('survivorShip', null);
                }
                this.updateSurvivorShipControl();
            }
        );

        if (this.form.get('resQc').value == null || this.form.get('resQc')) {
            this.updateSurvivorShipControl();
        }
    }

    updateSurvivorShipControl(): void {
        this.updateControlEnabled(this.form.get('survivorShip'), this.isPleinexAndNotQuebecResident());
    }

    isPleinexAndNotQuebecResident(): boolean {
        return this.isPleinEx() && this.form.get('resQc').value === false;
    }
}
