export const I18N_MESSAGES_EN: any = {

    GLOBAL_YES: 'Yes',
    GLOBAL_NO: 'No',
    GLOBAL_NEXT: 'Next',
    GLOBAL_BACK: 'Previous',
    GLOBAL_SELECT: 'Please select',
    GLOBAL_DATE_HELP: 'yyyy-mm-dd',
    GLOBAL_ERROR: 'Error',
    GLOBAL_WARNING: 'Warning',
    CURRENCY_CAD: 'CAD',
    CURRENCY_USD: 'USD',
    CURRENCY_CAD_AND_USD: 'CAD and USD',

    GLOBAL_AND: 'and',
    GLOBAL_NONE: 'None',
    GLOBAL_MODIFY: 'Modify',
    GLOBAL_CLOSE: 'Close',
    GLOBAL_SAVE_REQUEST: 'Save application',
    ERROR_TECHNICAL_PROBLEMS_OCCURED: 'An error has occurred. Please try again later',

    SR_ONLY_PHONE: 'This link will launch your default phone software.',
    SR_ONLY_GO_TO_MAIN_CONTENT: 'Go to the main content',

    ODC_LINK_NEW_WINDOW: ' - This link will open in a new window.',

    NOTE_BACK: '[Back]',

    MODAL_END: 'End of modal',

    ODC_OR: 'OR',
    ODC_PDF_LIST: 'Documents and Forms',
    ODC_PDF_LIST_TEXT: 'Please click on the following link to download the additional form(s) required for the account opening:',
    ODC_PDF_LIST_OTHER_ACCOUNT_TEXT: 'Click <strong>Continue</strong> to proceed to the next step to receive the account opening kit by email.',
    ODC_PDF_LIST_LINK: 'https://www.disnat.com/en/help-contact/document-form',

    DURATION: 'Duration:',

    CONFIRMATION_MESSAGE_TRASH: 'Are you sure you want to start over that section?',

    ADDRESS_COMPLETER_TOOLTIP: 'To search for an address, use the field No. & street to enter an address or a Postal Code. As you type, results are filtered to allow you to select the address you are looking for.',

    EXPIRATION_SESSION_TITLE: 'End of session',
    EXPIRATION_SESSION_BODY_TIMEOUT_1: 'Your session is about to expire.<br/>Do you wish to continue your account opening?',
    EXPIRATION_SESSION_BODY_TIMEOUT_2: 'seconds before expiration',

    HEADER_TITLE_PART1: 'Account Opening',
    HEADER_TITLE_PART1_ADD_ACCOUNT: 'Add Account',
    HEADER_TITLE_PART1_UPDATE_ACCOUNT: 'Update: {{clientId}}',
    HEADER_TITLE_PART1_BRANCH_LIST: 'Requests Follow-Up',
    HEADER_TITLE_PART1_OSS_QUERY: 'OSS Query',
    HEADER_TITLE_SUFFIX: ' | Desjardins Online Brokerage',
    HEADER_LANGUAGE_LABEL: 'Français',
    HEADER_LANGUAGE_CODE: 'fr',
    HEADER_TEXT: 'This tool will guide you through the account opening process. For further information, please review the ',
    HEADER_DOCUMENT_LINK: 'Relationship Disclosure Document (PDF, 459 KB)',
    HEADER_DOCUMENT_LINK_URL: 'https://www.disnat.com/en/client-service/forms/Disnat_reglementaire_DX99_web',
    HEADER_LOGO_LINK_URL: 'https://www.disnat.com/en',

    STEP_NAV_STEPS_SIZE: 'This form has {{size}} step{{plural}}',
    STEP_NAV_ITEM_STEP: 'Step',
    STEP_NAV_ITEM_COMPLETED: 'Completed',
    STEP_NAV_ITEM_SELECTED: 'Selected',
    STEP_NAV_PREVIOUS_STEPS: 'Previous steps',
    STEP_NAV_NEXT_STEPS: 'Next steps',

    FOOTER_OTHER_QUESTIONS_1: 'Do you have questions?',
    FOOTER_OTHER_QUESTIONS_2: 'Don\'t hesitate to ask!',
    FOOTER_SAC: 'Client Service',
    FOOTER_PHONE_NO: 'Phone: 1-866-873-7103',
    FOOTER_EMAIL_TITLE: 'Email :',
    FOOTER_EMAIL: 'infodisnat@desjardins.com',
    FOOTER_INVISIBLE_PHONE: 'one, height six six, height seven three, seven one zero three',

    SIDEBAR_ASSISTANCE_TITLE: 'We\'re here to help',
    SIDEBAR_ASSISTANCE_PHONE: '1-866-873-7103',
    SIDEBAR_ASSISTANCE_PHONE_LINK: '+18668737103',
    SIDEBAR_ASSISTANCE_SCHEDULE: 'Monday to Friday (8 a.m. to 5 p.m. ) ET',
    SIDEBAR_ASSISTANCE_DROPDOWN_1: 'If you have any questions, our knowledgeable representatives will be happy to assist you.',
    SIDEBAR_ASSISTANCE_DROPDOWN_2: 'Call us toll free:',
    SIDEBAR_ASSISTANCE_HOURS: 'Monday to Friday (8am to 5pm)',

    SIDEBAR_PLATFORM_TITLE: 'To consult',
    SIDEBAR_PLATFORM_COMMISSION: 'Commissions and fees',
    SIDEBAR_PLATFORM_COMMISSION_LINK: 'https://www.disnat.com/en/platforms-and-fees',
    SIDEBAR_PLATFORM_CONVENTION: 'Relationship Disclosure Document and Agreements (PDF 1.49 MB)',
    SIDEBAR_PLATFORM_CONVENTION_LINK: 'https://www.disnat.com/en/client-service/forms/Disnat_reglementaire_DX99_web',
    SIDEBAR_PLATFORM_GENERAL_DOCUMENTS: 'My Portal: General Documents',
    SIDEBAR_PLATFORM_GENERAL_DOCUMENTS_LINK: 'https://mvtdesjardins.sharepoint.com/sites/monportail/en/produits-et-services/Pages/courtagevaleursmobilieres.aspx',

    HELP: 'Help',
    HELP_CLOSE: 'Activate to close help',
    HELP_OPEN: 'Activate to open help',

    HELP_SIN_TITLE: 'Why must I provide my SIN?',
    HELP_SIN_BODY: 'All brokerage firms must report earnings on interest and dividends to the Canada Revenue Agency (CRA). As part of this requirement, we must provide the social insurance number (SIN) of the person in question.',

    HELP_DOB_TITLE: 'Why is it important for me to provide my date of birth?',
    HELP_DOB_BODY: 'This information is important so that we can confirm that you are 18 or older, the age required to open a brokerage account. Also, if you open an RRSP account, we need to ensure that you do not exceed the age limit for contributions to the plan.',

    CAISSE_SIDEBAR_TITLE: 'To contact us',
    CAISSE_SIDEBAR_CAISSE_SERVICE_HEADING: 'Caisse Services',
    CAISSE_SIDEBAR_CAISSE_SERVICE_LINE1: 'For general account opening',
    CAISSE_SIDEBAR_CAISSE_SERVICE_LINE2: 'or brokerage questions.',
    CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_PHONE: '1-800-363-7528',
    CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_HOURS: 'Monday to Friday (8 a.m. to 5 p.m. ) ET',
    CAISSE_SIDEBAR_DESK_SERVICE_HEADING: 'Desk Services',
    CAISSE_SIDEBAR_DESK_SERVICE_LINE1: 'For questions about system access',
    CAISSE_SIDEBAR_DESK_SERVICE_LINE2: 'and technical issues.',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE1: '6882433 (“MVT-AIDE”)',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE2: '514-284-2433',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE3: '1-855-688-2433',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS: 'Business hours:',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE1: 'Monday: 8:30am to 4:30pm',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE2: 'Tuesday: 8:30am to 4:30pm',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE3: 'Wednesday: 8:30am to 7:00pm',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE4: 'Thursday: 8:30am to 7:00pm',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE5: 'Friday: 8:30am to 4:30pm',
    CAISSE_SIDEBAR_IMAGE_ALT: 'All the information concerning Disnat to accompany the member.',
    CAISSE_SIDEBAR_IMAGE_LINK: 'Read more',
    CAISSE_SIDEBAR_IMAGE_HREF: 'https://www.disnat.com/en',

    ODC_LOGIN_TITRE_PAGE: 'Resume an application',
    ODC_LOGIN_EMAIL: 'E-Mail:',
    ODC_LOGIN_PASSWORD: 'Password:',
    ODC_LOGIN_ACTION_LOGIN: 'Resume',
    ODC_LOGIN_ACTION_RESET_PASSWORD: 'Forgot your password?',
    ODC_LOGIN_EXPLANATION: 'Please enter your e-mail and password to resume your account opening process.',

    ODC_FORGOT_PASSWORD_PAGE_TITLE: 'Forgot your password',
    ODC_FORGOT_PASSWORD_EMAIL: 'Please enter your email',

    ODC_CREATE_PROFIL_TITLE: 'Create profil',

    REPORTING_INSIDER_TITLE: 'Reporting insider',
    SIGNIFICANT_SHAREHOLDER_TITLE: 'Significant shareholder',

    ODC_QUESTIONNAIRE_SAVE_TITLE: 'Would you like to save your application?',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE: 'You can pause the application process at any time and return to it later.',
    ODC_QUESTIONNAIRE_SAVE_BUTTON: 'Save this application',
    ODC_QUESTIONNAIRE_SAVE_ERROR: 'An error has occurred while saving your application. Please try again later.',
    ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED: 'It seems that your request has already been submitted. It cannot be sent again.',
    ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED_COMPARE_SAVED_FORM: 'Prohibited action: Your request has already been submitted / printed.',
    ODC_QUESTIONNAIRE_SAVE_EMAIL: 'Please provide a valid email address in order to send this request.',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED: 'Your application is saved.',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_REPRISE: 'Resumption of a saved application',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_PLEINEX: 'Your request has been saved.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1: 'You can now leave the form at any time and come back later to the following address:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_UPDATE: 'You can now leave the form. To return later, please visit the "Account Opening" desktop in SmartD.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_CAISSE: 'You can now leave the form at any time and return later to follow up on your requests.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_REPRISE: 'You have selected a previously saved application. You can save this application again and return to it later at following link:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_2: 'Here is your login information:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_3: 'E-Mail:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_4: 'Password:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_5: 'www.disnat.com/en/client-service',
    ODC_QUESTIONNAIRE_SAVE_URL: 'http://www.disnat.com/en/client-service',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_PLEINEX: 'You can now leave the form. To return later, please visit the "Account Opening" desktop in SmartD.',
    ODC_QUESTIONNAIRE_PRINT_BUTTON: 'Print this application',
    ODC_QUESTIONNAIRE_PRINT_ERROR: 'An error has occurred while printing your application. Please try again later.',
    ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_TITLE: 'Please wait',
    ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_BODY: 'Your request is being saved.',
    ODC_QUESTIONNAIRE_IN_PROCESS_MODAL_INFO_BODY: 'Your request is being processed.',

    STEP_BEGIN_TITLE: 'Open an Account Online',
    STEP_BEGIN_BANNER_1: '<strong>In just 20 minutes</strong> for some accounts (non-registered, RRSP, TFSA and FHSA)',
    STEP_BEGIN_BANNER_2: 'No paper, no postal delays <strong>using your electronic signature</strong>',
    STEP_BEGIN_TRADES_TITLE: 'Place Trades for Yourself',
    STEP_BEGIN_TRADES_DESC: 'On Canadian and US markets.',
    STEP_BEGIN_TRADES_SECURITIES_TITLE: 'Securities ',
    STEP_BEGIN_TRADES_SECURITIES_STOCKS_OPTIONS: 'Stocks / options',
    STEP_BEGIN_TRADES_SECURITIES_BONDS: 'Bonds',
    STEP_BEGIN_TRADES_SECURITIES_FUNDS: 'Funds',
    STEP_BEGIN_TRADES_SECURITIES_ETF: 'ETFs',
    STEP_BEGIN_TRADES_PLANS_TITLE: 'Accounts',
    STEP_BEGIN_TRADES_PLANS_NON_REGISTERED: 'Non-registered',
    STEP_BEGIN_TRADES_PLANS_RRSP: 'RRSP',
    STEP_BEGIN_TRADES_PLANS_TFSA: 'TFSA',
    STEP_BEGIN_TRADES_PLANS_FHSA: 'FHSA',
    STEP_BEGIN_TRADES_PLANS_RESP: 'RESP',
    STEP_BEGIN_TRADES_PLANS_LIRA: 'LIRA',
    STEP_BEGIN_TRADES_PLANS_RRIF: 'RRIF',
    STEP_BEGIN_TRADES_PLANS_LIF: 'LIF',
    STEP_BEGIN_ELIGIBILITY_TITLE: 'Eligibility',
    STEP_BEGIN_ELIGIBILITY_DESC: 'You must:',
    STEP_BRANCH_BEGIN_ELIGIBILITY_DESC: 'The client must:',
    STEP_BEGIN_ELIGIBILITY_1: 'Be over 18 years old',
    STEP_BEGIN_ELIGIBILITY_1_BRANCH: 'Be over 18 years old',
    STEP_BEGIN_ELIGIBILITY_2: 'Reside in Canada',
    STEP_BEGIN_ELIGIBILITY_3: 'Have investment knowledge',
    STEP_BEGIN_ELIGIBILITY_3_BRANCH: 'Have investment knowledge',
    STEP_BEGIN_ELIGIBILITY_4: 'Have a social insurance number',
    STEP_BEGIN_ELIGIBILITY_5: 'Have their cell phone for the electronic signature',
    STEP_BEGIN_DOCUMENTS_TITLE: 'You will need the following information to complete your application:',
    STEP_BEGIN_DOCUMENTS_1: 'Your social insurance number',
    STEP_BEGIN_DOCUMENTS_2: 'A void cheque in JPEG or PDF format',
    STEP_BEGIN_DOCUMENTS_3: 'A scanned copy of a government issued ID with photo',
    STEP_BEGIN_DOCUMENTS_LBA_2: 'A personalized void cheque',
    STEP_BEGIN_DOCUMENTS_LBA_3: 'A legible copy of 2 valid and authentic supporting documents in order to proceed with identity verification',
    STEP_BEGIN_DOCUMENTS_LBA_4: 'Only PDF, PNG and JPEG documents are accepted.',
    STEP_BEGIN_RESUME_REQUEST: 'Resume an application',
    STEP_BEGIN_FAQ: 'Frequently Asked Questions',
    STEP_BEGIN_FAQ_PERSONAL_INFORMATION: 'Why must I provide my personal information?',
    STEP_BEGIN_FAQ_CANADIAN_RESIDENT: 'Am I considered a Canadian resident?',
    STEP_BEGIN_FAQ_AMERICAN_CITIZENS: 'Can American citizens open an account at Desjardins Online Brokerage?',
    STEP_BEGIN_FAQ_APPLE_SAFARI_COOKIES_ACCEPTATION: 'I am using Apple Safari, how can I accept cookies so that I can electronically sign my request?',

    STEP_BRANCH_BEGIN_DESC_1: 'In just 20 minutes for personal accounts, without paperwork or postage thanks to an electronic signature.',
    STEP_BRANCH_BEGIN_DESC_2: 'Get a package with all the forms required for joint account opening or for any other types of accounts.',
    STEP_BRANCH_BEGIN_DESC_3: 'The language of correspondence will be the language used to complete the questionnaire. You can change it by clicking on the  <strong>"Français"</strong> button located in the top right corner of the page.',
    STEP_BRANCH_BEGIN_DESC_4: 'The questionnaire will be filled in using the information entered in the "Desjardins Individual File (DIF)" depending on whether the consent is signed.',
    STEP_BRANCH_BEGIN_DESC_5: 'However, you must have located the file prior.',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_LIST_DESC: 'For the electronic signature, you must scan the following documents:',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_CAD_CHECK: 'A sample check in CAD currency',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_USD_CHECK: 'A sample check in USD (optional)',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_ID: 'Government ID with valid photo of the client',


    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_TITLE: 'Electronic Signature',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_1: 'Must be signed only in the presence of the client',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_2: 'Is done with the client\'s cell phone',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_3: 'Is as valid as a handwritten signature (paper)',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_4: 'Is available for personal account openings only',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_NOTE: 'Note : at any time, the client may choose to sign their account opening documents electronically or by hand (on paper).',

    STEP_BRANCH_BEGIN_SAVE_TITLE: 'Saving your work',
    STEP_BRANCH_BEGIN_SAVE_DESC: 'You can save your request at any time and resume it later.',

    ODC_STEP_PREPARATION_RDV_TITLE: 'Would you prefer to have an adviser help you open an account?',
    ODC_STEP_PREPARATION_RDV_DESCRIPTION: 'At a Desjardins caisse',
    ODC_STEP_PREPARATION_RDV_ACTION: 'Find a branch near you',
    ODC_STEP_PREPARATION_RDV_ACTION_URL: 'https://www.desjardins.com/ca/locator/index.jsp',
    ODC_STEP_PREPARATION_CONTACT_TITLE: 'At our offices',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL: 'MONTREAL',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_SCHEDULE: 'Monday to Friday from 8 a.m. to 5 p.m. ET',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_1: '1170 Peel Street',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_2: 'Suite 105',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_3: 'Montreal, QC',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_4: 'H3B 0A9',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC: 'QUEBEC',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_SCHEDULE: 'Monday to Friday from 8 a.m. to 5 p.m. ET',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_1: 'Place de la cité',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_2: '2600 Laurier Blvd',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_3: 'Suite 130',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_4: 'Quebec, QC',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_5: 'G1V 4T3',

    /* Preperation - Client Type */
    ODC_STEP_PREPARATION_CLIENT_TYPE_TITLE: 'Select an account type',
    ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT: 'Personal Account',
    ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_DESC: 'A personal account is one that is opened in the name of a single individual who owns the entire value of the account. This may be a cash account, margin, with options and short selling and may include registered plans.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT: 'Joint Account',
    ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT_DESC: 'A joint account is one that is in the name of 2 or 3 people, in a relationship or otherwise. Funds held in this account are jointly owned by each person. Account holders are jointly liable for the obligations or debts of the account.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_QUESTION: 'Do you want to open a RESP account?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_TYPE: 'Do you want to open a family RESP or individual RESP?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_OPTION_FAMILY: 'Family',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_OPTION_INDIVIDUAL: 'Individual',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_BENEFICIARY: 'How many beneficiaries would you like to register?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_SUBSCRIBER: 'Is one of the subscribers a parent or primary caregiver?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT: 'Other Accounts',
    ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT_DESC: 'Denotes all accounts other than personal or joint accounts. Subject to conditions governing the various types of accounts.',

    /* Preperation - Platform Type */
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_IDEAL: 'Ideal for you if',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_FEATURE: 'Features',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_MARKET_DATA: 'Market Data',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_PLATFORM_FEE: 'Platform Fees',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_INACTIVITY_FEE: 'Inactivity Fees<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_ADMINISTRATION_FEE: 'Administration Fees<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_STOCK_COMMISSION: 'Stock Commissions',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_CHOICE: 'Choice of Platform',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CHOICE: 'Choice of Platform',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC: 'Disnat classic',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT: 'Disnat direct',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_IDEAL: 'You are a <strong>beginner</strong> or experienced investor',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_FEATURE: 'Simple, modern and complete web trading platform',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_MARKET_DATA: '<ul><li><strong>Level 1</strong></li><li>Real-time quote updates</li></ul>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_PLATFORM_FEE: 'FREE real-time quotes',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_INACTIVITY_FEE: 'Inactivity fees of <strong>$30 per quarter</strong> apply if none of the exemption criteria are met<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_ADMINISTRATION_FEE: 'From <strong>$0</strong> to <strong>$100</strong> per year if none of the exemption criteria are met<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_STOCK_COMMISSION: '<strong>$0 per trade</strong>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_IDEAL: 'You are an <strong>active</strong> investor',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_FEATURE: 'Sophisticated trading platform',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_MARKET_DATA: '<ul><li><strong>Level 1 and 2</strong></li><li>Dynamic real-time quote updates</li><li>Unlimited dynamic real-time quotes</li></ul>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_PLATFORM_FEE: 'From <strong>$0 to $105 per month</strong> depending on the number of transactions and the type of market data chosen',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_INACTIVITY_FEE: 'Inactivity fees of <strong>$30 per quarter</strong> apply if none of the exemption criteria are met<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_ADMINISTRATION_FEE: 'From <strong>$0</strong> to <strong>$100</strong> per year if none of the exemption criteria are met<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_STOCK_COMMISSION: '<strong>$0 per trade</strong>',

    ODC_STEP_PLATFORM_TYPE_NOTE_TITLE: 'Notes',
    ODC_STEP_PLATFORM_TYPE_NOTE_1: '1 – Inactivity fees will not be charged to the account if:',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_1: 'Six or more transactions have been executed in the last 12 months, or;',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_2: 'The value of the portfolio is $15,000 or over, or;',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_3: 'A registered plan is held (RRSP, RRIF, LIRA, LIF, RESP).',
    ODC_STEP_PLATFORM_TYPE_NOTE_2: '2 – Administration fees will not be charged to the account if:',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_1: 'RRSP/LIRA: a value of $15,000 or over is maintained in the plan(s)',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_2: 'RRIF/LIF: a value of $25,000 or over is maintained in the plan(s)',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_3: 'RESP: Assets held in all subscriber accounts have a market value of $15,000 or over',

    ODC_STEP_PLATFORM_MODAL_TITLE: 'You have selected Disnat Direct',
    ODC_STEP_PLATFORM_MODAL_BODY: 'This is a more sophisticated platform intended for knowledgeable investors who perform many transactions each month. Platform fees may apply.',

    /* Account choice */
    ACCOUNT_CHOICE_TITLE: 'Non-Registered Account (created automatically)',
    ACCOUNT_CHOICE_UPDATE_TITLE: 'Non-Registered Account',
    ACCOUNT_CHOICE_FIDUCIARY_TRANSACTION_ACCOUNTS_TITLE: 'Fiduciary Transaction Accounts',
    REGISTERED_ACCOUNT_CHOICE_TITLE: 'Add Registered Account (optional)',
    NEW_EXIST_ACCOUNT: 'New account or add account',
    REQUEST_TYPE: 'Request type',
    REQUEST_TYPE_NEW_CLIENT: 'New',
    REQUEST_TYPE_ADD_ACCOUNT: 'Add',
    ACCOUNT_TYPE: 'Account type',
    DCL_ACCOUNT_TYPE: 'Need advanced investment strategies? (Optional)',
    ACCOUNT_CURRENCY: 'Account currency',
    FHSA_ELIGIBILITY: 'FHSA eligibility',
    NEW_ACCOUNT: 'New Account',
    DCL_NEW_ACCOUNT: 'I don\'t have a Desjardins Online Brokerage account yet',
    REDOCUMENT_ACCOUNT: 'Update an account',
    UPDATE_ACCOUNT: 'Update',
    ADD_ACCOUNT: 'Add an account to your client number',
    DCL_ADD_ACCOUNT: 'I already have a Desjardins Online Brokerage account',
    CLIENT_NUMBER: '',
    DCL_CLIENT_NUMBER: 'Desjardins Online Brokerage Account Number',
    CLIENT_NUMBER_LONG: 'Username',
    CASH_ACCOUNT: 'Cash Account',
    CASH_ACCOUNT_HELP: 'A brokerage account that allows for the purchase or sale of securities, in which you must have the amount of money required to cover your purchases, or which you have to pay the required amount before the settlement date. Each transaction must be settled in full.',
    CASH_ACCOUNT_HELP_DCL: 'A brokerage account that allows for the purchase or sale of securities, in which you must have the amount of money required to cover your purchases. Each transaction must be settled in full.',
    MARGIN_ACCOUNT: 'Margin Account',
    MARGIN_ACCOUNT_HELP: 'A type of account in which you can buy or sell securities on credit, by paying the amount corresponding to the difference between the market value of the security and the amount advanced by the brokerage in securities. You are charged interest on this loan.',
    MARGIN_ACCOUNT_WITH_OPTIONS: 'Margin account with options',
    WITH_OPTIONS: 'With options',
    DCL_WITH_OPTIONS: 'Options trading',
    WITH_OPTIONS_HELP: 'This is a margin account that allows you to buy or sell options. An option is a contract that provides the right or obligation to buy or sell a specified amount of an underlying security at a specified price within a specified time.',
    MARGIN_ACCOUNT_WITH_SHORT_SELLING: 'Short selling account',
    WITH_SHORT_SELLING: 'With short selling',
    DCL_WITH_SHORT_SELLING: 'Short selling',
    WITH_SHORT_SELLING_HELP: 'This is a margin account that allows you to make short sales. Short selling involves selling securities you do not own. In this case, Desjardins Securities borrows the securities on your behalf.',
    CA_ONLY: 'CAD only',
    US_AND_CA: 'USD and CAD',
    RRSP_ACCOUNT: 'RRSP',
    RRSP_ACCOUNT_DESC: 'A Registered Retirement Savings Plan (RRSP) is a registered account in which you, your spouse or common-law partner can contribute.',
    RRSP_ACCOUNT_HELP: 'It offers a double tax advantage: your contributions are deducted from your taxable income and income from your investments is reinvested fully in your plan tax-free. However, you generally have to pay tax when you withdraw funds from the plan.',
    RRSP_ANNUITANT: 'Personal/Spousal RRSP',
    RRSP_ANNUITANT_PERSONAL: 'Personal RRSP',
    RRSP_ANNUITANT_SPOUSAL: 'Spousal RRSP',
    RRSP_ADD: 'Add RRSP',
    RRSP_DELETE: 'Delete RRSP',
    TFSA_ACCOUNT: 'TFSA',
    TFSA_ACCOUNT_DESC: 'A Tax-Free Savings Account (TFSA) is a savings account in which you can put money aside tax-free.',
    TFSA_ACCOUNT_HELP: 'Contributions to a TFSA are not tax deductible. Funds withdrawn from this account will not be taxable.',
    FHSA_ACCOUNT: 'FHSA',
    FHSA_ACCOUNT_ELIGIBILITY: 'FHSA eligible',
    FHSA_ACCOUNT_DESC: 'A first home savings account (FHSA) is a registered plan allowing you, as a prospective first-time home buyer, to save for your first home tax-free (up to certain limits).',
    FHSA_ACCOUNT_HELP: 'Contributions that you make to your first home savings accounts (FHSAs) are generally deductible on your income tax and benefit return. It is important to note that transfers from your registered retirement saving plans (RRSPs) to your FHSAs <strong>are not deductible</strong>.',
    FHSA_ELIGIBILITY_CONFIRMATION: 'I confirm that I am a first-time home buyer',
    FHSA_HELP_ELIGIBILITY: '<strong>First-time home buyer:</strong> An individual is considered to be a first-time home buyer if, at any time in the part of the calendar year before the account is opened\n' +
        'or at any time in the preceding four calendar years, they did not live in a qualifying home as their principal place of residence (or what would be a qualifying\n' +
        'home if it were located in Canada) that was owned (either jointly or otherwise) by the individual or by their spouse or common-law partner (if the individual\n' +
        'has a spouse or common-law partner at the time of entering into an FHSA).\n' +
        '</br><strong>Qualifying home:</strong> A qualifying home is defined as a housing unit located in Canada. It also includes a share of the capital stock of a cooperative housing\n' +
        'corporation, where the holder of the share is entitled to possession of a housing unit located in Canada. However, where the context requires, such a share\n' +
        'means the housing unit to which the share relates.',
    CURRENCY_MODAL_TITLE: 'Account currency',
    CURRENCY_MODAL_BODY_TO_USD: 'To allow you to open registered accounts in Canadian and US dollars, you must have non-registered accounts in the same currency. As a result, the currencies in your non-registered account were automatically changed to Canadian and US dollars.',
    CURRENCY_MODAL_BODY_TO_CAD: 'You can not open a registered account in Canadian and US dollars if the non-registered account is in Canadian dollars only. As a result, the registered account currency was automatically changed to Canadian dollars.',
    RESP_ACCOUNT: 'Individual or family RESP',
    RESP_ACCOUNT_DESC: 'A Registered Education Saving Savings Plan (RESP) allows you, as a subscriber, to accumulate funds to finance the post-secondary education of one or more beneficiaries.',
    RESP_ACCOUNT_HELP: 'An individual RESP must be in the name of a single beneficiary, while a family RESP can be established on behalf of multiple beneficiaries. Contributions will not be deductible from your income, but the return on your investments will accumulate tax-free. The federal government and some provincial governments provide grants. Part of the withdrawals will be taxable for the beneficiary who receives them.',
    RESP_TYPE: 'Do you want to open a family or individual RESP?',
    RESP_TYPE_INDIVIDUAL: 'Individual',
    RESP_TYPE_FAMILY: 'Family',
    RESP_NUMBER_OF_BENEFICIARIES: 'How many beneficiaries would you like to register?',
    RESP_IS_SUBSCRIBER_RESPONSIBLE: 'Is the subscriber the parental primary caregiver?',
    LIRA_ACCOUNT: 'LIRA, RLSP or LRSP',
    LIRA_ACCOUNT_DESC: 'A Locked-In Retirement Account (LIRA), Restricted Locked-in Savings Plan (RLSP) and Locked-In Retirement Savings Account (LRSP) are plans in which locked-in amounts are deposited from a pension plan of your former employer.',
    LIRA_ACCOUNT_HELP: 'These locked-in amounts cannot be withdrawn except under certain special circumstances. In this case, the amount withdrawn from the account will be taxable. The return on your investments will accumulate tax-free. No contributions can be made to these plans.',
    LIRA_JURISDICTION: 'What is the jurisdiction of your plan?',
    LIRA_JURISDICTION_DEFAULT_OPTION: 'Please select a jurisdiction',
    LIRA_ADD: 'Add LIRA, RLSP or LRSP',
    LIRA_DELETE: 'Delete LIRA, RLSP or LRSP',
    RRIF_ACCOUNT: 'RRIF',
    RRIF_ACCOUNT_DESC: 'A Registered Retirement Income Fund (RRIF) is a plan for generating retirement income from funds initially contributed to your RRSP.',
    RRIF_ACCOUNT_HELP: 'The return on your investments will accumulate tax-free. A minimum withdrawal amount will be established at the beginning of each year. Amounts withdrawn from the plan will be taxable.',
    RRIF_ANNUITANT: 'Personal/Spousal RRIF',
    RRIF_ANNUITANT_PERSONAL: 'Personal RRIF',
    RRIF_ANNUITANT_SPOUSAL: 'Spousal RRIF',
    RRIF_ADD: 'Add RRIF',
    RRIF_DELETE: 'Delete RRIF',
    LIF_ACCOUNT: 'LIF or RLIF',
    LIF_ACCOUNT_DESC: 'A Life Income Fund (LIF) or the Restricted Life Income Fund (RLIF) are retirement income plan accounts that originate from funds deposited in your LIRA, RLSP or LRSP or your employer\'s pension plan.',
    LIF_ACCOUNT_HELP: 'The return on your investments will accumulate tax-free. A minimum withdrawal amount and a maximum withdrawal amount will be established at the beginning of each year. Any amount withdrawn from the account will be taxable.',
    LIF_JURISDICTION: 'What is the jurisdiction of your plan?',
    LIF_JURISDICTION_DEFAULT_OPTION: 'Please select a jurisdiction',
    LIF_FUNDS_FROM: 'Do the funds come from a LIRA account or a pension plan?',
    FTARRSP_ACCOUNT: 'Fixed-Term Annuity RRSP',
    FTARRSP_ACCOUNT_DESC: 'A fixed-term annuity plan is a registered retirement savings account available to residents of Quebec.',
    FTARRSP_ACCOUNT_HELP: 'It allows for the designation of a beneficiary upon death (revocable or irrevocable) and, in certain circumstances, provides greater protection for the property held therein.',
    FTARRSP_ANNUITANT: 'Personal/Spousal Fixed-Term Annuity RRSP',
    FTARRSP_ANNUITANT_PERSONAL: 'Personal Fixed-Term Annuity RRSP',
    FTARRSP_ANNUITANT_SPOUSAL: 'Spousal Fixed-Term Annuity RRSP',
    FTARRSP_REVOCABLE_BENEFICIARY: 'Designation of beneficiary at death',
    FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE: 'Revocable',
    FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE: 'Irrevocable',
    FTARRSP_ADD: 'Add Fixed-Term Annuity RRSP',
    FTARRSP_DELETE: 'Delete Fixed-Term Annuity RRSP',
    ACCOUNT_CHOICE_MODAL_FRV4AB_BC: 'An LIF or RLIF cannot be opened if you have not reached 50 years old.',
    ACCOUNT_CHOICE_MODAL_FRV4AB_BC_TITLE: 'Maximum age for opening a LIF or RLIF account',
    ACCOUNT_CHOICE_MODAL_FRV4NS_ON: 'An LIF or RLIF account cannot be opened if you have not reached 55 years old.',
    ACCOUNT_CHOICE_MODAL_FRV4NS_ON_TITLE: 'Maximum age for opening a LIF or RLIF account',
    ACCOUNT_CHOICE_MODAL_CRI: 'An LIRA, RLSP or LRSP cannot be opened after December 31 of the year in which you turn 71.',
    ACCOUNT_CHOICE_MODAL_CRI_TITLE: 'Maximum age for opening a LIRA, RLSP or LRSP account',
    ACCOUNT_CHOICE_MODAL_REER: 'An RRSP cannot be opened after December 31 of the year in which you turn 71.',
    ACCOUNT_CHOICE_MODAL_REER_TITLE: 'Maximum age for opening a RRSP account',
    ACCOUNT_CHOICE_MODAL_RRNV: 'A FTA RRSP account cannot be opened if you are not a resident of the province of Quebec.',
    ACCOUNT_CHOICE_MODAL_RRNV_TITLE: 'Province for opening a FTA RRSP account',
    ACCOUNT_CHOICE_MODAL_FHSA: 'An FHSA cannot be opened after December 31 of the year in which you turn 71.',
    ACCOUNT_CHOICE_MODAL_FHSA_TITLE: 'Maximum age for opening a FHSA account',
    ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY_TITLE: 'Non-resident of Canada',
    ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY: 'A FHSA account cannot be opened for a non-resident of Canada.',
    MGNT_TYPE: 'Type of management',
    MGNT_TYPE_COM: 'Commission-Based Advisory Services',
    MGNT_TYPE_MAND: 'Mandate Only',
    MGNT_TYPE_DISCRETIONARY: 'Discretionary Management Service',
    SFD_ACCOUNT_NUMBER: 'Desjardins Trust Services account number',
    SFD_ACCOUNT_NUMBER_CAD: 'Desjardins Trust Services account number (CAD)',
    SFD_ACCOUNT_NUMBER_USD: 'Desjardins Trust Services account number (USD)',
    SFD_PRODUCT_NUMBER: 'Product number',

    // Type of management
    ODC_STEP_TYPE_OF_MANAGEMENT_TITLE: 'Management Type',
    INVESTMENT_ADVICE: 'Investment Advice',
    PORTFOLIO_MANAGEMENT: 'Portfolio Management',
    INVESTMENT_ADVICE_COMMISSION_BASED: 'Commission-Based Advisory Services',
    INVESTMENT_ADVICE_FEE_BASED: 'Fee-Based Advisory Services',
    INVESTMENT_ADVICE_ELITE_BASIC: 'ELITE Basic',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE: 'ELITE Intermediate',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE: 'ELITE FLEXible',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM: 'Portfolio Manager Advisor Program',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_GPD: 'Discretionary Management service',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_SSD: 'Funds Portfolios',
    PORTFOLIO_MANAGEMENT_DISCRETIONARY_SERVICE: 'Discretionary Management Service',
    SECURITIES_PORTFOLIOS: 'Securities Portfolios',
    PORTFOLIO_MANAGEMENT_MANDATE_ONLY: 'Mandate Only',
    INVESTMENT_ADVICE_COMMISSION_BASED_FORM: 'V201',
    INVESTMENT_ADVICE_COMMISSION_BASED_FORM_SSD: 'SD201',
    INVESTMENT_ADVICE_ELITE_BASIC_FORM: 'V201 + V2066',
    INVESTMENT_ADVICE_ELITE_BASIC_PCG_FORM: 'V201 + V201PMAP + V201PMAP AR + V2066',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE_FORM: 'V201 + V2067',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE_PCG_FORM: 'V201 + V201PMAP + V201PMAP AR + V2067',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM: 'V201 + V2069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_PCG_FORM: 'V201 + V201PMAP + V201PMAP AR + V2069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_GDF_FORM: 'SD201 + SD201DFM + V2069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM_SSD: 'SD201 + V2069',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM: 'V201 + V201PMAP + V201PMAP AR',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD: 'GPD201 + GPD201.1 + GPD201P',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_ENTITY: 'GPD201E + GPD201.1 + GPD201P',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_OLD: 'GPD201 + GPD201.1 + GPD201Q',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_ENTITY_OLD: 'GPD201E + GPD201.1 + GPD201Q',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_SSD: 'SD201 + SD201DFM',
    PORTFOLIO_MANAGEMENT_MANDATE_ONLY_FORM: 'V233',
    SECURITIES_PORTFOLIOS_FORM: 'SD233',

    // Personal Information
    ODC_STEP_PERSONAL_INFORMATION_TITLE: 'Personal Information of the Client',
    ODC_STEP_PERSONAL_INFORMATION_HOLDER_TITLE: 'Account holder information',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE: 'Client language',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_FR: 'French',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_EN: 'English',
    ODC_STEP_PERSONAL_INFORMATION_GENDER: 'Title',
    ODC_STEP_PERSONAL_INFORMATION_MRS: 'Ms.',
    ODC_STEP_PERSONAL_INFORMATION_MR: 'Mr.',
    ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME: 'First Name',
    ODC_STEP_PERSONAL_INFORMATION_LAST_NAME: 'Last Name',
    ODC_STEP_PERSONAL_INFORMATION_DOB: 'Date of birth',
    ODC_STEP_PERSONAL_INFORMATION_DOB_HELP: 'yyyy-mm-dd',
    ODC_STEP_PERSONAL_INFORMATION_SIN: 'Social insurance number',
    ODC_STEP_PERSONAL_INFORMATION_SIN_OPTIONAL: 'Social insurance number (optional)',
    ODC_STEP_PERSONAL_INFORMATION_HOME_PHONE: 'Home telephone',
    ODC_STEP_PERSONAL_INFORMATION_OTHER_PHONE: 'Other telephone',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_HOME: 'Telephone (home)',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_BUSINESS: 'Telephone (business)',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_MOBILE: 'Telephone (mobile)',
    ODC_STEP_PERSONAL_INFORMATION_EMAIL: 'E-Mail',
    ODC_STEP_PERSONAL_INFORMATION_HOME_ADDRESS_TITLE: 'Home address',
    ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TITLE: 'Address',
    ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TYPE: 'Please indicate what type of address is:',
    ODC_STEP_PERSONAL_INFORMATION_OSS_IS_RESIDENTIAL_ADDRESS: 'Home address',
    ODC_STEP_PERSONAL_INFORMATION_OSS_IS_MAILING_ADDRESS: 'Mailing address',
    ODC_STEP_PERSONAL_INFORMATION_COUNTRY: 'Country',
    ODC_STEP_PERSONAL_INFORMATION_STREET: 'No. & street',
    ODC_STEP_PERSONAL_INFORMATION_UNIT: 'Unit / apt.',
    ODC_STEP_PERSONAL_INFORMATION_CITY: 'City',
    ODC_STEP_PERSONAL_INFORMATION_PROVINCE: 'Province',
    ODC_STEP_PERSONAL_INFORMATION_STATE: 'State',
    ODC_STEP_PERSONAL_INFORMATION_POSTAL: 'Postal code',
    ODC_STEP_PERSONAL_INFORMATION_IS_ADRESS_DIFFERENT_AS_FIRST_ACCOUNT_HOLDER: 'Is your address different from that of the first accountholder ?',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_TITLE: 'Mailing address',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_ADD: 'Add a different mailing address',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_REMOVE: 'Delete mailing address',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC: 'An LIF or RLIF cannot be opened if you have not reached 50 years old. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong></b> if you would like to correct the date of birth.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC_TITLE: 'Maximum age for opening a LIF or RLIF account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON: 'An LIF or RLIF account cannot be opened if you have not reached 55 years old. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong> if you would like to correct the date of birth.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON_TITLE: 'Maximum age for opening a LIF or RLIF account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI: 'An LIRA, RLSP or LRSP cannot be opened after December 31 of the year in which you turn 71. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong> if you would like to correct the date of birth.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI_TITLE: 'Maximum age for opening a LIRA, RLSP or LRSP account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_REER: 'An RRSP cannot be opened after December 31 of the year in which you turn 71. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong> if you would like to correct the date of birth.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_REER_TITLE: 'Maximum age for opening a RRSP account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA: 'A FHSA cannot be opened after December 31 of the year in which you turn 71. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong> if you would like to correct the date of birth.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_TITLE: 'Maximum age for opening a FHSA account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY_TITLE: 'Non-resident of Canada',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY: 'A FHSA account cannot be opened for a non-resident of Canada. Click <strong>Continue</strong> to remove this type of account from the request or <strong>Cancel</strong> if you want to correct the home address.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV: 'A FTA RRSP account cannot be opened if you are not a resident of the province of Quebec. Click <strong>Continue</strong> to remove this type of account from the application or <strong>Cancel</strong> if you would like to correct the province.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV_TITLE: 'Province for opening a FTA RRSP account',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RESP_JOINT_SECOND_ACCOUNTHOLDER_SPOUSE: 'To open a RESP account, the second accountholder must be your spouse.',
    ODC_STEP_PERSONAL_INFORMATION_IS_SECOND_ACCOUNTHOLDER_SPOUSE: 'Is the second accountholder your spouse?',
    ODC_STEP_CONTACT_INFORMATION_TITLE: 'Contact Information',
    ODC_STEP_PERSONAL_INFORMATION_ADDRESS_RECOVERED: 'Address currently on file :',
    ODC_STEP_PERSONAL_INFORMATION_IS_CURRENT_ADDRESS_QUESTION: 'Is this your current address ?',

    ODC_STEP_SPOUSE_INFORMATION_CLIENT_MARITAL_STATUS: 'Client Marital Status',
    ODC_STEP_SPOUSE_INFORMATION_TITLE: 'Spousal Information',
    ODC_STEP_SPOUSE_INFORMATION_MARITAL_STATUS: 'Marital Status',
    ODC_STEP_SPOUSE_EMPLOYER_NAME: 'Employer Name',
    ODC_STEP_SPOUSE_JOB_TITLE: 'Job Title',
    ODC_STEP_SPOUSE_ACTIVITY_AREA: 'Activity Area',
    ODC_STEP_CONTRIBUTING_SPOUSE_INFORMATION_TITLE: 'Contributing Spouse Information',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_LABEL: 'Is the contributing spouse to your {{order}} {{type}} your current spouse?',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_FIRST: 'first',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_SECOND: 'second',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_THIRD: 'third',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_RRSP: 'RRSP',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_RRIF: 'RRIF',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_FTARRSP: 'FTAR',
    ODC_STEP_MARITAL_STATUS_VERIFICATION_TITTLE: 'Marital Status',
    ODC_STEP_MARITAL_STATUS_VERIFICATION_MESSAGE: 'You have indicated that the second account holder is your spouse.<br/> Please select the appropriate marital status in the Client Marital Status section.',

    ODC_STEP_REFERRED_ACCOUNT_TITLE: 'Reference Desjardins Caisse',
    ODC_STEP_REFERRED_ACCOUNT_INSTITUTION: 'Institution Number',
    ODC_STEP_REFERRED_ACCOUNT_TRANSIT: 'Transit Number',
    ODC_STEP_REFERRED_ACCOUNT_FirstName: 'First Name',
    ODC_STEP_REFERRED_ACCOUNT_LastName: 'Last Name',

    OCCUPATION_TITLE: 'Occupation and employer',
    EMPLOYMENT_STATUS_LABEL: 'Employment status',
    EMPLOYMENT_STATUS_SELF_EMPLOYED: 'Self employed',

    ODC_STEP_OCCUPATION_MODAL_TITTLE: 'Change in employment status',
    ODC_STEP_OCCUPATION_MODAL_TEXT: 'The update cannot be executed because the question <em> Are you employed by a securities dealer? </em> shows a change in employment status. Please modify your answer to this question to continue. You\'ll need a new client number if the employment status has changed.',


    USER_EMPLOYER_IS_BROKER_LABEL: 'Are you employed by a securities dealer?',
    USER_EMPLOYER_IS_BROKER_NO: 'No',
    USER_EMPLOYER_IS_BROKER_VMD: 'Yes: I work for Desjardins Securities',
    USER_EMPLOYER_IS_BROKER_OTHER_BROKER: 'Yes: I work for a securities dealer other than Desjardins Securities',
    USER_EMPLOYER_AUTORIZATION: 'Please join an authorization from your employer.',

    USER_IS_INVESTMENT_ADVISOR: 'Are you an advisor?',

    USER_EMPLOYER_NAME: 'Employer\'s name',
    USER_EMPLOYER_VMD: 'Desjardins Securities inc.',
    USER_JOB_TITLE: 'Job title',
    USER_JOB_TITLE_INVESTMENT_ADVISOR: ' Advisor',
    USER_EMPLOYER_ACTIVITY: 'Sector of activity',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE: 'Do you live under the same roof as someone who works for a securities dealer?',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_VMD: 'Is this a Desjardins Securities employee?',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRM_NAME: 'Firm name',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRSTNAME: 'Employee\'s first name',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LASTNAME: 'Employee\'s last name',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LINK: 'Link',

    PERCENTAGE_OWNERSHIP_CLIENT: 'Percentage ownership of client',

    EMPLOYER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT: 'The following questions are regarding the natural persons controlling or holding more than 25% of the entity (VD262 section 4).',
    BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_VDX62_25_PERCENT: 'Questions starting with « Are you » are regarding the entity (V201 section 2) and/or the natural persons controlling or holding more than 25% of the entity (VD262 section 4).',
    BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT: 'Questions starting with « Are you » are regarding the entity (GPD201 section 2) and/or the natural persons controlling or holding more than 25% of the entity (VD262 section 4).',

    // tax
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN: 'Tax identification number (TIN)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TITLE: 'Place of tax residence',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_QUESTION: 'What is your citizenship ?',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA: 'Canadian',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES: 'U.S.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER: 'Other',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER_CITIZENSHIP: 'Other citizenship',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_RESIDENCE_QUESTION: 'Are you a temporary visitor to the United States (Snowbird)?',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_QUESTION: 'Please check all of the options that apply to you',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1: 'I am a tax resident of Canada.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2: 'I am a tax resident or a citizen of the United States.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN: 'Tax identification number (TIN)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_REASON: 'Reason if you don\'t have a TIN',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3: 'I am a tax resident of a jurisdiction other than Canada or the United States.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_COUNTRY: 'Country',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_TIN: 'Tax identification number (TIN)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON: 'Reason if you don\'t have a TIN',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_TITTLE: 'Address Verification',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_MESSAGE: 'Your home address country must match your country of residence for tax purpose.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_TIN_QUESTION: 'If you do not have a TIN from the USA, have you applied for one?',

    ODC_STEP_RESIDENTIAL_ADDRESS_ADD_2ND_COUNTRY: 'Add another country of residence for tax purposes.',
    ODC_STEP_RESIDENTIAL_ADDRESS_REMOVE_2ND_COUNTRY: 'Remove 2nd country.',

    ATTORNEY_TITLE: 'Attorney identification',
    USER_OTHER_TRADING_PERSON_TITLE: 'Would you like to authorize someone other than you to trade in this account?',
    ATTORNEY_CLIENT_NUMBER: 'Client Number',
    ATTORNEY_CLIENT_RELATIONSHIP: 'Relationship',
    ATTORNEY_CLIENT_RELATIONSHIP_TOOLTIP: 'The attorney must be a member of the immediate family, which includes: spouses, parents, children, brothers, sisters and any family members living under the same roof as the account holder.',
    ATTORNEY_ADDRESS_TITLE: 'Attorney\'s address',
    ATTORNEY_FINANCE_TITLE: 'Financial Institution',
    ATTORNEY_FINANCE_INSTITUTION: 'What is the name of the attorney\'s financial institution?',
    ATTORNEY_FINANCE_TRANSIT: 'Transit number',
    ATTORNEY_FINANCE_ACCOUNT: 'Account number',
    ATTORNEY_FINANCE_PHONE: 'Phone Number (optional)',
    ATTORNEY_INITIE: 'Is the Attorney a reporting insider of a company whose shares are traded on a stock exchange or in over-the-counter markets?',
    ATTORNEY_INITIE_COMPANY_NAME: 'Name of the company',
    ATTORNEY_INITIE_COMPANY_SYMBOL: 'Stock symbol',
    ATTORNEY_INITIE_MARKET: 'Market',
    ATTORNEY_INITIE_MARKET_CAN: 'Canadian',
    ATTORNEY_INITIE_MARKET_US: 'American',
    ATTORNEY_MAINSHAREHOLDER: 'Is the Attorney a significant shareholder of a company whose shares are traded on a stock exchange or in over-the-counter markets?',
    ATTORNEY_MAINSHAREHOLDER_TOOLTIP: 'Under IIROC\'s Universal Market Integrity Rules (UMIR), a significant Shareholder is a person who holds separately, or in combination with any other persons, more than 20% of the outstanding voting securities of an issuer.',
    ATTORNEY_MAINSHAREHOLDER_COMPANY_NAME: 'Name of the company',
    ATTORNEY_MAINSHAREHOLDER_COMPANY_SYMBOL: 'Stock symbol',
    ATTORNEY_MAINSHAREHOLDER_MARKET: 'Market',
    ATTORNEY_MAINSHAREHOLDER_MARKET_CAN: 'Canadian',
    ATTORNEY_MAINSHAREHOLDER_MARKET_US: 'American',
    ATTORNEY_CHOICE_TITLE: 'You have chosen to add an attorney',
    ATTORNEY_CHOICE_MESSAGE: 'Please note that the attorney must have their own account with Desjardins Online Brokerage.<br/><br/>To designate an attorney, click <strong>Continue</strong>. If you wish to continue without designating an attorney, click <strong>Cancel</strong>. The answer to the previous question will then be changed to "No".',

    USER_FINANCE_INFO_TITLE: 'Financial Information',
    USER_FINANCE_INFO_ANNUAL_REVENU: 'What is your annual revenue from all sources?',
    USER_FINANCE_INFO_TOTAL_NET_WORTH: 'Total net worth',
    USER_FINANCE_INFO_TOTAL_NET_WORTH_INVALID: 'The value could not be retrieved. Please list client assets below).',
    USER_FINANCE_INFO_NEW_TOTAL_NET_WORTH: 'New total net worth (A + B)',
    USER_FINANCE_INFO_EDIT_TOTAL_NET_WORTH: 'Edit total net worth',
    USER_FINANCE_INFO_EDIT_LOAN: 'Edit loan',
    USER_FINANCE_INFO_DELETE_TOTAL_NET_WORTH: 'Delete new total worth fields',
    USER_FINANCE_INFO_DELETE_LOAN: 'Delete loan modification fields',
    USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP_TITLE: 'Annual revenue from all sources',
    USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP: 'The total amount of income from employment, investment or other source.',
    USER_FINANCE_INFO_IMMO_ASSETS: 'What are your approximate net capital assets?',
    USER_FINANCE_INFO_IMMO_ASSETS_A: 'What are your approximate net capital assets? (A)',
    USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP_TITLE: 'Approximate net capital assets',
    USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP: 'Capital assets include real estate and commercial property less liabilities (secured and unsecured).',
    USER_FINANCE_INFO_LIQUID_ASSETS: 'What are your approximate net liquid assets?',
    USER_FINANCE_INFO_LIQUID_ASSETS_B: 'What are your approximate net liquid assets? (B)',
    USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP_TITLE: 'Approximate net liquid assets',
    USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP: 'Liquid assets are those that can be easily converted in cash.',
    USER_FINANCE_INFO_INSTITUTION: 'What is the name of your financial institution?',
    USER_FINANCE_INFO_TRANSIT: 'Transit number',
    USER_FINANCE_INFO_ACCOUNT: 'Account number',
    USER_FINANCE_INFO_PHONE: 'Financial institution\'s phone number (optional)',
    USER_FINANCE_INFO_OPTION_ACCOUNT_TITLE: 'Option Account',
    USER_FINANCE_INFO_OPTION_CODE: 'Authorized options code for non-registered account',
    USER_FINANCE_INFO_REGISTERED_OPTION_CODE: 'Autorized options code for registered account',

    ODC_STEP_FINANCIAL_INFORMATION_DATE_HELP: 'yyyy-mm-dd',

    USER_BORROWING_FOR_INVESTMENT_PURPOSE_TITLE: 'Borrowing for Investment Purposes',
    USER_BORROWING_FOR_INVESTMENT_PURPOSE_QUESTION: 'Have you borrowed money to finance the purchase of your investment products through loans advanced by third parties?',
    USER_BORROWING_AMOUNT_OF_LOAN: 'Amount of the loan',
    USER_BORROWING_INTEREST_RATE: 'Interest rate',
    USER_BORROWING_LOAN_STARTING_DATE: 'Loan starting date',
    USER_BORROWING_LOAN_MATURITY_DATE: 'Maturity of the loan',
    USER_BORROWING_AMOUNT_INVESTED: 'Amount invested as loans for investment purposes',
    USER_BORROWING_RECEIVED_AND_READ_BORROWING_RISKS_INDICATOR: 'I have received from my advisor explanations of the risks involving the leverage effect on borrowing for investment purposes. I have read and understood the information in the section entitled “Notices – Off-book borrowing for investment purposes” in the Relationship Disclosure Document.',

    USER_INVESTMENT_OBJECTIVES_INTENDED_USE_TITLE: 'Intended use of the account',
    USER_INVESTMENT_OBJECTIVES_INVESTMENT_HORIZON_TITLE: 'Investment Horizon',
    USER_INVESTMENT_OBJECTIVES_RISK_TOLERANCE_TITLE: 'Risk Profile',
    USER_INVESTMENT_OBJECTIVES_TITLE: 'Consolidated Investment Objectives',
    USER_INVESTMENT_OBJECTIVES_INTENDED_USE: 'Intended Use',
    USER_INVESTMENT_OBJECTIVES_NON_REG_INV_HORIZON: 'Investment Horizon',
    USER_INVESTMENT_OBJECTIVES_REG_INV_HORIZON: 'Investment Horizon',
    USER_INVESTMENT_OBJECTIVES_RSK_TOL: 'Risk Profile',
    USER_INVESTMENT_OBJECTIVES_TABLE_COL1: 'Investment Objectives',
    USER_INVESTMENT_OBJECTIVES_TABLE_COL2: 'Registered accounts',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW1: 'Low-risk income securities',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW2: 'Moderate-risk income securities',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW3: 'Moderate-to-high-risk income securities and growth securities',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW4: 'Very high risk securities',
    USER_INVESTMENT_INCLUDE_INVESTOR_PROFILE: 'Include Investor Profile',

    BROKERAGE_TITLE: 'Brokerage Accounts',
    BROKERAGE_OTHER_ACCOUNTS_TITLE: 'Do you have other brokerage accounts or do you control the trading in other brokerage accounts?',
    BROKERAGE_FIRM_NAME: 'Firm name',
    BROKERAGE_ACCOUNT_TYPE: 'Type of account',
    BROKERAGE_USER_REPORTING_INSIDER_TITLE: 'Are you a reporting insider of a company whose shares are traded on a Stock exchange or in over-the-counter markets?',
    BROKERAGE_MAIN_SHAREHOLDER_TITLE: 'Are you a significant shareholder of a company whose shares are traded on a Stock exchange or in over-the-counter markets?',
    BROKERAGE_COMPAGNY_NAME: 'Name of the company',
    BROKERAGE_COMPANY_SYMBOL: 'Stock symbol',
    BROKERAGE_MARKET: 'Market',
    BROKERAGE_MARKET_CAN: 'Canadian',
    BROKERAGE_MARKET_US: 'American',
    BROKERAGE_EPV_NPV_TITLE: 'Are you, or have you been, a <i>Foreign politically exposed person</i> (Foreign PEP) (in the last 5 years), a <i>Domestic politically exposed person</i> (Domestic PEP) or are you an <i>Head of an international organization</i> (HIO)? Or, are you a <i>Close associate</i> to someone in one of these categories (family member or close associate)?',
    BROKERAGE_FINANCIAL_INTERESET_TITLE: 'Is the account intended for use by or on behalf of a third party (person or entity other than the account holder or the person authorized to give instructions)?',
    BROKERAGE_TRUSTED_PERSON: 'Do you want to designate a trusted contact person in this account?',
    BROKERAGE_PURPOSE: 'What is the intended use of non-registered account?',
    BROKERAGE_PURPOSE_OTHER: 'Please specify',
    BROKERAGE_KNOWLEDGE: 'What is your level of investment knowledge?',
    BROKERAGE_CREDIT_BALANCE_TITLE: 'Credit Balance',
    BROKERAGE_CREDIT_BALANCE: 'Credit Balance',
    BROKERAGE_CREDIT_BALANCE_OPTIONAL: 'Credit Balance (optional)',
    BROKERAGE_DIRECT_DEPOSIT_AUTH: 'I hereby authorize Desjardins Securities to make deposits directly to my bank account.',
    DIRECT_DEPOSIT: 'Direct deposit',
    DIRECT_DEPOSIT_DIRECT_WITHDRAWAL: 'I hereby authorize Desjardins trust Inc. to make:',
    DIRECT_DEPOSIT_YES: 'Yes, attach a personalized void cheque.',
    DIRECT_DEPOSIT_TO_ACCOUNT: 'Direct bank account withdrawal / deposit authorization',
    DIRECT_WITHDRAWAL: 'Direct Withdrawal',

    // legal
    ODC_STEP_LEGAL_SHAREHOLDER_DESC_GPD: 'I acknowledge that I have read and understand the explanations regard the ' +
        '“Regulation concerning communication with beneficial owners of securities of a reporting issuer” included ' +
        'in the Relationship Disclosure Document and Agreements. I acknowledge that the choices indicated below will apply ' +
        'to all securities held in my accounts at Desjardins Investment Management inc. (DIM), unless I indicate otherwise.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_GPD_1: 'to allow DIM to provide my name, mailing address, e-mail address, ' +
        'securities holdings and preferred language of communication to the issuers of said securities or other persons ' +
        'or companies, in accordance with applicable securities legislation.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_1: 'The “Presence” service is a personal telephone ' +
        'assistance service operated by Assistel Inc., an external company to DIM. DIM has an agreement with ' +
        'Assistel Inc. which allows DIM’s clients to benefit from the offer described in the brochure entitled ' +
        '“Presence by Desjardins Private Management” given to the client at the account opening.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_2: 'to allow DIM to disclose the following information to ' +
        'Assistel Inc. for identification purposes: my name,address, telephone number and date of birth. I ' +
        'understand that to benefit from this assistance service, I have to consent to having the aforementioned ' +
        'personal information disclosed by DIM to Assistel Inc.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_3: 'If I do not consent, I understand that I will not be able ' +
        'to benefit from the “Presence” service.',

    SIDEBAR_SHAREHOLDER_TITLE: 'To consult',
    SIDEBAR_SHAREHOLDER_CONVENTION: 'General Account Agreement and Specific Agreements (PDF, 549 KB)',
    SIDEBAR_SHAREHOLDER_CONVENTION_LINK: 'https://secure.disnat.com/xd/en/help/pdf/account_agreements.pdf',
    ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING_TITLE: 'Warning',
    ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING: 'The preferences selected differ from those in the client\'s other accounts.' +
        ' By confirming these preferences, you\'ll be applying them to the client\'s other accounts as well.',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE: 'Communication with beneficial owners of securities',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE_GPD: 'Consent to disclose personal information for the Presence service',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE_Communication_GPD: 'Communication with Beneficial Owners',
    ODC_STEP_LEGAL_SHAREHOLDER_DESC: 'I acknowledge that I have read and understand the explanations regard the “Regulation ' +
        'concerning communication with beneficial owners of securities of a reporting issuer” included in the Relationship ' +
        'Disclosure Document and Agreements. I acknowledge that the choices indicated below will apply to all securities held ' +
        'in my accounts at Desjardins Online Brokerage (DOB), unless I indicate otherwise.',
    ODC_STEP_LEGAL_SHAREHOLDER_DESC_PLEIN_EX: 'I acknowledge that I have read and understand the explanations regard ' +
        'the “Regulation concerning communication with beneficial owners of securities of a reporting issuer” ' +
        'included in the Relationship Disclosure Document and Agreements. I acknowledge that the choices indicated ' +
        'below will apply to all securities held in my accounts at Desjardins Securities Inc. (Desjardins Securities), ' +
        'unless I indicate otherwise.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_LABEL: 'I, the undersigned, hereby',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_QUESTION: 'Part 1 – Disclosure of beneficial ownership information',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC: 'to allow DOB to provide my name, mailing address, e-mail address, securities ' +
        'holdings and preferred language of communication to the issuers of said securities or other persons or companies, in ' +
        'accordance with applicable securities legislation.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_PLEIN_EX: 'to allow Desjardins Securities to provide my name, ' +
        'mailing address, e-mail address, securities holdings and preferred language of communication to the issuers ' +
        'of said securities or other persons or companies, in accordance with applicable securities legislation.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT: 'I consent',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT: 'I do not consent',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT_SHORT: 'consent',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT_SHORT: 'do not consent',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_QUESTION: 'Part 2 - Receipt of documents by security holders',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1: 'I wish to receive <strong>ALL</strong> security holder documents sent to beneficial owners of securities.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2: 'I do not wish to receive <strong>ANY</strong> documents for security holders sent to the beneficial owners of the securities.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3: 'I wish to receive <strong>ONLY</strong> proxy-related documents sent regarding extraordinary meetings.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1_FORMATTED: 'I wish to receive ALL security holder documents sent to beneficial owners of securities.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2_FORMATTED: 'I do not wish to receive ANY documents for security holders sent to the beneficial owners of the securities.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3_FORMATTED: 'I wish to receive ONLY proxy-related documents sent regarding extraordinary meetings.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_DESC: 'Even if I decline to receive these types of materials, I understand that a reporting issuer or other person or company is entitled to send me these materials at its own expense.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_QUESTION: 'Part 3 – Consent to deliver documents electronically.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE1: 'I wish to receive the documents by electronic means.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE2: 'I do not wish to receive the documents by electronic means.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1: 'I wish to receive the documents by electronic means.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE2: 'I do not wish to receive the documents by electronic means.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_DESC: 'Consent to receive the documents by electronic means in accordance ' +
        'with the terms set out in the Consent to deliver documents electronically. I have provided my email ' +
        'address in the Step 2 Account holder information.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_TITLE: 'Email address required',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_DESC: 'You have selected "I wish to receive documents electronically". It appears, however, that you have not provided an email address. Click <strong>Continue</strong> to resume without documents being sent electronically or <strong>Cancel</strong> if you would like to add an email address.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TITLE: 'OPTION ACCOUNT',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER: 'Do you have an option account with another securities dealer?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER_FIRM: 'Firm name',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE: 'Do you have experience trading derivatives?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_YEAR: 'Number of years of experience',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_KNOWLEDGE: 'Do you have a theorical knowledge of options?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_SPECIFY_EXPERIENCE: 'Please specify',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_SELECTION: 'Please select:',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE: 'Type of trades',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE_HELP: 'Options trades, such as selling short options or spreads, require option trading experience. When selecting a type of option trade, please select from above.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_EXPERIENCE: 'I have experience',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_PLANNING: 'I anticipate doing these types of option trades',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1: 'Buys of call and put options',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1_HELP: 'Purchase of an option giving the buyer the right to buy or sell a set number of underlying shares at set a price until expiry.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2: 'Sale of covered options',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2_HELP: 'Sale of a call option while simultaneously holding an equivalent number of shares or options on the underlying security.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3: 'Spreads',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3_HELP: 'Purchase or sale of two or more different option contracts based on strike price and/or expiry date, to benefit from an increase or decrease in price of the underlying security.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4: 'Short uncovered option sales',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4_HELP: 'Sale of call or put options without holding the corresponding amount of the underlying security or equivalent options necessary to satisfy the obligation to purchase or sell.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_TITLE: 'Option Account',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_CONFIRM_MODAL_BODY: 'You must have theoretical knowledge to trade options. Click <b>Continue</b> to remove this type of account from the application or <b>Cancel</b> if you would like to correct your application.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_BODY_TYPES_OF_OPTION_TRADES: 'The type of option trades you anticipate doing implies the following types of option trades:',
    ODC_STEP_LEGAL_USER_OTHER_TRADING_PERSON_TITLE: 'Do other persons have authorization to trade in this account?',
    ODC_STEP_LEGAL_USER_OTHER_GUARANTING_PERSON_TITLE: 'Do other persons guarantee this account?',

    ODC_BENEFICIARY_RRSP_TITLE: 'RRSP account',
    ODC_BENEFICIARY_TFSA_TITLE: 'TFSA account',
    ODC_BENEFICIARY_FHSA_TITLE: 'FHSA account',
    ODC_BENEFICIARY_RESP_TITLE: 'RESP account',
    ODC_BENEFICIARY_RRSP_QUESTION: 'Would you like to name a beneficiary for the proceeds of your plan?',
    ODC_BENEFICIARY_TFSA_QUESTION: 'Would you like to name your spouse as successor holder of your account upon your death?',
    ODC_BENEFICIARY_FHSA_QUESTION: 'Would you like to designate your spouse or common-law partner as successor holder on your death?',
    ODC_BENEFICIARY_FHSA_QUESTION_2: 'Would you like to designate some person(s) as beneficiary(ies) for the benefit payable on your death?',
    ODC_BENEFICIARY_RESP_QUESTION: 'Are all the beneficiaries brothers and sisters ?',
    ODC_BENEFICIARY_RESP_BENEFICIARY_ADDRESS_QUESTION: 'Is the beneficiary address different from that of the subscriber ?',
    ODC_BENEFICIARY_TITLE: 'Beneficiary',
    ODC_SUCCESSOR_TITLE: 'Successor Holder',
    ODC_STEP_BENEFICIARY_PARENT_LINK: 'Parentship',
    ODC_STEP_BENEFICIARY_PART: 'Share (%)',
    ODC_STEP_BENEFICIARY_DELETE: 'Delete this beneficiary',
    ODC_STEP_BENEFICIARY_ADD: 'Add a beneficiary',
    ODC_STEP_PARENT_DELETE: 'Delete a parent',
    ODC_STEP_PARENT_ADD: 'Add a parent',
    ODC_BENEFICIARY_RRSP_SHARE_WITHOUT_BENEFICIARY: 'Please note that if the percentages do not total 100%, the remaining portion will be paid to your estate.',
    ODC_BENEFICIARY_RESP_REASON: 'Reason',
    ODC_BENEFICIARY_SIN: 'S.I.N. (optional)',
    ODC_BENEFICIARY_SIN_PLEINEX: 'S.I.N.',
    ODC_BENEFICIARY_CONTRACT_OPENING_DATE: 'Contract Opening Date',
    ODC_BENEFICIARY_CONTRACT_OPENING_DATE_HELP: 'yyyy-mm-dd',
    ODC_BENEFICIARY_CESG_FOR_RESP: 'Do not request the Additional CESG for this RESP',
    ODC_BENEFICIARY_CLB_FOR_RESP: 'Do not request CLB for this RESP',
    ODC_BENEFICIARY_CUSTODIAL_PARENT_IDENTIFICATION: 'Identification of the custodial parent',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_FIRST_NAME: 'Primary caregiver\'s first name',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_LAST_NAME: 'Primary caregiver\'s last name',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_SIN: 'Primary caregiver\'s SIN',
    ODC_BENEFICIARY_DISTRIBUTION_RATIO: 'Distribution Ratio',

    // transfer choice
    TRANSFER_REQUEST_CHOICE_TITLE: 'Transfer from another financial institution',
    TRANSFER_REQUEST_CHOICE_LABEL: 'Would you like to transfer an investment account from Desjardins or another financial institution?',
    TRANSFER_REQUEST_CHOICE_LIST_LABEL: 'Please check the accounts for which a transfer is needed',
    TRANSFER_REQUEST_CHOICE_COPY_LABEL: 'copy(ies)',
    ACCOUNT_TYPE_LABEL_CASH: 'Cash',
    ACCOUNT_TYPE_LABEL_MARGIN: 'Margin',
    ACCOUNT_TYPE_LABEL_MARGIN_OPTION: 'Margin with options',
    ACCOUNT_TYPE_LABEL_MARGIN_SHORT_SELL: 'Margin with short sell',
    ACCOUNT_TYPE_LABEL_MARGIN_OPTION_SHORT_SELL: 'Margin with options and short sell',
    ACCOUNT_TYPE_LABEL_RRSP: 'RRSP',
    ACCOUNT_TYPE_LABEL_TFSA: 'TFSA',
    ACCOUNT_TYPE_LABEL_FHSA: 'FHSA',
    ACCOUNT_TYPE_LABEL_RESP: 'RESP',
    ACCOUNT_TYPE_LABEL_LIRA: 'LIRA',
    ACCOUNT_TYPE_LABEL_RRIF: 'RRIF',
    ACCOUNT_TYPE_LABEL_LIF: 'LIF',
    ACCOUNT_TYPE_LABEL_FTARRSP: 'Fixed-Term Annuity RRSP',
    ACCOUNT_TYPE_LABEL_JOINT: 'Joint Account',
    ACCOUNT_TYPE_LABEL_ASSOCIATION: 'Other Account (Association)',
    ACCOUNT_TYPE_LABEL_CLUB: 'Other Account (Investment Club)',
    ACCOUNT_TYPE_LABEL_COOP: 'Other Account (Cooperative or Syndicate of co-ownership)',
    ACCOUNT_TYPE_LABEL_CORPO: 'Other Account (Business corporation (Corporation))',
    ACCOUNT_TYPE_LABEL_CO_UNIQUE: 'Other Account (Sole Proprietorship)',
    ACCOUNT_TYPE_LABEL_CURATELLE: 'Other Account (Curatorship (does not apply to Quebec residents))',
    ACCOUNT_TYPE_LABEL_ENF_ES_QUALITES: 'Other Account (Minor child (in trust - without judgment))',
    ACCOUNT_TYPE_LABEL_ENF_REF: 'Other Account (Account Held in the Name of a Minor)',
    ACCOUNT_TYPE_LABEL_FIDUCIE: 'Other Account (Trust)',
    ACCOUNT_TYPE_LABEL_MANDATAIRE: 'Incapable person (Proxy Mandate - Quebec)',
    ACCOUNT_TYPE_LABEL_OSBL: 'Other Account (Non-Profit Organization (NPO), included Foundation)',
    ACCOUNT_TYPE_LABEL_SENC: 'Other Account (General Partnership (G.P.) or Limited Liability Partnership (L.L.P.))',
    ACCOUNT_TYPE_LABEL_SOC_COMM: 'Other Account (Limited Partnership (L.P.))',
    ACCOUNT_TYPE_LABEL_SUCC: 'Other Account (Estate)',
    ACCOUNT_TYPE_LABEL_TUTELLE: 'Other Account (Guardianship)',
    ACCOUNT_TYPE_LABEL_RRI: 'Individual Pension Plan (IPP)',
    ACCOUNT_TYPE_LABEL_ORG_GOUV: 'Other Account (Government Organization)',

    // transfer request
    TRANSFER_REQUEST_TITLE: 'Transfer Request',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_CASH: 'Cash Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_MRGN: 'Margin Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RRSP: 'RRSP Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_TFSA: 'TFSA Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_FHSA: 'FHSA Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RESP: 'RESP Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_LIRA: 'LIRA Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RRIF: 'RRIF Account',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_LIF: 'LIF account',
    TRANSFER_FORM_BANK_TITLE: 'Identification of Delivering Institution',
    TRANSFER_FORM_ADD: 'Add a Delivering Institution',
    TRANSFER_FORM_REMOVE: 'Remove Delivering Institution',
    TRANSFER_FORM_ACCOUNT_TYPE: 'Account type',
    TRANSFER_FORM_BANK_NAME: 'Name of delivering institution',
    TRANSFER_FORM_BANK_RES_FIRST_NAME: 'Resource person\'s first name',
    TRANSFER_FORM_BANK_RES_LAST_NAME: 'Resource person\'s last name',
    TRANSFER_FORM_BANK_PHONE: 'Phone number',
    TRANSFER_FORM_ADDRESS_TITLE: 'Address of delivering institution',
    TRANSFER_FORM_ACCOUNT_TITLE: 'Account at delivering institution',
    TRANSFER_FORM_ACCOUNT_ADD: 'Add a second account at delivering institution',
    TRANSFER_FORM_ACCOUNT_REMOVE: 'Delete account',
    TRANSFER_FORM_REQUEST_TITLE: 'Requested Transfer',
    TRANSFER_FORM_REQUEST_TYPE: 'Type of transfer',
    TRANSFER_FORM_REQUEST_TYPE_TOTAL: 'Total',
    TRANSFER_FORM_REQUEST_TYPE_PARTIAL: 'Partial',
    TRANSFER_FORM_REQUEST_INSTRUCTION: 'Transfer instructions',
    TRANSFER_FORM_REQUEST_INSTRUCTION_CASH: 'In cash',
    TRANSFER_FORM_REQUEST_INSTRUCTION_KIND: 'In kind',
    TRANSFER_FORM_REQUEST_INSTRUCTION_MIXED: 'Mixed',
    TRANSFER_FORM_REQUEST_TRANSFER_CASH: 'Cash amount',
    TRANSFER_FORM_SECURITY_TITLE: 'List of securities to be transferred',
    TRANSFER_FORM_SECURITY_ADD: 'Add security',
    TRANSFER_FORM_SECURITY_DESCRIPTION: 'Description',
    TRANSFER_FORM_SECURITY_SYMBOL: 'Symbol',
    TRANSFER_FORM_SECURITY_QUANTITY: 'Quantity',
    TRANSFER_FORM_SECURITY_TYPE: 'Type',
    TRANSFER_FORM_SECURITY_TYPE_KIND: 'In kind',
    TRANSFER_FORM_SECURITY_TYPE_SELL: 'Sell',
    TRANSFER_FORM_ACCOUNTSTATEMENT: 'Please provide a copy of your most recent account statement to facilitate the transfer process.',
    TRANSFER_FORM_UPLOAD: 'Upload',

    UNSTRUCTURED_ADDRESS_TITLE: 'Unstructured address',
    UNSTRUCTURED_ADDRESS_NOTICE: 'The address found in OSS is invalid because it\'s structured differently from the one in the Canada Post system. To complete the update for your client, please enter it again by filling out all the fields.',

    ACCOUNT_FORM_ACCOUNT_NUMBER: 'Account number at delivering institution',
    ACCOUNT_FORM_ACCOUNT_TYPE: 'Account type',
    ACCOUNT_FORM_ACCOUNT_CURRENCY: 'Account currency',

    CURATORSHIP_RESIDENT_OF_QUEBEC_TITLE: 'Curatorship - Résidents of Quebec',
    CURATORSHIP_RESIDENT_OF_QUEBEC_BODY: 'You selected Quebec as the province of residence. Since November 1, 2022, curatorship accounts can no longer be opened in Quebec. Please change the province of residence or select a tutorship account.',

    ACCOUNT_FORM_ACCOUNT_OPTION_CASH: 'Cash',
    ACCOUNT_FORM_ACCOUNT_OPTION_MARGIN: 'Margin',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_OPT: 'Margin with options',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_SHT_SEL: 'Margin with short selling',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_OPT_SHT: 'Margin with options and short selling',
    ACCOUNT_FORM_ACCOUNT_OPTION_RRSP: 'RRSP',
    ACCOUNT_FORM_ACCOUNT_OPTION_TFSA: 'TFSA',
    ACCOUNT_FORM_ACCOUNT_OPTION_FHSA: 'FHSA',
    ACCOUNT_FORM_ACCOUNT_OPTION_RESP: 'RESP',
    ACCOUNT_FORM_ACCOUNT_OPTION_LIRA: 'LIRA',
    ACCOUNT_FORM_ACCOUNT_OPTION_RRIF: 'RRIF',
    ACCOUNT_FORM_ACCOUNT_OPTION_LIF: 'LIF',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_TITLE: 'Option Account',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_MSG: 'Short selling options requires a margin account with short selling. Select <strong>Continue</strong> to proceed and request a margin account with short selling. Select <strong>Cancel</strong> to withdraw your request to sell short options.',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_SPREADS_MSG: 'Spreads require a margin account with short selling. Select <strong>Continue</strong> to proceed and request a margin account with short selling. Select <strong>Cancel</strong> to withdraw your request.',
    // Agreement
    AGREEMENT_COLLECTION_AND_USE_TITLE: 'Collection, use and disclosure of your personal information',
    AGREEMENT_DISCLOSURE_AND_ACKNOWLEDGEMENT_TITLE: 'Client disclosure and consent',
    AGREEMENT_CONSENT_AND_DELIVERY_ELECTRONIC_DOC_TITLE: 'Consent to deliver documents electronically and conditions of use',
    AGREEMENT_CONSENT_HELP_LABEL: 'Summary',
    SPECIFIC_CONSENT_TITLE: 'Specific consent',

    TRANSMISSION_METHOD_ESIGN: 'Online',
    TRANSMISSION_METHOD_ESIGN_TITLE: 'To use an electronic signature:',
    TRANSMISSION_METHOD_ESIGN_LINE1: 'The client will have to use their own cell phone.',
    TRANSMISSION_METHOD_ESIGN_LINE2: 'They must have access to their mailbox.',
    TRANSMISSION_METHOD_ESIGN_LINE3: 'You will need to attach <strong>a scanned</strong> copy of the client’s ID and void cheque.',
    TRANSMISSION_METHOD_MAIL: 'By mail',
    TRANSMISSION_METHOD_MAIL_CAISSE: 'By mail',
    TRANSMISSION_METHOD_MAIL_TITLE: 'To sign paper documents:',
    TRANSMISSION_METHOD_MAIL_LINE1: 'Original documents must be sent by mail as soon as possible.',
    TRANSMISSION_METHOD_MAIL_LINE2: 'You will need to attach <strong>a photocopy</strong> copy of the client’s ID and void check.',
    TRANSMISSION_MAIL_STEP1: 'Download the account opening kit.',
    TRANSMISSION_MAIL_JOINT_STEP1: 'Click <strong>Continue</strong> to proceed to the next step to receive the account opening kit by email.',
    TRANSMISSION_MAIL_STEP2: 'Please see the first page for the list of documents to include with the forms.',
    TRANSMISSION_MAIL_STEP3: 'Print, complete the forms that are not included in the interactive web form and sign all required fields.',
    TRANSMISSION_MAIL_JOINT_STEP3: 'Print, complete and sign all required fields of each form.',
    TRANSMISSION_MAIL_STEP4: 'Send all completed and signed documents and forms by mail within the next 40 days.',
    TRANSMISSION_MAIL_STEP4_CAISSE: 'Send all completed and signed documents and forms by mail as soon as possible.',
    TRANSMISSION_MAIL_JOINT_STEP4: 'Send all completed and signed documents and forms by mail.',
    TRANSMISSION_WHAT_NEXT: 'what\'s next?',
    TRANSMISSION_MAIL_DOWNLOAD_BTN: 'Print the account opening kit',
    TRANSMISSION_ESIGN_MAIL_DOWNLOAD_BTN: 'Print the account opening kit',
    TRANSMISSION_ESIGN_MAIL_EMAIL_BTN: 'Receive this kit by email',
    TRANSMISSION_MAIL_DOWNLOAD_WEIGHT: '(PDF, 3MB)',
    TRANSMISSION_MAIL_ADDRESS_TITLE: 'Mailing Addresses',
    TRANSMISSION_MAIL_ADDRESS_TITLE_CAISSE: 'Mailing Address',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL1: 'Montreal',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL2: '1170, Peel Street, Suite 105',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL3: 'Montreal, QC H3B 0A9',
    TRANSMISSION_MAIL_ADRESS_QUEBEC1: 'Québec',
    TRANSMISSION_MAIL_ADRESS_QUEBEC2: 'Place de la Cité',
    TRANSMISSION_MAIL_ADRESS_QUEBEC3: '2600, Laurier Blvd, Suite 130',
    TRANSMISSION_MAIL_ADRESS_QUEBEC4: 'Quebec, QC G1V 4T3',

    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL: 'Please provide a scanned copy of the following documents in JPEG, PNG or PDF format:',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_CHECK_SAMPLE_LABEL: 'Sample personal cheque',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL: 'Valid government issued ID with photo',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL_CAISSE: 'Please provide a scanned copy of the following documents in JPEG, PNG or PDF format:',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL_CAISSE: 'Government-issued photo ID (selected in step 8)',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_INSTRUCTIONS: 'To open an account, you must provide a void cheque and 2 different identity' +
        ' documents when you submit your application.<br>' +
        'Only PDF, PNG and JPEG documents are accepted. A certified translation of your documents may also be required. The document must not have been issued by Desjardins.',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE: 'Type of document',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE_INDEX: 'Type of document',
    TRANSMISSION_ATTACHED_DOCUMENT_TYPE: 'Type of attached document',
    TRANSMISSION_ATTACHED_DOCUMENT_GENERIC_LABEL: 'Attached document',
    TRANSMISSION_ATTACHED_DOCUMENT_ID_DOC: 'Identity document',
    TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL: 'Void cheque',
    TRANSMISSION_ATTACHED_DOCUMENT_CHECK_US_LABEL: 'Void US cheque',
    TRANSMISSION_ATTACHED_DOCUMENT_ALL_FILES_LABEL: 'All documents',
    TRANSMISSION_ATTACHED_DOCUMENT_UPLOAD: 'Upload',
    TRANSMISSION_ATTACHED_DOCUMENT_ADD: 'Add a file',
    TRANSMISSION_ATTACHED_DOCUMENT_REMOVE: 'Remove file',
    TRANSMISSION_ATTACHED_DOCUMENT_LABEL: '{{index}} document type',
    TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL: '{{index}} document type (front)',
    TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL: '{{index}} document type (back)',
    TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL_CAISSE: 'Identification document (front)',
    TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL_CAISSE: 'Identification document (back)',

    USER_IDENTITY_TITLE: 'Identity Verification',
    USER_IDENTITY_MEETING: 'Has the client been met in person?',
    USER_IDENTITY_PAPER: 'Identification document',
    USER_IDENTITY_NUMBER: 'Unique ID number (document number)',
    USER_IDENTITY_DATE: 'Expiration date (YYYY-MM-DD)',
    USER_IDENTITY_ISSUE_EXPIRY_DATE: 'Issue or expiry Date, as applicable',
    USER_IDENTITY_PROVINCE: 'Issuing province or territory in Canada',
    USER_IDENTITY_COUNTRY: 'Issuing country',
    USER_IDENTITY_CAISSE_ERROR_MESSAGE: 'In order to continue with this request, the client must be with you. If this is not the case, you can save your work and return to it when you are with the client.',
    USER_IDENTITY_ADVISOR_FAMILY_NAME: 'Last Name of Caisse Advisor',
    USER_IDENTITY_ADVISOR_FIRST_NAME: 'First Name of Caisse Advisor',
    USER_IDENTITY_ADVISOR_BRANCH_NAME: 'Name of caisse',
    USER_IDENTITY_ADVISOR_INSTITUTION: 'Institution',
    USER_IDENTITY_ADVISOR_TRANSIT: 'Transit',
    USER_IDENTITY_ADVISOR_VERIFICATION_DATE: 'Verification date',
    USER_IDENTITY_ADDRESS_DIFFERENT: 'The address on the front of this document is different than the current address.',

    ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL: 'You can sign online, but please send us the following duly signed document(s) by mail.',
    ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL_CAISSE: 'To complete this type of request, additional forms duly signed and sent by mail are necessary. Please sent the following document(s):',
    ADDITIONAL_FORM_INFORMATION_TITLE_JOINT: 'You have successfully accessed the account opening kit.',
    ADDITIONAL_FORM_VD150: 'RESP Application Form – VD250',
    ADDITIONAL_FORM_VD150F: 'RESP Family Plan Application Form – VD250F',
    ADDITIONAL_FORM_SDE0093: 'Canada Education Savings Grant (CESG) and Canada Learning Bond (CLB) – SDE0093_E',
    ADDITIONAL_FORM_SDE0093A: 'Additional Beneficiaries – SDE0093-A_E',
    ADDITIONAL_FORM_SDE0093B: 'Primary caregiver or their spouse and/or custodial parent/legal guardian - SDE0093-B_E',
    ADDITIONAL_FORM_SDE0100A: 'RESP Transfer – Subscriber Information – SDE0100E-A',
    ADDITIONAL_FORM_TP1029_8_IQ: 'Transfer of Quebec Education Savings Incentive – TP1029.8.IQ',
    ADDITIONAL_FORM_RRIF_VD155: 'RRIF Application Form – VD255',
    ADDITIONAL_FORM_LIF_VD155: 'LIF or RLIF Application Form - VD255',
    ADDITIONAL_FORM_VD155FED: 'Addendum Federal – VD155FED',
    ADDITIONAL_FORM_VD155MB: 'Addendum Manitoba – VD255MB',
    ADDITIONAL_FORM_VD155NB: 'Addendum New Brunswick – VD255NB',
    ADDITIONAL_FORM_VD155ONTN: 'Addendum Ontario – VD255ONTN',
    ADDITIONAL_FORM_VD155QC: 'Addendum Quebec – VD255QC',
    ADDITIONAL_FORM_VD117: 'LIRA, RLSP or LRSP Application Form - VD217',
    ADDITIONAL_FORM_VD117CB: 'Addendum Bristish Colombia – VD217BC',
    ADDITIONAL_FORM_VD117FED: 'Addendum Federal – VD217FED',
    ADDITIONAL_FORM_VD117FEDR: 'Addendum Federal Restricted – VD217FEDR',
    ADDITIONAL_FORM_VD117MB: 'Addendum Manitoba – VD217MB',
    ADDITIONAL_FORM_VD117NB: 'Addendum New Brunswick – VD217NB',
    ADDITIONAL_FORM_VD117ONT: 'Addendum Ontario – VD217ONT',
    ADDITIONAL_FORM_VD117QC: 'Addendum Quebec – VD217QC',
    ADDITIONAL_FORM_VD217AB: 'Addendum Alberta – VD217AB',
    ADDITIONAL_FORM_VD217NS: 'Addendum Nova Scotia – VD217NS',
    ADDITIONAL_FORM_VD255AB: 'Addendum Alberta – VD255AB',
    ADDITIONAL_FORM_VD255CB: 'Addendum Bristish Colombia – VD255BC',
    ADDITIONAL_FORM_VD255FEDR: 'Addendum Federal Restricted – VD255FEDR',
    ADDITIONAL_FORM_VD255NS: 'Addendum Nova Scotia – VD255NS',
    ADDITIONAL_FORM_VD170: 'Request for Taxpayer Identification Number and Certification (W-9) - VD270',
    ADDITIONAL_FORM_VD1050: 'Politically Exposed Foreign Persons - VD2050',
    ADDITIONAL_FORM_CF00002027: 'Politically Exposed Foreign Persons - CF-00002-027A',
    ADDITIONAL_FORM_VD1051: 'Third Party Determination - VD2051',
    ADDITIONAL_FORM_VD1061_REER: 'Beneficiary Designation - VD2061 (RRSP)',
    ADDITIONAL_FORM_VD1061_CELI: 'Beneficiary Designation - VD2061 (TFSA)',
    ADDITIONAL_FORM_ABFORM10: 'Addendum Alberta – AB Form 10',
    ADDITIONAL_FORM_BCFORM3: 'Addendum British Columbiav– BC Form 3',
    ADDITIONAL_FORM_FORM9: 'Addendum Nova Scotia – Form 9',
    ADDITIONAL_FORM_D104: 'Power of Attorney - D204',
    ADDITIONAL_FORM_VD104A: 'Trusted contact person - VD204A',
    ADDITIONAL_FORM_VD104: 'Power of Attorney - VD204',

    ESIGN_CONTACT_INFORMATION_TITLE: 'Please provide the necessary contact information for the authentication process. A mobile phone number is required as it will be used to send a validation code via SMS.',
    ESIGN_CONTACT_INFORMATION_USER_PHONE: 'Please make a selection',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER: 'Other',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_SPECIFY_OTHER: 'Please specify',
    ESIGN_GUIDE: 'Documents will be signed in a new tab. Please perform this operation only once.',
    ESIGN_GUIDE2: 'If you would like to sign at a later date, please take note of your identifiers in order to resume your session at the desired time.',
    ESIGN_GUIDE3: 'Thank you for using our online account opening service!',
    ESIGN_MODAL_TITLE: 'Electronic Signature',
    ESIGN_MODAL_PAGE_TITLE: 'Please wait...',
    ESIGN_MODAL_WAITING: 'You are being redirected to the electronic signature secure site.',
    ESIGN_MODAL_BTN_NEXT_STEP: 'Close',
    ESIGN_MODAL_BTN_NEXT_STEP_TOOLTIP: 'Close the window once you have finished signing the documents.',
    ESIGN_MODAL_CONFIRM_CLOSE_BODY: 'The electronic signature of the document has not been completed. Do you really want to quit?',
    ESIGN_UNAVAILABLE: 'Your request has already been electronically signed. No further action is required.',
    ESIGN_CONTACT_INFORMATION_TITLE1_CAISSE: '1-An email will be sent to the client to access the secure site for the authentication process.\n' +
        'The email will be sent to this address: ',
    ESIGN_CONTACT_INFORMATION_TITLE2_CAISSE: '2-A validation code will be sent via SMS to the client\'s cell phone to complete the authentication process.',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_CAISSE: 'Please select a phone number:',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER_CAISSE: 'Other phone number',
    ESIGN_GUIDE_CAISSE: 'If you wish to sign at a later date, please save your work. You can resume your session later.',

    PDF_MODAL_TITLE: 'Account opening kit',
    PDF_MODAL_PAGE_TITLE: 'Please wait...',
    PDF_MODAL_NAV_TAB_FRENCH_LABEL: 'French',
    PDF_MODAL_NAV_TAB_ENGLISH_LABEL: 'English',

    REVIEW_INFORMATION_ACCOUNT_TYPE: 'Information about the account(s)',
    REVIEW_TRANSACTIONAL_PLATFORM: 'Transactional platform',
    REVIEW_PLATFORM: 'Platform type',
    REVIEW_ACCOUNT_TYPE: 'Account type',
    REVIEW_REGISTERED_ACCOUNT: 'Registered account',
    REVIEW_PERSONAL_INFORMATION: 'Personal Information',
    REVIEW_PERSONAL_INFORMATION_OF_THE_CLIENT: 'Account holder information',
    REVIEW_NAME: 'Name',
    REVIEW_DOB: 'Date of birth',
    REVIEW_SIN: 'Social insurance number',
    REVIEW_TELEPHONE: 'Telephone (home)',
    REVIEW_BUSINESS_TELEPHONE: 'Telephone (business)',
    REVIEW_MOBILE_TELEPHONE: 'Telephone (mobile)',
    REVIEW_HOME_ADDRESS: 'Home address',
    REVIEW_MAILING_ADDRESS: 'Mailing address',
    REVIEW_RESIDENTIAL_ADDRESS_FOR_TAX_PURPOSES: 'Place of tax residence',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP: 'Citizenship',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA: 'Canadian',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES: 'U.S.',
    REVIEW_SNOWBIRD: 'Are you a temporary visitor to the United States (Snowbird)?',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1: 'Are you a resident of Canada for tax purposes?',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2: 'Resident of the United States for tax purposes',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3: 'Resident of a another country for tax purposes',
    REVIEW_OCCUPATION: 'Occupation',
    REVIEW_OCCUPATION_AND_EMPLOYER: 'Occupation and employer',
    REVIEW_EMPLOYMENT_STATUS: 'Employment status',
    REVIEW_EMPLOYMENT_SECURITY_DEALERS: 'Are you employed by a securities dealer?',
    REVIEW_EMPLOYER_NAME: 'Employer\'s name',
    REVIEW_EMPLOYMENT_JOB_TITLE: 'Job title',
    REVIEW_EMPLOYMENT_SECTOR_ACTIVITY: 'Sector of activity',
    REVIEW_EMPLOYMENT_SAME_ADDRESS: 'Do you live under the same roof as someone who works for a securities dealer?',
    REVIEW_FIRM_NAME: 'Firm name',
    REVIEW_FIRM_EMPLOYEE_NAME: 'Employee\'s name',
    REVIEW_FIRM_LINK: 'Link with firm',
    REVIEW_MARITAL_STATUS_AND_DESIGNATION: 'Marital Status and Designation',
    REVIEW_SPOUSAL_INFORMATION: 'Spousal information',
    REVIEW_MARITAL_STATUS: 'Marital status',
    REVIEW_BENEFICIARY: 'Beneficiary',
    REVIEW_HAS_BENEFICIARY: 'Would you like to name a beneficiary for the proceeds of your plan?',
    REVIEW_HAS_BENEFICIARY_FHSA: 'Would you like to designate some person(s) as beneficiary(ies) for the benefit payable on your death?',
    REVIEW_SUB_BENEFICIARY: 'Beneficiary',
    REVIEW_SUCCESSOR_BENEFICIARY_TFSA: 'Would you like to name your spouse as successor holder of your account upon your death?',
    REVIEW_SUCCESSOR_BENEFICIARY_FHSA: 'Would you like to designate your spouse or common-law partner as successor holder on your death?',
    REVIEW_SUCCESSOR: 'Successor Holder',
    REVIEW_ATTORNEY: 'Attorney',
    REVIEW_ATTORNEY_CLIENT_NUMBER: 'Client number',
    REVIEW_ATTORNEY_RELATIONSHIP: 'Relationship',
    REVIEW_ADDRESS: 'Address',
    REVIEW_BANK: 'Financial institution',
    REVIEW_TRANSIT_NUMBER: 'Transit number',
    REVIEW_ACCOUNT_NUMBER: 'Account number',
    REVIEW_BANK_TELEPHONE: 'Financial institution\'s telephone',
    REVIEW_ATTORNEY_REPORTING_INSIDER: 'Is the Attorney a reporting insider of a company whose shares are traded on a stock exchange or in over-the-counter markets?',
    REVIEW_COMPANY_NAME: 'Company name',
    REVIEW_STOCK_SYMBOL: 'Stock symbol',
    REVIEW_MARKET: 'Market',
    REVIEW_ATTORNEY_SHAREHOLDER: 'Is the Attorney a significant shareholder of a company whose shares are traded on a stock exchange or in over-the-counter markets?',
    REVIEW_REGULATORY_QUESTIONS: 'Regulatory Questions',
    REVIEW_BROKERAGE_ACCOUNTS: 'Brokerage accounts',
    REVIEW_BROKERAGE_OTHER_ACCOUNTS: 'Do you have other brokerage accounts or do you control the trading in other brokerage accounts?',
    REVIEW_REPORTING_INSIDER: 'Are you a reporting insider?',
    REVIEW_SHAREHOLDER: 'Are you a significant shareholder?',
    REVIEW_EPV: 'Are you a politically exposed foreign person?',
    REVIEW_THIRD_PARTY_USE: 'Is the account intended for use by or on behalf of a third party?',
    REVIEW_BROKERAGE_PURPOSE: 'Intended use of the non-registered account',
    REVIEW_BROKERAGE_KNOWLEDGE: 'Level of investment knowledge',
    REVIEW_COMMUNICATION_BENEFICAL_OWNERS: 'Communication with beneficial owners of securities',
    REVIEW_CONSENTMENT: 'Disclosure of beneficial ownership information',
    REVIEW_RECEIPT_METHOD: 'Receipt of documents by security holders',
    REVIEW_ELECTRONIC_METHOD: 'Consent to deliver documents electronically',
    REVIEW_OPTION_ACCOUNT: 'Option account',
    REVIEW_OPTION_ACCOUNT_ANOTHER_DEALER: 'Do you have an option account with another securities dealer?',
    REVIEW_OPTION_ACCOUNT_EXPERIENCE_DERIVATIVES: 'Do you have experience trading derivatives?',
    REVIEW_YEARS_EXPERIENCE: 'Years of experience',
    REVIEW_OPTION_ACCOUNT_THEORICAL_KNOWLEDGE: 'Do you have a theorical knowledge of options?',
    REVIEW_OPTION_ACCOUNT_EXPERIENCE_TRADE: 'Investing experience',
    REVIEW_OPTION_ACCOUNT_ANTICIPATION_TRADE: 'Type(s) of option trades anticipated',
    REVIEW_FINANCIAL_INFORMATION_TITLE: 'Financial Information',
    REVIEW_FINANCIAL_INFORMATION: 'Financial information',
    REVIEW_ANNUAL_REVENUE: 'Annual revenue from all sources',
    REVIEW_NET_CAPITAL: 'Approximate net capital assets',
    REVIEW_NET_ASSETS: 'Approximate net liquid assets',
    REVIEW_TRANSFER_REQUEST_TITLE: 'Transfer Request',
    REVIEW_TRANSFER_REQUEST_OPT: 'Transfer from another financial institution',
    REVIEW_TRANSFER_REQUEST_FROM_ANOTHER_FINANCIAL_INSTITUTION: 'Would you like to transfer an investment account from Desjardins or another financial institution?',
    REVIEW_TRANSFER_REQUEST: 'Transfer request',
    REVIEW_DELIVERING_BANK: 'Delivering institution\'s name',
    REVIEW_TRANSFER_RESSOURCE_NAME: 'Resource person\'s name',
    REVIEW_DELIVERING_BANK_TELEPHONE: 'Phone number',
    REVIEW_TRANSFER_ADDRESS: 'Delivering institution\'s address',
    REVIEW_ACCOUNT_NUMBER_SEC: 'Second account number',
    REVIEW_ACCOUNT_TYPE_SEC: 'Second account type',
    REVIEW_TRANSFER_TYPE: 'Type of transfer',
    REVIEW_TRANSFER_INSTRUCTION: 'Transfer instructions',
    REVIEW_TRANSFER_CASH_AMOUNT: 'Cash amount',
    REVIEW_TRANSFER_SECURITY: 'Security to be transfered',
    REVIEW_BENEFICIARY_LINK: 'Parentship',
    REVIEW_BENEFICIARY_PART: 'Share',
    REVIEW_ADDING_A_NEW_ACCOUNT: 'New account',
    REVIEW_ADDING_A_NEW_ACCOUNT_TO_AN_EXISTING_USER: 'Adding a new account to an existing user',
    REVIEW_OTHER_REASON_NIF: 'No tax identification number for other reason',
    REVIEW_NIF: 'Tax identification Number',
    REVIEW_PLATFORM_DIRECT: 'Disnat Direct',
    REVIEW_PLATFORM_CLASSIC: 'Disnat Classic',
    REVIEW_JURIDICTION: 'Jurisdiction: ',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1: 'I wish to receive <strong>ALL</strong> security holder documents ',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2: 'I do not wish to receive <strong>ANY</strong> documents',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3: 'I wish to receive <strong>ONLY</strong> proxy-related documents sent regarding extraordinary meetings',
    REVIEW_MARKET_CAN: 'Canadian',
    REVIEW_MARKET_US: 'American',
    REVIEW_RESP_BENEFICIARY: 'beneficiaries',
    REVIEW_RESP_SUBSCRIBER_RESPONSIBLE: 'parental primary caregiver',
    REVIEW_RESP_SUBSCRIBER_NO_RESPONSIBLE: 'Not parental primary caregiver',
    REVIEW_ANNUITANT_PERSONAL: 'Personal',
    REVIEW_ANNUITANT_SPOUSAL: 'Spousal',
    REVIEW_ATTORNEY_QUESTION: 'Would you like to authorize a third party to trade in this account?',
    REVIEW_FROM_LIRA_OR_PENSION: 'Funds come from a LIRA account or a pension plan',
    REVIEW_EMAIL: 'E-Mail',
    REVIEW_ACCOUNT_RRSP: 'RRSP Account',
    REVIEW_ACCOUNT_TFSA: 'TFSA Account',
    REVIEW_ACCOUNT_FHSA: 'FHSA Account',
    REVIEW_ACCOUNT_STATEMENT: 'Account statement',
    REVIEW_BENEFICIARY_MODAL_TITLE: 'Canada Learning Bond (CLB) Request',
    REVIEW_BENEFICIARY_MODAL_BODY: 'A limitation in our system prevents us from requesting the CLB for one or more beneficiaries since the box is checked for <strong>Do not request the Additional CESG for this RESP</strong>. Click <strong>Continue</strong> to request the Additional CESG or <strong>Cancel</strong> if you no longer wish to request the CLB.',
    REVIEW_BENEFICIARY_INFO_ADDITIONAL_CESG_CLB: 'The Additional CESG and CLB are only paid into a family RESP where all beneficiaries are from the same family.',
    SPECIFY_EXPERIENCE: 'Please specify',
    REVIEW_PREVIOUS_VALUES: 'Before',
    REVIEW_NEW_VALUES: 'After',
    REVIEW_MISSING_DATA: 'Lost or missing data',
    REVIEW_SPECIFIC_CONSENT_OPT: 'Specific consent',
    REVIEW_SPECIFIC_CONSENT: 'Authorization from the client to share their personal information for the 360° service offer :',

    ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE: 'Account Type Related Questions',
    ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACT_PEOPLE: 'How many people ?',

    ACCOUNT_TYPE_RELATED_QUESTIONS_RES_QC: 'Are you a resident of the province of Quebec?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SURVIVORSHIP: 'Right of Survivorship',
    ACCOUNT_TYPE_RELATED_QUESTIONS_WITH_SURVIVORSHIP: 'With right of survivorship (5.2 Section Initialed)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_WITHOUT_SURVIVORSHIP: 'Without right of survivorship (5.1 Section Initialed)',

    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_PERSONAL: 'Yes : Personal account',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_JOINT: 'Yes : Joint account',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_NONE: 'No account',

    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_ONE_SHAREHOLDER: 'One Shareholder',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_MANY_SHAREHOLDERS: 'Many Shareholders',

    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE_COMPLEX: 'Complex',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE_GRANTOR_SIMPLE: 'Grantor/Simple',

    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_QC: 'Quebec',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_ON: 'Ontario',

    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_TITLE: 'Among the associates, how many of :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACT_AMERICAN_PEOPLE: 'American individual (U.S. citizen or resident)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACT_PEOPLE: 'Other than American individuals&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_HOLDING_ACCOUNT: 'Does one of the members who will place trades for the account of the association hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_TITLE: 'Among the members, how many of :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACT_AMERICAN_PEOPLE: 'American individual (U.S. citizen or resident)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACT_PEOPLE: 'Other than American individuals',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_HOLDING_ACCOUNT: 'Do any of the club members hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_HOLDING_ACCOUNT: 'Does one of the members who will place trades for the account of the cooperative hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ONE_SHAREHOLDER: 'Does the corporation have a single shareholder or several shareholders?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_HOLDING_ACCOUNT: 'Does one of the shareholders who will place trades for the account of the corporation hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_ACT_PEOPLE: 'How many administrators are there?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_HOLDING_ACCOUNT: 'Do any of the owners hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ENFESQUALITES_ADD_SPOUSE: 'Would you like to add your spouse to the account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ENFREF_ADD_SPOUSE: 'Would you like to add your spouse to the account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_AMERICAN_PEOPLE: 'American individual (U.S. citizen or resident)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_GRANTOR_TITLE: 'Among the beneficiaries and settlors (grantors), how many of :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE: 'What type of trust is it?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_PEOPLE: 'Other than American individuals',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_IS_THERE_AMERICAN_RESIDENT: 'Among the trustees, is there an american resident ?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_REGISTRATION_ACCOUNT: 'IPP Registration Number (Investment Account)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_TRADE_AUTHORIZED_PEOPLE: 'Number of persons authorized to trade',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_HOLDING_ACCOUNT: 'Does one of the administrators who will place trades for the account of the NPO hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_TITLE: 'Among the associates, how many of :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACT_AMERICAN_PEOPLE: 'American individual (U.S. citizen or resident)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACT_PEOPLE: 'Other than American individuals',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_LEG_ACT_PEOPLE: 'Number of legal persons',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_HOLDING_ACCOUNT: 'Does one of the associates who will place trades for the account of the company hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_TITLE: 'Among the associates (general partner and limited partners), how many of :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_ACT_AMERICAN_PEOPLE: 'American individual (U.S. citizen or resident)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_ACT_PEOPLE: 'Other than American individuals',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_LEG_ACT_PEOPLE: 'Number of legal persons',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_HOLDING_ACCOUNT: 'Does one of the associates who will place trades for the account of the company hold a personal or joint account with Desjardins Online Brokerage?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_LINKED_ACCOUNT: 'Would you like to add a personnal or joint account?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE: 'In which province did the deceased reside?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_VALUE_GREATER_THAN_LIMIT: 'Is the value of the account greater than $50,000?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_WILL: 'Did the deceased leave a will or a marriage contract?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACT_PEOPLE: 'How many executors are there for the estate?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_IS_THERE_AMERICAN_RESIDENT: 'Among the executors, is there an american resident ?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_GOUV_ORG_IS_IT_A_CITY: 'Is it a city ?',


    ACCOUNT_TYPE_ENTITIES_QUESTIONS_TITLE: 'Entities',
    ENTITY_TYPE: 'Entity Type',
    ENTITY_NAME: 'Entity Name',
    AMERICAN_INDIVIDUALS: 'American individuals (U.S. citizen or resident)',
    OTHER_THAN_AMERICAN_INDIVIDUALS: 'Other than American individuals',
    AMERICAN_INDIVIDUALS_TABLE: 'American',
    OTHER_THAN_AMERICAN_INDIVIDUALS_TABLE: 'Other',
    AMERICAN_RESIDENT: 'American Resident',
    AMERICAN_RESIDENT_FIDUCIE_C: 'Among the trustees, is there an american resident ?',
    AMERICAN_RESIDENT_SUCC: 'Among the liquidators, is there an american resident ?',
    AMERICAN_RESIDENT_CORPO_OSBL_RRI: 'Is there an american resident ?',
    // Entity Type
    ENTITY_TYPE_ASSOCIATION: 'Association',
    ENTITY_TYPE_SOC_COMM: 'Limited Partnership (L.P.)',
    ENTITY_TYPE_SENC: 'General Partnership (G.P.) or Limited Liability Partnership (L.L.P.)',
    ENTITY_TYPE_FIDUCIE_S: 'Grantor/Simple Trust',
    ENTITY_TYPE_FIDUCIE_C: 'Complex Trust',
    ENTITY_TYPE_CORPO: 'Business corporation (Corporation)',
    ENTITY_TYPE_OSBL: 'Non-Profit Organization (NPO), included Foundation',
    ENTITY_TYPE_SUCC: 'Estate',
    ENTITY_TYPE_RRI: 'Individual Pension Plan (IPP)',

    BTN_OPEN_ADD_ENTITY: 'Add entity',
    BTN_OPEN_DELETE_ENTITY: 'Delete entity',
    BTN_OPEN_ADD_SUB_ENTITY: 'Add sub-entity',
    BTN_OPEN_DELETE_SUB_ENTITY: 'Delete sub-entity',

    ENTITY_MSG_DISNAT_CONTACT_CUSTOMER_SERVICE: 'If you need to identify more people or entities holding the client’s control, please contact Customer Service.',
    ENTITY_MSG_PLEINEX_CONTACT_DISTRIBUTOR_SERVICE: 'If you need to identify more people or entities holding the client’s control, please contact Distributor Service.',

    CONFIRMATION_TITLE: 'Confirmation',
    CONFIRMATION_TEXT_ESIGN: 'You have successfully completed your request to open an account. You will receive your access code by email within the next 2 working days.',
    CONFIRMATION_TEXT_ESIGN_MAIL: 'You have successfully completed your request to open an account. Please send us all additional documents, duly completed and signed, within 40 days.',
    CONFIRMATION_TEXT_MAIL: 'Your account opening kit is now complete. Please see the first page for a list of documents to provide and instructions to help you complete them.',
    CONFIRMATION_TEXT_TRANSFER: 'Securities or funds being transferred from a financial institution other than Desjardins may take up to two weeks to become available in your online brokerage account.',

    CONFIRMATION_FUND_TITLE: 'Fund your account',
    CONFIRMATION_FUND_AS_FOLLOW: 'Once your account is open you can fund it as follows:',
    CONFIRMATION_FUND_OPTION1: 'If you are a Desjardins member:',
    CONFIRMATION_FUND_OPTION1_TEXT: 'In AccèsD, use the "Transfer" function and choose "Transfers between accounts".',
    CONFIRMATION_FUND_OPTION2: 'If you are not a Desjardins member:',
    CONFIRMATION_FUND_OPTION2_TEXT1: 'Use your financial institution’s bill payment feature and add Desjardins Securities to your payee list.',
    CONFIRMATION_FUND_OPTION2_TEXT2: 'Enter your 7-digit account number in the Account number field.',
    CONFIRMATION_FUND_OPTION2_TEXT3: 'Next, create a new bill payee for each account or plan you want to make transfers to.',

    CONFIRMATION_DISNAT_MOBILE1: 'Make trades from anywhere with the disnat app!',
    CONFIRMATION_DISNAT_MOBILE2: 'Learn more about Disnat Mobile',
    CONFIRMATION_DISNAT_MOBILE_LINK: 'https://www.disnat.com/en/benefits/mobile-service',

    CONFIRMATION_EMAIL_SUBSCRIPTION1: 'Email Subscriptions',
    CONFIRMATION_EMAIL_SUBSCRIPTION2: 'Learn more',
    CONFIRMATION_EMAIL_SUBSCRIPTION_LINK: 'https://www.disnat.com/en/learning/subscription',
    CONFIRMATION_CAISSE_VIDEO_DEMONSTRATION_LINK: 'https://web.microsoftstream.com/video/70c729c9-dfb2-4820-99b0-9c30a0470e77',

    PROFIL_ENTER_A_PASSWORD: 'You need a password to save your account application. This step is required to submit your application.',
    PROFIL_TMP_PASSWORD_ALREADY_A_PROFIL: 'Please enter a new password and confirm it.',
    PROFIL_PASSWORD_AUTHENTICATE_PASSWORD: 'Password',
    PROFIL_PASSWORD_FORGOT_LINK: 'Forgot your password?',
    PROFIL_OLD_PASSWORD: 'Temporary password',
    PROFIL_NEW_PASSWORD: 'New password',
    PROFIL_CONFIRM_PASSWORD: 'Confirm your new password',
    PROFIL_SECURITY_QUESTION_TITLE: 'Please choose a security question.',
    PROFIL_SECURITY_QUESTION: 'Security question',
    PROFIL_SECURITY_ANSWER: 'Answer',
    PROFIL_REGISTRATION: 'To create your profile, please select a security question and enter a password.',
    PROFIL_SERVICE_CLIENTELE: 'Please contact our client service department.',
    PROFIL_QUESTION_SERVICE_CLIENTELE: 'You cannot reset your password online. Please contact our client service department.',
    PROFIL_PASSWORD_EMAIL_SEND: 'If you already have a profile, an email will be sent to the following email address : ',
    PROFIL_CREATE_LINK: 'Don\'t have a password?',
    PROFIL_CREATE_EMAIL_SEND: 'An email will be sent to the following email address : ',
    PROFIL_CREATE_EMAIL_SENT: 'An email has been sent to the following email address : ',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_TITLE: 'Personal Information',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LANGUAGE: 'Language of correspondence',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FIRST_NAME: 'Contact First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LAST_NAME: 'Contact Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_SOLE_PROPRIETOR_SIN: 'Contact SIN',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_EMAIL: 'E-Mail',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_NAME: 'Company Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PRIMARY_PHONE: 'Telephone (home)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_OTHER_PHONE: 'Telephone (business)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MOBILE_PHONE: 'Telephone (mobile)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_ADDRESS_TITLE: 'Company Address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_ADDRESS_TITLE: 'Address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_ADDRESS_TITLE: 'Parent Address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CONTACT_ADDRESS_TITLE: 'Contact address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_ADDRESS_TITLE: 'Guardian Address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_ADDRESS_TITLE: 'Curator Address',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MAIL_ADDRESS_TITLE: 'Mailing Address',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER: 'Federal Business Number (BN)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCIAL_BUSINESS_NUMBER: 'Provincial Business Number',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCE: 'Province or territory',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER_HELP: '<h2></h2>\n' +
        '    <div>A CRA program account number has three parts:</div>\n' +
        '    <ol>\n' +
        '        <li>the 9-digit <strong>business number</strong> to identify the business</li>\n' +
        '        <li>a 2-letter <strong>program identifier</strong> code to identify the program account</li>\n' +
        '        <li>a 4-digit <strong>reference number</strong> to identify an individual program account (since businesses can have more than one of the same kind)</li>\n' +
        '    </ol>',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FISCAL_PERIOD_END_DATE: 'Fiscal period end date',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_FIRST_NAME: 'Deceased First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_LAST_NAME: 'Deceased Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_FIRST_NAME: 'Parent First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_LAST_NAME: 'Parent Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_DOB: 'Parent Date of Birth',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_SIN: 'Parent SIN',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_FIRST_NAME: 'Child First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_LAST_NAME: 'Child Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_DOB: 'Child Date of Birth',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_SIN: 'Child SIN',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_FIRST_NAME: 'Client Under Guardianship First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_LAST_NAME: 'Client Under Guardianship Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_FIRST_NAME: 'Client Under Curatorship First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_LAST_NAME: 'Client Under Curatorship Last Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_FIRST_NAME: 'Unfit Client First Name',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_LAST_NAME: 'Unfit Client Last Name',

    COUNTRY_ADDRESS_CAN: 'Canada',
    COUNTRY_ADDRESS_USA: 'United States',

    CURRENT_MODE: 'Current Mode:',
    LIST_MODE_PRINT: 'Forms Printing',
    LIST_MODE_AUTO: 'Automated Opening',
    LIST_MODE_UPD_AUTO: 'Automated Update',

    OTHER_ACCOUNT_ONLY_CANADIAN_ENTITIES: 'Only account opening for Canadian entities is available.',

    OSS_QUERY_CLIENT_CODE_LABEL: 'Client Code',
    OSS_QUERY_LANGUAGE_LABEL: 'Language',

    NO_REQUESTS_FOUND: 'No requests match the selected criteria.',
    MORE_THAN_500_REQUESTS: 'There are more than 500 results.  Please refine your search and try again.',
    BRANCH_LATEST_REQUESTS: 'Requests of the last 40 days are displayed.',
    BRANCH_ALL_REQUESTS: 'Requests of the last 3 years are displayed.',
    BRANCH_ADDITIONAL_CRITERIA: 'Additional Criteria',

    BRANCH_LIST_REQUESTS_FIELD_CREATION: 'Created on',
    BRANCH_LIST_REQUESTS_FIELD_REQUEST_NUMBER: 'No.',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_TYPE: 'Type',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_NAME: 'Client Name',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_NUMBER: 'Client No.',
    BRANCH_LIST_REQUESTS_FIELD_REQUEST_STATUS: 'Status',
    BRANCH_LIST_REQUESTS_FIELD_TRANSMISSION_MTHD: 'Transmission',
    BRANCH_LIST_REQUESTS_FIELD_ADVISOR_CODE: 'Advisor',
    BRANCH_LIST_REQUESTS_MODIFY_BTN: 'Modify',
    BRANCH_LIST_REQUESTS_PRINT_BTN: 'Print',

    BRANCH_SEARCH_FIELD_CLIENT_NAME_LABEL: 'Client Name',

    BRANCH_SEARCH_FIELD_TYPE_REQUESTS_LABEL: 'Requests',
    BRANCH_SEARCH_OPTION_TRANSIT_REQUESTS_LABEL: 'From Transit',
    BRANCH_SEARCH_OPTION_ADVISOR_REQUESTS_LABEL: 'My Requests',

    BRANCH_SEARCH_FIELD_DATE_REQUESTS_LABEL: 'Period',
    BRANCH_SEARCH_OPTION_LATEST_REQUESTS_LABEL: 'Last 40 Days',
    BRANCH_SEARCH_OPTION_ALL_REQUESTS_LABEL: 'All Requests',

    CAISSE_INPROGRESS: 'In Progress',
    CAISSE_CANCELLED: 'Cancelled',
    CAISSE_COMPLETE: 'Completed',
    CAISSE_FINALIZED: 'Finalized',
    CAISSE_SAVED: 'Saved',
    CAISSE_TRANSMIT: 'Transmitted',
    CAISSE_DECLINED: 'Declined',
    CAISSE_MANUAL: 'Manual Processing',

    ESIGN: 'Electronic Signature',
    ESIGN_MAIL: 'Electronic Signature and Mail',
    MAIL: 'Mail',
    PRINT: 'Print',

    INDIVIDU: 'Individual',
    CONJOINT: 'Joint',
    ASSOCIATION: 'Association',
    COOP: 'Cooperative or Syndicate of co-ownership',
    ENF_REF: 'Account Held in the Name of a Minor',
    CORPO: 'Business corporation (Corporation)',
    CURATELLE: 'Curatorship (does not apply to Quebec residents)',
    SUCC: 'Estate',
    ORG_GOUV: 'Government Organization',
    TUTELLE: 'Guardianship',
    ENF_ES_QUALITES: 'Minor child (in trust - without judgment)',
    RRI: 'Individual Pension Plan (IPP)',
    CLUB: 'Investment Club',
    SOC_COMM: 'Limited Partnership (L.P.)',
    OSBL: 'Non-Profit Organization (NPO), included Foundation',
    SENC: 'General Partnership (G.P.) or Limited Liability Partnership (L.L.P.)',
    MANDATAIRE: 'Incapable person (Proxy Mandate - Quebec)',
    CO_UNIQUE: 'Sole Proprietorship',
    FIDUCIE: 'Trust',

    BRANCH_RECENT_REQUESTS: 'Requests of the last 40 days are displayed.',

    BRANCH_SEARCH_FIELD_ADVISOR_CODE_LABEL: 'Advisor Code',
    BRANCH_SEARCH_FIELD_STATUS_LABEL: 'Status',

    PERSONAL_INFORMATION_FAILED_MODAL_TITLE: 'Retrieve client information',
    PERSONAL_INFORMATION_FAILED_MODAL_BODY: 'It is not possible to retrieve the client information.',

    PERSONAL_INFORMATION_UPDATE_FORM_MODAL_TITLE: 'Update client information',
    PERSONAL_INFORMATION_UPDATE_FORM_MODAL_BODY: 'The questionnaire has been updated with the new client information.',

    MODAL_AUTOSAVE_ERROR_TITLE: 'Save could not be completed',
    MODAL_AUTOSAVE_ERROR_BODY: 'An account opening request is currently being modified in your browser, but save could not be completed. Please click <b>Cancel</b> to access this request, or <b>Continue</b> to ignore it and proceed.',

    CAISSE_LIST_ACCOUNTS: 'Accounts',

    MOST_RECENT_FORM_MODAL_TITLE: 'Date of the most recent form',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_1: 'Date of receipt of the last account opening or update:',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_1_BEFORE: 'Date of receipt of the last account opening or update:',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_2: 'According to the 31-103 Regulation, you must complete the account opening form and the investor profile’s questionnaire (VD201P) and have them signed by your client.',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_2_BEFORE: 'Because of the stricter KYC and update requirements that have been in effect since January 1, 2022, you must complete the account opening form and the Investor Profile Questionnaire (VD201P), and have your client sign them.',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_3: 'Please follow each step of this questionnaire',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_3_BEFORE: 'Then follow each step of the web questionnaire.',
    NO_AS400_DATE: 'The date could not be retrieved in AS/400.',

    MOST_RECENT_FORM_MODAL_PARAGRAPH_AFTER: 'If no changes are made in the update of the account opening form, it will be possible to submit the request without the client’s signature. However, if changes are made in the update of the account opening form, the form must be signed by the client.',

    /* Account add */
    ADD_ACCOUNT_CLIENT_TITLE: 'Client:',
    ADD_ACCOUNT_MESSAGE_TITLE: 'Important:',
    ADD_ACCOUNT_MESSAGE_0: 'You will need to add a V201 form if any of the following applies:',
    ADD_ACCOUNT_MESSAGE_1: 'A major change has occurred in the client\'s file.',
    ADD_ACCOUNT_MESSAGE_2: 'The client opens a registered account for the first time.',
    ADD_ACCOUNT_MESSAGE_3: 'An update to the file is required.',
    ADD_ACCOUNT_REGISTERED_ACCOUNT_CHOICE_TITLE: 'Add Registered Account',
    ADD_ACCOUNT_CURRENCY_MODAL_TITLE: 'Adding a non-registered account',
    ADD_ACCOUNT_CURRENCY_MODAL_BODY_TO_USD: 'To allow you to open registered accounts in Canadian and American dollars, you must hold non- registered accounts in the same currency. Therefore, an unregistered account was automatically added.',
    ADD_ACCOUNT_IRS_EXP_DATE_MSG: 'To open the TFSA, you must provide a readable copy of a valid government-issued photo ID.',

    /* Help warning messages */
    INFORMATION_FETCHED_FROM_CUSTOMER: 'The information in this section must have been obtained by the advisor\u00B9 from the client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE: 'The information in this section must have been obtained by the advisor from the client. This section must be completed <u>after</u> obtaining the client\'s signature on the form.',
    INFORMATION_FETCHED_FROM_CUSTOMER_GPD: 'The information in this section must have been obtained by the private wealth manager from the client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE_GPD: 'The information in this section must have been obtained by the private wealth manager from the client. This section must be completed <u>after</u> obtaining the client\'s signature on the form.',
    INFORMATION_FETCHED_FROM_CUSTOMER_SFD: 'The information in this section must have been obtained by the private wealth manager from the client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE_SFD: 'The information in this section must have been obtained by the private wealth manager from the client. This section must be completed <u>after</u> obtaining the client\'s signature on the form.',

    TRANSFER_REQUEST_CHOICE_MODAL_TITLE: 'Important notice regarding requests for mutual funds transfers',
    TRANSFER_REQUEST_CHOICE_CHOICE_MESSAGE: 'Since June 1, 2022, new rules from the Canadian Securities Administrators will ban investment fund managers from paying trailing commissions to discount brokers who don\'t provide advisory services, like Desjardins Online Brokerage.\n' +
        '<br/><br/>' +
        'As a result of this regulatory change, mutual funds with trailing commissions can no longer be held in or transferred to non-advisory accounts.\n' +
        '<br/><br/>' +
        'Upon reception of a transfer form, a switch will be made automatically to a like-to-like or like-to-similar mutual funds class or series with no trailing commissions, without further notice.\n' +
        '<br/><br/>' +
        'If no like-to-like or like-to-similar mutual funds classes or series is available, a representative from Desjardins Online Brokerage will contact you to review your options.\n' +
        '<br/><br/>' +
        'The following link gives you detailed information regarding any trailing commissions that Desjardins Online Brokerage could receive between the transfer request and the switch to a category or series of mutual funds with no trailing commission.\n' +
        '<br/><br/>' +
        '<a href="https://www.disnat.com/en/platforms-and-fees/investment-types" class="lien-externe" target="_blank">Platforms and Fees - Investment Types | Desjardins Online Brokerage (disnat.com)',

    ODC_SEARCH_CLIENT_INFORMATION_OSS_ERROR: 'An error occurred while retrieving client information. Please try again later.',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX: 'Client signature is required to update the account information. To continue:',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_1: '1. Print the forms and send them to the client to sign;',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_2: '2. Have the advisor and branch supervisor approve the signed documents;',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_3: '3. Go back to the request, to the last section of the online form to transfer the information to the systems.',

    // SUBMISSION
    SUBMITTED_LABEL: 'The request has been submitted for automated opening.  Please refer to Account Opening desk in SmartD to manage it.',
    SUBMIT_CONFIRM: 'I confirm that I have communicated, reviewed and updated with the client all the information required on the account opening form. ' +
        'Since the last time the client’s file was updated, there have not been any significant changes affecting the client’s personal or financial situation, ' +
        'investment needs and objectives, or risk profile, or potentially having a significant impact on the client’s net worth or income. ' +
        'I have also assessed the suitability of the client\'s investments.',
    SUBMIT_PLEIN_EX_CONFIRM: 'I confirm that I checked the note in the Croesus / CRM core business system during the meeting between the advisor and the client. This note confirms that the advisor communicated, reviewed and updated all the information on the account opening documents with the client. It is noted that since the last update of the client file, there have been no significant changes that have affected the client\'s personal or financial situation, investment needs and objectives, risk profile or that would significantly affect the client\'s net value or income. The advisor confirms that they have conducted a suitability analysis of the client\'s investments.',
    SUBMITTED_UPDATE_LABEL_WITH_NAME: 'The client file update request was submitted on {{date}} by {{name}}. You can track its status on the Smart D Account Opening dashboard.',
    SUBMITTED_UPDATE_LABEL: 'The client file update request was submitted. You can track its status on the Smart D Account Opening dashboard.',
    ODC_STEP_SUBMISSION_TITLE: 'Name of advisor who updated the client\'s account',
    ODC_STEP_SUBMISSION_MRS_ADVISOR: 'Ms.',
    ODC_STEP_SUBMISSION_MR_ADVISOR: 'Mr.',
    ODC_STEP_SUBMISSION_TITLE_ADVISOR: 'Title',
    ODC_STEP_SUBMISSION_FIRST_NAME_ADVISOR: 'First Name',
    ODC_STEP_SUBMISSION_LAST_NAME_ADVISOR: 'Last Name',
    ODC_STEP_SUBMISSION_DATE_MEETING_ADVISOR: 'Date of meeting with the client',

    NO_CHANGES_DETECTED: 'No changes were found in your update request.',

    ENGLISH_QUEBEC_RESIDENTS_MODAL_TITLE: 'English-speaking residents of Quebec',
    ENGLISH_QUEBEC_RESIDENTS_MODAL_MESSAGE: 'In accordance with the requirements of Bill 96, adopted on May 24, 2022, you will receive documentation in both French and English, since you\'ve indicated that you are a resident of Quebec whose preferred language of correspondence is English.',

    SPECIFIC_CONSENT_COMPARISON: 'Authorization from the client to share their personal information for the 360° service offer.',

    PASSWORD_LENGTH_STRENGTH_WEAK: 'Weak',
    PASSWORD_LENGTH_STRENGTH_MEDIUM: 'Medium',
    PASSWORD_LENGTH_STRENGTH_GOOD: 'Good',
    PASSWORD_LENGTH_STRENGTH_STRONG: 'Strong',

    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_MIN_8: 'Minimum 8 characters',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_UPPERCASE_LETTER: 'One uppercase letter',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_LOWERCASE_LETTER: 'One lowercase letter',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_SPECIAL_CHARACTER: 'One special character',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_DIGIT: 'One digit',

    ODC_COMPARISON_FATAL_ERROR: 'We can\'t see the summary of changes because of information that\'s not standard in our core business systems.',

    ODC_MODAL_CONNEXION_TITLE: 'Save your application',
    ODC_MODAL_CONNEXION_CREATE_PASSWORD_TITLE: 'Create a password',
    ODC_MODAL_CONNEXION_CREATE_PASSWORD_DESC: 'Select a security question and enter an answer. A temporary password will then be sent to <b' +
        ' class="email">{{email}}<b/>',
    ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_TITLE: 'Already have a password?',
    ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_DESC: 'Use your password to save a new account application.',
    ODC_MODAL_CONNEXION_NOTE: '<b>Note</b>: This password is only used to open your online account. It\'s not the same as the one you use to log in' +
        ' to the Disnat platform.',
    ODC_MODAL_CONNEXION_ENTER_PASSWORD_LINK: 'Enter your password',

    ODC_MODAL_CONNEXION_TEMP_PASSWORD_TITLE: 'Enter temporary password',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_DESC: 'A temporary password has been sent to <b class="email">{{email}}</b>. You have 10&nbsp;minutes to' +
        ' enter the password.',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_CHECK_SPAM_DESC: 'If you didn\'t receive a password by email, you can request a new one. Be sure to check' +
        ' your junk mail folder.',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_LINK: 'Get new email',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_ALERT_DESC: 'A new email has been sent.',

    ODC_MODAL_CONNEXION_AUTHENTICATION_TITLE: 'Enter your password',
    ODC_MODAL_CONNEXION_AUTHENTICATION_DESC: 'Enter your password to save your account application.',

    ODC_MODAL_DEFINE_NEW_PASSWORD_CONFIRMATION_BUTTON_LABEL: 'Save application',

    ODC_MODAL_CREATE_PROFILE_NEW_PASSWORD_TITLE: 'Create a new password',

    ODC_MODAL_SAVED_APPLICATION_BODY: 'Your application has been saved.',
    ODC_MODAL_CONNEXION_REGISTER_DESC: 'If you do not have an account, click on "Register".',

    ODC_MODAL_CHANGING_SIGNIFICANT_FIELD: 'Are you sure you want to change the "{{field}}" field? Click <strong>Continue</strong> to proceed or <strong>Cancel</strong> to remove the entry.',
    ODC_MODAL_CHANGING_SIGNIFICANT_FIELD_TITLE: 'Changing a significant field',

    /* Help Transfer request*/
    HELP_TRANSFER_REQUEST_TITLE: 'Investment account',
    HELP_TRANSFER_REQUEST_PARAGRAPH: 'Investment accounts include accounts holding securities and registered accounts, and exclude chequing and Everyday transaction accounts. Money transfers from your caisse Desjardins’ Everyday transaction account are done using the <b>Transfers between accounts</b> function on AccèsD.',

    ODC_MODAL_FORGOT_PASSWORD_TITLE: 'Get a new password',
    ODC_MODAL_FORGOT_PASSWORD_DESC: 'Want to reset the password linked to <b class="email">{{email}}</b>?',
    ODC_MODAL_FORGOT_PASSWORD_CONFIRMATION_BUTTON_LABEL: 'Confirm',
    ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_DESC: 'There\'s no application linked to <b class="email">{{email}}</b>.<br>Create a new password to continue.',
    ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_CONFIRMATION_BUTTON_LABEL: 'Create password',

    FORGOT_PASSWORD_EMAIL_SENT: 'A password reset link has been sent to <b class="email">{{email}}</b>.',

    ODC_MODAL_SAVED_APPLICATION_CONFIRM_BUTTON_LABEL: 'Close',

    SIGNIFICANT_CHANGE: 'Significant change',

    INVALIDATED_SESSION_TITLE: 'Session Expired',
    INVALIDATED_SESSION_DESCRIPTION: 'Your session has expired due to prolonged iniactivity.<br>Please start your account opening request again.',

    HELP_WARNING_FOOTNOTE: '\u00B9The term advisor refers to all categories of advisors registered with the OCRI, including the Registered Representative, the Portfolio Manager, and the Assistant Portfolio Manager'
};
