<h1>
    <span>{{ 'ODC_FORGOT_PASSWORD_PAGE_TITLE' | translate }}</span>
</h1>

<div class="row">

    <div class="col-md-16">
        <div class="panel panel-simple">
            <div id="questionnaire-content" class="panel-body" tabindex="0">

                <div *ngIf="emailSent" [innerHTML]="'FORGOT_PASSWORD_EMAIL_SENT' | translate: {email: form.get('email').value}"></div>

                <form [formGroup]="form" ngForm novalidate (submit)="onSubmit()" [class.hidden]="emailSent">
                    <div *ngIf="!emailSent">

                        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>
                        <app-http-error [errors]="httpErrors"></app-http-error>

                        <p>{{'PROFIL_PASSWORD_EMAIL_SEND' | translate}}</p>
                        <app-odc-field
                            *ngIf="form.get('email').enabled"
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_FORGOT_PASSWORD_EMAIL' | translate"
                            [id]="'email'"
                            [name]="'email'"
                            [path]="'email'"
                            [binding]="null"
                            [attr]="{maxlength: 64}"
                        ></app-odc-field>

                        <br>
                        <div *ngIf="showRecaptcha()">
                            <app-recaptcha [langChange]="translate.onLangChange"
                                           [lang]="translate.currentLang" [containerId]="'recaptcha-reset-password'"></app-recaptcha>
                        </div>

                        <div class="btn-resp btn-resp-1colonne-inline m-t-4">
                            <div class="btn-resp-col btn-resp-demi">
                                <button type="submit" class="btn btn-primary loadable"
                                        [class.loading]="loading"
                                        [attr.disabled]="loading ? true : null">
                                    {{ 'BTN_CONTINUE' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

    <div class="col-md-8">
        <app-aside></app-aside>
    </div>

</div>
