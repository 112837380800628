<div [formGroup]="getFormGroup()" class="separator-top">

    <h2>
        {{'ODC_SUCCESSOR_TITLE' | translate}}
    </h2>
    <fieldset>
        <legend class="sr-only">{{'ODC_SUCCESSOR_TITLE' | translate}}</legend>

        <!--Gender-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get(groupName)"
            [submitted]="submitted"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
            [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
            [id]="gender"
            [name]="gender"
            [path]="gender"
            [binding]="gender"
        ></app-odc-field>

        <!--firstname & lastname--------------------------------->
        <div class="row">
            <div class="col-md-12">
                <!--spouseFirstName --------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                    [id]="firstName"
                    [name]="firstName"
                    [path]="firstName"
                    [binding]="firstName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <!--spouseLastName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                    [id]="lastName"
                    [name]="lastName"
                    [path]="lastName"
                    [binding]="lastName"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
        </div>

        <!--SIN-------------------------------------------------------->
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [autoComplete]="'off'"
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_BENEFICIARY_SIN_PLEINEX' | translate"
                    [id]="SIN"
                    [name]="SIN"
                    [path]="SIN"
                    [binding]="SIN"
                    [attr]="{maxlength: 11}"
                    [sinFormat]="true"
                    [templateRefFr]="helpSIN"
                    [templateRefEn]="helpSIN"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get(groupName)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_BENEFICIARY_PART' | translate"
                    [id]="part"
                    [name]="part"
                    [path]="part"
                    [binding]="part"
                    [percentFormat]="true"
                    [attr]="{maxlength: 3}"
                    [attrDisabled] = "true"
                    [value]="'100'"
                    [defaultValue]="'100'"
                ></app-odc-field>
            </div>
        </div>
    </fieldset>

</div>


<ng-template #helpSIN>
    <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
    <p>{{'HELP_SIN_BODY' | translate}}</p>
</ng-template>
