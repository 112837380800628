import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeNbsp'
})
export class RemoveNbspPipe implements PipeTransform {
    transform(value: string): string {
        return value ? value.replace(/&nbsp;/g, ' ') : value;
    }
}
