<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">
    <h2>{{'ODC_STEP_REFERRED_ACCOUNT_TITLE'|translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--userBankId--------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_REFERRED_ACCOUNT_INSTITUTION' | translate"
        [id]="'userBankId'"
        [name]="'userBankId'"
        [path]="'userBankId'"
        [list]="bankList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userBankId')"
    ></app-odc-field>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_REFERRED_ACCOUNT_TRANSIT' | translate"
        [id]="'userBankBranchNumber'"
        [name]="'userBankBranchNumber'"
        [path]="'userBankBranchNumber'"
        [binding]="getRequesterPath('userBankBranchNumber')"
        [numericFormat]="true"
        [attr]="{maxlength: 5}"
    ></app-odc-field>

    <div class="row" *ngIf="isGpdOrSfd()">
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_REFERRED_ACCOUNT_FirstName' | translate"
                [id]="'userIdentityAdvisorFirstName'"
                [name]="'userIdentityAdvisorFirstName'"
                [path]="'userIdentityAdvisorFirstName'"
                [binding]="getRequesterPath('userIdentityAdvisorFirstName')"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_REFERRED_ACCOUNT_LastName' | translate"
                [id]="'userIdentityAdvisorFamilyName'"
                [name]="'userIdentityAdvisorFamilyName'"
                [path]="'userIdentityAdvisorFamilyName'"
                [binding]="getRequesterPath('userIdentityAdvisorFamilyName')"
                [attr]="{maxlength: 32}"
            ></app-odc-field>
        </div>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>


