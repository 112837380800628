import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

declare let $: any;
declare let _: any;

@Component({
    selector: 'app-help-insider',
    templateUrl: './help-insider.component.html'
})
export class HelpInsiderComponent implements OnInit, AfterViewInit, OnDestroy {

    protected subscriptions: any[] = [];

    constructor(public translate: TranslateService) {
    }

    ngOnInit() {
        this.subscriptions.push(this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            this.initNotes();
        }));
    }

    ngAfterViewInit() {
        this.initNotes();

        setTimeout(() => {
            $('.help-link').on('shown.bs.popover', (e) => {
                this.initNotes();
            });
        }, 1000);
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    initNotes(): void {
        setTimeout(() => {
            $('.help-insider .appel-note, .help-insider .retour-note').off('click');
            $('.help-insider .appel-note, .help-insider .retour-note').on('click', (e) => {
                const targetId = $(e.currentTarget).attr('href');
                if ($(targetId).length > 0) {
                    $(targetId).focus();
                }
                return false;
            });
        });
    }
}
