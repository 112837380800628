import {Component, Input, OnInit} from '@angular/core';
import {UpdateSummaryCategory} from '../../../components/models/update-summary-category';
import {RequestsComparisonResult} from '../../../components/models/requests-comparison-result.model';
import {UpdateSummarySubCategory} from '../../../components/models/update-summary-sub-category';
import * as SummaryPageFields from '../../../components/constants/update-account-delta-review-page/summary-page-fields.constants';

@Component({
    selector: 'app-review-update',
    templateUrl: './review-update.component.html'
})
export class ReviewUpdateComponent implements OnInit {

    @Input() formDelta: RequestsComparisonResult[];
    @Input() showModifyBtn: boolean = true;
    @Input() isPleinEx: boolean = false;

    summaryCategories: UpdateSummaryCategory[];

    constructor() {
    }

    ngOnInit(): void {
        this.summaryCategories = [];
        if (this.formDelta) {
            this.createFormDeltaPage(this.formDelta);
        }
    }

    createFormDeltaPage(requestComparisonResultsList: RequestsComparisonResult[]) {

        const requestsComparisonResultsByStep = {
            personalInformationClient: [],
            fiscalResidency: [],
            occupationAndEmployer: [],
            clientMaritalStatus: [],
            brokerageAccount: [],
            financialInformation: [],
            investObjectivesAndRiskFactors: [],
            beneficiary: [],
            specificConsent: []
        };

        this.sortRequestComparisonResults(requestComparisonResultsList, requestsComparisonResultsByStep);
        this.createSummaryPage(2,
            requestsComparisonResultsByStep.personalInformationClient,
            requestsComparisonResultsByStep.fiscalResidency);
        this.createSummaryPage(3, requestsComparisonResultsByStep.occupationAndEmployer);
        this.createSummaryPage(4, requestsComparisonResultsByStep.clientMaritalStatus);

        if (this.isPleinEx) {

            this.createSummaryPage(5,
                requestsComparisonResultsByStep.brokerageAccount);
            this.createSummaryPage(6,
                requestsComparisonResultsByStep.financialInformation,
                requestsComparisonResultsByStep.investObjectivesAndRiskFactors,
                requestsComparisonResultsByStep.beneficiary,
                requestsComparisonResultsByStep.specificConsent);
        } else {

            this.createSummaryPage(5,
                requestsComparisonResultsByStep.brokerageAccount,
                requestsComparisonResultsByStep.specificConsent);
            this.createSummaryPage(6,
                requestsComparisonResultsByStep.financialInformation,
                requestsComparisonResultsByStep.investObjectivesAndRiskFactors,
                requestsComparisonResultsByStep.beneficiary);
        }
    }

    private createSummaryPage(pageIndex: number, ...args: any) {
        const comparisonPage: UpdateSummaryCategory = {
            pageKey: pageIndex,
            updateSummarySubcategory: []
        };

        args.forEach((requestComparison, index) => {
            if (requestComparison.length > 0) {
                const subPage: UpdateSummarySubCategory = {
                    subPageKey: index + 1,
                    comparisonResults: requestComparison
                };

                comparisonPage.updateSummarySubcategory.push(subPage);
            }
        });

        if (comparisonPage && comparisonPage.updateSummarySubcategory.length > 0) {
            this.summaryCategories.push(comparisonPage);
        }
    }

    private sortRequestComparisonResults(requestComparisonResultsList: RequestsComparisonResult[],
                                         requestComparisonResultByStep: { [key: string]: any }) {
        requestComparisonResultsList.forEach(requestComparisonResult => {
            if (SummaryPageFields.clientsPersonalInformationPage.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.personalInformationClient.push(requestComparisonResult);
            }

            if (SummaryPageFields.fiscalResidencyPage.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.fiscalResidency.push(requestComparisonResult);
            }

            if (SummaryPageFields.occupationAndEmployer.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.occupationAndEmployer.push(requestComparisonResult);
            }

            if (SummaryPageFields.clientMaritalStatus.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.clientMaritalStatus.push(requestComparisonResult);
            }

            if (SummaryPageFields.brokerageAccount.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.brokerageAccount.push(requestComparisonResult);
            }

            if (SummaryPageFields.financialInformation.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.financialInformation.push(requestComparisonResult);
            }

            if (SummaryPageFields.investObjectivesAndRiskFactors.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.investObjectivesAndRiskFactors.push(requestComparisonResult);
            }

            if (SummaryPageFields.beneficiary.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.beneficiary.push(requestComparisonResult);
            }

            if (SummaryPageFields.specificConsent.includes(requestComparisonResult.fieldName)) {
                requestComparisonResultByStep.specificConsent.push(requestComparisonResult);
            }
        });
    }
}
