<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">
    <h2>{{'ODC_STEP_SPOUSE_INFORMATION_CLIENT_MARITAL_STATUS'|translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--MARITAL STATUS-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_SPOUSE_INFORMATION_MARITAL_STATUS' | translate"
        [id]="'userMaritalStatus'"
        [name]="'userMaritalStatus'"
        [path]="'userMaritalStatus'"
        [list]="userMaritalStatus"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userMaritalStatus')"
        [readonlyProperty]="'userMaritalStatus'"
    ></app-odc-field>

    <div *ngIf="isPleinEx()">
        <div *ngFor="let account of contributingSpouseAccounts">

            <!--ContributingSpouseIsCurrentSpouse-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_LABEL' | translate: {order: (account.orderLabel | translate), type: ('CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_' + account.type) | translate}"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="account.name"
                [name]="account.name"
                [path]="account.name"
                [binding]="account.name"
                [errorOnSubmitOnly]="['contributingSpouseCannotBeCurrentSpouse']"
            ></app-odc-field>
        </div>
    </div>

    <div *ngIf="hasSpouse()" formGroupName="spouse">
        <h2>
            {{'ODC_STEP_SPOUSE_INFORMATION_TITLE'|translate}}
            <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
        </h2>
        <fieldset>
            <legend class="sr-only"><b>{{'ODC_STEP_SPOUSE_INFORMATION_TITLE'|translate}}</b></legend>

            <!--Gender-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('spouse')"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
                [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
                [id]="'spouseGender'"
                [name]="'spouseGender'"
                [path]="'spouseGender'"
                [binding]="getRequesterPath('spouseGender')"
                [readonlyProperty]="'spouseGender'"
            ></app-odc-field>


            <!--firstname & lastname--------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <!--spouseFirstName --------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('spouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                        [id]="'spouseFirstName'"
                        [name]="'spouseFirstName'"
                        [path]="'spouseFirstName'"
                        [binding]="getRequesterPath('spouseFirstName')"
                        [attr]="{maxlength: 32}"
                        [readonlyProperty]="'spouseFirstName'"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <!--spouseLastName--------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('spouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                        [id]="'spouseLastName'"
                        [name]="'spouseLastName'"
                        [path]="'spouseLastName'"
                        [binding]="getRequesterPath('spouseLastName')"
                        [attr]="{maxlength: 32}"
                        [readonlyProperty]="'spouseLastName'"
                    ></app-odc-field>
                </div>
            </div>


            <!--DOB & SIN-------------------------------------------------------->
            <div *ngIf="isAnnuitantSpouse()" class="row" formGroupName="annuitantSpouse">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('spouse.annuitantSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                        [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                        [id]="'spouseDOB'"
                        [name]="'spouseDOB'"
                        [path]="'spouseDOB'"
                        [binding]="getRequesterPath('spouseDOB')"
                        [dateFormat]="true"
                        [templateRefFr]="helpDOB"
                        [templateRefEn]="helpDOB"
                        [readonlyProperty]="'spouseDOB'"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [autoComplete]="'off'"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('spouse.annuitantSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_SIN' | translate"
                        [id]="'spouseSIN'"
                        [name]="'spouseSIN'"
                        [path]="'spouseSIN'"
                        [binding]="getRequesterPath('spouseSIN')"
                        [attr]="{maxlength: 11}"
                        [sinFormat]="true"
                        [templateRefFr]="helpSIN"
                        [templateRefEn]="helpSIN"
                    ></app-odc-field>
                </div>
            </div>

            <!--spouseJobStatus ------------------------------------------------>
            <div *ngIf="!isPleinEx()">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.SELECT"
                    [form]="form.get('spouse')"
                    [submitted]="isFormSubmitted()"
                    [label]="'EMPLOYMENT_STATUS_LABEL' | translate"
                    [id]="'spouseJobStatus'"
                    [name]="'spouseJobStatus'"
                    [path]="'spouseJobStatus'"
                    [list]="jobStatusList"
                    [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                    [binding]="getRequesterPath('spouseJobStatus')"
                ></app-odc-field>
            </div>

            <div *ngIf="spouseIsPaidEmployeeOrSelfEmployed()" formGroupName="spouseEmployer">

                <div class="row">
                    <div class="col-md-12">

                        <!--spouseEmployerName--------------------------------->
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('spouse.spouseEmployer')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_SPOUSE_EMPLOYER_NAME' | translate"
                            [id]="'spouseEmployerName'"
                            [name]="'spouseEmployerName'"
                            [path]="'spouseEmployerName'"
                            [binding]="getRequesterPath('spouseEmployerName')"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>

                    </div>
                    <div class="col-md-12">


                        <!--"--------------------------------->
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('spouse.spouseEmployer')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_SPOUSE_JOB_TITLE' | translate"
                            [id]="'spouseJobTitle'"
                            [name]="'spouseJobTitle'"
                            [path]="'spouseJobTitle'"
                            [binding]="getRequesterPath('spouseJobTitle')"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>

                    </div>
                </div>

                <!--spouseEmployerActivity--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.SELECT"
                    [form]="form.get('spouse.spouseEmployer')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_EMPLOYER_ACTIVITY' | translate"
                    [id]="'spouseEmployerActivity'"
                    [name]="'spouseEmployerActivity'"
                    [path]="'spouseEmployerActivity'"
                    [list]="employerActivityList"
                    [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                    [binding]="getRequesterPath('spouseEmployerActivity')"
                ></app-odc-field>


            </div>
        </fieldset>

    </div>

    <div *ngIf="hasAtLeastOneContributingSpouse()" formGroupName="contributingSpouse">
        <h2>
            {{'ODC_STEP_CONTRIBUTING_SPOUSE_INFORMATION_TITLE'|translate}}
        </h2>
        <fieldset>
            <legend class="sr-only"><b>{{'ODC_STEP_CONTRIBUTING_SPOUSE_INFORMATION_TITLE'|translate}}</b></legend>

            <!--Gender-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('contributingSpouse')"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
                [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
                [id]="'contributingSpouseGender'"
                [name]="'contributingSpouseGender'"
                [path]="'contributingSpouseGender'"
                [binding]="getRequesterPath('contributingSpouseGender')"
            ></app-odc-field>


            <!--firstname & lastname--------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <!--spouseFirstName --------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('contributingSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                        [id]="'contributingSpouseFirstName'"
                        [name]="'contributingSpouseFirstName'"
                        [path]="'contributingSpouseFirstName'"
                        [binding]="getRequesterPath('contributingSpouseFirstName')"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <!--spouseLastName--------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('contributingSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                        [id]="'contributingSpouseLastName'"
                        [name]="'contributingSpouseLastName'"
                        [path]="'contributingSpouseLastName'"
                        [binding]="getRequesterPath('contributingSpouseLastName')"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
            </div>


            <!--DOB & SIN-------------------------------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('contributingSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                        [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                        [id]="'contributingSpouseDOB'"
                        [name]="'contributingSpouseDOB'"
                        [path]="'contributingSpouseDOB'"
                        [binding]="getRequesterPath('contributingSpouseDOB')"
                        [dateFormat]="true"
                        [templateRefFr]="helpDOB"
                        [templateRefEn]="helpDOB"
                        [autoComplete]="'on'"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [autoComplete]="'off'"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('contributingSpouse')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_SIN' | translate"
                        [id]="'contributingSpouseSIN'"
                        [name]="'contributingSpouseSIN'"
                        [path]="'contributingSpouseSIN'"
                        [binding]="getRequesterPath('contributingSpouseSIN')"
                        [attr]="{maxlength: 11}"
                        [sinFormat]="true"
                        [templateRefFr]="helpSIN"
                        [templateRefEn]="helpSIN"
                    ></app-odc-field>
                </div>
            </div>

        </fieldset>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>


<ng-template #helpFr>
    <h2>Pourquoi dois-je fournir des renseignements sur mon conjoint?</h2>
    <p>Les renseignements sur votre conjoint sont requis afin de déterminer s'il y a lieu d'appliquer des règlements
        particuliers aux opérations effectuées sur le compte tel qu'établir le compte comme un compte de professionnels
        lorsque votre conjoint travaille au sein d'une société membre de l'OCRCVM.</p>
</ng-template>
<ng-template #helpEn>
    <h2>Why must I provide information about my spouse?</h2>
    <p>Information about your spouse is required to determine if there should be any special regulations for
        transactions in the account, such as establishing the account as "professional" if your spouse works at an IIROC
        member firm.</p>
</ng-template>


<ng-template #helpSIN>
    <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
    <p>{{'HELP_SIN_BODY' | translate}}</p>
</ng-template>

<ng-template #helpDOB>
    <h2>{{'HELP_DOB_TITLE' | translate}}</h2>
    <p>{{'HELP_DOB_BODY' | translate}}</p>
</ng-template>
