import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {I18N_NAV_MESSAGES_EN} from '../../components/i18n/nav-messages_en';
import {FormService} from '../../components/services/form.service';
import {SessionService} from '../../components/services/session.service';
import {RecoveryService} from '../../components/services/recovery.service';
import {WebException} from '../../components/models';
import {ProfileAccountOpeningRequestListComponent} from './profile-account-opening-request-list.component';
import {ProfileService} from '../../components/services/profile.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ListModelService} from '../../components/services/list-model.service';
import {VmdForm} from '../../components/utils/vmd-form';
import {AuthService} from '../../components/services/auth.service';
import {ClientLoginBean} from '../../components/models/clientLoginBean';
import {RecaptchaComponent} from './recaptcha.component';
import {UserLogin} from '../../components/models/user-login';

declare let $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    providers: [TranslatePipe]
})
export class LoginComponent implements OnInit {

    @ViewChild(ProfileAccountOpeningRequestListComponent)
    profileAccountOpeningRequestList: ProfileAccountOpeningRequestListComponent;
    @ViewChild(NgForm) ngForm: NgForm;
    @ViewChild(RecaptchaComponent) recaptchaWidget: RecaptchaComponent;

    form: FormGroup;
    loading = false;
    constants = VmdConstants;
    httpErrors: WebException;

    showList = false;
    protected questionSecuriteList: HtmlSelectKV[];

    constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder,
                private translate: TranslateService, private formService: FormService,
                private sessionService: SessionService, private recoveryService: RecoveryService,
                private authService: AuthService,
                private profileService: ProfileService, private listModelService: ListModelService,
                private translatePipe: TranslatePipe) {

        this.createForm();
    }

    ngOnInit() {
        this.listModelService.getListModel('questionSecuriteList', data => this.questionSecuriteList = data);

        this.sessionService.getNewToken().subscribe((data: any) => {

            this.sessionService.beginSession(data.payload);
        });

        const routerState = '_routerState';
        const url = this.route[routerState].snapshot.url;
        if (url === '/' + I18N_NAV_MESSAGES_FR.ODC_NAV_LOGIN_PATH && this.translate.currentLang !== VmdConstants.LANG_FR) {
            this.translate.use(VmdConstants.LANG_FR);
        } else if (url === '/' + I18N_NAV_MESSAGES_EN.ODC_NAV_LOGIN_PATH && this.translate.currentLang !== VmdConstants.LANG_EN) {
            this.translate.use(VmdConstants.LANG_EN);
        }

        this.enableLoginSection();
    }

    createForm() {
        this.form = this.fb.group({
            login: this.fb.group({
                courriel: [null, [Validators.required, VmdValidators.email]],
                motDePasse: [null, Validators.required]
            }),
            passwords: this.fb.group({
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]],
                passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]]
            }, {validator: VmdValidators.passwordMatchValidator()}),
            recaptcha: []
        });
    }

    displayList() {
        this.enableListSection();
        this.profileAccountOpeningRequestList.getAccountOpeningRequestList();
    }

    private enableLoginSection(): void {
        this.form.get('login').enable();
        this.form.get('login').markAsUntouched();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    private enablePasswordsSection(): void {
        this.form.get('login').disable();
        this.form.get('passwords').enable();
        this.form.get('passwords').markAsUntouched();
        $('#content').focus();
    }

    private enableListSection(): void {
        this.showList = true;
        this.form.get('login').disable();
        this.form.get('passwords').disable();
        $('#content').focus();
    }

    focusOnErrorsList() {
        setTimeout(() => {
            const firstError = ($('#boite-erreurs').first().length > 0) ? $('#boite-erreurs').first() : $('.has-error input, .has-error select').first();
            if (firstError.length > 0) {
                $(firstError).focus();
            }
        }, 0);
    }

    goToForgotPassword(): void {
        this.router.navigate(['/', this.translatePipe.transform('ODC_NAV_FORGOT_PASSWORD_PATH')]);
    }

    isFieldInError(path: string): boolean {
        return VmdForm.isFieldInError(this.form.get(path), this.isFormSubmitted());
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    login() {
        this.httpErrors = null;
        this.loading = true;

        this.authService.authenticate(ClientLoginBean.fromObject({
            email: this.form.get('login.courriel').value,
            password: this.form.get('login.motDePasse').value,
            reCaptchaResponse: this.recaptchaWidget.getRecaptchaToken()
        })).subscribe(
            (data) => {
                this.onLoginSuccess(data);
            },
            error => this.onRecoveryError(error)
        ).add(() => {
            this.loading = false;
        });
    }

    onLoginSuccess(data: UserLogin) {
        if (data) {
            if (data.temporaryPasswordIndicator) {
                this.enablePasswordsSection();
            } else {
                this.displayList();
            }
        }
    }

    onRecoveryError(error: WebException) {
        this.httpErrors = error;
        this.resetRecaptcha();
    }

    onSubmit() {
        VmdForm.markAsTouched(this.form);

        if (this.form.valid) {
            if (this.form.get('login').enabled) {
                this.login();
            } else if (this.form.get('passwords').enabled) {
                this.onSubmitPasswords();
            }
        } else {
            this.focusOnErrorsList();
        }
    }

    onSubmitPasswords() {
        this.loading = true;
        this.httpErrors = null;

        const newPassword = this.form.get('passwords.newPassword').value;
        const confirmNewPassword = this.form.get('passwords.confirmNewPassword').value;

        this.profileService.definePassword(newPassword, confirmNewPassword)
            .subscribe(
                () => {
                    this.displayList();
                },
                (error: WebException) => {
                    this.setError(error);
                }
            )
            .add(() => {
                this.loading = false;
            });
    }

    setError(error: any): void {
        this.httpErrors = error;
    }

    resetRecaptcha() {
        this.recaptchaWidget.reset();
    }
}
