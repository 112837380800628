export class AccountTransferSecurity {

    description: string;
    symbol: string;
    quantity: number;
    type: string;

    constructor(obj?: any) {

        this.description = obj && obj.description || null;
        this.symbol = obj && obj.symbol || null;
        this.quantity = obj && obj.quantity || null;
        this.type = obj && obj.type || null;
    }
}
