export const ErrorCodeConstants = {

    ODC_QUESTIONNAIRE_SAVE_ERROR: 'ODC_QUESTIONNAIRE_SAVE_ERROR',
    PROFILE_NOT_AUTHENTICATED: 'PROFILE_NOT_AUTHENTICATED',
    EMAIL_HAS_CHANGED: 'EMAIL_HAS_CHANGED',
    INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
    GLOBAL_ERROR_MSG: 'GLOBAL_ERROR_MSG',
    CLIENT_PROFILE_NOT_FOUND: 'CLIENTPROFILE_NOT_FOUND',
    TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS'
};
