import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-help-warning',
    templateUrl: './help-warning.component.html'
})
export class HelpWarningComponent {

    @Input() messageKey = null;
}
