import {Component} from '@angular/core';
import {BaseAddSpouseRelatedQuestionsComponent} from './base-add-spouse-related-questions.component';

@Component({
    selector: 'app-enfant-es-qualites-related-questions',
    templateUrl: './base-add-spouse-related-questions.component.html'
})
export class EnfantEsQualitesRelatedQuestionsComponent extends BaseAddSpouseRelatedQuestionsComponent {

    public getFieldPrefix(): string {
        return 'enfesqualites';
    }

}
