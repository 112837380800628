export const OTHER_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG: any = [
    {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_UPDATE_GPD_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'AccountsChoiceUpdateComponent',
                saveAvailable: false,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_GPD'
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_SUBMIT',
        items: [
            {
                label: 'ODC_NAV_STEP_SUBMIT',
                path: 'ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_UPDATE_PLEIN_EX_GPD_PATH',
                component: 'AddAccountSubmissionComponent',
                saveAvailable: false
            }
        ]
    }
];
