<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>
        {{'USER_FINANCE_INFO_OPTION_ACCOUNT_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--optionCode--------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'USER_FINANCE_INFO_OPTION_CODE' | translate"
        [id]="'optionCode'"
        [name]="'optionCode'"
        [path]="'optionCode'"
        [list]="optionCodeList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="'optionCode'"
    ></app-odc-field>

    <div *ngIf="hasRegisteredAccount()">
        <!--registeredOptionCode--------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'USER_FINANCE_INFO_REGISTERED_OPTION_CODE' | translate"
            [id]="'registeredOptionCode'"
            [name]="'registeredOptionCode'"
            [path]="'registeredOptionCode'"
            [list]="optionCodeList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [binding]="'registeredOptionCode'"
        ></app-odc-field>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
