import {VmdConstants} from './components/constants/vmd-constants';
import {PERSONAL_ACCOUNT_ROUTES_CONFIG} from './personal-account-routes-config';
import {JOINT_ACCOUNT_ROUTES_CONFIG} from './joint-account-routes-config';
import {OTHER_ACCOUNT_ROUTES_CONFIG} from './other-account-routes-config';
import {PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG} from './personal-account-plein-ex-routes-config';
import {JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG} from './joint-account-plein-ex-routes-config';
import {OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG} from './other-account-plein-ex-routes-config';
import {PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG} from './personal-account-gpd-routes-config';
import {JOINT_ACCOUNT_GPD_ROUTES_CONFIG} from './joint-account-gpd-routes-config';
import {OTHER_ACCOUNT_GPD_ROUTES_CONFIG} from './other-account-gpd-routes-config';
import {JOINT_ACCOUNT_SSD_ROUTES_CONFIG} from './joint-account-ssd-routes-config';
import {OTHER_ACCOUNT_SSD_ROUTES_CONFIG} from './other-account-ssd-routes-config';
import {PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG} from './update-personal-account-plein-ex-routes-config';
import {PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG} from './update-personal-account-ssd-routes-config';
import {PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG} from './update-personal-account-gpd-routes-config';
import {PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG} from './update-personal-account-routes-config';
import {PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG} from './personal-account-ssd-routes-config';
import {JOINT_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG} from "./update-joint-account-plein-ex-routes-config";
import {JOINT_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG} from "./update-joint-account-ssd-routes-config";
import {JOINT_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG} from "./update-joint-account-gpd-routes-config";
import {JOINT_ACCOUNT_UPDATE_ROUTES_CONFIG} from "./update-joint-account-routes-config";
import {OTHER_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG} from "./update-other-account-plein-ex-routes-config";
import {OTHER_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG} from "./update-other-account-ssd-routes-config";
import {OTHER_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG} from "./update-other-account-gpd-routes-config";
import {OTHER_ACCOUNT_UPDATE_ROUTES_CONFIG} from "./update-other-account-routes-config";

declare let window: any;

export const ODC_ROUTES_CONFIG = [
    {key: VmdConstants.PERSONAL_ACCOUNT_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_GPD_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_GPD_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_SSD_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_SSD_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY, config: PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.JOINT_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY, config: JOINT_ACCOUNT_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG},
    {key: VmdConstants.OTHER_ACCOUNT_UPDATE_ROUTES_CONFIG_KEY, config: OTHER_ACCOUNT_UPDATE_ROUTES_CONFIG},
];

export class AppRoutes {

    static getStepRoutesConfig(key: string): Array<any> {
        let stepRoutesConfig = [];
        let step = 1;
        for (const item of ODC_ROUTES_CONFIG) {
            if (item.key === key) {
                stepRoutesConfig = item.config;
                for (let j = 0; j < stepRoutesConfig.length; j++) {
                    if (stepRoutesConfig[j].showTab && AppRoutes.getEnabledSubStepLength(stepRoutesConfig, j) > 0) {
                        stepRoutesConfig[j].step = step;
                        step++;
                    } else {
                        stepRoutesConfig[j].step = null;
                    }
                }
            }
        }
        return stepRoutesConfig;
    }

    static getEnabledSubStepLength(routesConfig: Array<any>, index: number): number {
        let length = 0;
        for (const item of routesConfig[index].items) {
            if (!item.disable) {
                length++;
            }
        }
        return length;
    }

    static getFirstShownedTabStepPath(key: string): string {
        const stepRoutesConfig = AppRoutes.getStepRoutesConfig(key);

        for (const step of stepRoutesConfig) {

            if (step.showTab && step.items && step.items.length > 0) {

                for (const substep of step.items) {

                    if (!substep.disable) {
                        return substep.path;
                    }

                }
            }

        }

        return null;
    }
}
