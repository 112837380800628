<div id="modal-auth" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label" aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <form [formGroup]="form" ngForm novalidate (submit)="submitModalForm()" role="form">
            <div class="modal-content" role="document" tabindex="0">
                <div class="modal-header">
                    <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                        {{ 'ODC_MODAL_CONNEXION_TITLE' | translate }}
                    </h2>
                </div>

                <!--save authentication-->
                <ng-container *ngIf="displaySecurityQuestionGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <div formGroupName="securityQuestionGroup">

                            <div [class.has-error]="httpErrors != null">
                                <span class="help-block" tabindex="0">
                                    <app-http-error [errors]="httpErrors"></app-http-error>
                                </span>
                            </div>

                            <p>{{'PROFIL_ENTER_A_PASSWORD'|translate}}</p>

                            <ng-container *ngTemplateOutlet="notePassword"></ng-container>

                            <h3>{{'ODC_MODAL_CONNEXION_CREATE_PASSWORD_TITLE' | translate}}</h3>
                            <p [innerHTML]="'ODC_MODAL_CONNEXION_CREATE_PASSWORD_DESC' | translate : {email: requesterEmail}"></p>


                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="securityQuestion" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_QUESTION' | translate}}</b>
                                    </label>
                                    <div class="col-md-18" [class.has-error]="isFieldInError('securityQuestionGroup.securityQuestion')">
                                        <label *ngIf="isFieldInError('securityQuestionGroup.securityQuestion')" for="securityQuestion">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'securityQuestionGroup.securityQuestion'"></app-error>
                                        </label>
                                        <select id="securityQuestion" class="custom-select form-control"
                                                name="securityQuestion"
                                                formControlName="securityQuestion">
                                            <option [ngValue]="null">{{ 'GLOBAL_SELECT' | translate }}</option>
                                            <option *ngFor="let item of securityQuestionList" [value]="item.itemValue">
                                                {{item.itemLabel}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="securityAnswer" class="col-md-6 control-label">
                                        <b>{{'PROFIL_SECURITY_ANSWER' | translate}}</b>
                                    </label>
                                    <div class="col-md-18" [class.has-error]="isFieldInError('securityQuestionGroup.securityAnswer')">
                                        <label *ngIf="isFieldInError('securityQuestionGroup.securityAnswer')" for="securityAnswer">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'securityQuestionGroup.securityAnswer'"></app-error>
                                        </label>
                                        <input id="securityAnswer" class="form-control"
                                               name="securityAnswer" type="text"
                                               formControlName="securityAnswer" maxlength="64">
                                    </div>
                                </div>
                            </div>

                            <h3 [innerHTML]="'ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_TITLE' | translate"></h3>
                            <p>{{'ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_DESC' | translate}}</p>

                            <a href="javascript:void(0)" class="lien-action"
                               (click)="showAuthenticatePasswordForm()">{{'ODC_MODAL_CONNEXION_ENTER_PASSWORD_LINK' | translate }}</a>
                        </div>
                    </div>
                </ng-container>

                <!--profile authentication-->
                <ng-container *ngIf="displayAuthenticatePasswordGroup">
                    <div class="modal-body" [attr.id]="'modal-password-desc'">
                        <div formGroupName="authenticatePasswordGroup">

                            <h3>{{'ODC_MODAL_CONNEXION_AUTHENTICATION_TITLE'|translate}}</h3>
                            <p>{{'ODC_MODAL_CONNEXION_AUTHENTICATION_DESC'|translate}}</p>

                            <ng-container *ngTemplateOutlet="notePassword"></ng-container>

                            <div [class.has-error]="httpErrors != null">
                                    <span class="help-block" tabindex="0">
                                        <app-http-error [errors]="httpErrors" [isGenericBlocError]="false"></app-http-error>
                                    </span>
                            </div>

                            <div class="form-horizontal m-t-4 m-b-4">
                                <div class="form-group">
                                    <label for="password" class="col-md-6 control-label">
                                        <b>{{'PROFIL_PASSWORD_AUTHENTICATE_PASSWORD' | translate}}</b>
                                    </label>
                                    <div class="col-md-18" [class.has-error]="isFieldInError('authenticatePasswordGroup.password')">
                                        <label *ngIf="isFieldInError('authenticatePasswordGroup.password')" for="password">
                                            <app-error [form]="form" [submitted]="isFormSubmitted()"
                                                       [path]="'authenticatePasswordGroup.password'">
                                            </app-error>
                                        </label>
                                        <input id="password" class="form-control" name="password" type="password"
                                               maxlength="32" formControlName="password">
                                    </div>
                                </div>
                            </div>

                            <div>
                                <a href="javascript:void(0)" class="lien-action"
                                   (click)="openForgotPasswordModal()">
                                    {{'PROFIL_PASSWORD_FORGOT_LINK'|translate}}
                                </a>
                            </div>
                            <div>
                                <a href="javascript:void(0)" class="lien-action"
                                   (click)="showSecurityQuestionForm()">
                                    {{'PROFIL_CREATE_LINK'|translate}}
                                </a>
                            </div>
                            <div>
                                <br>
                                <app-recaptcha *ngIf="renderRecaptcha"
                                               [containerId]="'recaptcha-login-save'"
                                               [langChange]="translate.onLangChange"
                                               [lang]="translate.currentLang"
                                ></app-recaptcha>
                            </div>

                        </div>
                    </div>
                </ng-container>

                <div class="modal-footer">
                    <div
                        *ngIf="displayAuthenticatePasswordGroup || displaySecurityQuestionGroup"
                        class="btn-resp btn-resp-1colonne-inline">
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{ (displayAuthenticatePasswordGroup ? 'GLOBAL_SAVE_REQUEST' : 'BTN_PASSWORD_VALIDATION') | translate }}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null">
                                {{ (displayAuthenticatePasswordGroup ? 'GLOBAL_SAVE_REQUEST' : 'BTN_PASSWORD_VALIDATION') | translate }}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                    type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>

            </div>
        </form>
    </div>
    <ng-template #notePassword>
        <div class="alert alert-info" [innerHTML]="'ODC_MODAL_CONNEXION_NOTE' | translate"></div>
    </ng-template>
</div>
