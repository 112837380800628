import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-field',
  templateUrl: './review-field.component.html'
})
export class ReviewFieldComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;

    ngOnInit() {

    }

}
