import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseNavComponent} from '../../questionnaire/base-nav.component';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../../../components/services/navigation.service';
import {ModalService} from '../../../../components/services/modal.service';
import {FormService} from '../../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '../../../../components/services/profile.service';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {SessionService} from '../../../../components/services/session.service';
import {AuthService} from '../../../../components/services/auth.service';
import {ClientLoginBean} from '../../../../components/models/clientLoginBean';
import {UserLogin} from '../../../../components/models/user-login';
import {RecaptchaComponent} from '../../../login/recaptcha.component';
import {WebException} from '../../../../components/models';
import {HtmlSelectKV} from '../../../../components/models/html-select-k-v';
import {ListModelService} from '../../../../components/services/list-model.service';
import {CreateProfileBean} from '../../../../components/models/createProfileBean';
import {ModalSelectors} from '../../../../components/constants/modal-selectors';

declare let $: any;

@Component({
    selector: 'app-modal-auth',
    templateUrl: './modal-auth.component.html'
})
export class ModalAuthComponent extends BaseNavComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;

    displaySecurityQuestionGroup = false;
    displayAuthenticatePasswordGroup = false;
    loading = false;
    renderRecaptcha: boolean = false;

    constants: any = VmdConstants;
    httpErrors: any;

    requesterEmail: string;
    securityQuestionList: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected modalService: ModalService,
                private profileService: ProfileService,
                private sessionService: SessionService,
                private authService: AuthService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({

            securityQuestionGroup: this.fb.group({
                securityQuestion: [null, [Validators.required]],
                securityAnswer: [null, [Validators.required, Validators.minLength(5)]]
            }),

            authenticatePasswordGroup: this.fb.group({
                password: [null, [Validators.required]]
            }),
        });

        this.modalService.showAuthenticatePasswordForm.subscribe(() => {
            this.resetModal();
            this.displayAuthenticatePasswordGroup = true;
            this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), true);
        });

        this.modalService.showSaveAuthForm.subscribe(() => {
            this.resetModal();
            this.displaySecurityQuestionGroup = true;
            this.requesterEmail = this.formService.getForm().requesters[0].userEmail;
            this.updateControlEnabled(this.form.get('securityQuestionGroup'), true);
        });

        this.renderRecaptcha = true;
        this.listModelService.getListModel('questionSecuriteList', data => this.securityQuestionList = data);
    }

    submitModalForm() {
        this.markAsTouched(this.form);
        if (this.form.valid) {
            if (this.displaySecurityQuestionGroup) {
                this.submitCreateProfile(this.form.get('securityQuestionGroup'));
            } else if (this.displayAuthenticatePasswordGroup) {
                this.authenticatePassword(this.form.get('authenticatePasswordGroup'));
            }
        }
    }

    resetModal() {
        this.httpErrors = null;
        this.form.reset();

        this.displayAuthenticatePasswordGroup = false;
        this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), false);

        this.displaySecurityQuestionGroup = false;
        this.updateControlEnabled(this.form.get('securityQuestionGroup'), false);

        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }

    private submitCreateProfile(form: AbstractControl) {
        this.httpErrors = null;
        this.loading = true;

        const createProfileBean = new CreateProfileBean({
                lang: this.translate.currentLang,
                email: this.requesterEmail,
                securityQuestion: form.get('securityQuestion').value,
                securityAnswer: form.get('securityAnswer').value
            }
        );

        this.profileService.createProfile(createProfileBean).subscribe(() => {
                this.openCreateProfileModal();
            },
            (error: WebException) => {
                this.httpErrors = error;
                this.focusOnError();
            })
            .add(() => {
                this.loading = false;
                this.resetRecaptcha();
            });
    }

    focusOnError() {
        $('#modal-auth').animate({ scrollTop: 0 }, 'fast');
    }

    authenticatePassword(form: AbstractControl) {
        this.loading = true;
        this.httpErrors = null;
        this.authService.authenticate(ClientLoginBean.fromObject({
            email: this.formService.getForm().requesters[0].userEmail,
            password: form.get('password').value,
            reCaptchaResponse: this.recaptcha.getRecaptchaToken()
        })).subscribe(
            (data: UserLogin) => {
                if (data.temporaryPasswordIndicator) {
                    this.openDefinePasswordModal();
                } else {
                    this.onAuthenticatePasswordSuccess();
                }
            },
            (error: WebException) => {
                this.loading = false;
                this.onAuthenticatePasswordError(error);
            }
        );
    }

    onAuthenticatePasswordSuccess() {
        this.loading = true;
        this.formService.saveForm().subscribe(
            () => {
                this.closeModal();
                this.formService.emitSaved();
                this.modalService.openModal('ODC_MODAL_CONNEXION_TITLE', 'ODC_MODAL_SAVED_APPLICATION_BODY',
                    ModalSelectors.ALERT_MODAL, null, null, ['GLOBAL_CLOSE']);
            }, (error) => {
                this.httpErrors = error;
            }
        ).add(() => {
            this.loading = false;
        });
    }

    onAuthenticatePasswordError(error) {
        this.httpErrors = error;
        this.resetRecaptcha();
    }

    closeModal() {
        if (!$(ModalSelectors.AUTH_MODAL).length) {
            return;
        }
        $(ModalSelectors.AUTH_MODAL).modal('hide');
    }

    openForgotPasswordModal() {
        this.closeModal();
        this.modalService.emitShowForgotPassword();
        this.modalService.openModal(null, null, ModalSelectors.FORGOT_PASSWORD_MODAL);
    }

    openCreateProfileModal() {
        this.closeModal();
        this.modalService.emitShowCreateProfile();
        this.modalService.openModal(null, null, ModalSelectors.CREATE_PROFILE_MODAL);
    }

    openDefinePasswordModal() {
        this.closeModal();
        this.modalService.emitShowDefineNewPassword();
        this.modalService.openModal(null, null, ModalSelectors.CREATE_PROFILE_MODAL);
    }

    showSecurityQuestionForm() {
        this.modalService.emitShowSaveAuthForm();
    }

    showAuthenticatePasswordForm() {
        this.modalService.emitShowAuthenticatePasswordForm();
    }
}
