import {BaseNavComponent} from '../../layout/questionnaire/base-nav.component';
import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {FormsComparisonService} from '../../../components/services/forms-comparison.service';
import {RequestsComparisonResult} from '../../../components/models/requests-comparison-result.model';
import {ModalService} from '../../../components/services/modal.service';
import {RequestsComparisonReport} from '../../../components/models/requests-comparison-report.model';
import {
    FormatToSummaryQuestionPipe
} from '../../../components/pipes/update-acount-delta-review-page/summary-question.pipe';

@Component({
    selector: 'app-review-update-account',
    templateUrl: './review-update-account.component.html'
})
export class ReviewUpdateAccountComponent extends BaseNavComponent implements OnInit {

    formDelta: RequestsComparisonResult[];

    loading = true;
    isSaving = false;
    alreadySubmitError: string = null;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService,
                private formsComparisonService: FormsComparisonService,
                private propertyTranslatePipe: FormatToSummaryQuestionPipe,
                private translateService: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit(): void {
        this.loading = true;
        this.formService.emitIsSaving(true);
        if (!this.formService.isCompleted() && !this.formService.isPrinted()) {
            this.alreadySubmitError = null;
            this.formService.compareSavedForm().subscribe(
                (data: any) => this.onComparisonSuccess(data),
                () => this.onComparisonFailed()
            ).add(
                () => this.onComparisonComplete()
            );
        } else {
            this.alreadySubmitError = 'ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED_COMPARE_SAVED_FORM';
            this.loading = false;
            this.formService.emitIsSaving(false);
        }
    }

    private onComparisonSuccess(data: any) {
        const report: RequestsComparisonReport = data.payload;
        const results: RequestsComparisonResult[] = report.requestsComparisonResults;

        if (results && Object.keys(results).length > 0) {
            this.formsComparisonService.updateComparisonResults$.next(results);
        } else {
            this.formsComparisonService.updateComparisonResults$.next([]);
        }

        this.retrieveFormDifferences();
        this.showPropertyErrorsModal(report.propertyErrors);
    }

    private showPropertyErrorsModal(propertyErrorsReport: string[]) {

        if (propertyErrorsReport && propertyErrorsReport.length > 0) {
            this.modalService.openModal('GLOBAL_WARNING',
                'NOT_STANDARD_DATA_ERROR',
                '#modal-alert-error', null, this.formatPropertyList(propertyErrorsReport));
        }
    }

    private formatPropertyList(propertyErrors: string[]): string {
        return propertyErrors.map(str => this.translateService.instant(this.propertyTranslatePipe.transform(str))).join(', ');
    }

    private onComparisonFailed() {
        this.modalService.openModal('GLOBAL_ERROR',
            'ODC_COMPARISON_FATAL_ERROR',
            '#modal-alert-error', null);
    }

    private onComparisonComplete() {
        this.loading = false;
        this.formService.emitIsSaving(false);
    }

    private retrieveFormDifferences() {
        this.formDelta = this.formsComparisonService.getComparisonResults();
    }
}
