import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, ValidationErrors} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {WebException} from '../../components/models';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {ModalService} from '../../components/services/modal.service';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {PostLoginActionService} from '../../components/services/post-login-action.service';

@Component({
    selector: 'app-transfer-request-choice',
    templateUrl: './transfer-request-choice.component.html'
})
export class TransferRequestChoiceComponent extends BaseNavComponent implements AfterViewInit, OnInit {

    transferTypes: string[];
    submitted = false;
    loading = false;
    isModalActive = false;

    public FIELD_NUMBER_COPY = 'NumberCopy';
    public MAX_NUMBER_COPY = 3;
    public MIN_NUMBER_COPY = 1;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService,
                private postLoginActionService: PostLoginActionService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        if (this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU) {
            this.transferTypes = this.useSwitchCeliApp() ? VmdConstants.TRANSFER_REQUEST_TYPES : this.constants.TRANSFER_REQUEST_TYPES_TOGGLE_CELIAPP_OFF;
        } else if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && !this.isPleinEx()) {
            this.transferTypes = this.constants.JOINT_TRANSFER_REQUEST_TYPES;
        } else if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && this.isGpdOrSfd()) {
            this.transferTypes = this.constants.JOINT_TRANSFER_REQUEST_TYPES_GPD;
        } else if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && this.isPleinEx()) {
            this.transferTypes = this.constants.JOINT_TRANSFER_REQUEST_TYPES_PLEINEX;
        } else {
            this.transferTypes = this.constants.OTHER_TRANSFER_REQUEST_TYPES;
        }

        this.form = this.fb.group({
            hasTransfers: [this.hasTransfersInit(), [this.requiredValidator('hasTransfers')]],
            transferTypes: this.fb.group(this.getTransferTypesControlsConfig(), {validator: this.getTransferTypesControlsValidators(this.useSwitchCeliApp())})
        });

        this.form.get('hasTransfers').valueChanges.subscribe(value => {
            this.initTransferTypesChoice(value);
            this.updateControlEnabled(this.form.get('transferTypes'), value);
            if (value && !this.isPleinEx()) {
                this.isModalActive = true;
                this.modalService.openModal('TRANSFER_REQUEST_CHOICE_MODAL_TITLE',
                    'TRANSFER_REQUEST_CHOICE_CHOICE_MESSAGE',
                    '#modal-alert-choice-transfer-request');
            }
        });

        this.updateControlEnabled(this.form.get('transferTypes'), this.hasTransfersInit());
        this.initTransferTypesChoice(this.form.get('hasTransfers').value);

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe(() => {
            this.isModalActive = false;
        }));

        this.form.get('transferTypes').valueChanges.subscribe(() => {
            this.updateTransferRequestRoutes();
        });
        setTimeout(() => this.updateTransferRequestRoutes(), 0);
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    getAccountKey(transferType: string): string {
        let key: string;
        if (transferType === 'transfer') {
            key = 'CASH';
            if (this.formService.getForm().actType === this.constants.ACCOUNT_TYPE_MARGIN) {
                if (this.formService.getForm().optionAct && this.formService.getForm().shortSellAct) {
                    key = 'MARGIN_OPTION_SHORT_SELL';
                } else if (this.formService.getForm().optionAct) {
                    key = 'MARGIN_OPTION';
                } else if (this.formService.getForm().shortSellAct) {
                    key = 'MARGIN_SHORT_SELL';
                } else {
                    key = 'MARGIN';
                }
            }
        } else if (transferType === 'jointAccount') {
            key = transferType.replace('Transfer', '').toUpperCase();
        } else if (transferType === 'otherTransfer') {
            key = this.formService.getForm().cltType;
        } else {
            key = transferType.replace('Transfer', '').toUpperCase();
        }
        return key;
    }

    getTransferFieldName(): string {
        if (this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU) {
            return 'transferTypes.transfer';
        } else if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT) {
            if (this.isPleinEx()) {
                return 'transferTypes.transfer';
            } else {
                return 'transferTypes.jointTransfer';
            }
        } else {
            return 'transferTypes.otherTransfer';
        }
    }

    getTransferTypesControlsConfig(): { [key: string]: any } {
        const transferTypesControlsConfig: { [key: string]: any } = {};
        for (const name of this.transferTypes) {
            transferTypesControlsConfig[name] = [this.formService.getForm()[name]];

            const nameNumberCopy: string = name + this.FIELD_NUMBER_COPY;
            transferTypesControlsConfig[nameNumberCopy] = [this.formService.getForm()[nameNumberCopy]];
        }

        return transferTypesControlsConfig;
    }

    getTransferTypesControlsValidators(isCeliAppActive: boolean): ValidationErrors | null {

        if (this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU) {

            return VmdValidators.transferRequestChoiceGroupValidator(isCeliAppActive);
        } else if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT) {

            return VmdValidators.jointTransferRequestChoiceGroupValidator(this.transferTypes);
        } else {

            return VmdValidators.otherTransferRequestChoiceGroupValidator;
        }
    }

    hasAccountType(transferType: string): boolean {
        if (transferType === 'transfer' || transferType === 'jointTransfer' || transferType === 'otherTransfer') {
            return true;
        } else {
            const key: string = 'add' + this.getAccountKey(transferType);
            return this.formService.getForm()[key];
        }
    }

    hasTransfersInit() {
        let hasTransfer = null;
        for (const name of this.transferTypes) {
            const value = this.formService.getForm()[name];
            if (value === true) {
                hasTransfer = true;
                break;
            } else if (value === false) {
                hasTransfer = false;
            }
        }
        return hasTransfer;
    }

    initTransferTypesChoice(hasTransfersValue: boolean) {
        const values = {};
        for (const transferType of this.transferTypes) {
            const name: string = 'transferTypes.' + transferType;
            const nameNumberCopy: string = transferType + this.FIELD_NUMBER_COPY;
            if (this.form.get(name) !== null) {
                const value = this.form.get(name).value;

                if (value === true && hasTransfersValue === true) {
                    values[transferType] = true;
                } else if (value === null && hasTransfersValue !== null) {
                    values[transferType] = false;
                } else if (hasTransfersValue === false) {
                    values[transferType] = false;
                    this.setTransferNumberCopyForFieldName(nameNumberCopy, null);
                } else {
                    values[transferType] = value;
                }
            }
            this.formService.setFormProperty(transferType, values[transferType]);
        }
        this.form.get('transferTypes').patchValue(values);
    }

    updateTransferRequestRoutes() {

        for (const name of this.transferTypes) {
            const value = this.form.get('transferTypes.' + name).value;
            if (this.formService.getForm()[name] !== value) {
                this.formService.setFormProperty(name, value);
            }
        }

        this.navigationService.checkTransferRequestNav(this.formService.getForm());
    }

    onSubmitForm() {
        this.error = null;
        let saveForm = false;
        if (this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU && !this.isPleinEx()) {
            saveForm = true;
        }
        this.submitForm(!saveForm);
        if (this.form.valid && saveForm) {

            this.loading = true;
            this.error = null;

            this.formService.saveCompleteForm().subscribe(
                (data: any) => {
                    if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                        this.formService.setSavedEmail(data.payload.courriel);
                        this.formService.setSavedPassword(data.payload.motDePasse);
                        this.formService.emitSaved();
                    }

                    this.formService.setCompleted(true);

                    this.navNext();
                },
                (error: WebException) => {
                    this.postLoginActionService.registerAction(() => this.onSubmitForm());
                    this.onCompleteFormError(error);
                }
            ).add(() => {
                this.loading = false;
                this.formService.emitIsSaving(false);
            });
        }
    }

    hasTransferForType(transferType: string) {

        return this.formService.getForm()[transferType];
    }

    clickTransferType(transferType: string) {

        const fieldName: string = this.getTransferNumberCopyFieldName(transferType);
        const fieldNameValue: boolean = this.form.get('transferTypes').get(transferType).value;

        if (!fieldNameValue) {

            this.setTransferNumberCopyForFieldName(fieldName, this.MIN_NUMBER_COPY);
        } else {

            this.setTransferNumberCopyForFieldName(fieldName, null);
        }
    }

    blurTransferNumberCopy(transferType: string) {

        const fieldName: string = this.getTransferNumberCopyFieldName(transferType);
        const fieldNameValue: number = this.form.get('transferTypes').get(fieldName).value;

        if (!fieldNameValue || fieldNameValue < this.MIN_NUMBER_COPY) {
            this.setTransferNumberCopyForFieldName(fieldName, this.MIN_NUMBER_COPY);
        } else if (fieldNameValue < this.MAX_NUMBER_COPY) {
            this.setTransferNumberCopyForFieldName(fieldName, Math.round(fieldNameValue));
        } else if (fieldNameValue > this.MAX_NUMBER_COPY) {
            this.setTransferNumberCopyForFieldName(fieldName, this.MAX_NUMBER_COPY);
        }
    }

    private getTransferNumberCopyFieldName(transferType: string): string {

        return transferType + this.FIELD_NUMBER_COPY;
    }

    private setTransferNumberCopyForFieldName(fieldName: string, value: number | null): void {

        this.form.get('transferTypes').get(fieldName).patchValue(value);
        this.formService.setFormProperty(fieldName, value);
    }

}
