import {Injectable} from '@angular/core';
import {Functions} from '../utils/functions';

@Injectable()
export class WindowRefService {

    public confirm(message?: string): boolean {
        return this.getNativeWindow().confirm(message);
    }

    public getLocationSearch(): string {
        return this.getNativeWindow().location.search;
    }

    public getNativeWindow(): Window {
        return window;
    }

    public open(url: string, target: string): Window {
        return this.getNativeWindow().open(url, target);
    }

    public getUrlParameter(name): string {
        return Functions.getUrlParameter(name);
    }
}
