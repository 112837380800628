import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {WebException} from '../../components/models';
import {ProfileService} from '../../components/services/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VmdForm} from '../../components/utils/vmd-form';
import {I18N_NAV_MESSAGES_FR} from '../../components/i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../../components/i18n/nav-messages_en';
import {RecaptchaComponent} from './recaptcha.component';

declare let $: any;

@Component({
    selector: 'app-forget-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

    @ViewChild(NgForm) ngForm: NgForm;
    @ViewChild(RecaptchaComponent) recaptchaWidget: RecaptchaComponent;

    form: FormGroup;
    httpErrors: any;
    loading = false;
    constants = VmdConstants;
    emailSent: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private translate: TranslateService,
        private profileService: ProfileService
    ) {
        this.createForm();
    }

    ngOnInit() {

        const routerState = '_routerState';
        const url = this.route[routerState].snapshot.url;
        if (url === '/' + I18N_NAV_MESSAGES_FR.ODC_NAV_FORGOT_PASSWORD_PATH && this.translate.currentLang !== VmdConstants.LANG_FR) {
            this.translate.use(VmdConstants.LANG_FR);
        } else if (url === '/' + I18N_NAV_MESSAGES_EN.ODC_NAV_FORGOT_PASSWORD_PATH && this.translate.currentLang !== VmdConstants.LANG_EN) {
            this.translate.use(VmdConstants.LANG_EN);
        }

        this.enableEmailSection();
    }

    createForm() {
        this.form = this.fb.group({
            email: [null, [Validators.required, VmdValidators.email]]
        });
    }

    private enableEmailSection(): void {
        this.form.get('email').enable();
        this.emailSent = false;
        $('#content').focus();
    }

    private enableConfirmationSection(): void {
        this.form.get('email').disable();
        this.emailSent = true;
    }

    focusOnErrorsList() {
        setTimeout(() => {
            const firstError = ($('#boite-erreurs').first().length > 0) ? $('#boite-erreurs').first() : $('.has-error input, .has-error select').first();
            if (firstError.length > 0) {
                $(firstError).focus();
            }
        }, 0);
    }

    isFieldInError(path: string): boolean {
        return VmdForm.isFieldInError(this.form.get(path), this.isFormSubmitted());
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    onSubmit() {
        this.httpErrors = null;
        VmdForm.markAsTouched(this.form);

        if (this.form.valid) {
            this.onSubmitEmail();
        } else {
            this.focusOnErrorsList();
        }
    }

    onSubmitEmail() {
        this.loading = true;

        this.profileService.forgotPassword(
            this.translate.currentLang,
            this.form.get('email').value,
            this.recaptchaWidget.getRecaptchaToken()
        ).subscribe(() => {
                this.enableConfirmationSection();
            },
            (error: WebException) => {
                this.httpErrors = error;
            })
            .add(() => {
                this.loading = false;
                this.resetRecaptcha();
            });
    }

    showRecaptcha() {
        return this.form.get('email').enabled;
    }

    resetRecaptcha() {
        this.recaptchaWidget.reset();
    }
}
