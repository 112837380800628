import {Component, Inject, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {FormService} from '../../../../components/services/form.service';
import {IOdcConfig, ODC_CONFIG} from '../../../../odc-config';

@Component({
    selector: 'app-province-resident-question',
    templateUrl: './province-resident-question.component.html'
})
export class ProvinceResidentQuestionComponent {

    public constants = VmdConstants;
    @Input() form: FormGroup;
    @Input() submitted: boolean;

    isPleinExRequest: boolean = false;

    constructor(
        @Inject(ODC_CONFIG) protected config: IOdcConfig,
        private fb: FormBuilder,
        protected formService: FormService) {
    }

    ngOnInit(): void {

        this.isPleinExRequest = this.formService.getForm().pleinEx;

        if (this.config.TOGGLE_PDF_LANG && !this.isPleinExRequest) {
            this.form.addControl('resQc', this.fb.control(this.formService.getForm().resQc, Validators.required));
        }
    }
}
