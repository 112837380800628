<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>{{ 'ODC_STEP_PREPARATION_CLIENT_TYPE_TITLE' | translate }}</h2>

    <fieldset class="form-group" [class.has-error]="isFormSubmitted() && form.get('clientType').hasError('required')">
        <legend class="sr-only">{{ 'ODC_STEP_PREPARATION_CLIENT_TYPE_TITLE' | translate }}</legend>
        <div *ngIf="isFormSubmitted() && form.get('clientType').hasError('required')">
            <span class="help-block">{{'GLOBAL_ERROR_CHOOSE_AN_OPTION'|translate}}</span>
        </div>
        <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
            <div class="panel-body">
                <div class="panel-content" id="panel-radio-client-personal">
                    <label class="panel-label" for="client-personal">{{
                        'ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT' | translate }}</label>
                    <p>
                        <i class="b-c-h-icon icon-disnat2 icon-user-line" aria-hidden="true"></i>
                        {{ 'ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_DESC' | translate }}
                    </p>
                </div>
                <input class="panel-input" type="radio" id="client-personal" name="client-type"
                       [value]="constants.CLT_TYPE_INDIVIDU"
                       (click)="personalAccountChoice()"
                       aria-describedby="panel-radio-client-personal">
                <div class="panel-check">
                    <div class="c-input c-radio">
                        <span class="c-indicator"></span>
                    </div>
                </div>
                <div class="panel-active"></div>
                <div class="panel-focus"></div>
            </div>
        </div>

        <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
            <div class="panel-body">
                <div class="panel-content" id="panel-radio-client-joint">
                    <label class="panel-label" for="client-joint">{{
                        'ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT' | translate }}</label>
                    <p>
                        <i class="b-c-h-icon icon-disnat2 icon-users-line" aria-hidden="true"></i>
                        {{ 'ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT_DESC' | translate }}
                    </p>
                </div>
                <input class="panel-input" type="radio" id="client-joint" name="client-type"
                       [value]="constants.CLT_TYPE_CONJOINT"
                       (click)="jointAccountChoice()"
                       aria-describedby="panel-radio-client-joint">
                <div class="panel-check">
                    <div class="c-input c-radio">
                        <span class="c-indicator"></span>
                    </div>
                </div>
                <div class="panel-active"></div>
                <div class="panel-focus"></div>
            </div>
        </div>

        <div class="panel panel-simple panel-radio panel-horizontal panel-alignleft">
            <div class="panel-body">
                <input class="panel-input" type="radio" id="client-other" name="client-type"
                       [value]="constants.CLT_TYPE_OTHER"
                       (click)="otherAccountChoice()"
                       aria-describedby="panel-radio-client-other"
                       [class.panel-input-with-field]="form.get('clientType').value === constants.CLT_TYPE_OTHER"
                >
                <div class="panel-check">
                    <div class="c-input c-radio">
                        <span class="c-indicator"></span>
                    </div>
                </div>
                <div class="panel-active"></div>
                <div class="panel-focus"></div>
                <div class="panel-content" id="panel-radio-client-other">
                    <label class="panel-label" for="client-other">{{
                        'ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT' | translate }}</label>
                    <p>
                        <i class="b-c-h-icon icon-disnat2 icon-group-line" aria-hidden="true"></i>
                        {{ 'ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT_DESC' | translate }}
                    </p>
                    <div *ngIf="form.get('clientType').value === constants.CLT_TYPE_OTHER"
                         class="form-group" formGroupName="otherAccount"
                         [class.has-error]="isFormSubmitted() && form.get('otherAccount.otherAccountType').touched && form.get('otherAccount.otherAccountType').hasError('required')">
                        <label class="sr-only" for="other-account-type">{{'GLOBAL_SELECT'|translate}}</label>
                        <div *ngIf="isFormSubmitted() && form.get('otherAccount.otherAccountType').touched && form.get('otherAccount.otherAccountType').hasError('required')">
                            <span class="help-block">{{'GLOBAL_ERROR_SELECT'|translate}}</span>
                        </div>
                        <select (change)="otherAccountItemChoice()" id="other-account-type" class="form-control" formControlName="otherAccountType" aria-describedby="other-account-type-help-block">
                            <option [ngValue]="null">{{'GLOBAL_SELECT'|translate}}</option>
                            <option *ngFor="let item of otherAccountList" [value]="item.itemValue">{{item.itemLabel}}</option>
                        </select>
                        <p class="petit" id="other-account-type-help-block">{{'OTHER_ACCOUNT_ONLY_CANADIAN_ENTITIES' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="isLoading"></app-questionnaire-btn>
</form>

