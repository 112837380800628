<ng-container *ngIf="!!messageKey">
    <!-- Composante N3 https://ux-documentation.apps.cfzcea.dev.desjardins.com/documentation/composantes.html#tiroirs_arbre -->
    <div class="panel-group arbre">
        <div class="panel panel-tiroir">
            <div class="panel-heading collapsed" role="tab">
                <h3 class="panel-title">
                    <a aria-controls="collapseA4" aria-expanded="false" aria-expanded="false" data-toggle="collapse"
                       href="#collapseA4" role="button">
                        {{ 'BTN_HELP_WARNING' | translate }}
                    </a>
                </h3>
            </div>
            <div aria-labelledby="collapseA4" class="panel-collapse collapse" id="collapseA4" role="tabpanel">
                <div class="panel-body">
                    <p [innerHTML]="messageKey | translate"></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
