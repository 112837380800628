import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';

@Component({
  selector: 'app-review-other-account',
  templateUrl: './review-other-account.component.html'
})
export class ReviewOtherAccountComponent extends BaseNavComponent implements OnInit {

  ngOnInit() {

  }

}
