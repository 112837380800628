<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_GOUV_ORG_IS_IT_A_CITY' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'gouvOrgCity'"
        [name]="'gouvOrgCity'"
        [path]="'gouvOrgCity'"
        [binding]="'otherGouvOrgCity'"
    ></app-odc-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
