import {Component, OnInit} from '@angular/core';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';

@Component({
    template: '',
})
export class BaseAddSpouseRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    public fieldPrefix: string;

    ngOnInit() {
        this.fieldPrefix = this.getFieldPrefix();

        this.form = this.fb.group(this.getFormControlsConfig());

        if (this.isPleinEx()) {

            this.form.get(this.getFieldPrefix() + 'AddSpouse').valueChanges.subscribe((value) => {
                this.formService.setFormProperty('otherAddSpouse', value);
                if (!value) {
                    this.form.get(this.getFieldPrefix() + 'ResQc').setValue(null);
                    this.formService.setFormProperty('resQc', null);
                    this.form.get(this.getFieldPrefix() + 'SurvivorShip').setValue(null);
                    this.formService.setFormProperty('survivorShip', null);
                }
                this.updateResQcAndSurvivorShipControl();
            });

            this.form.get(this.getFieldPrefix() + 'ResQc').valueChanges.subscribe((value) => {
                if (value) {
                    this.form.get(this.getFieldPrefix() + 'SurvivorShip').setValue(null);
                    this.formService.setFormProperty('survivorShip', null);
                }
                this.updateResQcAndSurvivorShipControl();
            });

            this.updateResQcAndSurvivorShipControl();
        }
    }

    private getFormControlsConfig(): { [key: string]: any } {
        const controlsConfig: { [key: string]: any } = {};

        controlsConfig[this.getFieldPrefix() + 'AddSpouse'] = [this.formService.getForm().otherAddSpouse, [this.requiredValidator('otherAddSpouse')]];
        if (this.isPleinEx()) {
            controlsConfig[this.getFieldPrefix() + 'ResQc'] = [this.formService.getForm().resQc, [this.requiredValidator('resQc')]];
            controlsConfig[this.getFieldPrefix() + 'SurvivorShip'] = [this.formService.getForm().survivorShip, [this.requiredValidator('survivorShip')]];
        }

        return controlsConfig;
    }

    public getPrefixedLabelKey(begin: string, end: string): string {
        return begin + '_' + this.getFieldPrefix().toUpperCase() + '_' + end;
    }

    public getFieldPrefix(): string {
        return null;
    }

    showResQc(): boolean {
        return this.isPleinEx() && this.form.get(this.getFieldPrefix() + 'AddSpouse').value;
    }

    showSurvivorShip(): boolean {
        return this.showResQc() && this.form.get(this.getFieldPrefix() + 'ResQc').value === false;
    }

    updateResQcAndSurvivorShipControl(): void {
        this.updateControlEnabled(this.form.get(this.getFieldPrefix() + 'ResQc'), this.showResQc());
        this.updateControlEnabled(this.form.get(this.getFieldPrefix() + 'SurvivorShip'), this.showSurvivorShip());
    }
}
