<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>
        {{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div *ngIf="hasActPeople()">
        <h4 *ngIf="isAnAssociate()">{{'ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_TITLE'| translate}}</h4>
        <h4 *ngIf="isAClubPerson()">{{'ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_TITLE'| translate}}</h4>
        <h4 *ngIf="isSenc()">{{'ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_TITLE'| translate}}</h4>
        <h4 *ngIf="isSocComm()">{{'ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_TITLE'| translate}}</h4>

        <!--ActPeople-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'ACT_AMERICAN_PEOPLE') | translate"
            [id]="fieldPrefix + 'ActAmericanPeople'"
            [name]="fieldPrefix + 'ActAmericanPeople'"
            [path]="fieldPrefix + 'ActAmericanPeople'"
            [binding]="'actAmericanPeople'"
            [integerFormat]="true"
            [attr]="{maxlength: 2}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'ACT_PEOPLE') | translate"
            [id]="fieldPrefix + 'ActPeople'"
            [name]="fieldPrefix + 'ActPeople'"
            [path]="fieldPrefix + 'ActPeople'"
            [binding]="'otherActPeople'"
            [integerFormat]="true"
            [attr]="{maxlength: 2}"
        ></app-odc-field>
    </div>


    <!--OneShareholder / Corporation-->
    <div *ngIf="hasShareholders()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'ONE_SHAREHOLDER') | translate"
            [id]="fieldPrefix + 'OneShareholder'"
            [name]="fieldPrefix + 'OneShareholder'"
            [controlName]="fieldPrefix + 'OneShareholder'"
            [path]="fieldPrefix + 'OneShareholder'"
            [binding]="'otherOneShareholder'"
            [list]="[{itemValue: 'UNIQUE', itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_ONE_SHAREHOLDER'|translate}, {itemValue: 'MANY', itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_MANY_SHAREHOLDERS'|translate}]"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        ></app-odc-field>
    </div>


    <!--HoldingAccount-->
    <div *ngIf="!isPleinEx()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'HOLDING_ACCOUNT') | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="fieldPrefix + 'HoldingAccount'"
            [name]="fieldPrefix + 'HoldingAccount'"
            [path]="fieldPrefix + 'HoldingAccount'"
            [binding]="'otherHoldingAccount'"
        ></app-odc-field>
    </div>

    <!--LinkedAccount-->
    <div *ngIf="form.get(fieldPrefix + 'HoldingAccount').value === false && !isPleinEx()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'LINKED_ACCOUNT') | translate"
            [id]="fieldPrefix + 'LinkedAccount'"
            [name]="fieldPrefix + 'LinkedAccount'"
            [controlName]="fieldPrefix + 'LinkedAccount'"
            [path]="fieldPrefix + 'LinkedAccount'"
            [binding]="'otherLinkedAccount'"
            [list]="[
                        {itemValue: constants.OTHER_LINKED_ACCOUNT_PERSONAL, itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_PERSONAL' | translate},
                        {itemValue: constants.OTHER_LINKED_ACCOUNT_JOINT, itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_JOINT' | translate},
                        {itemValue: constants.OTHER_LINKED_ACCOUNT_NONE, itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_NONE' | translate}
                    ]"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        ></app-odc-field>
    </div>

    <app-province-resident-question [form]="form" [submitted]="formSubmitted"></app-province-resident-question>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>
