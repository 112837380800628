export class ProfileBean {

    email: string;
    password: string;
    confirmPassword: string;
    temporaryPassword: string;
    securityQuestion: string;
    securityAnswer: string;
    isTemporaryPassword: boolean;
    lang: string;
    reCaptchaResponse: string;

    constructor(obj?: any) {
        this.email = obj && obj.email || null;
        this.password = obj && obj.password || null;
        this.confirmPassword = obj && obj.confirmPassword || null;
        this.securityQuestion = obj && obj.securityQuestion || null;
        this.securityAnswer = obj && obj.securityAnswer || null;
        this.temporaryPassword = obj && obj.temporaryPassword || null;
        this.isTemporaryPassword = obj && obj.isTemporaryPassword || null;
        this.lang = obj && obj.lang || null;
    }
}
