import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-beneficary',
  templateUrl: './review-beneficiary.component.html'
})
export class ReviewBeneficiaryComponent implements OnInit {

    @Input() label: string;
    @Input() index: number;

    @Input() name: string;
    @Input() dob: string;
    @Input() sin: string;
    @Input() address: string;
    @Input() link: string;
    @Input() part: string;

  ngOnInit() {

  }
}
