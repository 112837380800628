<form [formGroup]="form" ngForm novalidate (submit)="submit()">
    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>
    <ng-content *ngIf="isGpdOrSfd(); then shareholder_gpd else shareholder"></ng-content>
    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #shareholder>
    <div *ngIf="isUpdateAccount() && isLegalShareHolderMsgWarning()" class="m-b-2" tabindex="0"
         id="legalShareHolderMsgWarning">
        <strong>
            {{'ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING_TITLE'|translate}}
        </strong>
        <br>
        <p>
            {{'ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING'|translate}}
        </p>
    </div>
    <h2>
        <span class="b-c-h-icon"></span>
        {{'ODC_STEP_LEGAL_SHAREHOLDER_TITLE'|translate}}
    </h2>

    <div *ngIf="!isPleinEx()" class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_DESC'|translate}}
    </div>

    <div *ngIf="isPleinEx()" class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_DESC_PLEIN_EX'|translate}}
    </div>
    <app-odc-field *ngIf="!isPleinEx()"
                   [type]="constants.FIELD_TYPES.RADIO"
                   [form]="form"
                   [submitted]="isFormSubmitted()"
                   [label]="('ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_QUESTION'|translate)"
                   [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT' | translate, value: true}, {label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT' | translate, value: false}]"
                   [stacked]="true"
                   [id]="'userDisclaimerIndicator'"
                   [name]="'userDisclaimerIndicator'"
                   [path]="'userDisclaimerIndicator'"
                   [binding]="getRequesterPath('userDisclaimerIndicator')"
    ></app-odc-field>

    <div class="m-b-2" tabindex="0" *ngIf="!isPleinEx()">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC'|translate}}
    </div>

    <app-odc-field *ngIf="isPleinEx()"
                   [type]="constants.FIELD_TYPES.RADIO"
                   [form]="form"
                   [submitted]="isFormSubmitted()"
                   [label]="('ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_QUESTION'|translate)"
                   [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT' | translate, value: true}, {label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT' | translate, value: false}]"
                   [stacked]="true"
                   [id]="'userDisclaimerIndicator'"
                   [name]="'userDisclaimerIndicator'"
                   [path]="'userDisclaimerIndicator'"
                   [binding]="getRequesterPath('userDisclaimerIndicator')"
    ></app-odc-field>

    <div class="m-b-2" tabindex="0" *ngIf="isPleinEx()">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_PLEIN_EX'|translate}}
    </div>

    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_QUESTION' | translate"
        [choices]="[
            {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1' | translate, value: 'ALL', helpRefFr: helpDocFr, helpRefEn: helpDocEn},
            {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2' | translate, value: 'NONE', helpRefFr: helpDocFr, helpRefEn: helpDocEn},
            {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3' | translate, value: 'ONLY'}]"
        [stacked]="true"
        [id]="'userReceiptDocumentSecuritiesHolderIndicator'"
        [name]="'userReceiptDocumentSecuritiesHolderIndicator'"
        [path]="'userReceiptDocumentSecuritiesHolderIndicator'"
        [binding]="getRequesterPath('userReceiptDocumentSecuritiesHolderIndicator')"
        [templateRefFr]="helpInfoFr" [templateRefEn]="helpInfoEn"
    ></app-odc-field>

    <div class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_DESC'|translate}}
    </div>

    <div class="m-b-1" tabindex="0">
        <b>{{'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_QUESTION' | translate}}</b>
    </div>

    <div class="m-b-1" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_DESC' | translate}}
    </div>

    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE1' | translate, value: true}, {label: 'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE2' | translate, value: false}]"
        [stacked]="true"
        [id]="'userElectronicDocumentSecuritiesHolderIndicator'"
        [name]="'userElectronicDocumentSecuritiesHolderIndicator'"
        [path]="'userElectronicDocumentSecuritiesHolderIndicator'"
        [binding]="getRequesterPath('userElectronicDocumentSecuritiesHolderIndicator')"
    ></app-odc-field>
</ng-template>

<ng-template #shareholder_gpd>
    <h2>
        <span class="b-c-h-icon"></span>
        {{'ODC_STEP_LEGAL_SHAREHOLDER_TITLE_GPD' | translate}}
    </h2>

    <div class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_1' | translate}}
    </div>

    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_LABEL' | translate"
        [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT_SHORT' | translate, value: true},
                    {label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT_SHORT' | translate, value: false}]"
        [id]="'authorizeClientInfoPresence'"
        [name]="'authorizeClientInfoPresence'"
        [path]="'authorizeClientInfoPresence'"
        [binding]="getRequesterPath('authorizeClientInfoPresence')"
    ></app-odc-field>

    <div class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_2' | translate}}
    </div>

    <div class="m-b-2" tabindex="0">
        {{'ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_3' | translate}}
    </div>

    <div *ngIf="isEligibleToShowCommunicationBeneficialOwnersSection()">
        <h2>
            <span class="b-c-h-icon"></span>
            {{'ODC_STEP_LEGAL_SHAREHOLDER_TITLE_Communication_GPD' | translate}}
        </h2>

        <div class="m-b-2" tabindex="0">
            {{'ODC_STEP_LEGAL_SHAREHOLDER_DESC_GPD' | translate}}
        </div>

        <div id="panelDefault">
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_QUESTION' | translate"
                [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT' | translate, value: true},
                                {label: 'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT' | translate, value: false}]"
                [stacked]="true"
                [id]="'userDisclaimerIndicator'"
                [name]="'userDisclaimerIndicator'"
                [path]="'userDisclaimerIndicator'"
                [binding]="getRequesterPath('userDisclaimerIndicator')"
            ></app-odc-field>

            <div class="m-b-1" tabindex="0">
                {{'ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_GPD_1' | translate}}
            </div>

            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_QUESTION' | translate"
                [choices]="[
                        {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1' | translate, value: 'ALL', helpRefFr: helpDocFr, helpRefEn: helpDocEn},
                        {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2' | translate, value: 'NONE', helpRefFr: helpDocFr, helpRefEn: helpDocEn},
                        {label: 'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3' | translate, value: 'ONLY'}]"
                [stacked]="true"
                [id]="'userReceiptDocumentSecuritiesHolderIndicator'"
                [name]="'userReceiptDocumentSecuritiesHolderIndicator'"
                [path]="'userReceiptDocumentSecuritiesHolderIndicator'"
                [binding]="getRequesterPath('userReceiptDocumentSecuritiesHolderIndicator')"
                [templateRefFr]="helpInfoFr" [templateRefEn]="helpInfoEn"
            ></app-odc-field>

            <div class="m-b-2" tabindex="0">
                {{'ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_DESC'|translate}}
            </div>

            <div class="m-b-1" tabindex="0">
                <b>{{'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_QUESTION' | translate}}</b>
            </div>

            <div class="m-b-1" tabindex="0">
                {{'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_DESC' | translate}}
            </div>

            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [choices]="[{label: 'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE1' | translate, value: true}, {label: 'ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE2' | translate, value: false}]"
                [stacked]="true"
                [id]="'userElectronicDocumentSecuritiesHolderIndicator'"
                [name]="'userElectronicDocumentSecuritiesHolderIndicator'"
                [path]="'userElectronicDocumentSecuritiesHolderIndicator'"
                [binding]="getRequesterPath('userElectronicDocumentSecuritiesHolderIndicator')"
            ></app-odc-field>
        </div>
    </div>

</ng-template>

<ng-template #helpInfoFr>

    <h2>Information supplémentaire sur la réception des documents par les porteurs de titres :</h2>
    <p>Les instructions données à la partie 2 ne s'appliquent à aucune demande particulière que vous présentez ou avez
        présentée à un émetteur assujetti concernant l'envoi de ses états financiers intermédiaires. De plus, dans
        certaines circonstances, les instructions que vous fournirez dans le formulaire de réponse du client ne
        s'appliqueront pas aux rapports annuels ni aux états financiers d'un fonds d'investissement qui ne font pas
        partie des documents reliés aux procurations. Un fonds d'investissement est également autorisé à obtenir de vous
        des instructions expresses sur l'envoi de ses rapports annuels et états financiers, et si vous lui en donnez,
        les instructions fournies dans le présent formulaire sur les états financiers ne s'appliqueront pas.</p>
</ng-template>
<ng-template #helpDocFr>
    <h2>Les documents pour porteurs de titres sont :</h2>
    <ul>
        <li>les documents reliés aux procurations pour les assemblées annuelles et extraordinaires;</li>
        <li>les états financiers et les rapports annuels qui ne font pas partie des documents reliés aux procurations;
        </li>
        <li>les documents transmis aux porteurs de titres, mais dont le droit des sociétés ou les lois applicables au
            domaine des valeurs mobilières n'exigent pas l'envoi.
        </li>
    </ul>
</ng-template>
<ng-template #helpInfoEn>

    <h2>Additional information on receipt of documents by security holders:</h2>
    <p>Instructions provided in Part 2 do not apply to any individual request you present or have presented to a
        reporting issuer concerning the sending of interim financial statements. Moreover, in some circumstances,
        instructions you provide on the customer response form will not apply to the annual reports or to the financial
        statements of an investment fund if they are not part of proxy-related documents. An investment fund is also
        authorized to obtain express instructions from you on the sending of its annual reports and financial
        statements; if you provide such instructions, the instructions given on this form regarding financial statements
        will not apply.</p>
</ng-template>
<ng-template #helpDocEn>
    <h2>The documents for security holders are:</h2>
    <ul>
        <li>proxy-related documents for annual or extraordinary meetings;</li>
        <li>financial statements and annual reports that are not part of proxy-related materials;</li>
        <li>materials sent to security holders that applicable corporate regulations or securities legislation do not
            require be sent.
        </li>
    </ul>
</ng-template>
