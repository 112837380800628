import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {ODCForm} from '../../components/models/ODCForm';
import {AccountTransferRequest} from '../../components/models/accountTransferRequest';
import {FormBuilder} from '@angular/forms';
import {VmdFormat} from '../../components/formats/vmd-format';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';

import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';

@Component({
    selector: 'app-review-personal-account',
    templateUrl: './review-personal-account.component.html'
})
export class ReviewPersonalAccountComponent extends BaseNavComponent implements OnInit {

    public ODCForm: ODCForm;

    private provinces: HtmlSelectKV[] = [];
    private countries: HtmlSelectKV[] = [];
    private nifReasons: HtmlSelectKV[] = [];
    private jurisdictions: HtmlSelectKV[] = [];
    private jobStatusList: HtmlSelectKV[] = [];
    private isBrokerList: HtmlSelectKV[] = [];
    private employerActivityList: HtmlSelectKV[] = [];
    private userMaritalStatusList: HtmlSelectKV[] = [];
    private userMaritalStatusListRrspAnnuitantSpouse: HtmlSelectKV[] = [];
    private parentLinks: HtmlSelectKV[] = [];
    private parentLinksFhsa: HtmlSelectKV[] = [];
    private relationList: HtmlSelectKV[] = [];
    private bankList: HtmlSelectKV[] = [];
    private brokerageOtherAccountsAccountTypeList: HtmlSelectKV[];
    private brokeragePurposeList: HtmlSelectKV[];
    private userBrokerageKnowledgeList: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.ODCForm = this.formService.getForm();
        this.listModelService.getListModel(this.getRequesterProperty('userAddressCountry') === this.constants.COUNTRIES.UNITED_STATES ?
            'statesList' : 'provincesList', data => this.provinces = data);
        this.listModelService.getListModel('paysList', data => this.countries = data);
        this.listModelService.getListModel('statusNoNifList', data => this.nifReasons = data);
        this.listModelService.getListModel('jurisdictionsList', data => this.jurisdictions = data);
        this.listModelService.getListModel('statutEmploiList', data => this.jobStatusList = data);
        this.listModelService.getListModel('userEmployerIsBrokerList', data => this.isBrokerList = data);
        this.listModelService.getListModel('userEmployerActivityList', data => this.employerActivityList = data);
        this.listModelService.getListModel('listEtatCivil', data => this.userMaritalStatusList = data);
        this.listModelService.getListModel(this.getCivilStatusListByOrigin(), data => this.userMaritalStatusListRrspAnnuitantSpouse = data);
        this.listModelService.getListModel('beneficiaryRelationList', data => this.parentLinks = data);
        this.listModelService.getListModel('beneficiaryCeliappRelationList', data => this.parentLinksFhsa = data);
        this.listModelService.getListModel('attorneyRelationList', data => this.relationList = data);
        this.listModelService.getListModel('bankIdList', data => this.bankList = data);
        this.listModelService.getListModel('brokerageAccountList', data => this.brokerageOtherAccountsAccountTypeList = data);
        this.listModelService.getListModel('brokeragePurposeList', data => this.brokeragePurposeList = data);
        this.listModelService.getListModel('brokerageKnowledgeList', data => this.userBrokerageKnowledgeList = data);
    }

    getCivilStatusListByOrigin(): string {
        if (this.formService.isCaisseContext()) {

            return 'listEtatCivil';
        }
        return 'listEtatCivilRrspAnnuitant';
    }

    public getRequesterProperty(name: string, index: number = 0): any {
        if (this.ODCForm.requesters && this.ODCForm.requesters.length >= index) {
            return this.ODCForm.requesters[index][name];
        }
        return null;
    }

    public getAccountTypeValue(): string[] {

        const accountTypeValues: string[] = [this.translation('ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT')];

        if (this.ODCForm.actType === this.constants.ACCOUNT_TYPE_CASH) {

            accountTypeValues.push(this.translation('CASH_ACCOUNT'));
        } else {

            accountTypeValues.push(this.translation('MARGIN_ACCOUNT'));
        }

        if (this.ODCForm.optionAct) {

            accountTypeValues.push(this.translation('WITH_OPTIONS'));
        }

        if (this.ODCForm.shortSellAct) {

            accountTypeValues.push(this.translation('WITH_SHORT_SELLING'));
        }

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.currency));

        return accountTypeValues;
    }

    public getAccountTypeRRSPValue(): string[] {

        const accountTypeValues: string[] = [this.translation('RRSP_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.rrspCurrency));

        if (this.ODCForm.rrspAnnuitant === this.constants.ANNUITANT_SPOUSE) {

            accountTypeValues.push(this.translation('REVIEW_ANNUITANT_SPOUSAL'));
        } else {

            accountTypeValues.push(this.translation('REVIEW_ANNUITANT_PERSONAL'));
        }

        return accountTypeValues;
    }

    public getAccountTypeTFSAValue(): string[] {

        const accountTypeValues: string[] = [this.translation('TFSA_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.tfsaCurrency));

        return accountTypeValues;
    }

    public getAccountTypeFHSAValue(): string[] {

        const accountTypeValues: string[] = [this.translation('FHSA_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.fhsaCurrency));
        accountTypeValues.push(this.translation('FHSA_ACCOUNT_ELIGIBILITY'));

        return accountTypeValues;
    }

    public getAccountTypeRESPValue(): string[] {

        const accountTypeValues: string[] = [this.translation('ACCOUNT_TYPE_LABEL_RESP')];
        accountTypeValues.push(this.translation('ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_OPTION_' + this.ODCForm.respType));

        if (this.constants.RESP_TYPE_FAMILY === this.ODCForm.respType) {

            accountTypeValues.push(this.ODCForm.respNumberOfBeneficiaries + ' ' + this.translation('REVIEW_RESP_BENEFICIARY'));
        }

        if (this.ODCForm.respIsSubscriberResponsible) {

            accountTypeValues.push(this.translation('REVIEW_RESP_SUBSCRIBER_RESPONSIBLE'));
        } else {

            accountTypeValues.push(this.translation('REVIEW_RESP_SUBSCRIBER_NO_RESPONSIBLE'));
        }

        return accountTypeValues;
    }

    public getAccountTypeLIRAValue(): string[] {

        const accountTypeValues: string[] = [this.translation('LIRA_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.liraCurrency));

        const juridiction: string = ListModelService.getLabelFromList(this.jurisdictions, this.ODCForm.liraJurisdiction);
        accountTypeValues.push(this.translation('REVIEW_JURIDICTION') + ' ' + juridiction);

        return accountTypeValues;
    }

    public getAccountTypeRIFFValue(): string[] {

        const accountTypeValues: string[] = [this.translation('RRIF_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.rrifCurrency));

        return accountTypeValues;
    }

    public getAccountTypeLIFValue(): string[] {

        const accountTypeValues: string[] = [this.translation('LIF_ACCOUNT')];

        accountTypeValues.push(this.getCurrencyValue(this.ODCForm.lifCurrency));

        const juridiction: string = ListModelService.getLabelFromList(this.jurisdictions, this.ODCForm.lifJurisdiction);
        accountTypeValues.push(this.translation('REVIEW_JURIDICTION') + ' ' + juridiction);

        return accountTypeValues;
    }

    public getCitizenshipValue(): string {

        let countries = '';

        if (this.getRequesterProperty('userCitizenship')) {
            countries += this.translation('REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA');

            if (this.getRequesterProperty('addUserOtherCitizenship') || this.getRequesterProperty('userCitizenshipUS')) {
                countries += ' ' + this.translation('GLOBAL_AND') + ' ';
            }
        }

        if (this.getRequesterProperty('userCitizenshipUS')) {
            countries += this.translation('REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES');

            if (this.getRequesterProperty('addUserOtherCitizenship')) {
                countries += ' ' + this.translation('GLOBAL_AND') + ' ';
            }
        }

        if (this.getRequesterProperty('addUserOtherCitizenship')) {
            countries += ListModelService.getLabelFromList(this.countries, this.getRequesterProperty('userOtherCitizenship'));
        }

        return countries;
    }

    public getTransferResp(): AccountTransferRequest {

        const respTransfer = new AccountTransferRequest(null);

        respTransfer.actType = 'RESP';
        respTransfer.actTypeCurrency = 'CAD';

        return respTransfer;
    }

    public getResidentUSAValue(): string {

        if (this.getRequesterProperty('usaNif')) {

            return this.translation('REVIEW_NIF') + ' ' + this.getRequesterProperty('usaNif');
        }

        if (this.getRequesterProperty('userNifMissingReason') === 'OTHER_REASON') {

            return this.translation('REVIEW_OTHER_REASON_NIF');
        }

        return ListModelService.getLabelFromList(this.nifReasons, this.getRequesterProperty('userNifMissingReason'));
    }

    public getResidentOtherValue1(): string[] {

        const values: string[] = [ListModelService.getLabelFromList(this.countries, this.getRequesterProperty('userCountryNif2'))];

        if (this.getRequesterProperty('codePremierAutreNif')) {

            values.push(this.translation('REVIEW_NIF') + ' ' + this.getRequesterProperty('codePremierAutreNif'));
        } else if (this.getRequesterProperty('userNifMissingReason2') === 'OTHER_REASON') {

            values.push(this.translation('REVIEW_OTHER_REASON_NIF'));
        } else {

            values.push(ListModelService.getLabelFromList(this.nifReasons, this.getRequesterProperty('userNifMissingReason2')));
        }

        return values;
    }

    public getResidentOtherValue2(): string[] {

        const values: string[] = [ListModelService.getLabelFromList(this.countries, this.getRequesterProperty('userCountryNif3'))];

        if (this.getRequesterProperty('codeSecondAutreNif')) {

            values.push(this.translation('REVIEW_NIF') + ' ' + this.getRequesterProperty('codeSecondAutreNif'));
        } else if (this.getRequesterProperty('userNifMissingReason3') === 'OTHER_REASON') {

            values.push(this.translation('REVIEW_OTHER_REASON_NIF'));
        } else {

            values.push(ListModelService.getLabelFromList(this.nifReasons, this.getRequesterProperty('userNifMissingReason3')));
        }

        return values;
    }

    public getOptionAccountExperience(): string[] {

        const values: string[] = [];

        if (this.ODCForm.optionActTradingExperienceOperationLevel1) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1'));
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel2) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2'));
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel3) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3'));
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel4) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4'));
        }

        if (values.length === 0) {

            values.push(this.translation('GLOBAL_NONE'));
        }

        return values;
    }

    public getOptionAccountAnticipation(): string[] {

        const values: string[] = [];

        if (this.ODCForm.optionActTradingPlannedOperationLevel1) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1'));
        }

        if (this.ODCForm.optionActTradingPlannedOperationLevel2) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2'));
        }

        if (this.ODCForm.optionActTradingPlannedOperationLevel3) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3'));
        }

        if (this.ODCForm.optionActTradingPlannedOperationLevel4) {

            values.push(this.translation('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4'));
        }

        if (values.length === 0) {

            values.push(this.translation('GLOBAL_NONE'));
        }

        return values;
    }

    public getOptionAccountExperienceYears(): string[] {

        const values: string[] = [];

        if (this.ODCForm.optionActTradingExperienceOperationLevel1) {

            values.push(this.ODCForm.optionActTradingExperienceYears1);
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel2) {

            values.push(this.ODCForm.optionActTradingExperienceYears2);
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel3) {

            values.push(this.ODCForm.optionActTradingExperienceYears3);
        }

        if (this.ODCForm.optionActTradingExperienceOperationLevel4) {

            values.push(this.ODCForm.optionActTradingExperienceYears4);
        }

        if (values.length === 0) {

            values.push(this.translation('GLOBAL_NONE'));
        }

        return values;
    }

    public hasTransfers(): boolean {

        return this.ODCForm.transfer
            || this.ODCForm.rrspTransfer
            || this.ODCForm.tfsaTransfer
            || this.ODCForm.fhsaTransfer
            || this.ODCForm.respTransfer
            || this.ODCForm.liraTransfer
            || this.ODCForm.rrifTransfer
            || this.ODCForm.lifTransfer;
    }

    public getNameValueWithSex(gender: string, firstName: string, lastName: string): string {

        return this.getGenderValue(gender) + ' ' + firstName + ' ' + lastName;
    }

    public getNameValue(firstName: string, lastName: string): string {

        return firstName + ' ' + lastName;
    }

    public getDOBValue(value: string): string {

        return VmdFormat.date.formatAsDate(value);
    }

    public getSINValue(value: string): string {

        return VmdFormat.sin.formatAsSIN(value);
    }

    public getPhoneValue(value: string): string {

        return VmdFormat.phone.formatAsPhone(value);
    }

    public getMoneyValue(value: string): string {

        return VmdFormat.numeric.formatAsCurrency(value);
    }

    public getBooleanValue(value: boolean): string {

        return value ? this.translation('GLOBAL_YES') : this.translation('GLOBAL_NO');
    }

    public getAddressValue(unit: string, street: string, city: string, prov: string, postalCode: string): string {

        return (unit ? unit : '') + ' ' + street + ', ' + city + ', ' + this.getProvince(prov) + ', ' + VmdFormat.zipCode.formatAsZipCode(postalCode);
    }

    public getProvince(prov: string) {
        return ListModelService.getLabelFromList(this.provinces, prov);
    }

    public isEmployed(jobStatus: string): boolean {

        return jobStatus === this.constants.EMPLOYMENT_STATUS_PAID_EMPLOYEE ||
            jobStatus === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED;
    }

    public isNotBrokerVmd() {

        return this.getRequesterProperty('userEmployerIsBroker') !== this.constants.USER_EMPLOYER_IS_BROKER_VMD;
    }

    public getConsentment(): string {

        return this.getRequesterProperty('userDisclaimerIndicator')
            ? this.translation('ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT')
            : this.translation('ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT');
    }

    public getReceiptMethod(): string {

        if (this.getRequesterProperty('userReceiptDocumentSecuritiesHolderIndicator') === 'ALL') {

            return this.translation('REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1');
        }

        if (this.getRequesterProperty('userReceiptDocumentSecuritiesHolderIndicator') === 'NONE') {

            return this.translation('REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2');
        }

        return this.translation('REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3');
    }

    public getElectronicConsentment(): string {

        return this.getRequesterProperty('userElectronicDocumentSecuritiesHolderIndicator')
            ? this.translation('ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1')
            : this.translation('ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE2');
    }


    public isAnnuitantSpouse(): boolean {

        return this.ODCForm.addRRSP && this.ODCForm.rrspAnnuitant === this.constants.ANNUITANT_SPOUSE;
    }

    public getMaritalStatus(): string {

        return this.isAnnuitantSpouse()
            ? ListModelService.getLabelFromList(this.userMaritalStatusListRrspAnnuitantSpouse, this.getRequesterProperty('userMaritalStatus'))
            : ListModelService.getLabelFromList(this.userMaritalStatusList, this.getRequesterProperty('userMaritalStatus'));
    }

    public hasSpouse(): boolean {

        return this.getRequesterProperty('userMaritalStatus') === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW
            || this.getRequesterProperty('userMaritalStatus') === this.constants.MARITAL_STATUS_MARRIED;
    }

    public hasBeneficiary(accountTypeAllowed: boolean): boolean {

        return accountTypeAllowed && this.getRequesterProperty('userAddressProv') !== 'CANQC';
    }

    public isPurposeOther(): boolean {

        return this.getRequesterProperty('brokeragePurpose') === this.constants.USER_EMPLOYER_IS_BROKER_OTHER_BROKER;
    }

    public getLink(linkKey: string): string {

        return ListModelService.getLabelFromList(this.parentLinks, linkKey);
    }

    public getLinkFhsa(linkKey: string): string {

        return ListModelService.getLabelFromList(this.parentLinksFhsa, linkKey);
    }

    public getRelationship(relationKey: string): string {

        return ListModelService.getLabelFromList(this.relationList, relationKey);
    }

    public getEmploymentStatus(jobStatus: string): string {

        return ListModelService.getLabelFromList(this.jobStatusList, jobStatus);
    }

    public isWorkingForBrokering(isBroker: string): string {

        return ListModelService.getLabelFromList(this.isBrokerList, isBroker);
    }

    public getSectorActivity(sectorActivity: string): string {

        return ListModelService.getLabelFromList(this.employerActivityList, sectorActivity);
    }

    public getBank(bankKey: string): string {

        return ListModelService.getLabelFromList(this.bankList, bankKey);
    }

    public getPurpose(purposekey: string): string {

        if (this.isPurposeOther()) {

            return this.getRequesterProperty('brokeragePurposeOther');
        }

        return ListModelService.getLabelFromList(this.brokeragePurposeList, purposekey);
    }

    public getKnowledge(knowledgeKey: string): string {

        return ListModelService.getLabelFromList(this.userBrokerageKnowledgeList, knowledgeKey);
    }

    public getMarket(marketKey: string): string {

        return this.translation('REVIEW_MARKET_' + marketKey);
    }

    public getOtherAccountType(typeKey: string): string {

        return ListModelService.getLabelFromList(this.brokerageOtherAccountsAccountTypeList, typeKey);
    }

    public getPlatformValue(): string {

        return this.translation('REVIEW_PLATFORM_' + this.ODCForm.plateformDisnat);
    }

    public getAccountCreationValue(): string {

        return this.ODCForm.newExistAct === this.constants.ACCOUNT_NEW_CLIENT
            ? this.translation('REVIEW_ADDING_A_NEW_ACCOUNT')
            : this.translation('REVIEW_ADDING_A_NEW_ACCOUNT_TO_AN_EXISTING_USER');
    }

    public isExistingAccount(): boolean {

        return this.ODCForm.newExistAct === this.constants.ACCOUNT_ADD_ACCOUNT;
    }

    public translation(value: string): string {

        return this.translate.instant(value);
    }

    private getCurrencyValue(currency: string): string {
        return currency === this.constants.CURRENCY_CAD
            ? this.translation('CA_ONLY')
            : this.translation('US_AND_CA');
    }

    private getGenderValue(gender: string): string {

        return this.translation('ODC_STEP_PERSONAL_INFORMATION_' + gender);
    }
}
