export class OdcConfigUtils {

    static getBooleanValue(value: any): boolean {
        if (typeof value === 'boolean') {
            return value;
        } else {
            if (value === 'true' || value === '1' || value === 'on') {
                return true;
            } else {
                return false;
            }
        }
    }
}
