<div class="help-insider">
    <ng-container *ngIf="translate.currentLang === 'fr'">
        <h2>Qu’est-ce qu’un initié assujetti?</h2>
        <p>Selon le <i>Règlement 55-104 sur les exigences et dispenses de déclaration d'initié</i> (ci-après, le «Règlement
            55-104»), un initié assujetti se définit comme étant :</p>
        <ul>
            <li>Un administrateur, le Chef de la direction, le Chef des finances ou le Chef de l'exploitation de l'émetteur,
                de toute Filiale importante<a id="appel-note1-fr" class="appel-note" href="#note1_fr"><sup>(1)</sup></a>,
                de tout Actionnaire important<a id="appel-note2-fr" class="appel-note" href="#note2_fr"><sup>(2)</sup></a>
                et de tout Actionnaire important post-conversion<a id="appel-note3-fr" class="appel-note" href="#note3_fr"><sup>(3)</sup></a>;
            </li>
            <li>le responsable de toute unité d'exploitation, division ou fonction importante de l'émetteur ou de sa Filiale
                importante;
            </li>
            <li>un Actionnaire important ou un Actionnaire important post-conversion;</li>
            <li>une société de gestion qui fournit des services de gestion ou d'administration significatifs à l'émetteur
                assujetti ou à une Filiale importante de celui-ci ainsi que chaque administrateur, Chef de la direction,
                Chef des finances, Chef de l'exploitation et Actionnaire important de cette société;
            </li>
            <li>une personne physique qui exerce des fonctions analogues à ce qui précède;</li>
            <li>un émetteur assujetti, s'il a acheté, racheté ou autrement acquis des titres qu'il a lui-même émis, aussi
                longtemps qu'il les conserve; et
            </li>
            <li>
                tout autre initié qui remplit les conditions suivantes :
                <ul>
                    <li>il reçoit, dans le cours normal de ses activités, de l'information ou a accès à de l'information sur
                        des faits importants ou des changements importants concernant l'émetteur assujetti avant qu'ils ne
                        soient rendus publics;
                    </li>
                    <li>il exerce ou peut exercer directement ou indirectement un pouvoir ou une influence significative sur
                        les activités, l'exploitation, le capital ou le développement de l'émetteur assujetti.
                    </li>
                </ul>
            </li>
        </ul>

        <div class="note-bas-page m-t-5">
            <div id="note1_fr" tabindex="0">
                (1) Une Filiale importante est une filiale d'un émetteur dont la valeur de l'actif représente au moins 30 %
                de
                l'actif consolidé de cet émetteur ou dont le revenu représente au moins 30 % des produits consolidés de cet
                    émetteur.
                <a href="#appel-note1-fr" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
            <div id="note2_fr" tabindex="0">
                (2) Un Actionnaire important aux termes du Règlement 55-104 est une personne physique ou morale qui a la
                propriété véritable et/ou exerce une emprise, directement ou indirectement, sur des titres comportant plus
                de 10 % des droits de vote rattachés à l'ensemble des titres avec droit de vote en circulation. Est
                également
                considérée comme un Actionnaire important une personne physique ou morale qui a la propriété véritable
                post-conversion de titres.
                <a href="#appel-note2-fr" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
            <div id="note3_fr" tabindex="0">
                (3) La propriété véritable «post-conversion» est considérée si les titres sont convertibles dans un délai de
                60
                jours.
                <a href="#appel-note3-fr" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="translate.currentLang === 'en'">
        <h2>What is an insider?</h2>
        <p>Under <i>National Instrument 55-104 Insider Reporting Requirements and Exemptions</i> (hereinafter, "NI 55-104"), a
            reporting insider is defined as being :</p>
        <ul>
            <li>A director, Chief Executive Officer, Chief Financial Officer or Chief Operating Officer of an issuer, a
                Major Subsidiary<a id="appel-note1-en" class="appel-note" href="#note1_en"><sup>(1)</sup></a>,
                a
                Significant Shareholder<a id="appel-note2-en" class="appel-note" href="#note2_en"><sup>(2)</sup></a>
                and a post-conversion Significant Shareholder<a id="appel-note3-en" class="appel-note" href="#note3_en"><sup>(3)</sup></a>;
            </li>
            <li>the head of any business unit, division or function of the issuer or Significant Subsidiary;</li>
            <li>a Significant Shareholder or a post-conversion Significant Shareholder;</li>
            <li>a management company that provides significant management or administrative services to the reporting issuer
                or a Major Subsidiary of the reporting issuer, every director, CEO, CFO and COO of the management company,
                and every Significant Shareholder of the management company;
            </li>
            <li>an individual who performs functions similar to those described above;</li>
            <li>a reporting issuer, if it has purchased, redeemed or otherwise acquired securities of its own issue, as long
                as it continues to hold them; and
            </li>
            <li>
                any other insider who meets the following conditions:
                <ul>
                    <li>in the ordinary course receives or has access to information as to material facts or material
                        changes concerning the reporting issuer before the material facts or material changes are generally
                        disclosed; and;
                    </li>
                    <li>directly or indirectly exercises, or has the ability to exercise, significant power or in influence
                        over the business, operations, capital or development of the reporting issuer.
                    </li>
                </ul>
            </li>
        </ul>

        <div class="note-bas-page m-t-5">
            <div id="note1_en" tabindex="0">
                (1) A Major subsidiary is a subsidiary of an issuer whose assets are 30% or more of the consolidated assets
                of this issuer, or whose revenues are 30% or more of the consolidated revenue of the issuer.
                <a href="#appel-note1-en" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
            <div id="note2_en" tabindex="0">
                (2) A Significant Shareholder under NI 55-104 is a person or company that has beneficial ownership of, or
                control or direction over, whether direct or indirect, of securities of an issuer carrying more than 10 per
                cent of the voting rights attached to all the issuer’s outstanding voting securities, excluding, for the
                purpose of the calculation of the percentage held, any securities held by the person or company as
                underwriter in the course of a distribution. Post-conversion ownership of securities is also taken into
                consideration for identification as a Significant Shareholder.
                <a href="#appel-note2-en" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
            <div id="note3_en" tabindex="0">
                (3) "Post-conversion" ownership is considered if the securities are convertible within a 60-day timeframe.
                <a href="#appel-note3-en" class="retour-note">{{'NOTE_BACK'|translate}}</a>
            </div>
        </div>
    </ng-container>
</div>
