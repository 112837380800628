import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {zxcvbn, zxcvbnOptions} from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import * as zxcvbnFrPackage from '@zxcvbn-ts/language-fr';
import {TranslateService} from '@ngx-translate/core';
import {I18N_MESSAGES_EN} from '../../../../../components/i18n/messages_en';
import {I18N_MESSAGES_FR} from '../../../../../components/i18n/messages_fr';
import {VmdConstants} from '../../../../../components/constants/vmd-constants';

declare const n3ProgressBar: Function;

@Component({
    selector: 'app-password-strength',
    templateUrl: './password-strength.component.html',
    styleUrls: ['./password-strength.component.css']
})
export class PasswordStrengthComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() password: string;
    @Output() strengthChanges = new EventEmitter();
    passwordStrength: number;
    progressBarStrengthText: string;

    passwordStrengthLimit = VmdConstants.PASSWORD_STRENGTH_MIN;

    constructor(private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.passwordStrength = 0;
        this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_WEAK';

        this.refreshProgressBar();

        const options = {
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            dictionary: {

                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnPackage.dictionary,
                ...zxcvbnFrPackage.dictionary,
            }
        };

        zxcvbnOptions.setOptions(options);
    }

    ngOnChanges(changes: SimpleChanges): void {

        const passwordCurrentValue = changes.password.currentValue;

        if (passwordCurrentValue != null) {

            const result = zxcvbn(passwordCurrentValue);
            this.passwordStrength = result.score;

            switch (this.passwordStrength) {
                case 0:
                case 1:
                    this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_WEAK';
                    break;
                case 2:
                    this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_MEDIUM';
                    break;
                case 3:
                    this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_GOOD';
                    break;
                case 4:
                    this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_STRONG';
                    break;
            }

            this.refreshProgressBar();
            this.strengthChanges.emit(this.passwordStrength);

        } else {
            this.passwordStrength = 0;
            this.progressBarStrengthText = 'PASSWORD_LENGTH_STRENGTH_WEAK';

            this.refreshProgressBar();
            this.strengthChanges.emit(this.passwordStrength);
        }
    }

    ngAfterViewInit() {
        this.refreshProgressBar();
    }

    refreshProgressBar() {
        n3ProgressBar('#password-strength-progress', String(this.passwordStrength), this.getMessage(this.progressBarStrengthText));
    }

    getMessage(key: string): string {
        if (this.translate.currentLang === VmdConstants.LANG_EN) {
            return I18N_MESSAGES_EN[key];
        } else {
            return I18N_MESSAGES_FR[key];
        }
    }
}
