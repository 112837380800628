export class AdvisorIdentity {

    public advisorCode: string;
    public prenom: string;
    public nom: string;
    public numeroInstitution: string;
    public numeroTransit: string;
    public langue: string;

    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {

            this.advisorCode = this.init(obj.advisorCode);
            this.prenom = this.init(obj.prenom);
            this.nom = this.init(obj.nom);
            this.numeroInstitution = this.init(obj.numeroInstitution);
            this.numeroTransit = this.init(obj.numeroTransit);
            this.langue = this.init(obj.langue);
        }
    }
}
