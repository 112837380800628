import {Component} from '@angular/core';
import {BaseHoldingAccountRelatedQuestionsComponent} from './base-holding-account-related-questions.component';

@Component({
    selector: 'app-cooperative-related-questions',
    templateUrl: './base-holding-account-related-questions.component.html'
})
export class CooperativeRelatedQuestionsComponent extends BaseHoldingAccountRelatedQuestionsComponent {

    public getFieldPrefix(): string {
        return 'cooperative';
    }

    public getMaxActPeople(): number {
        return null;
    }

    public hasActPeople(): boolean {
        return false;
    }

    public hasLegActPeople(): boolean {
        return false;
    }

    public hasShareholders(): boolean {
        return false;
    }

}
