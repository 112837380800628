import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {VmdForm} from '../../components/utils/vmd-form';
import {AddressCompleterService} from '../../components/services/address-completer.service';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {FormService} from '../../components/services/form.service';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {TranslateService} from '@ngx-translate/core';
import {I18N_MESSAGES_EN} from '../../components/i18n/messages_en';
import {I18N_MESSAGES_FR} from '../../components/i18n/messages_fr';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {NavigationService} from '../../components/services/navigation.service';

declare let $: any;

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    providers: [AddressCompleterService]
})
export class AddressFormComponent extends BaseNavComponent implements AfterViewInit, OnInit {
    constants = VmdConstants;

    @Input() form: FormGroup;
    @Input() submitted: boolean;
    @Input() index: string;
    formGroup: FormGroup;
    @Input() bindPath: string;
    @Input() path: string;
    @Input() country: string;
    @Input() street: string;
    @Input() unit: string;
    @Input() city: string;
    @Input() province: string;
    @Input() postalcode: string;
    @Input() legend: string;
    provinces: HtmlSelectKV[];

    @Input() isStreetReadOnly = false;
    @Input() isUnitReadOnly = false;
    @Input() isCityReadOnly = false;
    @Input() isProvinceReadOnly = false;
    @Input() isPostalCodeReadOnly = false;
    @Input() isCountryReadOnly = false;


    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                private addressCompleter: AddressCompleterService,
                private listModelService: ListModelService,
                public translate: TranslateService) {

        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {


        this.formGroup = this.form.get(this.path) as FormGroup;
        if (this.formGroup === null) {
            this.formGroup = this.form;
            this.path = '';
        } else {
            this.path += '.';
        }

        let currentCountry = this.constants.COUNTRIES.CANADA;
        if (this.form.get(this.path + this.country).value === this.constants.COUNTRIES.UNITED_STATES) {
            currentCountry = this.constants.COUNTRIES.UNITED_STATES;
        }
        this.loadCountryFieldData(this.form.get(this.path + this.country).value);

        this.addressCompleter.country = currentCountry;
        this.setAddressCompleterLanguage(currentCountry);
        this.form.get(this.path + this.country).patchValue(currentCountry);
        this.formService.setFormProperty(this.getRequesterPath(this.country), currentCountry);

        if (!this.formService.isFromSmartD() && this.formService.isCaisseContext()) {
            const ddpRequester = this.formService.getDdpRequester();
            if (this.path === 'addressGroup.homeAddress.') {
                this.isStreetReadOnly = this.formService.isReadOnly(ddpRequester.userAddressStreet);
                this.isUnitReadOnly = this.formService.isReadOnly(ddpRequester.userAddressUnit);
                this.isCityReadOnly = this.formService.isReadOnly(ddpRequester.userAddressCity);
                this.isProvinceReadOnly = this.formService.isReadOnly(ddpRequester.userAddressProv);
                this.isPostalCodeReadOnly = this.formService.isReadOnly(ddpRequester.userAddressPostalCode);
            }
        }

        this.form.get(this.path + this.country).valueChanges.subscribe(value => {
            this.loadCountryFieldData(value);
        });
    }

    ngAfterViewInit() {

        if (!this.isStreetReadOnly) {
            this.addressCompleter.initialize($('#' + this.street + this.index));
        }
    }

    loadCountryFieldData(value: any) {
        if (value === this.constants.COUNTRIES.UNITED_STATES) {
            this.listModelService.getListModel('statesList', data => this.setProvinces(data));
        } else {
            this.listModelService.getListModel('provincesList', data => this.setProvinces(data));
        }
    }

    isFieldInError(path: string) {
        return VmdForm.isFieldInError(this.form.get(path), this.submitted);
    }

    setProvinces(data: any): void {
        // Façon de ne pas perdre la valeur par défaut du select 'province/état'
        const globalSelect: string = (this.translate.currentLang === this.constants.LANG_EN) ?
            I18N_MESSAGES_EN.GLOBAL_SELECT : I18N_MESSAGES_FR.GLOBAL_SELECT;

        const dataTmp = Object.assign([], data);

        if (dataTmp[0].itemValue !== 'null') {
            dataTmp.unshift({itemValue: 'null', itemLabel: globalSelect});
        }

        this.provinces = dataTmp;
        setTimeout(() => {
            // Initialize province
            $('#' + this.province + this.index + ' option[value="' + this.form.get(this.path + this.province).value + '"]').attr('selected', 'selected');
        }, 0);
    }

    getSuffix(): string {
        let suffix = '';
        if (this.index !== '') {
            suffix = '_' + this.index;
        }
        return suffix;
    }

    changeCountryAddress(newCountry): void {
        const currentCountry = this.form.get(this.path + this.country).value;

        if (currentCountry !== newCountry) {

            this.resetAddress(newCountry);

            this.constants.COUNTRIES.UNITED_STATES === newCountry ?
                this.listModelService.getListModel('statesList', data => this.setProvinces(data)) :
                this.listModelService.getListModel('provincesList', data => this.setProvinces(data));
        }
    }

    resetAddress(country: string): void {
        this.addressCompleter.country = country;
        this.setAddressCompleterLanguage(country);

        this.form.get(this.path + this.street).patchValue(null);
        this.formService.setFormProperty(this.getRequesterPath(this.street), null);
        this.form.get(this.path + this.unit).patchValue(null);
        this.formService.setFormProperty(this.getRequesterPath(this.unit), null);
        this.form.get(this.path + this.city).patchValue(null);
        this.formService.setFormProperty(this.getRequesterPath(this.city), null);
        this.form.get(this.path + this.province).patchValue(null);
        this.formService.setFormProperty(this.getRequesterPath(this.province), null);
        this.form.get(this.path + this.postalcode).patchValue(null);
        this.formService.setFormProperty(this.getRequesterPath(this.postalcode), null);
        this.form.get(this.path + this.country).patchValue(country);
        this.formService.setFormProperty(this.getRequesterPath(this.country), country);
    }

    getRequesterPath(name): string {
        return this.bindPath + name;
    }

    getProvinceLabel(): string {
        if (!!this.form.get(this.path + this.country)) {

            return this.form.get(this.path + this.country).value === this.constants.COUNTRIES.UNITED_STATES ?
                'ODC_STEP_PERSONAL_INFORMATION_STATE' : 'ODC_STEP_PERSONAL_INFORMATION_PROVINCE';
        }

        return 'ODC_STEP_PERSONAL_INFORMATION_PROVINCE';
    }

    getPostalCodeMaxLength(): number {
        if (!!this.form.get(this.path + this.country)) {

            return this.form.get(this.path + this.country).value === this.constants.COUNTRIES.UNITED_STATES ? 5 : 7;
        }

        return 7;
    }

    getPostalCodeCountry(): string {
        if (!!this.form.get(this.path + this.country)) {

            return this.form.get(this.path + this.country).value === this.constants.COUNTRIES.UNITED_STATES ?
                this.constants.COUNTRIES.UNITED_STATES : this.constants.COUNTRIES.CANADA;
        }

        return this.constants.COUNTRIES.CANADA;
    }

    setAddressCompleterLanguage(country: string): void {
        if (country === this.constants.COUNTRIES.CANADA) {
            this.addressCompleter.language = this.formService.getCurrentLang();
        } else if (country === this.constants.COUNTRIES.UNITED_STATES) {
            this.addressCompleter.language = VmdConstants.LANG_EN;
        }
    }

    getFormValue(elementPath: string): any {
        return this.form.get(elementPath).value;
    }
}
