export class AttachedDocumentValidation {

    id: number;
    requestId: string;
    contentHash: string;
    contentHashCalculated: string;
    fileName: string;
    userId: string;
    docId: number;

    constructor(obj?: any) {
        this.id = obj && obj.id || null;
        this.requestId = obj && obj.requestId || null;
        this.contentHashCalculated = obj && obj.contentHashCalculated || null;
        this.fileName = obj && obj.fileName || null;
        this.contentHash = obj && obj.contentHash || null;
        this.userId = obj && obj.userId || null;
        this.docId = obj && obj.docId || null;
    }
}
