import {ClientInformations} from '../models/ClientInformations';
import {Inject, Injectable} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

@Injectable()
export class OssQueryService {

    private clientInformations: ClientInformations;

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig) {

        this.initClientInformations({});
    }


    public initClientInformations(obj: any): void {

        this.clientInformations = new ClientInformations(obj);
    }

    public getClientInformations(): ClientInformations {
        return this.clientInformations;
    }
}
