import {Functions} from '../utils/functions';
import {VmdConstants} from '../constants/vmd-constants';

export class BeneficiaryRespBean {

    beneficiaryRespGender: string;
    beneficiaryRespFirstName: string;
    beneficiaryRespLastName: string;
    beneficiaryRespDOB: string;
    beneficiaryRespSIN: string;
    beneficiaryRespAddressStreet: string;
    beneficiaryRespAddressUnit: string;
    beneficiaryRespAddressCity: string;
    beneficiaryRespAddressProv: string;
    beneficiaryRespAddressPostalCode: string;
    beneficiaryRespAddressCountry: string = VmdConstants.COUNTRIES.CANADA;
    beneficiaryRespParentLink: string;
    beneficiaryRespAddressSameAsSubscriber: boolean;
    beneficiaryRespDistributionRatio: string;
    beneficiaryRespCaregiverParentFirstName: string;
    beneficiaryRespCaregiverParentLastName: string;
    beneficiaryRespCaregiverParentSin: string;
    beneficiaryRespRequestCLBForResp: boolean;

    beneficiaryRespCustodialParentGender: string;
    beneficiaryRespCustodialParentFirstName: string;
    beneficiaryRespCustodialParentLastName: string;
    beneficiaryRespCustodialParentSecGender: string;
    beneficiaryRespCustodialParentSecFirstName: string;
    beneficiaryRespCustodialParentSecLastName: string;


    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {
            this.beneficiaryRespGender = this.init(obj.beneficiaryRespGender);
            this.beneficiaryRespFirstName = this.init(obj.beneficiaryRespFirstName);
            this.beneficiaryRespLastName = this.init(obj.beneficiaryRespLastName);
            this.beneficiaryRespDOB = this.init(obj.beneficiaryRespDOB);
            this.beneficiaryRespSIN = this.init(obj.beneficiaryRespSIN);
            this.beneficiaryRespAddressStreet = this.init(obj.beneficiaryRespAddressStreet);
            this.beneficiaryRespAddressUnit = Functions.initByLength(obj.beneficiaryRespAddressUnit, 8);
            this.beneficiaryRespAddressCity = Functions.initByLength(obj.beneficiaryRespAddressCity, 32);
            this.beneficiaryRespAddressProv = this.init(obj.beneficiaryRespAddressProv);
            this.beneficiaryRespAddressPostalCode = this.init(obj.beneficiaryRespAddressPostalCode);
            this.beneficiaryRespAddressCountry = Functions.initCountryAddress(obj.beneficiaryRespAddressCountry);
            this.beneficiaryRespParentLink = this.init(obj.beneficiaryRespParentLink);
            this.beneficiaryRespAddressSameAsSubscriber = this.init(obj.beneficiaryRespAddressSameAsSubscriber);
            this.beneficiaryRespDistributionRatio = this.init(obj.beneficiaryRespDistributionRatio);
            this.beneficiaryRespCaregiverParentFirstName = this.init(obj.beneficiaryRespCaregiverParentFirstName);
            this.beneficiaryRespCaregiverParentLastName = this.init(obj.beneficiaryRespCaregiverParentLastName);
            this.beneficiaryRespCaregiverParentSin = this.init(obj.beneficiaryRespCaregiverParentSin);
            this.beneficiaryRespRequestCLBForResp = this.init(obj.beneficiaryRespRequestCLBForResp);

            this.beneficiaryRespCustodialParentGender = this.init(obj.beneficiaryRespCustodialParentGender);
            this.beneficiaryRespCustodialParentFirstName = this.init(obj.beneficiaryRespCustodialParentFirstName);
            this.beneficiaryRespCustodialParentLastName = this.init(obj.beneficiaryRespCustodialParentLastName);
            this.beneficiaryRespCustodialParentSecGender = this.init(obj.beneficiaryRespCustodialParentSecGender);
            this.beneficiaryRespCustodialParentSecFirstName = this.init(obj.beneficiaryRespCustodialParentSecFirstName);
            this.beneficiaryRespCustodialParentSecLastName = this.init(obj.beneficiaryRespCustodialParentSecLastName);

        }
    }
}
