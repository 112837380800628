import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, NgForm} from '@angular/forms';
import {BaseTransferComponent} from '../base-transfer.component';
import {NavigationService} from '../../../components/services/navigation.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormService} from '../../../components/services/form.service';
import {TranslateSortService} from '../../../components/services/translate-sort.service';
import {FileValidatorService} from '../../../components/services/file-validator.service';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';

@Component({
    selector: 'app-account-form',
    templateUrl: './account-form.component.html'
})
export class AccountFormComponent extends BaseTransferComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() index: string;
    @Input() ngFormRef: NgForm;
    formGroup: FormGroup;
    @Input() path: string;
    @Input() bindPath: string;
    @Input() actType: string;
    @Input() accountNumber: string;
    @Input() accountType: string;
    @Input() accountCurrency: string;
    @Input() accountCurrencyCAD: string;
    @Input() accountCurrencyUS: string;
    accountTypeList: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected fileValidatorService: FileValidatorService,
                private translateSort: TranslateSortService) {
        super(navigationService, fb, formService, translate, fileValidatorService);
    }

    ngOnInit() {


        this.formGroup = this.form.get(this.path) as FormGroup;
        if (this.formGroup === null) {
            this.formGroup = this.form;
            this.path = '';
        } else {
            this.path += '.';
        }

        this.accountTypeList = this.translateSort.getTranslatedKVList(this.getAccountTypeList(this.actType));
        this.translate.onLangChange.subscribe(
            (langChangeEvent: LangChangeEvent) => {
                this.accountTypeList = this.translateSort.getTranslatedKVList(this.getAccountTypeList(this.actType));
            }
        );
    }

    isFieldInError(path: string) {
        const control: AbstractControl = this.form.get(path);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid
            && ((control.hasError('required') && this.ngFormRef.submitted) || !control.hasError('required'));
    }

    isNgFormSubmitted(): boolean {
        if (this.ngFormRef) {
            return this.ngFormRef.submitted;
        }
        return false;
    }

    getSuffix(): string {
        let suffix = '';
        if (this.index !== '') {
            suffix = '_' + this.index;
        }
        return suffix;
    }

}
