import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {NavigationService} from '../../components/services/navigation.service';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {Functions} from '../../components/utils/functions';
import {ModalService} from '../../components/services/modal.service';


@Component({
    selector: 'app-spouse-information',
    templateUrl: './spouse-information.component.html'
})
export class SpouseInformationComponent extends BaseNavComponent implements OnInit {

    userMaritalStatus: HtmlSelectKV[];
    userMaritalStatusList: HtmlSelectKV[];
    userMaritalStatusListRrspAnnuitantSpouse: HtmlSelectKV[];

    jobStatusList: HtmlSelectKV[];
    employerActivityList: HtmlSelectKV[];

    contributingSpouseAccounts: { type: string, name: string, orderLabel: string }[] = [];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(lang => {
            if (this.hasSpouse() && this.spouseIsSelfEmployed() && !this.isConjointPleinEx()) {
                this.setSpouseEmployerName();
            }
        });

        this.form = this.fb.group({
            userMaritalStatus: [this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus, [this.requiredValidator('userMaritalStatus')]],

            rrspContributingSpouseIsCurrentSpouse: [this.formService.getForm().rrspContributingSpouseIsCurrentSpouse, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            rrspContributingSpouseIsCurrentSpouseSec: [this.formService.getForm().rrspContributingSpouseIsCurrentSpouseSec, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            rrspContributingSpouseIsCurrentSpouseTer: [this.formService.getForm().rrspContributingSpouseIsCurrentSpouseTer, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            rrifContributingSpouseIsCurrentSpouse: [this.formService.getForm().rrifContributingSpouseIsCurrentSpouse, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            rrifContributingSpouseIsCurrentSpouseSec: [this.formService.getForm().rrifContributingSpouseIsCurrentSpouseSec, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            ftarrspContributingSpouseIsCurrentSpouse: [this.formService.getForm().ftarrspContributingSpouseIsCurrentSpouse, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            ftarrspContributingSpouseIsCurrentSpouseSec: [this.formService.getForm().ftarrspContributingSpouseIsCurrentSpouseSec, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],
            ftarrspContributingSpouseIsCurrentSpouseTer: [this.formService.getForm().ftarrspContributingSpouseIsCurrentSpouseTer, [this.requiredValidator('contributingSpouseIsCurrentSpouse')]],

            spouse: this.fb.group({
                spouseGender: [this.formService.getForm().requesters[this.requesterIndex].spouseGender, [this.requiredValidator('spouseGender')]],
                spouseFirstName: [this.formService.getForm().requesters[this.requesterIndex].spouseFirstName, [this.requiredValidator('spouseFirstName')]],
                spouseLastName: [this.formService.getForm().requesters[this.requesterIndex].spouseLastName, [this.requiredValidator('spouseLastName')]],
                annuitantSpouse: this.fb.group({
                    spouseDOB: [this.formService.getForm().requesters[this.requesterIndex].spouseDOB, [this.requiredValidator('spouseDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.date]],
                    spouseSIN: [this.formService.getForm().requesters[this.requesterIndex].spouseSIN, [this.requiredValidator('spouseSIN'), VmdValidators.sin]]
                }),
                spouseJobStatus: [this.formService.getForm().requesters[this.requesterIndex].spouseJobStatus, [this.requiredValidator('spouseJobStatus')]],
                spouseEmployer: this.fb.group({
                    spouseEmployerName: [this.formService.getForm().requesters[this.requesterIndex].spouseEmployerName, [this.requiredValidator('spouseEmployerName')]],
                    spouseJobTitle: [this.formService.getForm().requesters[this.requesterIndex].spouseJobTitle, [this.requiredValidator('spouseJobTitle')]],
                    spouseEmployerActivity: [this.formService.getForm().requesters[this.requesterIndex].spouseEmployerActivity, [this.requiredValidator('spouseEmployerActivity')]]
                })
            }),
            contributingSpouse: this.fb.group({
                contributingSpouseGender: [this.formService.getForm().requesters[this.requesterIndex].contributingSpouseGender, [this.requiredValidator('contributingSpouseGender')]],
                contributingSpouseFirstName: [this.formService.getForm().requesters[this.requesterIndex].contributingSpouseFirstName, [this.requiredValidator('contributingSpouseFirstName')]],
                contributingSpouseLastName: [this.formService.getForm().requesters[this.requesterIndex].contributingSpouseLastName, [this.requiredValidator('contributingSpouseLastName')]],
                contributingSpouseDOB: [this.formService.getForm().requesters[this.requesterIndex].contributingSpouseDOB, [this.requiredValidator('contributingSpouseDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.date]],
                contributingSpouseSIN: [this.formService.getForm().requesters[this.requesterIndex].contributingSpouseSIN, [this.requiredValidator('contributingSpouseSIN'), VmdValidators.sin]]
            })
        }, {
            validator: [
                VmdValidators.contributingSpouseCannotBeCurrentSpouse(this.isPleinEx()),
                VmdValidators.multipleContributingSpousesValidation(this.isPleinEx(), this.formService.isPrintMode())
            ]
        });

        const serviceName = this.isPleinEx() ? 'listEtatCivilPleinEx' : 'listEtatCivil';
        const serviceNameRrspAnnuitant = this.isPleinEx() ? 'listEtatCivilPleinEx' : this.getCivilStatusListByOrigin();

        this.listModelService.getListModel(serviceName, data => this.setUserMaritalStatusListWithoutRrspAnnuitantSpouse(data));
        this.listModelService.getListModel(serviceNameRrspAnnuitant, data => this.setUserMaritalStatusListWithRrspAnnuitantSpouse(data));

        this.listModelService.getListModel('statutEmploiList', data => this.jobStatusList = data);
        this.listModelService.getListModel('userEmployerActivityList', data => this.employerActivityList = data);

        this.form.get('userMaritalStatus').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('spouse'), this.hasSpouse() && !this.isConjointPleinEx());
            this.setAnnuitantSpouseFields();
            this.updateControlEnabled(this.form.get('spouse.spouseJobStatus'), this.hasSpouse() && !this.isPleinEx() && !this.isConjointPleinEx());
            this.updateControlEnabled(this.form.get('spouse.spouseEmployer'), this.hasSpouse() && this.spouseIsPaidEmployeeOrSelfEmployed() && !this.isConjointPleinEx());

            this.formService.setFormProperty(this.getRequesterPath('userMaritalStatus'), value);
        });
        this.updateControlEnabled(this.form.get('spouse'), this.hasSpouse() && !this.isConjointPleinEx());

        this.setAnnuitantSpouseFields();


        if (!this.isPleinEx()) {
            this.form.get('spouse.spouseJobStatus').valueChanges.subscribe(value => {
                this.updateControlEnabled(this.form.get('spouse.spouseEmployer'), this.hasSpouse() && this.spouseIsPaidEmployeeOrSelfEmployed() && !this.isConjointPleinEx());
                this.setSpouseEmployerName();
            });
        }

        this.updateControlEnabled(this.form.get('spouse.spouseEmployer'), this.hasSpouse() && this.spouseIsPaidEmployeeOrSelfEmployed() && !this.isConjointPleinEx());

        this.setSpouseEmployerNameEnabled();

        this.contributingSpouseAccounts = this.getContributingSpouseAccounts();
        // do after call of this.getContributingSpouseAccounts()
        this.contributingSpouseValueChanges();
    }

    private contributingSpouseValueChanges(): void {
        const paths = [
            'rrspContributingSpouseIsCurrentSpouse',
            'rrspContributingSpouseIsCurrentSpouseSec',
            'rrspContributingSpouseIsCurrentSpouseTer',
            'rrifContributingSpouseIsCurrentSpouse',
            'rrifContributingSpouseIsCurrentSpouseSec',
            'ftarrspContributingSpouseIsCurrentSpouse',
            'ftarrspContributingSpouseIsCurrentSpouseSec',
            'ftarrspContributingSpouseIsCurrentSpouseTer'
        ];

        for (const path of paths) {
            this.form.get(path).valueChanges.subscribe(value => {
                // needed because OdcFieldComponent call setFormProperty on blur and it is too late
                this.formService.setFormProperty(path, value);
                this.updateControlEnabled(this.form.get('contributingSpouse'), value === false || this.hasAtLeastOneContributingSpouse());
                this.setAnnuitantSpouseFields();
            });
        }
        this.updateControlEnabled(this.form.get('contributingSpouse'), this.hasAtLeastOneContributingSpouse());
    }

    getContributingSpouseAccounts(): { type: string, name: string, orderLabel: string }[] {
        const accounts: { type: string, name: string, orderLabel: string }[] = [];

        for (const type of ['rrsp', 'rrif', 'ftarrsp']) {

            const typeUpperCase = type.toUpperCase();

            if (this.formService.getForm()['add' + typeUpperCase]) {

                if (this.formService.getForm()[type + 'Annuitant'] === this.constants.ANNUITANT_SPOUSE) {

                    accounts.push({
                        type: this.constants['ACCOUNT_TYPE_' + typeUpperCase],
                        name: type + 'ContributingSpouseIsCurrentSpouse',
                        orderLabel: 'CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_FIRST'
                    });
                    this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouse'), true);
                    // OCE-7381
                    this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouse',
                        (!(this.form.get(type + 'ContributingSpouseIsCurrentSpouse').value !== null
                            && this.form.get(type + 'ContributingSpouseIsCurrentSpouse').value !== true))
                    );
                } else {
                    this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouse', null);
                    this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouse'), false);
                }

                if (this.formService.getForm()['add' + typeUpperCase + 'Sec'] && this.formService.getForm()[type + 'AnnuitantSec'] === this.constants.ANNUITANT_SPOUSE) {
                    accounts.push({
                        type: this.constants['ACCOUNT_TYPE_' + typeUpperCase],
                        name: type + 'ContributingSpouseIsCurrentSpouseSec',
                        orderLabel: 'CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_SECOND'
                    });
                    this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseSec'), true);
                    // OCE-7381
                    this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseSec',
                        (!(this.form.get(type + 'ContributingSpouseIsCurrentSpouseSec').value !== null
                            && this.form.get(type + 'ContributingSpouseIsCurrentSpouseSec').value !== true))
                    );
                } else {
                    this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseSec', null);
                    this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseSec'), false);
                }

                if (typeof this.formService.getForm()['add' + typeUpperCase + 'Ter'] !== 'undefined') {

                    if (
                        this.formService.getForm()['add' + typeUpperCase + 'Ter']
                        && this.formService.getForm()[type + 'AnnuitantTer'] === this.constants.ANNUITANT_SPOUSE
                    ) {
                        accounts.push({
                            type: this.constants['ACCOUNT_TYPE_' + typeUpperCase],
                            name: type + 'ContributingSpouseIsCurrentSpouseTer',
                            orderLabel: (this.formService.getForm()['add' + typeUpperCase + 'Sec']) ? 'CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_THIRD' : 'CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_SECOND'
                        });
                        this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseTer'), true);
                        // OCE-7381
                        this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseTer',
                            (!(this.form.get(type + 'ContributingSpouseIsCurrentSpouseTer').value !== null
                                && this.form.get(type + 'ContributingSpouseIsCurrentSpouseTer').value !== true))
                        );
                    } else {
                        this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseTer', null);
                        this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseTer'), false);
                    }
                }
            } else {
                this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouse', null);
                this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseSec', null);
                this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouse'), false);
                this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseSec'), false);
                if (typeof this.formService.getForm()[type + 'ContributingSpouseIsCurrentSpouseTer'] !== 'undefined') {
                    this.formService.setFormProperty(type + 'ContributingSpouseIsCurrentSpouseTer', null);
                    this.updateControlEnabled(this.form.get(type + 'ContributingSpouseIsCurrentSpouseTer'), false);
                }
            }
        }

        return accounts;
    }

    hasAtLeastOneCurrentSpouse(): boolean {
        return this.isPleinEx() && this.hasAtLeastOneContributingSpouseIsCurrentSpouse(true);
    }

    hasAtLeastOneContributingSpouse(): boolean {
        return this.isPleinEx() && this.hasAtLeastOneContributingSpouseIsCurrentSpouse(false) && this.formService.hasAnnuitantSpouseAccount();
    }

    private hasAtLeastOneContributingSpouseIsCurrentSpouse(value: boolean): boolean {
        return this.form.get('rrspContributingSpouseIsCurrentSpouse').value === value
            || this.form.get('rrspContributingSpouseIsCurrentSpouseSec').value === value
            || this.form.get('rrspContributingSpouseIsCurrentSpouseTer').value === value
            || this.form.get('rrifContributingSpouseIsCurrentSpouse').value === value
            || this.form.get('rrifContributingSpouseIsCurrentSpouseSec').value === value
            || this.form.get('ftarrspContributingSpouseIsCurrentSpouse').value === value
            || this.form.get('ftarrspContributingSpouseIsCurrentSpouseSec').value === value
            || this.form.get('ftarrspContributingSpouseIsCurrentSpouseTer').value === value;
    }

    hasSpouse(): boolean {
        return (
            this.form.get('userMaritalStatus').value === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW
            || this.form.get('userMaritalStatus').value === this.constants.MARITAL_STATUS_MARRIED
        ) && !this.isConjointPleinEx();
    }

    isAnnuitantSpouse(): boolean {
        return this.formService.hasAnnuitantSpouseAccount() && (!this.isPleinEx() || this.hasAtLeastOneCurrentSpouse());
    }

    processAnnuitantSpouseList() {
        if (this.isAnnuitantSpouse()) {
            this.userMaritalStatus = this.userMaritalStatusListRrspAnnuitantSpouse;
        } else {
            this.userMaritalStatus = this.userMaritalStatusList;
        }
    }

    setSpouseEmployerName(): void {
        let spouseEmployerName = null;
        if (this.form.get('spouse.spouseJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
            spouseEmployerName = this.getMessage('EMPLOYMENT_STATUS_SELF_EMPLOYED');
        }

        this.form.get('spouse.spouseEmployer').patchValue({
            spouseEmployerName
        });
        this.formService.setFormProperty(this.getRequesterPath('spouseEmployerName'), spouseEmployerName);

        this.setSpouseEmployerNameEnabled();
    }

    setSpouseEmployerNameEnabled(): void {
        if (this.form.get('spouse.spouseEmployer').enabled) {
            if (this.form.get('spouse.spouseJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED) {
                this.form.get('spouse.spouseEmployer.spouseEmployerName').disable();
            } else {
                this.form.get('spouse.spouseEmployer.spouseEmployerName').enable();
            }
        }
    }

    setUserMaritalStatusListWithoutRrspAnnuitantSpouse(data: { itemValue: string, itemLabel: string }[]): void {
        this.userMaritalStatusList = data;

        if (!this.formService.hasAnnuitantSpouseAccount() && !Functions.listHasValue(this.userMaritalStatusList, this.form.get('userMaritalStatus').value)) {
            this.resetUserMaritalStatus();
        }
        this.processAnnuitantSpouseList();
    }

    setUserMaritalStatusListWithRrspAnnuitantSpouse(data: { itemValue: string, itemLabel: string }[]): void {
        this.userMaritalStatusListRrspAnnuitantSpouse = data;

        if (this.formService.hasAnnuitantSpouseAccount() && !Functions.listHasValue(this.userMaritalStatusListRrspAnnuitantSpouse, this.form.get('userMaritalStatus').value)) {
            this.resetUserMaritalStatus();
        }
        this.processAnnuitantSpouseList();
    }

    private resetUserMaritalStatus(): void {
        this.form.get('userMaritalStatus').setValue(null);
        this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus = null;
    }

    spouseIsPaidEmployeeOrSelfEmployed(): boolean {
        return this.form.get('spouse.spouseJobStatus').value === this.constants.EMPLOYMENT_STATUS_PAID_EMPLOYEE
            || this.spouseIsSelfEmployed();
    }

    spouseIsSelfEmployed(): boolean {
        return this.form.get('spouse.spouseJobStatus').value === this.constants.EMPLOYMENT_STATUS_SELF_EMPLOYED;
    }

    getCivilStatusListByOrigin(): string {
        if (this.formService.isCaisseContext()) {

            return 'listEtatCivil';
        }
        return 'listEtatCivilRrspAnnuitant';
    }

    isConjointPleinEx(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && this.isPleinEx();
    }

    setAnnuitantSpouseFields(): void {
        const isAnnuitantSpouse = this.hasSpouse() && this.isAnnuitantSpouse() && !this.isConjointPleinEx();

        if (isAnnuitantSpouse) {
            this.updateControlEnabled(this.form.get('spouse.annuitantSpouse'), true);
            this.formService.setFormProperty(this.getRequesterPath('spouseDOB'), this.form.get('spouse.annuitantSpouse.spouseDOB').value);
            this.formService.setFormProperty(this.getRequesterPath('spouseSIN'), this.form.get('spouse.annuitantSpouse.spouseSIN').value);

        } else {
            this.formService.setFormProperty(this.getRequesterPath('spouseDOB'), null);
            this.formService.setFormProperty(this.getRequesterPath('spouseSIN'), null);
            this.updateControlEnabled(this.form.get('spouse.annuitantSpouse'), false);
        }
    }

    isMaritalStatusValid(): boolean {
        const odcForm = this.formService.getForm();
        const requester = odcForm.requesters[this.requesterIndex];

        return !(odcForm.jointSecondAccountholderSpouse && requester.userMaritalStatus === this.constants.MARITAL_STATUS_SINGLE);
    }

    submitForm(triggerNext: boolean = true) {
        this.isMaritalStatusValid()
            ? super.submitForm()
            : this.modalService.openModal(
                'ODC_STEP_MARITAL_STATUS_VERIFICATION_TITTLE',
                'ODC_STEP_MARITAL_STATUS_VERIFICATION_MESSAGE');
    }
}
