import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {VmdForm} from '../../../components/utils/vmd-form';
import {ListModelService} from '../../../components/services/list-model.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';

declare let $: any;

@Component({
    selector: 'app-beneficiary',
    templateUrl: './beneficiary.component.html'
})
export class BeneficiaryComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() submitted: boolean;
    @Input() groupName: string;
    @Input() gender: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() DOB: Date;
    @Input() SIN: string;
    @Input() addressStreet: string;
    @Input() addressUnit: string;
    @Input() addressCity: string;
    @Input() addressProv: string;
    @Input() addressPostalCode: string;
    @Input() addressCountry: string;
    @Input() parentLink: string;
    @Input() part: string;
    @Input() deleteVisible: boolean;
    @Input() otherPart1: string;
    @Input() otherPart2: string;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

    constants: any = VmdConstants;
    parentLinks: HtmlSelectKV[] = [];
    formGroup: FormGroup;
    path: string;

    constructor(private listModelService: ListModelService) {
    }

    ngOnInit() {
        this.listModelService.getListModel('beneficiaryRelationList', data => this.setParentLinks(data));
        if (this.groupName && this.groupName.length > 0) {
            this.path = this.groupName + '.';
        } else {
            this.path = '';
        }
        this.formGroup = this.form.get(this.groupName) as FormGroup;
    }

    getFormGroup(): FormGroup {
        return this.form.get(this.groupName) as FormGroup;
    }

    isFieldInError(fieldName: string) {
        const control: AbstractControl = this.form.get(this.path + fieldName);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid && ((control.hasError('required') && this.submitted) || !control.hasError('required'));
    }

    setParentLinks(data: any): void {
        this.parentLinks = data;
        const that = this;
        setTimeout(() => {
            // Initialize province
            $('#' + that.parentLink + ' option[value="' + that.formGroup.get(that.parentLink).value + '"]').attr('selected', 'selected');
        }, 0);
    }

    deleteBeneficiary() {
        this.toggle.emit();
        return false;
    }

}
