import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {BaseTransferComponent} from './base-transfer.component';
import {
    AccountTransferSecurity,
    AttachedDocument,
    AttachedDocumentValidation,
    WebException
} from '../../components/models';
import {AddressCompleterService} from '../../components/services/address-completer.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {TranslateSortService} from '../../components/services/translate-sort.service';
import {AttachedDocumentService} from '../../components/services/attached-document.service';
import {PostLoginActionService} from "../../components/services/post-login-action.service";
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {HttpStatusCode} from "@angular/common/http";

declare let $: any;

@Component({
    selector: 'app-transfer-form',
    templateUrl: './transfer-form.component.html'
})
export class TransferFormComponent extends BaseTransferComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() index: string;
    @Input() path: string;
    @Input() ngFormRef: NgForm;
    @Input() actType: string;
    @Input() actTypeCurrency: string;
    @Input() bindPath: string;
    @Input() bankName: string;
    @Input() bankResourceFirstName: string;
    @Input() bankResourceLastName: string;
    @Input() bankPhone: string;
    @Input() transferType: string;
    @Input() transferTypeTotal: string;
    @Input() transferTypePartial: string;
    @Input() transferInstructionType: string;
    @Input() transferInstructionTypeCash: string;
    @Input() transferInstructionTypeKind: string;
    @Input() transferInstructionTypeMixed: string;
    @Input() transferCash: string;
    @Input() accountStatementName: string;
    @Input() accountStatement: string;
    @Input() uploadFile: string;

    formGroup: FormGroup;
    securityGroup: FormArray;
    securities: AccountTransferSecurity[]; // list of securities from accountTransferList
    showAccountGroupSec = false;
    securityTypeList: HtmlSelectKV[]; // select option for security
    accountTypeList: HtmlSelectKV[]; // selection option for account type
    accountTitle: string;

    isActTypeCurrencyDisabled = false;
    isTransferFormSecurityTypeDisabled = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected fileValidatorService: FileValidatorService,
                private addressCompleter: AddressCompleterService,
                private translateSort: TranslateSortService,
                private attachedDocumentService: AttachedDocumentService,
                private postLoginActionService: PostLoginActionService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
        super(navigationService, fb, formService, translate, fileValidatorService);

    }

    ngOnInit() {

        this.formGroup = this.form.get(this.path + '.' + this.index) as FormGroup;
        this.securityGroup = this.formGroup.get('securityGroup') as FormArray;
        // set required validation on the description of the first index of securityGroup
        this.securityGroup.get('0.description').setValidators(Validators.required);

        this.securities = this.getAccountTransferList(this.actType)[this.index].securities;

        this.securityTypeList = this.getSecurityTypeList();
        this.accountTypeList = this.getAccountTypeList(this.actType);

        this.securityTypeList = this.translateSort.getTranslatedKVList(this.getSecurityTypeList());
        this.accountTypeList = this.translateSort.getTranslatedKVList(this.getAccountTypeList(this.actType));
        this.translate.onLangChange.subscribe(
            () => {
                this.securityTypeList = this.translateSort.getTranslatedKVList(this.getSecurityTypeList());
                this.accountTypeList = this.translateSort.getTranslatedKVList(this.getAccountTypeList(this.actType));
            }
        );

        // --------------------validation when loading the form------------------------------//

        // assign title according to the type of request
        if (this.formService.getForm().actType === this.constants.ACCOUNT_TYPE_MARGIN && this.actType === this.constants.ACCOUNT_TRANSFER_CASH) {
            this.accountTitle = 'TRANSFER_FORM_TITLE_ACCOUNT_TYPE_' + this.formService.getForm().actType;
        } else {
            this.accountTitle = 'TRANSFER_FORM_TITLE_ACCOUNT_TYPE_' + this.actType;
        }

        // default CAD currency if the account is CASH, RRSP, TFSA, LIRA and selected currency is CAD
        // default CAD currency for all time for RRIF, LIF
        if ((this.actType === this.constants.ACCOUNT_TRANSFER_CASH && this.formService.getForm().currency === this.constants.CURRENCY_CAD) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_RRSP && this.formService.getForm().rrspCurrency === this.constants.CURRENCY_CAD) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_TFSA && this.formService.getForm().tfsaCurrency === this.constants.CURRENCY_CAD) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_FHSA && this.formService.getForm().fhsaCurrency === this.constants.CURRENCY_CAD) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_LIRA && this.formService.getForm().liraCurrency === this.constants.CURRENCY_CAD) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_RRIF) ||
            (this.actType === this.constants.ACCOUNT_TRANSFER_LIF)) {

            this.formGroup.get('actTypeCurrency').setValue(this.constants.CURRENCY_CAD);
            this.formService.setFormProperty(this.getBindPath() + 'actTypeCurrency', this.constants.CURRENCY_CAD);
            this.updateControlEnabled(this.formGroup.get('actTypeCurrency'), false);

            // OCE-7367
            this.isActTypeCurrencyDisabled = true;
        }

        // auto select account type if account type is not cash
        if (this.actType !== this.constants.ACCOUNT_TRANSFER_CASH) {
            this.formGroup.get('accountGroup.accountType').setValue(this.actType);
            this.formService.setFormProperty(this.getBindPath() + 'accountType', this.actType);
            this.updateControlEnabled(this.formGroup.get('accountGroup.accountType'), false);
        }

        // disable transferCash
        if (this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL ||
            (this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL &&
                this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND)) {
            this.updateControlEnabled(this.formGroup.get('transferCash'), false);
        }


        // show or hide second account group
        if (this.formGroup.get('accountGroupSec.accountTypeSec').value ||
            this.formGroup.get('accountGroupSec.accountNumberSec').value ||
            this.formGroup.get('accountGroupSec.accountCurrencySec').value) {
            this.toggleAccountGroupSec(true);
        } else {
            this.toggleAccountGroupSec(false);
        }

        // auto set type in security group to default KIND
        if (this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL &&
            this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND) {

            this.updateControlEnabled(this.securityGroup, true);

            // OCE-7369
            this.isTransferFormSecurityTypeDisabled = true;

            for (let i = 0; i < this.securityGroup.length; i++) {
                this.securityGroup.get(i + '.type').setValue('KIND');
                this.formService.setFormProperty(this.getBindPath() + 'securities.' + i + '.type', 'KIND');
                // disable type
                this.updateControlEnabled(this.securityGroup.get(i + '.type'), false);
            }
        } else if ((this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL &&
                (this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_CASH ||
                    this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND)) ||
            (this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL &&
                this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_CASH) ||
            (!this.formGroup.get('transferType').value || !this.formGroup.get('transferInstructionType').value)
        ) {
            this.updateControlEnabled(this.securityGroup, false);
        }

        // ---------------------validation when change on the form--------------------------------//

        // validation on the security list
        this.formGroup.get('transferType').valueChanges.subscribe(value => {
            if (value === this.constants.TRANSFER_TYPE_PARTIAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_CASH) {
                this.updateControlEnabled(this.formGroup.get('transferCash'), true);
            } else if (value === this.constants.TRANSFER_TYPE_TOTAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_CASH) {
                this.updateControlEnabled(this.securityGroup, false);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

            } else if (value === this.constants.TRANSFER_TYPE_PARTIAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND) {
                this.setAllSecurityTypeKind();
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;

            } else if (value === this.constants.TRANSFER_TYPE_TOTAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND) {
                this.updateControlEnabled(this.securityGroup, false);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;

            } else if (value === this.constants.TRANSFER_TYPE_PARTIAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_MIXED) {
                this.unsetSecurityTypeKind();
                this.updateControlEnabled(this.formGroup.get('transferCash'), true);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = false;

            } else if (value === this.constants.TRANSFER_TYPE_TOTAL && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_MIXED) {
                this.updateControlEnabled(this.securityGroup, true);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = false;
            }

        });
        this.formGroup.get('transferInstructionType').valueChanges.subscribe(value => {
            if (value === this.constants.TRANSFER_INSTRUCTION_KIND && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL) {
                this.setAllSecurityTypeKind();
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;

            } else if (value === this.constants.TRANSFER_INSTRUCTION_MIXED && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL) {
                this.unsetSecurityTypeKind();
                this.updateControlEnabled(this.formGroup.get('transferCash'), true);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = false;

            } else if (value === this.constants.TRANSFER_INSTRUCTION_CASH && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL) {
                this.updateControlEnabled(this.securityGroup, false);
                this.updateControlEnabled(this.formGroup.get('transferCash'), true);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;

            } else if (value === this.constants.TRANSFER_INSTRUCTION_KIND && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL) {
                this.updateControlEnabled(this.securityGroup, false);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;

            } else if (value === this.constants.TRANSFER_INSTRUCTION_MIXED && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL) {
                this.updateControlEnabled(this.securityGroup, true);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = false;

            } else if (value === this.constants.TRANSFER_INSTRUCTION_CASH && this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL) {
                this.updateControlEnabled(this.securityGroup, false);
                this.formGroup.get('transferCash').setValue(null);
                this.formService.setFormProperty(this.getBindPath() + 'transferCash', null);
                this.updateControlEnabled(this.formGroup.get('transferCash'), false);

                // OCE-7369
                this.isTransferFormSecurityTypeDisabled = true;
            }

        });
    }

    disableWhenSaving(): boolean {
        return this.formService.saveInProgressWithoutModal() ? true : null;
    }

    displaySecurities(): boolean {
        return this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL
            && (
                this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND
                || this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_MIXED
            )
            || (
                this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_TOTAL
                && this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_MIXED
            );
    }

    displayTransferCash(): boolean {
        return this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL
            && (
                this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_CASH
                || this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_MIXED
            );
    }

    displayRemoveTransfer(): boolean {
        return false; // this.form.get(this.path).controls.length > 1;
    }

    unsetSecurityTypeKind() {
        this.updateControlEnabled(this.securityGroup, true);
        for (let i = 0; i < this.securityGroup.length; i++) {
            this.updateControlEnabled(this.securityGroup.get(i + '.type'), true);
        }
    }

    setAllSecurityTypeKind() {
        this.updateControlEnabled(this.securityGroup, true);
        for (let i = 0; i < this.securityGroup.length; i++) {
            this.securityGroup.get(i + '.type').setValue('KIND');
            this.formService.setFormProperty(this.getBindPath() + 'securities.' + i + '.type', 'KIND');
            this.updateControlEnabled(this.securityGroup.get(i + '.type'), false);
        }
    }


    isFieldInError(path: string) {
        const control: AbstractControl = this.form.get(path);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid
            && ((control.hasError('required') && this.ngFormRef.submitted) || !control.hasError('required'));
    }

    isNgFormSubmitted(): boolean {
        if (this.ngFormRef) {
            return this.ngFormRef.submitted;
        }
        return false;
    }


    toggleAccountGroupSec(toggle: boolean) {
        this.showAccountGroupSec = toggle;
        this.updateControlEnabled(this.formGroup.get('accountGroupSec'), this.showAccountGroupSec);
        if (!this.showAccountGroupSec) {
            this.formGroup.get('accountGroupSec.accountTypeSec').setValue(null);
            this.formService.setFormProperty(this.getBindPath() + 'accountTypeSec', null);
            this.formGroup.get('accountGroupSec.accountNumberSec').setValue(null);
            this.formService.setFormProperty(this.getBindPath() + 'accountNumberSec', null);
            this.formGroup.get('accountGroupSec.accountCurrencySec').setValue(null);
            this.formService.setFormProperty(this.getBindPath() + 'accountCurrencySec', null);

        }

        return false;
    }

    addSecurity() {
        if (this.securityGroup.length < 30) {
            for (let i = 0; i < 5; i++) {
                const newSecurity: AccountTransferSecurity = new AccountTransferSecurity();
                this.securities.push(newSecurity);
                this.securityGroup.push(this.buildSecurity(newSecurity));
                if (this.formGroup.get('transferType').value === this.constants.TRANSFER_TYPE_PARTIAL &&
                    this.formGroup.get('transferInstructionType').value === this.constants.TRANSFER_INSTRUCTION_KIND) {
                    this.securityGroup.get(this.securityGroup.length - 1 + '.type').setValue('KIND');
                    this.formService.setFormProperty(this.getBindPath() + 'securities.' + (this.securityGroup.length - 1) + '.type', 'KIND');
                    this.updateControlEnabled(this.securityGroup.get(this.securityGroup.length - 1 + '.type'), false);
                }
            }
        }

        return false;
    }

    onFileChange(event: any): void {
        if (this.config.TOGGLE_AKAMAI) {
            if (event?.target?.files?.length > 0 && event.target.files[0]) {
                const attachedFile: File = event.target.files[0];
                this.subscriptions.push(
                    this.attachedDocumentService.checkFileContent(attachedFile)
                        .subscribe((fileResponsePayload: any) => {
                            if (fileResponsePayload[0]?.CATEGORY === 'Malware') {
                                this.transferGroup.get(`${this.index}.accountStatementName`).setErrors({fileInfected: true});
                                this.removeAccountStatement(this.index);

                            } else {
                                if (fileResponsePayload[0]?.FILE_HASH) {
                                    this.saveFileContentHash(event, this.index, fileResponsePayload[0]?.FILE_HASH);
                                }
                                this.attachedDocumentService.onFileChange(event, this.getAccountTransferList(this.actType)[this.index].accountStatement,
                                    this.formGroup.get('accountStatementName'), this.formGroup.get('accountStatementContent'), this.formGroup.get('accountStatementContentHash'));
                            }

                        }, errors => {
                            this.postLoginActionService.registerAction(() => this.onFileChange(event));
                            this.onFileChangeError(errors, this.index);
                        }
                    )
                );
            }

        } else {
            this.attachedDocumentService.onFileChange(event, this.getAccountTransferList(this.actType)[this.index].accountStatement,
                this.formGroup.get('accountStatementName'), this.formGroup.get('accountStatementContent'), this.formGroup.get('accountStatementContentHash'));
        }
    }

    selectFile(name: string) {
        $('input[name="' + name + '"],select[name="' + name + '"]').first().click();
        return false;
    }

    removeAccountStatement(index: string) {
        const currentAccountTransfer = this.getAccountTransferList(this.actType)[index];

        this.deleteAttachedDocumentValidation(currentAccountTransfer.accountStatement.validation, index);

        currentAccountTransfer.accountStatement = new AttachedDocument();

        this.transferGroup.get(index + '.accountStatementName').setValue(null);
        this.transferGroup.get(index + '.accountStatementContent').setValue(null);
        this.transferGroup.get(index + '.accountStatementContentHash').setValue(null);
        this.transferGroup.get(index + '.uploadFile').setValue(null);
    }

    removeTransfer(type: string, index: number) {
        for (let i = 0; i < this.getAccountTransferList(type).length; i++) {
            $('#addressStreet' + i).typeahead('destroy');
        }
        this.getAccountTransferList(type).splice(index, 1);
        this.transferGroup.removeAt(index);

        setTimeout(() => {
            for (let i = 0; i < this.getAccountTransferList(type).length; i++) {
                this.addressCompleter.initialize($('#addressStreet' + i));
            }
        }, 0);

        return false;
    }

    getBindPath(): string {
        let newPath: string;
        if (this.bindPath) {
            newPath = this.bindPath + '.' + this.index + '.';
        }
        return newPath;
    }

    getFormPath(): string {
        let newPath: string;
        if (this.path) {
            newPath = this.path + '.' + this.index + '.';
        }
        return newPath;
    }

    private saveFileContentHash(event: any, index: string, hash: string): void {
        let currentAccountTransfer = this.getAccountTransferList(this.actType)[index];

        currentAccountTransfer.accountStatement.validation.contentHash = hash;
        currentAccountTransfer.accountStatement.validation.fileName = event.target.files[0].name;
        currentAccountTransfer.accountStatement.validation.requestId = this.formService.getForm().requestID;

        this.subscriptions.push(
            this.attachedDocumentService.saveAttachedDocumentValidation(currentAccountTransfer.accountStatement.validation)
                .subscribe(
                    (savedData: any) => {
                        currentAccountTransfer.accountStatement.validation = savedData.payload
                    },
                    errors => {
                        this.postLoginActionService.registerAction(() => this.onFileChange(event));
                        this.onFileChangeError(errors, index);
                    }
                )
        );
    }

    private deleteAttachedDocumentValidation(validation: AttachedDocumentValidation, index: string) {
        if (validation?.id) {
            this.subscriptions.push(
                this.attachedDocumentService.deleteAttachedDocumentValidation(validation)
                    .subscribe(
                        () => {
                        },
                        error => {
                            this.postLoginActionService.registerAction(() => this.removeAccountStatement(index));
                            this.onFileChangeError(error, index);
                        }
                    )
            );
        }
    }

    private onFileChangeError(errors: WebException, currentIndex: string) {
        if (errors) {
            if (errors.status !== HttpStatusCode.Unauthorized && errors.status !== HttpStatusCode.Forbidden) {
                this.verifyFileErrorMessageWithIndex(errors, currentIndex);
                this.focusOnErrorsList();
                this.getAccountTransferList(this.actType)[+currentIndex].accountStatement = new AttachedDocument();
            }
        }
    }
}
