<h2>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}</h2>

<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--AddSpouse-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="getPrefixedLabelKey('ACCOUNT_TYPE_RELATED_QUESTIONS', 'ADD_SPOUSE') | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="fieldPrefix + 'AddSpouse'"
        [name]="fieldPrefix + 'AddSpouse'"
        [path]="fieldPrefix + 'AddSpouse'"
        [binding]="'otherAddSpouse'"
    ></app-odc-field>

    <div *ngIf="showResQc()">
        <!--resQc-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_RES_QC' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="fieldPrefix + 'ResQc'"
            [name]="fieldPrefix + 'ResQc'"
            [path]="fieldPrefix + 'ResQc'"
            [binding]="'resQc'"
        ></app-odc-field>

        <div *ngIf="showSurvivorShip()">
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SURVIVORSHIP' | translate"
                [id]="fieldPrefix + 'SurvivorShip'"
                [name]="fieldPrefix + 'SurvivorShip'"
                [path]="fieldPrefix + 'SurvivorShip'"
                [list]="[{itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_WITHOUT_SURVIVORSHIP' | translate, itemValue:'WITHOUT'},
                    {itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_WITH_SURVIVORSHIP' | translate, itemValue:'WITH'}]"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="'survivorShip'"
            ></app-odc-field>
        </div>
    </div>

    <app-province-resident-question [form]="form" [submitted]="formSubmitted"></app-province-resident-question>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
