import {Directive, ElementRef} from '@angular/core';
import {HostListener} from '@angular/core';

declare let $: any;

@Directive({
    selector: '[appIFrameRefresh]'
})
export class IframeRefreshDirective {

    hidden = false;

    constructor(private el: ElementRef) {
    }

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange() {
        if (document.visibilityState === 'visible' && this.hidden === true) {
            const src = $(this.el.nativeElement).attr('src');
            $(this.el.nativeElement).attr('src', src);
        } else {
            this.hidden = true;
        }
    }
}
