import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder, Validators} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {ModalService} from '../../components/services/modal.service';

declare let $: any;

const MODAL_CONTEXT_OPTIONS_ACCOUNT = 'MODAL_CONTEXT_OPTIONS_ACCOUNT';
const MODAL_CONTEXT_OPTIONS_CONTINUE_BACK = 'MODAL_CONTINUE_BACK';
const MODAL_CONTEXT_OPTIONS_SPREADS_CONTINUE_BACK = 'MODAL_CONTINUE_BACK_SPREADS';

@Component({
    selector: 'app-options-account',
    templateUrl: './options-account.component.html'
})
export class OptionsAccountComponent extends BaseNavComponent implements OnInit {

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private modalService: ModalService,
                private translatePipe: TranslatePipe) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            this.uncheckOptionActTradingPlannedOperationLevel3(context);
            this.uncheckOptionActTradingPlannedOperationLevel4(context);
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe((context: string) => {
            if (MODAL_CONTEXT_OPTIONS_ACCOUNT === context) {
                // remove option
                this.formService.setFormProperty('optionAct', false);

                // this step is not available if optionAct is false. Move to next step
                this.navNext();
            }
            this.setTrueShortSellAct(context);
        }));

        this.form = this.fb.group({
            optionActOtherBrokerIndicator: [this.formService.getForm().optionActOtherBrokerIndicator, this.requiredValidator('optionActOtherBrokerIndicator')],
            optionActOtherBrokerFirmName: [this.formService.getForm().optionActOtherBrokerFirmName, this.requiredValidator('optionActOtherBrokerFirmName')],
            optionActExperienceSpecification: [this.formService.getForm().optionActExperienceSpecification, this.requiredValidator('optionActExperienceSpecification')],
            optionActTradingExperienceIndicator: [this.formService.getForm().optionActTradingExperienceIndicator, this.requiredValidator('optionActTradingExperienceIndicator')],

            optionActTradingTheoriqueKnowledgeIndicator: [this.formService.getForm().optionActTradingTheoriqueKnowledgeIndicator, this.requiredValidator('optionActTradingTheoriqueKnowledgeIndicator')],

            optionActCheckboxGroup: this.fb.group({
                optionActTradingExperienceOperationLevel1: [this.formService.getForm().optionActTradingExperienceOperationLevel1],
                optionActTradingExperienceYears1: [this.formService.getForm().optionActTradingExperienceYears1, [this.requiredValidator('optionActTradingExperienceYears1'), VmdValidators.digit, Validators.min(1), Validators.max(99)]],
                optionActTradingPlannedOperationLevel1: [this.formService.getForm().optionActTradingPlannedOperationLevel1],
                optionActTradingExperienceOperationLevel2: [this.formService.getForm().optionActTradingExperienceOperationLevel2],
                optionActTradingExperienceYears2: [this.formService.getForm().optionActTradingExperienceYears2, [this.requiredValidator('optionActTradingExperienceYears2'), VmdValidators.digit, Validators.min(1), Validators.max(99)]],
                optionActTradingPlannedOperationLevel2: [this.formService.getForm().optionActTradingPlannedOperationLevel2],
                optionActTradingExperienceOperationLevel3: [this.formService.getForm().optionActTradingExperienceOperationLevel3],
                optionActTradingExperienceYears3: [this.formService.getForm().optionActTradingExperienceYears3, [this.requiredValidator('optionActTradingExperienceYears3'), VmdValidators.digit, Validators.min(1), Validators.max(99)]],
                optionActTradingPlannedOperationLevel3: [this.formService.getForm().optionActTradingPlannedOperationLevel3],
                optionActTradingExperienceOperationLevel4: [this.formService.getForm().optionActTradingExperienceOperationLevel4],
                optionActTradingExperienceYears4: [this.formService.getForm().optionActTradingExperienceYears4, [this.requiredValidator('optionActTradingExperienceYears4'), VmdValidators.digit, Validators.min(1), Validators.max(99)]],
                optionActTradingPlannedOperationLevel4: [this.formService.getForm().optionActTradingPlannedOperationLevel4],
            }, {validator: VmdValidators.optionActTradingPlannedGroupValidator})

        });

        // validation when loading the form
        this.updateControlEnabled(this.form.get('optionActOtherBrokerFirmName'), this.form.get('optionActOtherBrokerIndicator').value);
        this.updateControlEnabled(this.form.get('optionActExperienceSpecification'), this.form.get('optionActTradingExperienceIndicator').value && !this.form.get('optionActOtherBrokerIndicator').value);
        this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears1'), this.form.get('optionActTradingExperienceIndicator').value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel1').value);
        this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears2'), this.form.get('optionActTradingExperienceIndicator').value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel2').value);
        this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears3'), this.form.get('optionActTradingExperienceIndicator').value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel3').value);
        this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears4'), this.form.get('optionActTradingExperienceIndicator').value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel4').value);
        this.updateControlEnabled(this.form.get('optionActTradingTheoriqueKnowledgeIndicator'), this.form.get('optionActTradingExperienceIndicator').value === false);
        if (this.form.get('optionActTradingExperienceIndicator').value === true) {
            // add Trading Experience Validator
            this.form.get('optionActCheckboxGroup').setValidators([VmdValidators.optionActTradingPlannedGroupValidator, VmdValidators.optionActTradingExperienceGroupValidator]);
        }

        // validation when change the form
        this.form.get('optionActOtherBrokerIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActOtherBrokerFirmName'), value);
            if (!value) {
                // clear value of broker firm name
                this.form.get('optionActOtherBrokerFirmName').setValue(null);
                this.formService.setFormProperty('optionActOtherBrokerFirmName', null);
            }
        });
        this.nullifyAndSetMandatoryStatusForSpecificationExperience();

        this.form.get('optionActTradingExperienceIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears1'), value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel1').value);
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears2'), value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel2').value);
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears3'), value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel3').value);
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears4'), value && this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel4').value);
            this.updateControlEnabled(this.form.get('optionActTradingTheoriqueKnowledgeIndicator'), !value);
            if (!value) {
                // remove Trading Experience Validator
                this.form.get('optionActCheckboxGroup').setValidators([VmdValidators.optionActTradingPlannedGroupValidator]);
                // clear number of year experience
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears1').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceYears1', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears2').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceYears2', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears3').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceYears3', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears4').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceYears4', null);
                // clear trade experience Checkbox
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel1').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceOperationLevel1', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel2').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceOperationLevel2', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel3').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceOperationLevel3', null);
                this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel4').setValue(null);
                this.formService.setFormProperty('optionActTradingExperienceOperationLevel4', null);

            } else {
                // add Trading Experience Validator
                this.form.get('optionActCheckboxGroup').setValidators([VmdValidators.optionActTradingPlannedGroupValidator, VmdValidators.optionActTradingExperienceGroupValidator]);
                // clear
                this.form.get('optionActTradingTheoriqueKnowledgeIndicator').setValue(null);
                this.formService.setFormProperty('optionActTradingTheoriqueKnowledgeIndicator', null);
            }
        });

        this.form.get('optionActTradingTheoriqueKnowledgeIndicator').valueChanges.subscribe(value => {
            this.disableOptionActs();
        });

        this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel1').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears1'), value && this.form.get('optionActTradingExperienceIndicator').value);
        });

        this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel2').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears2'), value && this.form.get('optionActTradingExperienceIndicator').value);
        });

        this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel3').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears3'), value && this.form.get('optionActTradingExperienceIndicator').value);
        });

        this.form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel4').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActCheckboxGroup.optionActTradingExperienceYears4'), value && this.form.get('optionActTradingExperienceIndicator').value);
        });

        this.enableAccountFormModal();
    }

    enableAccountFormModal() {

        this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel3').valueChanges.subscribe(value => {
            const shortSellAct = this.formService.getForm().shortSellAct;
            const optionAct = this.formService.getForm().optionAct;
            if (value && optionAct && !shortSellAct) {
                this.modalService.openModal('ACCOUNT_FORM_ACCOUNT_OPTIONS_TITLE', 'ACCOUNT_FORM_ACCOUNT_OPTIONS_SPREADS_MSG', '#modal-continue-back', MODAL_CONTEXT_OPTIONS_SPREADS_CONTINUE_BACK);
            }
        });

        this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel4').valueChanges.subscribe(value => {
            const shortSellAct = this.formService.getForm().shortSellAct;
            if (value && !shortSellAct) {
                this.modalService.openModal('ACCOUNT_FORM_ACCOUNT_OPTIONS_TITLE', 'ACCOUNT_FORM_ACCOUNT_OPTIONS_MSG', '#modal-continue-back', MODAL_CONTEXT_OPTIONS_CONTINUE_BACK);
            }
        });
    }

    nullifyAndSetMandatoryStatusForSpecificationExperience() {

        this.form.get('optionActOtherBrokerIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActExperienceSpecification'), value);

            // reset experience field when broker indicator is checked
            if (value) {
                this.form.get('optionActExperienceSpecification').setValue(null);
                this.formService.setFormProperty('optionActExperienceSpecification', null);
                this.form.get('optionActExperienceSpecification').setValidators([VmdValidators.required(false)]);
            }

            // make experience field mandatory
            if (!value && this.form.get('optionActTradingExperienceIndicator').value) {
                this.updateControlEnabled(this.form.get('optionActExperienceSpecification'), this.form.get('optionActTradingExperienceIndicator').value && !this.form.get('optionActOtherBrokerIndicator').value);
                this.form.get('optionActExperienceSpecification').setValidators([VmdValidators.required(true)]);
            }

        });

        this.form.get('optionActTradingExperienceIndicator').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('optionActExperienceSpecification'), value);

            // reset experience field
            if (!value && this.form.get('optionActOtherBrokerIndicator').value) {
                this.form.get('optionActExperienceSpecification').setValue(null);
                this.formService.setFormProperty('optionActExperienceSpecification', null);
                this.form.get('optionActExperienceSpecification').setValidators([VmdValidators.required(false)]);
            }
        });
    }

    setTrueShortSellAct(context: string) {
        if (MODAL_CONTEXT_OPTIONS_CONTINUE_BACK === context || MODAL_CONTEXT_OPTIONS_SPREADS_CONTINUE_BACK === context) {
            this.formService.setFormProperty('shortSellAct', true);
        }
    }

    uncheckOptionActTradingPlannedOperationLevel3(context: string) {
        if (MODAL_CONTEXT_OPTIONS_SPREADS_CONTINUE_BACK === context) {
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel3').setValue(false);
            this.formService.setFormProperty('optionActTradingPlannedOperationLevel3', false);
        }
    }

    uncheckOptionActTradingPlannedOperationLevel4(context: string) {
        if (MODAL_CONTEXT_OPTIONS_CONTINUE_BACK === context) {
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel4').setValue(false);
            this.formService.setFormProperty('optionActTradingPlannedOperationLevel4', false);
        }
    }

    closeModalAlert(missingTypeOfOptionTradesList: number[]) {
        // auto check missed checkbox
        for (const i of missingTypeOfOptionTradesList) {
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel' + i).setValue(true);
            this.formService.setFormProperty('optionActTradingPlannedOperationLevel' + i, true);
        }
    }

    disableOptionActs() {
        this.disableOptionAct(3);
        this.disableOptionAct(4);
    }

    disableOptionAct(index) {
        if (this.isDisabledOptionAct(index)) {
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel' + index).setValue(false);
            this.formService.setFormProperty('optionActTradingPlannedOperationLevel' + index, false);
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel' + index).disable();
        } else {
            this.form.get('optionActCheckboxGroup.optionActTradingPlannedOperationLevel' + index).enable();
        }
    }

    getMissingTypeOfOptionTradesList(): number[] {
        const missingList: number[] = [];
        let checked = false;

        for (const i of [4, 3, 2, 1]) {
            if (this.formService.getForm()['optionActTradingPlannedOperationLevel' + i]) {
                checked = true;
            }
            if (checked && !this.formService.getForm()['optionActTradingPlannedOperationLevel' + i]) {
                missingList.unshift(i);
            }
        }

        return missingList;
    }

    isDisabledOptionAct(index): boolean {
        return (index === 3 || index === 4)
            && this.form.get('optionActTradingExperienceIndicator').value === false
            && this.form.get('optionActTradingTheoriqueKnowledgeIndicator').value;
    }

    getMissingTypeOfOptionTradesLabelList(missingTypeOfOptionTradesList: number[], level1: string, level2: string, level3: string, level4: string): string {

        const levelLabelMap = {
            1: level1,
            2: level2,
            3: level3,
            4: level4
        };

        let labelList = '<ul>';

        for (const i of missingTypeOfOptionTradesList) {
            labelList += '<li>' + levelLabelMap[i] + '</li>';
        }

        labelList += '</ul>';

        return labelList;
    }

    openModalAlert(missingTypeOfOptionTradesList: number[]): void {
        if (!$('#modal-alert').length) {
            return;
        }

        $('#modal-alert').on('hidden.bs.modal', (e) => {
            this.closeModalAlert(missingTypeOfOptionTradesList);
        });

        const title = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_TITLE');
        let body = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_BODY_TYPES_OF_OPTION_TRADES');
        const level1 = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1');
        const level2 = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2');
        const level3 = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3');
        const level4 = this.translatePipe.transform('ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4');

        body += this.getMissingTypeOfOptionTradesLabelList(missingTypeOfOptionTradesList, level1, level2, level3, level4);

        $('#modal-alert .modal-title').html(title);
        $('#modal-alert .modal-body').html(body);
        this.modalService.openModal(null, null, '#modal-alert', MODAL_CONTEXT_OPTIONS_ACCOUNT);
    }

    openModalConfirmOptionAccount(): void {
        if (!$('#modal-alert-personal-information').length) {
            return;
        }

        this.modalService.openModal('ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_TITLE', 'ODC_STEP_LEGAL_OPTION_ACCOUNT_CONFIRM_MODAL_BODY', '#modal-alert-personal-information', MODAL_CONTEXT_OPTIONS_ACCOUNT);
    }

    processRules(): boolean {
        return this.processRulesMustHaveTheoreticalKnowledgeForTradeOptions()
            || this.getMissingTypeOfOptionTradesList().length > 0;
    }

    processRulesMustHaveTheoreticalKnowledgeForTradeOptions(): boolean {
        return this.formService.getForm().optionActTradingExperienceIndicator === false
            && this.formService.getForm().optionActTradingTheoriqueKnowledgeIndicator === false;
    }

    submit() {
        if (this.processRules()) {
            const missingTypeOfOptionTradesList: number[] = this.getMissingTypeOfOptionTradesList();

            if (this.processRulesMustHaveTheoreticalKnowledgeForTradeOptions()) {
                this.openModalConfirmOptionAccount();
            } else if (missingTypeOfOptionTradesList.length > 0) {
                this.openModalAlert(missingTypeOfOptionTradesList);
            }
        } else {
            this.submitForm();
        }
    }

}
