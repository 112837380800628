export class CreateProfileBean {

    lang: string;
    email: string;
    securityQuestion: string;
    securityAnswer: string;
    reCaptchaResponse: string;

    constructor(obj?: any) {
        this.lang = obj && obj.lang || null;
        this.email = obj && obj.email || null;
        this.securityQuestion = obj && obj.securityQuestion || null;
        this.securityAnswer = obj && obj.securityAnswer || null;
        this.reCaptchaResponse = obj && obj.reCaptchaResponse || null;
    }
}
