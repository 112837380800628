import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';

@Component({
    selector: 'app-investment-objectives-and-risk-factors',
    templateUrl: './investment-objectives-and-risk-factors.component.html'
})
export class InvestmentObjectivesAndRiskFactorsComponent extends BaseNavComponent implements OnInit {

    codeIntendedUsage: HtmlSelectKV[];
    codeInvestmentHorizon: HtmlSelectKV[];
    codeRiskClass: HtmlSelectKV[];
    codeRiskTolerance: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.initInvestmentObjectivesValues();

        const investmentObjectivesMinValidator = this.formService.isPrintMode() ? Validators.nullValidator : Validators.min(0);
        const investmentObjectivesMaxValidator = this.formService.isPrintMode() ? Validators.nullValidator : Validators.max(100);
        this.form = this.fb.group({
            userInvestmentObjectivesIntendedUse: [this.formService.getForm().userInvestmentObjectivesIntendedUse, [this.requiredValidator('userInvestmentObjectivesIntendedUse')]],
            userInvestmentObjectivesNonRegInvHorizon: [this.formService.getForm().userInvestmentObjectivesNonRegInvHorizon, [this.requiredValidator('userInvestmentObjectivesNonRegInvHorizon')]],
            userInvestmentObjectivesRskTol: [this.formService.getForm().userInvestmentObjectivesRskTol, [this.requiredValidator('userInvestmentObjectivesRskTol')]],

            userInvestmentObjectivesLowRiskNonReg: [this.formService.getForm().userInvestmentObjectivesLowRiskNonReg, [this.requiredValidator('userInvestmentObjectivesLowRiskNonReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
            userInvestmentObjectivesModerateRiskNonReg: [this.formService.getForm().userInvestmentObjectivesModerateRiskNonReg, [this.requiredValidator('userInvestmentObjectivesModerateRiskNonReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
            userInvestmentObjectivesModHighRiskNonReg: [this.formService.getForm().userInvestmentObjectivesModHighRiskNonReg, [this.requiredValidator('userInvestmentObjectivesModHighRiskNonReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
            userInvestmentObjectivesSpeculativeStrategiesNonReg: [this.formService.getForm().userInvestmentObjectivesSpeculativeStrategiesNonReg, [this.requiredValidator('userInvestmentObjectivesSpeculativeStrategiesNonReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],

            includeInvestorProfile: [this.formService.getForm().includeInvestorProfile, [this.requiredValidator('includeInvestorProfile')]],

            registered: this.fb.group({
                userInvestmentObjectivesRegInvHorizon: [this.formService.getForm().userInvestmentObjectivesRegInvHorizon, [this.requiredValidator('userInvestmentObjectivesRegInvHorizon')]],
                userInvestmentObjectivesLowRiskReg: [this.formService.getForm().userInvestmentObjectivesLowRiskReg, [this.requiredValidator('userInvestmentObjectivesLowRiskReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
                userInvestmentObjectivesModerateRiskReg: [this.formService.getForm().userInvestmentObjectivesModerateRiskReg, [this.requiredValidator('userInvestmentObjectivesModerateRiskReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
                userInvestmentObjectivesModHighRiskReg: [this.formService.getForm().userInvestmentObjectivesModHighRiskReg, [this.requiredValidator('userInvestmentObjectivesModHighRiskReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]],
                userInvestmentObjectivesSpeculativeStrategiesReg: [this.formService.getForm().userInvestmentObjectivesSpeculativeStrategiesReg, [this.requiredValidator('userInvestmentObjectivesSpeculativeStrategiesReg'), investmentObjectivesMinValidator, investmentObjectivesMaxValidator]]
            })

        }, {validator: [VmdValidators.investmentObjectives(this.formService.isPrintMode())]});

        this.updateControlEnabled(this.form.get('registered'), this.shouldDisplayRegisteredAccount());

        const investmentObjectivesPaths = [
            'userInvestmentObjectivesLowRiskNonReg',
            'userInvestmentObjectivesModerateRiskNonReg',
            'userInvestmentObjectivesModHighRiskNonReg',
            'userInvestmentObjectivesSpeculativeStrategiesNonReg',
            'registered.userInvestmentObjectivesLowRiskReg',
            'registered.userInvestmentObjectivesModerateRiskReg',
            'registered.userInvestmentObjectivesModHighRiskReg',
            'registered.userInvestmentObjectivesSpeculativeStrategiesReg'
        ];
        for (const path of investmentObjectivesPaths) {
            this.form.get(path).valueChanges.subscribe(value => {
                if (value === null || value === '') {
                    this.form.get(path).setValue(0);
                }
            });
        }

        this.listModelService.getListModel(this.getCodeIntendedUsageListName(), data => this.codeIntendedUsage = data);
        this.listModelService.getListModel('codeInvestmentHorizon', data => this.codeInvestmentHorizon = data);
        this.listModelService.getListModel('codeRiskClass', data => this.codeRiskClass = data);
        this.listModelService.getListModel('codeRiskTolerance', data => this.codeRiskTolerance = data);
    }

    getCodeIntendedUsageListName(): string {
        const defaultListName = 'codeIntendedUsage';
        const entityListName = 'codeIntendedUsageEntity';

        if (!this.isGpdOrSfd()) {
            return defaultListName;
        }

        const isEntity: boolean = this.isOtherAccountCorporation() ||
            this.isOtherAccountEntrepriseIndividuelle() ||
            this.isOtherAccountFiducie() ||
            this.isOtherAccountCooperative() ||
            this.isOtherAccountOSBL() ||
            this.isOtherAccountOrganisationGouvernementale() ||
            this.isOtherAccountAssociation() ||
            this.isOtherAccountSocieteCommandite() ||
            this.isOtherAccountRRI() ||
            this.isOtherAccountSENC();

        if (!isEntity) {
            return defaultListName;
        }

        return entityListName;
    }

    initInvestmentObjectivesValues(): void {

        const investmentObjectivesPaths = [
            'userInvestmentObjectivesLowRiskNonReg',
            'userInvestmentObjectivesModerateRiskNonReg',
            'userInvestmentObjectivesModHighRiskNonReg',
            'userInvestmentObjectivesSpeculativeStrategiesNonReg',
            'userInvestmentObjectivesLowRiskReg',
            'userInvestmentObjectivesModerateRiskReg',
            'userInvestmentObjectivesModHighRiskReg',
            'userInvestmentObjectivesSpeculativeStrategiesReg'
        ];

        for (const path of investmentObjectivesPaths) {
            if (null === this.formService.getForm()[path]) {
                this.formService.setFormProperty(path, 0);
            }
        }
    }

    requestHasRegisteredAccount(): boolean {
        return this.formService.getForm().hasRegisteredAccount();
    }

    shouldDisplayRegisteredAccount(): boolean {
        const path = !this.isPleinEx();
        return path && this.requestHasRegisteredAccount();
    }

    getSpeculativeStrategiesAttributes(): object {

        var attrs:any = { maxlength: 3 };
        if (this.isGpdOrSfd()) {

            attrs.disabled = true;
        }

        return attrs;
    }
}
