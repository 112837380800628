import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {VmdValidators} from '../../../components/validators/vmd-validators';
import {ListModelService} from '../../../components/services/list-model.service';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormService} from '../../../components/services/form.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {ModalService} from '../../../components/services/modal.service';

@Component({
    selector: 'app-fiducie-related-questions',
    templateUrl: './fiducie-related-questions.component.html'
})
export class FiducieRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    fiducieTypeList: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService,
                protected modalService: ModalService) {
        super(navigationService, fb, formService, translate, modalService);
    }

    ngOnInit() {
        this.form = this.fb.group({
            fiducieType: [this.formService.getForm().otherFiducieType, [Validators.required]],
            fiducieHasAmericanResident: [this.formService.getForm().otherAccountHasAmericanResident, [Validators.required]],
            fiducieActAmericanPeople: [this.formService.getForm().actAmericanPeople, [Validators.required, VmdValidators.digit, Validators.min(0), Validators.max(10)]],
            fiducieActPeople: [this.formService.getForm().otherActPeople, [Validators.required, VmdValidators.digit, Validators.min(0), Validators.max(10)]]
        });

        this.listModelService.getListModel('fiducieTypeList', data => this.fiducieTypeList = data);

        this.form.get('fiducieType').valueChanges.subscribe((value) => {
            if (this.isSimpleFiducie()) {
                this.form.get('fiducieHasAmericanResident').setValue(null);
                this.formService.setFormProperty('otherAccountHasAmericanResident', null);
            }
            this.navigationService.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH', !this.isSimpleFiducie());
            this.navigationService.setSubTabDisable('ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH', !this.isSimpleFiducie());
            this.updateControls();
        });

        this.updateControls();
    }

    private updateControls() {
        this.updateControlEnabled(this.form.get('fiducieHasAmericanResident'), this.isComplexFiducie());
        this.updateControlEnabled(this.form.get('fiducieActAmericanPeople'), this.isSimpleFiducie());
        this.updateControlEnabled(this.form.get('fiducieActPeople'), this.isSimpleFiducie());
    }

    private isSimpleFiducie(): boolean {
        return this.form.get('fiducieType').value === VmdConstants.OTHER_FIDUCIE_TYPE_GRANTOR_SIMPLE;
    }

    private isComplexFiducie(): boolean {
        return this.form.get('fiducieType').value === VmdConstants.OTHER_FIDUCIE_TYPE_COMPLEX;
    }
}
