import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'moment',
    pure: false
})
export class MomentPipe implements PipeTransform {

    transform(date: any, format: string, inputFormat: string = 'YYYY-MM-DD HH:mm:ss.SSS'): any {
        if (date === '0000-00-00') {
            return null;
        }
        if (date !== null) {
            let m: any;
            if (typeof date === 'number') {
                m = moment(date * 1000);
            } else {
                m = moment(date, inputFormat);
            }

            return m.format(format);
        }

        return date;
    }

}
