<h2>{{'CONFIRMATION_TITLE'| translate}}</h2>

<div class="p-20 bg-concrete text-align-justify">

    <!-- Section for esign and status -->
    <div
        *ngIf="!formService.getForm().transmissionMethod || formService.getForm().transmissionMethod === constants.TRANSMISSION_MAIL">
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="confirmationMailEn"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang !== 'en'">
            <ng-container *ngTemplateOutlet="confirmationMailFr"></ng-container>
        </ng-container>
    </div>

    <!-- Button to print the account opening kit -->
    <div *ngIf="formService.getForm().transmissionMethod === constants.TRANSMISSION_ESIGN_MAIL">
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="printAccountOpeningKitEn"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang !== 'en'">
            <ng-container *ngTemplateOutlet="printAccountOpeningKitFr"></ng-container>
        </ng-container>
        <div class="panel panel-default panel-noborder">
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-6">
                        <img [src]="'assets/img/pdf-download.png'" alt="">
                    </div>
                    <div class="col-md-16">
                        <div>
                            <button class="btn btn-primary btn-block loadable m-t-5"
                                    (click)="downloadPDF()"
                                    [class.loading]="loadingDownload"
                                    [attr.disabled]="loadingDownload ? true : null">
                                <i class="fa fa-arrow-circle-down"></i>
                                {{'TRANSMISSION_MAIL_DOWNLOAD_BTN' | translate}}
                            </button>
                        </div>
                        <div *ngIf="error !== null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Section for esign and status -->
    <div
        *ngIf="formService.getForm().transmissionMethod === constants.TRANSMISSION_ESIGN || formService.getForm().transmissionMethod === constants.TRANSMISSION_ESIGN_MAIL">
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="esignAndStatusEn"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang !== 'en'">
            <ng-container *ngTemplateOutlet="esignAndStatusFr"></ng-container>
        </ng-container>
    </div>

    <!-- Section for fund the account -->
    <ng-container *ngIf="translate.currentLang === 'en'">
        <ng-container *ngTemplateOutlet="fundAccountEn"></ng-container>
    </ng-container>
    <ng-container *ngIf="translate.currentLang !== 'en'">
        <ng-container *ngTemplateOutlet="fundAccountFr"></ng-container>
    </ng-container>

    <!-- Transfer -->
    <div *ngIf="hasTransfer()">
        <ng-container *ngIf="translate.currentLang === 'en'">
            <ng-container *ngTemplateOutlet="transferEn"></ng-container>
        </ng-container>
        <ng-container *ngIf="translate.currentLang !== 'en'">
            <ng-container *ngTemplateOutlet="transferFr"></ng-container>
        </ng-container>
    </div>

</div>

<ng-template #confirmationMailEn>
    <p>Your Open Account Forms Kit is now complete. Please see the first page for a list of documents to provide and
        instructions to help you complete them.</p>
</ng-template>
<ng-template #confirmationMailFr>
    <p>La trousse de formulaires d’ouverture de compte est maintenant complète. Veuillez consulter la 1<sup>re</sup>
        page afin de connaître la liste des documents à fournir et les instructions qui vous aideront à les remplir.</p>
</ng-template>

<ng-template #printAccountOpeningKitEn>
    <p>Please send us all completed and signed documents and forms by mail as soon as possible.</p>
</ng-template>
<ng-template #printAccountOpeningKitFr>
    <p>Veuillez nous faire parvenir tous les documents et formulaires originaux dûment remplis et signés par courrier
        dans les plus brefs délais.</p>
</ng-template>

<ng-template #esignAndStatusEn>
    <h3 class="h2">E-sign</h3>
    <p>You have completed your part of the job!</p>
    <p>Here are the next steps for the client:</p>
    <ol>
        <li>An email will be sent to the client to access the secure site for the authentication process;</li>
        <li>A validation code will be sent via SMS to the client's cell phone to complete the authentication process;
        </li>
        <li>The electronic signature consent must first be accepted and the forms must be signed electronically;</li>
        <li>Subsequently, a confirmation message will indicate that the process is complete;</li>
        <li>Finally, an email will be sent to the client to allow them to access the signed online application.</li>
    </ol>

    <p>A demonstration of these steps is available <a href="{{ 'CONFIRMATION_CAISSE_VIDEO_DEMONSTRATION_LINK' | translate }}" class="lien-externe" target="_blank">in this</a> video clip.</p>
    <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>

    <h3 class="h2">Status of the request</h3>
    <p>You can follow the status of the request on the <strong>Requests Follow-Up</strong> page.</p>
    <p>The account number will be assigned once the request status is <strong>finalized</strong>.</p>
</ng-template>
<ng-template #esignAndStatusFr>
    <h3 class="h2">Signature électronique</h3>
    <p>Vous avez terminé votre partie du travail!</p>
    <p>Voici les prochaines étapes à effectuer par le client :</p>
    <ol>
        <li>Un courriel lui sera acheminé pour accéder au processus d’authentification;</li>
        <li>Un code d’accès unique par texto lui sera transmis sur son téléphone cellulaire;</li>
        <li>Le consentement à la signature électronique doit d’abord être approuvé et les formulaires signés de manière
            électronique;
        </li>
        <li>Par la suite, un message de confirmation lui indiquera que le processus est terminé;</li>
        <li>Enfin, un courriel sera envoyé au client pour lui permettre d’avoir accès à la demande signée en ligne.</li>
    </ol>

    <p>Une démonstration de ces étapes est disponible <a href="{{ 'CONFIRMATION_CAISSE_VIDEO_DEMONSTRATION_LINK' | translate }}" class="lien-externe" target="_blank">ici</a> sous forme de capsule vidéo.</p>
    <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>

    <h3 class="h2">Statut de la demande</h3>
    <p>Vous pouvez suivre l’état de la demande à la page <strong>suivi des demandes</strong>.</p>
    <p>Le numéro de compte sera attribué une fois que la demande à comme statut <strong>finalisée</strong>.</p>
</ng-template>

<ng-template #fundAccountEn>
    <h3 class="h2">Fund the account</h3>
    <p>Once the account number has been obtained, you can fund it by transferring money from a caisse account to the
        Desjardins Online Brokerage account through SIPC payments. For more information on the SIPC codes to use, please
        refer to the link in the section on the right.</p>
</ng-template>
<ng-template #fundAccountFr>
    <h3 class="h2">Approvisionner le compte</h3>
    <p>Une fois le numéro de compte obtenu, vous pourrez l’approvisionner en transférant un montant du compte de la
        caisse vers le compte Desjardins Courtage en ligne par SIPC. Pour plus d’informations sur les codes SIPC à
        utiliser, veuillez vous référer au lien de la section de droite.</p>
</ng-template>

<ng-template #transferEn>
    <h3 class="h2">Transfer</h3>
    <p>Securities or funds being transferred from a financial institution other than Desjardins may take up to two weeks
        to become available in the online brokerage account.</p>
</ng-template>
<ng-template #transferFr>
    <h3 class="h2">Transfert </h3>
    <p>Il est possible que les titres ou les fonds d’un transfert en provenance d’une autre institution financière que
        Desjardins prennent jusqu’à deux (2) semaines avant d’être disponibles dans le compte de courtage en ligne.</p>
</ng-template>
