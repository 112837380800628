import {Pipe, PipeTransform} from '@angular/core';
import {SummaryFieldsConstants} from '../../constants/update-account-delta-review-page/summary-fields-constants';

@Pipe({
    name: 'formatToSummaryQuestion'
})
export class FormatToSummaryQuestionPipe implements PipeTransform {

    transform(value: string): any {
        return SummaryFieldsConstants[value][0] ? SummaryFieldsConstants[value][0] : '';
    }
}
