export class UserIdentity {

    code: string;
    idNumber: string;
    expirationDate: string;
    province: string;
    country: string;

    constructor(obj?: any) {

        this.code = obj && obj.code || null;
        this.idNumber = obj && obj.idNumber || null;
        this.expirationDate = obj && obj.expirationDate || null;
        this.province = obj && obj.province || null;
        this.country = obj && obj.country || null;
    }
}
