import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-modal-pdf-multi-lang',
    templateUrl: './modal-pdf-multi-lang.component.html',
    styleUrls: ['./modal-pdf-multi-lang.component.css']
})
export class ModalPdfMultiLangComponent implements OnInit {

    @Input() loading = false;
    @Input() error: string = null;

    constructor() {
    }

    ngOnInit(): void {
    }
}
