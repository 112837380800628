import {Component} from '@angular/core';
import {OccupationComponent} from './occupation.component';

@Component({
    selector: 'app-occupation-third-holder',
    templateUrl: './occupation.component.html'
})
export class OccupationComponentThirdHolderComponent extends OccupationComponent {

    public requesterIndex = 2;

}
