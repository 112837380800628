import {Component} from '@angular/core';
import {AccountHolderInformationChildComponent} from './account-holder-information-child.component';

@Component({
    templateUrl: './account-holder-information.component.html'
})
export class AccountHolderInformationChildEsQualitesComponent extends AccountHolderInformationChildComponent {

    public displayChildDobAndSin(): boolean {
        return true;
    }

    public getDOBLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_DOB';
    }

    public getSINLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_SIN';
    }

}
