<div id="default-begin-component-id">
    <h2>{{ 'STEP_BEGIN_TITLE' | translate }}</h2>
    <ul>
        <li [innerHTML]="'STEP_BEGIN_BANNER_1' | translate "></li>
        <li [innerHTML]="'STEP_BEGIN_BANNER_2' | translate "></li>
    </ul>

    <h2 class="m-t-5">{{ 'STEP_BEGIN_TRADES_TITLE' | translate }}</h2>
    <div>{{ 'STEP_BEGIN_TRADES_DESC' | translate }}</div>

    <h3>{{ 'STEP_BEGIN_TRADES_SECURITIES_TITLE' | translate }}</h3>
    <ul>
        <li>{{ 'STEP_BEGIN_TRADES_SECURITIES_STOCKS_OPTIONS' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_SECURITIES_BONDS' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_SECURITIES_FUNDS' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_SECURITIES_ETF' | translate }}</li>
    </ul>

    <h3>{{ 'STEP_BEGIN_TRADES_PLANS_TITLE' | translate }}</h3>
    <ul>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_NON_REGISTERED' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_RRSP' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_TFSA' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_FHSA' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_RESP' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_LIRA' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_RRIF' | translate }}</li>
        <li>{{ 'STEP_BEGIN_TRADES_PLANS_LIF' | translate }}</li>
    </ul>

    <h2>
        {{ 'STEP_BEGIN_ELIGIBILITY_TITLE' | translate }}
    </h2>
    <div>{{ 'STEP_BEGIN_ELIGIBILITY_DESC' | translate }}</div>

    <ol>
        <li>{{ 'STEP_BEGIN_ELIGIBILITY_1' | translate }}</li>
        <li>{{ 'STEP_BEGIN_ELIGIBILITY_2' | translate }}</li>
        <li>{{ 'STEP_BEGIN_ELIGIBILITY_3' | translate }}</li>
    </ol>

    <div class="m-t-4">{{ 'STEP_BEGIN_DOCUMENTS_TITLE' | translate }}</div>
    <ul>
        <li>{{ 'STEP_BEGIN_DOCUMENTS_1' | translate }}</li>
        <li>
            {{ 'STEP_BEGIN_DOCUMENTS_LBA_2' | translate }}
            <app-help [templateRefEn]="helpDocumentsLba" [templateRefFr]="helpDocumentsLba"
                      id="helpDocumentsLba2Id"></app-help>
        </li>
        <li>
            {{ 'STEP_BEGIN_DOCUMENTS_LBA_3' | translate }}
            <app-help [templateRefEn]="helpDocumentsLba" [templateRefFr]="helpDocumentsLba"
                      id="helpDocumentsLba3Id"></app-help>
        </li>
    </ul>

    <div class="m-t-4">{{ 'STEP_BEGIN_FAQ' | translate }}</div>
    <ul>
        <li>
            <app-help [label]="'STEP_BEGIN_FAQ_PERSONAL_INFORMATION' | translate"
                      [templateRefEn]="helpEnPersonalInformation"
                      [templateRefFr]="helpFrPersonalInformation"></app-help>
        </li>
        <li>
            <app-help [label]="'STEP_BEGIN_FAQ_CANADIAN_RESIDENT' | translate" [templateRefEn]="helpEnCanadianResident"
                      [templateRefFr]="helpFrCanadianResident"></app-help>
        </li>
        <li>
            <app-help [label]="'STEP_BEGIN_FAQ_AMERICAN_CITIZENS' | translate" [templateRefEn]="helpEnAmericanCitizens"
                      [templateRefFr]="helpFrAmericanCitizens"></app-help>
        </li>
        <li>
            <app-help [label]="'STEP_BEGIN_FAQ_APPLE_SAFARI_COOKIES_ACCEPTATION' | translate"
                      [templateRefEn]="helpEnAppleSafariCookiesAcceptation"
                      [templateRefFr]="helpFrAppleSafariCookiesAcceptation"></app-help>
        </li>
    </ul>

    <div class="btn-resp btn-resp-1colonne-inline m-t-4">
        <div class="btn-resp-col btn-resp-demi">
            <button (click)="navNext()" class="btn btn-primary" type="button">{{ 'BTN_BEGIN' | translate }}</button>
            <button (click)="goToLogin()" class="btn btn-link"
                    type="button">{{ 'STEP_BEGIN_RESUME_REQUEST' | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #helpFrPersonalInformation>
    <h2>{{ 'STEP_BEGIN_FAQ_PERSONAL_INFORMATION'|translate }}</h2>
    <p>Nous sommes dans l'obligation de vous demander certains renseignements personnels afin de répondre aux exigences
        de la législation ou de la réglementation fédérale ou provinciale ou d'autres autorités réglementaires, qui
        peuvent exiger d'avoir accès aux renseignements personnels vous concernant, de les utiliser ou de les
        divulguer.</p>
</ng-template>
<ng-template #helpEnPersonalInformation>
    <h2>{{ 'STEP_BEGIN_FAQ_PERSONAL_INFORMATION'|translate }}</h2>
    <p>We are required to ask you some personal information in order to meet the requirements set by legislation or
        federal or provincial regulations or other regulatory authorities, which may require access to personal
        information about you, to use or disclose these.</p>
</ng-template>

<ng-template #helpFrCanadianResident>
    <h2>{{ 'STEP_BEGIN_FAQ_CANADIAN_RESIDENT'|translate }}</h2>
    <p><strong>Mise en garde:</strong> Les personnes qui répondent à la définition ci-après de résidents canadiens de
        l'Agence de
        revenu du Canada mais, qui résident hors du Canada peuvent être assujetties à des lois et règlements en valeurs
        mobilières qui entrainent des restrictions quand à l'ouverture d'un compte.
    </p>
    <p>Est un résident canadien le citoyen canadien résidant au Canada ou qui ne réside pas habituellement au Canada,
        mais fait partie d'une catégorie de personnes suivantes&nbsp;:</p>
    <ul>
        <li>a)&nbsp;le citoyen canadien qui réside hors du Canada principalement pour y exercer ses fonctions d'employé
            à plein temps d'une personne morale, selon le cas&nbsp;:
            <ul>
                <li>(i)&nbsp;dont plus de cinquante pour cent des actions avec droit de vote sont la propriété effective
                    de résidents canadiens au sens des alinéas a) ou c) de cette définition, ou dont le contrôle ou la
                    haute main est exercé par de tels résidents canadiens,
                </li>
                <li>(ii)&nbsp;dont la majorité des administrateurs sont des résidents canadiens au sens des alinéas a)
                    ou c) de cette définition,
                </li>
                <li>(iii)&nbsp;qui est une filiale d'une personne morale visée aux sous-alinéas (i) ou (ii);</li>
            </ul>
        </li>
        <li>b)&nbsp;le citoyen canadien qui est un employé à plein temps d'une association ou organisation
            internationale dont le Canada est membre;
        </li>
        <li>c)&nbsp;le citoyen canadien qui résidait habituellement au Canada à la date de son 60e anniversaire de
            naissance et qui réside hors du Canada depuis moins de dix années consécutives.
        </li>
    </ul>
</ng-template>
<ng-template #helpEnCanadianResident>
    <h2>{{ 'STEP_BEGIN_FAQ_CANADIAN_RESIDENT'|translate }}</h2>
    <p><strong>Caution:</strong> Persons who meet the Canada Revenue Agency's definition of "Canadian resident" but who
        reside
        outside of Canada may be subject to securities laws and regulations that entail restrictions with regard to
        opening an account.
    </p>
    <p>A Canadian resident is a Canadian citizen residing in Canada or who is not ordinarily resident in Canada but is
        part of the following class of persons:</p>
    <ul>
        <li>a)&nbsp;A Canadian citizen residing outside Canada primarily as a functions of full time employment by a
            legal
            person, where:
            <ul>
                <li>(i)&nbsp;more than fifty percent of the shares with voting rights are beneficially owned by Canadian
                    residents as defined in paragraph a) or c) of this definition, or which control or direction is
                    exercised by such Canadian residents,
                </li>
                <li>(ii)&nbsp;a majority of the directors are Canadian residents as defined in paragraph a) or c) of
                    this
                    definition,
                </li>
                <li>(iii)&nbsp;they are a subsidiary of a corporation referred to in subparagraph (i) or (ii);</li>
            </ul>
        </li>
        <li>b)&nbsp;A Canadian citizen who is a full time employee of an international association or organization of
            which
            Canada is a member;
        </li>
        <li>c)&nbsp;A Canadian citizen who ordinarily resided in Canada on the date of their 60th birthday and who lives
            outside Canada for less than ten consecutive years.
        </li>
    </ul>
</ng-template>

<ng-template #helpFrAmericanCitizens>
    <h2>{{ 'STEP_BEGIN_FAQ_AMERICAN_CITIZENS'|translate }}</h2>
    <p>Desjardins Courtage en ligne peut offrir ses services à des citoyens américains, en autant que ces derniers
        soient résidents canadiens. Si cette situation s'applique à vous, vous devrez remplir notre formulaire, «Demande
        de numéro d'identification de contribuable et d'attestation (W9)» au <a
            class="lien-externe" href="https://www.disnat.com/service-clientele/formulaires" target="_blank">www.disnat.com/formulaires<span
            class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span></a>.
    </p>
</ng-template>
<ng-template #helpEnAmericanCitizens>
    <h2>{{ 'STEP_BEGIN_FAQ_AMERICAN_CITIZENS'|translate }}</h2>
    <p>Desjardins Online Brokerage may offer its services to US citizens, provided that they are Canadian residents. If
        this situation applies to you, you must complete a "Request for Taxpayer Identification Number and Certification
        (W-9)" form found at <a class="lien-externe" href="https://www.disnat.com/en/client-service/forms"
                                target="_blank">www.disnat.com/forms<span
            class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span></a>.
    </p>
</ng-template>

<ng-template #helpFrAppleSafariCookiesAcceptation>
    <h2>{{ 'STEP_BEGIN_FAQ_APPLE_SAFARI_COOKIES_ACCEPTATION'|translate }}</h2>
    <ul>
        <li>Sur votre <b>Mac</b>, choisissez <b>Safari</b> > <b>Préférences</b>, cliquez <b>Confidentialité</b>, puis à
            <b>Suivi de site web</b>, décochez <b>Empêcher le suivi sur plusieurs domaines</b>.
        </li>
        <li>Sur votre <b>iPad</b> ou <b>iPhone</b>, allez dans <b>Réglages</b>, choisissez <b>Safari</b>, puis à <b>Confidentialité
            et sécurité</b>, décochez <b>Empêcher le suivi entre sites</b>.
        </li>
    </ul>
</ng-template>
<ng-template #helpEnAppleSafariCookiesAcceptation>
    <h2>{{ 'STEP_BEGIN_FAQ_APPLE_SAFARI_COOKIES_ACCEPTATION'|translate }}</h2>
    <ul>
        <li>On your <b>Mac</b>, select <b>Safari</b> > <b>Preferences</b> click <b>Privacy</b>, then unselect <b>"Prevent
            cross-site tracking"</b>.
        </li>
        <li>On your <b>iPad</b> or <b>iPhone</b>, go to <b>Settings</b> > select <b>Safari</b> then at <b>Privacy &
            security</b> unselect <b>"Prevent cross-site tracking"</b>.
        </li>
    </ul>
</ng-template>
<ng-template #helpDocumentsLba>
    <p class="padding-right-20">{{ 'STEP_BEGIN_DOCUMENTS_LBA_4'|translate }}</p>
</ng-template>
