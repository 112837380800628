<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-user-line"></span>
        {{'ATTORNEY_TITLE' | translate}}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--userOtherTradingPersonIndicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'USER_OTHER_TRADING_PERSON_TITLE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'userOtherTradingPersonIndicator'"
        [name]="'userOtherTradingPersonIndicator'"
        [path]="'userOtherTradingPersonIndicator'"
        [binding]="getRequesterPath('userOtherTradingPersonIndicator')"
    ></app-odc-field>

    <div *ngIf="form.get('userOtherTradingPersonIndicator').value && !this.isModalActive" formGroupName="attorney">

        <!--Gender-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get('attorney')"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
            [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
            [id]="'attorneyGender'"
            [name]="'attorneyGender'"
            [path]="'attorneyGender'"
            [binding]="'attorneyGender'"
        ></app-odc-field>

        <!--firstname & lastname--------------------------------->
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                    [id]="'attorneyFirstName'"
                    [name]="'attorneyFirstName'"
                    [path]="'attorneyFirstName'"
                    [binding]="'attorneyFirstName'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                    [id]="'attorneyLastName'"
                    [name]="'attorneyLastName'"
                    [path]="'attorneyLastName'"
                    [binding]="'attorneyLastName'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>
            </div>
        </div>


        <div *ngIf="!isPleinEx()">

            <!--DOB & SIN-------------------------------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('attorney')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                        [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                        [id]="'attorneyDOB'"
                        [name]="'attorneyDOB'"
                        [path]="'attorneyDOB'"
                        [binding]="'attorneyDOB'"
                        [attr]="{maxlength: 10}"
                        [dateFormat]="true"
                        [templateRefFr]="helpDOB"
                        [templateRefEn]="helpDOB"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [autoComplete]="'off'"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('attorney')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_SIN_OPTIONAL' | translate"
                        [id]="'attorneySIN'"
                        [name]="'attorneySIN'"
                        [path]="'attorneySIN'"
                        [binding]="'attorneySIN'"
                        [attr]="{maxlength: 11}"
                        [sinFormat]="true"
                        [templateRefFr]="helpSIN"
                        [templateRefEn]="helpSIN"
                    ></app-odc-field>
                </div>
            </div>

            <!--HomePhone & OtherPhone------------------------------------------------>
            <div class="row" formGroupName="phoneGroup">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('attorney.phoneGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_HOME_PHONE' | translate"
                        [id]="'attorneyHomePhone'"
                        [name]="'attorneyHomePhone'"
                        [path]="'attorneyHomePhone'"
                        [binding]="'attorneyHomePhone'"
                        [attr]="{maxlength: 10}"
                        [phoneFormat]="true"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('attorney.phoneGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_OTHER_PHONE' | translate"
                        [id]="'attorneyOtherPhone'"
                        [name]="'attorneyOtherPhone'"
                        [path]="'attorneyOtherPhone'"
                        [binding]="'attorneyOtherPhone'"
                        [attr]="{maxlength: 10}"
                        [phoneFormat]="true"
                    ></app-odc-field>
                </div>
            </div>

        </div>

        <!--client number & relationship------------------------------------------------>
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_CLIENT_NUMBER' | translate"
                    [id]="'attorneyClientNumber'"
                    [name]="'attorneyClientNumber'"
                    [path]="'attorneyClientNumber'"
                    [binding]="'attorneyClientNumber'"
                    [attr]="{maxlength: 5}"
                ></app-odc-field>
            </div>

            <div *ngIf="!isPleinEx()">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.SELECT"
                        [form]="form.get('attorney')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ATTORNEY_CLIENT_RELATIONSHIP' | translate"
                        [id]="'attorneyClientRelationship'"
                        [name]="'attorneyClientRelationship'"
                        [path]="'attorneyClientRelationship'"
                        [list]="relationList"
                        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                        [binding]="'attorneyClientRelationship'"
                        [templateRefFr]="helpClientRelationship"
                        [templateRefEn]="helpClientRelationship"
                    ></app-odc-field>
                </div>
            </div>

        </div>

        <div *ngIf="!isPleinEx()">

            <!--Address--------------------------------------------------------------------------->
            <h3>{{'ATTORNEY_ADDRESS_TITLE'|translate}}</h3>
            <app-address-form
                [legend]="'ATTORNEY_ADDRESS_TITLE'|translate"
                [form]="form"
                [index]="''"
                [submitted]="isFormSubmitted()"
                [bindPath]="''"
                [path]="'attorney'"
                [street]="'attorneyAddressStreet'"
                [unit]="'attorneyAddressUnit'"
                [city]="'attorneyAddressCity'"
                [province]="'attorneyAddressProv'"
                [postalcode]="'attorneyAddressPostalCode'"
                [country]="'attorneyAddressCountry'"
            ></app-address-form>

        </div>

        <h3>{{'OCCUPATION_TITLE' | translate}}</h3>
        <fieldset>
            <legend class="sr-only">{{'OCCUPATION_TITLE' | translate}}</legend>
            <!--attorneyJobStatus--------------------------------->
            <app-odc-field
                [type]="constants.FIELD_TYPES.SELECT"
                [form]="form.get('attorney')"
                [submitted]="isFormSubmitted()"
                [label]="'EMPLOYMENT_STATUS_LABEL' | translate"
                [id]="'attorneyJobStatus'"
                [name]="'attorneyJobStatus'"
                [path]="'attorneyJobStatus'"
                [list]="jobStatusList"
                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                [binding]="'attorneyJobStatus'"
            ></app-odc-field>

            <div *ngIf="attorneyIsPaidEmployeeOrSelfEmployed()" formGroupName="attorneyEmployer">
                <!--attorneyEmployerIsBroker--------------------------------->
                <app-odc-field *ngIf="attorneyIsPaidEmployeeOrSelfEmployed()"
                               [type]="constants.FIELD_TYPES.SELECT"
                               [form]="form.get('attorney.attorneyEmployer')"
                               [submitted]="isFormSubmitted()"
                               [label]="'USER_EMPLOYER_IS_BROKER_LABEL' | translate"
                               [id]="'attorneyEmployerIsBroker'"
                               [name]="'attorneyEmployerIsBroker'"
                               [path]="'attorneyEmployerIsBroker'"
                               [list]="isBrokerList"
                               [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                               [binding]="'attorneyEmployerIsBroker'"
                               [helpText]="getAttorneyEmployerIsBrokerHelp()"
                ></app-odc-field>

                <!--attorneyEmployerName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyEmployer')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_EMPLOYER_NAME' | translate"
                    [id]="'attorneyEmployerName'"
                    [name]="'attorneyEmployerName'"
                    [path]="'attorneyEmployerName'"
                    [binding]="'attorneyEmployerName'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>

                <!--attorneyJobTitle--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyEmployer')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_JOB_TITLE' | translate"
                    [id]="'attorneyJobTitle'"
                    [name]="'attorneyJobTitle'"
                    [path]="'attorneyJobTitle'"
                    [binding]="'attorneyJobTitle'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>

                <!--attorneyEmployerActivity--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.SELECT"
                    [form]="form.get('attorney.attorneyEmployer')"
                    [submitted]="isFormSubmitted()"
                    [label]="'USER_EMPLOYER_ACTIVITY' | translate"
                    [id]="'attorneyEmployerActivity'"
                    [name]="'attorneyEmployerActivity'"
                    [path]="'attorneyEmployerActivity'"
                    [list]="employerActivityList"
                    [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                    [binding]="'attorneyEmployerActivity'"
                ></app-odc-field>
            </div>
        </fieldset>

        <!--attorneyInitieIndicator-->
        <div>
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('attorney')"
                [submitted]="isFormSubmitted()"
                [label]="'ATTORNEY_INITIE' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="'attorneyInitieIndicator'"
                [name]="'attorneyInitieIndicator'"
                [path]="'attorneyInitieIndicator'"
                [binding]="'attorneyInitieIndicator'"
                [templateRefFr]="helpInsiderFr"
                [templateRefEn]="helpInsiderEn"
            ></app-odc-field>
        </div>

        <div *ngIf="form.get('attorney.attorneyInitieIndicator').value" formGroupName="attorneyInitie">
            <h3>{{'REPORTING_INSIDER_TITLE'|translate}}</h3>
            <fieldset>
                <legend class="sr-only">{{'REPORTING_INSIDER_TITLE' | translate}}</legend>

                <!--attorneyInitieCompanyName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyInitie')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_INITIE_COMPANY_NAME' | translate"
                    [id]="'attorneyInitieCompanyName'"
                    [name]="'attorneyInitieCompanyName'"
                    [path]="'attorneyInitieCompanyName'"
                    [binding]="'attorneyInitieCompanyName'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>

                <!--attorneyInitieCompanySymbol--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyInitie')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_INITIE_COMPANY_SYMBOL' | translate"
                    [id]="'attorneyInitieCompanySymbol'"
                    [name]="'attorneyInitieCompanySymbol'"
                    [path]="'attorneyInitieCompanySymbol'"
                    [binding]="'attorneyInitieCompanySymbol'"
                    [attr]="{maxlength: 16}"
                ></app-odc-field>

                <!--attorneyInitieMarket-->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.RADIO"
                    [form]="form.get('attorney.attorneyInitie')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_INITIE_MARKET' | translate"
                    [choices]="[{label: 'ATTORNEY_INITIE_MARKET_CAN' | translate, value: constants.MARKET_CAN}, {label: 'ATTORNEY_INITIE_MARKET_US' | translate, value: constants.MARKET_US}]"
                    [id]="'attorneyInitieMarket'"
                    [name]="'attorneyInitieMarket'"
                    [path]="'attorneyInitieMarket'"
                    [binding]="'attorneyInitieMarket'"
                ></app-odc-field>
            </fieldset>
        </div>

        <!--attorneyMainShareholderIndicator-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form.get('attorney')"
            [submitted]="isFormSubmitted()"
            [label]="'ATTORNEY_MAINSHAREHOLDER' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'attorneyMainShareholderIndicator'"
            [name]="'attorneyMainShareholderIndicator'"
            [path]="'attorneyMainShareholderIndicator'"
            [binding]="'attorneyMainShareholderIndicator'"
            [templateRefFr]="helpMainShareholderFr"
            [templateRefEn]="helpMainShareholderEn"
        ></app-odc-field>

        <div *ngIf="form.get('attorney.attorneyMainShareholderIndicator').value"
             formGroupName="attorneyMainShareholder">
            <h3>{{'SIGNIFICANT_SHAREHOLDER_TITLE'|translate}}</h3>
            <fieldset>
                <legend class="sr-only">{{'SIGNIFICANT_SHAREHOLDER_TITLE' | translate}}</legend>

                <!--attorneyMainShareholderCompanyName--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyMainShareholder')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_MAINSHAREHOLDER_COMPANY_NAME' | translate"
                    [id]="'attorneyMainShareholderCompanyName'"
                    [name]="'attorneyMainShareholderCompanyName'"
                    [path]="'attorneyMainShareholderCompanyName'"
                    [binding]="'attorneyMainShareholderCompanyName'"
                    [attr]="{maxlength: 32}"
                ></app-odc-field>

                <!--attorneyMainShareholderCompanySymbol--------------------------------->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('attorney.attorneyMainShareholder')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_MAINSHAREHOLDER_COMPANY_SYMBOL' | translate"
                    [id]="'attorneyMainShareholderCompanySymbol'"
                    [name]="'attorneyMainShareholderCompanySymbol'"
                    [path]="'attorneyMainShareholderCompanySymbol'"
                    [binding]="'attorneyMainShareholderCompanySymbol'"
                    [attr]="{maxlength: 16}"
                ></app-odc-field>

                <!--attorneyMainShareholderMarket-->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.RADIO"
                    [form]="form.get('attorney.attorneyMainShareholder')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ATTORNEY_MAINSHAREHOLDER_MARKET' | translate"
                    [choices]="[{label: 'ATTORNEY_INITIE_MARKET_CAN' | translate, value: constants.MARKET_CAN}, {label: 'ATTORNEY_INITIE_MARKET_US' | translate, value: constants.MARKET_US}]"
                    [id]="'attorneyMainShareholderMarket'"
                    [name]="'attorneyMainShareholderMarket'"
                    [path]="'attorneyMainShareholderMarket'"
                    [binding]="'attorneyMainShareholderMarket'"
                ></app-odc-field>
            </fieldset>
        </div>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpSIN>
    <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
    <p>{{'HELP_SIN_BODY' | translate}}</p>
</ng-template>

<ng-template #helpDOB>
    <h2>{{'HELP_DOB_TITLE' | translate}}</h2>
    <p>{{'HELP_DOB_BODY' | translate}}</p>
</ng-template>

<ng-template #helpInsiderFr>
    <app-help-insider></app-help-insider>
</ng-template>
<ng-template #helpInsiderEn>
    <app-help-insider></app-help-insider>
</ng-template>

<ng-template #helpMainShareholderFr>
    <h2>Actionnaire important</h2>
    <p>{{'ATTORNEY_MAINSHAREHOLDER_TOOLTIP'|translate}}</p>
</ng-template>
<ng-template #helpMainShareholderEn>
    <h2>Significant Shareholder</h2>
    <p>{{'ATTORNEY_MAINSHAREHOLDER_TOOLTIP'|translate}}</p>
</ng-template>

<ng-template #helpFr>
    <h2>Qu’est-ce qu’un mandataire?</h2>
    <p>Un <strong>mandataire</strong> est une personne que vous nommez afin d’agir en votre nom pour donner des ordres
        d’achat et/ou de vente dans votre compte et effectuer des dépôts, des retraits et transferts exclusivement à
        votre nom. Le mandataire peut également obtenir un accès direct via les services en ligne s’il en fait la
        demande.</p>
    <h2>Qui peut être nommé mandataire?</h2>
    <p>Le mandataire nommé par le client doit être un membre de la famille immédiate du client. Par membre de la famille
        immédiate, on entend : les conjoints, parents, enfants, frères et sœurs et tout membre de la famille vivant sous
        le même toit que le titulaire de compte.</p>
    <h2>Est-ce que j’ai besoin de remplir ce formulaire si je possède une procuration notariée?</h2>
    <p>Oui, même si nous détenons une procuration notariée nous devons procéder à la vérification d’identité du
        mandataire. Il est donc important de remplir ce formulaire afin d’effectuer cette vérification.</p>
</ng-template>
<ng-template #helpEn>
    <h2>What is an attorney?</h2>
    <p>An <strong>attorney</strong> is a person you appoint to act on your behalf; to place buy and/or sell orders in
        your account and make deposits, withdrawals and transfers exclusively in your name. The attorney can also obtain
        direct access via the online services if they request it.</p>
    <h2>Who can be an attorney?</h2>
    <p>The attorney appointed by the client must be a member of the client's immediate family. Immediate family members
        include: spouses, parents, children, siblings and any family members living under the same roof as the account
        holder.</p>
    <h2>Do I need to complete this form if I have a notarised power of attorney?</h2>
    <p>Yes, even if we receive a notarized power of attorney we must verify the identity of the attorney. It is
        therefore important to complete this form in order to perform this check.</p>
</ng-template>

<ng-template #helpClientRelationship>
    <h2>{{'ATTORNEY_CLIENT_RELATIONSHIP'|translate}}</h2>
    <p>{{'ATTORNEY_CLIENT_RELATIONSHIP_TOOLTIP'|translate}}</p>
</ng-template>
