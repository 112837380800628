<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <h2>
        {{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--succDeceasedProvince-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE' | translate"
        [id]="'succDeceasedProvince'"
        [name]="'succDeceasedProvince'"
        [controlName]="'succDeceasedProvince'"
        [path]="'succDeceasedProvince'"
        [binding]="'otherDeceasedProvince'"
        [list]="[{itemValue: constants.QUEBEC, itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_QC'|translate}, {itemValue: constants.ONTARIO, itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_ON'|translate}]"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
    ></app-odc-field>

    <!--succAccountValueGreaterLimit-->
    <div *ngIf="form.get('succDeceasedProvince').value === constants.ONTARIO">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_VALUE_GREATER_THAN_LIMIT' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'succAccountValueGreaterLimit'"
            [name]="'succAccountValueGreaterLimit'"
            [path]="'succAccountValueGreaterLimit'"
            [binding]="'otherAccountValueGreaterLimit'"
        ></app-odc-field>
    </div>

    <!--succWill-->
    <div>
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_WILL' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'succWill'"
            [name]="'succWill'"
            [path]="'succWill'"
            [binding]="'otherWill'"
        ></app-odc-field>
    </div>


    <!--succActPeople-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACT_PEOPLE' | translate"
        [id]="'succActPeople'"
        [name]="'succActPeople'"
        [path]="'succActPeople'"
        [binding]="'otherActPeople'"
        [integerFormat]="true"
        [attr]="{maxlength: 2}"
    ></app-odc-field>

    <!--succAccountHasAmericanResident-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_IS_THERE_AMERICAN_RESIDENT' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'succAccountHasAmericanResident'"
        [name]="'succAccountHasAmericanResident'"
        [path]="'succAccountHasAmericanResident'"
        [binding]="'otherAccountHasAmericanResident'"
    ></app-odc-field>

    <app-province-resident-question [form]="form" [submitted]="formSubmitted"></app-province-resident-question>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
