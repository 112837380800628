import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-section',
  templateUrl: './review-section.component.html'
})
export class ReviewSectionComponent  implements OnInit {

    ngOnInit() {

    }

}
