<div class="section-summary-sub">
    <h5 class="h5 text-uppercase">{{ getTitle() }}</h5>
    <div *ngIf="transfer.bankName">
        <app-review-field [label]="'REVIEW_DELIVERING_BANK'" [value]="transfer.bankName"></app-review-field>
        <app-review-field [label]="'REVIEW_TRANSFER_RESSOURCE_NAME'" [value]="getRessourceName()"></app-review-field>
        <app-review-field [label]="'REVIEW_DELIVERING_BANK_TELEPHONE'" [value]="getPhone()"></app-review-field>
        <app-review-field [label]="'REVIEW_TRANSFER_ADDRESS'" [value]="getAddress()"></app-review-field>
        <app-review-field [label]="'REVIEW_ACCOUNT_NUMBER'" [value]="transfer.accountNumber"></app-review-field>
        <app-review-field [label]="'REVIEW_ACCOUNT_TYPE'" [value]="getInstitutionAccountType(transfer.accountType)"></app-review-field>
        <app-review-field *ngIf="transfer.accountNumberSec" [label]="'REVIEW_ACCOUNT_NUMBER_SEC'" [value]="transfer.accountNumberSec"></app-review-field>
        <app-review-field *ngIf="transfer.accountNumberSec" [label]="'REVIEW_ACCOUNT_TYPE_SEC'" [value]="getInstitutionAccountType(transfer.accountTypeSec)"></app-review-field>
        <app-review-field [label]="'REVIEW_TRANSFER_TYPE'" [value]="getTypeTransfer()"></app-review-field>
        <app-review-field [label]="'REVIEW_TRANSFER_INSTRUCTION'" [value]="getInstructions()"></app-review-field>
        <app-review-field *ngIf="hasAttachedDocumentName()" [label]="'REVIEW_ACCOUNT_STATEMENT'" [value]="getAttachedDocumentName()"></app-review-field>
        <app-review-field *ngIf="hasCashAmount()" [label]="'REVIEW_TRANSFER_CASH_AMOUNT'" [value]="getCashAmount()"></app-review-field>
    </div>
    <ul class="list-unstyled" *ngIf="transfer.securities">
        <ng-container *ngFor="let security of getSecurities()">
            <li *ngIf="security.description && security.quantity && security.type">
                <dl class="row">
                    <dt class="col-md-14">{{ 'REVIEW_TRANSFER_SECURITY' | translate }} :</dt>
                    <dd class="col-md-10">{{ getSecurityValue(security) }}</dd>
                </dl>
            </li>
        </ng-container>
    </ul>
</div>
