import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {RequestsComparisonResult} from '../models/requests-comparison-result.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiEndpoints} from "../enums/api-endpoints.enum";

@Injectable()
export class FormsComparisonService {

    updateComparisonResults$ = new Subject<RequestsComparisonResult[]>();
    significantChangeState$ = new Subject<boolean>();
    private updateComparisonResults: RequestsComparisonResult[];
    private significantChangeState = false;

    constructor(private http: HttpClient) {

        this.updateComparisonResults$.subscribe((comparisonResults: RequestsComparisonResult[]) => {
            this.updateComparisonResults = comparisonResults;

            if (this.isSignificantChangeDetected(comparisonResults)) {
                this.significantChangeState = true;
                this.significantChangeState$.next(true);
            } else {
                this.significantChangeState = false;
                this.significantChangeState$.next(false);
            }
        });
    }

    getSignificantChangeState(): boolean {
        return this.significantChangeState;
    }

    getComparisonResults(): RequestsComparisonResult[] {
        return this.updateComparisonResults;
    }

    public getRequestDiff(encryptedUrl: string): Observable<any> {
        const params = new HttpParams().set('encryptedUrl', encryptedUrl);
        return this.http.get(ApiEndpoints.InternalRequestDiff, {params});
    }

    private isSignificantChangeDetected(results: RequestsComparisonResult[]): boolean {
        if (results == null) {
            return false;
        }
        return Object.values(results)
            .filter(e => e !== null)
            .map(e => e.significantChange)
            .includes(true);
    }
}
