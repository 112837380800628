<div *ngIf="errMsg && errMsg.id === null" id="errMsg" class="bloc-erreur-generique m-b-4" tabindex="0">
    {{ errMsg.message | translate }}
</div>

<ul *ngIf="list && list.length > 1" class="list-unstyled liste-striped">
    <li *ngFor="let item of list">

        <div *ngIf="errMsg && errMsg.id === item.numeroDemande" id="errMsg-{{item.numeroDemande}}" class="bloc-erreur-generique m-b-1" tabindex="0">
            {{ errMsg.message | translate }}
        </div>

        <div class="row">
            <div class="col-md-4">{{item.numeroDemande}}</div>
            <div class="col-md-4">{{('REQUEST_TYPE_' + item.codeDemande) | translate}}</div>
            <div class="col-md-10 text-right">{{item.horodateurMiseAJour | moment: 'LLL'}}</div>
            <div class="col-md-6 text-right">
                <button type="button"
                        class="btn btn-primary"
                        (click)="resumeAccountOpeningRequest(item)"
                        [attr.disabled]="loading ? true : null"
                >
                    {{ 'ODC_LOGIN_ACTION_LOGIN' | translate }}
                </button>
            </div>
        </div>

    </li>
</ul>
