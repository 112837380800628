export const PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG: any = [
    {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'TypeOfManagementComponent',
                saveAvailable: false,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'AccountsChoiceUpdateComponent',
                saveAvailable: false,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'UpdatePersonalInformationComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'ResidentialAddressComponent',
                saveAvailable: true
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_OCCUPATION',
        items: [
            {
                label: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION',
                path: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'OccupationComponent',
                saveAvailable: true
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_CIVIL_STATUS',
        items: [
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'SpouseInformationComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH',
                path: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'ReferredAccountComponent',
                saveAvailable: true
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_LEGAL',
        items: [
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'BrokerageAccountsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX',
        items: [
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'FinancialInformationComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'InvestmentObjectivesAndRiskFactorsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER',
                path: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'ShareholderComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT',
                path: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_PATH',
                component: 'SpecificConsentsComponent',
                saveAvailable: true
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT',
        items: [
            {
                label: 'ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_UPDATE_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReviewUpdateAccountComponent',
                saveAvailable: true,
                disable: false
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_PRINT',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_PERSONAL_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'TransmissionKitDownloadComponent',
                saveAvailable: false
            }
        ]
    },
    {
        showTab: true,
        label: 'ODC_NAV_STEP_SUBMIT',
        items: [
            {
                label: 'ODC_NAV_STEP_SUBMIT',
                path: 'ODC_NAV_STEP_SUBMIT_ACCOUNT_UPDATE_PLEIN_EX_PATH',
                component: 'AddAccountSubmissionComponent',
                saveAvailable: false
            }
        ]
    }
];
