import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FormService} from './form.service';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {ApiEndpoints} from "../enums/api-endpoints.enum";

@Injectable()
export class FileValidatorService {

    constructor(private http: HttpClient,
                private formService: FormService) {
    }

    public fileSizeValidator(control: AbstractControl): ValidationErrors | null {
        return (control.value && !this.formService.isValidFileSizeAllAccounts()) ? {exceedFileSize: true} : null;
    }

    public validateFile(file: any[], path: string): Observable<any> {
        return this.http.post(path, this.getJSONValue(file), {withCredentials: true});
    }

    public getVerifyAccountStatementPath(): string {
        return this.formService.isInternalUser()
            ? ApiEndpoints.InternalVerifyAccountStatement
            : ApiEndpoints.ExternalVerifyAccountStatement;
    }

    public getVerifyIdentityFilePath(): string {
        return this.formService.isInternalUser()
            ? ApiEndpoints.InternalVerifyIdentityFile
            : ApiEndpoints.ExternalVerifyIdentityFile;
    }

    private getJSONValue(obj: any): string {
        const json: string = JSON.stringify(obj);
        return json.replace(/:(\d+)([,}])/g, ':"$1"$2');
    }
}
