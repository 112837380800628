import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: '[app-review-update-account-table-cell]',
    templateUrl: './review-update-account-table-cell.component.html'
})
export class ReviewUpdateAccountTableCellComponent implements OnInit {

    @Input() data: any;
    @Input() highlight: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }
}
