<ng-container *ngFor="let error of getErrors()">
    <div *ngIf="showErrors(error.key)" [attr.id]="'ERROR_FIELD_' + lastPath + '-error'" class="help-block" tabindex="0">
        <div *ngIf="error.key === 'maxlength'">
            {{ 'GLOBAL_ERROR_MSG_MAX_LENGTH' | translate: {maxlength: error.params.requiredLength} }}
        </div>
        <div *ngIf="error.key !== 'maxlength'">
            {{ 'ERROR_FIELD_' + lastPath + '_' + error.key | translate }}
        </div>
    </div>
</ng-container>
