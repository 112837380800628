import {Component, OnInit} from '@angular/core';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';

@Component({
    selector: 'app-gouv-org-related-questions',
    templateUrl: './gouv-org-related-questions.component.html'
})
export class GouvOrgRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    ngOnInit() {
        this.form = this.fb.group({
            gouvOrgCity: [this.formService.getForm().otherGouvOrgCity, [this.requiredValidator('otherGouvOrgCity')]]
        });
    }
}
