<h1>{{ 'HEADER_TITLE_PART1_UPDATE_ACCOUNT' | translate: {clientId: getClientId()} }}</h1>
<h2>{{ 'ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT' | translate }}</h2>

<div class="row">

    <div class="col-md-16">
        <div class="panel panel-simple">
            <div id="questionnaire-content" class="panel-body" tabindex="0">
                <div class="section">
                    <ng-container *ngIf="formDelta">
                        <ng-container *ngIf="formDelta.length > 0" class="section">
                            <app-review-update [formDelta]="formDelta" [showModifyBtn]="false" [isPleinEx]="isPleinEx()"></app-review-update>
                        </ng-container>
                        <ng-container *ngIf="formDelta.length == 0">
                            <p>{{ 'NO_CHANGES_DETECTED' | translate }}</p>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
