import {AfterContentChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseTransferComponent} from './base-transfer.component';
import {AccountTransferRequest, AttachedDocument, WebException} from '../../components/models';
import {FormArray, FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {PostLoginActionService} from "../../components/services/post-login-action.service";

@Component({
    selector: 'app-transfer-request-generic-type',
    templateUrl: './transfer-request-generic-type.component.html'
})
export class TransferRequestGenericTypeComponent extends BaseTransferComponent implements OnInit, AfterContentChecked {

    currentTransferList: AccountTransferRequest [];

    accountTransferType: string;
    bindPath: string;
    loading = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected fileValidatorService: FileValidatorService,
                private postLoginActionService: PostLoginActionService,
                private changeDetectorRef: ChangeDetectorRef) {
        super(navigationService, fb, formService, translate, fileValidatorService);
    }

    ngOnInit() {
        this.currentTransferList = this.getAccountTransferList(this.accountTransferType);
        this.form = this.fb.group({
            transferGroup: this.fb.array([])
        });

        // if accountTransferList has something, we read it and populate it with the transferGroup
        if (this.currentTransferList && this.currentTransferList.length) {
            this.currentTransferList.forEach(accountTransfer => {
                this.addTransfer(accountTransfer);
            });
        } else {
            // add new transfer to both accountTransferList and transferGroup
            this.addNewTransfer(this.accountTransferType);
        }

    }

    ngAfterContentChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    displayAddNewTransfer(index: number): boolean {
        const transferGroup: FormArray = this.transferGroup;
        return transferGroup.length < 3 && transferGroup.length === (index + 1);
    }

    submit() {
        const accountStatementList: AttachedDocument[] = [];
        if (this.form.valid) {
            for (let i = 0; i < this.transferGroup.length; i++) {
                const accountStatement = new AttachedDocument();
                if (this.transferGroup.get(i + '.accountStatementName').value && this.transferGroup.get(i + '.accountStatementContent').value) {
                    accountStatement.name = this.transferGroup.get(i + '.accountStatementName').value;
                    accountStatement.content = this.transferGroup.get(i + '.accountStatementContent').value;
                    accountStatement.validation.contentHash = this.transferGroup.get(i + '.accountStatementContentHash').value;
                    accountStatement.id = i + '';
                    accountStatementList.push(accountStatement);
                }
            }
        }
        if (accountStatementList.length) {
            this.formSubmitted = true;
            this.markAsTouched(this.form);
            this.loading = true;
            this.fileValidatorService.validateFile(accountStatementList, this.fileValidatorService.getVerifyAccountStatementPath()).subscribe(data => {
                if (this.form.valid) {
                    this.loading = false;
                    this.submitForm();
                }
            }, (errors: WebException) => {
                this.postLoginActionService.registerAction(() => this.submit());
                this.verifyFileErrorMessage(errors);
                this.focusOnErrorsList();
                this.loading = false;
            });
        } else {
            this.submitForm();
        }
    }
}
