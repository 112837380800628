<h2>{{'ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE' | translate}}</h2>

<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--jointActPeople-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACT_PEOPLE' | translate"
        [id]="'jointActPeople'"
        [name]="'jointActPeople'"
        [path]="'jointActPeople'"
        [list]="[{itemLabel: 2, itemValue:2}, {itemLabel: 3, itemValue:3}]"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="'jointActPeople'"
    ></app-odc-field>

    <!--resQc-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_RES_QC' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'resQc'"
        [name]="'resQc'"
        [path]="'resQc'"
        [binding]="'resQc'"
    ></app-odc-field>

    <div *ngIf="isPleinexAndNotQuebecResident()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_SURVIVORSHIP' | translate"
            [id]="'survivorShip'"
            [name]="'survivorShip'"
            [path]="'survivorShip'"
            [list]="[{itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_WITHOUT_SURVIVORSHIP' | translate, itemValue:'WITHOUT'},
                    {itemLabel: 'ACCOUNT_TYPE_RELATED_QUESTIONS_WITH_SURVIVORSHIP' | translate, itemValue:'WITH'}]"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
            [binding]="'survivorShip'"
        ></app-odc-field>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>
