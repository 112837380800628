import {Inject, Pipe, PipeTransform} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';
import {PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG} from '../../../update-personal-account-plein-ex-routes-config';
import {PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG} from '../../../update-personal-account-gpd-routes-config';
import {PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG} from '../../../update-personal-account-ssd-routes-config';
import {PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG} from '../../../update-personal-account-routes-config';

@Pipe({
    name: 'summarySubtitle'
})
export class SummarySubtitleValuesPipe implements PipeTransform {

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig) {
    }

    transform(subStepKey: number, stepKey: number): any {
        const config = this.getActualRouteConfig();
        const stepItems = config[stepKey - 1].items;
        return stepItems[subStepKey - 1].label;
    }

    getActualRouteConfig(): any {

        if (this.isPleinEx()) {
            return PERSONAL_ACCOUNT_PLEIN_EX_UPDATE_ROUTES_CONFIG;
        }

        if (this.isGpd()) {
            return PERSONAL_ACCOUNT_GPD_UPDATE_ROUTES_CONFIG;
        }

        if (this.isSsd()) {
            return PERSONAL_ACCOUNT_SSD_UPDATE_ROUTES_CONFIG;
        }

        return PERSONAL_ACCOUNT_UPDATE_ROUTES_CONFIG;
    }

    isGpd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_GPD;
    }

    isSsd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_SSD;
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }
}
