export class VmdFormatSin {

    formatAsSIN(valueToFormat: string): string {
        if (!!valueToFormat) {
            const sinNoSpace = this.undoFormatAsSIN(valueToFormat);

            return (
                sinNoSpace.slice(0, 3) + ' ' +
                sinNoSpace.slice(3, 6) + ' ' +
                sinNoSpace.slice(6, 9)
            ).trim();
        }
        return valueToFormat;
    }

    undoFormatAsSIN(valueToUnformat: string): string {
        if (!!valueToUnformat) {
            return valueToUnformat.replace(/ /g, '').substring(0, 9);
        }
        return valueToUnformat;
    }
}
