import {Inject, Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {AttachedDocument, AttachedDocumentValidation} from '../models';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {HttpClient} from '@angular/common/http';
import {ApiEndpoints} from "../enums/api-endpoints.enum";

@Injectable()
export class AttachedDocumentService {

    private emitLoadedSource = new Subject<any>();
    loadedEmitted = this.emitLoadedSource.asObservable();

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig, private http: HttpClient) {
    }

    emitLoaded() {
        this.emitLoadedSource.next();
    }

    public onFileChange(event, doc: AttachedDocument,
                        nameControl: AbstractControl,
                        contentControl: AbstractControl,
                        contentHashControl: AbstractControl): void {

        if (event && event.target && event.target.files && event.target.files.length > 0) {
            const attachedFile = event.target.files[0];

            this.upload(attachedFile, doc, nameControl, contentControl, contentHashControl);
        }
    }

    public checkFileContent(attachedDocument: File) {
        const formData = new FormData();

        formData.append('attachedDocument', attachedDocument);

        const path = this.isInternalUser()
            ? ApiEndpoints.InternalCheckFileContent
            : ApiEndpoints.ExternalCheckFileContent;

        return  this.http.post(path, formData);
    }

    private upload(attachedFile, doc: AttachedDocument, nameControl: AbstractControl, contentControl: AbstractControl, contentHashControl: AbstractControl) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(attachedFile);
        fileReader.onload = () => {
            doc.name = attachedFile.name;
            // convert size to kb
            doc.size = Math.round(attachedFile.size).toString();
            doc.content = (fileReader.result as string).split(',')[1];
            nameControl.setValue(doc.name);
            contentControl.setValue(doc.content);
            contentHashControl.setValue(doc.validation.contentHash);

            this.emitLoaded();
        };
    }

    public saveAttachedDocumentValidation(validation: AttachedDocumentValidation) {
        const path = this.isInternalUser()
            ? ApiEndpoints.InternalAttachedDocumentValidation
            : ApiEndpoints.ExternalAttachedDocumentValidation

        return this.http.post(path, validation);
    }

    public deleteAttachedDocumentValidation(validation: AttachedDocumentValidation) {
        const path = this.isInternalUser()
            ? ApiEndpoints.InternalAttachedDocumentValidation
            : ApiEndpoints.ExternalAttachedDocumentValidation

        return this.http.delete(path + '/' + validation.id);
    }

    public isInternalUser(): boolean {
        return this.agentDisnatUsername() !== '';
    }

    private agentDisnatUsername(): string {
        return this.config.AGENT_DISNAT_USERNAME || '';
    }
}
