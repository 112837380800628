import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-sub-section',
  templateUrl: './review-sub-section.component.html'
})
export class ReviewSubSectionComponent  implements OnInit {

    @Input() label: string;

    ngOnInit() {

    }

}
