import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormGroup, NgForm} from '@angular/forms';
import {VmdForm} from '../../../components/utils/vmd-form';
import {ListModelService} from '../../../components/services/list-model.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {BaseNavComponent} from "../../layout/questionnaire/base-nav.component";

declare let $: any;

@Component({
    selector: 'app-successor',
    templateUrl: './successor.component.html'
})
export class SuccessorComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() submitted: boolean;
    @Input() groupName: string;
    @Input() gender: string;
    @Input() firstName: string;
    @Input() lastName: string;
    @Input() SIN: string;
    @Input() part: string;
    @Input() deleteVisible: boolean;

    constants: any = VmdConstants;
    formGroup: FormGroup;
    path: string;

    ngOnInit() {
        this.path = this.groupName && this.groupName.length > 0 ? this.groupName + '.' : '';
        this.formGroup = this.form.get(this.groupName) as FormGroup;
    }

    getFormGroup(): FormGroup {
        return this.form.get(this.groupName) as FormGroup;
    }

    isFieldInError(fieldName: string) {
        const control: AbstractControl = this.form.get(this.path + fieldName);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid && ((control.hasError('required') && this.submitted) || !control.hasError('required'));
    }
}
