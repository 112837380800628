import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AccountRulesService} from '../../../components/services/account-rules.service';
import {FormService} from '../../../components/services/form.service';
import {ListModelService} from '../../../components/services/list-model.service';
import {ModalService} from '../../../components/services/modal.service';
import {NavigationService} from '../../../components/services/navigation.service';
import {AccountsChoiceComponent} from '../accounts-choice.component';

@Component({
    selector: 'app-accounts-choice-update',
    templateUrl: './accounts-choice-update.component.html'
})
export class AccountsChoiceUpdateComponent extends AccountsChoiceComponent implements OnInit {

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                protected router: Router,
                public formService: FormService,
                public translate: TranslateService,
                protected accountRulesService: AccountRulesService,
                protected modalService: ModalService,
                protected listModelService: ListModelService) {
        super(navigationService, fb, router, formService, translate, accountRulesService, modalService, listModelService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.hideSubTabFinancialInfos(this.formService.getForm().requesters[this.requesterIndex].userEmployerIsBroker);

        if (this.isPrintMode()) {
            this.setManagementTypeDefaultValueToUpdate();
        }
    }

    setPleinExDefaultValues() {
        if (this.formService.getForm().newExistAct === null) {
            this.formService.setFormProperty('newExistAct', this.constants.ACCOUNT_UPDATE_CLIENT);
        }
    }

    public setManagementTypeDefaultValueToUpdate(): void {
        this.setManagementTypeDefaultValue();

        if (this.isGpd()) {
            this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
            this.formService.getForm().cashAccountMgntType = this.constants.MGNT_TYPE_COM;
        }
    }

    showManagementTypeChoicesUpdate(): boolean {
        return this.isPleinEx()
            && this.form.get('actType').value === this.constants.ACCOUNT_TYPE_CASH;
    }

}
