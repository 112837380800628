import {Directive, ElementRef, forwardRef, HostListener, Input, OnChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HtmlSelectKV} from '../models/html-select-k-v';
import {ListModelService} from '../services/list-model.service';

declare let _: any;
declare let $: any;

@Directive({
    selector: '[appCountryCompleter]',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CountryCompleterDirective), multi: true}
    ]
})
export class CountryCompleterDirective implements ControlValueAccessor, OnChanges {

    @Input() appCountryCompleter: HtmlSelectKV[];
    countries: HtmlSelectKV[] = [];

    constructor(private el: ElementRef,
                private listModelService: ListModelService) {
        this.listModelService.getListModel('paysList', data => this.setCountries(data));
    }


    ngOnChanges() {

        if (this.appCountryCompleter && this.appCountryCompleter.length > 0) {
            let country = this.getCountry(null, this.el.nativeElement.value);
            if ((this.el.nativeElement.value && country.itemLabel === null) || $(this.el.nativeElement).attr('data-input-value')) {
                country = this.getCountry(null, $(this.el.nativeElement).attr('data-input-value'));
            }
            this.el.nativeElement.value = country.itemLabel;
        }
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        const country = this.getCountry(this.el.nativeElement.value, null);

        this.el.nativeElement.value = country.itemLabel;
        this.writeValue(country.itemValue);

        this.onTouched();
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        $(this.el.nativeElement).attr('data-input-value', obj);
        this.el.nativeElement.value = this.getCountry(null, obj).itemLabel;
        this.onChange(obj);
    }

    private getCountry(label: string, value: string): HtmlSelectKV {
        let result = new HtmlSelectKV(null, null);
        _.each(this.countries, (obj) => {
            if (
                (label && label.toLowerCase() === obj.itemLabel.toLowerCase())
                || (value && value.toLowerCase() === obj.itemValue.toLowerCase())
            ) {
                result = obj;
            }
        });

        return result;
    }

    setCountries(list: HtmlSelectKV[]): void {
        const reload = this.countries.length === 0 && list.length > 0;

        this.countries = list;

        if (reload) {
            this.ngOnChanges();
        }
    }

}
