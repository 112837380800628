export class VmdKeyboard {

    allowCopy = true;
    allowCut = true;
    allowPaste = false;
    allowRefresh = true;

    static getKeyCode(e: KeyboardEvent): number {
        return e.keyCode;
    }

    static getCharCode(e: KeyboardEvent): number {
        return e.charCode;
    }

    static getWhich(e: KeyboardEvent): number {
        return e.which;
    }

    isKeyPressNumeric(e: KeyboardEvent): boolean {
        const key = VmdKeyboard.getKeyCode(e);
        const cha = VmdKeyboard.getCharCode(e);
        return (key >= 48 && key <= 57) ||
            (cha >= 48 && cha <= 57) ||
            (this.isKeyPressSpecial(e)) ||
            this.isKeyPressControl(e);
    }

    isKeyPressAlpha(e: KeyboardEvent): boolean {
        const cha = VmdKeyboard.getCharCode(e);
        return ((cha >= 97 && cha <= 122) || (cha >= 65 && cha <= 90) ||
            (this.isKeyPressSpecial(e)) ||
            this.isKeyPressControl(e));
    }

    isKeyPressSpecial(e: KeyboardEvent): boolean {
        return (VmdKeyboard.getKeyCode(e) === 8) ||
            (VmdKeyboard.getKeyCode(e) === 9) ||
            (VmdKeyboard.getKeyCode(e) === 37 && VmdKeyboard.getWhich(e) === 0 && !(e.ctrlKey && e.metaKey && e.shiftKey)) ||
            (VmdKeyboard.getKeyCode(e) === 39 && VmdKeyboard.getWhich(e) === 0 && !(e.ctrlKey && e.metaKey && e.shiftKey));
    }

    isKeyPressControl(e: KeyboardEvent): boolean {
        if (e.ctrlKey || e.metaKey) {
            if ((VmdKeyboard.getCharCode(e) === 88) && (e.ctrlKey || e.metaKey) ||
                ((VmdKeyboard.getCharCode(e) === 120) && (e.ctrlKey || e.metaKey))) {
                return this.allowCut;
            }
            if ((VmdKeyboard.getCharCode(e) === 67) && (e.ctrlKey || e.metaKey) ||
                ((VmdKeyboard.getCharCode(e) === 99) && (e.ctrlKey || e.metaKey))) {
                return this.allowCopy;
            }
            if ((VmdKeyboard.getCharCode(e) === 86) && (e.ctrlKey || e.metaKey) ||
                ((VmdKeyboard.getCharCode(e) === 118) && (e.ctrlKey || e.metaKey))) {
                return this.allowPaste;
            }
            if ((VmdKeyboard.getCharCode(e) === 82) && (e.ctrlKey || e.metaKey) ||
                ((VmdKeyboard.getCharCode(e) === 114) && (e.ctrlKey || e.metaKey))) {
                // refresh page
                return this.allowRefresh;
            }
        }
        return false;
    }

    isKeyPressNumericDecimal(e: KeyboardEvent): boolean {
        const key = VmdKeyboard.getKeyCode(e);
        const cha = VmdKeyboard.getCharCode(e);
        return this.isKeyPressNumeric(e) ||
            key === 44 || key === 46 ||
            cha === 44 || cha === 46;
    }

    isKeyDecimalAllowed(e: KeyboardEvent): boolean {
        return (VmdKeyboard.getKeyCode(e) !== 160) &&
            (VmdKeyboard.getKeyCode(e) !== 192 );
    }

    isKeyDownAllowed(e: KeyboardEvent): boolean {
        return (VmdKeyboard.getKeyCode(e) !== 160) &&
            (VmdKeyboard.getKeyCode(e) !== 192 ) &&
            (VmdKeyboard.getKeyCode(e) !== 188 );
    }

    isKeyArrow(e: KeyboardEvent): boolean {
        return (VmdKeyboard.getKeyCode(e) === 8 && !(e.ctrlKey && e.metaKey && e.shiftKey)) || // Backspace
            (VmdKeyboard.getKeyCode(e) === 46 && !(e.ctrlKey && e.metaKey && e.shiftKey)) || // Delete
            (VmdKeyboard.getKeyCode(e) === 37 && !(e.ctrlKey && e.metaKey && e.shiftKey)) || // Arrow right
            (VmdKeyboard.getKeyCode(e) === 39 && !(e.ctrlKey && e.metaKey && e.shiftKey));
    }

    isKeySpaceOrDash(e: KeyboardEvent): boolean {
        const key = VmdKeyboard.getKeyCode(e);
        const cha = VmdKeyboard.getCharCode(e);
        return key === 32 || key === 45 ||
            cha === 32 || cha === 45;
    }
}
