import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';

@Component({
    selector: 'app-beneficiary-tfsa',
    templateUrl: './beneficiary-tfsa.component.html'
})
export class BeneficiaryTfsaComponent extends BaseNavComponent implements OnInit {

    isBeneficiaryPriIndicator = false;
    isBeneficiarySecIndicator = false;
    isBeneficiaryTerIndicator = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            beneficiaryCeliConjointTitulaire: [this.formService.getForm().beneficiaryCeliConjointTitulaire, [this.requiredValidator('beneficiaryCeliConjointTitulaire')]],
            beneficiaryCeliActiveIndicator: [this.formService.getForm().beneficiaryCeliActiveIndicator, [this.requiredValidator('beneficiaryCeliActiveIndicator')]],
            beneficiary: this.fb.group({
                beneficiaryCeliGender: [this.formService.getForm().beneficiaryCeliGender, [this.requiredValidator('beneficiaryCeliGender')]],
                beneficiaryCeliFirstName: [this.formService.getForm().beneficiaryCeliFirstName, [this.requiredValidator('beneficiaryCeliFirstName')]],
                beneficiaryCeliLastName: [this.formService.getForm().beneficiaryCeliLastName, [this.requiredValidator('beneficiaryCeliLastName')]],
                beneficiaryCeliDOB: [this.formService.getForm().beneficiaryCeliDOB, [this.requiredValidator('beneficiaryCeliDOB'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliSIN: [this.formService.getForm().beneficiaryCeliSIN, [this.requiredValidator('beneficiaryCeliSIN'), VmdValidators.sin]],
                beneficiaryCeliAdressStreet: [this.formService.getForm().beneficiaryCeliAdressStreet, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryCeliAdressStreet'))]],
                beneficiaryCeliAdressUnit: [this.formService.getForm().beneficiaryCeliAdressUnit, [VmdValidators.alphanumericWithSpace]],
                beneficiaryCeliAdressCity: [this.formService.getForm().beneficiaryCeliAdressCity, [this.requiredValidator('beneficiaryCeliAdressCity')]],
                beneficiaryCeliAdressProv: [this.formService.getForm().beneficiaryCeliAdressProv, [this.requiredValidator('beneficiaryCeliAdressProv')]],
                beneficiaryCeliAdressPostalCode: [this.formService.getForm().beneficiaryCeliAdressPostalCode, [this.requiredValidator('beneficiaryCeliAdressPostalCode')]],
                beneficiaryCeliAdressCountry: [this.formService.getForm().beneficiaryCeliAdressCountry, [this.requiredValidator('beneficiaryCeliAdressCountry')]],
                beneficiaryCeliParentLink: [this.formService.getForm().beneficiaryCeliParentLink, [this.requiredValidator('beneficiaryCeliParentLink')]],
                beneficiaryCeliPart: [this.formService.getForm().beneficiaryCeliPart, [this.requiredValidator('beneficiaryCeliPart')]]
            }, {validator: [VmdValidators.beneficiaryCeliAdressZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryCeliAdressZipCodeRequired)]}),
            beneficiarySec: this.fb.group({
                beneficiaryCeliGenderSec: [this.formService.getForm().beneficiaryCeliGenderSec, [this.requiredValidator('beneficiaryCeliGenderSec')]],
                beneficiaryCeliFirstNameSec: [this.formService.getForm().beneficiaryCeliFirstNameSec, [this.requiredValidator('beneficiaryCeliFirstNameSec')]],
                beneficiaryCeliLastNameSec: [this.formService.getForm().beneficiaryCeliLastNameSec, [this.requiredValidator('beneficiaryCeliLastNameSec')]],
                beneficiaryCeliDOBSec: [this.formService.getForm().beneficiaryCeliDOBSec, [this.requiredValidator('beneficiaryCeliDOBSec'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliSINSec: [this.formService.getForm().beneficiaryCeliSINSec, [this.requiredValidator('beneficiaryCeliSINSec'), VmdValidators.sin]],
                beneficiaryCeliAdressStreetSec: [this.formService.getForm().beneficiaryCeliAdressStreetSec, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryCeliAdressStreetSec'))]],
                beneficiaryCeliAdressUnitSec: [this.formService.getForm().beneficiaryCeliAdressUnitSec, [VmdValidators.alphanumericWithSpace]],
                beneficiaryCeliAdressCitySec: [this.formService.getForm().beneficiaryCeliAdressCitySec, [this.requiredValidator('beneficiaryCeliAdressCitySec')]],
                beneficiaryCeliAdressProvSec: [this.formService.getForm().beneficiaryCeliAdressProvSec, [this.requiredValidator('beneficiaryCeliAdressProvSec')]],
                beneficiaryCeliAdressPostalCodeSec: [this.formService.getForm().beneficiaryCeliAdressPostalCodeSec, [this.requiredValidator('beneficiaryCeliAdressPostalCodeSec')]],
                beneficiaryCeliAdressCountrySec: [this.formService.getForm().beneficiaryCeliAdressCountrySec, [this.requiredValidator('beneficiaryCeliAdressCountrySec')]],
                beneficiaryCeliParentLinkSec: [this.formService.getForm().beneficiaryCeliParentLinkSec, [this.requiredValidator('beneficiaryCeliParentLinkSec')]],
                beneficiaryCeliPartSec: [this.formService.getForm().beneficiaryCeliPartSec, [this.requiredValidator('beneficiaryCeliPartSec')]]
            }, {validator: [VmdValidators.beneficiaryCeliAdressSecZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryCeliAdressSecZipCodeRequired)]}),
            beneficiaryTer: this.fb.group({
                beneficiaryCeliGenderTer: [this.formService.getForm().beneficiaryCeliGenderTer, [this.requiredValidator('beneficiaryCeliGenderTer')]],
                beneficiaryCeliFirstNameTer: [this.formService.getForm().beneficiaryCeliFirstNameTer, [this.requiredValidator('beneficiaryCeliFirstNameTer')]],
                beneficiaryCeliLastNameTer: [this.formService.getForm().beneficiaryCeliLastNameTer, [this.requiredValidator('beneficiaryCeliLastNameTer')]],
                beneficiaryCeliDOBTer: [this.formService.getForm().beneficiaryCeliDOBTer, [this.requiredValidator('beneficiaryCeliDOBTer'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliSINTer: [this.formService.getForm().beneficiaryCeliSINTer, [this.requiredValidator('beneficiaryCeliSINTer'), VmdValidators.sin]],
                beneficiaryCeliAdressStreetTer: [this.formService.getForm().beneficiaryCeliAdressStreetTer, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryCeliAdressStreetTer'))]],
                beneficiaryCeliAdressUnitTer: [this.formService.getForm().beneficiaryCeliAdressUnitTer, [VmdValidators.alphanumericWithSpace]],
                beneficiaryCeliAdressCityTer: [this.formService.getForm().beneficiaryCeliAdressCityTer, [this.requiredValidator('beneficiaryCeliAdressCityTer')]],
                beneficiaryCeliAdressProvTer: [this.formService.getForm().beneficiaryCeliAdressProvTer, [this.requiredValidator('beneficiaryCeliAdressProvTer')]],
                beneficiaryCeliAdressPostalCodeTer: [this.formService.getForm().beneficiaryCeliAdressPostalCodeTer, [this.requiredValidator('beneficiaryCeliAdressPostalCodeTer')]],
                beneficiaryCeliAdressCountryTer: [this.formService.getForm().beneficiaryCeliAdressCountryTer, [this.requiredValidator('beneficiaryCeliAdressCountryTer')]],
                beneficiaryCeliParentLinkTer: [this.formService.getForm().beneficiaryCeliParentLinkTer, [this.requiredValidator('beneficiaryCeliParentLinkTer')]],
                beneficiaryCeliPartTer: [this.formService.getForm().beneficiaryCeliPartTer, [this.requiredValidator('beneficiaryCeliPartTer')]]
            }, {validator: [VmdValidators.beneficiaryCeliAdressTerZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryCeliAdressTerZipCodeRequired)]})
        }, {validator: this.validTFSAShares});


        this.isBeneficiaryPriIndicator = this.form.get('beneficiaryCeliActiveIndicator').value;
        this.form.get('beneficiaryCeliActiveIndicator').valueChanges.subscribe(value => {
            if (value) {
                this.isBeneficiaryPriIndicator = value;
                this.updateControlEnabled(this.form.get('beneficiary'), value);
            } else {
                this.removeTerBeneficiary();
                this.removeSecBeneficiary();
                this.removePriBeneficiary();
            }
        });


        this.updateControlEnabled(this.form.get('beneficiaryCeliConjointTitulaire'), this.hasSpouse());

        this.isBeneficiarySecIndicator = !!this.formService.getForm().beneficiaryCeliFirstNameSec;
        this.isBeneficiaryTerIndicator = !!this.formService.getForm().beneficiaryCeliFirstNameTer;

        this.updateControlEnabled(this.form.get('beneficiary'), this.isBeneficiaryPriIndicator);
        this.updateControlEnabled(this.form.get('beneficiarySec'), this.isBeneficiarySecIndicator);
        this.updateControlEnabled(this.form.get('beneficiaryTer'), this.isBeneficiaryTerIndicator);
    }

    validTFSAShares(form: FormGroup) {
        let controls: AbstractControl[];
        controls = [form.get('beneficiary.beneficiaryCeliPart'),
            form.get('beneficiarySec.beneficiaryCeliPartSec'),
            form.get('beneficiaryTer.beneficiaryCeliPartTer')];

        return VmdValidators.validMinMaxCumul(controls, 1, 100);
    }

    removePriBeneficiary() {
        if (this.isBeneficiarySecIndicator) {
            this.copyBeneficiarySecToBeneficiaryPri();
            if (this.isBeneficiaryTerIndicator) {
                this.copyBeneficiaryTerToBeneficiarySec();
                this.removeTerBeneficiary();
            } else {
                this.removeSecBeneficiary();
            }
        } else {
            this.isBeneficiaryPriIndicator = false;
            this.form.get('beneficiary.beneficiaryCeliGender').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliGender', null);

            this.form.get('beneficiary.beneficiaryCeliFirstName').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliFirstName', null);

            this.form.get('beneficiary.beneficiaryCeliLastName').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliLastName', null);

            this.form.get('beneficiary.beneficiaryCeliDOB').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliDOB', null);

            this.form.get('beneficiary.beneficiaryCeliSIN').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliSIN', null);

            this.form.get('beneficiary.beneficiaryCeliAdressStreet').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressStreet', null);

            this.form.get('beneficiary.beneficiaryCeliAdressUnit').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressUnit', null);

            this.form.get('beneficiary.beneficiaryCeliAdressCity').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressCity', null);

            this.form.get('beneficiary.beneficiaryCeliAdressProv').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressProv', null);

            this.form.get('beneficiary.beneficiaryCeliAdressPostalCode').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressPostalCode', null);

            this.form.get('beneficiary.beneficiaryCeliParentLink').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliParentLink', null);

            this.form.get('beneficiary.beneficiaryCeliPart').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliPart', null);

            this.updateControlEnabled(this.form.get('beneficiary'), false);
        }
    }

    addBeneficiarySec() {
        this.isBeneficiarySecIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiarySec'), true);
        return false;
    }

    removeSecBeneficiary() {
        if (this.isBeneficiaryTerIndicator) {
            this.copyBeneficiaryTerToBeneficiarySec();
            this.removeTerBeneficiary();
        } else {
            this.isBeneficiarySecIndicator = false;
            this.form.get('beneficiarySec.beneficiaryCeliGenderSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliGenderSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliFirstNameSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliFirstNameSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliLastNameSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliLastNameSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliDOBSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliDOBSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliSINSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliSINSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliAdressStreetSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressStreetSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliAdressUnitSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressUnitSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliAdressCitySec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressCitySec', null);

            this.form.get('beneficiarySec.beneficiaryCeliAdressProvSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressProvSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliAdressPostalCodeSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliAdressPostalCodeSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliParentLinkSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliParentLinkSec', null);

            this.form.get('beneficiarySec.beneficiaryCeliPartSec').setValue(null);
            this.formService.setFormProperty('beneficiaryCeliPartSec', null);

            this.updateControlEnabled(this.form.get('beneficiarySec'), false);
        }
    }

    addBeneficiaryTer() {
        this.isBeneficiaryTerIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiaryTer'), true);
        return false;
    }

    removeTerBeneficiary() {
        this.isBeneficiaryTerIndicator = false;
        this.form.get('beneficiaryTer.beneficiaryCeliGenderTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliGenderTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliFirstNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliFirstNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliLastNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliLastNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliDOBTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliDOBTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliSINTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliSINTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAdressStreetTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAdressStreetTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAdressUnitTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAdressUnitTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAdressCityTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAdressCityTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAdressProvTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAdressProvTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAdressPostalCodeTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAdressPostalCodeTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliParentLinkTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliParentLinkTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliPartTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliPartTer', null);

        this.updateControlEnabled(this.form.get('beneficiaryTer'), false);
    }

    copyBeneficiarySecToBeneficiaryPri() {
        this.form.get('beneficiary.beneficiaryCeliGender').setValue(this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);
        this.formService.setFormProperty('beneficiaryCeliGender', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliFirstName').setValue(this.form.get('beneficiarySec.beneficiaryCeliFirstNameSec').value);
        this.formService.setFormProperty('beneficiaryCeliFirstName', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliLastName').setValue(this.form.get('beneficiarySec.beneficiaryCeliLastNameSec').value);
        this.formService.setFormProperty('beneficiaryCeliLastName', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliDOB').setValue(this.form.get('beneficiarySec.beneficiaryCeliDOBSec').value);
        this.formService.setFormProperty('beneficiaryCeliDOB', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliSIN').setValue(this.form.get('beneficiarySec.beneficiaryCeliSINSec').value);
        this.formService.setFormProperty('beneficiaryCeliSIN', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressStreet').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressStreetSec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressStreet', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressUnit').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressUnitSec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressUnit', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressCity').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressCitySec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressCity', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressProv').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressProvSec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressProv', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressPostalCode').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressPostalCodeSec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressPostalCode', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAdressCountry').setValue(this.form.get('beneficiarySec.beneficiaryCeliAdressCountrySec').value);
        this.formService.setFormProperty('beneficiaryCeliAdressCountry', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliParentLink').setValue(this.form.get('beneficiarySec.beneficiaryCeliParentLinkSec').value);
        this.formService.setFormProperty('beneficiaryCeliParentLink', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliPart').setValue(this.form.get('beneficiarySec.beneficiaryCeliPartSec').value);
        this.formService.setFormProperty('beneficiaryCeliPart', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

    }

    copyBeneficiaryTerToBeneficiarySec() {
        this.form.get('beneficiarySec.beneficiaryCeliGenderSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliGenderTer').value);
        this.formService.setFormProperty('beneficiaryCeliGenderSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliFirstNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliFirstNameTer').value);
        this.formService.setFormProperty('beneficiaryCeliFirstNameSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliLastNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliLastNameTer').value);
        this.formService.setFormProperty('beneficiaryCeliLastNameSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliDOBSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliDOBTer').value);
        this.formService.setFormProperty('beneficiaryCeliDOBSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliSINSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliSINTer').value);
        this.formService.setFormProperty('beneficiaryCeliSINSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressStreetSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressStreetTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressStreetSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressUnitSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressUnitTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressUnitSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressCitySec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressCityTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressCitySec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressProvSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressProvTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressProvSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressPostalCodeSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressPostalCodeTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressPostalCodeSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliAdressCountrySec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAdressCountryTer').value);
        this.formService.setFormProperty('beneficiaryCeliAdressCountrySec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliParentLinkSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliParentLinkTer').value);
        this.formService.setFormProperty('beneficiaryCeliParentLinkSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryCeliPartSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliPartTer').value);
        this.formService.setFormProperty('beneficiaryCeliPartSec', this.form.get('beneficiarySec.beneficiaryCeliGenderSec').value);

    }

    hasSpouse(): boolean {
        return this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW
            || this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus === this.constants.MARITAL_STATUS_MARRIED;
    }
}
