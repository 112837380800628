import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {FormService} from '../../../components/services/form.service';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';
import {FormBuilder, FormGroup} from '@angular/forms';
import {NavigationService} from '../../../components/services/navigation.service';
import {Router} from '@angular/router';
import {I18N_NAV_MESSAGES_FR} from '../../../components/i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../../../components/i18n/nav-messages_en';
import {AppRoutes} from '../../../app.routes';

declare let $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    static readonly LOGO_DISNAT_SRC_FR = 'assets/img/logo-courtage-ligne-fr.svg';
    static readonly LOGO_DISNAT_SRC_EN = 'assets/img/logo-courtage-ligne-en.svg';
    static readonly LOGO_PLEIN_EX_SRC_FR = 'assets/img/logo-gestion-patrimoine-fr.svg';
    static readonly LOGO_PLEIN_EX_SRC_EN = 'assets/img/logo-gestion-patrimoine-en.svg';
    static readonly LOGO_GPD_SRC_FR = 'assets/img/logo-gestion-patrimoine-gpd-fr.svg';
    static readonly LOGO_GPD_SRC_EN = 'assets/img/logo-gestion-patrimoine-gpd-en.svg';
    static readonly LOGO_SFD_SRC_FR = 'assets/img/logo-gestion-patrimoine-gpd-fr.svg'; // Waiting for new logo, eventually
    static readonly LOGO_SFD_SRC_EN = 'assets/img/logo-gestion-patrimoine-gpd-en.svg';
    static readonly LOGO_SSD_SRC_FR = 'assets/img/logo-gestion-patrimoine-ssd-fr.svg';
    static readonly LOGO_SSD_SRC_EN = 'assets/img/logo-gestion-patrimoine-ssd-en.svg';

    static readonly LOGO_DISNAT_ALT_FR = 'Desjardins Courtage en ligne.';
    static readonly LOGO_DISNAT_ALT_EN = 'Desjardins Online Brokerage.';
    static readonly LOGO_PLEIN_EX_ALT_FR = 'Desjardins Gestion de patrimoine Valeurs Mobilières.';
    static readonly LOGO_PLEIN_EX_ALT_EN = 'Desjardins Wealth Management Securities.';
    static readonly LOGO_GPD_ALT_FR = 'Desjardins Gestion privée.';
    static readonly LOGO_GPD_ALT_EN = 'Desjardins Private Wealth Management.';
    static readonly LOGO_SFD_ALT_FR = 'Desjardins Gestion privée.';
    static readonly LOGO_SFD_ALT_EN = 'Desjardins Private Wealth Management.';
    static readonly LOGO_SSD_ALT_FR = 'Desjardins Gestion de patrimoine.';
    static readonly LOGO_SSD_ALT_EN = 'Desjardins Wealth Management.';

    form: FormGroup;

    constants: any = VmdConstants;

    lang: string;
    switchLang: string;

    constructor(private fb: FormBuilder,
                private router: Router,
                public formService: FormService,
                protected navigationService: NavigationService,
                public translate: TranslateService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
        // In order to reload a component
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    ngOnInit() {
        this.setSwitchLang(this.translate.currentLang);

        this.form = this.fb.group({
            mode: [this.formService.getMode()]
        });

        this.formService.modeChanged.subscribe((mode) => {
            this.form.get('mode').setValue(mode);
        });

        this.disabledModeUpdate();
    }

    getLogoSrc(): string {
        if (this.isSsd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_SSD_SRC_EN : HeaderComponent.LOGO_SSD_SRC_FR;
        } else if (this.isSfd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_SFD_SRC_EN : HeaderComponent.LOGO_SFD_SRC_FR;
        } else if (this.isGpd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_GPD_SRC_EN : HeaderComponent.LOGO_GPD_SRC_FR;
        } else if (this.isPleinEx()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_PLEIN_EX_SRC_EN : HeaderComponent.LOGO_PLEIN_EX_SRC_FR;
        }
        return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_DISNAT_SRC_EN : HeaderComponent.LOGO_DISNAT_SRC_FR;
    }

    getLogoAlt(): string {
        if (this.isSsd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_SSD_ALT_EN : HeaderComponent.LOGO_SSD_ALT_FR;
        } else if (this.isSfd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_SFD_ALT_EN : HeaderComponent.LOGO_SFD_ALT_FR;
        } else if (this.isGpd()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_GPD_ALT_EN : HeaderComponent.LOGO_GPD_ALT_FR;
        } else if (this.isPleinEx()) {
            return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_PLEIN_EX_ALT_EN : HeaderComponent.LOGO_PLEIN_EX_ALT_FR;
        }
        return this.translate.currentLang === VmdConstants.LANG_EN ? HeaderComponent.LOGO_DISNAT_ALT_EN : HeaderComponent.LOGO_DISNAT_ALT_FR;
    }

    goToMainContent(): boolean {
        this.navigationService.goToMainContent();

        return false;
    }

    isModeAutoEnabled(): boolean {
        const cltType = this.formService.getClientType();
        const newExistAct = this.formService.getForm().newExistAct;
        const modeDisabledForCltTypes = [
            VmdConstants.CLT_TYPE_CURATELLE,
            VmdConstants.CLT_TYPE_MANDATAIRE,
            VmdConstants.CLT_TYPE_FIDUCIE,
            VmdConstants.CLT_TYPE_RRI,
            VmdConstants.CLT_TYPE_SUCC,
            VmdConstants.CLT_TYPE_TUTELLE
        ];

        return (this.isGpd() || this.isSfd())
            && -1 === modeDisabledForCltTypes.indexOf(cltType)
            && newExistAct !== VmdConstants.ACCOUNT_REDOCUMENT
            && this.config.IS_AUTOMATED_OPENING_MODE_ACTIVE
            || (this.isPleinEx()
                && -1 === modeDisabledForCltTypes.indexOf(cltType)
                && (newExistAct === VmdConstants.ACCOUNT_NEW_CLIENT || newExistAct === VmdConstants.ACCOUNT_ADD_ACCOUNT || newExistAct === VmdConstants.ACCOUNT_UPDATE_CLIENT)
                && !this.formService.isEditableConvertedRequest());
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }

    isGpd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_GPD;
    }

    isSfd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_SFD;
    }

    isSsd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_SSD;
    }

    isUpdateAccount(): boolean {
        return this.config.IS_UPDATE_ACCOUNT;
    }

    isModeUpdate(): boolean {
        return this.config.IS_MODE_UPDATE;
    }

    isAutomatedOpeningModeActive(): boolean {
        return this.formService.getMode() === VmdConstants.MODES.AUTOMATED;
    }

    showMode(): boolean {
        if ((this.isUpdateAccount() && !this.isModeUpdate())
            || (this.isModeUpdate() && this.formService.getClientType() !== VmdConstants.CLT_TYPE_INDIVIDU)) {
            return false;
        }

        let disableMode = false;
        const currentSubTab = this.navigationService.getCurrentSubTab();
        if (currentSubTab) {
            disableMode = currentSubTab.disableMode;
        }
        return this.isPleinEx()
            && this.showTab()
            && !disableMode
            && !this.formService.isCompleted();
    }

    useLang(lang: string) {
        this.setSwitchLang(lang);
        if (this.translate.currentLang !== lang) {
            this.translate.use(lang);
        }

        $('.modal').modal('hide');
        $('.help-link').popover('hide');

        return false;
    }

    useMode(): void {
        const oldMode = this.formService.getMode();
        const mode = this.form.get('mode').value;
        if (mode !== oldMode) {
            this.formService.setMode(mode);
            this.formService.applyRules();

            // In order to reload a component
            this.goToBeginOrReloadPage();
        }
    }

    private getCurrentTabStepPath(): string {
        const currentSubTab = this.navigationService.getCurrentSubTab();
        const path = currentSubTab === null ? null : currentSubTab.path;
        return this.translate.currentLang === VmdConstants.LANG_EN ? I18N_NAV_MESSAGES_EN[path] : I18N_NAV_MESSAGES_FR[path];
    }

    private getFirstShownedTabStepPath(): string {
        const path = AppRoutes.getFirstShownedTabStepPath(this.navigationService.stepRoutesConfigKey);
        return this.translate.currentLang === VmdConstants.LANG_EN ? I18N_NAV_MESSAGES_EN[path] : I18N_NAV_MESSAGES_FR[path];
    }

    private goToBeginOrReloadPage() {
        let navigateCommands: any[];
        if (this.formService.isPrintMode()) {
            // Reload page
            navigateCommands = ['/', this.getCurrentTabStepPath()];
        } else {
            // Go to begin
            navigateCommands = ['/', this.getFirstShownedTabStepPath()];
        }
        // In order to reload a component
        this.router.navigated = false;
        this.router.navigate(navigateCommands);
    }

    private setSwitchLang(lang: string) {
        this.lang = lang;
        this.switchLang = this.lang === VmdConstants.LANG_EN ? VmdConstants.LANG_FR : VmdConstants.LANG_EN;
    }

    private showTab(): boolean {
        const currentTab = this.navigationService.getCurrentTab();

        return currentTab && currentTab.showTab;
    }

    public disabledModeUpdate() {
        if (this.isUpdateAccount()
            && this.isModeUpdate()
            && this.isAutomatedOpeningModeActive()
            && (this.navigationService.getCurrentTab().label === 'ODC_NAV_STEP_REVIEW_UPDATE_ACCOUNT'
                || this.navigationService.getCurrentTab().label === 'ODC_NAV_STEP_SUBMIT'
                || this.navigationService.getCurrentTab().label === 'ODC_NAV_STEP_PRINT')) {
            this.form.get('mode').disable();
        }
    }

}
