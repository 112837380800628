<aside id="caissesidebar" class="panel panel-default">

    <div class="panel-group sans-panneaux step-preparation-contact" role="tablist" aria-multiselectable="true">
        <div class="panel panel-tiroir m-l-2 m-t-1">
            <div class="panel-heading p-t-2 p-l-2 p-b-1 collapsed" role="tab" id="headingTitle">
                <h2 class="contactus p-l-2 panel-title h2">
                    <a class="collapsed p-t-0 p-b-0" style="border: 0px" role="button" data-toggle="collapse" href="#collapseTitle"
                       aria-expanded="false" aria-controls="collapseTitle">
                        {{ 'CAISSE_SIDEBAR_TITLE' | translate }}
                    </a>
                </h2>
            </div>
            <div id="collapseTitle" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTitle">
                <div class="panel-heading p-t-2 p-l-2 p-b-1 collapsed" role="tab" id="caisseServiceTitle">
                    <h2 class="p-l-2 panel-title h2">
                        <a class="collapsed p-t-0 p-b-0" style="border: 0px" role="button" data-toggle="collapse" href="#collapsecaisseServiceTitle"
                           [attr.aria-expanded]="caisseServiceOpen" aria-controls="collapsecaisseServiceTitle" (click)="caisseService()">
                            {{ 'CAISSE_SIDEBAR_CAISSE_SERVICE_HEADING' | translate }}
                        </a>
                    </h2>
                    <p class="p-l-2 small"> {{ 'CAISSE_SIDEBAR_CAISSE_SERVICE_LINE1' | translate }} <br/> {{ 'CAISSE_SIDEBAR_CAISSE_SERVICE_LINE2' | translate }} </p>
                    <div *ngIf="caisseServiceOpen" id="collapsecaisseServiceTitle" class="panel-collapse collapse p-l-2" role="tabpanel" aria-labelledby="caisseServiceTitle">
                        <div class="card card-body p-l-0 p-r-0 p-b-0" style="border: 0px; background-color: transparent">
                            <h4> {{ 'CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_PHONE' | translate }} </h4>
                            <p> {{ 'CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_HOURS' | translate }} </p>
                        </div>
                    </div>
                </div>
                <div class="panel-heading p-t-2 p-l-2 p-b-1 collapsed" role="tab" id="deskServiceTitle">
                    <h2 class="p-l-2 panel-title h2">
                        <a class="collapsed p-t-0 p-b-0" style="border: 0px" role="button" data-toggle="collapse" href="#collapsedeskServiceTitle"
                           [attr.aria-expanded]='deskServiceOpen' aria-controls="collapsedeskServiceTitle" (click)="deskService()">
                            {{ 'CAISSE_SIDEBAR_DESK_SERVICE_HEADING' | translate }}
                        </a>
                    </h2>
                    <p class="p-l-2 small"> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_LINE1' | translate }} <br/> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_LINE2' | translate }} </p>
                    <div *ngIf="deskServiceOpen" id="collapsedeskServiceTitle" class="panel-collapse collapse p-l-2" role="tabpanel" aria-labelledby="deskServiceTitle">
                        <div class="card card-body p-l-0 p-r-0 p-b-0" style="border: 0px; background-color: transparent">
                            <h4> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE1' | translate }} </h4>
                            <h4> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE2' | translate }} </h4>
                            <h4> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE3' | translate }} </h4>
                            <p> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS' | translate }} </p>
                            <p> {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE1' | translate }} <br/>
                                {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE2' | translate }} <br/>
                                {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE3' | translate }} <br/>
                                {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE4' | translate }} <br/>
                                {{ 'CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE5' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="z-index: 1; position: relative;">
            <a target="_blank" href="{{ 'CAISSE_SIDEBAR_IMAGE_HREF' | translate }}"><img class="img-thumbnail" style="z-index: 2; position: absolute;" src="assets/img/banniere-cta-service-clientele-lg.jpg"></a>
            <a class="lien-externe" style="z-index: 3; position: absolute; width: 150px; top: 40px; left:10px; padding-bottom: 5px; font-size: 1.1rem; text-decoration: none; background-color: white; color: #009757;" target="_blank" href="{{ 'CAISSE_SIDEBAR_IMAGE_HREF' | translate }}">{{ 'CAISSE_SIDEBAR_IMAGE_ALT' | translate }} <br/><br/>{{ 'CAISSE_SIDEBAR_IMAGE_LINK' | translate }}  </a>
        </div>
        <div *ngIf="displayConsult()" class="p-l-2" style="padding-top: 110px;">
            <h2 class="m-t-5">{{ 'SIDEBAR_PLATFORM_TITLE' | translate }}</h2>
            <ul class="list-unstyled">
                <li>
                    <a href="{{ 'SIDEBAR_PLATFORM_GENERAL_DOCUMENTS_LINK' | translate }}" class="lien-externe" target="_blank">
                        {{ 'SIDEBAR_PLATFORM_GENERAL_DOCUMENTS' | translate }}
                        <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
                    </a>
                </li>
                <li>
                    <a href="{{ 'SIDEBAR_PLATFORM_COMMISSION_LINK' | translate }}" class="lien-externe" target="_blank">
                        {{ 'SIDEBAR_PLATFORM_COMMISSION' | translate }}
                        <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
                    </a>
                </li>
                <li>
                    <a href="{{ 'SIDEBAR_PLATFORM_CONVENTION_LINK' | translate }}" class="lien-externe" target="_blank">
                        {{ 'SIDEBAR_PLATFORM_CONVENTION' | translate }}
                        <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="displaySave()" class="p-l-2 p-r-2 p-b-1">
            <div class="save-actions">
                <div *ngIf="!saved">
                    <h2>{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE' | translate }}</h2>
                    <p class="text-justify">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE' | translate }}</p>
                </div>
                <div *ngIf="saved">
                    <h2>
                        <span *ngIf="!fromLogin && isPleinEx()">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_PLEINEX' | translate }}</span>
                        <span *ngIf="!fromLogin && isNotPleinEx()">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED' | translate }}</span>
                        <span *ngIf="fromLogin">{{ 'ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_REPRISE' | translate }}</span>
                    </h2>
                    <div *ngIf="isNotPleinEx()">
                        <p class="text-justify">
                            <span *ngIf="!fromLogin">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_CAISSE' | translate }}</span>
                            <span *ngIf="fromLogin">{{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_REPRISE' | translate }}&nbsp;</span>
                        </p>
                    </div>
                    <div *ngIf="isPleinEx()">
                        <p class="text-justify">
                            {{ 'ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_PLEINEX' | translate }}
                        </p>
                    </div>
                </div>
                <div>
                    <button class="btn btn-primary btn-block loadable"
                            (click)="saveOnClick()"
                            [class.loading]="loading || isRequestInProgressOrSaving()"
                            [attr.disabled]="(loading || isRequestInProgressOrSaving()) ? true : null">
                        {{ 'ODC_QUESTIONNAIRE_SAVE_BUTTON' | translate }}
                    </button>
                </div>
                <div *ngIf="saveError !== null" class="alert alert-danger">{{saveError | translate}}</div>
            </div>
        </div>
    </div>
</aside>
