import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class TrashService {

    subjectTrash = new Subject<string>();

    public getTrashObservable(): Observable<string> {

        return this.subjectTrash.asObservable();
    }

    public emitEventTrash(section: string): void {

        this.subjectTrash.next(section);
    }
}
