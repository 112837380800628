import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-block',
  templateUrl: './review-block.component.html'
})
export class ReviewBlockComponent implements OnInit {

    ngOnInit() {

    }
}
