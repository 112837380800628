import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';

@Component({
    selector: 'app-beneficiary-fhsa',
    templateUrl: './beneficiary-fhsa.component.html'
})
export class BeneficiaryFhsaComponent extends BaseNavComponent implements OnInit {

    isBeneficiaryPriIndicator = false;
    isBeneficiarySecIndicator = false;
    isBeneficiaryTerIndicator = false;

    isBeneficiarySecBackupIndicator = false;
    isBeneficiaryTerBackupIndicator = false;

    isSuccessorHolderIndicator = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            successorHolderCeliAppActiveIndicator: [this.formService.getForm().successorHolderCeliAppActiveIndicator, [this.requiredValidator('successorHolderCeliAppActiveIndicator')]],
            beneficiaryCeliAppActiveIndicator: [this.formService.getForm().beneficiaryCeliAppActiveIndicator, [this.requiredValidator('beneficiaryCeliAppActiveIndicator')]],
            successor: this.fb.group({
                successorHolderCeliAppGender: [this.formService.getForm().successorHolderCeliAppGender, [this.requiredValidator('successorHolderCeliAppGender')]],
                successorHolderCeliAppFirstName: [this.formService.getForm().successorHolderCeliAppFirstName, [this.requiredValidator('successorHolderCeliAppFirstName')]],
                successorHolderCeliAppLastName: [this.formService.getForm().successorHolderCeliAppLastName, [this.requiredValidator('successorHolderCeliAppLastName')]],
                successorHolderCeliAppSIN: [this.formService.getForm().successorHolderCeliAppSIN, [this.requiredValidator('successorHolderCeliAppSIN'), VmdValidators.sin]],
                successorHolderCeliAppPart: [this.formService.getForm().successorHolderCeliAppPart, [this.requiredValidator('successorHolderCeliAppPart')]]
            }),
            beneficiary: this.fb.group({
                beneficiaryCeliAppGender: [this.formService.getForm().beneficiaryCeliAppGender, [this.requiredValidator('beneficiaryCeliAppGender')]],
                beneficiaryCeliAppFirstName: [this.formService.getForm().beneficiaryCeliAppFirstName, [this.requiredValidator('beneficiaryCeliAppFirstName')]],
                beneficiaryCeliAppLastName: [this.formService.getForm().beneficiaryCeliAppLastName, [this.requiredValidator('beneficiaryCeliAppLastName')]],
                beneficiaryCeliAppDOB: [this.formService.getForm().beneficiaryCeliAppDOB, [this.requiredValidator('beneficiaryCeliAppDOB'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliAppSIN: [this.formService.getForm().beneficiaryCeliAppSIN, [this.requiredValidator('beneficiaryCeliAppSIN'), VmdValidators.sin]],
                beneficiaryCeliAppParentLink: [this.formService.getForm().beneficiaryCeliAppParentLink, [this.requiredValidator('beneficiaryCeliAppParentLink')]],
                beneficiaryCeliAppPart: [this.formService.getForm().beneficiaryCeliAppPart, [this.requiredValidator('beneficiaryCeliAppPart')]]
            }),
            beneficiarySec: this.fb.group({
                beneficiaryCeliAppGenderSec: [this.formService.getForm().beneficiaryCeliAppGenderSec, [this.requiredValidator('beneficiaryCeliAppGenderSec')]],
                beneficiaryCeliAppFirstNameSec: [this.formService.getForm().beneficiaryCeliAppFirstNameSec, [this.requiredValidator('beneficiaryCeliAppFirstNameSec')]],
                beneficiaryCeliAppLastNameSec: [this.formService.getForm().beneficiaryCeliAppLastNameSec, [this.requiredValidator('beneficiaryCeliAppLastNameSec')]],
                beneficiaryCeliAppDOBSec: [this.formService.getForm().beneficiaryCeliAppDOBSec, [this.requiredValidator('beneficiaryCeliAppDOBSec'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliAppSINSec: [this.formService.getForm().beneficiaryCeliAppSINSec, [this.requiredValidator('beneficiaryCeliAppSINSec'), VmdValidators.sin]],
                beneficiaryCeliAppParentLinkSec: [this.formService.getForm().beneficiaryCeliAppParentLinkSec, [this.requiredValidator('beneficiaryCeliAppParentLinkSec')]],
                beneficiaryCeliAppPartSec: [this.formService.getForm().beneficiaryCeliAppPartSec, [this.requiredValidator('beneficiaryCeliAppPartSec')]]
            }),
            beneficiaryTer: this.fb.group({
                beneficiaryCeliAppGenderTer: [this.formService.getForm().beneficiaryCeliAppGenderTer, [this.requiredValidator('beneficiaryCeliAppGenderTer')]],
                beneficiaryCeliAppFirstNameTer: [this.formService.getForm().beneficiaryCeliAppFirstNameTer, [this.requiredValidator('beneficiaryCeliAppFirstNameTer')]],
                beneficiaryCeliAppLastNameTer: [this.formService.getForm().beneficiaryCeliAppLastNameTer, [this.requiredValidator('beneficiaryCeliAppLastNameTer')]],
                beneficiaryCeliAppDOBTer: [this.formService.getForm().beneficiaryCeliAppDOBTer, [this.requiredValidator('beneficiaryCeliAppDOBTer'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryCeliAppSINTer: [this.formService.getForm().beneficiaryCeliAppSINTer, [this.requiredValidator('beneficiaryCeliAppSINTer'), VmdValidators.sin]],
                beneficiaryCeliAppParentLinkTer: [this.formService.getForm().beneficiaryCeliAppParentLinkTer, [this.requiredValidator('beneficiaryCeliAppParentLinkTer')]],
                beneficiaryCeliAppPartTer: [this.formService.getForm().beneficiaryCeliAppPartTer, [this.requiredValidator('beneficiaryCeliAppPartTer')]]
            })
        }, {validator: this.validFHSAShares});

        if (!this.hasSpouse()) {

            this.form.get('successorHolderCeliAppActiveIndicator').clearValidators();
            this.form.get('successorHolderCeliAppActiveIndicator').updateValueAndValidity();
        }

        this.isSuccessorHolderIndicator = this.form.get('successorHolderCeliAppActiveIndicator').value;
        this.form.get('successorHolderCeliAppActiveIndicator').valueChanges.subscribe(value => {
            if (value) {
                this.isSuccessorHolderIndicator = value;
                this.updateControlEnabled(this.form.get('successor'), value);
            } else {
                this.removeSuccessor();
            }
        });

        this.isBeneficiaryPriIndicator = this.form.get('beneficiaryCeliAppActiveIndicator').value;
        this.form.get('beneficiaryCeliAppActiveIndicator').valueChanges.subscribe(value => {
            if (value) {
                this.isBeneficiaryPriIndicator = value;
                this.updateControlEnabled(this.form.get('beneficiary'), this.isBeneficiaryPriIndicator);

                this.isBeneficiarySecIndicator = this.isBeneficiarySecBackupIndicator;
                this.updateControlEnabled(this.form.get('beneficiarySec'), this.isBeneficiarySecIndicator);

                this.isBeneficiaryTerIndicator = this.isBeneficiaryTerBackupIndicator;
                this.updateControlEnabled(this.form.get('beneficiaryTer'), this.isBeneficiaryTerIndicator);
            } else {
                this.hideBeneficiaries();
            }
        });

        this.updateControlEnabled(this.form.get('successor'), this.hasSpouse());

        this.isBeneficiarySecIndicator = !!this.formService.getForm().beneficiaryCeliAppFirstNameSec;
        this.isBeneficiaryTerIndicator = !!this.formService.getForm().beneficiaryCeliAppFirstNameTer;

        this.updateControlEnabled(this.form.get('successor'), this.isSuccessorHolderIndicator);
        this.updateControlEnabled(this.form.get('beneficiary'), this.isBeneficiaryPriIndicator);
        this.updateControlEnabled(this.form.get('beneficiarySec'), this.isBeneficiarySecIndicator);
        this.updateControlEnabled(this.form.get('beneficiaryTer'), this.isBeneficiaryTerIndicator);
    }

    validFHSAShares(form: FormGroup) {
        let controls: AbstractControl[];
        controls = [
            form.get('beneficiary.beneficiaryCeliAppPart'),
            form.get('beneficiarySec.beneficiaryCeliAppPartSec'),
            form.get('beneficiaryTer.beneficiaryCeliAppPartTer')];

        return VmdValidators.validMinMaxCumul(controls, 1, 100, true);
    }

    removePriBeneficiary() {
        if (this.isBeneficiarySecIndicator) {
            this.copyBeneficiarySecToBeneficiaryPri();
            if (this.isBeneficiaryTerIndicator) {
                this.copyBeneficiaryTerToBeneficiarySec();
                this.removeTerBeneficiary();
            } else {
                this.removeSecBeneficiary();
            }
        } else {
            this.isBeneficiaryPriIndicator = false;
            this.updateControlEnabled(this.form.get('beneficiary'), false);
        }
    }

    addBeneficiarySec() {
        this.isBeneficiarySecIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiarySec'), true);
        return false;
    }

    removeSecBeneficiary() {
        if (this.isBeneficiaryTerIndicator) {
            this.copyBeneficiaryTerToBeneficiarySec();
            this.removeTerBeneficiary();
        } else {
            this.isBeneficiarySecIndicator = false;
            this.updateControlEnabled(this.form.get('beneficiarySec'), false);

            this.emptySecBeneficiary();
        }
    }

    addBeneficiaryTer() {
        this.isBeneficiaryTerIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiaryTer'), true);
        return false;
    }

    removeTerBeneficiary() {
        this.isBeneficiaryTerIndicator = false;
        this.updateControlEnabled(this.form.get('beneficiaryTer'), false);

        this.emptyTerBeneficiary();
    }

    hideBeneficiaries() {

        this.isBeneficiarySecBackupIndicator = this.isBeneficiarySecIndicator;
        this.isBeneficiaryTerBackupIndicator = this.isBeneficiaryTerIndicator;

        this.isBeneficiaryPriIndicator = false;
        this.updateControlEnabled(this.form.get('beneficiary'), false);

        this.isBeneficiarySecIndicator = false;
        this.updateControlEnabled(this.form.get('beneficiarySec'), false);

        this.isBeneficiaryTerIndicator = false;
        this.updateControlEnabled(this.form.get('beneficiaryTer'), false);
    }

    copyBeneficiarySecToBeneficiaryPri() {
        this.form.get('beneficiary.beneficiaryCeliAppGender').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppGenderSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppGender', this.form.get('beneficiarySec.beneficiaryCeliAppGenderSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppFirstName').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppFirstNameSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppFirstName', this.form.get('beneficiarySec.beneficiaryCeliAppFirstNameSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppLastName').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppLastNameSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppLastName', this.form.get('beneficiarySec.beneficiaryCeliAppLastNameSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppDOB').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppDOBSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppDOB', this.form.get('beneficiarySec.beneficiaryCeliAppDOBSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppSIN').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppSINSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppSIN', this.form.get('beneficiarySec.beneficiaryCeliAppSINSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppParentLink').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppParentLinkSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppParentLink', this.form.get('beneficiarySec.beneficiaryCeliAppParentLinkSec').value);

        this.form.get('beneficiary.beneficiaryCeliAppPart').setValue(this.form.get('beneficiarySec.beneficiaryCeliAppPartSec').value);
        this.formService.setFormProperty('beneficiaryCeliAppPart', this.form.get('beneficiarySec.beneficiaryCeliAppPartSec').value);

        this.emptySecBeneficiary();
    }

    emptySecBeneficiary() {

        this.form.get('beneficiarySec.beneficiaryCeliAppGenderSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppGenderSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppFirstNameSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppFirstNameSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppLastNameSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppLastNameSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppDOBSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppDOBSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppSINSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppSINSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppParentLinkSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppParentLinkSec', null);

        this.form.get('beneficiarySec.beneficiaryCeliAppPartSec').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppPartSec', null);
    }

    copyBeneficiaryTerToBeneficiarySec() {
        this.form.get('beneficiarySec.beneficiaryCeliAppGenderSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppGenderTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppGenderSec', this.form.get('beneficiaryTer.beneficiaryCeliAppGenderTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppFirstNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppFirstNameTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppFirstNameSec', this.form.get('beneficiaryTer.beneficiaryCeliAppFirstNameTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppLastNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppLastNameTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppLastNameSec', this.form.get('beneficiaryTer.beneficiaryCeliAppLastNameTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppDOBSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppDOBTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppDOBSec', this.form.get('beneficiaryTer.beneficiaryCeliAppDOBTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppSINSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppSINTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppSINSec', this.form.get('beneficiaryTer.beneficiaryCeliAppSINTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppParentLinkSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppParentLinkTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppParentLinkSec', this.form.get('beneficiaryTer.beneficiaryCeliAppParentLinkTer').value);

        this.form.get('beneficiarySec.beneficiaryCeliAppPartSec').setValue(this.form.get('beneficiaryTer.beneficiaryCeliAppPartTer').value);
        this.formService.setFormProperty('beneficiaryCeliAppPartSec', this.form.get('beneficiaryTer.beneficiaryCeliAppPartTer').value);

        this.emptyTerBeneficiary();
    }

    emptyTerBeneficiary() {

        this.form.get('beneficiaryTer.beneficiaryCeliAppGenderTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppGenderTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppFirstNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppFirstNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppLastNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppLastNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppDOBTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppDOBTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppSINTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppSINTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppParentLinkTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppParentLinkTer', null);

        this.form.get('beneficiaryTer.beneficiaryCeliAppPartTer').setValue(null);
        this.formService.setFormProperty('beneficiaryCeliAppPartTer', null);
    }

    private removeSuccessor() {

        this.isSuccessorHolderIndicator = false;
        this.updateControlEnabled(this.form.get('successor'), false);
    }

    hasSpouse(): boolean {
        return this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus === this.constants.MARITAL_STATUS_LIVING_COMMON_LAW
            || this.formService.getForm().requesters[this.requesterIndex].userMaritalStatus === this.constants.MARITAL_STATUS_MARRIED;
    }
}
