import {Inject, Injectable} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {VmdConstants} from '../constants/vmd-constants';
import {TranslateService} from '@ngx-translate/core';

declare let _: any;
declare let $: any;
declare let Bloodhound: any;
declare let AddressDisplayer: any;

@Injectable()
export class AddressCompleterService {

    apiKey: string;
    language: string;
    country: string;
    findEndpointPlaceholder = 'QUERY';
    findEndpoint: string = location.protocol + '//ws1.postescanada-canadapost.ca/addresscomplete/interactive/find/v2.10/json3ex.ws';
    detailsEndpoint: string = location.protocol + '//ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3ex.ws';
    searchTerm = '';

    constructor(@Inject(ODC_CONFIG) private config: IOdcConfig,
                private translate: TranslateService) {
        this.setApiKey();
        this.language = this.translate.currentLang || VmdConstants.LANG_FR;
        this.country = (this.country === VmdConstants.COUNTRIES.UNITED_STATES ? VmdConstants.COUNTRIES.UNITED_STATES : VmdConstants.COUNTRIES.CANADA);
    }

    private getFindQueryString(): string {
        if (this.country === VmdConstants.COUNTRIES.UNITED_STATES) {
            return '?country=usa&LanguagePreference=' + this.language + '&SearchFor=Places&key=' + this.apiKey + '&searchTerm=' + this.findEndpointPlaceholder;
        } else {
            return '?country=canada&LanguagePreference=' + this.language + '&SearchFor=Places&key=' + this.apiKey + '&searchTerm=' + this.findEndpointPlaceholder;
        }
    }

    private getDetailsQueryString(): string {
        return '?key=' + this.apiKey + '&Id=';
    }

    getFindEndpoint(lastId: string = null): string {
        let url = this.findEndpoint + this.getFindQueryString();
        if (lastId !== null) {
            url += '&LastId=' + encodeURI(lastId);
        }

        return url;
    }

    getDetailsEndpoint(id: string): string {
        return this.detailsEndpoint + this.getDetailsQueryString() + id;
    }

    isError(results: any): boolean {
        return !results.Items || !results.Items.length || !!results.Items[0].Error;
    }

    logError(results): void {
        /*
        if (!this.isError(results)) {
          return this;
        }

        if (!results.Items || !results.Items.length) {
          logger.error(sprintf('AddressComplete returned invalid results: %s', results));
          return this;
        }

        logger.error(sprintf('AddressComplete error: (%s) %s - %s',
          results.Items[0].Error, results.Items[0].Description, results.Items[0].Resolution));

        return this;
        */
    }

    initialize(element: any): void {
        const self: AddressCompleterService = this;
        $.support.cors = true;
        const suggestions = new Bloodhound({
            datumTokenizer(datum) {
                return [datum.Text];
            },
            queryTokenizer(query) {
                self.searchTerm = query;
                return [query];
            },
            identify(datum) {
                return datum.Id;
            },
            sufficient: 1,
            remote: {
                url: self.getFindEndpoint(),
                wildcard: self.findEndpointPlaceholder,
                transform(response) {
                    if (self.isError(response)) {
                        self.logError(response);
                        return [];
                    }

                    return response.Items;
                }
            }
        });

        const displayer = new AddressDisplayer($(element).attr('id'));
        const tt = $(element).typeahead('destroy');
        element.bind('keydown', (e) => {
            if (e.keyCode === 9) { // (9 - keyTab)
                tt.typeahead('close');
            }
        });

        $(element).typeahead({
            hint: false,
            highlight: true,
            minLength: 0
        }, {
            display(item) {
                return item.Text + ', ' + item.Description;
            },
            source: suggestions,
            limit: 1000
        }).on('typeahead:open', function() {
            if (displayer.getState() === displayer.states.RETRIEVE) {
                $(this).typeahead('close');
            }
        }).on('typeahead:select typeahead:autocomplete', (e, choice) => {
            switch (displayer.changeState(choice.Next).getState()) {
                case displayer.states.FIND_MORE:
                    // Funky case: this is NOT a complete address, usually because of apartments
                    // We change the bloodhound url to do a two-stage find
                    suggestions.remote.url = self.getFindEndpoint(choice.Id);
                    break;
                case displayer.states.RETRIEVE:
                    // Regular case: this is a complete address. We retrieve details and fill in fields with the data
                    suggestions.remote.url = self.getFindEndpoint();
                    $.ajax({
                        url: self.getDetailsEndpoint(choice.Id),
                        dataType: 'json',
                        type: 'GET'
                    }).done((data) => {
                        if (self.isError(data)) {
                            self.logError(data);
                            return;
                        }
                        const foundAddress = _.find(data.Items, (val) => {
                            // /!\ Le service post Canada renvoie uniquement des adresses en anglais pour les Etats-Unis /!\
                            const currentLang = (this.country === VmdConstants.COUNTRIES.UNITED_STATES ? VmdConstants.LANG_EN : this.translate.currentLang) || this.language;
                            return val.Language && val.Language.toLowerCase().indexOf(currentLang) > -1;
                        });
                        if (!foundAddress) {
                            return;
                        }
                        displayer.displayResult(foundAddress, this.country);
                        setTimeout(() => {
                            // IE fix bug
                            // must defer resetState to avoid suggestions to be displayed again after choosing a RETRIEVE address
                            displayer.resetState();
                        }, 1000);
                    });
                    break;
            }
        }).on('typeahead:close', function() {
            // Reset value to what was actually typed (typeahead sets the value to the value of the selection, we do not want this)
            // (unless we're leaving the input)
            if ($(document.activeElement)[0] === $(this)[0]) {
                $(this).val($(this).siblings('pre').html());
            }

            //
            // Reopen details panel with second-stage find if necessary
            if (displayer.getState() === displayer.states.FIND_MORE) {
                displayer.resetState();
                $(element).focus();
            }
        }).on('typeahead:cursorchange', function() {
            // Reset value to what was actually typed (typeahead sets the value to the value of the selection, we do not want this)
            $(this).val($(this).siblings('pre').html());
        }).on('keypress', () => {
            // Reset remote url and completer state to remove LastId in case of a find-inside-a-find
            displayer.resetState();
            suggestions.remote.url = self.getFindEndpoint();
        }).width('100%');
    }

    setApiKey(): void {
        const host = window.location.host;

        switch (host) {
            case VmdConstants.PROD_HOST_NAME_PRIV_DISNAT:
            case VmdConstants.PROD_HOST_NAME_DISNAT:
            case VmdConstants.PROD_HOST_NAME:
            case VmdConstants.PROD_HOST_NAME_EN:
                this.apiKey = 'yk31-jh47-fb59-cy36';
                break;
            case VmdConstants.PREPROD_HOST_NAME_VMD_PRIV:
            case VmdConstants.PREPROD_HOST_NAME_VMD:
            case VmdConstants.PREPROD_HOST_NAME:
            case VmdConstants.PREPROD_HOST_NAME_EN:
                this.apiKey = 'eb41-pp23-rp29-xm54';
                break;
            default:
                this.apiKey = 'TR28-MH11-UD79-BR91';
        }
    }
}
