import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {Requester} from '../../components/models/requester';
import {Component} from "@angular/core";

@Component({
    template: '',
})
export class BaseInformationComponent extends BaseNavComponent {

    showMailingAddress = false;

    initRequester(): void {
        if (!Array.isArray(this.formService.getForm().requesters)) {
            this.formService.getForm().requesters = [];
        }

        const length = this.requesterIndex + 1;

        while (this.formService.getForm().requesters.length < length) {
            this.formService.getForm().requesters.push(new Requester({}));
        }
    }

    resetMailingAddressValue() {
        this.form.get('addressGroup.mailingAddress.userAddressStreetSec').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressStreetSec'), null);
        this.form.get('addressGroup.mailingAddress.userAddressUnitSec').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressUnitSec'), null);
        this.form.get('addressGroup.mailingAddress.userAddressCitySec').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCitySec'), null);
        this.form.get('addressGroup.mailingAddress.userAddressProvSec').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressProvSec'), null);
        this.form.get('addressGroup.mailingAddress.userAddressPostalCodeSec').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCodeSec'), null);
        this.form.get('addressGroup.mailingAddress.userAddressCountrySec').setValue(this.constants.COUNTRIES.CANADA);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCountrySec'), this.constants.COUNTRIES.CANADA);
    }

    clearHomeAddressForm() {
        this.form.get('addressGroup.homeAddress.userAddressStreet').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressStreet'), null);
        this.form.get('addressGroup.homeAddress.userAddressUnit').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressUnit'), null);
        this.form.get('addressGroup.homeAddress.userAddressCity').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCity'), null);
        this.form.get('addressGroup.homeAddress.userAddressProv').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressProv'), null);
        this.form.get('addressGroup.homeAddress.userAddressPostalCode').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCode'), null);
        this.form.get('addressGroup.homeAddress.userAddressCountry').setValue(this.constants.COUNTRIES.CANADA);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.constants.COUNTRIES.CANADA);
    }

    toggleMailingAddress(toggle: boolean) {
        this.showMailingAddress = toggle;
        this.updateControlEnabled(this.form.get('addressGroup.mailingAddress'), this.showMailingAddress);
        if (!this.showMailingAddress) {
            this.resetMailingAddressValue();
        }

        return false;
    }

    isClientTypePersonalAccount(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU;
    }

    isClientTypeOtherAccount(): boolean {
        return !(this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU || this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT);
    }
}
