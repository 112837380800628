import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../../layout/questionnaire/base-nav.component';

import {ModalService} from '../../../components/services/modal.service';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {Entity} from '../../../components/models/entity';

@Component({
    selector: 'app-entities-questions',
    templateUrl: './entities-questions.component.html',
    styleUrls: ['./entities-questions.component.css']
})
export class EntitiesQuestionsComponent extends BaseNavComponent implements OnInit {

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                protected translate: TranslateService,
                public modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.initEntity();
        this.subscriptions.push(this.modalService.openAddEntityPopupEmitted.subscribe(() => {
            this.modalService.openModal(null, null, '#modal-add-entity');
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe(() => {
            // Check if a subEntity is SENC_FIDU_SOC
            this.isSubEntitySENC_FIDU_S_SOC_COMM(this.formService.getForm().entities);
        }));
    }

    // method Requester
    initEntity(): void {
        if (!Array.isArray(this.formService.getForm().entities)) {
            this.formService.getForm().entities = [];
        }
    }

    getEntities(): Entity[] {
        return this.formService.getForm().entities;
    }

    deleteRow(i, j) {
        if (null === j) {
            this.formService.getForm().entities.splice(i, 1);
        } else {
            this.formService.getForm().entities[i].entities.splice(j, 1);
        }
        this.isSubEntitySENC_FIDU_S_SOC_COMM(this.formService.getForm().entities);
    }

    isAllowedToAddSubEntity(entityType: string): boolean {

        return (entityType === this.constants.SOC_COMM || entityType === this.constants.SENC || entityType === this.constants.FIDUCIE_S);
    }

    getIsUsResidentBoolean(isUsResident: boolean): string {

        if (isUsResident) {
            return 'GLOBAL_YES';
        } else if (isUsResident === false) {
            return 'GLOBAL_NO';
        } else {
            return null;
        }
    }

    entityHasChild(entity: Entity): boolean {
        return entity.entities != null && entity.entities.length > 0;
    }

    isSubEntitySENC_FIDU_S_SOC_COMM(entitiesArray): boolean {

        if (entitiesArray != null) {
            for (const entities of entitiesArray) {
                if (entities.entities != null) {
                    for (const subentities of entities.entities) {
                        if (subentities.entityType === this.constants.SOC_COMM ||
                            subentities.entityType === this.constants.SENC ||
                            subentities.entityType === this.constants.FIDUCIE_S) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

}
