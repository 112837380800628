import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';

@Component({
    selector: 'app-transmission-choice',
    templateUrl: './transmission-choice.component.html'
})
export class TransmissionChoiceComponent extends BaseNavComponent implements OnInit {

    esignChoiceValue: string = null;

    ngOnInit() {
        this.formService.setTransmitted(false);
        this.navigationService.emitIsBackDisabled(true);

        this.setDefaultEsignTransmission();

        this.form = this.fb.group({
            transmissionMethod: [this.formService.getForm().transmissionMethod, [this.requiredValidator('transmissionMethod')]]
        });

        this.form.get('transmissionMethod').valueChanges.subscribe(value => {

            this.formService.setFormProperty('transmissionMethod', value);
        });

        if (this.formService.withTransmissionAdditionalForms()) {
            this.esignChoiceValue = this.constants.TRANSMISSION_ESIGN_MAIL;
        } else {
            this.esignChoiceValue = this.constants.TRANSMISSION_ESIGN;
        }

        setTimeout(() => {
            if (this.formService.getForm().transmissionMethod !== this.constants.TRANSMISSION_MAIL && this.formService.getForm().transmissionMethod !== this.esignChoiceValue) {
                this.setTransmissionMethod(this.esignChoiceValue);
                this.form.patchValue({
                    transmissionMethod: this.esignChoiceValue
                });
            }
        }, 0);
    }

    getEsignChoiceValue(): string {
        return this.esignChoiceValue;
    }

    setTransmissionMethod(value: string) {
        this.formService.setFormProperty('transmissionMethod', value);
    }

    isSourceCaisse(): boolean {
        return this.formService.isCaisseContext();
    }

    onlineSelected() {
        this.setTransmissionMethod(this.getEsignChoiceValue());
    }

    mailSelected() {
        this.setTransmissionMethod(this.constants.TRANSMISSION_MAIL);
    }

    isEsignTransmission(): boolean {
        return this.formService.getForm().transmissionMethod === this.constants.TRANSMISSION_ESIGN_MAIL
            || this.formService.getForm().transmissionMethod === this.constants.TRANSMISSION_ESIGN;
    }

    isMailTransmission(): boolean {
        return this.formService.getForm().transmissionMethod === this.constants.TRANSMISSION_MAIL;
    }

    setDefaultEsignTransmission() {
        if (!this.isEsignTransmission() && !this.isMailTransmission()) {
            if (this.formService.withTransmissionAdditionalForms()) {
                this.setTransmissionMethod(this.constants.TRANSMISSION_ESIGN_MAIL);
            } else {
                this.setTransmissionMethod(this.constants.TRANSMISSION_ESIGN);
            }
        }
    }

}
