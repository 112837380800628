import {Component} from '@angular/core';
import {PersonalInformationComponent} from './personal-information.component';

@Component({
    selector: 'app-account-holder-information-second-child',
    templateUrl: './personal-information.component.html'
})

export class AccountHolderInformationSecondChildComponent extends PersonalInformationComponent {

    public requesterIndex = 1;
}
