import {Component, Inject, OnInit} from '@angular/core';
import {SessionService} from '../../components/services/session.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {RequestFollowUpService} from '../../components/services/request-follow-up.service';
import {Branch} from '../../components/models/Branch';
import {Functions} from '../../components/utils/functions';
import {VmdConstants} from '../../components/constants/vmd-constants';

@Component({
    selector: 'app-branch-error',
    templateUrl: './branch-error.component.html'
})
export class BranchErrorComponent implements OnInit {

    constructor(private sessionService: SessionService,
                private requestFollowUpService: RequestFollowUpService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    ngOnInit() {
        if (!this.config.FROM_EXISTING_REQUEST) {
            this.sessionService.getNewToken().subscribe((data: any) => {
                this.sessionService.beginSession(data.payload);
            });
        }
    }

    isDecryptionError(): boolean {
        return VmdConstants.CAISSE_CONTEXT_DECRYPTION_ERROR === this.config.CAISSE_CONTEXT_DECRYPTION_ERROR;
    }

    isPdoError(): boolean {
        return Branch.getInstance() !== null && Functions.isNullOrEmpty(Branch.getInstance().noPdo);
    }

    isBranchListError(): boolean {
        return Branch.getInstance() !== null && (Branch.getInstance().act === 'LIST');
    }

    getErrorDescription(): string {
        if (this.isDecryptionError()) {
            return 'SERVER_BRANCH_ERROR_DESCRIPTION';
        } else if (this.isBranchListError()) {
            return 'SERVER_BRANCH_ERROR';
        } else if (this.isPdoError()) {
            return 'SERVER_PDO_ERROR_DESCRIPTION';
        } else {
            return 'SERVER_BRANCH_ERROR_DESCRIPTION';
        }
    }

    getErrorTitle(): string {
        if (this.isDecryptionError()) {
            return 'SERVER_BRANCH_ERROR';
        } else if (this.isPdoError()) {
            return 'SERVER_BRANCH_PDO_ERROR_TITLE';
        } else {
            return 'SERVER_BRANCH_ERROR';
        }
    }

}
