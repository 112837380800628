export class HtmlSelectKV {

    itemValue: string;
    itemLabel: string;

    constructor(value: string, label: string) {

        this.itemValue = value;
        this.itemLabel = label;
    }
}
