import {Component, Inject, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {FormBuilder} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {Requester} from '../../components/models/requester';
import {PersonalInformationService} from '../../components/services/personal-information.service';
import {ModalService} from '../../components/services/modal.service';
import {SessionService} from '../../components/services/session.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

@Component({
    selector: 'app-branch-begin',
    templateUrl: './branch-begin.component.html'
})
export class BranchBeginComponent extends BaseNavComponent implements OnInit {

    isLoading = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private sessionService: SessionService,
                private personalInformationService: PersonalInformationService,
                private modalService: ModalService,
                @Inject(ODC_CONFIG) private config: IOdcConfig
    ) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit(): void {
        this.formService.initForm({});
        this.formService.initBranchParams();
        this.formService.initAdvisorIdentityParams();

        if (!this.config.FROM_EXISTING_REQUEST) {
            this.sessionService.getNewToken().subscribe((data: any) => {
                this.sessionService.beginSession(data.payload);

                this.isLoading = true;

                this.personalInformationService.obtenirInformationPartiePrenante(this.config.BRANCH_ENCRYPTED_URL).subscribe((res) => {
                    this.isLoading = false;

                    if (null === res.payload) {
                        this.modalService.openModal('PERSONAL_INFORMATION_FAILED_MODAL_TITLE', 'PERSONAL_INFORMATION_FAILED_MODAL_BODY');
                    } else {
                        this.formService.setDdpRequester(new Requester(res.payload));
                        this.formService.getForm().requesters[0] = res.payload;
                    }
                }, () => {
                    this.isLoading = false;
                    this.modalService.openModal('PERSONAL_INFORMATION_FAILED_MODAL_TITLE', 'PERSONAL_INFORMATION_FAILED_MODAL_BODY');
                });
            });
        }
    }
}
