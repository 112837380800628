import {VmdConstants} from '../constants/vmd-constants';

export class VmdFormatDate {

    formatAsDate(valueToFormat: string): string {
        let formatToApply = '';

        if (moment.locale() === 'en') {
            formatToApply = VmdConstants.DATE_FORMAT_EN;
        } else {
            formatToApply = VmdConstants.DATE_FORMAT_FR;
        }

        if (!!valueToFormat && moment(valueToFormat, VmdConstants.DATE_FORMAT_INPUT).isValid() && valueToFormat.length === 10) {
            return moment(valueToFormat, VmdConstants.DATE_FORMAT_INPUT, moment.locale()).format(formatToApply);
        } else {
            return valueToFormat;
        }

    }

    formatAsStandardDate(valueToFormat: string): string {
        return valueToFormat ? moment(new Date(valueToFormat).toDateString()).format(VmdConstants.DATE_FORMAT_INPUT) : '' ;
    }

    formatAsInputDate(valueToFormat: string): string {
        let result = valueToFormat;
        if (!!valueToFormat) {

            const splitValues: string[] = result.split('-');
            let year = '';
            let month = '';
            let day = '';

            // Year formatting
            let carryOver = '';
            if (splitValues[0]) {
                if (splitValues[0].length !== 4) {
                    if (splitValues[0].length < 4) {
                        year = splitValues[0];
                    } else {
                        carryOver = splitValues[0][4];
                        year = splitValues[0].slice(0, 4);
                    }
                } else {
                    year = splitValues[0];
                }
            }

            if (carryOver.length > 0) {
                if (!splitValues[1]) {
                    splitValues[1] = carryOver;
                } else {
                    splitValues[1] = carryOver + splitValues[1];
                }
            }

            carryOver = '';

            // Month formatting
            if (splitValues[1] && splitValues[1].length > 0) {
                if (splitValues[1] && splitValues[1].length !== 2) {
                    if (splitValues[1].length < 2) {
                        month = splitValues[1];
                    } else {
                        carryOver = splitValues[1][2];
                        month = splitValues[1].slice(0, 2);
                    }
                } else {
                    month = splitValues[1];
                }
            }

            if (carryOver.length > 0) {
                if (!splitValues[2]) {
                    splitValues[2] = carryOver;
                } else {
                    splitValues[2] = carryOver + splitValues[2];
                }
            }

            // Day formatting
            if (splitValues[2] && splitValues[2].length > 0) {
                if (splitValues[2] && splitValues[2].length !== 2) {
                    if (splitValues[2].length < 2) {
                        day = splitValues[2];
                    } else {
                        day = splitValues[2].slice(0, 2);
                    }
                } else {
                    day = splitValues[2];
                }
            }

            result = year;
            if (month.length > 0) {
                result += '-' + month;
            }
            if (day.length > 0) {
                result += '-' + day;
            }
        }
        return result;
    }

    undoFormatAsDate(valueToUnformat: string): string {
        let formatToApply = '';

        if (moment.locale() === 'en') {
            formatToApply = VmdConstants.DATE_FORMAT_EN;
        } else {
            formatToApply = VmdConstants.DATE_FORMAT_FR;
        }

        if (!!valueToUnformat && moment(valueToUnformat, formatToApply, true).isValid()) {
            return moment(valueToUnformat, formatToApply).format(VmdConstants.DATE_FORMAT_INPUT);
        }

        return '';
    }

    formatDateOfTheDayTOCompare(date: string): Date {

        if (date.length === 10) {
            return new Date(moment().format('YYYY-MM-DD') + 'T00:00');
        } else {
            return new Date(moment().format('YYYY-MM') + 'T00:00');
        }
    }
}
