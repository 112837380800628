<div class="section">

    <app-review-block>
        <app-review-header [step]="1" [label]="'REVIEW_INFORMATION_ACCOUNT_TYPE'" [path]="'ODC_NAV_STEP_PREPARATION_PLATFORM_CHOICE_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_ACCOUNTS_CHOICE'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_TRANSACTIONAL_PLATFORM'">
                <app-review-field [label]="'REVIEW_PLATFORM'" [value]="getPlatformValue()"></app-review-field>
            </app-review-sub-section>
            <app-review-sub-section [label]="'REVIEW_ACCOUNT_TYPE'">
                <app-review-value [value]="getAccountCreationValue()"></app-review-value>
                <app-review-field *ngIf="isExistingAccount()" [label]="'REVIEW_ACCOUNT_NUMBER'" [value]="ODCForm.cltNo"></app-review-field>
                <app-review-field-list [label]="'REVIEW_ACCOUNT_TYPE'" [values]="getAccountTypeValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addTFSA" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeTFSAValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addFHSA" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeFHSAValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addRRSP" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeRRSPValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addLIRA" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeLIRAValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addRRIF" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeRIFFValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addLIF" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeLIFValue()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.addRESP" [label]="'REVIEW_REGISTERED_ACCOUNT'" [values]="getAccountTypeRESPValue()"></app-review-field-list>
            </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="2" [label]="'REVIEW_PERSONAL_INFORMATION'" [path]="'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_PERSONAL_INFORMATION'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_PERSONAL_INFORMATION_OF_THE_CLIENT'">
                <app-review-field [label]="'REVIEW_NAME'" [value]="getNameValueWithSex(ODCForm.requesters[requesterIndex].gender, ODCForm.requesters[requesterIndex].userFirstName, ODCForm.requesters[requesterIndex].userLastName)"></app-review-field>
                <app-review-field [label]="'REVIEW_DOB'" [value]="getDOBValue(ODCForm.requesters[requesterIndex].userDOB)"></app-review-field>
                <app-review-field [label]="'REVIEW_SIN'" [value]="getSINValue(ODCForm.requesters[requesterIndex].userSIN)"></app-review-field>
                <app-review-field [label]="'REVIEW_TELEPHONE'" [value]="getPhoneValue(ODCForm.requesters[requesterIndex].userHomePhone)"></app-review-field>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userOtherPhone" [label]="'REVIEW_BUSINESS_TELEPHONE'" [value]="getPhoneValue(ODCForm.requesters[requesterIndex].userOtherPhone)"></app-review-field>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userMobilePhone" [label]="'REVIEW_MOBILE_TELEPHONE'" [value]="getPhoneValue(ODCForm.requesters[requesterIndex].userMobilePhone)"></app-review-field>
                <app-review-field style="overflow-wrap: break-word" [label]="'REVIEW_EMAIL'" [value]="ODCForm.requesters[requesterIndex].userEmail"></app-review-field>
                <app-review-field [label]="'REVIEW_HOME_ADDRESS'" [value]="getAddressValue(ODCForm.requesters[requesterIndex].userAddressUnit, ODCForm.requesters[requesterIndex].userAddressStreet, ODCForm.requesters[requesterIndex].userAddressCity, ODCForm.requesters[requesterIndex].userAddressProv, ODCForm.requesters[requesterIndex].userAddressPostalCode)"></app-review-field>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userAddressStreetSec" [label]="'REVIEW_MAILING_ADDRESS'" [value]="getAddressValue(ODCForm.requesters[requesterIndex].userAddressUnitSec, ODCForm.requesters[requesterIndex].userAddressStreetSec, ODCForm.requesters[requesterIndex].userAddressCitySec, ODCForm.requesters[requesterIndex].userAddressProvSec, ODCForm.requesters[requesterIndex].userAddressPostalCodeSec)"></app-review-field>
            </app-review-sub-section>
            <app-review-sub-section [label]="'REVIEW_RESIDENTIAL_ADDRESS_FOR_TAX_PURPOSES'">
                <app-review-field-question [label]="'REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP'" [value]="getCitizenshipValue()"></app-review-field-question>
                <app-review-field-question [label]="'REVIEW_SNOWBIRD'" [value]="getBooleanValue(ODCForm.requesters[requesterIndex].fiscalUsCitizenshipIndicator)"></app-review-field-question>
                <app-review-field-question *ngIf="ODCForm.requesters[requesterIndex].userFiscalRelationCanada" [label]="'REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1'" [value]="getBooleanValue(ODCForm.requesters[requesterIndex].userFiscalRelationCanada)"></app-review-field-question>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userFiscalRelationUsa" [label]="'REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2'" [value]="getResidentUSAValue()"></app-review-field>
                <app-review-field-list *ngIf="ODCForm.requesters[requesterIndex].userFiscalRelationOther && ODCForm.requesters[requesterIndex].userCountryNif2" [label]="'REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3'" [values]="getResidentOtherValue1()"></app-review-field-list>
                <app-review-field-list *ngIf="ODCForm.requesters[requesterIndex].userFiscalRelationOther && ODCForm.requesters[requesterIndex].userCountryNif3" [label]="'REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3'" [values]="getResidentOtherValue2()"></app-review-field-list>
            </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="3" [label]="'REVIEW_OCCUPATION'" [path]="'ODC_NAV_STEP_OCCUPATION_OCCUPATION_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_OCCUPATION'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_OCCUPATION_AND_EMPLOYER'">
                <app-review-field [label]="'REVIEW_EMPLOYMENT_STATUS'" [value]="getEmploymentStatus(ODCForm.requesters[requesterIndex].userJobStatus)"></app-review-field>
                <app-review-field-question *ngIf="isEmployed(ODCForm.requesters[requesterIndex].userJobStatus)" [label]="'REVIEW_EMPLOYMENT_SECURITY_DEALERS'" [value]="isWorkingForBrokering(ODCForm.requesters[requesterIndex].userEmployerIsBroker)"></app-review-field-question>
                <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].userJobStatus)" [label]="'REVIEW_EMPLOYER_NAME'" [value]="ODCForm.requesters[requesterIndex].userEmployerName"></app-review-field>
                <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].userJobStatus)" [label]="'REVIEW_EMPLOYMENT_JOB_TITLE'" [value]="ODCForm.requesters[requesterIndex].userJobTitle"></app-review-field>
                <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].userJobStatus)" [label]="'REVIEW_EMPLOYMENT_SECTOR_ACTIVITY'" [value]="getSectorActivity(ODCForm.requesters[requesterIndex].userEmployerActivity)"></app-review-field>
                <app-review-field-question [label]="'REVIEW_EMPLOYMENT_SAME_ADDRESS'" [value]="getBooleanValue(ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeIndicator)"></app-review-field-question>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeIndicator" [label]="'REVIEW_FIRM_NAME'" [value]="ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeFirmName"></app-review-field>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeIndicator" [label]="'REVIEW_FIRM_EMPLOYEE_NAME'" [value]="getNameValue(ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeFirstName, ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeLastName)"></app-review-field>
                <app-review-field *ngIf="ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeIndicator && ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeLink" [label]="'REVIEW_FIRM_LINK'" [value]="ODCForm.requesters[requesterIndex].userSameAddressWithBrokerEmployeeLink"></app-review-field>
            </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="4" [label]="'REVIEW_MARITAL_STATUS_AND_DESIGNATION'" [path]="'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_CIVIL_STATUS'"></app-review-header>
        <app-review-section>
            <!-- spouse  -->
            <app-review-sub-section [label]="'REVIEW_SPOUSAL_INFORMATION'">
                <app-review-field [label]="'REVIEW_MARITAL_STATUS'" [value]="getMaritalStatus()"></app-review-field>
                <ng-container *ngIf="hasSpouse()">
                    <app-review-field [label]="'REVIEW_NAME'" [value]="getNameValueWithSex(ODCForm.requesters[requesterIndex].spouseGender, ODCForm.requesters[requesterIndex].spouseFirstName, ODCForm.requesters[requesterIndex].spouseLastName)"></app-review-field>
                    <app-review-field *ngIf="isAnnuitantSpouse()" [label]="'REVIEW_DOB'" [value]="getDOBValue(ODCForm.requesters[requesterIndex].spouseDOB)"></app-review-field>
                    <app-review-field *ngIf=" isAnnuitantSpouse()" [label]="'REVIEW_SIN'" [value]="getSINValue(ODCForm.requesters[requesterIndex].spouseSIN)"></app-review-field>
                    <app-review-field [label]="'REVIEW_EMPLOYMENT_STATUS'" [value]="getEmploymentStatus(ODCForm.requesters[requesterIndex].spouseJobStatus)"></app-review-field>
                    <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].spouseJobStatus)" [label]="'REVIEW_EMPLOYER_NAME'" [value]="ODCForm.requesters[requesterIndex].spouseEmployerName"></app-review-field>
                    <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].spouseJobStatus)" [label]="'REVIEW_EMPLOYMENT_JOB_TITLE'" [value]="ODCForm.requesters[requesterIndex].spouseJobTitle"></app-review-field>
                    <app-review-field *ngIf="isEmployed(ODCForm.requesters[requesterIndex].spouseJobStatus)" [label]="'REVIEW_EMPLOYMENT_SECTOR_ACTIVITY'" [value]="getSectorActivity(ODCForm.requesters[requesterIndex].spouseEmployerActivity)"></app-review-field>
                </ng-container>
            </app-review-sub-section>

            <app-review-sub-section *ngIf="hasBeneficiary(ODCForm.addRRSP)" [label]="'REVIEW_ACCOUNT_RRSP'">
                <app-review-field-question [label]="'REVIEW_HAS_BENEFICIARY'" [value]="getBooleanValue(ODCForm.beneficiaryReerActiveIndicator)"></app-review-field-question>
            </app-review-sub-section>

            <ng-container *ngIf="hasBeneficiary(ODCForm.addRRSP) && ODCForm.beneficiaryReerActiveIndicator">
                <app-review-beneficary *ngIf="ODCForm.beneficiaryReerFirstName"
                    [label]="'REVIEW_BENEFICIARY'" [index]="1"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryReerGender, ODCForm.beneficiaryReerFirstName, ODCForm.beneficiaryReerLastName)"
                    [dob]="getDOBValue(ODCForm.beneficiaryReerDOB)"
                    [sin]="getSINValue(ODCForm.beneficiaryReerSIN)"
                    [address]="getAddressValue(ODCForm.beneficiaryReerAdressUnit, ODCForm.beneficiaryReerAdressStreet, ODCForm.beneficiaryReerAdressCity, ODCForm.beneficiaryReerAdressProv, ODCForm.beneficiaryReerAdressPostalCode)"
                    [link]="getLink(ODCForm.beneficiaryReerParentLink)"
                    [part]="ODCForm.beneficiaryReerPart">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryReerFirstNameSec"
                    [label]="'REVIEW_BENEFICIARY'" [index]="2"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryReerGenderSec, ODCForm.beneficiaryReerFirstNameSec, ODCForm.beneficiaryReerLastNameSec)"
                    [dob]="getDOBValue(ODCForm.beneficiaryReerDOBSec)"
                    [sin]="getSINValue(ODCForm.beneficiaryReerSINSec)"
                    [address]="getAddressValue(ODCForm.beneficiaryReerAdressUnitSec, ODCForm.beneficiaryReerAdressStreetSec, ODCForm.beneficiaryReerAdressCitySec, ODCForm.beneficiaryReerAdressProvSec, ODCForm.beneficiaryReerAdressPostalCodeSec)"
                    [link]="getLink(ODCForm.beneficiaryReerParentLinkSec)"
                    [part]="ODCForm.beneficiaryReerPartSec">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryReerFirstNameTer"
                    [label]="'REVIEW_BENEFICIARY'" [index]="3"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryReerGenderTer, ODCForm.beneficiaryReerFirstNameTer, ODCForm.beneficiaryReerLastNameTer)"
                    [dob]="getDOBValue(ODCForm.beneficiaryReerDOBTer)"
                    [sin]="getSINValue(ODCForm.beneficiaryReerSINTer)"
                    [address]="getAddressValue(ODCForm.beneficiaryReerAdressUnitTer, ODCForm.beneficiaryReerAdressStreetTer, ODCForm.beneficiaryReerAdressCityTer, ODCForm.beneficiaryReerAdressProvTer, ODCForm.beneficiaryReerAdressPostalCodeTer)"
                    [link]="getLink(ODCForm.beneficiaryReerParentLinkTer)"
                    [part]="ODCForm.beneficiaryReerPartTer">
                </app-review-beneficary>
            </ng-container>

            <app-review-sub-section *ngIf="hasBeneficiary(ODCForm.addTFSA)" [label]="'REVIEW_ACCOUNT_TFSA'">
                <app-review-field-question *ngIf="hasSpouse()" [label]="'REVIEW_SUCCESSOR_BENEFICIARY_TFSA'" [value]="getBooleanValue(ODCForm.beneficiaryCeliConjointTitulaire)"></app-review-field-question>
                <app-review-field-question [label]="'REVIEW_HAS_BENEFICIARY'" [value]="getBooleanValue(ODCForm.beneficiaryCeliActiveIndicator)"></app-review-field-question>
            </app-review-sub-section>

            <ng-container *ngIf="hasBeneficiary(ODCForm.addTFSA) && ODCForm.beneficiaryCeliActiveIndicator">
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliFirstName"
                    [label]="'REVIEW_BENEFICIARY'" [index]="1"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryCeliGender, ODCForm.beneficiaryCeliFirstName, ODCForm.beneficiaryCeliLastName)"
                    [dob]="getDOBValue(ODCForm.beneficiaryCeliDOB)"
                    [sin]="getSINValue(ODCForm.beneficiaryCeliSIN)"
                    [address]="getAddressValue(ODCForm.beneficiaryCeliAdressUnit, ODCForm.beneficiaryCeliAdressStreet, ODCForm.beneficiaryCeliAdressCity, ODCForm.beneficiaryCeliAdressProv, ODCForm.beneficiaryCeliAdressPostalCode)"
                    [link]="getLink(ODCForm.beneficiaryCeliParentLink)"
                    [part]="ODCForm.beneficiaryCeliPart">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliFirstNameSec"
                    [label]="'REVIEW_BENEFICIARY'" [index]="2"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryCeliGenderSec, ODCForm.beneficiaryCeliFirstNameSec, ODCForm.beneficiaryCeliLastNameSec)"
                    [dob]="getDOBValue(ODCForm.beneficiaryCeliDOBSec)"
                    [sin]="getSINValue(ODCForm.beneficiaryCeliSINSec)"
                    [address]="getAddressValue(ODCForm.beneficiaryCeliAdressUnitSec, ODCForm.beneficiaryCeliAdressStreetSec, ODCForm.beneficiaryCeliAdressCitySec, ODCForm.beneficiaryCeliAdressProvSec, ODCForm.beneficiaryCeliAdressPostalCodeSec)"
                    [link]="getLink(ODCForm.beneficiaryCeliParentLinkSec)"
                    [part]="ODCForm.beneficiaryCeliPartSec">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliFirstNameTer"
                    [label]="'REVIEW_BENEFICIARY'" [index]="3"
                    [name]="getNameValueWithSex(ODCForm.beneficiaryCeliGenderTer, ODCForm.beneficiaryCeliFirstNameTer, ODCForm.beneficiaryCeliLastNameTer)"
                    [dob]="getDOBValue(ODCForm.beneficiaryCeliDOBTer)"
                    [sin]="getSINValue(ODCForm.beneficiaryCeliSINTer)"
                    [address]="getAddressValue(ODCForm.beneficiaryCeliAdressUnitTer, ODCForm.beneficiaryCeliAdressStreetTer, ODCForm.beneficiaryCeliAdressCityTer, ODCForm.beneficiaryCeliAdressProvTer, ODCForm.beneficiaryCeliAdressPostalCodeTer)"
                    [link]="getLink(ODCForm.beneficiaryCeliParentLinkTer)"
                    [part]="ODCForm.beneficiaryCeliPartTer">
                </app-review-beneficary>
            </ng-container>

            <app-review-sub-section *ngIf="hasBeneficiary(ODCForm.addFHSA)" [label]="'REVIEW_ACCOUNT_FHSA'">
                <app-review-field-question *ngIf="hasSpouse()" [label]="'REVIEW_SUCCESSOR_BENEFICIARY_FHSA'" [value]="getBooleanValue(ODCForm.successorHolderCeliAppActiveIndicator)"></app-review-field-question>
            </app-review-sub-section>

            <ng-container *ngIf="hasSpouse() && hasBeneficiary(ODCForm.addFHSA) && ODCForm.successorHolderCeliAppActiveIndicator">
                <app-review-successor *ngIf="ODCForm.successorHolderCeliAppFirstName"
                                       [label]="'REVIEW_SUCCESSOR'"
                                       [name]="getNameValueWithSex(ODCForm.successorHolderCeliAppGender, ODCForm.successorHolderCeliAppFirstName, ODCForm.successorHolderCeliAppLastName)"
                                       [sin]="getSINValue(ODCForm.successorHolderCeliAppSIN)"
                                       [part]="'100'">
                </app-review-successor>
            </ng-container>

            <ng-container *ngIf="hasBeneficiary(ODCForm.addFHSA)">
                <app-review-field-question [label]="'REVIEW_HAS_BENEFICIARY_FHSA'" [value]="getBooleanValue(ODCForm.beneficiaryCeliAppActiveIndicator)"></app-review-field-question>
            </ng-container>

            <ng-container *ngIf="hasBeneficiary(ODCForm.addFHSA) && ODCForm.beneficiaryCeliAppActiveIndicator">
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliAppFirstName"
                                       [label]="'REVIEW_BENEFICIARY'" [index]="1"
                                       [name]="getNameValueWithSex(ODCForm.beneficiaryCeliAppGender, ODCForm.beneficiaryCeliAppFirstName, ODCForm.beneficiaryCeliAppLastName)"
                                       [dob]="getDOBValue(ODCForm.beneficiaryCeliAppDOB)"
                                       [sin]="getSINValue(ODCForm.beneficiaryCeliAppSIN)"
                                       [link]="getLinkFhsa(ODCForm.beneficiaryCeliAppParentLink)"
                                       [part]="ODCForm.beneficiaryCeliAppPart">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliAppFirstNameSec"
                                       [label]="'REVIEW_BENEFICIARY'" [index]="2"
                                       [name]="getNameValueWithSex(ODCForm.beneficiaryCeliAppGenderSec, ODCForm.beneficiaryCeliAppFirstNameSec, ODCForm.beneficiaryCeliAppLastNameSec)"
                                       [dob]="getDOBValue(ODCForm.beneficiaryCeliAppDOBSec)"
                                       [sin]="getSINValue(ODCForm.beneficiaryCeliAppSINSec)"
                                       [link]="getLinkFhsa(ODCForm.beneficiaryCeliAppParentLinkSec)"
                                       [part]="ODCForm.beneficiaryCeliAppPartSec">
                </app-review-beneficary>
                <app-review-beneficary *ngIf="ODCForm.beneficiaryCeliAppFirstNameTer"
                                       [label]="'REVIEW_BENEFICIARY'" [index]="3"
                                       [name]="getNameValueWithSex(ODCForm.beneficiaryCeliAppGenderTer, ODCForm.beneficiaryCeliAppFirstNameTer, ODCForm.beneficiaryCeliAppLastNameTer)"
                                       [dob]="getDOBValue(ODCForm.beneficiaryCeliAppDOBTer)"
                                       [sin]="getSINValue(ODCForm.beneficiaryCeliAppSINTer)"
                                       [link]="getLinkFhsa(ODCForm.beneficiaryCeliAppParentLinkTer)"
                                       [part]="ODCForm.beneficiaryCeliAppPartTer">
                </app-review-beneficary>
            </ng-container>

            <app-review-sub-section [label]="'REVIEW_ATTORNEY'">
                <app-review-field-question [label]="'REVIEW_ATTORNEY_QUESTION'" [value]="getBooleanValue(getRequesterProperty('userOtherTradingPersonIndicator'))"></app-review-field-question>
                <ng-container *ngIf="getRequesterProperty('userOtherTradingPersonIndicator')">
                    <app-review-field [label]="'REVIEW_NAME'" [value]="getNameValueWithSex(ODCForm.attorneyGender, ODCForm.attorneyFirstName, ODCForm.attorneyLastName)"></app-review-field>
                    <app-review-field [label]="'REVIEW_DOB'" [value]="getDOBValue(ODCForm.attorneyDOB)"></app-review-field>
                    <app-review-field *ngIf="ODCForm.attorneySIN" [label]="'REVIEW_SIN'" [value]="getSINValue(ODCForm.attorneySIN)"></app-review-field>
                    <app-review-field [label]="'REVIEW_TELEPHONE'" [value]="getPhoneValue(ODCForm.attorneyHomePhone)"></app-review-field>
                    <app-review-field *ngIf="ODCForm.attorneyOtherPhone" [label]="'REVIEW_BUSINESS_TELEPHONE'" [value]="getPhoneValue(ODCForm.attorneyOtherPhone)"></app-review-field>
                    <app-review-field *ngIf="ODCForm.attorneyClientNumber" [label]="'REVIEW_ATTORNEY_CLIENT_NUMBER'" [value]="ODCForm.attorneyClientNumber"></app-review-field>
                    <app-review-field [label]="'REVIEW_ATTORNEY_RELATIONSHIP'" [value]="getRelationship(ODCForm.attorneyClientRelationship)"></app-review-field>
                    <app-review-field [label]="'REVIEW_ADDRESS'" [value]="getAddressValue(ODCForm.attorneyAddressUnit, ODCForm.attorneyAddressStreet, ODCForm.attorneyAddressCity, ODCForm.attorneyAddressProv, ODCForm.attorneyAddressPostalCode)"></app-review-field>
                    <app-review-field [label]="'REVIEW_EMPLOYMENT_STATUS'" [value]="getEmploymentStatus(ODCForm.attorneyJobStatus)"></app-review-field>
                    <app-review-field-question *ngIf="isEmployed(ODCForm.attorneyJobStatus)" [label]="'REVIEW_EMPLOYMENT_SECURITY_DEALERS'" [value]="isWorkingForBrokering(ODCForm.attorneyEmployerIsBroker)"></app-review-field-question>
                    <app-review-field *ngIf="isEmployed(ODCForm.attorneyJobStatus)" [label]="'REVIEW_EMPLOYER_NAME'" [value]="ODCForm.attorneyEmployerName"></app-review-field>
                    <app-review-field *ngIf="isEmployed(ODCForm.attorneyJobStatus)" [label]="'REVIEW_EMPLOYMENT_JOB_TITLE'" [value]="ODCForm.attorneyJobTitle"></app-review-field>
                    <app-review-field *ngIf="isEmployed(ODCForm.attorneyJobStatus)" [label]="'REVIEW_EMPLOYMENT_SECTOR_ACTIVITY'" [value]="getSectorActivity(ODCForm.attorneyEmployerActivity)"></app-review-field>
                    <app-review-field-question [label]="'REVIEW_ATTORNEY_REPORTING_INSIDER'" [value]="getBooleanValue(ODCForm.attorneyInitieIndicator)"></app-review-field-question>
                    <app-review-sub-field *ngIf="ODCForm.attorneyInitieIndicator" [label]="'REVIEW_COMPANY_NAME'" [value]="ODCForm.attorneyInitieCompanyName"></app-review-sub-field>
                    <app-review-sub-field *ngIf="ODCForm.attorneyInitieIndicator" [label]="'REVIEW_STOCK_SYMBOL'" [value]="ODCForm.attorneyInitieCompanySymbol"></app-review-sub-field>
                    <app-review-sub-field *ngIf="ODCForm.attorneyInitieIndicator" [label]="'REVIEW_MARKET'" [value]="getMarket(ODCForm.attorneyInitieMarket)"></app-review-sub-field>
                    <app-review-field-question [label]="'REVIEW_ATTORNEY_SHAREHOLDER'" [value]="getBooleanValue(ODCForm.attorneyMainShareholderIndicator)"></app-review-field-question>
                    <app-review-sub-field *ngIf="ODCForm.attorneyMainShareholderIndicator" [label]="'REVIEW_COMPANY_NAME'" [value]="ODCForm.attorneyMainShareholderCompanyName"></app-review-sub-field>
                    <app-review-sub-field *ngIf="ODCForm.attorneyMainShareholderIndicator" [label]="'REVIEW_STOCK_SYMBOL'" [value]="ODCForm.attorneyMainShareholderCompanySymbol"></app-review-sub-field>
                    <app-review-sub-field *ngIf="ODCForm.attorneyMainShareholderIndicator" [label]="'REVIEW_MARKET'" [value]="getMarket(ODCForm.attorneyMainShareholderMarket)"></app-review-sub-field>
                </ng-container>
                </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="5" [label]="'REVIEW_REGULATORY_QUESTIONS'" [path]="'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_LEGAL'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_BROKERAGE_ACCOUNTS'">
                <app-review-field-question [label]="'REVIEW_BROKERAGE_OTHER_ACCOUNTS'" [value]="getBooleanValue(getRequesterProperty('brokerageOtherAccountsIndicator'))"></app-review-field-question>
                <app-review-sub-field *ngIf="getRequesterProperty('brokerageOtherAccountsIndicator')" [label]="'REVIEW_FIRM_NAME'" [value]="getRequesterProperty('brokerageOtherAccountsFirmName')"></app-review-sub-field>
                <app-review-sub-field *ngIf="getRequesterProperty('brokerageOtherAccountsIndicator')" [label]="'REVIEW_ACCOUNT_TYPE'" [value]="getOtherAccountType(getRequesterProperty('brokerageOtherAccountsAccountType'))"></app-review-sub-field>
                <app-review-field-question [label]="'REVIEW_REPORTING_INSIDER'" [value]="getBooleanValue(getRequesterProperty('userInitieIndicator'))"></app-review-field-question>
                <app-review-sub-field *ngIf="getRequesterProperty('userInitieIndicator')" [label]="'REVIEW_COMPANY_NAME'" [value]="getRequesterProperty('userInitieCompanyName')"></app-review-sub-field>
                <app-review-sub-field *ngIf="getRequesterProperty('userInitieIndicator')" [label]="'REVIEW_STOCK_SYMBOL'" [value]="getRequesterProperty('userInitieCompanySymbol')"></app-review-sub-field>
                <app-review-sub-field *ngIf="getRequesterProperty('userInitieIndicator')" [label]="'REVIEW_MARKET'" [value]="getMarket(getRequesterProperty('userInitieMarket'))"></app-review-sub-field>
                <app-review-field-question [label]="'REVIEW_SHAREHOLDER'" [value]="getBooleanValue(getRequesterProperty('userMainShareholderIndicator'))"></app-review-field-question>
                <app-review-sub-field *ngIf="getRequesterProperty('userMainShareholderIndicator')" [label]="'REVIEW_COMPANY_NAME'" [value]="getRequesterProperty('userMainShareholderCompanyName')"></app-review-sub-field>
                <app-review-sub-field *ngIf="getRequesterProperty('userMainShareholderIndicator')" [label]="'REVIEW_STOCK_SYMBOL'" [value]="getRequesterProperty('userMainShareholderCompanySymbol')"></app-review-sub-field>
                <app-review-sub-field *ngIf="getRequesterProperty('userMainShareholderIndicator')" [label]="'REVIEW_MARKET'" [value]="getMarket(getRequesterProperty('userMainShareholderMarket'))"></app-review-sub-field>
                <app-review-field-question [label]="'REVIEW_EPV'" [value]="getBooleanValue(getRequesterProperty('userPoliticallyExposedForeignPersonIndicator'))"></app-review-field-question>
                <app-review-field-question [label]="'REVIEW_THIRD_PARTY_USE'" [value]="getBooleanValue(getRequesterProperty('userFinancialInterestIndicator'))"></app-review-field-question>
                <app-review-field-question [label]="'BROKERAGE_TRUSTED_PERSON'" [value]="getBooleanValue(getRequesterProperty('userTrustedPersonIndicator'))"></app-review-field-question>
                <app-review-field [label]="'REVIEW_BROKERAGE_PURPOSE'" [value]="getPurpose(getRequesterProperty('brokeragePurpose'))"></app-review-field>
                <app-review-field [label]="'REVIEW_BROKERAGE_KNOWLEDGE'" [value]="getKnowledge(getRequesterProperty('userBrokerageKnowledge'))"></app-review-field>
            </app-review-sub-section>
            <app-review-sub-section [label]="'REVIEW_COMMUNICATION_BENEFICAL_OWNERS'">
                <app-review-field [label]="'REVIEW_CONSENTMENT'" [value]="getConsentment()"></app-review-field>
                <app-review-field [label]="'REVIEW_RECEIPT_METHOD'" [value]="getReceiptMethod()"></app-review-field>
                <app-review-field [label]="'REVIEW_ELECTRONIC_METHOD'" [value]="getElectronicConsentment()"></app-review-field>
            </app-review-sub-section>
            <app-review-sub-section *ngIf="ODCForm.optionAct" [label]="'REVIEW_OPTION_ACCOUNT'">
                <app-review-field-question [label]="'REVIEW_OPTION_ACCOUNT_ANOTHER_DEALER'" [value]="getBooleanValue(ODCForm.optionActOtherBrokerIndicator)"></app-review-field-question>
                <app-review-sub-field *ngIf="ODCForm.optionActOtherBrokerIndicator" [label]="'REVIEW_FIRM_NAME'" [value]="ODCForm.optionActOtherBrokerFirmName"></app-review-sub-field>
                <app-review-field-question [label]="'REVIEW_OPTION_ACCOUNT_EXPERIENCE_DERIVATIVES'" [value]="getBooleanValue(ODCForm.optionActTradingExperienceIndicator)"></app-review-field-question>

                <app-review-sub-field *ngIf="!ODCForm.optionActOtherBrokerIndicator && ODCForm.optionActTradingExperienceIndicator" [label]="'SPECIFY_EXPERIENCE'" [value]="ODCForm.optionActExperienceSpecification"></app-review-sub-field>

                <app-review-field-question *ngIf="!ODCForm.optionActTradingExperienceIndicator" [label]="'REVIEW_OPTION_ACCOUNT_THEORICAL_KNOWLEDGE'" [value]="getBooleanValue(ODCForm.optionActTradingTheoriqueKnowledgeIndicator)"></app-review-field-question>
                <ng-container *ngFor="let value of getOptionAccountExperience(); let i = index">
                    <app-review-field [label]="'REVIEW_OPTION_ACCOUNT_EXPERIENCE_TRADE'" [value]="value"></app-review-field>
                    <app-review-field *ngIf="ODCForm.optionActTradingExperienceIndicator" [label]="'REVIEW_YEARS_EXPERIENCE'" [value]="getOptionAccountExperienceYears()[i]"></app-review-field>
                </ng-container>
                <app-review-field-list *ngIf="ODCForm.optionActTradingExperienceIndicator" [label]="'REVIEW_OPTION_ACCOUNT_ANTICIPATION_TRADE'" [values]="getOptionAccountAnticipation()"></app-review-field-list>
            </app-review-sub-section>
            <app-review-sub-section [label]="'REVIEW_SPECIFIC_CONSENT_OPT'">
                <app-review-field-question [label]="'REVIEW_SPECIFIC_CONSENT'" [value]="getBooleanValue(getRequesterProperty('personalInformationSpecificConsentIndicator'))"></app-review-field-question>
            </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="6" [label]="'REVIEW_FINANCIAL_INFORMATION_TITLE'" [path]="'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_FINANCIAL_INFORMATION'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_FINANCIAL_INFORMATION'">
                <app-review-field *ngIf="isNotBrokerVmd()" [label]="'REVIEW_ANNUAL_REVENUE'" [value]="getMoneyValue(getRequesterProperty('userAnnualIncome'))"></app-review-field>
                <app-review-field *ngIf="isNotBrokerVmd()" [label]="'REVIEW_NET_CAPITAL'" [value]="getMoneyValue(getRequesterProperty('userNetCapitalAsset'))"></app-review-field>
                <app-review-field *ngIf="isNotBrokerVmd()" [label]="'REVIEW_NET_ASSETS'" [value]="getMoneyValue(getRequesterProperty('userNetLiquidityAsset'))"></app-review-field>
                <app-review-field [label]="'REVIEW_BANK'" [value]="getBank(getRequesterProperty('userBankId'))"></app-review-field>
                <app-review-field [label]="'REVIEW_TRANSIT_NUMBER'" [value]="getRequesterProperty('userBankBranchNumber')"></app-review-field>
                <app-review-field [label]="'REVIEW_ACCOUNT_NUMBER'" [value]="getRequesterProperty('userBankAccountNumber')"></app-review-field>
                <app-review-field *ngIf="getRequesterProperty('userBankPhone')" [label]="'REVIEW_BANK_TELEPHONE'" [value]="getPhoneValue(getRequesterProperty('userBankPhone'))"></app-review-field>
            </app-review-sub-section>
        </app-review-section>
    </app-review-block>

    <app-review-block>
        <app-review-header [step]="7" [label]="'REVIEW_TRANSFER_REQUEST_TITLE'" [path]="'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_PERSONAL_ACCOUNT_PATH'" [targetLabel]="'ODC_NAV_STEP_TRANSFER_REQUEST'"></app-review-header>
        <app-review-section>
            <app-review-sub-section [label]="'REVIEW_TRANSFER_REQUEST_OPT'">
                <app-review-field-question [label]="'REVIEW_TRANSFER_REQUEST_FROM_ANOTHER_FINANCIAL_INSTITUTION'" [value]="getBooleanValue(hasTransfers())"></app-review-field-question>
            </app-review-sub-section>

            <ul class="list-unstyled">
                <li *ngIf="ODCForm.transfer" class="p-l-0">
                    <ul class="list-unstyled">
                        <li *ngFor="let transfer of ODCForm.accountTransferList" class="p-l-0">
                            <app-review-transfer [transfer]="transfer" [baseAccountType]="ODCForm.actType"></app-review-transfer>
                        </li>
                    </ul>
                </li>
                <ng-container *ngFor="let key of ['tfsa', 'fhsa', 'rrsp', 'lira', 'rrif', 'lif', 'resp']">
                    <li *ngIf="ODCForm[key + 'Transfer']" class="p-l-0">
                        <ng-container *ngIf="key !== 'resp'">
                            <ul class="list-unstyled">
                                <li *ngFor="let transfer of ODCForm[key + 'AccountTransferList']" class="p-l-0">
                                    <app-review-transfer [transfer]="transfer"></app-review-transfer>
                                </li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="key === 'resp'">
                            <app-review-transfer [transfer]="getTransferResp()"></app-review-transfer>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </app-review-section>
    </app-review-block>

    <div class="btn-resp btn-resp-1colonne-inline m-t-4">
        <div class="btn-resp-col btn-resp-demi">
            <button type="button" class="btn btn-primary" (click)="navNext()">
                {{'BTN_NEXT' | translate}}
            </button>
        </div>
        <div class="btn-resp-col btn-resp-demi">
            <button *ngIf="showBack()" type="button" class="btn btn-default" (click)="navBack()">{{'BTN_BACK' | translate}}</button>
        </div>
    </div>

</div>
