import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

import {TokenService} from './token.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {map} from "rxjs/operators";

@Injectable()
export class SessionService {

    //Representing the timestamp, in milliseconds, of the last session update.
    lastSessionUpdate: number

    constructor(private http: HttpClient,
                private tokenService: TokenService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public setLastSessionUpdate(lastUpdate: number) {
        this.lastSessionUpdate = lastUpdate;
    }

    public getLastSessionUpdate(): number {
        return this.lastSessionUpdate;
    }

    public getNewToken(): Observable<any> {

        return this.http.post('api/session/begin', {
            username: this.config.AGENT_DISNAT_USERNAME,
            // Origine pour la création
            origin: this.getOrigin(),
            // Source pour la reprise
            src: this.config.SOURCE
        }).pipe(map(value => {
            this.lastSessionUpdate = Date.now();
            return value
        }));
    }

    public getOrigin(): string {
        // Source pour la reprise
        if (this.config.SOURCE) {
            return this.config.SOURCE;
        }

        // Origine pour la création
        if (this.fromInternalOrigin() || this.fromInternalUpdateOrigin()) {
            return 'INTERNAL';
        } else if (!!this.config.CODE_USAGER && !!this.config.INST_ORIG && !!this.config.TRANSIT_ORIG) {
            return 'CAISSE';
        } else {
            return 'WEB';
        }
    }

    private fromInternalOrigin(): boolean {

        // AGENT_ID is not undefined, null or empty AND AGENT_DISNAT_USERNAME is not null and not empty. AGENT_DISNAT_USERNAME can be undefined.
        return !!this.config.AGENT_ID && this.config.AGENT_DISNAT_USERNAME !== null && this.config.AGENT_DISNAT_USERNAME !== '';
    }

    private fromInternalUpdateOrigin(): boolean {

        // IS_UPDATE_ACCOUNT is not undefined, null or empty AND AGENT_DISNAT_USERNAME is not null and not empty. AGENT_DISNAT_USERNAME can be undefined.
        return !!this.config.IS_UPDATE_ACCOUNT && this.config.AGENT_DISNAT_USERNAME !== null && this.config.AGENT_DISNAT_USERNAME !== '';
    }

    public beginSession(token: string) {

        if (token) {

            this.tokenService.setToken(token);
        }
    }

    public refreshSession(): Observable<any> {

        return this.http.get('api/session/heartbeat', {withCredentials: true});
    }

    public resetSession() : Observable<any> {
        return this.http.get('api/session/reset');
    }

    public endSession(): void {

        this.tokenService.setToken(null);
        this.resetParameters();
    }

    public resetParameters(): void {

        this.config.FROM_EXISTING_REQUEST = '';
    }

    public hasSessionInProgress(): boolean {

        return !!this.tokenService.getToken();
    }
}
