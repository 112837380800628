import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
    selector: 'app-review-header',
    templateUrl: './review-header.component.html',
    providers: [TranslatePipe]
})
export class ReviewHeaderComponent implements OnInit {

    @Input() step: number;
    @Input() label: string;
    @Input() path: string;
    @Input() targetLabel: string;

    constructor(private router: Router,
                private translatePipe: TranslatePipe) {
    }

    ngOnInit() {

    }

    public navigateToSection() {

        this.router.navigate(['/', this.translatePipe.transform(this.path)]);
    }
}
