import {AfterViewInit, Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormService} from "../services/form.service";

declare let $: any;

@Directive({
    selector: '[appDatePicker]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerDirective),
            multi: true
        }
    ]
})
export class DatePickerDirective implements AfterViewInit {
    @Input() form: FormGroup;
    @Input() name: string;
    @Input() binding: string;
    @Input() beforeShowDay: (date: Date) => boolean;

    constructor(private el: ElementRef,
                private formService: FormService) {
    }

    ngAfterViewInit() {
        this.initializeDatepicker();
    }

    initializeDatepicker() {
        const textInput = $(this.el.nativeElement);
        const dateInput = textInput.parent().find('.input-group-addon-calendar');

        dateInput.on('changeDate', (event: any) => {
            const control = this.form.get(this.name);
            const value = event.format('yyyy-mm-dd');
            this.formService.setFormProperty(this.binding, value);
            control.setValue(value);
            control.markAsTouched();
        });

        this.updateDatepicker();
    }

    @HostListener('change', ['$event.target'])
    onChange(target: HTMLInputElement) {
        const textInput = $(this.el.nativeElement);
        const dateInput = textInput.parent().find('.input-group-addon-calendar');
        const value = target.value;
        dateInput.datepicker('update', typeof value === 'string' ? value : '');
    }

    updateDatepicker() {
        const textInput = $(this.el.nativeElement);
        const dateInput = textInput.parent().find('.input-group-addon-calendar');
        dateInput.datepicker({
            autoclose: true,
            clearBtn: false,
            format: 'yyyy-mm-dd',
            beforeShowDay: this.beforeShowDay || undefined
        });
        dateInput.datepicker('update', this.form.get(this.name).value);
    }
}
