export class Entity {

    entityType: string;
    entityName: string;
    physicalPersonsUs: number;
    physicalPersonsOtherThanUs: number;
    isUsResident: boolean;
    entities: Entity[];

    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {

            this.entityType = this.init(obj.entityType);
            this.entityName = this.init(obj.entityName);
            this.physicalPersonsUs = this.init(obj.physicalPersonsUs);
            this.physicalPersonsOtherThanUs = this.init(obj.physicalPersonsOtherThanUs);
            this.isUsResident = this.init(obj.isUsResident);
            this.entities = this.init(obj.entities);

        }
    }
}
