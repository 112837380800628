<dl class="row">
    <dt class="col-md-14">{{ label | translate }} :</dt>
    <dd class="col-md-10">
        <ul class="list-unstyled">
            <li *ngFor="let value of values" class="p-l-0">
                {{ value }}
            </li>
        </ul>
    </dd>
</dl>
