import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {FormGroup, NgForm} from '@angular/forms';
import {HtmlSelectKV} from '../../../../components/models/html-select-k-v';
import {VmdForm} from '../../../../components/utils/vmd-form';
import {VmdKeyboard} from '../../../../components/utils/vmd-keyboard';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {FormService} from '../../../../components/services/form.service';

@Component({
    selector: 'app-odc-field',
    templateUrl: './odc-field.component.html',
    providers: [
        VmdKeyboard
    ]
})
export class OdcFieldComponent implements OnInit, AfterViewInit {

    @Input() type: string;
    @Input() form: FormGroup;
    @Input() ngForm: NgForm;
    @Input() submitted: boolean;
    @Input() label: string;
    @Input() hiddenLabel: boolean;
    @Input() id: string;
    @Input() name: string;
    @Input() controlName: string;
    @Input() path: string;
    @Input() pathPasswordStrengthIndicator: string;
    @Input() nameSuffix: string = null;
    @Input() list: HtmlSelectKV[];
    @Input() filteredList: HtmlSelectKV[];
    @Input() defaultOptionLabel: string;
    @Input() value: any;
    @Input() defaultValue: string;
    @Input() choices: {
        label: string,
        labelSrOnly?: boolean,
        value?: any,
        id?: string,
        binding?: string,
        templateRef?: TemplateRef<any>,
        helpRefFr?: TemplateRef<any>,
        helpRefEn?: TemplateRef<any>,
        hidden?: boolean
    }[];
    @Input() binding: string;
    @Input() helpText: string;
    @Input() autoComplete: string;
    @Input() className: string;

    @Input() stacked = false;
    @Input() attr: any = {};
    @Input() readonlyProperty: string;
    @Input() attrDisabled: boolean;

    @Input() countryCompleterCountries = false;
    @Input() currencyFormat = false;
    @Input() interestRateFormat = false;
    @Input() dateFormat = false;
    @Input() datePicker = false;
    @Input() decimalCurrencyFormat = false;
    @Input() integerFormat = false;
    @Input() nameFormat = false;
    @Input() numericFormat = false;
    @Input() percentFormat = false;
    @Input() phoneFormat = false;
    @Input() securityQuantityFormat = false;
    @Input() sinFormat = false;

    @Input() templateRefFr: TemplateRef<any>;
    @Input() templateRefEn: TemplateRef<any>;

    @Input() labelSrOnly = false;

    @Input() errorOnSubmitOnly: string[] = null;

    @ViewChild('input') inputElement: ElementRef;

    @Input() beforeShowDay: (date: any) => boolean;

    noFormat = true;
    fieldTypes = VmdConstants.FIELD_TYPES;
    lastPath: string;

    constructor(private formService: FormService) {
    }

    ngOnInit() {
        this.noFormat = this.type === this.fieldTypes.PASSWORD
            || (
                !this.countryCompleterCountries
                && !this.currencyFormat
                && !this.interestRateFormat
                && !this.dateFormat
                && !this.datePicker
                && !this.decimalCurrencyFormat
                && !this.integerFormat
                && !this.nameFormat
                && !this.numericFormat
                && !this.percentFormat
                && !this.phoneFormat
                && !this.securityQuantityFormat
                && !this.sinFormat
            );
        if (!this.type) {
            this.type = this.fieldTypes.TEXT;
        }
        if (!this.controlName) {
            this.controlName = this.name;
        }

        this.lastPath = VmdForm.getLastPath(this.path);
    }

    ngAfterViewInit() {
        for (const prop in this.attr) {
            if (this.attr.hasOwnProperty(prop)) {
                this.inputElement.nativeElement.setAttribute(prop, this.attr[prop]);
            }
        }
    }

    getLabelVisibility(): string {
        return this.hiddenLabel ? 'hidden' : 'visible';
    }

    getAriaDescribedby(): string {
        return this.isFieldInError() ? 'ERROR_FIELD_' + this.lastPath + '-error' : null;
    }

    isFieldInError(): boolean {
        return VmdForm.isFieldInError(this.form.get(this.path), this.submitted, this.errorOnSubmitOnly);
    }

    isReadOnly(): boolean {

        if (this.readonlyProperty) {
            const field = this.formService.getDdpRequester()[this.readonlyProperty];

            if (field === null || typeof field === 'undefined') {
                return false;
            } else if (field.toString().trim().length > 0) {
                return true;
            }
        }

        return false;
    }

    // OCE-7163
    isDisabledChoice(name: string): boolean {

        if (this.readonlyProperty && this.readonlyProperty === name) {
            const field = this.formService.getDdpRequester()[this.readonlyProperty];

            if (field === null || typeof field === 'undefined') {
                return false;
            } else if (field.toString().trim().length > 0) {
                return true;
            }
        }

        return false;
    }

    isOptionDisabled(option: HtmlSelectKV): boolean {
        return this.filteredList && !this.filteredList.includes(option);
    }

    onPasswordStrengthChanges(value: number) {
        if (value != null) {
            this.form.get(this.pathPasswordStrengthIndicator).setValue(value);
        }
    }
}
