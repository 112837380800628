<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2>{{ getAccountHolderInformationTitle() | translate }}</h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div *ngIf="(isPleinEx() || isUpdateAccount()) && requesterIndex === 0">
        <!--formLang----------------------------------------------------------------------->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE' | translate"
            [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_FR' | translate, value: constants.LANG_FR}, {label: 'ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_EN' | translate, value: constants.LANG_EN}]"
            [id]="'formLang'"
            [name]="'formLang'"
            [path]="'formLang'"
            [binding]="getRequesterPath('formLang')"
        ></app-odc-field>
    </div>

    <!--Gender-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
        [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
        [id]="'gender'"
        [name]="'gender'"
        [path]="'gender'"
        [binding]="getRequesterPath('gender')"
        [readonlyProperty]="'gender'"
        [attrDisabled]="true"
    ></app-odc-field>

    <!--firstname & lastname--------------------------------->
    <div class="row">
        <div class="col-md-12">
            <app-odc-field
                [attrDisabled]='true'
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                [id]="'userFirstName'"
                [name]="'userFirstName'"
                [path]="'userFirstName'"
                [binding]="getRequesterPath('userFirstName')"
                [attr]="{maxlength: 32}"
                [readonlyProperty]="'userFirstName'"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [attrDisabled]='true'
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                [id]="'userLastName'"
                [name]="'userLastName'"
                [path]="'userLastName'"
                [binding]="getRequesterPath('userLastName')"
                [attr]="{maxlength: 32}"
                [readonlyProperty]="'userLastName'"
            ></app-odc-field>
        </div>
    </div>

    <!--DOB & SIN-------------------------------------------------------->
    <div *ngIf="isFullFields()" class="row">
        <div class="col-md-12">
            <app-odc-field
                [attrDisabled]='true'
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                [id]="'userDOB'"
                [name]="'userDOB'"
                [path]="'userDOB'"
                [binding]="getRequesterPath('userDOB')"
                [readonlyProperty]="'userDOB'"
                [dateFormat]="true"
                [templateRefFr]="helpDOB"
                [templateRefEn]="helpDOB"
                [autoComplete]="'on'"
            ></app-odc-field>
        </div>
        <div class="col-md-12">
            <app-odc-field
                [autoComplete]="'off'"
                [attrDisabled]='true'
                [type]="constants.FIELD_TYPES.TEXT"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_SIN' | translate"
                [id]="'userSIN'"
                [name]="'userSIN'"
                [path]="'userSIN'"
                [binding]="getRequesterPath('userSIN')"
                [attr]="{maxlength: 11}"
                [readonlyProperty]="'userSIN'"
                [sinFormat]="true"
                [templateRefFr]="helpSIN"
                [templateRefEn]="helpSIN"
            ></app-odc-field>
        </div>
    </div>

    <!--HomePhone & OtherPhone------------------------------------------------>
    <div formGroupName="phoneGroup">
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="(this.isFieldUserMobileShown() ? 'ODC_STEP_PERSONAL_INFORMATION_PHONE_HOME' : 'ODC_STEP_PERSONAL_INFORMATION_HOME_PHONE') | translate"
                    [id]="'userHomePhone'"
                    [name]="'userHomePhone'"
                    [path]="'userHomePhone'"
                    [binding]="getRequesterPath('userHomePhone')"
                    [attr]="{maxlength: 10}"
                    [readonlyProperty]="'userHomePhone'"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
            <div *ngIf="isFullFields()" class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="(this.isFieldUserMobileShown() ? 'ODC_STEP_PERSONAL_INFORMATION_PHONE_BUSINESS' : 'ODC_STEP_PERSONAL_INFORMATION_OTHER_PHONE') | translate"
                    [id]="'userOtherPhone'"
                    [name]="'userOtherPhone'"
                    [path]="'userOtherPhone'"
                    [binding]="getRequesterPath('userOtherPhone')"
                    [attr]="{maxlength: 10}"
                    [readonlyProperty]="'userOtherPhone'"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
        </div>
        <div class="row">
            <div *ngIf="isFieldUserMobileShown()" class="col-md-12">
                <app-odc-field
                    [type]="constants.FIELD_TYPES.TEXT"
                    [form]="form.get('phoneGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_PHONE_MOBILE' | translate"
                    [id]="'userMobilePhone'"
                    [name]="'userMobilePhone'"
                    [path]="'userMobilePhone'"
                    [binding]="getRequesterPath('userMobilePhone')"
                    [attr]="{maxlength: 10}"
                    [readonlyProperty]="'userMobilePhone'"
                    [phoneFormat]="true"
                ></app-odc-field>
            </div>
        </div>
    </div>

    <!--Email----------------------------------------------------------------------->
    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_PERSONAL_INFORMATION_EMAIL' | translate"
        [id]="'userEmail'"
        [name]="'userEmail'"
        [path]="'userEmail'"
        [binding]="getRequesterPath('userEmail')"
        [attr]="{maxlength: 64}"
        [readonlyProperty]="'userEmail'"
    ></app-odc-field>


    <div class="m-b-2" *ngIf="isOtherPersonThanFirstAccountHolder() || isEnfRefOrEenfEsQualites()">

        <!--isSameAddressAsFirstIndicator-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_IS_ADRESS_DIFFERENT_AS_FIRST_ACCOUNT_HOLDER' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: true}, {label: 'GLOBAL_YES' | translate, value: false}]"
            [id]="'isSameAddressAsFirstIndicator'"
            [name]="'isSameAddressAsFirstIndicator'"
            [path]="'isSameAddressAsFirstIndicator'"
            [binding]="getRequesterPath('isSameAddressAsFirstIndicator')"
        ></app-odc-field>
    </div>


    <ng-container>
        <br>
        <div class="panel panel-default panel-noborder">
            <div class="panel-body">
                <h3>{{ 'ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TITLE'|translate }}</h3>
                <app-address-form
                    *ngIf="!hasUserAddressUnstructured()"
                    [legend]="'ODC_STEP_PERSONAL_INFORMATION_HOME_ADDRESS_TITLE'|translate"
                    [form]="addressForm"
                    [index]="''"
                    [street]="'userAddressStreetOSS'"
                    [unit]="'userAddressUnitOSS'"
                    [city]="'userAddressCityOSS'"
                    [province]="'userAddressProvOSS'"
                    [postalcode]="'userAddressPostalCodeOSS'"
                    [country]="'userAddressCountryOSS'"
                    [isStreetReadOnly]="true"
                    [isUnitReadOnly]="true"
                    [isCityReadOnly]="true"
                    [isPostalCodeReadOnly]="true"
                    [isProvinceReadOnly]="true"
                    [isCountryReadOnly]="true"
                ></app-address-form>

                <table *ngIf="hasUserAddressUnstructured()">
                    <thead><th></th></thead>
                    <tbody>
                    <ng-container *ngFor="let addressUnstructured of userAddressUnstructured">
                        <tr>
                            <td>{{ addressUnstructured }}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <!--address type-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [stacked]="true"
            [form]="addressForm"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TYPE' | translate"
            [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_OSS_IS_RESIDENTIAL_ADDRESS' | translate, value: constants.OSS_HOME_ADDRESS},
            {label: 'ODC_STEP_PERSONAL_INFORMATION_OSS_IS_MAILING_ADDRESS' | translate, value: constants.OSS_MAILING_ADDRESS}]"
            [id]="'userAddressType'"
            [name]="'userAddressType'"
            [path]="'userAddressType'"
            [binding]="'userAddressType'"
            [attrDisabled]="isMandateOnlyAndUSAAddress() ? true : null"
        ></app-odc-field>
    </ng-container>

    <div *ngIf="isFullFields() && showHomeAddress" formGroupName="addressGroup">
        <!--Home Address--------------------------------------------------------------------------->
        <h3>{{ 'ODC_STEP_PERSONAL_INFORMATION_HOME_ADDRESS_TITLE'|translate }}</h3>
        <div formGroupName="homeAddress">
            <app-address-form
                [legend]="'ODC_STEP_PERSONAL_INFORMATION_HOME_ADDRESS_TITLE'|translate"
                [form]="form"
                [index]="''"
                [submitted]="isFormSubmitted()"
                [bindPath]="'requesters.' + requesterIndex + '.'"
                [path]="'addressGroup.homeAddress'"
                [street]="'userAddressStreet'"
                [unit]="'userAddressUnit'"
                [city]="'userAddressCity'"
                [province]="'userAddressProv'"
                [postalcode]="'userAddressPostalCode'"
                [country]="'userAddressCountry'"
                [isCountryReadOnly]="isGpdOrSfd() || isSsdPortfolioManagementAdvisorProgram() || isVmdPortfolioManagementMandateOnly()"
            ></app-address-form>
        </div>

        <div class="m-b-1">
            <ng-container *ngIf="!showMailingAddress">
                <a href="#" class="lien-sans-soulignement" (click)="toggleMailingAddress(true)">
                    <span class="icon-circle-plus"></span>
                    {{ 'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_ADD'|translate }}
                </a>
            </ng-container>
        </div>

        <!--Mailing Address---------------------------------------------------------------->
        <div *ngIf="showMailingAddress" formGroupName="mailingAddress">
            <h3>{{ 'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_TITLE'|translate }}</h3>
            <app-address-form
                [legend]="'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_TITLE'|translate"
                [form]="form"
                [index]="''"
                [submitted]="isFormSubmitted()"
                [bindPath]="'requesters.' + requesterIndex + '.'"
                [path]="'addressGroup.mailingAddress'"
                [street]="'userAddressStreetSec'"
                [unit]="'userAddressUnitSec'"
                [city]="'userAddressCitySec'"
                [province]="'userAddressProvSec'"
                [postalcode]="'userAddressPostalCodeSec'"
                [country]="'userAddressCountrySec'"
            ></app-address-form>
        </div>

        <div class="m-b-1">
            <ng-container *ngIf="showMailingAddress">
                <a href="#" class="lien-sans-soulignement" (click)="toggleMailingAddress(false)">
                    <span class="icon-close rouge"></span>
                    {{ 'ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_REMOVE'|translate }}
                </a>
            </ng-container>
        </div>
    </div>

    <!--jointSecondAccountholderSpouse----------------------------------------------------------------------->
    <div *ngIf="isPleinExJointAccountFirstAccountholderOfTwo()">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_PERSONAL_INFORMATION_IS_SECOND_ACCOUNTHOLDER_SPOUSE' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'jointSecondAccountholderSpouse'"
            [name]="'jointSecondAccountholderSpouse'"
            [path]="'jointSecondAccountholderSpouse'"
            [binding]="'jointSecondAccountholderSpouse'"
        ></app-odc-field>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpSIN>
    <h2>{{ 'HELP_SIN_TITLE' | translate }}</h2>
    <p>{{ 'HELP_SIN_BODY' | translate }}</p>
</ng-template>

<ng-template #helpDOB>
    <h2>{{ 'HELP_DOB_TITLE' | translate }}</h2>
    <p>{{ 'HELP_DOB_BODY' | translate }}</p>
</ng-template>
