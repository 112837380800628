<h2>{{'CONFIRMATION_TITLE'| translate}}</h2>

<div class="p-20 bg-concrete text-align-justify">
    <p class="h5" *ngIf="formService.getForm().transmissionMethod === constants.TRANSMISSION_ESIGN">
        {{'CONFIRMATION_TEXT_ESIGN'|translate}}
    </p>
    <p class="h5" *ngIf="formService.getForm().transmissionMethod === constants.TRANSMISSION_ESIGN_MAIL">
        {{'CONFIRMATION_TEXT_ESIGN_MAIL'|translate}}
    </p>
    <p class="h5" *ngIf="formService.getForm().transmissionMethod === constants.TRANSMISSION_MAIL">
        {{'CONFIRMATION_TEXT_MAIL'|translate}}
    </p>

    <div *ngIf="formService.getForm().transmissionMethod !== this.constants.TRANSMISSION_ESIGN" class="panel panel-default panel-noborder">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-6">
                    <img [src]="'assets/img/pdf-download.png'" alt="">
                </div>
                <div class="col-md-16">
                <!-- <div>
                        <button class="btn btn-primary btn-block loadable"
                                (click)="downloadPDF()"
                                [class.loading]="loadingDownload"
                                [class.m-t-5]="!showMailButton() || formService.getForm().requesters[0].userOtherTradingPersonIndicator"
                                [attr.disabled]="loadingDownload ? true : null">
                            <i class="fa fa-arrow-circle-down"></i>

                            <span *ngIf="showMailButton()">
                                {{'TRANSMISSION_ESIGN_MAIL_DOWNLOAD_BTN' | translate}}
                            </span>
                            <span *ngIf="!showMailButton()">
                                {{'TRANSMISSION_MAIL_DOWNLOAD_BTN' | translate}}
                                <br>
                                <span class="button-weight-indication">
                                    {{'TRANSMISSION_MAIL_DOWNLOAD_WEIGHT' | translate}}
                                </span>
                            </span>
                        </button>
                    </div>-->
                    <div *ngIf="showMailButton() && !formService.getForm().requesters[0].userOtherTradingPersonIndicator">
                        <!--<div class="m-t-3 m-b-3 text-center">{{'ODC_OR' | translate}}</div>-->
                        <button class="m-t-5 btn btn-default btn-block loadable"
                                (click)="emailPDF()"
                                [class.loading]="loadingEmail"
                                [attr.disabled]="loadingEmail ? true : null">
                            <i class="fa fa-envelope"></i>
                            {{'TRANSMISSION_ESIGN_MAIL_EMAIL_BTN' | translate}}
                        </button>
                    </div>
                    <div *ngIf="error !== null" class="alert alert-danger">{{'GLOBAL_ERROR_MSG' | translate}}</div>
                </div>
            </div>
        </div>
    </div>
    <p class="h5" *ngIf="hasTransfer()">
        {{'CONFIRMATION_TEXT_TRANSFER'|translate}}
    </p>
</div>

<h3>{{'CONFIRMATION_FUND_TITLE'|translate}}</h3>
<div class="panel panel-simple text-align-justify p-a-2">
    <div class="h5">{{'CONFIRMATION_FUND_AS_FOLLOW'|translate}}</div>
    <div class="h5">{{'CONFIRMATION_FUND_OPTION1'|translate}}</div>
    <p>{{'CONFIRMATION_FUND_OPTION1_TEXT'|translate}}</p>

    <div class="h5">{{'CONFIRMATION_FUND_OPTION2'|translate}}</div>
    <ol>
        <li>{{'CONFIRMATION_FUND_OPTION2_TEXT1'|translate}}</li>
        <li>{{'CONFIRMATION_FUND_OPTION2_TEXT2'|translate}}</li>
        <li>{{'CONFIRMATION_FUND_OPTION2_TEXT3'|translate}}</li>
    </ol>
</div>
<div class="panel-background app-mobile">
    <div class="p-t-4" >
        <b>{{'CONFIRMATION_DISNAT_MOBILE1'|translate}}</b>
    </div>
    <div class="p-t-4">
        <a href="{{ 'CONFIRMATION_DISNAT_MOBILE_LINK' | translate }}" class="lien-externe" target="_blank">
            {{'CONFIRMATION_DISNAT_MOBILE2'|translate}}
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>
    </div>
</div>
<div class="panel-background info-courriel m-t-3">
    <div class="p-t-4" >
        <b>{{'CONFIRMATION_EMAIL_SUBSCRIPTION1'|translate}}</b>
    </div>
    <div class="p-t-4">
        <a href="{{ 'CONFIRMATION_EMAIL_SUBSCRIPTION_LINK' | translate }}" class="lien-externe" target="_blank">
            {{'CONFIRMATION_EMAIL_SUBSCRIPTION2'|translate}}
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>
    </div>
</div>
