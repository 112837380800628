<div id="modal-forgot-password" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-password-label"
     aria-describedby="modal-password-desc">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-password-label" data-loop="start" tabindex="0">
                    {{ 'ODC_MODAL_CONNEXION_TITLE' | translate }}
                </h2>
            </div>
            <div class="modal-body" [attr.id]="'modal-password-desc'">

                <app-http-error [errors]="httpErrors"></app-http-error>

                <div *ngIf="displayProfileNotFoundTemplate">
                    <p [innerHTML]="'ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_DESC' | translate : {email: requesterEmail}"></p>
                </div>

                <div *ngIf="displayResetPasswordTemplate" class="form-horizontal">
                    <div *ngIf="!emailSent">
                        <h3 class="m-t-0">{{'ODC_MODAL_FORGOT_PASSWORD_TITLE' | translate}}</h3>
                        <p [innerHTML]="'ODC_MODAL_FORGOT_PASSWORD_DESC' | translate : {email: requesterEmail}"></p>
                        <app-recaptcha *ngIf="renderRecaptcha"
                                       [containerId]="'recaptcha-forgot-password'"
                                       [langChange]="translate.onLangChange"
                                       [lang]="translate.currentLang"
                        ></app-recaptcha>
                    </div>

                    <p *ngIf="emailSent" [innerHTML]="'FORGOT_PASSWORD_EMAIL_SENT' | translate : {email: requesterEmail}"></p>
                </div>
            </div>

            <div class="modal-footer">
                <ng-container *ngIf="displayResetPasswordTemplate">
                    <div class="btn-resp btn-resp-1colonne-inline" *ngIf="!emailSent">
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-primary loadable"
                                    title="{{'ODC_MODAL_FORGOT_PASSWORD_CONFIRMATION_BUTTON_LABEL'|translate}}"
                                    type="submit"
                                    data-focus="initial"
                                    [class.loading]="loading"
                                    [attr.disabled]="loading ? true : null"
                                    (click)="submit()">
                                {{'ODC_MODAL_FORGOT_PASSWORD_CONFIRMATION_BUTTON_LABEL'|translate}}
                            </button>
                        </div>
                        <div class="btn-resp-col btn-resp-demi">
                            <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                    type="button">
                                {{'BTN_CANCEL'|translate}}
                            </button>
                        </div>
                    </div>
                    <button *ngIf="emailSent" class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}">
                        {{'BTN_CLOSE'|translate}}
                    </button>
                </ng-container>

                <div class="btn-resp btn-resp-1colonne-inline" *ngIf="displayProfileNotFoundTemplate">
                    <div class="btn-resp-col btn-resp-demi">
                        <button class="btn btn-primary loadable"
                                title="{{'ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_CONFIRMATION_BUTTON_LABEL'|translate}}"
                                data-focus="initial"
                                (click)="redirectToAuthModal()">
                            {{'ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_CONFIRMATION_BUTTON_LABEL'|translate}}
                        </button>
                    </div>
                    <div class="btn-resp-col btn-resp-demi">
                        <button class="btn btn-default" data-dismiss="modal" title="{{'BTN_CANCEL'|translate}}"
                                type="button">
                            {{'GLOBAL_CLOSE'|translate}}
                        </button>
                    </div>
                </div>
            </div>

            <div class="sr-only sr-only-focusable" tabindex="0" data-loop="end">{{'MODAL_END'|translate}}</div>
        </div>
    </div>
</div>
