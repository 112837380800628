<form [formGroup]="form" ngForm novalidate (submit)="submit()">
    <h2>
        <span class="b-c-h-icon icon-disnat2"></span>
        {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TITLE'|translate}}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--broker indicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'optionActOtherBrokerIndicator'"
        [name]="'optionActOtherBrokerIndicator'"
        [path]="'optionActOtherBrokerIndicator'"
        [binding]="'optionActOtherBrokerIndicator'"
        [stacked]="true"
    ></app-odc-field>

    <div *ngIf="this.form.get('optionActOtherBrokerIndicator').value">
        <!--broker firm name-->
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER_FIRM' | translate"
            [id]="'optionActOtherBrokerFirmName'"
            [name]="'optionActOtherBrokerFirmName'"
            [path]="'optionActOtherBrokerFirmName'"
            [binding]="'optionActOtherBrokerFirmName'"
            [attr]="{maxlength: 32}"
        ></app-odc-field>

    </div>

    <!--experience indicator-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'optionActTradingExperienceIndicator'"
        [name]="'optionActTradingExperienceIndicator'"
        [path]="'optionActTradingExperienceIndicator'"
        [binding]="'optionActTradingExperienceIndicator'"
        [stacked]="true"
    ></app-odc-field>

    <!--experience knowledge-->
    <div *ngIf="form.get('optionActTradingExperienceIndicator').value === false">
        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_KNOWLEDGE' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'optionActTradingTheoriqueKnowledgeIndicator'"
            [name]="'optionActTradingTheoriqueKnowledgeIndicator'"
            [path]="'optionActTradingTheoriqueKnowledgeIndicator'"
            [binding]="'optionActTradingTheoriqueKnowledgeIndicator'"
            [stacked]="true"
        ></app-odc-field>
    </div>

    <div *ngIf="form.get('optionActTradingExperienceIndicator').value && !this.form.get('optionActOtherBrokerIndicator').value">
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="form"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_SPECIFY_EXPERIENCE' | translate"
            [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
            [id]="'optionActExperienceSpecification'"
            [name]="'optionActExperienceSpecification'"
            [path]="'optionActExperienceSpecification'"
            [binding]="'optionActExperienceSpecification'"
            [attr]="{maxlength: 32}"
            [stacked]="true"
        ></app-odc-field>
    </div>

    <div formGroupName="optionActCheckboxGroup">
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingExperienceOperationLevel1'"></app-error>
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingPlannedOperationLevel1'"></app-error>
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingExperienceYears1'"></app-error>
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingExperienceYears2'"></app-error>
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingExperienceYears3'"></app-error>
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="'optionActCheckboxGroup.optionActTradingExperienceYears4'"></app-error>

        <table class="table tableau-donnees">
            <caption class="sr-only sr-only-focusable">{{ 'ODC_STEP_LEGAL_OPTION_ACCOUNT_TITLE' | translate }}
            </caption>

            <thead>
            <tr>
                <th class="va-top">
                    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE'|translate}}
                    <app-help [templateRefFr]="helpType" [templateRefEn]="helpType"></app-help>
                </th>
                <th width="120" class="va-top" *ngIf="form.get('optionActTradingExperienceIndicator').value">
                    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_EXPERIENCE'|translate}}
                </th>
                <th width="120" class="va-top" *ngIf="form.get('optionActTradingExperienceIndicator').value">
                    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_YEAR'|translate}}
                </th>
                <th width="120" class="va-top">
                    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_PLANNING'|translate}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let index of [1,2,3,4]">
                <th>
                    {{ 'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL' + index | translate }}
                    <app-help *ngIf="index === 1" [templateRefFr]="helpLevel1" [templateRefEn]="helpLevel1"></app-help>
                    <app-help *ngIf="index === 2" [templateRefFr]="helpLevel2" [templateRefEn]="helpLevel2"></app-help>
                    <app-help *ngIf="index === 3" [templateRefFr]="helpLevel3" [templateRefEn]="helpLevel3"></app-help>
                    <app-help *ngIf="index === 4" [templateRefFr]="helpLevel4" [templateRefEn]="helpLevel4"></app-help>
                </th>
                <td *ngIf="form.get('optionActTradingExperienceIndicator').value"
                    [class.error]="isFieldInError('optionActTradingExperienceOperationLevel1')"
                >
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.CHECKBOX"
                        [form]="form.get('optionActCheckboxGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_EXPERIENCE' | translate"
                        [labelSrOnly]="true"
                        [choices]="[
                        {
                            id: 'optionActTradingExperienceOperationLevel'+ index,
                            binding: 'optionActTradingExperienceOperationLevel'+ index,
                            disabled: isDisabledOptionAct(index) ? true : null,
                            name: 'optionActTradingExperienceOperationLevel' + index,
                            label: 'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_EXPERIENCE' | translate,
                            labelSrOnly: true
                        }
                            ]"
                        [path]="'optionActTradingExperienceOperationLevel'+ index"
                    ></app-odc-field>
                </td>
                <td   *ngIf="form.get('optionActTradingExperienceIndicator').value"
                      [class.error]="isFieldInError('optionActCheckboxGroup.optionActTradingExperienceYears')"
                      class="p-t-0"
                >
                    <ng-container *ngIf="form.get('optionActCheckboxGroup.optionActTradingExperienceOperationLevel' + index).value">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('optionActCheckboxGroup')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_YEAR' | translate"
                            [labelSrOnly]="true"
                            [id]="'optionActTradingExperienceYears' + index"
                            [name]="'optionActTradingExperienceYears' + index"
                            [binding]="'optionActTradingExperienceYears' + index"
                            [attr]="{maxlength: 2}"
                            [path]="'optionActTradingExperienceYears'+ index"
                            [integerFormat]="true"
                        ></app-odc-field>
                    </ng-container>
                </td>
                <td [class.error]="isFieldInError('optionActCheckboxGroup.optionActTradingPlannedOperationLevel1')"
                >
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.CHECKBOX"
                        [form]="form.get('optionActCheckboxGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_PLANNING' | translate"
                        [labelSrOnly]="true"
                        [choices]="[
                        {
                            id: 'optionActTradingPlannedOperationLevel'+ index,
                            binding: 'optionActTradingPlannedOperationLevel'+ index,
                            disabled: isDisabledOptionAct(index) ? true : null,
                            name: 'optionActTradingPlannedOperationLevel' + index,
                            label: 'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_PLANNING' | translate,
                            labelSrOnly: true
                        }
                            ]"
                        [path]="'optionActTradingPlannedOperationLevel'+ index"
                    ></app-odc-field>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>

</form>

<ng-template #helpFr>
    <h2>Qu'est-ce qu'un compte d'options?</h2>
    <p>Compte marge qui vous permet de vendre ou d'acheter des options. Une option est un contrat qui donne le droit ou
        l'obligation d'acheter ou de vendre une quantité donnée d'un élément sous-jacent à un prix déterminé dans un
        délai prescrit. Vous devez choisir entre quatre types d'opération sur options, soit :</p>
    <ol>
        <li>Acquisition d'options de vente et d'options d'achat</li>
        <li>Vente d'options couvertes</li>
        <li>Opérations mixtes</li>
        <li>Vente d'options à découvert</li>
    </ol>
    <p>Veuillez vous référer à la <a href="https://secure.disnat.com/xd/fr/help/pdf/conventions_de_comptes.pdf"
                                     rel="external" target="_blank">Convention de compte d'options (PDF, 124 ko)<span
        class="sr-only"> (Cet hyperlien s'ouvrira dans une nouvelle fenêtre)</span></a> pour connaître les
        spécificités du compte.</p>

    <h2>Mise en garde :</h2>
    <p>Les opérations sur options comportent un degré de risque élevé. Les acheteurs et les vendeurs d'options devraient
        se familiariser avec le type d'option (de vente ou d'achat) qu'ils envisagent de négocier et les risques qui y
        sont associés. Veuillez vous référer à la section Opérations sur les options et les contrats à terme du <a
            href="https://www.disnat.com/service-clientele/formulaires/Disnat_reglementaire_DX99_web" rel="external"
            target="_blank">Document d'information sur la relation avec les clients (PDF, 676 ko)<span
            class="sr-only"> (Cet hyperlien s'ouvrira dans une nouvelle fenêtre)</span></a>.</p>

</ng-template>
<ng-template #helpEn>
    <h2>What is an options account?</h2>
    <p>This is a margin account that allows you to buy or sell options. An option is a contract that provides the right
        or obligation to buy or sell a specified amount of an underlying security at a specified price within a
        specified time. You must choose between four types of option transactions:</p>
    <ol>
        <li>Purchasing puts and calls</li>
        <li>Sale of covered options</li>
        <li>Spread trading</li>
        <li>Sale of uncovered options</li>
    </ol>
    <p>Please refer to the "<a href="https://secure.disnat.com/xd/en/help/pdf/account_agreements.pdf" rel="external"
                               target="_blank">Option Account Agreement (PDF, 119 KB)<span class="sr-only"> (This link will open in a new window)</span></a>"
        for the specifics of this type of account.</p>

    <h2>Warning:</h2>
    <p>Option transactions carry a high degree of risk. Purchasers and sellers of options should familiarize themselves
        with the type of option (i.e. put or call) which they contemplate trading and the risks associated with it.
        Please refer to the Options and Futures Trading section of the <a
            href="https://www.disnat.com/en/client-service/forms/Disnat_reglementaire_DX99_web" rel="external"
            target="_blank">Relationship Disclosure Document (PDF, 459 KB)<span class="sr-only"> (This link will open in a new window)</span></a>.
    </p>
</ng-template>

<ng-template #helpType>
    <h2>{{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE'|translate}}</h2>
    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE_HELP'|translate}}
</ng-template>
<ng-template #helpLevel1>
    <h2>{{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1'|translate}}</h2>
    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1_HELP'|translate}}
</ng-template>
<ng-template #helpLevel2>
    <h2>{{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2'|translate}}</h2>
    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2_HELP'|translate}}
</ng-template>
<ng-template #helpLevel3>
    <h2>{{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3'|translate}}</h2>
    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3_HELP'|translate}}
</ng-template>
<ng-template #helpLevel4>
    <h2>{{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4'|translate}}</h2>
    {{'ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4_HELP'|translate}}
</ng-template>
