<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <app-error-list [form]="form" [ngFormRef]="ngForm" [context]="'app-transmission-attachments-lba'"></app-error-list>

    <p [innerHTML]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_INSTRUCTIONS' | translate"></p>

    <h3>{{ 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL' | translate: {index: translate.currentLang === constants.LANG_EN ? '1st' : 1} }}</h3>
    <!-- Type doc 1 -->
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form.get('firstIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE_INDEX' | translate"
        [id]="'firstIdentityCode'"
        [name]="'firstIdentityCode'"
        [path]="'firstIdentityCode'"
        [list]="userIdentityCodeList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.code')"
        [filteredList]="userIdentityCodeFirstListAllowed"
    ></app-odc-field>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form.get('firstIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'USER_IDENTITY_NUMBER' | translate"
        [id]="'firstIdentityIDNumber'"
        [name]="'firstIdentityIDNumber'"
        [path]="'firstIdentityIDNumber'"
        [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.idNumber')"
        [attr]="{maxlength: 32}"
    ></app-odc-field>

    <app-odc-field *ngIf="!showDateIssuanceExpiryFirstIdentityFileField"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_DATE' | translate"
                   [id]="'firstIdentityExpirationDate'"
                   [name]="'firstIdentityExpirationDate'"
                   [path]="'firstIdentityExpirationDate'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.expirationDate')"
                   [dateFormat]="true"
    ></app-odc-field>

    <app-odc-field *ngIf="showDateIssuanceExpiryFirstIdentityFileField"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_ISSUE_EXPIRY_DATE' | translate"
                   [id]="'firstIdentityDate'"
                   [name]="'firstIdentityDate'"
                   [path]="'firstIdentityDate'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.expirationDate')"
                   [dateFormat]="true"
    ></app-odc-field>

    <app-odc-field *ngIf="showProvinceFirstIdentityFile"
                   [type]="constants.FIELD_TYPES.SELECT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_PROVINCE' | translate"
                   [id]="'firstIdentityProvince'"
                   [name]="'firstIdentityProvince'"
                   [controlName]="'firstIdentityProvince'"
                   [path]="'firstIdentityProvince'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.province')"
                   [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                   [list]="provincesList"
    ></app-odc-field>

    <app-odc-field *ngIf="showCountryFirstIdentityFile"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('firstIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_COUNTRY' | translate"
                   [id]="'firstIdentityCountry'"
                   [name]="'firstIdentityCountry'"
                   [path]="'firstIdentityCountry'"
                   [binding]="getRequesterPath('userIdentities.'+ FIRST_ATTACHED_DOCUMENT_INDEX +'.country')"
                   [attr]="{maxlength: 32}"
                   [countryCompleterCountries]="countries"
    ></app-odc-field>

    <app-input-file-field
        [form]="form"
        [index]="FIRST_ATTACHED_DOCUMENT_INDEX"
        [path]="'attachedDocuments.' + FIRST_ATTACHED_DOCUMENT_INDEX"
        [submitted]="isFormSubmitted()"
        [isRequestSaving]="this.formService.isSaving"
        [hasBack]="isDriverLicenseFirstIdentityFileSelected"
    ></app-input-file-field>

    <!-- Type doc 2 -->
    <br>
    <h3>{{ 'TRANSMISSION_ATTACHED_DOCUMENT_LABEL' | translate: {index: translate.currentLang === constants.LANG_EN ? '2nd' : 2} }}</h3>
    <app-odc-field
        [type]="constants.FIELD_TYPES.SELECT"
        [form]="form.get('secondIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE_INDEX' | translate"
        [id]="'secondIdentityCode'"
        [name]="'secondIdentityCode'"
        [path]="'secondIdentityCode'"
        [list]="userIdentityCodeSecondList"
        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.code')"
        [attrDisabled]="!isFirstDocumentInfosValid"
        [filteredList]="userIdentityCodeSecondListAllowed"
    ></app-odc-field>

    <app-odc-field
        [type]="constants.FIELD_TYPES.TEXT"
        [form]="form.get('secondIdentityInfos')"
        [submitted]="isFormSubmitted()"
        [label]="'USER_IDENTITY_NUMBER' | translate"
        [id]="'secondIdentityIDNumber'"
        [name]="'secondIdentityIDNumber'"
        [path]="'secondIdentityIDNumber'"
        [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.idNumber')"
        [attr]="{maxlength: 32}"
        [attrDisabled]="!isFirstDocumentInfosValid"
    ></app-odc-field>

    <app-odc-field *ngIf="!showDateIssuanceExpirySecondIdentityFileField"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('secondIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_DATE' | translate"
                   [id]="'secondIdentityExpirationDate'"
                   [name]="'secondIdentityExpirationDate'"
                   [path]="'secondIdentityExpirationDate'"
                   [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.expirationDate')"
                   [dateFormat]="true"
                   [attrDisabled]="!isFirstDocumentInfosValid"
    ></app-odc-field>

    <app-odc-field *ngIf="showDateIssuanceExpirySecondIdentityFileField"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('secondIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_ISSUE_EXPIRY_DATE' | translate"
                   [id]="'secondIdentityDate'"
                   [name]="'secondIdentityDate'"
                   [path]="'secondIdentityDate'"
                   [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.expirationDate')"
                   [dateFormat]="true"
                   [attrDisabled]="!isFirstDocumentInfosValid"
    ></app-odc-field>

    <app-odc-field *ngIf="showProvinceSecondIdentityFile"
                   [type]="constants.FIELD_TYPES.SELECT"
                   [form]="form.get('secondIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_PROVINCE' | translate"
                   [id]="'secondIdentityProvince'"
                   [name]="'secondIdentityProvince'"
                   [controlName]="'secondIdentityProvince'"
                   [path]="'secondIdentityProvince'"
                   [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.province')"
                   [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                   [list]="secondProvincesList"
                   [attrDisabled]="!isFirstDocumentInfosValid"
    ></app-odc-field>

    <app-odc-field *ngIf="showCountrySecondIdentityFile"
                   [type]="constants.FIELD_TYPES.TEXT"
                   [form]="form.get('secondIdentityInfos')"
                   [submitted]="isFormSubmitted()"
                   [label]="'USER_IDENTITY_COUNTRY' | translate"
                   [id]="'secondIdentityCountry'"
                   [name]="'secondIdentityCountry'"
                   [path]="'secondIdentityCountry'"
                   [binding]="getRequesterPath('userIdentities.'+ SECOND_ATTACHED_DOCUMENT_INDEX +'.country')"
                   [attr]="{maxlength: 32}"
                   [attrDisabled]="!isFirstDocumentInfosValid"
                   [countryCompleterCountries]="countries"
    ></app-odc-field>

    <app-input-file-field
        [form]="form"
        [index]="SECOND_ATTACHED_DOCUMENT_INDEX"
        [path]="'attachedDocuments.' + SECOND_ATTACHED_DOCUMENT_INDEX"
        [submitted]="isFormSubmitted()"
        [isRequestSaving]="this.formService.isSaving"
        [hasBack]="isDriverLicenseSecondIdentityFileSelected"
        [attrDisabled]="!isFirstDocumentInfosValid"
    ></app-input-file-field>

    <!-- check sample -->
    <br>
    <h3>{{ 'TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL' | translate }}</h3>
    <app-input-file-field
        [form]="form"
        [index]="ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX"
        [showLabel]="false"
        [path]="'attachedDocuments.' + ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX"
        [submitted]="isFormSubmitted()"
        [isRequestSaving]="this.formService.isSaving"
    ></app-input-file-field>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="loading"></app-questionnaire-btn>

</form>
