import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormService} from '../../../components/services/form.service';
import {FormGroup} from '@angular/forms';
import {VmdConstants} from '../../../components/constants/vmd-constants';

@Component({
    selector: 'app-questionnaire-btn',
    templateUrl: './questionnaire-btn.component.html'
})
export class QuestionnaireBtnComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() formRef: ElementRef;
    @Input() loading = false;
    @Input() hideBackBtn = false;
    isSaving = false;

    constructor(private navigationService: NavigationService,
                protected formService: FormService) {
    }

    ngOnInit(): void {
        this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        });
    }

    private clickSubmitButton(): void {
        this.formRef.nativeElement.querySelector('[type="submit"]').click();
    }

    private isPleinEx(): boolean {
        return this.formService.isPleinEx();
    }

    private isUpdateAccount(): boolean {
        return this.formService.isUpdateAccount();
    }

    navBack() {
        if (
            this.isPleinEx()
            && this.formService.isPrintMode()
            && (this.formService.getCurrentComposant() && this.formService.getCurrentComposant().processRules())
        ) {
            this.clickSubmitButton();
        } else {
            this.navigationService.emitBack();
        }
    }

    showBack() {

        if (this.hideBackBtn) {
            return false;
        }

        if (this.isUpdateAccount() && this.navigationService.currentTab === 0 && this.navigationService.currentSubTab === 0) {
            return false;
        }

        // If a request was created from SmartD and we are at client type selection page
        if (this.formService.getAgentID() && this.navigationService.currentTab === 0) {

            return false;
        }

        let showBack = (this.navigationService.currentTab !== 1 || this.navigationService.currentSubTab !== 0);

        if (!showBack && this.formService.getForm().cltType !== VmdConstants.CLT_TYPE_INDIVIDU) {
            showBack = true;
        }

        if (this.isPleinEx() || this.isUpdateAccount()) {
            showBack = true;
        }

        return showBack;
    }

}
