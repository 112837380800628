import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {WebException} from '../../components/models';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {NavigationService} from '../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {PostLoginActionService} from '../../components/services/post-login-action.service';

@Component({
    selector: 'app-consents',
    templateUrl: './consents.component.html',
    styles: ['hr {height:1px; border:none; color:#333; background-color:#333;}']
})
export class ConsentsComponent extends BaseNavComponent implements OnInit {

    loading = false;
    isSaving = false;

    @ViewChild('helpConsentCaisseFr')
    private titleHelpFrTemplateRef: TemplateRef<any>;

    @ViewChild('helpConsentCaisseEn')
    private titleHelpEnTemplateRef: TemplateRef<any>;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                public translate: TranslateService,
                private postLoginActionService: PostLoginActionService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.formService.isSavingEmitted.subscribe((isSaving) => {
            this.isSaving = isSaving;
        }));
    }

    submitForm() {

        if (!this.formService.saveInProgress()) {

            this.loading = true;
            this.formService.emitIsSaving(true);
            this.error = null;

            this.formService.saveCompleteForm().subscribe((data: any) => {

                    if (data.status === 'OK' && data.payload && !data.payload.errorMsg) {
                        this.formService.setSavedEmail(data.payload.courriel);
                        this.formService.setSavedPassword(data.payload.motDePasse);
                        this.formService.emitSaved();
                    }

                    this.formService.setCompleted(true);

                    this.navNext();
                },
                (error: WebException) => {
                    this.postLoginActionService.registerAction(() => this.submitForm());
                    this.onCompleteFormError(error);

                }
            ).add(() => {
                this.loading = false;
                this.formService.emitIsSaving(false);
            });
        }
    }

    getTitleHelpTemplateFr(): TemplateRef<any> {
        return this.formService.isCaisseContext()
            ? this.titleHelpFrTemplateRef
            : null;
    }

    getTitleHelpTemplateEn(): TemplateRef<any> {
        return this.formService.isCaisseContext()
            ? this.titleHelpEnTemplateRef
            : null;
    }

}
