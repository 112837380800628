import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {FormService} from '../services/form.service';

declare let $: any;

@Directive({
    selector: '[appFormBinding]'
})
export class ODCFormBindingDirective {

    @Input() appFormBinding: string;

    constructor(private el: ElementRef, private control: NgControl, private formService: FormService) {
    }

    private isRadioOrCheckbox(): boolean {
        return 'radio' === $(this.el.nativeElement).attr('type') || 'checkbox' === $(this.el.nativeElement).attr('type');
    }

    private updateValue() {
        if (this.appFormBinding !== null && this.appFormBinding !== '') {
            this.formService.setFormProperty(this.appFormBinding, this.control.value);
        }
    }

    @HostListener('change', ['$event'])
    onChange() {
        if (this.isRadioOrCheckbox()) {
            this.updateValue();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur() {

        this.updateValue();

    }
}
