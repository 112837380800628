import {Component, Input, OnInit} from '@angular/core';
import {AccountTransferRequest} from '../../../components/models/accountTransferRequest';
import {AccountTransferSecurity} from '../../../components/models/accountTransferSecurity';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {TranslateService} from '@ngx-translate/core';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {ListModelService} from '../../../components/services/list-model.service';
import {VmdFormat} from '../../../components/formats/vmd-format';

@Component({
    selector: 'app-review-transfer',
    templateUrl: './review-transfer.component.html'
})
export class ReviewTransferComponent implements OnInit {

    @Input() transfer: AccountTransferRequest;
    @Input() baseAccountType: string = null;

    private provinces: HtmlSelectKV[] = [];

    constructor(private translate: TranslateService,
                private listModelService: ListModelService) {
    }

    ngOnInit() {
        this.listModelService.getListModel(this.transfer.addressCountry === VmdConstants.COUNTRIES.UNITED_STATES ?
            'statesList' : 'provincesList', data => this.provinces = data);
    }

    public getTransferAccountType(): string {

        if (this.baseAccountType === VmdConstants.ACCOUNT_TYPE_MARGIN) {

            return this.translation('TRANSFER_FORM_TITLE_ACCOUNT_TYPE_MRGN');
        }

        return this.translation('TRANSFER_FORM_TITLE_ACCOUNT_TYPE_' + this.transfer.actType);
    }

    public getSecurityType(type: string): string {

        return this.translation('TRANSFER_FORM_SECURITY_TYPE_' + type);
    }

    public getInstitutionAccountType(accountType: string): string {

        return this.translation('ACCOUNT_FORM_ACCOUNT_OPTION_' + accountType) + ' ' + this.getAccountCurrency(this.transfer.accountCurrency);
    }

    public getAddress(): string {

        let addressUnit = '';
        if (this.transfer.addressUnit) {

            addressUnit = this.transfer.addressUnit + ' ';
        }

        return addressUnit + this.transfer.addressStreet + ', ' + this.transfer.addressCity + ', '
            + this.getProvince(this.transfer.addressProv) + ', ' + VmdFormat.zipCode.formatAsZipCode(this.transfer.addressPostalCode);
    }

    public getPhone(): string {

        return this.transfer.bankPhone ? VmdFormat.phone.formatAsPhone('' + this.transfer.bankPhone) : '';
    }

    public getProvince(prov: string) {

        return ListModelService.getLabelFromList(this.provinces, prov);
    }

    public getTypeTransfer(): string {

        return this.translation('TRANSFER_FORM_REQUEST_TYPE_' + this.transfer.transferType);
    }

    public getInstructions(): string {

        return this.translation('TRANSFER_FORM_REQUEST_INSTRUCTION_' + this.transfer.transferInstructionType);
    }

    public getAccountCurrency(currency: string): string {

        return this.translation('CURRENCY_' + currency);
    }

    public getTitle(): string {

        return this.translation('REVIEW_TRANSFER_REQUEST') + ' - '
            + this.getTransferAccountType() + ' ' + this.getAccountCurrency(this.transfer.actTypeCurrency);
    }

    public getRessourceName(): string {

        return (this.transfer.bankResourceFirstName ? this.transfer.bankResourceFirstName + ' ' : '') + (this.transfer.bankResourceLastName ? this.transfer.bankResourceLastName : '');
    }

    public getCashAmount(): string {

        return VmdFormat.numeric.formatAsCurrency(this.transfer.transferCash);
    }

    public hasCashAmount(): boolean {

        return this.transfer.transferType === VmdConstants.TRANSFER_TYPE_PARTIAL
            && (this.transfer.transferInstructionType === VmdConstants.TRANSFER_INSTRUCTION_CASH
                || this.transfer.transferInstructionType === VmdConstants.TRANSFER_INSTRUCTION_MIXED);
    }

    public getSecurities(): AccountTransferSecurity[] {

        const securities: AccountTransferSecurity[] = [];

        if (this.transfer.securities) {

            for (const security of this.transfer.securities) {

                if (security.description && security.quantity && security.type) {

                    securities.push(security);
                }
            }
        }

        return securities;
    }

    public getSecurityValue(security: AccountTransferSecurity): string {

        let symbol = '';
        if (security.symbol) {

            symbol = security.symbol + ' ';
        }

        return security.description + ' - ' + symbol
            + '' + security.quantity + ' (' + this.getSecurityType(security.type) + ')';
    }

    private translation(value: string): string {

        return this.translate.instant(value);
    }

    public getAttachedDocumentName(): string {

        return this.transfer.accountStatement.name;
    }

    public hasAttachedDocumentName(): boolean {

        return !!this.transfer.accountStatement.name;
    }
}
