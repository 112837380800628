import {Component} from '@angular/core';
import {PersonalInformationComponent} from './personal-information.component';

@Component({
    selector: 'app-personal-informations-second-holder',
    templateUrl: './personal-information.component.html'
})
export class PersonalInformationSecondHolderComponent extends PersonalInformationComponent {

    public requesterIndex = 1;

}
