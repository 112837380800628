import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-value',
  templateUrl: './review-value.component.html'
})
export class ReviewValueComponent implements OnInit {

    @Input() value: string;

  ngOnInit() {

  }

}
