import {Component} from '@angular/core';
import {BrokerageAccountsComponent} from './brokerage-accounts.component';

@Component({
    selector: 'app-legal-third-holder',
    templateUrl: './brokerage-accounts.component.html'
})
export class BrokerageAccountsComponentThirdHolderComponent extends BrokerageAccountsComponent {

    public requesterIndex = 2;

}
