import {InjectionToken} from '@angular/core';
import {OdcConfigUtils} from './components/utils/odc-config-utils';

declare let ODC_ENCRYPTED_URL: any;
declare let ODC_FROM_EXISTING_REQUEST: any;
declare let ODC_AGENT_DISNAT_USERNAME: any;
declare let ODC_IS_PLEIN_EX: any;
declare let ODC_IS_GPD: any;
declare let ODC_IS_SFD: any;
declare let ODC_IS_SSD: any;
declare let ODC_IS_UPDATE_ACCOUNT: any;
declare let ODC_IS_MODE_UPDATE: any;
declare let ODC_IS_UPDATE_PILOT_MEMBER: any;
declare let ODC_USE_ADDRESS_COMPLETION: any;
declare let BRANCH_ACT: any;
declare let SOURCE: any;
declare let READ_ONLY: any;
declare let TO_CORRECT: any;
declare let IS_AUTOMATED_OPENING_MODE_ACTIVE: any;
declare let CONSULTATION: any;

declare let BRANCH_ENCRYPTED_URL: any;
declare let ACT: any;
declare let TYPE_MEMBRE: any;
declare let NO_PDO: any;
declare let NO_INST: any;
declare let NO_TRANSIT: any;
declare let CODE_USAGER: any;
declare let INST_ORIG: any;
declare let TRANSIT_ORIG: any;
declare let ADV_LST_NAME: any;
declare let ADV_FST_NAME: any;
declare let LANGUE: any;
declare let CAISSE_CONTEXT_DECRYPTION_ERROR: any;
declare let CLIENT_CODE: any;
declare let QUERY_LANGUAGE: any;
declare let QUERY_DECRYPTION_ERROR: any;

declare let ODC_AGENT_ID: any;
declare let ODC_CLIENT_ID: any;

declare let TOGGLE_PDF_LANG: any;
declare let TOGGLE_CELI_APP: any;
declare let TOGGLE_CELI_APP_USD: any;
declare let TOGGLE_GPD_REEE: any;
declare let TOGGLE_AKAMAI: any;
declare let TOGGLE_RECAPTCHA: any;

export const ODC_CONFIG = new InjectionToken<IOdcConfig>('odc-config');

export interface IOdcConfig {
    ENCRYPTED_URL: string;
    FROM_EXISTING_REQUEST: string;
    AGENT_DISNAT_USERNAME: string;
    IS_PLEIN_EX: boolean;
    IS_GPD: boolean;
    IS_SFD: boolean;
    IS_SSD: boolean;
    IS_UPDATE_ACCOUNT: boolean;
    IS_MODE_UPDATE: boolean;
    IS_UPDATE_PILOT_MEMBER: boolean;
    USE_ADDRESS_COMPLETION: boolean;
    SOURCE: string;
    BRANCH_ACT: string;
    READ_ONLY: boolean;
    TO_CORRECT: boolean;
    IS_AUTOMATED_OPENING_MODE_ACTIVE: boolean;
    CONSULTATION: string;

    // Branch Params
    BRANCH_ENCRYPTED_URL: string;
    ACT: string;
    TYPE_MEMBRE: string;
    NO_PDO: string;
    NO_INST: string;
    NO_TRANSIT: string;
    CODE_USAGER: string;
    INST_ORIG: string;
    TRANSIT_ORIG: string;
    ADV_LST_NAME: string;
    ADV_FST_NAME: string;
    LANGUE: string;
    CAISSE_CONTEXT_DECRYPTION_ERROR: string;

    // OSS QUERY PARAMS
    CLIENT_CODE: string;
    QUERY_LANGUAGE: string;
    QUERY_DECRYPTION_ERROR: string;
    AGENT_ID: string;
    CLIENT_ID: string;

    TOGGLE_PDF_LANG: boolean;
    TOGGLE_CELI_APP: boolean;
    TOGGLE_CELI_APP_USD: boolean;
    TOGGLE_GPD_REEE: boolean;
    TOGGLE_AKAMAI: boolean;
    TOGGLE_RECAPTCHA: boolean;
}

export const OdcConfig: IOdcConfig = {
    ENCRYPTED_URL: typeof ODC_ENCRYPTED_URL === 'undefined' ? null : ODC_ENCRYPTED_URL,
    FROM_EXISTING_REQUEST: typeof ODC_FROM_EXISTING_REQUEST === 'undefined' ? null : ODC_FROM_EXISTING_REQUEST,
    AGENT_DISNAT_USERNAME: typeof ODC_AGENT_DISNAT_USERNAME === 'undefined' ? null : ODC_AGENT_DISNAT_USERNAME,
    IS_PLEIN_EX: typeof ODC_IS_PLEIN_EX === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_PLEIN_EX),
    IS_GPD: typeof ODC_IS_GPD === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_GPD),
    IS_SFD: typeof ODC_IS_SFD === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_SFD),
    IS_SSD: typeof ODC_IS_SSD === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_SSD),
    IS_UPDATE_ACCOUNT: typeof ODC_IS_UPDATE_ACCOUNT === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_UPDATE_ACCOUNT),
    IS_MODE_UPDATE: typeof ODC_IS_MODE_UPDATE === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_MODE_UPDATE),
    IS_UPDATE_PILOT_MEMBER: typeof ODC_IS_UPDATE_PILOT_MEMBER === 'undefined' ? null : OdcConfigUtils.getBooleanValue(ODC_IS_UPDATE_PILOT_MEMBER),
    USE_ADDRESS_COMPLETION: typeof ODC_USE_ADDRESS_COMPLETION === 'undefined' ? null : ODC_USE_ADDRESS_COMPLETION,
    SOURCE: typeof SOURCE === 'undefined' ? null : SOURCE,
    READ_ONLY: typeof READ_ONLY === 'undefined' ? null : OdcConfigUtils.getBooleanValue(READ_ONLY),
    TO_CORRECT: typeof TO_CORRECT === 'undefined' ? null : OdcConfigUtils.getBooleanValue(TO_CORRECT),
    IS_AUTOMATED_OPENING_MODE_ACTIVE: typeof IS_AUTOMATED_OPENING_MODE_ACTIVE === 'undefined' ? null : OdcConfigUtils.getBooleanValue(IS_AUTOMATED_OPENING_MODE_ACTIVE),
    CONSULTATION: typeof CONSULTATION === 'undefined' ? null : CONSULTATION,
    BRANCH_ENCRYPTED_URL: typeof BRANCH_ENCRYPTED_URL === 'undefined' ? null : BRANCH_ENCRYPTED_URL,
    BRANCH_ACT: typeof BRANCH_ACT === 'undefined' ? null : BRANCH_ACT,
    ACT: typeof ACT === 'undefined' ? null : ACT,
    TYPE_MEMBRE: typeof TYPE_MEMBRE === 'undefined' ? null : TYPE_MEMBRE,
    NO_PDO: typeof NO_PDO === 'undefined' ? null : NO_PDO,
    NO_INST: typeof NO_INST === 'undefined' ? null : NO_INST,
    NO_TRANSIT: typeof NO_TRANSIT === 'undefined' ? null : NO_TRANSIT,
    CODE_USAGER: typeof CODE_USAGER === 'undefined' ? null : CODE_USAGER,
    INST_ORIG: typeof INST_ORIG === 'undefined' ? null : INST_ORIG,
    TRANSIT_ORIG: typeof TRANSIT_ORIG === 'undefined' ? null : TRANSIT_ORIG,
    ADV_LST_NAME: typeof ADV_LST_NAME === 'undefined' ? null : ADV_LST_NAME,
    ADV_FST_NAME: typeof ADV_FST_NAME === 'undefined' ? null : ADV_FST_NAME,
    LANGUE: typeof LANGUE === 'undefined' ? null : LANGUE,
    CAISSE_CONTEXT_DECRYPTION_ERROR: typeof CAISSE_CONTEXT_DECRYPTION_ERROR === 'undefined' ? null : CAISSE_CONTEXT_DECRYPTION_ERROR,
    CLIENT_CODE: typeof CLIENT_CODE === 'undefined' ? null : CLIENT_CODE,
    QUERY_LANGUAGE: typeof QUERY_LANGUAGE === 'undefined' ? null : QUERY_LANGUAGE,
    QUERY_DECRYPTION_ERROR: typeof QUERY_DECRYPTION_ERROR === 'undefined' ? null : QUERY_DECRYPTION_ERROR,
    AGENT_ID: typeof ODC_AGENT_ID === 'undefined' ? null : ODC_AGENT_ID,
    CLIENT_ID: typeof ODC_CLIENT_ID === 'undefined' ? null : ODC_CLIENT_ID,
    TOGGLE_PDF_LANG: typeof TOGGLE_PDF_LANG === 'undefined' ? null :
        OdcConfigUtils.getBooleanValue(TOGGLE_PDF_LANG),
    TOGGLE_CELI_APP: typeof TOGGLE_CELI_APP === 'undefined' ? null :
        OdcConfigUtils.getBooleanValue(TOGGLE_CELI_APP),
    TOGGLE_CELI_APP_USD: typeof TOGGLE_CELI_APP_USD === 'undefined' ? null :
        OdcConfigUtils.getBooleanValue(TOGGLE_CELI_APP_USD),
    TOGGLE_GPD_REEE: typeof TOGGLE_GPD_REEE === 'undefined' ? null :
        OdcConfigUtils.getBooleanValue(TOGGLE_GPD_REEE),
    TOGGLE_AKAMAI: typeof TOGGLE_AKAMAI === 'undefined' ? null : OdcConfigUtils.getBooleanValue(TOGGLE_AKAMAI),
    TOGGLE_RECAPTCHA: typeof TOGGLE_RECAPTCHA === 'undefined' ? null : OdcConfigUtils.getBooleanValue(TOGGLE_RECAPTCHA),
};
