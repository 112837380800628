import {Component} from '@angular/core';
import {FormService} from '../../components/services/form.service';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {Branch} from '../../components/models/Branch';

@Component({
    selector: 'app-begin',
    templateUrl: './begin.component.html'
})
export class BeginComponent {

    constructor(
        public formService: FormService
    ) {
    }

    isBranch(): boolean {
        return VmdConstants.BRANCH_ACT_NEW === Branch.getInstance().act;
    }
}
