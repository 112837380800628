<div class="btn-resp btn-resp-1colonne-inline m-t-4">
    <div class="btn-resp-col btn-resp-demi">
        <button type="submit"
                class="btn btn-primary loadable"
                [class.loading]="loading"
                [attr.disabled]="loading || isSaving ? true : null"
                [attr.id]="'nextButton'">
            {{'BTN_NEXT' | translate}}
        </button>
    </div>
    <div *ngIf="showBack()" class="btn-resp-col btn-resp-demi">
        <button type="button" class="btn btn-default" [attr.disabled]="isSaving ? true : null" (click)="navBack()">{{'BTN_BACK' | translate}}</button>
    </div>
</div>
