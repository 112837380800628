export class VmdFormatPhone {

    undoFormatAsPhone(valueToUnformat: string): string {
        if (!!valueToUnformat) {
            return valueToUnformat.replace(/[ -]/g, '').substring(0, 10);
        }
        return valueToUnformat;
    }

    formatAsPhone(valueToFormat: string): string {
        if (!valueToFormat) {
            return valueToFormat;
        }
        const telNoFormatCarac = this.undoFormatAsPhone(valueToFormat);
        let telFormatted = '';

        // Length 1 and more
        if (telNoFormatCarac.length > 0) {
            telFormatted += telNoFormatCarac.slice(0, 3);
        }
        // Length 3 and more
        if (telNoFormatCarac.length > 3) {
            telFormatted += ' ' + telNoFormatCarac.slice(3, 6);
        }
        // Length 6 and more
        if (telNoFormatCarac.length > 6) {
            telFormatted += '-' + telNoFormatCarac.slice(6, 10);
        }

        return telFormatted.trim();
    }
}
