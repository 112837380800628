import {Component} from '@angular/core';
import {AccountHolderInformationComponent} from './account-holder-information.component';

@Component({
    templateUrl: './account-holder-information.component.html'
})
export class AccountHolderInformationAttorneyComponent extends AccountHolderInformationComponent {

    public displayCompanyInformation(): boolean {
        return false;
    }

    public displayAddress(): boolean {
        return true;
    }

    public displayOtherName(): boolean {
        return true;
    }

    public getHomeAddressLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_ADDRESS_TITLE';
    }

    public getOtherFirstNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_FIRST_NAME';
    }

    public getOtherLastNameLabel(): string {
        return 'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_LAST_NAME';
    }
}
