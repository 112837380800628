import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FormService} from '../../components/services/form.service';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ListModelService} from '../../components/services/list-model.service';
import {ModalService} from '../../components/services/modal.service';

@Component({
    selector: 'app-residential-address',
    templateUrl: './residential-address.component.html'
})
export class ResidentialAddressComponent extends BaseNavComponent implements OnInit {

    showSecondAutreNif = false;
    countries: HtmlSelectKV[];
    nifReasons: HtmlSelectKV[];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected cdr: ChangeDetectorRef,
                private listModelService: ListModelService,
                private modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            citizenshipGroup: this.fb.group({
                userCitizenship: [this.formService.getForm().requesters[this.requesterIndex].userCitizenship],
                userCitizenshipUS: [this.formService.getForm().requesters[this.requesterIndex].userCitizenshipUS],
                addUserOtherCitizenship: [this.formService.getForm().requesters[this.requesterIndex].addUserOtherCitizenship],
                userOtherCitizenship: [this.formService.getForm().requesters[this.requesterIndex].userOtherCitizenship, [this.requiredValidator('userOtherCitizenship')]]
            }, {validator: this.requiredGroupValidator(VmdValidators.citizenshipValidator)}),
            fiscalUsCitizenshipIndicator: [this.formService.getForm().requesters[this.requesterIndex].fiscalUsCitizenshipIndicator, [this.requiredValidator('fiscalUsCitizenshipIndicator')]],

            fiscalGroup: this.fb.group({
                userFiscalRelationCanada: [this.formService.getForm().requesters[this.requesterIndex].userFiscalRelationCanada],

                userFiscalRelationUsa: [this.formService.getForm().requesters[this.requesterIndex].userFiscalRelationUsa],
                usaNifGroup: this.fb.group({
                    usaNif: [this.formService.getForm().requesters[this.requesterIndex].usaNif, [VmdValidators.lengthValidator(6, 32)]],
                    userNifMissingReason: [this.formService.getForm().requesters[this.requesterIndex].userNifMissingReason]
                }, {validator: this.requiredGroupValidator(VmdValidators.usaNifRequired)}),

                userFiscalRelationOther: [this.formService.getForm().requesters[this.requesterIndex].userFiscalRelationOther],
                autreNifGroup1: this.fb.group({
                    userCountryNif2: [this.formService.getForm().requesters[this.requesterIndex].userCountryNif2, [this.requiredValidator('userCountryNif2')]],
                    codePremierAutreNif: [this.formService.getForm().requesters[this.requesterIndex].codePremierAutreNif, [VmdValidators.lengthValidator(6, 32)]],
                    userNifMissingReason2: [this.formService.getForm().requesters[this.requesterIndex].userNifMissingReason2]
                }, {validator: this.requiredGroupValidator(VmdValidators.otherNif2Required)}),

                autreNifGroup2: this.fb.group({
                    userCountryNif3: [this.formService.getForm().requesters[this.requesterIndex].userCountryNif3, [this.requiredValidator('userCountryNif3')]],
                    codeSecondAutreNif: [this.formService.getForm().requesters[this.requesterIndex].codeSecondAutreNif, [VmdValidators.lengthValidator(6, 32)]],
                    userNifMissingReason3: [this.formService.getForm().requesters[this.requesterIndex].userNifMissingReason3]
                }, {validator: this.requiredGroupValidator(VmdValidators.otherNif3Required)})
            }, {validator: this.requiredGroupValidator(VmdValidators.fiscalValidator)})

        });

        this.showSecondAutreNif = this.form.get('fiscalGroup.autreNifGroup2.userCountryNif3').value
            || this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif').value
            || this.form.get('fiscalGroup.autreNifGroup2.userNifMissingReason3').value;

        this.cdr.detectChanges();

        this.listModelService.getListModel('paysList', data => this.countries = data);
        this.listModelService.getListModel('statusNoNifList', data => this.nifReasons = data);

        // validation when loading the form
        this.updateControlEnabled(this.form.get('citizenshipGroup.userOtherCitizenship'), this.form.get('citizenshipGroup.addUserOtherCitizenship').value === true);

        this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup'), this.form.get('fiscalGroup.userFiscalRelationUsa').value === true);

        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1'), this.form.get('fiscalGroup.userFiscalRelationOther').value === true);

        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2'), this.showSecondAutreNif);

        this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup.usaNif'), this.form.get('fiscalGroup.usaNifGroup.userNifMissingReason').value == null &&
            this.form.get('fiscalGroup.userFiscalRelationUsa').value === true);

        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1.codePremierAutreNif'), !this.form.get('fiscalGroup.autreNifGroup1.userNifMissingReason2').value &&
            this.form.get('fiscalGroup.userFiscalRelationOther').value === true);

        if (this.showSecondAutreNif) {
            this.toggleSecondAutreNif(true);
        }

        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif'), !this.form.get('fiscalGroup.autreNifGroup2.userNifMissingReason3').value &&
            this.form.get('fiscalGroup.userFiscalRelationOther').value === true);

        // validation when changes on the form
        this.form.get('citizenshipGroup.addUserOtherCitizenship').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('citizenshipGroup.userOtherCitizenship'), value);
        });

        this.form.get('fiscalGroup.userFiscalRelationUsa').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup'), value);

            if (!value) {
                this.form.get('fiscalGroup.usaNifGroup.usaNif').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('usaNif'), null);
                this.form.get('fiscalGroup.usaNifGroup.userNifMissingReason').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('userNifMissingReason'), null);
            } else {
                this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup.userNifMissingReason'), true);
            }
        });
        this.form.get('fiscalGroup.usaNifGroup.userNifMissingReason').valueChanges.subscribe(value => {
            if (value != null) {
                this.form.get('fiscalGroup.usaNifGroup.usaNif').setValue(null);
                this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup.usaNif'), false);
                this.formService.setFormProperty(this.getRequesterPath('usaNif'), null);
            } else {
                this.updateControlEnabled(this.form.get('fiscalGroup.usaNifGroup.usaNif'), true);
            }
        });


        this.form.get('fiscalGroup.userFiscalRelationOther').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1'), value);
            this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1.userCountryNif2'), value);
            this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1.userNifMissingReason2'), value);

            if (!value) {
                this.form.get('fiscalGroup.autreNifGroup1.userCountryNif2').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('userCountryNif2'), null);
                this.form.get('fiscalGroup.autreNifGroup1.codePremierAutreNif').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('codePremierAutreNif'), null);
                this.form.get('fiscalGroup.autreNifGroup1.userNifMissingReason2').setValue(null);
                this.formService.setFormProperty(this.getRequesterPath('userNifMissingReason2'), null);
                this.toggleSecondAutreNif(false);
            }
        });
        this.form.get('fiscalGroup.autreNifGroup1.userNifMissingReason2').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('fiscalGroup.autreNifGroup1.codePremierAutreNif').setValue(null);
                this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1.codePremierAutreNif'), false);
                this.formService.setFormProperty(this.getRequesterPath('codePremierAutreNif'), null);
            } else {
                this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup1.codePremierAutreNif'), true);
            }
        });
        this.form.get('fiscalGroup.autreNifGroup2.userNifMissingReason3').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif').setValue(null);
                this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif'), false);
                this.formService.setFormProperty(this.getRequesterPath('codeSecondAutreNif'), null);
            } else {
                this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif'), true);
            }
        });

    }

    toggleSecondAutreNif(toggle: boolean) {
        this.showSecondAutreNif = toggle;
        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2'), this.showSecondAutreNif);
        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2.userCountryNif3'), this.showSecondAutreNif);
        this.updateControlEnabled(this.form.get('fiscalGroup.autreNifGroup2.userNifMissingReason3'), this.showSecondAutreNif);
        if (!this.showSecondAutreNif) {
            this.form.get('fiscalGroup.autreNifGroup2.userCountryNif3').setValue(null);
            this.formService.setFormProperty(this.getRequesterPath('userCountryNif3'), null);
            this.form.get('fiscalGroup.autreNifGroup2.codeSecondAutreNif').setValue(null);
            this.formService.setFormProperty(this.getRequesterPath('codeSecondAutreNif'), null);
            this.form.get('fiscalGroup.autreNifGroup2.userNifMissingReason3').setValue(null);
            this.formService.setFormProperty(this.getRequesterPath('userNifMissingReason3'), null);
        }
    }

    isResidentCountryMatchTaxPurpose(): boolean {
        const country = this.formService.getForm().requesters[this.requesterIndex].userAddressCountry;
        const userFiscalRelationCanada = this.form.get('fiscalGroup.userFiscalRelationCanada').value;
        const userFiscalRelationUsa = this.form.get('fiscalGroup.userFiscalRelationUsa').value;

        if (this.constants.COUNTRIES.UNITED_STATES === country && (!userFiscalRelationUsa || userFiscalRelationCanada)) {
            return false;
        } else if (this.constants.COUNTRIES.CANADA === country && !userFiscalRelationCanada) {
            return false;
        }

        return true;
    }

    submit() {
        if (this.isResidentCountryMatchTaxPurpose() && !this.isGpdOrSfd() && !this.isVmdPortfolioManagementMandateOnly() && !this.isSsdPortfolioManagementAdvisorProgram()) {
            this.submitForm();
        } else if (!this.isResidentCountryMatchTaxPurpose() && !this.isGpdOrSfd() && !this.isVmdPortfolioManagementMandateOnly() && !this.isSsdPortfolioManagementAdvisorProgram()) {
            this.modalService.openModal('ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_TITTLE', 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_MESSAGE');
        } else {
            this.submitForm();
        }
    }

}
