import {Component, OnInit} from '@angular/core';
import {TransferRequestGenericTypeComponent} from './transfer-request-generic-type.component';

@Component({
    selector: 'app-transfer-request-rrsp',
    templateUrl: './transfer-request-generic-type.component.html'
})
export class TransferRequestRrspComponent extends TransferRequestGenericTypeComponent implements OnInit {

    accountTransferType: string = this.constants.ACCOUNT_TRANSFER_RRSP;
    bindPath = 'rrspAccountTransferList';

    ngOnInit() {
        super.ngOnInit();
    }

}
