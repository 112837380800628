<app-header></app-header>

<main class="container m-t-3" id="content" tabindex="0">

    <router-outlet></router-outlet>

</main>

<!--<app-footer></app-footer>-->
<div aria-describedby="modal-alert-desc" aria-labelledby="modal-alert-label" class="modal" id="modal-alert"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-desc"></div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">{{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-alert-desc" aria-labelledby="modal-alert-label" class="modal" id="modal-alert-error"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-error-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-error-desc"></div>
            <b><p class="modal-specific-text"></p></b>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnChoice1()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_OK'|translate}}"
                        type="submit">
                    {{ 'BTN_OK'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>


<app-modal-auth></app-modal-auth>
<app-modal-forgot-password></app-modal-forgot-password>
<app-modal-create-profile></app-modal-create-profile>
<app-modal-personal-information-second-accountholder-spouse></app-modal-personal-information-second-accountholder-spouse>
<app-modal-pdf></app-modal-pdf>
<app-modal-add-entity></app-modal-add-entity>

<div aria-describedby="modal-alert-personal-information-desc" aria-labelledby="modal-alert-personal-information-label"
     class="modal" id="modal-alert-personal-information"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-personal-information-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-personal-information-desc"></div>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnChoice1()" class="btn btn-default" data-dismiss="modal"
                        title="{{'BTN_CANCEL'|translate}}"
                        type="submit">
                    {{ 'BTN_CANCEL'|translate }}
                </button>
                <button (click)="modalService.emitValidClickOnChoice2()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">
                    {{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>


<div aria-describedby="modal-esign-desc" aria-labelledby="modal-esign-label" class="modal" id="modal-esign"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-full-screen">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-esign-label" tabindex="0">
                    {{ 'ESIGN_MODAL_TITLE'|translate }}
                </h2>
                <button *ngIf="!esignLoading" class="close" data-dismiss="modal" type="submit">
                    <i class="icon-close"></i>
                </button>
            </div>
            <div [class.generation-pdf-01]="esignLoading" class="modal-body" id="modal-esign-desc">
                <div *ngIf="esignLoading" class="generation-pdf-01-text">
                    <h3>{{ 'ESIGN_MODAL_PAGE_TITLE'|translate }}</h3>
                    <div class="text-align-left">
                        <div class="pull-left m-r-3">
                            <img alt="" src="assets/img/loading.gif">
                        </div>
                        <div class="pull-left">{{ 'ESIGN_MODAL_WAITING' | translate }}</div>
                    </div>
                </div>

                <iframe *ngIf="!esignLoading" scrolling="yes"></iframe>
            </div>
            <div class="modal-footer">
                <span class="text-inline">
                    <span class="contactus p-l-3">
                        {{ 'SIDEBAR_ASSISTANCE_TITLE' | translate }}
                    </span>
                    <span class="m-l-1" itemscope itemtype="http://schema.org/BankOrCreditUnion">
                        <span itemprop="telephone">
                            <a class="lien-sans-soulignement"
                               href="tel:{{ 'SIDEBAR_ASSISTANCE_PHONE_LINK' | translate }}">
                                {{ 'SIDEBAR_ASSISTANCE_PHONE' | translate }}
                                <span class="sr-only">{{ 'SR_ONLY_PHONE' | translate }}</span>
                            </a>
                        </span>
                    </span>
                    <span class="m-l-1">
                        {{ 'SIDEBAR_ASSISTANCE_SCHEDULE' | translate }}
                    </span>
                </span>
                <button class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial" title="{{'ESIGN_MODAL_BTN_NEXT_STEP_TOOLTIP'|translate}}"
                        type="submit">
                    {{ 'ESIGN_MODAL_BTN_NEXT_STEP'|translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div aria-describedby="modal-esign-confirm-close-desc" aria-labelledby="modal-esign-confirm-close-label" class="modal"
     id="modal-esign-confirm-close"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-esign-confirm-close-label" tabindex="0">
                    {{ 'ESIGN_MODAL_TITLE'|translate }}
                </h2>
            </div>
            <div class="modal-body" id="modal-esign-confirm-close-desc">
                {{ 'ESIGN_MODAL_CONFIRM_CLOSE_BODY'|translate }}
            </div>
            <div class="modal-footer">
                <button (click)="modalService.emitEsignCloseConfirmPopup(false)" class="btn btn-default"
                        data-dismiss="modal" title="{{'GLOBAL_NO'|translate}}"
                        type="submit">
                    {{ 'GLOBAL_NO'|translate }}
                </button>
                <button (click)="modalService.emitEsignCloseConfirmPopup(true)" class="btn btn-primary"
                        data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'GLOBAL_YES'|translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div aria-describedby="modal-alert-platform-disnat-desc" aria-labelledby="modal-alert-platform-disnat-label"
     class="modal" id="modal-alert-platform-disnat"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-platform-disnat-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-platform-disnat-desc"></div>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnPlatformBack()" class="btn btn-default"
                        data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'BTN_BACK_PLATFORM_DISNAT'|translate }}
                </button>
                <button (click)="modalService.emitValidClickOnPlatformContinue()" class="btn btn-primary"
                        data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-session-timeout-desc" aria-labelledby="modal-session-timeout-label" class="modal"
     id="modal-session-timeout" role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-session-timeout-label" tabindex="0">
                    {{ 'EXPIRATION_SESSION_TITLE' | translate }}
                </h2>
            </div>
            <div class="modal-body" id="modal-session-timeout-desc">
                <p [innerHTML]="getBodyExpirationSession()"></p>
                <p>
                    <strong>
                        <span class="timeout-counter" id="session-timeout-remaining">{{ countdown }}</span>
                    </strong>
                    {{ 'EXPIRATION_SESSION_BODY_TIMEOUT_2' | translate }}
                </p>
            </div>
            <div class="modal-footer">
                <button (click)="invalidateSession()" class="btn btn-default" data-dismiss="modal"
                        title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'BTN_ABANDON'|translate }}
                </button>
                <button (click)="extendSession()" class="btn btn-primary" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'BTN_EXTEND'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-video-desc" aria-labelledby="modal-video-label" class="modal" id="modal-video"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-video-label" tabindex="0">
                    <span *ngIf="popupVideoParam">{{ popupVideoParam.title|translate }}</span>
                </h2>
            </div>
            <div class="modal-body" id="modal-video-desc">
                <div id="video-player">
                    <iframe
                        allow="autoplay; encrypted-media"
                        allowfullscreen="1"
                        class="modal-video-iframe"
                        frameborder="0"
                        id="player"
                        title="YouTube video player"
                    ></iframe>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CLOSE'|translate}}"
                        type="submit">
                    {{ 'BTN_CLOSE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-alert-choice-attorney-desc" aria-labelledby="modal-alert-choice-attorney-label"
     class="modal" id="modal-alert-choice-attorney"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-choice-attorney-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-choice-attorney-desc"></div>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnChoice1()" class="btn btn-default" data-dismiss="modal"
                        title="{{'BTN_CANCEL'|translate}}"
                        type="submit">
                    {{ 'BTN_CANCEL'|translate }}
                </button>
                <button (click)="modalService.emitValidClickOnChoice2()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">
                    {{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-continue-back-desc" aria-labelledby="modal-continue-back-label" class="modal"
     id="modal-continue-back" role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-continue-back-label" tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-continue-back-desc"></div>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnChoice1()" class="btn btn-default" data-dismiss="modal"
                        title="{{'BTN_CANCEL'|translate}}"
                        type="submit">
                    {{ 'BTN_CANCEL'|translate }}
                </button>
                <button (click)="modalService.emitValidClickOnChoice2()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">
                    {{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>

<div aria-describedby="modal-alert-choice-transfer-request-desc"
     aria-labelledby="modal-alert-choice-transfer-request-label" class="modal" id="modal-alert-choice-transfer-request"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-choice-transfer-request-label"
                    tabindex="0"></h2>
            </div>
            <div class="modal-body" id="modal-alert-choice-transfer-request-desc"></div>
            <div class="modal-footer">
                <button (click)="modalService.emitValidClickOnChoice1()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_OK'|translate}}"
                        type="submit">
                    {{ 'BTN_OK'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>
    </div>
</div>


<div aria-describedby="modal-alert-most-recent-form-date-desc" aria-labelledby="modal-alert-most-recent-form-date-label"
     class="modal" id="modal-alert-most-recent-form-date"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-most-recent-form-date-label"
                    tabindex="0">
                    {{ 'MOST_RECENT_FORM_MODAL_TITLE' | translate }}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-most-recent-form-date-desc">
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_1_BEFORE' | translate }}</p>
                <b><p class="modal-specific-text"></p></b>
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_2_BEFORE' | translate }}</p>
                <p *ngIf="isIndividu()">{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_3_BEFORE' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button (click)="clickModalAlertMostRecentFormDate()" class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">{{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>

    </div>
</div>

<div aria-describedby="modal-alert-most-recent-form-date-after-desc"
     aria-labelledby="modal-alert-most-recent-form-date-after-label" class="modal"
     id="modal-alert-most-recent-form-date-after"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-most-recent-form-date-after-label"
                    tabindex="0">
                    {{ 'MOST_RECENT_FORM_MODAL_TITLE' | translate }}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-most-recent-form-date-after-desc">
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_1' | translate }}</p>
                <b><p class="modal-specific-text"></p></b>
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_3' | translate }}</p>
                <p>{{ 'MOST_RECENT_FORM_MODAL_PARAGRAPH_AFTER' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">{{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>

    </div>
</div>

<div aria-describedby="modal-alert-most-recent-form-date-after-desc"
     aria-labelledby="modal-alert-most-recent-form-date-after-label" class="modal"
     id="modal-alert-user-address-unstructured"
     role="dialog"
     tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start" id="modal-alert-user-address-unstructured-title"
                    tabindex="0">
                    {{ 'UNSTRUCTURED_ADDRESS_TITLE' | translate }}
                </h2>
            </div>
            <div class="modal-body" id="modal-alert-user-address-unstructured-body">
                <p>{{ 'UNSTRUCTURED_ADDRESS_NOTICE' | translate }}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CONTINUE'|translate}}"
                        type="submit">{{ 'BTN_CONTINUE'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>

    </div>
</div>

<div class="modal" id="modal-alert-generic" role="dialog" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" data-loop="start"
                    tabindex="0">
                </h2>
            </div>
            <div class="modal-body">
            </div>
            <div class="modal-footer">
                <button (click)='modalService.emitValidClickOnChoice1()' class="btn btn-primary" data-dismiss="modal"
                        data-focus="initial"
                        title="{{'BTN_OK'|translate}}" type="submit">{{ 'BTN_OK'|translate }}
                </button>

                <button class="btn btn-default" data-dismiss="modal" data-focus="initial"
                        title="{{'BTN_CANCEL'|translate}}"
                        type="submit">{{ 'BTN_CANCEL'|translate }}
                </button>
            </div>
            <div class="sr-only sr-only-focusable" data-loop="end" tabindex="0">{{ 'MODAL_END'|translate }}</div>
        </div>

    </div>
</div>
