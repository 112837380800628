import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

@Injectable()
export class TokenService {

    public TOKEN_NAME = 'JSESSIONID';
    private token: string = null;

    constructor(private http: HttpClient,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public getToken(): string {

        return this.token;
    }

    public setToken(token: string): void {

        this.token = token;
    }
}
