import {Inject, Injectable} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {VmdConstants} from '../constants/vmd-constants';

declare const grecaptcha: any;

@Injectable()
export class RecaptchaService {

    private siteKey: string | null = null;

    constructor(@Inject(ODC_CONFIG) public config: IOdcConfig) {
        this.setSiteKey();
    }

    renderRecaptcha(lang, containerId: string): string {
        return grecaptcha.enterprise.render(containerId, {
            sitekey: this.siteKey,
            action: 'authenticate',
            hl: lang
        });
    }

    resetRecaptcha(widgetId) {
        grecaptcha.enterprise.reset(widgetId);
    }

    getRecaptchaToken(widgetId: string): string | null {
        return grecaptcha.enterprise.getResponse(widgetId);
    }

    private setSiteKey(): void {
        const host = window.location.host;

        switch (host) {
            case VmdConstants.PROD_HOST_NAME_PRIV_DISNAT:
            case VmdConstants.PROD_HOST_NAME_DISNAT:
            case VmdConstants.PROD_HOST_NAME:
            case VmdConstants.PROD_HOST_NAME_EN:
                this.siteKey = '6LdnlV4eAAAAAGHHoe7r2hwkdqRtdQSQWPSaqrtU';
                break;
            case VmdConstants.PREPROD_HOST_NAME_VMD_PRIV:
            case VmdConstants.PREPROD_HOST_NAME_VMD:
            case VmdConstants.PREPROD_HOST_NAME:
            case VmdConstants.PREPROD_HOST_NAME_EN:
                this.siteKey = '6LfgI1AeAAAAAFvTDX1_DKHjG6gjHLMgBo43_zRE';
                break;
            default:
                this.siteKey = '6LfdQ0oeAAAAAOSbuetvripYPsJn505z08FXLUlw';
        }
    }
}
