import {OssClientAddress} from './ossClientAddress';

export class ClientInformations {

    clientLanguage: string;
    clientTitle: string;
    clientFirstName: string;
    clientLastName: string;
    clientDateOfBirth: string;
    clientSIN: string;
    clientPhoneNumber: string;
    clientAlternativePhoneNumber: string;
    clientAddress: OssClientAddress;

    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {

            this.clientLanguage = this.init(obj.clientLanguage);
            this.clientTitle = this.init(obj.clientTitle);
            this.clientFirstName = this.init(obj.clientFirstName);
            this.clientLastName = this.init(obj.clientLastName);
            this.clientDateOfBirth = this.init(obj.clientDateOfBirth);
            this.clientSIN = this.init(obj.clientSIN);
            this.clientPhoneNumber = this.init(obj.clientPhoneNumber);
            this.clientAlternativePhoneNumber = this.init(obj.clientAlternativePhoneNumber);
            this.clientAddress = this.init(obj.clientAddress);
        }
    }
}
