<h2 class="m-t-5">{{ 'SIDEBAR_PLATFORM_TITLE' | translate }}</h2>
<ul class="list-unstyled">
    <li>
        <a href="{{ 'SIDEBAR_PLATFORM_COMMISSION_LINK' | translate }}" class="lien-externe" target="_blank">
            {{ 'SIDEBAR_PLATFORM_COMMISSION' | translate }}
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>
    </li>
    <li>
        <a href="{{ 'SIDEBAR_PLATFORM_CONVENTION_LINK' | translate }}" class="lien-externe" target="_blank">
            {{ 'SIDEBAR_PLATFORM_CONVENTION' | translate }}
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>
    </li>
</ul>
