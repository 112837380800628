<div *ngIf="!loading" role="navigation">
    <ul class="nav nav-pills nav-justified" role="tablist">
        <li class="nav-item active">
            <a
                class="nav-link active"
                id="tabpills-1"
                href="#content-iframe-pdf-fr"
                data-toggle="pill"
                aria-selected="true"
                aria-controls="content-iframe-pdf-fr"
                role="tab"
            >{{ 'PDF_MODAL_NAV_TAB_FRENCH_LABEL' | translate }}</a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                id="tabpills-2"
                href="#content-iframe-pdf-en"
                data-toggle="pill"
                aria-selected="false"
                aria-controls="content-iframe-pdf-en"
                role="tab"
            >{{ 'PDF_MODAL_NAV_TAB_ENGLISH_LABEL' | translate }}</a>
        </li>
    </ul>
</div>
<div class="tab-content" [hidden]="loading">
    <iframe
        id="content-iframe-pdf-fr"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="tabpills-1"
        tabindex="0"
        [title]="'french pdf form'"
        appIFrameRefresh
        [hidden]="loading || error"
    ></iframe>
    <iframe
        id="content-iframe-pdf-en"
        class="tab-pane"
        role="tabpanel"
        aria-labelledby="tabpills-2"
        tabindex="0"
        [title]="'english pdf form'"
        appIFrameRefresh [hidden]="loading || error"></iframe>
</div>
