import {Component} from '@angular/core';
import {AccountHolderInformationComponent} from './account-holder-information.component';

@Component({
    templateUrl: './account-holder-information.component.html'
})
export class AccountHolderInformationIppComponent extends AccountHolderInformationComponent {

    public displayCompanyName(): boolean {
        return true;
    }

    public displayCompanyInformation(): boolean {
        return false;
    }

    public displayAddress(): boolean {
        return true;
    }

    public displayOtherName(): boolean {
        return false;
    }
}
