import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslatePipe} from '@ngx-translate/core';
import {
    SummaryPathConstants,
    SummaryPathConstantsGPD,
    SummaryPathConstantsPleinEx,
    SummaryPathConstantsSSD
} from '../../../components/constants/update-account-delta-review-page/summary-path.constants';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';

@Component({
    selector: 'app-review-update-account-header',
    templateUrl: './review-update-account-header.component.html',
    providers: [TranslatePipe]
})
export class ReviewUpdateAccountHeaderComponent implements OnInit {

    @Input() showModifyBtn: boolean = true;
    @Input() step: number;

    constructor(private router: Router,
                private translatePipe: TranslatePipe,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
    }

    ngOnInit() {
    }

    public navigateToSection() {
        const path = this.getNavigationUrl(this.step);
        this.router.navigate(['/', this.translatePipe.transform(path)]);
    }

    getNavigationUrl(stepKey) {

        if (this.isGpd()) {
            return SummaryPathConstantsGPD[stepKey] ? SummaryPathConstantsGPD[stepKey] : '';
        }

        if (this.isSsd()) {
            return SummaryPathConstantsSSD[stepKey] ? SummaryPathConstantsSSD[stepKey] : '';
        }

        if (this.isPleinEx()) {
            return SummaryPathConstantsPleinEx[stepKey] ? SummaryPathConstantsPleinEx[stepKey] : '';
        }

        return SummaryPathConstants[stepKey] ? SummaryPathConstants[stepKey] : '';
    }

    isGpd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_GPD;
    }

    isSsd(): boolean {
        return this.config.IS_PLEIN_EX && this.config.IS_SSD;
    }

    isPleinEx(): boolean {
        return this.config.IS_PLEIN_EX;
    }
}
