<div class="table-responsive">
    <table class="table tableau-donnees table-striped text-center">
        <thead>
        <tr>
            <th class="col-md-2 text-center">{{ 'TRANSFER_FORM_SECURITY_DESCRIPTION' | translate }}</th>
            <th class="col-md-5 text-center">{{ 'REVIEW_PREVIOUS_VALUES' | translate }}</th>
            <th class="col-md-5 text-center">{{ 'REVIEW_NEW_VALUES' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let subPage of data.updateSummarySubcategory; let i = index">
            <tr>
                <td [class]="'sous-titre'" [colSpan]="3">
                    <h4 [ngStyle]="{'margin':'0'}" class="h3">
                        {{ subPage.subPageKey | summarySubtitle: pageStep | translate }}
                    </h4>
                </td>
            </tr>
            <ng-container>
                <ng-container *ngFor="let comparisonResult of subPage.comparisonResults; let i = index">
                    <tr>
                        <td title="{{ comparisonResult.fieldName | formatToSummaryQuestion | translate | removeNbsp }}">
                            <div>
                                <app-help *ngIf="comparisonResult.significantChange"
                                          [placement]="'right'"
                                          [templateRefEn]="helpSignificantChange"
                                          [templateRefFr]="helpSignificantChange"></app-help>
                                {{ comparisonResult.fieldName | formatToSummaryQuestion | translate | truncate : 50 }}
                            </div>
                        </td>
                        <td [data]="transform(comparisonResult.externalSystemValue, comparisonResult.fieldName)"
                            app-review-update-account-table-cell>
                        </td>
                        <td [data]="transform(comparisonResult.internalSystemValue, comparisonResult.fieldName, true)"
                            [highlight]="true"
                            app-review-update-account-table-cell>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
        </tbody>
    </table>
</div>
<ng-template #helpSignificantChange>
    <p class="padding-right-20">{{ 'SIGNIFICANT_CHANGE'|translate }}</p>
</ng-template>
