<div id="modal-pdf" class="modal" tabindex="-1" role="dialog" aria-labelledby="modal-pdf-label" aria-describedby="modal-pdf-desc">
    <div class="modal-dialog modal-full-screen">
        <div class="modal-content" role="document" tabindex="0">
            <div class="modal-header">
                <h2 class="modal-title" id="modal-pdf-label" data-loop="start" tabindex="0">
                    {{'PDF_MODAL_TITLE'|translate}}
                </h2>
            </div>
            <div class="modal-body" id="modal-pdf-desc" [class.generation-pdf-01]="loading">
                <div *ngIf="loading" class="generation-pdf-01-text">
                    <h3>{{'PDF_MODAL_PAGE_TITLE'|translate}}</h3>
                    <div class="text-align-left">
                        <img alt="" src="assets/img/loading.gif">
                    </div>
                </div>

                <app-modal-pdf-multi-lang
                    *ngIf="isPdfLangActivate && isClientEnglishSpeakingQuebecResident"
                    [loading]="loading"
                    [error]="error"
                ></app-modal-pdf-multi-lang>

                <iframe *ngIf="!isPdfLangActivate || !isClientEnglishSpeakingQuebecResident"
                        [title]="'pdf form'"
                        [hidden]="loading || error">
                    appIFrameRefresh
                </iframe>

                <div *ngIf="!loading && error" class="alert alert-danger">{{error | translate}}</div>
            </div>
            <div class="modal-footer">
                <span *ngIf="!isPleinEx()" class="text-inline">
                    <span class="contactus p-l-3">
                        {{ 'SIDEBAR_ASSISTANCE_TITLE' | translate }}
                    </span>
                    <span class="m-l-1" itemscope itemtype="http://schema.org/BankOrCreditUnion">
                        <span itemprop="telephone">
                            <a href="tel:{{ 'SIDEBAR_ASSISTANCE_PHONE_LINK' | translate }}" class="lien-sans-soulignement">
                                {{ 'SIDEBAR_ASSISTANCE_PHONE' | translate }}
                                <span class="sr-only">{{ 'SR_ONLY_PHONE' | translate }}</span>
                            </a>
                        </span>
                    </span>
                    <span class="m-l-1">
                        {{ 'SIDEBAR_ASSISTANCE_SCHEDULE' | translate }}
                    </span>
                </span>
                <button *ngIf="!loading" class="btn btn-primary" data-dismiss="modal" title="{{'BTN_CLOSE'|translate}}" type="submit"
                        data-focus="initial">
                    {{'BTN_CLOSE'|translate}}
                </button>
            </div>
        </div>
    </div>
</div>
