import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormGroup, NgForm} from '@angular/forms';

declare let $: any;

@Component({
    selector: 'app-error-list',
    templateUrl: './error-list.component.html'
})
export class ErrorListComponent {

    @Input() form: FormGroup;
    @Input() ngFormRef: NgForm;
    @Input() context: string;

    getErrors(control: FormGroup = null, nameSuffix: string = '', index: string = null): { label: string, name: string, index: string }[] {

        if (control === null) {
            control = this.form;
        }
        let errors: { label: string, name: string, index: string }[] = [];
        Object.keys(control.controls).forEach(path => {
            if (control.get(path) instanceof FormGroup) {
                const groupErrors: { label: string, name: string, index: string }[] = this.getErrors(control.get(path) as FormGroup, nameSuffix, null);
                errors = errors.concat(groupErrors);
            } else if (control.get(path) instanceof FormArray) {
                // if the FormGroup has a FormArray, we loop all way down to all form controls of each FormArray
                const formArray = control.get(path) as FormArray;
                Object.keys(formArray.controls).forEach(index => {
                    const groupErrors: { label: string, name: string, index: string }[] = this.getErrors(formArray.get(index) as FormGroup, nameSuffix + '_' + index, index);
                    errors = errors.concat(groupErrors);
                });
            } else if (control.get(path).touched && control.get(path).invalid && this.ngFormRef && this.ngFormRef.submitted) {
                errors.push({label: path, name: path + nameSuffix, index});
            } else if (control.get(path).touched && control.get(path).invalid && control.get(path).errors !== null) {
                errors.push({label: path, name: path + nameSuffix, index});
            }
        });

        return errors;
    }

    focusToField(name: string) {
        $('input[name="' + name + '"],select[name="' + name + '"]').first().focus().click();
        return false;
    }
}
