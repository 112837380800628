import {BaseNavComponent} from '../../layout/questionnaire/base-nav.component';
import {Component} from '@angular/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../../components/services/modal.service';

@Component({
    template: '',
})
export class BaseAccountTypeRelatedQuestionsComponent extends BaseNavComponent {

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService,
                protected modalService: ModalService) {
        super(navigationService, fb, formService, translate);
    }

    navBack(): void {
        if (this.constants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey) {
            this.navigationService.emitBack();
        } else {
            super.navBack();
        }
    }

    isExcludeStepNavigation(): boolean {
        return this.constants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey
            || this.constants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    submitForm(triggerNext: boolean = true) {
        super.submitForm(triggerNext);

        if (this.formService.isQuebecResident()) {
            this.modalService.triggerEnglishQuebecResidentModal();
        }
    }
}
