<form [formGroup]="form" ngForm novalidate (submit)="submit()">
    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-bill"></span>
        {{'TRANSFER_REQUEST_TITLE'|translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <div class="m-b-3" formArrayName="transferGroup"
         *ngFor="let transfer of transferGroup.controls; let i=index">
        <div [formGroupName]="i">
            <app-transfer-form
                [form]="form"
                [index]="i"
                [ngFormRef]="ngForm"
                [actType]="accountTransferType"
                [path]="'transferGroup'"
                [bindPath]="bindPath"
                [actTypeCurrency]="'actTypeCurrency'"
                [bankName]="'bankName'"
                [bankResourceFirstName]="'bankResourceFirstName'"
                [bankResourceLastName]="'bankResourceLastName'"
                [bankPhone]="'bankPhone'"
                [transferType]="'transferType'"
                [transferTypeTotal]="'transferTypeTotal'"
                [transferTypePartial]="'transferTypePartial'"
                [transferInstructionType]="'transferInstructionType'"
                [transferInstructionTypeCash]="'transferInstructionTypeCash'"
                [transferInstructionTypeKind]="'transferInstructionTypeKind'"
                [transferInstructionTypeMixed]="'transferInstructionTypeMixed'"
                [transferCash]="'transferCash'"
                [accountStatementName]="'accountStatementName'"
                [accountStatement]="'accountStatement'"
                [uploadFile]="'uploadFile'"
            >
            </app-transfer-form>
        </div>

        <div *ngIf="displayAddNewTransfer(i)" class="m-t-5 m-b-1">
            <a href="#" class="lien-sans-soulignement" (click)="addNewTransfer(accountTransferType)">
                <span class="icon-circle-plus"></span>
                {{'TRANSFER_FORM_ADD'|translate}}
            </a>
        </div>
    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef" [loading]="loading"></app-questionnaire-btn>

</form>
