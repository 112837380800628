<form [formGroup]="form" ngForm novalidate (submit)="submitForm()">
    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-house"></span>

        {{'ODC_BENEFICIARY_RRSP_TITLE' | translate}}
        <app-help [templateRefFr]="helpFr" [templateRefEn]="helpEn"></app-help>
    </h2>


    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--Answer-->
    <app-odc-field
        [type]="constants.FIELD_TYPES.RADIO"
        [form]="form"
        [submitted]="isFormSubmitted()"
        [label]="'ODC_BENEFICIARY_RRSP_QUESTION' | translate"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [id]="'beneficiaryReerActiveIndicator'"
        [name]="'beneficiaryReerActiveIndicator'"
        [path]="'beneficiaryReerActiveIndicator'"
        [binding]="'beneficiaryReerActiveIndicator'"
    ></app-odc-field>





    <div *ngIf="isBeneficiaryPriIndicator">

        <app-beneficiary
            [form]="form"
            [submitted]="isFormSubmitted()"
            [groupName]="'beneficiary'"
            [gender]="'beneficiaryReerGender'"
            [firstName]="'beneficiaryReerFirstName'"
            [lastName]="'beneficiaryReerLastName'"
            [DOB]="'beneficiaryReerDOB'"
            [SIN]="'beneficiaryReerSIN'"
            [addressStreet]="'beneficiaryReerAdressStreet'"
            [addressUnit]="'beneficiaryReerAdressUnit'"
            [addressCity]="'beneficiaryReerAdressCity'"
            [addressProv]="'beneficiaryReerAdressProv'"
            [addressPostalCode]="'beneficiaryReerAdressPostalCode'"
            [addressCountry]="'beneficiaryReerAdressCountry'"
            [parentLink]="'beneficiaryReerParentLink'"
            [part]="'beneficiaryReerPart'"
            [deleteVisible]="isBeneficiarySecIndicator"
            (toggle)="removePriBeneficiary()"
        ></app-beneficiary>



        <div class="m-b-1">
            <ng-container *ngIf="!isBeneficiarySecIndicator">
                <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiarySec()">
                    <span class="icon-circle-plus"></span>
                    {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                </a>
            </ng-container>

        </div>

        <div *ngIf="isBeneficiarySecIndicator" >

            <app-beneficiary
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiarySec'"
                [gender]="'beneficiaryReerGenderSec'"
                [firstName]="'beneficiaryReerFirstNameSec'"
                [lastName]="'beneficiaryReerLastNameSec'"
                [DOB]="'beneficiaryReerDOBSec'"
                [SIN]="'beneficiaryReerSINSec'"
                [addressStreet]="'beneficiaryReerAdressStreetSec'"
                [addressUnit]="'beneficiaryReerAdressUnitSec'"
                [addressCity]="'beneficiaryReerAdressCitySec'"
                [addressProv]="'beneficiaryReerAdressProvSec'"
                [addressPostalCode]="'beneficiaryReerAdressPostalCodeSec'"
                [addressCountry]="'beneficiaryReerAdressCountrySec'"
                [parentLink]="'beneficiaryReerParentLinkSec'"
                [part]="'beneficiaryReerPartSec'"
                [deleteVisible]="true"
                (toggle)="removeSecBeneficiary()"
            ></app-beneficiary>



            <div class="m-b-1">
                <ng-container *ngIf="!isBeneficiaryTerIndicator">
                    <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addBeneficiaryTer()">
                        <span class="icon-circle-plus"></span>
                        {{'ODC_STEP_BENEFICIARY_ADD'|translate}}
                    </a>
                </ng-container>

            </div>



        </div>
        <div *ngIf="isBeneficiaryTerIndicator">
            <app-beneficiary
                [form]="form"
                [submitted]="isFormSubmitted()"
                [groupName]="'beneficiaryTer'"
                [gender]="'beneficiaryReerGenderTer'"
                [firstName]="'beneficiaryReerFirstNameTer'"
                [lastName]="'beneficiaryReerLastNameTer'"
                [DOB]="'beneficiaryReerDOBTer'"
                [SIN]="'beneficiaryReerSINTer'"
                [addressStreet]="'beneficiaryReerAdressStreetTer'"
                [addressUnit]="'beneficiaryReerAdressUnitTer'"
                [addressCity]="'beneficiaryReerAdressCityTer'"
                [addressProv]="'beneficiaryReerAdressProvTer'"
                [addressPostalCode]="'beneficiaryReerAdressPostalCodeTer'"
                [addressCountry]="'beneficiaryReerAdressCountryTer'"
                [parentLink]="'beneficiaryReerParentLinkTer'"
                [part]="'beneficiaryReerPartTer'"
                [deleteVisible]="true"
                (toggle)="removeTerBeneficiary()"
            ></app-beneficiary>
        </div>
    </div>

    <p *ngIf="isBeneficiaryPriIndicator" class="m-b-2 m-t-1" tabindex="0">{{ 'ODC_BENEFICIARY_RRSP_SHARE_WITHOUT_BENEFICIARY' | translate }}</p>



    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpFr>
    <h2>Pourquoi nommer un bénéficiaire?</h2>
    <p>Les actifs du REER pourront ainsi être transférés directement aux bénéficiaires que vous désignez (dans la documentation du compte REER) conformément à la législation applicable.</p>
</ng-template>
<ng-template #helpEn>
    <h2>Why name a beneficiary?</h2>
    <p>RRSP assets will therefore be transferred directly to the beneficiaries you designate (in the RRSP account documentation) in accordance with the applicable legislation.</p>
</ng-template>
