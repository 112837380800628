<form (submit)="onSubmitForm()" [formGroup]="form" ngForm novalidate>

    <h2 class="clearfix">
        <span class="b-c-h-icon icon-disnat2 icon-bill"></span>
        {{'TRANSFER_REQUEST_CHOICE_TITLE' | translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--hasTransfers-->
    <app-odc-field
        [binding]="''"
        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
        [form]="form"
        [id]="'hasTransfers'"
        [label]="'TRANSFER_REQUEST_CHOICE_LABEL' | translate"
        [name]="'hasTransfers'"
        [path]="'hasTransfers'"
        [submitted]="isFormSubmitted()"
        [templateRefEn]="helpTransferRequestEn"
        [templateRefFr]="helpTransferRequestFr"
        [type]="constants.FIELD_TYPES.RADIO"
    ></app-odc-field>

    <div *ngIf="form.get('hasTransfers').value && !isModalActive" formGroupName="transferTypes">
        <app-error [form]="form" [ngFormRef]="ngForm"
                   [path]="getTransferFieldName()"></app-error>
        <fieldset>
            <legend>
                <b>{{'TRANSFER_REQUEST_CHOICE_LIST_LABEL'|translate}}</b>
            </legend>
            <ng-container *ngFor="let transferType of transferTypes">
                <div *ngIf="hasAccountType(transferType)" class="row">
                    <div [class.has-error]="isFieldInError(getTransferFieldName())" class="col-md-12">
                        <label class="c-input c-checkbox">
                            <input (click)="clickTransferType(transferType)"
                                   [appFormBinding]="transferType"
                                   [attr.id]="transferType"
                                   [attr.name]="transferType"
                                   formControlName="{{transferType}}"
                                   type="checkbox"
                            >
                            <span
                                class="c-indicator"></span> {{ 'ACCOUNT_TYPE_LABEL_' + getAccountKey(transferType) | translate }}
                        </label>
                    </div>
                    <div *ngIf="isPleinEx()" class="col-md-12 transfer-number-copy-box">
                        <input (blur)="blurTransferNumberCopy(transferType)"
                               [appFormBinding]="transferType + FIELD_NUMBER_COPY"
                               [attr.disabled]="hasTransferForType(transferType) ? null : ''"
                               class="transfer-number-copy-input"
                               formControlName="{{transferType + FIELD_NUMBER_COPY}}"
                               id="{{transferType + FIELD_NUMBER_COPY}}"
                               max="{{MAX_NUMBER_COPY}}"
                               maxlength="2"
                               min="{{MIN_NUMBER_COPY}}"
                               name="{{transferType + FIELD_NUMBER_COPY}}"
                               type="number"/>
                        <label for="{{transferType + FIELD_NUMBER_COPY}}">
                            {{'TRANSFER_REQUEST_CHOICE_COPY_LABEL' | translate}}
                        </label>
                    </div>
                </div>
            </ng-container>
        </fieldset>
    </div>

    <app-questionnaire-btn [formRef]="formRef" [form]="form" [loading]="loading"></app-questionnaire-btn>

    <div *ngIf="error !== null" class="alert alert-danger">
        {{'GLOBAL_ERROR_MSG'|translate}}
    </div>
</form>
<ng-template #helpTransferRequestFr>
    <h2>Compte de placement</h2>
    <p>Les comptes de placement incluent les comptes détenant des titres et les comptes enregistrés, et excluent les
        comptes chèques et les comptes d’opérations courantes. Le transfert d’argent en provenance de votre compte
        d’opérations courantes à votre caisse Desjardins se fait à l’aide de la fonction de <b>Virements entre
            comptes</b> sur AccèsD.</p>
</ng-template>
<ng-template #helpTransferRequestEn>
    <h2>Investment account</h2>
    <p>Investment accounts include accounts holding securities and registered accounts, and exclude chequing and
        Everyday transaction accounts. Money transfers from your caisse Desjardins’ Everyday transaction account are
        done using the <b>Transfers between accounts</b> function on AccèsD.</p>
</ng-template>
