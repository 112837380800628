import {Directive, ElementRef, forwardRef, HostListener, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

declare let $: any;

@Directive({
    selector: '[appAddressField]',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AddressFieldDirective), multi: true}
    ]
})
export class AddressFieldDirective implements ControlValueAccessor, OnInit {

    @Input() appAddressField: string;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        if (this.appAddressField) {
            $(this.el.nativeElement).attr('address-field', this.appAddressField);
        }
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        this.writeValue(this.el.nativeElement.value);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        if (this.el.nativeElement.tagName === 'SELECT' && obj === 'null') {
            obj = null;
        }
        this.el.nativeElement.value = obj;
        this.onChange(obj);
    }

}
