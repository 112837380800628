<header class="row review-header">
    <h3 class="col-md-18 left review-header-title">
        <span class="badge indicator review-header-indicator oswald">{{ step }}</span>
        <span class="h2 text-uppercase review-header-text">{{ label | translate }}</span>
    </h3>
    <div class="col-md-6 m-t-1 text-right">
        <button class="btn btn-primary review-header-modify" [attr.data-mw-action-clic]="('GLOBAL_MODIFY' | translate) + ' - ' + (targetLabel | translate)" (click)="navigateToSection()">
            {{ 'GLOBAL_MODIFY' | translate }}
            <span class="sr-only"> - {{ targetLabel | translate }}</span>
        </button>
    </div>
</header>
