import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, ViewChild} from '@angular/core';
import {LangChangeEvent} from '@ngx-translate/core/lib/translate.service';
import {Subscription} from 'rxjs';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {RecaptchaService} from '../../components/services/recaptcha.service';

@Component({
    selector: 'app-recaptcha',
    templateUrl: './recaptcha.component.html',
})
export class RecaptchaComponent implements AfterViewInit, OnDestroy {

    @Input() langChange: EventEmitter<LangChangeEvent>;
    @Input() lang: string;
    @Input() containerId: string = 'recaptcha';
    private recaptchaWidgetId: string;

    recaptchaChangeLangSubscriptor: Subscription;

    @ViewChild('recaptchaParentNode') recaptchaParentNode: ElementRef;

    constructor(private recaptchaService: RecaptchaService,
                @Inject(ODC_CONFIG) public config: IOdcConfig) {
    }

    ngAfterViewInit(): void {
        if (this.config.TOGGLE_RECAPTCHA) {
            if (!this.recaptchaWidgetId) {
                this.recaptchaWidgetId = this.recaptchaService.renderRecaptcha(this.lang, this.containerId);
            } else {
                this.recaptchaService.resetRecaptcha(this.recaptchaWidgetId);
            }

            this.recaptchaChangeLangSubscriptor = this.langChange.subscribe(lang => {

                this.recaptchaParentNode.nativeElement.innerHTML =
                    '<div #recaptchaParentNode>' +
                    '    <div id=' + this.containerId + '></div>' +
                    '</div>';

                this.recaptchaWidgetId = this.recaptchaService.renderRecaptcha(lang.lang, this.containerId);
            });
        }
    }

    getRecaptchaToken() {
        if (this.config.TOGGLE_RECAPTCHA) {
            return this.recaptchaService.getRecaptchaToken(this.recaptchaWidgetId);
        }
    }

    reset() {
        if (this.config.TOGGLE_RECAPTCHA) {
            this.recaptchaService.resetRecaptcha(this.recaptchaWidgetId);
        }
    }

    ngOnDestroy(): void {
        if (this.recaptchaChangeLangSubscriptor) {
            this.recaptchaChangeLangSubscriptor.unsubscribe();
        }
    }
}
