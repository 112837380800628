import {Component} from '@angular/core';
import {ResidentialAddressComponent} from './residential-address.component';

@Component({
    selector: 'app-residential-address-second-holder',
    templateUrl: './residential-address.component.html'
})
export class ResidentialAddressSecondHolderComponent extends ResidentialAddressComponent {

    public requesterIndex = 1;

}
