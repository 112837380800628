import {Injectable} from '@angular/core';
import {HtmlSelectKV} from '../models/html-select-k-v';
import {VmdFormat} from '../formats/vmd-format';
import {FormService} from './form.service';

declare let _: any;
declare let $: any;

@Injectable()
export class CountryCompleterService {

    constructor(private formService: FormService) {
    }

    initialize(element: any, countries: HtmlSelectKV[]): void {
        const tt = $(element).typeahead('destroy');
        element.bind('keydown', (e) => {
            if (e.keyCode === 9) { // (9 - keyTab)
                tt.typeahead('close');
            }
        });

        $(element).typeahead({
            minLength: 2,
            highlight: true
        }, {
            display: 'itemLabel',
            limit: 10,
            source: (query, cb) => {
                const matches = [];
                const regexp = new RegExp(VmdFormat.removeAccents(query), 'i');

                _.each(countries, (obj) => {
                    if (regexp.test(VmdFormat.removeAccents(obj.itemLabel))) {
                        matches.push(obj);
                    }
                });

                cb(matches);
            }
        }).on('typeahead:select', (e, choice) => {
            $(element).val(choice.itemValue);
            $(element).attr('data-input-value', choice.itemValue);
        });
    }

}
