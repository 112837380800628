import {Component} from '@angular/core';
import {OccupationComponent} from './occupation.component';

@Component({
    selector: 'app-occupation-second-holder',
    templateUrl: './occupation.component.html'
})
export class OccupationComponentSecondHolderComponent extends OccupationComponent {

    public requesterIndex = 1;

}
