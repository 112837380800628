<form [formGroup]="form" ngForm novalidate (submit)="submit()">

    <h2>
        {{'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TITLE'|translate}}
    </h2>

    <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

    <!--citizenship---------------------------------------------------->
    <div [ngClass]="{'panel panel-default panel-noborder' : !isGpdOrSfd()}" id="panelDefault">
        <div [ngClass]="{'panel-body' : !isGpdOrSfd()}">

            <div formGroupName="citizenshipGroup">

                <app-odc-field
                    [type]="constants.FIELD_TYPES.CHECKBOX"
                    [form]="form.get('citizenshipGroup')"
                    [submitted]="isFormSubmitted()"
                    [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_QUESTION' | translate"
                    [choices]="[
                        {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA' | translate, id: 'userCitizenship', binding: getRequesterPath('userCitizenship')},
                        {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES' | translate, id: 'userCitizenshipUS', binding: getRequesterPath('userCitizenshipUS')},
                        {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER' | translate, id: 'addUserOtherCitizenship', binding: getRequesterPath('addUserOtherCitizenship'), templateRef: userOtherCitizenship}
                    ]"
                    [path]="'userCitizenship'"
                    [stacked]="true"
                    [readonlyProperty]="'userCitizenship'"
                ></app-odc-field>

                <ng-template #userOtherCitizenship>
                    <div *ngIf="form.get('citizenshipGroup.addUserOtherCitizenship').value" class="m-l-3">
                        <!--userOtherCitizenship-->
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('citizenshipGroup')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER_CITIZENSHIP' | translate"
                            [id]="'userOtherCitizenship'"
                            [name]="'userOtherCitizenship'"
                            [path]="'userOtherCitizenship'"
                            [list]="countries"
                            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                            [binding]="getRequesterPath('userOtherCitizenship')"
                        ></app-odc-field>
                    </div>
                </ng-template>
            </div>

            <!--Snowbird-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form"
                [submitted]="isFormSubmitted()"
                [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_RESIDENCE_QUESTION' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="'fiscalUsCitizenshipIndicator'"
                [name]="'fiscalUsCitizenshipIndicator'"
                [path]="'fiscalUsCitizenshipIndicator'"
                [binding]="getRequesterPath('fiscalUsCitizenshipIndicator')"
                [stacked]="true"
                [templateRefFr]="helpSnowbirdFr"
                [templateRefEn]="helpSnowbirdEn"
            ></app-odc-field>

        </div>
    </div>


    <!--tax purpose--------------------------------------------------------------------------------------------------->
    <div formGroupName="fiscalGroup">
        <app-odc-field
            [type]="constants.FIELD_TYPES.CHECKBOX"
            [form]="form.get('fiscalGroup')"
            [submitted]="isFormSubmitted()"
            [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_QUESTION' | translate"
            [choices]="[
                {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1' | translate, id: 'userFiscalRelationCanada', binding: getRequesterPath('userFiscalRelationCanada'), helpRefFr: helpCanadianResidentTaxPurposesFr, helpRefEn: helpCanadianResidentTaxPurposesEn},
                {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2' | translate, id: 'userFiscalRelationUsa', binding: getRequesterPath('userFiscalRelationUsa'), templateRef: userFiscalRelationUsa, helpRefFr: helpUsaResidentTaxPurposesFr, helpRefEn: helpUsaResidentTaxPurposesEn},
                {label: 'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3' | translate, id: 'userFiscalRelationOther', binding: getRequesterPath('userFiscalRelationOther'), templateRef: userFiscalRelationOther}
            ]"
            [stacked]="true"
            [path]="'userFiscalRelationCanada'"
        ></app-odc-field>

        <ng-template #userFiscalRelationUsa>
            <div *ngIf="form.get('fiscalGroup.userFiscalRelationUsa').value" class="m-l-3">
                <div class="row" formGroupName="usaNifGroup">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('fiscalGroup.usaNifGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN' | translate"
                        [id]="'usaNif'"
                        [name]="'usaNif'"
                        [path]="'usaNif'"
                        [binding]="getRequesterPath('usaNif')"
                        [attr]="{maxlength: 32}"
                        [templateRefFr]="helpTinFr"
                        [templateRefEn]="helpTinEn"
                    ></app-odc-field>

                    <app-odc-field
                        [type]="constants.FIELD_TYPES.RADIO"
                        [form]="form.get('fiscalGroup.usaNifGroup')"
                        [submitted]="isFormSubmitted()"
                        [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_TIN_QUESTION' | translate"
                        [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                        [id]="'userNifMissingReason'"
                        [name]="'userNifMissingReason'"
                        [path]="'userNifMissingReason'"
                        [binding]="getRequesterPath('userNifMissingReason')"
                        [stacked]="true"
                    ></app-odc-field>
                </div>
            </div>
        </ng-template>

        <ng-template #userFiscalRelationOther>
            <div *ngIf="form.get('fiscalGroup.userFiscalRelationOther').value" class="m-l-3">
                <div class="row align-items-baseline" formGroupName="autreNifGroup1">
                    <div class="col-md-8">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('fiscalGroup.autreNifGroup1')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_COUNTRY' | translate"
                            [id]="'userCountryNif2'"
                            [name]="'userCountryNif2'"
                            [path]="'userCountryNif2'"
                            [list]="countries"
                            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                            [binding]="getRequesterPath('userCountryNif2')"
                        ></app-odc-field>
                    </div>
                    <div class="col-md-8">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('fiscalGroup.autreNifGroup1')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_TIN' | translate"
                            [id]="'codePremierAutreNif'"
                            [name]="'codePremierAutreNif'"
                            [path]="'codePremierAutreNif'"
                            [binding]="getRequesterPath('codePremierAutreNif')"
                            [attr]="{maxlength: 32}"
                            [templateRefFr]="helpTinFr"
                            [templateRefEn]="helpTinEn"
                        ></app-odc-field>
                    </div>
                    <div class="col-md-8">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.SELECT"
                            [form]="form.get('fiscalGroup.autreNifGroup1')"
                            [submitted]="isFormSubmitted()"
                            [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON' | translate"
                            [id]="'userNifMissingReason2'"
                            [name]="'userNifMissingReason2'"
                            [path]="'userNifMissingReason2'"
                            [list]="nifReasons"
                            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                            [binding]="getRequesterPath('userNifMissingReason2')"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-b-1">
                    <ng-container *ngIf="!showSecondAutreNif">
                        <a href="javascript:void(0)" class="lien-sans-soulignement"
                           (click)="toggleSecondAutreNif(true)">
                            <span class="icon-circle-plus"></span>
                            {{'ODC_STEP_RESIDENTIAL_ADDRESS_ADD_2ND_COUNTRY'|translate}}
                        </a>
                    </ng-container>
                </div>

                <div *ngIf="showSecondAutreNif">
                    <div class="row align-items-baseline" formGroupName="autreNifGroup2">
                        <div class="col-md-8">
                            <app-odc-field
                                [type]="constants.FIELD_TYPES.SELECT"
                                [form]="form.get('fiscalGroup.autreNifGroup2')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_COUNTRY' | translate"
                                [labelSrOnly]="true"
                                [id]="'userCountryNif3'"
                                [name]="'userCountryNif3'"
                                [path]="'userCountryNif3'"
                                [list]="countries"
                                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                                [binding]="getRequesterPath('userCountryNif3')"
                            ></app-odc-field>
                        </div>
                        <div class="col-md-8">
                            <app-odc-field
                                [type]="constants.FIELD_TYPES.TEXT"
                                [form]="form.get('fiscalGroup.autreNifGroup2')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_TIN' | translate"
                                [labelSrOnly]="true"
                                [id]="'codeSecondAutreNif'"
                                [name]="'codeSecondAutreNif'"
                                [path]="'codeSecondAutreNif'"
                                [binding]="getRequesterPath('codeSecondAutreNif')"
                                [attr]="{maxlength: 32}"
                                [templateRefFr]="helpTinFr"
                                [templateRefEn]="helpTinEn"
                            ></app-odc-field>
                        </div>
                        <div class="col-md-8">
                            <app-odc-field
                                [type]="constants.FIELD_TYPES.SELECT"
                                [form]="form.get('fiscalGroup.autreNifGroup2')"
                                [submitted]="isFormSubmitted()"
                                [label]="'ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON' | translate"
                                [labelSrOnly]="true"
                                [id]="'userNifMissingReason3'"
                                [name]="'userNifMissingReason3'"
                                [path]="'userNifMissingReason3'"
                                [list]="nifReasons"
                                [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                                [binding]="getRequesterPath('userNifMissingReason3')"
                            ></app-odc-field>
                        </div>
                    </div>
                </div>

                <div class="m-b-1">
                    <ng-container *ngIf="showSecondAutreNif">
                        <a href="javascript:void(0)" class="lien-sans-soulignement"
                           (click)="toggleSecondAutreNif(false)">
                            <span class="icon-close rouge"></span>
                            {{'ODC_STEP_RESIDENTIAL_ADDRESS_REMOVE_2ND_COUNTRY'|translate}}
                        </a>
                    </ng-container>
                </div>
            </div>
        </ng-template>

    </div>

    <app-questionnaire-btn [form]="form" [formRef]="formRef"></app-questionnaire-btn>
</form>

<ng-template #helpCanadianResidentFR>
    <h2>Dois-je être un résident canadien pour ouvrir un compte?</h2>
    <p>Oui. Desjardins Courtage en ligne n'est pas inscrite comme firme de courtage en valeurs mobilières à l'extérieur
        du Canada.</p>
    <h2>Les citoyens américains peuvent-ils ouvrir un compte auprès de Desjardins Courtage en ligne?</h2>
    <p>Desjardins Courtage en ligne peut offrir ses services à des citoyens américains, en autant que ces derniers
        soient résidents canadiens. Si cette situation s'applique à vous, vous devrez remplir notre formulaire, «Demande
        de numéro d'identification de contribuable et d'attestation (W9)» au
        <a href="https://www.disnat.com/formulaires" class="lien-externe" target="_blank">
            www.disnat.com/formulaires
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>.</p>
</ng-template>
<ng-template #helpCanadianResidentEn>
    <h2>Must I be a Canadian resident to open an account?</h2>
    <p>Yes. Desjardins Online Brokerage is not registered as a securities broker outside of Canada.</p>
    <h2>Can American citizens open an account at Desjardins Online Brokerage?</h2>
    <p>Desjardins Online Brokerage may offer its services to US citizens, provided that they are Canadian residents. If
        this situation applies to you, you must complete a "Request for Taxpayer Identification Number and Certification
        (W-9)" form found at
        <a href="https://www.disnat.com/forms" class="lien-externe" target="_blank">
            www.disnat.com/forms
            <span class="sr-only">{{ 'ODC_LINK_NEW_WINDOW' | translate }}</span>
        </a>.</p>
</ng-template>

<ng-template #helpSnowbirdFr>
    <h2>Suis-je considéré comme résident temporaire des États-Unis (Snowbird) ?</h2>
    <p>Vous êtes considéré comme un résident temporaire des États-Unis si vous êtes résident du Canada aux fins de
        l’impôt, mais que vous avez inscrit à votre dossier à titre temporaire une adresse ou un numéro de téléphone
        répertoriés aux États-Unis, ou encore si tout ordre de virement est effectué dans un compte détenu aux
        États-Unis associé à votre nom pendant un séjour temporaire dans ce pays. Si vous êtes un résident des
        États-Unis aux fins fiscales ou un citoyen des États-Unis, vous ne pouvez être considéré un résident temporaire
        des États-Unis.</p>
</ng-template>
<ng-template #helpSnowbirdEn>
    <h2>Am I considered a temporary resident of the United States (Snowbird)?</h2>
    <p>You are considered a temporary resident of the United States if you are a resident of Canada for tax purposes,
        but in your file you have specified a temporary address or telephone number in the United States, or if a
        transfer request is made to an account held in the United States linked to your name during a temporary stay
        there. If you are a US resident for tax purposes or a US citizen you may not be considered a temporary resident
        of the United States.</p>
</ng-template>

<ng-template #helpCanadianResidentTaxPurposesFr>
    <h2>Suis-je considéré un résident canadien?</h2>
    <p><strong>Mise en garde:</strong> Les personnes qui répondent à la définition ci-après de résidents canadiens de
        l'Agence de
        revenu du Canada mais, qui résident hors du Canada peuvent être assujetties à des lois et règlements en valeurs
        mobilières qui entrainent des restrictions quand à l'ouverture d'un compte.</p>
    <p>Est un résident canadien le citoyen canadien résidant au Canada ou qui ne réside pas habituellement au Canada,
        mais fait partie d'une catégorie de personnes suivantes :</p>
    <ul>
        <li>a) le citoyen canadien qui réside hors du Canada principalement pour y exercer ses fonctions d'employé à
            plein temps d'une personne morale, selon le cas:
        </li>
        <li>(i) dont plus de cinquante pour cent des actions avec droit de vote sont la propriété effective de résidents
            canadiens au sens des alinéas a) ou c) de cette définition, ou dont le controle ou la haute main est exercé
            par de tels résidents canadiens,
        </li>
        <li>(ii) dont la majorité des administrateurs sont des résidents canadiens au sens des alinéas a) ou c) de cette
            définition,
        </li>
        <li>(iii) qui est une filiale d'une personne morale visée aux sous-alinéas (i) ou (ii);</li>
        <li>b) le citoyen canadien qui est un employé à plein temps d'une association ou organisation internationale
            dont le Canada est membre;
        </li>
        <li>c) le citoyen canadien qui résidait habituellement au Canada à la date de son 60e anniversaire de naissance
            et qui réside hors du Canada depuis moins de dix années consécutives.
        </li>
    </ul>
</ng-template>
<ng-template #helpCanadianResidentTaxPurposesEn>
    <h2>Am I considered a Canadian resident?</h2>
    <p><strong>Caution:</strong> Persons who meet the Canada Revenue Agency's definition of "Canadian resident" but who
        reside
        outside of Canada may be subject to securities laws and regulations that entail restrictions with regard to
        opening an account.</p>
    <p>A Canadian resident is a Canadian citizen residing in Canada or who is not ordinarily resident in Canada but is
        part of the following class of persons:</p>
    <ul>
        <li>a) A Canadian citizen residing outside Canada primarily as a function of full time employment by a legal
            person, where:
        </li>
        <li>(i) more than fifty percent of the shares with voting rights are beneficially owned by Canadian residents as
            defined in paragraph a) or c) of this definition, or which control or direction is exercised by such
            Canadian residents,
        </li>
        <li>(ii) a majority of the directors are Canadian residents as defined in paragraph a) or c) of this
            definition,
        </li>
        <li>(iii) they are a subsidiary of a corporation referred to in subparagraph (i) or (ii);</li>
        <li>b) A Canadian citizen who is a full time employee of an international association or organization of which
            Canada is a member;
        </li>
        <li>c) A Canadian citizen who ordinarily resided in Canada on the date of their 60th birthday and who lives
            outside Canada for less than ten consecutive years.
        </li>
    </ul>
</ng-template>

<ng-template #helpUsaResidentTaxPurposesFr>
    <h2>Que signifie être un résident des États-Unis aux fins de l'impôt ou de citoyenneté américaine ?</h2>
    <p>Selon la législation fiscale américaine, est considéré comme un résident des États-Unis aux fins de l'impôt ou un
        citoyen américain :</p>
    <ul>
        <li>tout citoyen des États-Unis (notamment une personne née aux États-Unis qui réside au Canada ou dans un autre
            pays et qui n'a pas renoncé à sa citoyenneté américaine); ou
        </li>
        <li>tout résident autorisé des États-Unis (notamment un titulaire de la carte verte américaine); ou</li>
        <li>tout résident permanent des États-Unis.</li>
    </ul>
    <p>Une personne peut également être considérée comme un résident des États-Unis aux fins de l'impôt si elle passe
        chaque année une période suffisamment longue aux États-Unis.</p>
</ng-template>
<ng-template #helpUsaResidentTaxPurposesEn>
    <h2>What does it means to be a tax resident or citizen of the United States ?</h2>
    <p>Under U.S. tax law, is considered a tax resident or a citizen of the United States :</p>
    <ul>
        <li>Any citizen of the United States (including an individual born in the U.S. who resides in Canada or another
            country and who has not renounced their U.S. citizenship); or
        </li>
        <li>Any legal resident of the U.S. (including a U.S. Green Card holder); or</li>
        <li>Any permanent resident of the U.S.</li>
    </ul>
    <p>A person may also be considered a tax resident of the United States if they spend a certain number of days in the
        U.S. each year.</p>
</ng-template>

<ng-template #helpTinFr>
    <h2>Numéro d'Identification Fiscale (NIF)</h2>
    <p>Le numéro d'identification fiscal (NIF) est une combinaison unique de lettres et de chiffres qu'un pays attribue
        à un particulier pour identifier celui-ci aux fins de l'administration de ses lois fiscales. Au Canada, le NIF
        correspond au numéro d'assurance sociale (NAS) pour une personne physique, au numéro d'entreprise (NE ou NEQ)
        pour une entreprise et au numéro de fiducie pour une fiducie.</p>
    <p>Si vous ne possédez pas un NIF, vous avez 90 jours pour en faire la demande.</p>
</ng-template>
<ng-template #helpTinEn>
    <h2>Tax Identification Number (TIN)</h2>
    <p>A tax identification number (TIN) is a unique combination of letters or numbers that a country assigns to an
        individual to identify the individual for the purposes of administering its tax laws. In Canada, the TIN is a
        social insurance number (SIN) for a natural person, a business number (BN) or Quebec enterprise number (NEQ) for
        a business and a trust number for a trust.</p>
    <p>If you do not have a TIN, you have 90 days to request a SIN for a natural person, a business number (BN or NEQ)
        for a business, or a trust number for a trust.</p>
</ng-template>
