import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';
import {VmdValidators} from '../../../components/validators/vmd-validators';

@Component({
    selector: 'app-ipp-related-questions',
    templateUrl: './ipp-related-questions.component.html'
})
export class IppRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    ngOnInit() {
        this.form = this.fb.group({
            ippRegistrationNumber: [this.formService.getForm().otherIppRegistrationNumber, [Validators.maxLength(32)]],
            ippAuthorizedTradePersonNumber: [this.formService.getForm().otherActPeople, [Validators.min(1), Validators.max(10), Validators.required, VmdValidators.digit]]
        });

        if (this.formService.getForm().otherActPeople == null) {
            this.form.get('ippAuthorizedTradePersonNumber').setValue(3);
            this.formService.setFormProperty('otherActPeople', 3);
        }

        this.form.get('ippAuthorizedTradePersonNumber').valueChanges.subscribe(value => {
            if (value === '') {
                this.form.get('ippAuthorizedTradePersonNumber').setValue(3);
                this.formService.setFormProperty('otherActPeople', 3);
            }
        });
    }
}
