<div [formGroup]="formGroup">
    <fieldset>
        <legend class="sr-only">{{'TRANSFER_FORM_ACCOUNT_TITLE'|translate}}</legend>
        <app-odc-field
            [type]="constants.FIELD_TYPES.TEXT"
            [form]="formGroup"
            [submitted]="isNgFormSubmitted()"
            [label]="'ACCOUNT_FORM_ACCOUNT_NUMBER' | translate"
            [id]="accountNumber + index"
            [name]="accountNumber + getSuffix()"
            [controlName]="accountNumber"
            [path]="accountNumber"
            [nameSuffix]="index"
            [binding]="bindPath + accountNumber"
            [attr]="{maxlength: 12}"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.SELECT"
            [form]="formGroup"
            [submitted]="isNgFormSubmitted()"
            [label]="'ACCOUNT_FORM_ACCOUNT_TYPE' | translate"
            [id]="accountType + index"
            [name]="accountType + getSuffix()"
            [controlName]="accountType"
            [path]="accountType"
            [nameSuffix]="index"
            [binding]="bindPath + accountType"
            [list]="accountTypeList"
            [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
        ></app-odc-field>

        <app-odc-field
            [type]="constants.FIELD_TYPES.RADIO"
            [form]="formGroup"
            [submitted]="isNgFormSubmitted()"
            [label]="'ACCOUNT_FORM_ACCOUNT_CURRENCY' | translate"
            [choices]="[{label: 'CURRENCY_CAD' | translate, value: constants.CURRENCY_CAD}, {label: 'CURRENCY_USD' | translate, value: constants.CURRENCY_USD}]"
            [id]="accountCurrency + index"
            [name]="accountCurrency + getSuffix()"
            [controlName]="accountCurrency"
            [path]="accountCurrency"
            [nameSuffix]="index"
            [binding]="bindPath + accountCurrency"
        ></app-odc-field>
    </fieldset>
</div>
