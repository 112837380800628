export class Branch {

    private static instance: Branch = null;

    public act: string;
    public noPdo: string;
    public typeMembre: string;
    public noInst: string;
    public noTransit: string;
    public advLstNam: string;
    public advFstNam: string;
    public transitOrig: string;
    public instOrig: string;
    public langue: string;
    public codeUsager: string;

    static create(act: string, noPdo: string, typeMembre: string, noInst: string,
                  noTransit: string, advLstNam: string, advFstNam: string,
                  transitOrig: string, instOrig: string, langue: string, codeUsager: string) {
        Branch.instance = new Branch();
        Branch.instance.act = act;
        Branch.instance.noPdo = noPdo;
        Branch.instance.typeMembre = typeMembre;
        Branch.instance.noInst = noInst;
        Branch.instance.noTransit = noTransit;
        Branch.instance.advLstNam = advLstNam;
        Branch.instance.advFstNam = advFstNam;
        Branch.instance.transitOrig = transitOrig;
        Branch.instance.instOrig = instOrig;
        Branch.instance.langue = langue;
        Branch.instance.codeUsager = codeUsager;
    }

    static getInstance(): Branch {
        return Branch.instance;
    }

    static clear() {
        Branch.instance = null;
    }

    public getQueryString(): string {
        return this.getQueryKeyValue('act') +
            '&' + this.getQueryKeyValue('typeMembre') +
            '&' + this.getQueryKeyValue('noPdo') +
            '&' + this.getQueryKeyValue('noInst') +
            '&' + this.getQueryKeyValue('noTransit') +
            '&' + this.getQueryKeyValue('codeUsager') +
            '&' + this.getQueryKeyValue('instOrig') +
            '&' + this.getQueryKeyValue('transitOrig') +
            '&' + this.getQueryKeyValue('advLstNam') +
            '&' + this.getQueryKeyValue('advFstNam') +
            '&' + this.getQueryKeyValue('langue');
    }

    private getQueryKeyValue(key: string): string {
        const value = this.getQueryStringValue(this[key]);
        if (null !== value) {
            return key + '=' + value;
        }

        return '';
    }

    private getQueryStringValue(value: string): string {
        if (value === null) {
            return null;
        } else {
            return encodeURIComponent(value);
        }
    }
}
