import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {FormArray, FormBuilder} from '@angular/forms';
import {FormService} from '../../components/services/form.service';
import {ListModelService} from '../../components/services/list-model.service';
import {NavigationService} from '../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {AttachedDocument, WebException} from '../../components/models';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {FileValidatorService} from '../../components/services/file-validator.service';
import {Functions} from '../../components/utils/functions';

declare let $: any;

@Component({
    selector: 'app-transmission-attachments-lba-caisse',
    templateUrl: './transmission-attachments-lba-caisse.component.html'
})
export class TransmissionAttachmentsLbaCaisseComponent extends BaseNavComponent implements OnInit, AfterViewInit {

    FIRST_ATTACHED_DOCUMENT_INDEX = 0;
    ATTACHED_DOCUMENT_CHECK_SAMPLE_INDEX = 1;

    userIdentityFile: string;
    userSpecimenFile: string;
    accountStatement: string;
    loading: boolean;
    showProvinceFirstIdentityFile: boolean;
    showCountryFirstIdentityFile: boolean;
    showDateIssuanceExpiryFirstIdentityFileField: boolean;
    isDriverLicenseFirstIdentityFileSelected: boolean;
    userIdentityCodeCompleteList: HtmlSelectKV[];
    userIdentityCodeList: HtmlSelectKV[];
    provincesList: HtmlSelectKV[] = [];
    provincesListComplete: HtmlSelectKV[] = [];
    provincesListForHealthInsuranceCard: HtmlSelectKV[] = [];
    countries: HtmlSelectKV[] = [];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private fileValidatorService: FileValidatorService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.formService.setTransmitted(false);
        this.navigationService.emitIsBackDisabled(true);

        this.form = this.fb.group({

            firstIdentityInfos: this.fb.group({
                firstIdentityCode: [this.formService.getForm().requesters[this.requesterIndex].userIdentityCode],
                firstIdentityIDNumber: [this.formService.getForm().requesters[this.requesterIndex].userIdentityIDNumber],
                firstIdentityExpirationDate: [this.formService.getForm().requesters[this.requesterIndex].userIdentityExpirationDate],
                firstIdentityProvince: [this.formService.getForm().requesters[this.requesterIndex].userIdentityProvince],
                firstIdentityCountry: [this.formService.getForm().requesters[this.requesterIndex].userIdentityCountry]
            }),

            attachedDocuments: this.fb.array([], VmdValidators.attachedDocumentUnique()),
        });

        this.initLists();

        this.attachValuesChangesOnFirstIdentityCode();

        this.showProvinceFirstIdentityFile = this.isProvincialDocumentType(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.showCountryFirstIdentityFile = this.isNationalDocumentType(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.showDateIssuanceExpiryFirstIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(this.form.get('firstIdentityInfos.firstIdentityCode').value);
        this.isDriverLicenseFirstIdentityFileSelected = this.form.get('firstIdentityInfos.firstIdentityCode').value === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;
    }

    setCountries(list: HtmlSelectKV[]): void {
        this.countries = list;
    }

    setProvincesLists(list: HtmlSelectKV[]) {
        this.provincesListComplete = list;
        this.provincesListForHealthInsuranceCard = [];
        for (const item of list) {
            if (!this.isNotProvinceForHealthInsurance(item.itemValue)) {
                this.provincesListForHealthInsuranceCard.push(item);
            }
        }
        this.chooseProvincesList();
    }

    chooseProvincesList(): void {
        if (this.form.get('firstIdentityInfos.firstIdentityCode').value === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD) {
            this.provincesList = this.provincesListForHealthInsuranceCard;
        } else {
            this.provincesList = this.provincesListComplete;
        }
    }

    isNotProvinceForHealthInsurance(province: string): boolean {
        for (const item of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (province === item) {
                return true;
            }
        }
        return false;
    }

    getAttachedDocumentsControl(): FormArray {
        return this.form.get('attachedDocuments') as FormArray;
    }

    setTypeDocumentLists(list: HtmlSelectKV[]) {
        this.userIdentityCodeCompleteList = list;
        this.userIdentityCodeList = list;

        this.setUserIdentityCodeFirstList(this.constants.USER_IDENTITY_CODE_BANK_STATEMENT,
            this.constants.USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT,
            this.constants.USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT);

    }

    setUserIdentityCodeFirstList(...filteredValues: string[]) {
        this.userIdentityCodeList = this.userIdentityCodeCompleteList;

        if (this.isHealthInsuranceException()) {
            this.userIdentityCodeList = this.userIdentityCodeList.filter(item => item.itemValue !== this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD);
        }

        this.userIdentityCodeList = this.userIdentityCodeList.filter(item => !Functions.stringListHasValue(filteredValues, item.itemValue));
    }

    isHealthInsuranceException(): boolean {
        for (const province of this.constants.PROVINCES_HEALTH_INSURANCE_EXCEPTION) {
            if (this.formService.getForm().requesters[this.requesterIndex].userAddressProv === province) {
                return true;
            }
        }
        return false;
    }

    collectIdentityFiles(): AttachedDocument[] {
        const identityFileList: AttachedDocument[] = [];

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            let name = control.get('attachedDocumentName').value;
            if (control.get('attachedDocument').value && control.get('attachedDocumentContent').value) {
                const accountStatement = new AttachedDocument();
                accountStatement.name = control.get('attachedDocument').value;
                accountStatement.content = control.get('attachedDocumentContent').value;
                accountStatement.validation.contentHash = control.get('attachedDocumentContentHash').value;
                accountStatement.id = name;
                identityFileList.push(accountStatement);
            }

            name = control.get('attachedDocumentNameBack').value;
            if (control.get('attachedDocumentBack').value && control.get('attachedDocumentContentBack').value) {
                const accountStatement = new AttachedDocument();
                accountStatement.name = control.get('attachedDocumentBack').value;
                accountStatement.content = control.get('attachedDocumentContentBack').value;
                accountStatement.validation.contentHash = control.get('attachedDocumentContentHashBack').value;
                accountStatement.id = name;
                identityFileList.push(accountStatement);
            }
        });

        return identityFileList;
    }

    submit() {
        let identityFileList: AttachedDocument[] = [];
        if (this.form.valid) {
            identityFileList = this.collectIdentityFiles();
        }

        if (identityFileList.length) {
            this.formSubmitted = true;
            this.markAsTouched(this.form);
            this.loading = true;
            this.fileValidatorService.validateFile(identityFileList, this.fileValidatorService.getVerifyIdentityFilePath()).subscribe(data => {
                if (this.form.valid) {
                    this.loading = false;
                    this.submitForm();
                }
            }, (errors: WebException) => {
                this.loading = false;

                if (errors.listWarning && errors.listWarning.length) {
                    for (const warning of errors.listWarning) {

                        const path = this.getAttachedDocumentsPath(warning.id);

                        if (warning.message === 'file.infected') {
                            this.form.get(path).setErrors({fileInfected: true});
                        } else if (warning.message === 'antivirus.notavailable') {
                            this.form.get(path).setErrors({antivirusNotAvailable: true});
                        } else if (warning.message === 'file.weight.invalid') {
                            this.form.get(path).setErrors({weightInvalid: true});
                        } else if (warning.message === 'file.pdf.encrypted') {
                            this.form.get(path).setErrors({fileEncrypted: true});
                        } else if (warning.message === 'file.extension.invalid') {
                            this.form.get(path).setErrors({invalidFormat: true});
                        } else if (warning.message === 'file.invalid.document') {
                            this.form.get(path).setErrors({invalidDocument: true});
                        }

                    }
                }
                this.focusOnErrorsList();
            });
        } else {
            this.submitForm();
        }
    }

    getAttachedDocumentsPath(name: string): string {
        let path = null;

        this.getAttachedDocumentsControl().controls.forEach((control, index) => {
            if (control.get('attachedDocumentName').value === name) {
                path = 'attachedDocuments.' + index + '.attachedDocument';
            }

            if (control.get('attachedDocumentNameBack').value === name) {
                path = 'attachedDocuments.' + index + '.attachedDocumentBack';
            }
        });

        return path;
    }

    private initLists() {
        let identityPaperListId = this.determinateIdentityPaperListId();
        this.listModelService.getListModel(identityPaperListId, (data: HtmlSelectKV[]) => this.setTypeDocumentLists(data));
        this.listModelService.getListModel('provincesList', data => this.setProvincesLists(data));
        this.listModelService.getListModel('paysList', data => this.setCountries(data));
    }

    private attachValuesChangesOnFirstIdentityCode() {
        this.form.get('firstIdentityInfos.firstIdentityCode').valueChanges.subscribe(code => {

            this.showProvinceFirstIdentityFile = this.isProvincialDocumentType(code);
            this.showCountryFirstIdentityFile = this.isNationalDocumentType(code);
            this.showDateIssuanceExpiryFirstIdentityFileField = this.documentTypeHasIssuanceOrExpirationDate(code);

            this.isDriverLicenseFirstIdentityFileSelected = code === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE;
            if (!this.isDriverLicenseFirstIdentityFileSelected) {
                this.form.get('attachedDocuments.' + this.FIRST_ATTACHED_DOCUMENT_INDEX + '.attachedDocumentDifferentAddress').setValue(false);
            }
        });
    }

    private isProvincialDocumentType(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_DRIVER_LICENSE
            || code === this.constants.USER_IDENTITY_CODE_HEALTH_INSURANCE_CARD
            || code === this.constants.USER_IDENTITY_CODE_PROVINCIAL_TERRITORIAL_CARD
            || code === this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE;
    }

    private isNationalDocumentType(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_PASSPORT
            || code === this.constants.USER_IDENTITY_CODE_CPP_ACCOUNT_STATEMENT;
    }

    private documentTypeHasIssuanceOrExpirationDate(code: string): boolean {
        return code === this.constants.USER_IDENTITY_CODE_BIRTH_CERTIFICATE
            || code === this.constants.USER_IDENTITY_CODE_INVEST_ACCT_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_PUBLIC_UTILITY_BILL
            || code === this.constants.USER_IDENTITY_CODE_BANK_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_CREDIT_CARD_STATEMENT
            || code === this.constants.USER_IDENTITY_CODE_LOAN_ACCOUNT_STATEMENT;
    }

    private determinateIdentityPaperListId() {
        if (this.isGpdOrSfd()) {
            return 'identityPaperGpdList';
        }

        return 'identityPaperDclWebList';
    }
}
