import {Component, Inject, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../components/services/form.service';
import {SessionService} from '../../components/services/session.service';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {ListModelService} from '../../components/services/list-model.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {Requester} from '../../components/models/requester';
import {ModalService} from '../../components/services/modal.service';

@Component({
    selector: 'app-client-type',
    templateUrl: './client-type.component.html'
})
export class ClientTypeComponent extends BaseNavComponent implements OnInit {

    otherAccountList: HtmlSelectKV[];
    isLoading = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private sessionService: SessionService,
                private listModelService: ListModelService,
                private modalService: ModalService,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.subscriptions.push(this.modalService.closeModalAlertEmitted.subscribe(() => {
            super.submitForm();
        }));

        if (!this.formService.isCaisseContext()) {
            this.formService.initForm({});

            if (!this.config.FROM_EXISTING_REQUEST) {
                this.sessionService.getNewToken().subscribe((data: any) => {

                    this.sessionService.beginSession(data.payload);
                });
            }
        }

        this.formService.setFormProperty('repId', this.config.AGENT_ID);
        this.formService.setFormProperty('pleinEx', this.config.IS_PLEIN_EX);
        this.formService.setFormProperty('gpd', this.config.IS_GPD);
        this.formService.setFormProperty('sfd', this.config.IS_SFD);
        this.formService.setFormProperty('ssd', this.config.IS_SSD);
        sessionStorage.setItem(VmdConstants.ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY, '');

        this.form = this.fb.group({
            clientType: [this.initClientTypeValue(), Validators.required],
            otherAccount: this.fb.group({
                otherAccountType: [this.formService.getForm().cltType, Validators.required]
            })
        });

        this.listModelService.getListModel(this.isPleinEx() ? 'otherAccountsListPleinEx' : 'otherAccountsList', data => this.setOtherAccountList(data));

        this.subscriptions.push(this.form.get('clientType').valueChanges.subscribe(value => {
            if (value === this.constants.CLT_TYPE_INDIVIDU || value === this.constants.CLT_TYPE_CONJOINT) {
                this.formService.setFormProperty('cltType', value);
            } else if (value === this.constants.CLT_TYPE_OTHER && (this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU || this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT)) {
                this.form.get('otherAccount.otherAccountType').setValue(null);
                this.formService.setFormProperty('cltType', null);
            }
            this.updateControlEnabled(this.form.get('otherAccount'), this.form.get('clientType').value === this.constants.CLT_TYPE_OTHER);
            return true;
        }));
        this.updateControlEnabled(this.form.get('otherAccount'), this.form.get('clientType').value === this.constants.CLT_TYPE_OTHER);
    }

    private initClientTypeValue(): string {

        return this.isOtherChoice() ? this.constants.CLT_TYPE_OTHER : this.formService.getForm().cltType;
    }

    private isOtherChoice(): boolean {
        return this.formService.getForm().cltType !== null
            && this.formService.getForm().cltType !== ''
            && this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU
            && this.formService.getForm().cltType !== this.constants.CLT_TYPE_CONJOINT;
    }

    personalAccountChoice(): boolean {
        this.form.get('clientType').setValue(this.constants.CLT_TYPE_INDIVIDU);
        if (this.isGpdOrSfd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.PERSONAL_ACCOUNT_GPD_ROUTES_CONFIG_KEY);
        } else if (this.isSsd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.PERSONAL_ACCOUNT_SSD_ROUTES_CONFIG_KEY);
        } else if (this.isPleinEx()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.PERSONAL_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        } else {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.PERSONAL_ACCOUNT_ROUTES_CONFIG_KEY);
        }
        return true;
    }

    jointAccountChoice() {
        this.form.get('clientType').setValue(this.constants.CLT_TYPE_CONJOINT);
        if (this.isGpdOrSfd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.JOINT_ACCOUNT_GPD_ROUTES_CONFIG_KEY);
        } else if (this.isSsd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.JOINT_ACCOUNT_SSD_ROUTES_CONFIG_KEY);
        } else if (this.isPleinEx()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.JOINT_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        } else {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.JOINT_ACCOUNT_ROUTES_CONFIG_KEY);
        }
    }

    otherAccountChoice() {
        this.form.get('clientType').setValue(this.constants.CLT_TYPE_OTHER);
        if (this.isGpdOrSfd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY);
        } else if (this.isSsd()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.OTHER_ACCOUNT_SSD_ROUTES_CONFIG_KEY);
        } else if (this.isPleinEx()) {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG_KEY);
        } else {
            this.navigationService.emitStepRoutesConfigKeys(VmdConstants.OTHER_ACCOUNT_ROUTES_CONFIG_KEY);
        }
    }

    otherAccountItemChoice() {
        this.formService.setFormProperty('cltType', this.form.get('otherAccount.otherAccountType').value);
    }

    private setOtherAccountList(data): void {
        this.otherAccountList = data;
        this.form.get('otherAccount.otherAccountType').setValue(this.formService.getForm().cltType);
    }

    showBack() {

        if (this.isPleinEx()) {
            return false;
        }

        return super.showBack();
    }

    navNext() {

        if (this.isPleinEx()) {

            if (this.formService.getForm().newExistAct === null) {
                this.formService.setFormProperty('newExistAct', VmdConstants.ACCOUNT_NEW_CLIENT);
            }

            if (this.isPersonalAccount()) {

                this.setDefaultFormPropertyPersonalAccount();
            }

            this.setDefaultPleinExAccount();
        }

        super.navNext();
    }

    private isPersonalAccount(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU;
    }

    private setDefaultFormPropertyPersonalAccount() {

        if (this.formService.getForm().actType === null) {
            this.formService.setFormProperty('actType', VmdConstants.ACCOUNT_TYPE_CASH);
        }

        if (this.formService.getForm().currency === null) {
            this.formService.setFormProperty('currency', VmdConstants.CURRENCY_CAD);
        }
    }

    private setDefaultPleinExAccount() {

        const requesters: Requester[] = this.formService.getForm().requesters;

        if (requesters && requesters.length > 0 && requesters[0].formLang === null) {

            this.formService.setFormProperty('requesters.0.formLang', VmdConstants.LANG_FR);
        }
    }

    submitForm() {
        this.formService.setMode(VmdConstants.MODES.PRINT);
        super.submitForm();
    }
}
