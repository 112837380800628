import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../../../../components/services/form.service';
import {ModalService} from '../../../../../components/services/modal.service';
import {WebException} from '../../../../../components/models';
import {ProfileService} from '../../../../../components/services/profile.service';
import {RecaptchaComponent} from '../../../../login/recaptcha.component';
import {Message} from '../../../../../components/models/message';
import {ErrorCodeConstants} from '../../../../../components/constants/error-code-constants';
import {ModalSelectors} from '../../../../../components/constants/modal-selectors';

declare let $: any;

@Component({
    selector: 'app-modal-forgot-password',
    templateUrl: './modal-forgot-password.component.html'
})
export class ModalForgotPasswordComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;
    @ViewChild('httpError') httpErrorDiv!: ElementRef;

    requesterEmail: string;
    loading = false;
    emailSent: boolean = false;
    httpErrors: any;
    renderRecaptcha: boolean = false;
    displayResetPasswordTemplate: boolean = true;
    displayProfileNotFoundTemplate: boolean = false;

    constructor(public translate: TranslateService,
                private formService: FormService,
                private modalService: ModalService,
                private profileService: ProfileService) {
    }

    ngOnInit(): void {

        this.modalService.showForgotPassword.subscribe(() => {
            this.resetModal();
            this.requesterEmail = this.formService.getForm().requesters[0].userEmail;
            this.renderRecaptcha = true;
        });
    }

    submit() {
        this.httpErrors = null;
        this.loading = true;

        this.profileService.forgotPassword(this.translate.currentLang, this.requesterEmail, this.recaptcha.getRecaptchaToken()).subscribe(() => {

                this.emailSent = true;
            },
            (error: WebException) => {
                const warningMessage: Message = error.listWarning[0];
                if (warningMessage !== null && warningMessage.message === ErrorCodeConstants.CLIENT_PROFILE_NOT_FOUND) {
                    this.displayResetPasswordTemplate = false;
                    this.displayProfileNotFoundTemplate = true;
                } else {
                    this.httpErrors = error;
                }
            })
            .add(() => {
                this.loading = false;
                this.resetRecaptcha();
            });
    }

    resetModal() {
        this.httpErrors = null;
        this.emailSent = false;
        this.displayResetPasswordTemplate = true;
        this.displayProfileNotFoundTemplate = false;
        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }

    redirectToAuthModal() {
        this.closeModal();
        this.modalService.emitShowSaveAuthForm();
        this.modalService.openModal(null, null, ModalSelectors.AUTH_MODAL);
    }

    closeModal() {
        if (!$(ModalSelectors.FORGOT_PASSWORD_MODAL).length) {
            return;
        }
        $(ModalSelectors.FORGOT_PASSWORD_MODAL).modal('hide');
    }
}
