export const OTHER_ACCOUNT_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    },
    {

        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PATH',
                component: 'AssociationRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PATH',
                component: 'ClubRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PATH',
                component: 'CooperativeRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PATH',
                component: 'CorporationRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PATH',
                component: 'CoUniqueRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PATH',
                component: 'EnfantEsQualitesRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PATH',
                component: 'EnfantRefRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PATH',
                component: 'OsblRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PATH',
                component: 'FiducieRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PATH',
                component: 'SencRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PATH',
                component: 'SocCommRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PATH',
                component: 'SuccessionRelatedQuestionsComponent'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PATH',
                component: 'EntitiesQuestionsComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PATH',
                component: 'PersonalInformationComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PATH',
                component: 'TransferRequestChoiceComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSMISSION',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PATH',
                component: 'TransmissionAdditionalFormsComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: false,
        label: 'ODC_NAV_STEP_CONFIRMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_OTHER_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationComponent'
            }, {
                label: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION',
                path: 'ODC_NAV_STEP_CONFIRMATION_CONFIRMATION_CAISSE_OTHER_ACCOUNT_PATH',
                aside: 'ASIDE_CONFIRMATION',
                component: 'ConfirmationCaisseComponent',
                disable: true,
                hideAsideConsult: true
            }
        ]
    }
];
