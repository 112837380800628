<app-odc-field *ngIf="this.config.TOGGLE_PDF_LANG && !this.isPleinExRequest"
               [type]="constants.FIELD_TYPES.RADIO"
               [form]="form"
               [submitted]="submitted"
               [label]="'ACCOUNT_TYPE_RELATED_QUESTIONS_RES_QC' | translate"
               [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
               [id]="'resQc'"
               [name]="'resQc'"
               [path]="'resQc'"
               [binding]="'resQc'"
></app-odc-field>
