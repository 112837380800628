import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {ApiEndpoints} from "../enums/api-endpoints.enum";

@Injectable({
    providedIn: 'root'
})
export class PersonalInformationService {

    constructor(
        private http: HttpClient,
        @Inject(ODC_CONFIG) private config: IOdcConfig) {
    }

    public obtenirInformationPartiePrenante(encryptedBranchUrl: string): Observable<any> {
        return this.http.post(ApiEndpoints.InternalPersonalInformation, JSON.stringify(encryptedBranchUrl));
    }

    public retrieveClientData(encryptedUrl: string): Observable<any> {
        const params = new HttpParams().set('encryptedUrl', encryptedUrl);
        return this.http.get(ApiEndpoints.InternalClientInformation, {params});
    }
}
