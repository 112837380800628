<div [formGroup]="formGroup" class="separator-top">
    <div [formGroup]="formGroup" class="separator-top">

        <h2>
            {{'ODC_BENEFICIARY_TITLE' | translate}} <span *ngIf="isFamilyAccount()">{{(index) + 1}}</span>
        </h2>
        <fieldset>
            <legend class="sr-only">{{'ODC_BENEFICIARY_TITLE' | translate}}</legend>

            <!--Gender-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('beneficiaryGroup.' + index)"
                [submitted]="submitted"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
                [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
                [id]="gender + index"
                [name]="gender + '_' + index"
                [controlName]="gender"
                [path]="gender"
                [binding]="getBindPath() + gender"
            ></app-odc-field>


            <!--firstname & lastname--------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <!--spouseFirstName --------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                        [id]="firstName + index"
                        [name]="firstName + '_' + index"
                        [controlName]="firstName"
                        [path]="firstName"
                        [binding]="getBindPath() + firstName"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <!--spouseLastName--------------------------------->
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                        [id]="lastName + index"
                        [name]="lastName + '_' + index"
                        [controlName]="lastName"
                        [path]="lastName"
                        [binding]="getBindPath() + lastName"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
            </div>

            <!--DOB & SIN-------------------------------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_DOB' | translate"
                        [helpText]="'ODC_STEP_PERSONAL_INFORMATION_DOB_HELP' | translate"
                        [id]="DOB + index"
                        [name]="DOB + '_' + index"
                        [controlName]="DOB"
                        [path]="DOB"
                        [binding]="getBindPath() + DOB"
                        [attr]="{maxlength: 10}"
                        [dateFormat]="true"
                        [templateRefFr]="helpDOB"
                        [templateRefEn]="helpDOB"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [autoComplete]="'off'"
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_BENEFICIARY_SIN_PLEINEX' | translate"
                        [id]="SIN + index"
                        [name]="SIN + '_' + index"
                        [controlName]="SIN"
                        [path]="SIN"
                        [binding]="getBindPath() + SIN"
                        [attr]="{maxlength: 11}"
                        [sinFormat]="true"
                        [templateRefFr]="helpSIN"
                        [templateRefEn]="helpSIN"
                    ></app-odc-field>
                </div>
            </div>

            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('beneficiaryGroup.' + index)"
                [submitted]="submitted"
                [label]="'ODC_BENEFICIARY_RESP_BENEFICIARY_ADDRESS_QUESTION' | translate"
                [choices]="[{label: 'GLOBAL_NO' | translate, value: false}, {label: 'GLOBAL_YES' | translate, value: true}]"
                [id]="beneficiaryAddressSameAsSubscriber + index"
                [name]="beneficiaryAddressSameAsSubscriber + '_'+ index"
                [controlName]="beneficiaryAddressSameAsSubscriber"
                [path]="beneficiaryAddressSameAsSubscriber"
                [binding]="getBindPath() + beneficiaryAddressSameAsSubscriber"
            ></app-odc-field>

            <!--Address--------------------------------------------------------------------------->

            <div *ngIf="showAddress">
                <div class="grid-12">
                    <app-address-form
                        [legend]="'ODC_STEP_ACCOUNT_HOLDER_INFORMATION_ADDRESS_TITLE'|translate"
                        [form]="form.get(groupName +'.' + index + '.' + addressGroupName)"
                        [index]="index"
                        [submitted]="submitted"
                        [bindPath]="getBindPath()"
                        [path]="getFormPath()"
                        [street]="addressStreet"
                        [unit]="addressUnit"
                        [city]="addressCity"
                        [province]="addressProv"
                        [postalcode]="addressPostalCode"
                        [country]="addressCountry"
                    ></app-address-form>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.SELECT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_BENEFICIARY_PARENT_LINK' | translate"
                        [id]="parentLink + index"
                        [name]="parentLink + '_' + index"
                        [controlName]="parentLink"
                        [path]="parentLink"
                        [list]="parentLinks"
                        [defaultOptionLabel]="'GLOBAL_SELECT' | translate"
                        [binding]="getBindPath() + parentLink"
                    ></app-odc-field>
                </div>
                <div class="col-md-12" *ngIf="isFamilyAccount()">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_BENEFICIARY_DISTRIBUTION_RATIO' | translate"
                        [id]="distributionRatio + index"
                        [name]="distributionRatio + '_'+ index"
                        [controlName]="distributionRatio"
                        [path]="distributionRatio"
                        [binding]="getBindPath() + distributionRatio"
                        [integerFormat]="true"
                        [attr]="{maxlength: 3}"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showCaregiver">
                <div class="row">
                    <div class="col-md-12">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('beneficiaryGroup.' + index)"
                            [submitted]="submitted"
                            [label]="'ODC_BENEFICIARY_PRIMARY_CAREGIVER_FIRST_NAME' | translate"
                            [id]="caregiverParentFirstName + index"
                            [name]="caregiverParentFirstName + '_'+ index"
                            [controlName]="caregiverParentFirstName"
                            [path]="caregiverParentFirstName"
                            [binding]="getBindPath() + caregiverParentFirstName"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>
                    </div>
                    <div class="col-md-12">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('beneficiaryGroup.' + index)"
                            [submitted]="submitted"
                            [label]="'ODC_BENEFICIARY_PRIMARY_CAREGIVER_LAST_NAME' | translate"
                            [id]="caregiverParentLastName + index"
                            [name]="caregiverParentLastName + '_'+ index"
                            [controlName]="caregiverParentLastName"
                            [path]="caregiverParentLastName"
                            [binding]="getBindPath() + caregiverParentLastName"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <app-odc-field
                            [autoComplete]="'off'"
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('beneficiaryGroup.' + index)"
                            [submitted]="submitted"
                            [label]="'ODC_BENEFICIARY_PRIMARY_CAREGIVER_SIN' | translate"
                            [id]="caregiverParentSin + index"
                            [name]="caregiverParentSin + '_'+ index"
                            [controlName]="caregiverParentSin"
                            [path]="caregiverParentSin"
                            [binding]="getBindPath() + caregiverParentSin"
                            [attr]="{maxlength: 11}"
                            [sinFormat]="true"
                        ></app-odc-field>
                    </div>
                </div>
            </div>

            <h3>
                {{'ODC_BENEFICIARY_CUSTODIAL_PARENT_IDENTIFICATION' | translate}}
            </h3>

            <!--Gender-->
            <app-odc-field
                [type]="constants.FIELD_TYPES.RADIO"
                [form]="form.get('beneficiaryGroup.' + index)"
                [submitted]="submitted"
                [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
                [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
                [id]="custodialParentGender + index"
                [name]="custodialParentGender + '_'+ index"
                [controlName]="custodialParentGender"
                [path]="custodialParentGender"
                [binding]="getBindPath() + custodialParentGender"
            ></app-odc-field>

            <!--firstname & lastname--------------------------------->
            <div class="row">
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                        [id]="custodialParentFirstName + index"
                        [name]="custodialParentFirstName + '_'+ index"
                        [controlName]="custodialParentFirstName"
                        [path]="custodialParentFirstName"
                        [binding]="getBindPath() + custodialParentFirstName"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
                <div class="col-md-12">
                    <app-odc-field
                        [type]="constants.FIELD_TYPES.TEXT"
                        [form]="form.get('beneficiaryGroup.' + index)"
                        [submitted]="submitted"
                        [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                        [id]="custodialParentLastName + index"
                        [name]="custodialParentLastName + '_'+ index"
                        [controlName]="custodialParentLastName"
                        [path]="custodialParentLastName"
                        [binding]="getBindPath() + custodialParentLastName"
                        [attr]="{maxlength: 32}"
                    ></app-odc-field>
                </div>
            </div>

            <div *ngIf="showCustodialParentSecFields">
                <!--Gender-->
                <app-odc-field
                    [type]="constants.FIELD_TYPES.RADIO"
                    [form]="form.get('beneficiaryGroup.' + index)"
                    [submitted]="submitted"
                    [label]="'ODC_STEP_PERSONAL_INFORMATION_GENDER' | translate"
                    [choices]="[{label: 'ODC_STEP_PERSONAL_INFORMATION_MRS' | translate, value: constants.MRS}, {label: 'ODC_STEP_PERSONAL_INFORMATION_MR' | translate, value: constants.MR}]"
                    [id]="custodialParentSecGender + index"
                    [name]="custodialParentSecGender + '_'+ index"
                    [controlName]="custodialParentSecGender"
                    [path]="custodialParentSecGender"
                    [binding]="getBindPath() + custodialParentSecGender"
                ></app-odc-field>

                <!--firstname & lastname--------------------------------->
                <div class="row">
                    <div class="col-md-12">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('beneficiaryGroup.' + index)"
                            [submitted]="submitted"
                            [label]="'ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME' | translate"
                            [id]="custodialParentSecFirstName + index"
                            [name]="custodialParentSecFirstName + '_'+ index"
                            [controlName]="custodialParentSecFirstName"
                            [path]="custodialParentSecFirstName"
                            [binding]="getBindPath() + custodialParentSecFirstName"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>
                    </div>
                    <div class="col-md-12">
                        <app-odc-field
                            [type]="constants.FIELD_TYPES.TEXT"
                            [form]="form.get('beneficiaryGroup.' + index)"
                            [submitted]="submitted"
                            [label]="'ODC_STEP_PERSONAL_INFORMATION_LAST_NAME' | translate"
                            [id]="custodialParentSecLastName + index"
                            [name]="custodialParentSecLastName + '_'+ index"
                            [controlName]="custodialParentSecLastName"
                            [path]="custodialParentSecLastName"
                            [binding]="getBindPath() + custodialParentSecLastName"
                            [attr]="{maxlength: 32}"
                        ></app-odc-field>
                    </div>
                </div>

                <div class="m-b-1">
                    <ng-container *ngIf="showCustodialParentSecFields">
                        <a href="javascript:void(0)" class="lien-sans-soulignement"
                           (click)="deleteCustodialParentSec()">
                            <span class="icon-close rouge"></span>
                            {{'ODC_STEP_PARENT_DELETE'|translate}}
                        </a>
                    </ng-container>
                </div>
            </div>

            <div class="m-b-1">
                <ng-container *ngIf="!showCustodialParentSecFields">
                    <a href="javascript:void(0)" class="lien-sans-soulignement" (click)="addCustodialParentSec()">
                        <span class="icon-circle-plus"></span>
                        {{'ODC_STEP_PARENT_ADD'|translate}}
                    </a>
                </ng-container>
            </div>

            <div>
                <label class="c-input c-checkbox" [class.disabled]="form.get('beneficiaryGroup.' + index).get('beneficiaryRespRequestCLBForResp').disabled">
                    <input type="checkbox"
                           [name]="requestCLBForResp + '_'+ index"
                           [id]="requestCLBForResp + index"
                           formControlName="beneficiaryRespRequestCLBForResp"
                           [appFormBinding]="getBindPath() + requestCLBForResp">
                    <span class="c-indicator"></span>
                    {{ 'ODC_BENEFICIARY_CLB_FOR_RESP' | translate }}
                </label>
            </div>


        </fieldset>


    </div>


    <ng-template #helpSIN>
        <h2>{{'HELP_SIN_TITLE' | translate}}</h2>
        <p>{{'HELP_SIN_BODY' | translate}}</p>
    </ng-template>

    <ng-template #helpDOB>
        <h2>{{'HELP_DOB_TITLE' | translate}}</h2>
        <p>{{'HELP_DOB_BODY' | translate}}</p>
    </ng-template>
