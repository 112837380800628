<form (submit)="submitForm()" *ngIf="!submitted" [formGroup]="form" ngForm novalidate>

    <div *ngIf="isUpdateAccount() && isPleinEx()" class="container-fluid m-b-2">
        <h2> {{ 'ODC_STEP_SUBMISSION_TITLE' | translate }} </h2>

        <app-error-list [form]="form" [ngFormRef]="ngForm"></app-error-list>

        <!--Gender-->
        <app-odc-field
            [binding]="'advisorInvestmentMeetingCustomer.title'"
            [choices]="[{label: 'ODC_STEP_SUBMISSION_MRS_ADVISOR' | translate, value: constants.MRS},
                {label: 'ODC_STEP_SUBMISSION_MR_ADVISOR' | translate, value: constants.MR}]"
            [form]="form"
            [id]="'advisorInvestmentTitle'"
            [label]="'ODC_STEP_SUBMISSION_TITLE_ADVISOR' | translate"
            [name]="'advisorInvestmentTitle'"
            [path]="'advisorInvestmentTitle'"
            [readonlyProperty]="'advisorInvestmentTitle'"
            [submitted]="isFormSubmitted()"
            [type]="constants.FIELD_TYPES.RADIO"
        ></app-odc-field>

        <!--firstname & lastname--------------------------------->
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [attr]="{maxlength: 32}"
                    [binding]="'advisorInvestmentMeetingCustomer.firstName'"
                    [form]="form"
                    [id]="'advisorInvestmentFirstName'"
                    [label]="'ODC_STEP_SUBMISSION_FIRST_NAME_ADVISOR' | translate"
                    [nameFormat]="true"
                    [name]="'advisorInvestmentFirstName'"
                    [path]="'advisorInvestmentFirstName'"
                    [readonlyProperty]="'advisorInvestmentFirstName'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </div>
            <div class="col-md-12">
                <app-odc-field
                    [attr]="{maxlength: 32}"
                    [binding]="'advisorInvestmentMeetingCustomer.lastName'"
                    [form]="form"
                    [id]="'advisorInvestmentLastName'"
                    [label]="'ODC_STEP_SUBMISSION_LAST_NAME_ADVISOR' | translate"
                    [nameFormat]="true"
                    [name]="'advisorInvestmentLastName'"
                    [path]="'advisorInvestmentLastName'"
                    [readonlyProperty]="'advisorInvestmentLastName'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                ></app-odc-field>
            </div>
        </div>

        <!--Date de la rencontre-------------------------------------------------------->
        <div class="row">
            <div class="col-md-12">
                <app-odc-field
                    [beforeShowDay]="beforeShowDay"
                    [binding]="'advisorInvestmentMeetingCustomer.dateMeeting'"
                    [datePicker]="true"
                    [form]="form"
                    [helpText]="'GLOBAL_DATE_HELP' | translate"
                    [id]="'advisorInvestmentDateMeeting'"
                    [label]="'ODC_STEP_SUBMISSION_DATE_MEETING_ADVISOR' | translate"
                    [name]="'advisorInvestmentDateMeeting'"
                    [path]="'advisorInvestmentDateMeeting'"
                    [submitted]="isFormSubmitted()"
                    [type]="constants.FIELD_TYPES.TEXT"
                    appDatePicker
                ></app-odc-field>
            </div>
        </div>
    </div>

    <div class="panel panel-default panel-noborder">
        <div class="panel-body">
            <!--confirm-->
            <app-odc-field
                [choices]="[{label: (isPleinEx() && isUpdateAccount() && isPilotMemberOrModeUpdateAutomatedOpeningActive() ? 'SUBMIT_PLEIN_EX_CONFIRM' : 'SUBMIT_CONFIRM') |
                    translate, id: 'confirm', binding: ''}]"
                [form]="form"
                [labelSrOnly]="true"
                [label]="'ODC_NAV_STEP_SUBMIT' | translate"
                [stacked]="true"
                [submitted]="isFormSubmitted()"
                [type]="constants.FIELD_TYPES.CHECKBOX">
            </app-odc-field>

            <div>
                <button [attr.disabled]="(loading || !form.get('confirm').value) ? true : null"
                        [class.loading]="loading"
                        class="btn btn-primary btn-block loadable"
                        type="submit">
                    <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                    <i class="fa fa-check"></i>
                    {{ 'BTN_SUBMIT' | translate }}
                </button>
            </div>
            <div *ngIf="alreadySubmitError !== null"
                 class="alert alert-danger">{{ alreadySubmitError | translate }}
            </div>
            <div *ngIf="error !== null"
                 class="alert alert-danger">{{ 'GLOBAL_ERROR_MSG' | translate }}
            </div>
        </div>
    </div>

    <div class="btn-resp-col btn-resp-demi btn-resp-1colonne-inline m-t-4">
        <button (click)="navBack()" class="btn btn-default"
                type="button">{{ 'BTN_BACK' | translate }}
        </button>
    </div>

</form>

<div *ngIf="submitted && submitLabel" class="panel panel-default panel-noborder">
    <div class="panel-body">
        {{ submitLabel }}
    </div>
</div>
