import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-invalid-session',
    templateUrl: './invalid-session.component.html'
})
export class InvalidSessionComponent implements OnInit {
    
    constructor() {
    }

    ngOnInit(): void {
    }
}
