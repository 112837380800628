<fieldset>
    <legend class="sr-only">{{legend}}</legend>
    <div [formGroup]="formGroup">

        <!--street & unit-->
        <div class="row">
            <div class="col-md-16 form-group" [class.has-error]="isFieldInError(path + street)">
                <label [attr.for]="street + index">
                    <b>{{'ODC_STEP_PERSONAL_INFORMATION_STREET'|translate}}</b>
                    <app-help [templateRefFr]="helpStreet" [templateRefEn]="helpStreet"></app-help>
                    <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index"
                               [path]="path + street"></app-error>
                </label>

                <div class="twitter-typeahead twitter-typeahead-odc">
                    <input
                        type="text"
                        class="form-control typeahead addresscomplete tt-input"
                        [attr.id]="street + index"
                        name="{{street + getSuffix()}}"
                        formControlName="{{street}}"
                        [appFormBinding]="bindPath + street"
                        maxlength="70"
                        appStreetCompleter
                        [attr.address-parent]="street + index"
                        address-field="street"
                        [attr.disabled]="isStreetReadOnly ? true: null"
                    >
                    <pre aria-hidden="true"
                         style="position: absolute; visibility: hidden; white-space: pre; font-family: Arial, Helvetica; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; word-spacing: 0px; letter-spacing: normal; text-indent: 0px; text-rendering: auto; text-transform: none;"></pre>
                    <div class="tt-menu"
                         style="position: absolute; top: 100%; left: 0px; z-index: 100; display: none;">
                        <div class="tt-dataset tt-dataset-2"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 form-group" [class.has-error]="isFieldInError(path + unit)">
                <label [attr.for]="unit + index">
                    <b>{{'ODC_STEP_PERSONAL_INFORMATION_UNIT'|translate}}</b>
                    <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index"
                               [path]="path + unit"></app-error>
                </label>

                <input
                    type="text"
                    class="form-control"
                    [attr.id]="unit + index"
                    name="{{unit + getSuffix()}}"
                    formControlName="{{unit}}"
                    [appFormBinding]="bindPath + unit"
                    maxlength="8"
                    [attr.address-parent]="street + index"
                    [appAddressField]="'unit'"
                    [attr.disabled]="isUnitReadOnly ? true: null"
                >
            </div>
        </div>

        <!--city-->
        <div class="form-group" [class.has-error]="isFieldInError(path + city)">
            <label [attr.for]="city + index">
                <b>{{'ODC_STEP_PERSONAL_INFORMATION_CITY'|translate}}</b>
                <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index" [path]="path + city"></app-error>
            </label>

            <input
                type="text"
                class="form-control"
                [attr.id]="city + index"
                name="{{city + getSuffix()}}"
                formControlName="{{city}}"
                [appFormBinding]="bindPath + city"
                maxlength="32"
                [attr.address-parent]="street + index"
                [appAddressField]="'city'"
                [attr.disabled]="isCityReadOnly ? true: null"
            >
        </div>

        <!--province & postal-->
        <div class="row">
            <div class="col-md-12 form-group" [class.has-error]="isFieldInError(path + province)">
                <label [attr.for]="province + index">
                    <b>{{getProvinceLabel()|translate}}</b>
                    <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index"
                               [path]="path + province"></app-error>
                </label>

                <select
                    class="form-control custom-select"
                    [attr.id]="province + index"
                    name="{{province + getSuffix()}}"
                    formControlName="{{province}}"
                    [appFormBinding]="bindPath + province"
                    [attr.address-parent]="street + index"
                    [appAddressField]="'province'"
                    [attr.disabled]="isProvinceReadOnly ? true: null"
                >
                    <option *ngFor="let item of provinces" [value]="item.itemValue">{{item.itemLabel}}</option>
                </select>
            </div>
            <div class="col-md-12 form-group" [class.has-error]="isFieldInError(path + postalcode)">
                <label [attr.for]="postalcode + index">
                    <b>{{'ODC_STEP_PERSONAL_INFORMATION_POSTAL'|translate}}</b>
                    <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index"
                               [path]="path + postalcode"></app-error>
                </label>

                <input
                    type="text"
                    class="form-control"
                    [attr.id]="postalcode + index"
                    name="{{postalcode + getSuffix()}}"
                    formControlName="{{postalcode}}"
                    [appFormBinding]="bindPath + postalcode"
                    maxlength="{{getPostalCodeMaxLength()}}"
                    appZipCodeFormat
                    [attr.address-parent]="street + index"
                    address-field="postalcode"
                    [attr.usemap]="getPostalCodeCountry()"
                    [attr.disabled]="isPostalCodeReadOnly ? true: null"
                >
            </div>
        </div>

        <!--country-->
        <div class="row">
            <div class="col-md-12 form-group" [class.has-error]="isFieldInError(path + country)">
                <label [attr.for]="country + index">
                    <b>{{'ODC_STEP_PERSONAL_INFORMATION_COUNTRY'|translate}}</b>
                    <app-error [form]="form" [submitted]="submitted" [nameSuffix]="index"
                               [path]="path + country"></app-error>
                </label>

                <select
                    class="form-control custom-select"
                    [attr.id]="country + index"
                    name="{{country + getSuffix()}}"
                    formControlName="{{country}}"
                    [appFormBinding]="bindPath + country"
                    [attr.address-parent]="street + index"
                    [appAddressField]="'country'"
                    [attr.disabled]="isCountryReadOnly ? true: null"
                    (change)="changeCountryAddress($event.target.value)"
                    [value]="constants.COUNTRIES.CANADA"
                >
                    <option value='CAN'
                            [selected]="getFormValue(this.path + this.country) === constants.COUNTRIES.CANADA">{{'COUNTRY_ADDRESS_CAN'|translate}}</option>
                    <option value='USA'
                            [selected]="getFormValue(this.path + this.country) === constants.COUNTRIES.UNITED_STATES">{{'COUNTRY_ADDRESS_USA'|translate}}</option>
                </select>
            </div>
        </div>

    </div>
</fieldset>

<ng-template #helpStreet>
    <h2>{{'ODC_STEP_PERSONAL_INFORMATION_STREET'|translate}}</h2>
    <p>{{'ADDRESS_COMPLETER_TOOLTIP' | translate}}</p>
</ng-template>
