export const I18N_MESSAGES_FR: any = {

    GLOBAL_YES: 'Oui',
    GLOBAL_NO: 'Non',
    GLOBAL_NEXT: 'Suivant',
    GLOBAL_BACK: 'Précédent',
    GLOBAL_SELECT: 'Veuillez sélectionner',
    GLOBAL_DATE_HELP: 'aaaa-mm-jj',
    GLOBAL_ERROR: 'Erreur',
    GLOBAL_WARNING: 'Avertissement',
    CURRENCY_CAD: 'CAD',
    CURRENCY_USD: 'USD',
    CURRENCY_CAD_AND_USD: 'CAD et USD',

    GLOBAL_AND: 'et',
    GLOBAL_NONE: 'Aucun',
    GLOBAL_MODIFY: 'Modifier',
    GLOBAL_CLOSE: 'Fermer',
    GLOBAL_SAVE_REQUEST: 'Enregistrer la demande',
    ERROR_TECHNICAL_PROBLEMS_OCCURED: 'Une erreur est survenue, veuillez réessayer',

    SR_ONLY_PHONE: 'Ce lien lancera votre logiciel de téléphonie par défaut.',
    SR_ONLY_GO_TO_MAIN_CONTENT: 'Aller au contenu principal',

    ODC_LINK_NEW_WINDOW: ' - Cet hyperlien s\'ouvrira dans une nouvelle fenêtre.',

    NOTE_BACK: '[Retour]',

    MODAL_END: 'Fin de la modal',

    ODC_OR: 'OU',
    ODC_PDF_LIST: 'Documents et formulaires',
    ODC_PDF_LIST_TEXT: 'Veuillez cliquer sur le lien suivant pour télécharger le(s) formulaire(s) additionnel(s) requis à l\'ouverture :',
    ODC_PDF_LIST_OTHER_ACCOUNT_TEXT: 'Cliquez sur <strong>Continuer</strong> pour accéder à la prochaine étape afin de' +
        ' recevoir la trousse de formulaires par courriel.',
    ODC_PDF_LIST_LINK: 'https://www.disnat.com/aide-contact/document-formulaire',

    DURATION: 'Durée :',

    CONFIRMATION_MESSAGE_TRASH: 'Êtes-vous sûre de vouloir recommencer cette section?',

    ADDRESS_COMPLETER_TOOLTIP: 'Pour effectuer une recherche d\'adresse, utilisez le champ «No et rue» pour y inscrire une adresse ou un code postal. Les résultats s’affichent au fur et à mesure que vous tapez les renseignements, de façon à vous permettre de sélectionner l\'adresse que vous recherchez.',

    EXPIRATION_SESSION_TITLE: 'Expiration de session',
    EXPIRATION_SESSION_BODY_TIMEOUT_1: 'Votre session va tr&egrave;s prochainement expirer.<br/>D&eacute;sirez-vous poursuivre votre ouverture de compte ?',
    EXPIRATION_SESSION_BODY_TIMEOUT_2: 'secondes avant expiration',

    HEADER_TITLE_PART1: 'Ouverture de compte',
    HEADER_TITLE_PART1_ADD_ACCOUNT: 'Ajout de compte',
    HEADER_TITLE_PART1_UPDATE_ACCOUNT: 'Mise à jour: {{clientId}}',
    HEADER_TITLE_PART1_BRANCH_LIST: 'Suivi des demandes',
    HEADER_TITLE_PART1_OSS_QUERY: 'Requête de l\'OSS',
    HEADER_TITLE_SUFFIX: ' | Desjardins Courtage en ligne',
    HEADER_LANGUAGE_LABEL: 'English',
    HEADER_LANGUAGE_CODE: 'en',
    HEADER_TEXT: 'Cet outil vous guidera tout au long de votre ouverture de compte. En complément d\'information, nous vous invitons à consulter le ',
    HEADER_DOCUMENT_LINK: 'Document d\'information sur la relation avec les clients (PDF, 676 ko)',
    HEADER_DOCUMENT_LINK_URL: 'https://www.disnat.com/service-clientele/formulaires/Disnat_reglementaire_DX99_web',
    HEADER_LOGO_LINK_URL: 'https://www.disnat.com',

    STEP_NAV_STEPS_SIZE: 'Ce formulaire comporte {{size}} étape{{plural}}',
    STEP_NAV_ITEM_STEP: 'Étape',
    STEP_NAV_ITEM_COMPLETED: 'Complétée',
    STEP_NAV_ITEM_SELECTED: 'Sélectionnée',
    STEP_NAV_PREVIOUS_STEPS: 'Étapes précédentes',
    STEP_NAV_NEXT_STEPS: 'Étapes suivantes',

    FOOTER_OTHER_QUESTIONS_1: 'Vous avez d\'autres questions ?',
    FOOTER_OTHER_QUESTIONS_2: 'N\'hésitez pas à nous les poser !',
    FOOTER_SAC: 'Service à la clientèle',
    FOOTER_PHONE_NO: 'Téléphone: 1 866 873-7103',
    FOOTER_EMAIL_TITLE: 'Adresse électronique :',
    FOOTER_EMAIL: 'infodisnat@desjardins.com',
    FOOTER_INVISIBLE_PHONE: 'un, huit six six, huit sept trois, sept un zéro trois',

    SIDEBAR_ASSISTANCE_TITLE: 'On vous assiste',
    SIDEBAR_ASSISTANCE_PHONE: '1 866 873-7103',
    SIDEBAR_ASSISTANCE_PHONE_LINK: '+18668737103',
    SIDEBAR_ASSISTANCE_SCHEDULE: 'Lundi au vendredi (8h à 17h) HE',
    SIDEBAR_ASSISTANCE_DROPDOWN_1: 'Si vous avez des questions, nos représentants bien informés se feront un plaisir de vous répondre.',
    SIDEBAR_ASSISTANCE_DROPDOWN_2: 'Appelez-nous sans frais :',
    SIDEBAR_ASSISTANCE_HOURS: 'Lundi au vendredi (8h à 17h)',

    SIDEBAR_PLATFORM_TITLE: 'À consulter',
    SIDEBAR_PLATFORM_COMMISSION: 'Commissions et frais',
    SIDEBAR_PLATFORM_COMMISSION_LINK: 'https://www.disnat.com/plateformes-et-frais',
    SIDEBAR_PLATFORM_CONVENTION: 'Document d\'information sur la relation avec les clients et Conventions (PDF 1.53 Mo)',
    SIDEBAR_PLATFORM_CONVENTION_LINK: 'https://www.disnat.com/service-clientele/formulaires/Disnat_reglementaire_DX99_web',
    SIDEBAR_PLATFORM_GENERAL_DOCUMENTS: 'Mon Portail: Documents généraux',
    SIDEBAR_PLATFORM_GENERAL_DOCUMENTS_LINK: 'https://mvtdesjardins.sharepoint.com/sites/monportail/fr/produits-et-services/Pages/courtagevaleursmobilieres.aspx',

    HELP: 'Aide',
    HELP_CLOSE: 'Activez pour fermer l\'aide',
    HELP_OPEN: 'Activez pour ouvrir l\'aide',

    HELP_SIN_TITLE: 'Pourquoi dois-je fournir mon NAS ?',
    HELP_SIN_BODY: 'Toute firme de courtage doit déclarer ses gains sur les intérêts et les dividendes à l\'Agence du revenu du Canada (ARC). Dans le cadre de cette déclaration, nous devons fournir le numéro d\'assurance sociale (NAS) de la personne visée.',

    HELP_DOB_TITLE: 'Pourquoi est-il important que je fournisse ma date de naissance ?',
    HELP_DOB_BODY: 'Ce renseignement est important afin que nous puissions valider si vous avez 18 ans ou plus, soit l\'âge requis pour ouvrir un compte de courtage. Également, si vous faites l\'ouverture d\'un compte REER, nous devons nous assurer que vous n\'excédez pas l\'âge limite pour cotiser à un régime d\'épargne retraite.',

    CAISSE_SIDEBAR_TITLE: 'Pour nous joindre',
    CAISSE_SIDEBAR_CAISSE_SERVICE_HEADING: 'Services aux caisses',
    CAISSE_SIDEBAR_CAISSE_SERVICE_LINE1: 'Pour des questions générales sur',
    CAISSE_SIDEBAR_CAISSE_SERVICE_LINE2: 'l\'ouverture de compte ou le courtage.',
    CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_PHONE: '1 800 363-7528',
    CAISSE_SIDEBAR_CAISSE_SERVICE_DETAIL_HOURS: 'Lundi au vendredi (8h à 17h) HE',
    CAISSE_SIDEBAR_DESK_SERVICE_HEADING: 'Service soutien en ligne (SSL)',
    CAISSE_SIDEBAR_DESK_SERVICE_LINE1: 'Pour toute question sur l\'accès aux',
    CAISSE_SIDEBAR_DESK_SERVICE_LINE2: 'systèmes et aux problèmes techniques.',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE1: 'MVT-AIDE (6882433)',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE2: '514 284-AIDE ou 1 855 MVT-AIDE',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_PHONES_LINE3: '514 284-2433 ou 1 855 688-2433',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS: 'Heures d\'ouverture:',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE1: 'Lundi : 8 h 30 à 16 h 30',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE2: 'Mardi : 8 h 30 à 16 h 30',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE3: 'Mercredi : 8 h 30 à 19 h',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE4: 'Jeudi : 8 h 30 à 19 h',
    CAISSE_SIDEBAR_DESK_SERVICE_DETAIL_HOURS_LINE5: 'Vendredi : 8 h 30 à 16 h 30',
    CAISSE_SIDEBAR_IMAGE_ALT: 'Toute l’information sur Disnat pour accompagner le membre.',
    CAISSE_SIDEBAR_IMAGE_LINK: 'En savoir plus',
    CAISSE_SIDEBAR_IMAGE_HREF: 'https://www.disnat.com',

    ODC_LOGIN_TITRE_PAGE: 'Reprise de demande',
    ODC_LOGIN_EMAIL: 'Adresse électronique',
    ODC_LOGIN_PASSWORD: 'Mot de passe',
    ODC_LOGIN_ACTION_LOGIN: 'Reprendre',
    ODC_LOGIN_ACTION_RESET_PASSWORD: 'Mot de passe oublié&nbsp;?',
    ODC_LOGIN_EXPLANATION: 'Veuillez entrer vos identifiants afin de reprendre votre ouverture de compte.',

    ODC_FORGOT_PASSWORD_PAGE_TITLE: 'Mot de passe oublié',
    ODC_FORGOT_PASSWORD_EMAIL: 'Veuillez saisir votre adresse électronique',

    ODC_CREATE_PROFIL_TITLE: 'Créer profil',

    REPORTING_INSIDER_TITLE: 'Initié assujetti',
    SIGNIFICANT_SHAREHOLDER_TITLE: 'Actionnaire important',

    ODC_QUESTIONNAIRE_SAVE_TITLE: 'Voulez-vous sauvegarder votre demande?',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE: 'Vous pouvez suspendre le processus de demande en tout temps et y revenir plus tard.',
    ODC_QUESTIONNAIRE_SAVE_BUTTON: 'Enregistrer cette demande',
    ODC_QUESTIONNAIRE_SAVE_ERROR: 'Une erreur est survenue lors de la sauvegarde de votre demande. Veuillez réessayer plus tard.',
    ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED: 'Il semble que votre demande ait déjà été soumise. Vous ne pouvez l’enregistrer à nouveau.',
    ODC_QUESTIONNAIRE_SAVE_ERROR_ALREADY_COMPLETED_COMPARE_SAVED_FORM: 'Action interdite: votre demande est déjà soumise/imprimée.',
    ODC_QUESTIONNAIRE_SAVE_EMAIL: 'Veuillez fournir une adresse électronique valide pour enregistrer cette demande.',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED: 'Votre demande est sauvegardée.',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_REPRISE: 'Reprise d\'une demande enregistrée',
    ODC_QUESTIONNAIRE_SAVE_TITLE_SAVED_PLEINEX: 'Votre demande est sauvegardée.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1: 'Vous pouvez désormais quitter le formulaire en tout temps pour y revenir plus tard à l\'adresse suivante :',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_UPDATE: 'Vous pouvez désormais quitter le formulaire en tout temps. Pour y revenir plus tard veuillez accéder au bureau SmartD Ouverture de compte.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_CAISSE: 'Vous pouvez désormais quitter le formulaire en tout temps pour y revenir plus tard dans le suivi de vos demandes.',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_1_REPRISE: 'Vous venez de récupérer une demande que vous aviez précédemment enregistrée. Vous pourrez enregistrer cette demande de nouveau pour y revenir plus tard à l\'adresse suivante :',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_2: 'Voici vos identifiants:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_3: 'Adresse électronique:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_4: 'Mot de passe:',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_5: 'www.disnat.com/service-clientele',
    ODC_QUESTIONNAIRE_SAVE_URL: 'http://www.disnat.com/service-clientele',
    ODC_QUESTIONNAIRE_SAVE_MESSAGE_SAVED_PLEINEX: 'Vous pouvez désormais quitter le formulaire en tout temps. Pour y revenir plus tard veuillez accéder au bureau SmartD Ouverture de compte.',
    ODC_QUESTIONNAIRE_PRINT_BUTTON: 'Imprimer cette demande',
    ODC_QUESTIONNAIRE_PRINT_ERROR: 'Une erreur est survenue lors de l\'impression de votre demande. Veuillez réessayer plus tard.',
    ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_TITLE: 'Veuillez patienter',
    ODC_QUESTIONNAIRE_SAVE_MODAL_INFO_BODY: 'Votre demande est en cours d’enregistrement.',
    ODC_QUESTIONNAIRE_IN_PROCESS_MODAL_INFO_BODY: 'Votre demande est en cours de traitement.',

    STEP_BEGIN_TITLE: 'Ouvrez un compte en ligne',
    STEP_BEGIN_BANNER_1: '<strong>En 20 minutes seulement</strong> pour certains comptes (non enregistrés, REER, CELI et CELIAPP)',
    STEP_BEGIN_BANNER_2: 'Sans papier ni délai de poste <strong>grâce à la signature électronique</strong>',
    STEP_BEGIN_TRADES_TITLE: 'Effectuez vos transactions de façon autonome',
    STEP_BEGIN_TRADES_DESC: 'Sur les marchés canadiens et américains.',
    STEP_BEGIN_TRADES_SECURITIES_TITLE: 'Titres',
    STEP_BEGIN_TRADES_SECURITIES_STOCKS_OPTIONS: 'Actions / options',
    STEP_BEGIN_TRADES_SECURITIES_BONDS: 'Obligations',
    STEP_BEGIN_TRADES_SECURITIES_FUNDS: 'Fonds',
    STEP_BEGIN_TRADES_SECURITIES_ETF: 'FNB',
    STEP_BEGIN_TRADES_PLANS_TITLE: 'Comptes',
    STEP_BEGIN_TRADES_PLANS_NON_REGISTERED: 'Non enregistré',
    STEP_BEGIN_TRADES_PLANS_RRSP: 'REER',
    STEP_BEGIN_TRADES_PLANS_TFSA: 'CELI',
    STEP_BEGIN_TRADES_PLANS_FHSA: 'CELIAPP',
    STEP_BEGIN_TRADES_PLANS_RESP: 'REEE',
    STEP_BEGIN_TRADES_PLANS_LIRA: 'CRI',
    STEP_BEGIN_TRADES_PLANS_RRIF: 'FERR',
    STEP_BEGIN_TRADES_PLANS_LIF: 'FRV',
    STEP_BEGIN_ELIGIBILITY_TITLE: 'Admissibilité',
    STEP_BEGIN_ELIGIBILITY_DESC: 'Vous devez :',
    STEP_BRANCH_BEGIN_ELIGIBILITY_DESC: 'Le client doit :',
    STEP_BEGIN_ELIGIBILITY_1: 'Avoir plus de 18 ans',
    STEP_BEGIN_ELIGIBILITY_1_BRANCH: 'Être âgé de 18 ans ou plus ',
    STEP_BEGIN_ELIGIBILITY_2: 'Résider au Canada',
    STEP_BEGIN_ELIGIBILITY_3: 'Avoir des connaissances en matière de placement',
    STEP_BEGIN_ELIGIBILITY_3_BRANCH: 'Posséder des connaissances en matière de placement',
    STEP_BEGIN_ELIGIBILITY_4: 'Détenir un numéro d’assurance sociale',
    STEP_BEGIN_ELIGIBILITY_5: 'Avoir son téléphone cellulaire pour la signature électronique',
    STEP_BEGIN_DOCUMENTS_TITLE: 'Vous aurez besoin des renseignements suivants pour remplir votre demande :',
    STEP_BEGIN_DOCUMENTS_1: 'Votre numéro d’assurance sociale',
    STEP_BEGIN_DOCUMENTS_2: 'Un spécimen de chèque en format JPEG ou PDF',
    STEP_BEGIN_DOCUMENTS_3: 'Une copie numérisée d\'une pièce d\'identité gouvernementale avec photo valide',
    STEP_BEGIN_DOCUMENTS_LBA_2: 'Un spécimen de chèque personnalisé',
    STEP_BEGIN_DOCUMENTS_LBA_3: 'Une copie lisible de 2 documents justificatifs valides et authentiques afin de procéder à la vérification de votre identité',
    STEP_BEGIN_DOCUMENTS_LBA_4: 'Seuls les documents en format PDF, PNG ou JPEG sont acceptés.',
    STEP_BEGIN_RESUME_REQUEST: 'Reprendre une demande',
    STEP_BEGIN_FAQ: 'Questions fréquentes',
    STEP_BEGIN_FAQ_PERSONAL_INFORMATION: 'Pourquoi dois-je fournir mes renseignements personnels ?',
    STEP_BEGIN_FAQ_CANADIAN_RESIDENT: 'Suis-je considéré un résident canadien ?',
    STEP_BEGIN_FAQ_AMERICAN_CITIZENS: 'Les citoyens américains peuvent-ils ouvrir un compte auprès de Desjardins Courtage en ligne ?',
    STEP_BEGIN_FAQ_APPLE_SAFARI_COOKIES_ACCEPTATION: 'J’utilise le navigateur Safari, comment puis-je accepter les témoins (cookies) afin d’être en mesure de signer électroniquement ma demande ?',

    STEP_BRANCH_BEGIN_DESC_1: 'En seulement 20 minutes pour les comptes de particuliers, sans papier ni délai de poste grâce à la signature électronique.',
    STEP_BRANCH_BEGIN_DESC_2: 'Obtenez une trousse avec tous les formulaires nécessaires pour l’ouverture de compte conjoint ou pour tous les autres types de comptes.',
    STEP_BRANCH_BEGIN_DESC_3: 'La langue de correspondance sera celle utilisée pour remplir le questionnaire. Vous pouvez la changer en cliquant sur le bouton <strong>« English »</strong> situé dans le coin supérieur droit de la page.',
    STEP_BRANCH_BEGIN_DESC_4: 'Le questionnaire sera rempli à l’aide des informations complètes provenant du « Dossier Desjardins particulier (DDP) » en considérant si le consentement est signé ou non.',
    STEP_BRANCH_BEGIN_DESC_5: 'Vous devez cependant avoir recherché le dossier auparavant.',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_LIST_DESC: 'Pour la signature électronique, vous devrez numériser les documents suivants :',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_CAD_CHECK: 'Un spécimen de chèque en devise CAD',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_USD_CHECK: 'Un spécimen de chèque en devise USD (facultatif)',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_DOCUMENT_ID: 'Une pièce d’identité gouvernementale avec photo valide du client',


    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_TITLE: 'Signature électronique',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_1: 'Doit être faite en présence du client seulement',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_2: 'S’effectue avec le téléphone cellulaire du client',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_3: 'A la même valeur qu’une signature manuscrite (papier)',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_CONDITION_4: 'Est disponible pour les ouvertures de compte de particuliers seulement',
    STEP_BRANCH_BEGIN_ELECTRONIC_SIGNATURE_NOTE: 'Note : le client peut choisir en tout temps de signer de façon électronique ou manuscrite (papier) ses documents d’ouverture de compte.',

    STEP_BRANCH_BEGIN_SAVE_TITLE: 'Enregistrement',
    STEP_BRANCH_BEGIN_SAVE_DESC: 'Vous pouvez enregistrer en tout temps votre demande et la reprendre plus tard.',


    ODC_STEP_PREPARATION_RDV_TITLE: 'Vous préférez qu’un conseiller vous accompagne pour ouvrir un compte?',
    ODC_STEP_PREPARATION_RDV_DESCRIPTION: 'À une caisse Desjardins',
    ODC_STEP_PREPARATION_RDV_ACTION: 'Trouver une caisse près de chez vous',
    ODC_STEP_PREPARATION_RDV_ACTION_URL: 'https://www.desjardins.com/localisateur/index.jsp',
    ODC_STEP_PREPARATION_CONTACT_TITLE: 'À nos bureaux',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL: 'MONTRÉAL',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_SCHEDULE: 'Du lundi au vendredi de 8h à 17h HE',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_1: '1170, rue Peel',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_2: 'Bureau 105',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_3: 'Montréal (QC)',
    ODC_STEP_PREPARATION_CONTACT_MONTREAL_ADDRESS_4: 'H3B 0A9',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC: 'QUÉBEC',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_SCHEDULE: 'Du lundi au vendredi de 8h à 17h HE',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_1: 'Place de la cité',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_2: '2600, boul. Laurier',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_3: 'Bureau 130',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_4: 'Québec (QC)',
    ODC_STEP_PREPARATION_CONTACT_QUEBEC_ADDRESS_5: 'G1V 4T3',

    /* Preperation - Client Type */
    ODC_STEP_PREPARATION_CLIENT_TYPE_TITLE: 'Choisissez un type de compte',
    ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT: 'Compte personnel',
    ODC_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_DESC: 'Un compte personnel est un compte ouvert au nom d\'une seule personne physique qui détient la totalité des sommes à ce compte. Il peut être au comptant, sur marge, avec options et à découvert et peut inclure les régimes enregistrés.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT: 'Compte conjoint',
    ODC_STEP_PREPARATION_CLIENT_TYPE_JOINT_ACCOUNT_DESC: 'Le compte conjoint est un compte ouvert au nom de 2 ou 3 personnes, ayant un lien de parenté ou non. Les sommes détenues à ce compte appartiennent conjointement à chaque personne.',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_QUESTION: 'Désirez-vous ajouter un REEE?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_TYPE: 'Désirez-vous ouvrir un REEE familial ou individuel?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_OPTION_FAMILY: 'Familial',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_OPTION_INDIVIDUAL: 'Individuel',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_BENEFICIARY: 'Combien de bénéficiaires voulez-vous inscrire?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_RESP_SUBSCRIBER: 'Un des souscripteurs est-il le parent responsable?',
    ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT: 'Autres comptes',
    ODC_STEP_PREPARATION_CLIENT_TYPE_OTHER_ACCOUNT_DESC: 'Désigne tous les comptes autres que les comptes personnels ou conjoints. Assujettis aux diverses conditions régissant les différents types de comptes.',

    /* Preperation - Platform Type */
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_IDEAL: 'Idéal pour vous si',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_FEATURE: 'Caractéristiques',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_MARKET_DATA: 'Données de marché',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_PLATFORM_FEE: 'Frais de plateforme',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_INACTIVITY_FEE: 'Frais d’inactivité<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_ADMINISTRATION_FEE: 'Frais d’administration<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_STOCK_COMMISSION: 'Commissions sur actions',
    ODC_STEP_PLATFORM_TYPE_DISNAT_LEFT_CHOICE: 'Mon choix de plateforme',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CHOICE: 'Choisissez une plateforme transactionnelle',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC: 'Disnat classique',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT: 'Disnat direct',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_IDEAL: 'Vous êtes un investisseur <strong>débutant</strong> ou expérimenté',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_FEATURE: 'Plateforme de négociation <strong>Web</strong> simple, moderne et complète',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_MARKET_DATA: '<ul><li><strong>Niveau 1</strong></li><li>Mise à jour des cotes en temps réel</li></ul>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_PLATFORM_FEE: 'Cotes en temps réel GRATUITES',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_INACTIVITY_FEE: 'Des frais d’inactivité de <strong>30 $ par trimestre</strong> s’appliquent si aucun critère de gratuité n’est respecté<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_ADMINISTRATION_FEE: 'De <strong>0 $</strong> à <strong>100 $</strong> par année si aucun critère de gratuité n’est respecté<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_CLASSIC_STOCK_COMMISSION: '<strong>0 $ par transaction</strong>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_IDEAL: 'Vous êtes un investisseur <strong>actif</strong>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_FEATURE: 'Plateforme de négociation sophistiquée',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_MARKET_DATA: '<ul><li><strong>Niveaux 1 et 2</strong></li><li>Mise à jour des cotes dynamiques en temps réel</li><li>Cotes dynamiques illimitées</li></ul>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_PLATFORM_FEE: 'De <strong>0 $ à 105 $ par mois</strong> selon le nombre de transactions et le type de données de marché choisies',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_INACTIVITY_FEE: 'Des frais d’inactivité de <strong>30 $ par trimestre</strong> s’appliquent si aucun critère de gratuité n’est respecté<a class="appel-note" href="#platform-note-1"><sup>[ 1 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_ADMINISTRATION_FEE: 'De <strong>0 $</strong> à <strong>100 $</strong> par année si aucun critère de gratuité n’est respecté<a class="appel-note" href="#platform-note-2"><sup>[ 2 ]</sup></a>',
    ODC_STEP_PLATFORM_TYPE_DISNAT_DIRECT_STOCK_COMMISSION: '<strong>0 $ par transaction</strong>',

    ODC_STEP_PLATFORM_TYPE_NOTE_TITLE: 'Notes',
    ODC_STEP_PLATFORM_TYPE_NOTE_1: '1. Aucuns frais d’inactivité ne seront portés au compte si :',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_1: 'Six transactions ou plus ont été exécutées au cours des 12 derniers mois, ou;',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_2: 'La valeur du portefeuille est de 15 000 $ et plus, ou;',
    ODC_STEP_PLATFORM_TYPE_NOTE_1_3: 'Un régime enregistré est détenu (REER, FERR, CRI, FRV, REEE).',
    ODC_STEP_PLATFORM_TYPE_NOTE_2: '2. Aucuns frais d’administration de régime ne seront portés au compte si :',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_1: 'REER / CRI : une valeur de 15 000 $ et plus est maintenue au(x) régime(s)',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_2: 'FERR / FRV : une valeur de 25 000 $ et plus est maintenue au(x) régime(s)',
    ODC_STEP_PLATFORM_TYPE_NOTE_2_3: 'REEE : l’actif détenu dans l’ensemble des comptes du souscripteur est d’une valeur marchande de 15 000 $ et plus',

    ODC_STEP_PLATFORM_MODAL_TITLE: 'Vous avez choisi Disnat Direct',
    ODC_STEP_PLATFORM_MODAL_BODY: 'Cette plateforme plus sophistiquée s’adresse aux investisseurs avertis, qui effectuent de nombreuses transactions chaque mois. Des frais de plateforme pourraient s’appliquer.',

    /* Account choice */
    ACCOUNT_CHOICE_TITLE: 'Compte non enregistré (créé automatiquement)',
    ACCOUNT_CHOICE_UPDATE_TITLE: 'Compte non enregistré',
    ACCOUNT_CHOICE_FIDUCIARY_TRANSACTION_ACCOUNTS_TITLE: 'Comptes opérations fiduciaires',
    REGISTERED_ACCOUNT_CHOICE_TITLE: 'Ajouter un compte enregistré (facultatif)',
    NEW_EXIST_ACCOUNT: 'Nouveau compte ou ajout de compte',
    REQUEST_TYPE: 'Type de demande',
    REQUEST_TYPE_NEW_CLIENT: 'Nouveau',
    REQUEST_TYPE_ADD_ACCOUNT: 'Ajout',
    ACCOUNT_TYPE: 'Type de compte',
    DCL_ACCOUNT_TYPE: 'Voulez-vous ajouter la possibilité d\'effectuer des stratégies d\'investissement avancées ? (Facultatif)',
    FHSA_ELIGIBILITY: 'Admissibilité au CELIAPP',
    ACCOUNT_CURRENCY: 'Devise du compte',
    NEW_ACCOUNT: 'Nouveau compte',
    DCL_NEW_ACCOUNT: 'Je n\'ai pas encore de compte Desjardins Courtage en ligne',
    REDOCUMENT_ACCOUNT: 'Redocumenter un compte',
    UPDATE_ACCOUNT: 'Mise à jour',
    ADD_ACCOUNT: 'Ajouter un compte à votre numéro de client existant',
    DCL_ADD_ACCOUNT: 'J\'ai déjà un compte Desjardins Courtage en ligne',
    CLIENT_NUMBER: 'N°',
    DCL_CLIENT_NUMBER: 'Numéro de compte Desjardins Courtage en ligne',
    CLIENT_NUMBER_LONG: 'Code d\'utilisateur',
    CASH_ACCOUNT: 'Compte Comptant',
    CASH_ACCOUNT_HELP: 'Il permet l’achat ou la vente de valeurs mobilières, dans lequel vous devez avoir un certain montant d’argent pour couvrir vos achats, ou par lequel vous devez verser la somme d’argent nécessaire avant la date de règlement. Chaque transaction doit être réglée intégralement.',
    CASH_ACCOUNT_HELP_DCL: 'Il permet l’achat ou la vente de valeurs mobilières, dans lequel vous devez avoir un certain montant d’argent pour couvrir vos achats. Chaque transaction doit être réglée intégralement.',
    MARGIN_ACCOUNT: 'Compte Marge',
    MARGIN_ACCOUNT_HELP: 'Type de compte dans lequel vous pouvez acheter ou vendre des titres à crédit, en ne versant que le montant qui correspond à la différence entre la valeur marchande du titre et le montant avancé par la société de courtage en valeurs mobilières, qui va alors vous imputer des intérêts sur ce prêt.',
    MARGIN_ACCOUNT_WITH_OPTIONS: 'Compte marge avec options',
    WITH_OPTIONS: 'Avec options',
    DCL_WITH_OPTIONS: 'Je désire pouvoir négocier des options',
    WITH_OPTIONS_HELP: 'Compte marge qui vous permet de vendre ou d’acheter des options. Une option est un contrat qui donne le droit ou l’obligation d’acheter ou de vendre une quantité donnée d’un élément sous-jacent à un prix déterminé dans un délai prescrit.',
    MARGIN_ACCOUNT_WITH_SHORT_SELLING: 'Compte marge avec vente à découvert',
    WITH_SHORT_SELLING: 'Avec ventes à découvert',
    DCL_WITH_SHORT_SELLING: 'Je désire pouvoir faire des ventes à découvert',
    WITH_SHORT_SELLING_HELP: 'Compte marge qui vous permet d’effectuer des ventes à découvert. La vente à découvert consiste à vendre des titres que vous ne détenez pas. Dans ce cas, Valeurs mobilières Desjardins emprunte les titres.',
    CA_ONLY: 'CAD seulement',
    US_AND_CA: 'USD et CAD',
    RRSP_ACCOUNT: 'REER',
    RRSP_ACCOUNT_DESC: 'Un régime enregistré d\'épargne-retraite (REER) est un compte enregistré dans lequel vous ou votre époux ou conjoint de fait pouvez cotiser.',
    RRSP_ACCOUNT_HELP: 'Il offre un double avantage fiscal : vos cotisations sont déduites de votre revenu imposable et les revenus de vos placements sont réinvestis entièrement dans votre régime à l\'abri de l\'impôt. Toutefois, vous devez généralement payer de l\'impôt lorsque vous retirez des montants du régime.',
    RRSP_ANNUITANT: 'Type de cotisation REER',
    RRSP_ANNUITANT_PERSONAL: 'REER rentier cotisant',
    RRSP_ANNUITANT_SPOUSAL: 'REER conjoint cotisant',
    RRSP_ADD: 'Ajouter un REER',
    RRSP_DELETE: 'Supprimer le REER',
    TFSA_ACCOUNT: 'CELI',
    TFSA_ACCOUNT_DESC: 'Le compte d\'épargne libre d\'impôt (CELI) est un compte d\'épargne dans lequel vous pouvez mettre de l\'argent de côté libre d\'impôt.',
    TFSA_ACCOUNT_HELP: 'Les cotisations à un CELI ne sont pas déductibles d\'impôt sur le revenu. Les montants retirés de ce compte ne seront pas imposables.',
    FHSA_ACCOUNT: 'CELIAPP',
    FHSA_ACCOUNT_ELIGIBILITY: 'Admissible au CELIAPP',
    FHSA_ACCOUNT_DESC: 'Le compte d’épargne libre d’impôt pour l\'achat d\'une première propriété (CELIAPP) est un régime enregistré qui vous permet, en tant qu’acheteur potentiel d’une première habitation, d’épargner à l’abri de l’impôt pour votre première habitation (jusqu’à certaines limites).',
    FHSA_ACCOUNT_HELP: 'Vous pouvez généralement déduire les cotisations que vous versez à vos comptes d\'épargne libre d\'impôt pour l\'achat d\'une première propriété (CELIAPP) dans votre déclaration de revenus et de prestations. Toutefois, vous <strong>ne pouvez pas déduire</strong> les montants que vous transférez de vos régimes enregistrés d\'épargne-retraite (REER) à vos CELIAPP',
    FHSA_ELIGIBILITY_CONFIRMATION: 'Je confirme être un acheteur d\'une première habitation',
    FHSA_HELP_ELIGIBILITY: '<strong>Acheteur d’une première habitation :</strong> Un particulier est considéré comme étant un acheteur d’une première habitation si, à tout moment dans la partie de\n' +
        'l’année civile avant que le compte ne soit ouvert ou à tout temps dans les quatre années civiles précédentes, il ne vivait pas dans une habitation admissible\n' +
        'comme lieu principal de résidence (ou ce que serait une habitation admissible si celle-ci était au Canada) qui était la propriété (conjointement ou non) du\n' +
        'particulier ou de son époux ou conjoint de fait (si la personne a un époux ou conjoint de fait au moment de conclure un CELIAPP).\n' +
        '</br><strong>Habitation admissible :</strong> Une habitation admissible est définie comme une unité d’habitation située au Canada. Elle comprend également une part du capital\n' +
        'social d’une coopérative d’habitation, lorsque le détenteur de la part a droit à la possession d’une unité d’habitation située au Canada. Toutefois, lorsque le\n' +
        'contexte l’exige, une telle part désigne l’unité d’habitation à laquelle la part se rapporte.',
    CURRENCY_MODAL_TITLE: 'Devise du compte',
    CURRENCY_MODAL_BODY_TO_USD: 'Pour vous permettre d\'ouvrir des comptes enregistrés en dollars canadiens et américains, vous devez absolument détenir des comptes non enregistrés dans la même devise. Par conséquent, les devises de votre compte non enregistré ont automatiquement été changées en dollars canadiens et américains.',
    CURRENCY_MODAL_BODY_TO_CAD: 'Vous ne pouvez pas ouvrir un compte enregistré en dollars canadiens et américains si le compte non enregistré est en dollars canadiens seulement. Par conséquent, la devise du compte enregistré a donc automatiquement été changée en dollars canadiens.',
    RESP_ACCOUNT: 'REEE individuel ou familial',
    RESP_ACCOUNT_DESC: 'Le Régime enregistré d\'épargne-étude (REEE) vous permet, à titre de souscripteur, d\'accumuler des sommes pour financer les études postsecondaires d\'un ou de plusieurs bénéficiaires.',
    RESP_ACCOUNT_HELP: 'Le REEE individuel doit être établi au nom d\'un seul bénéficiaire, alors que le REEE familial peut être établi au nom de plusieurs bénéficiaires. Les cotisations ne seront pas déductibles de votre revenu, mais le rendement sur vos placements s\'accumulera à l\'abri de l\'impôt. Le gouvernement fédéral, et certains gouvernements provinciaux verseront des subventions. Une partie des sommes retirées seront imposables pour le bénéficiaire qui les recevra.',
    RESP_TYPE: 'Désirez-vous ouvrir un REEE familial ou individuel ?',
    RESP_TYPE_INDIVIDUAL: 'Individuel',
    RESP_TYPE_FAMILY: 'Familial',
    RESP_NUMBER_OF_BENEFICIARIES: 'Combien de bénéficiaires voulez-vous inscrire ?',
    RESP_IS_SUBSCRIBER_RESPONSIBLE: 'Le souscripteur est-il le parent responsable ?',
    LIRA_ACCOUNT: 'CRI, CRIF, RERI ou REIR',
    LIRA_ACCOUNT_DESC: 'Le Compte de retraite immobilisé (CRI), le Compte de retraite immobilisé fédéral (CRIF), le régime enregistré de retraite immobilisé (RERI) et le régime d\'épargne immobilisé restreint (REIR) sont des régimes dans lequel sont déposées les sommes immobilisées provenant du régime de pension de votre ancien employeur.',
    LIRA_ACCOUNT_HELP: 'Ces sommes immobilisées ne pourront être retirées, sauf dans certaines circonstances particulières. Dans ce cas, la somme retirée du compte sera imposable. Le rendement sur vos placements s\'accumulera à l\'abri de l\'impôt. Aucune cotisation ne peut être effectuée dans ce régime.',
    LIRA_JURISDICTION: 'Quelle est la juridiction de votre régime ?',
    LIRA_JURISDICTION_DEFAULT_OPTION: 'Veuillez choisir une juridiction',
    LIRA_ADD: 'Ajouter un CRI, CRIF, RERI ou REIR',
    LIRA_DELETE: 'Supprimer le CRI, CRIF, RERI ou REIR',
    RRIF_ACCOUNT: 'FERR',
    RRIF_ACCOUNT_DESC: 'Le Fonds enregistré de revenu de retraite (FERR) est un régime permettant de générer un revenu de retraite provenant des sommes initialement cotisées à votre REER.',
    RRIF_ACCOUNT_HELP: 'Le rendement sur vos placements s\'accumulera à l\'abri de l\'impôt. Un montant de retrait minimum sera établi au début de chaque année. Les sommes retirées du régime seront imposables.',
    RRIF_ANNUITANT: 'Type de cotisation FERR',
    RRIF_ANNUITANT_PERSONAL: 'FERR rentier cotisant',
    RRIF_ANNUITANT_SPOUSAL: 'FERR conjoint cotisant',
    RRIF_ADD: 'Ajouter un FERR',
    RRIF_DELETE: 'Supprimer le FERR',
    LIF_ACCOUNT: 'FRV ou FRVR',
    LIF_ACCOUNT_DESC: 'Le Fonds de revenu de retraite (FRV) ou le Fonds de revenu viager restreint (FRVR) sont des comptes de régimes générant un revenu de retraite provenant des sommes déposés dans votre CRI, CRIF, RERI, REIR ou au régime de pension de votre employeur.',
    LIF_ACCOUNT_HELP: 'Le rendement sur vos placements s\'accumulera à l\'abri de l\'impôt. Un montant de retrait minimum et un montant de retrait maximum seront établis au début de chaque année. Toute somme retirée du compte sera imposable.',
    LIF_JURISDICTION: 'Quelle est la juridiction de votre régime ?',
    LIF_JURISDICTION_DEFAULT_OPTION: 'Veuillez choisir une juridiction',
    LIF_FUNDS_FROM: 'Les fonds proviennent-ils d\'un compte CRI ou d\'un régime de pension ?',
    FTARRSP_ACCOUNT: 'REER rente non viagère (RRNV)',
    FTARRSP_ACCOUNT_DESC: 'Le régime de rente non viagère est un compte enregistré d\'épargne-retraite offert aux résidents du Québec.',
    FTARRSP_ACCOUNT_HELP: 'Il permet la désignation d\'un bénéficiaire au décès (révocable ou irrévocable) et offre, dans certaines circonstances, une protection accrue des biens y étant détenus.',
    FTARRSP_ANNUITANT: 'Type de cotisation RRNV',
    FTARRSP_ANNUITANT_PERSONAL: 'RRNV rentier cotisant',
    FTARRSP_ANNUITANT_SPOUSAL: 'RRNV conjoint cotisant',
    FTARRSP_REVOCABLE_BENEFICIARY: 'Désignation de bénéficiaire au décès',
    FTARRSP_REVOCABLE_BENEFICIARY_REVOCABLE: 'Révocable',
    FTARRSP_REVOCABLE_BENEFICIARY_IRREVOCABLE: 'Irrévocable',
    FTARRSP_ADD: 'Ajouter un RRNV',
    FTARRSP_DELETE: 'Supprimer le RRNV',
    ACCOUNT_CHOICE_MODAL_FRV4AB_BC: 'Un compte FRV ou FRVR ne peut être ouvert si vous n\'avez pas atteint l\'âge de 50 ans.',
    ACCOUNT_CHOICE_MODAL_FRV4AB_BC_TITLE: 'Âge limite pour l\'ouverture d’un compte FRV ou FRVR',
    ACCOUNT_CHOICE_MODAL_FRV4NS_ON: 'Un compte FRV ou FRVR ne peut être ouvert si vous n\'avez pas atteint l\'âge de 55 ans.',
    ACCOUNT_CHOICE_MODAL_FRV4NS_ON_TITLE: 'Âge limite pour l\'ouverture d’un compte FRV ou FRVR',
    ACCOUNT_CHOICE_MODAL_CRI: 'Un compte CRI, CRIF, RERI, REIR ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans.',
    ACCOUNT_CHOICE_MODAL_CRI_TITLE: 'Âge limite pour l\'ouverture d’un compte CRI, CRIF, RERI ou REIR',
    ACCOUNT_CHOICE_MODAL_REER: 'Un compte REER ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans.',
    ACCOUNT_CHOICE_MODAL_REER_TITLE: 'Âge limite pour l\'ouverture d’un compte REER',
    ACCOUNT_CHOICE_MODAL_RRNV: 'Un compte RRNV ne peut être ouvert si vous n\'êtes pas résident de la province de Québec.',
    ACCOUNT_CHOICE_MODAL_RRNV_TITLE: 'Province pour l\'ouverture d’un compte RRNV',
    ACCOUNT_CHOICE_MODAL_FHSA: 'Un compte CELIAPP ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans.',
    ACCOUNT_CHOICE_MODAL_FHSA_TITLE: 'Âge limite pour l\'ouverture d’un compte CELIAPP',
    ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY_TITLE: 'Non résident du Canada',
    ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY: 'Un compte CELIAPP ne peut être ouvert pour un client non résident du Canada.',
    MGNT_TYPE: 'Type de gestion',
    MGNT_TYPE_COM: 'Services-conseils à commission',
    MGNT_TYPE_MAND: 'Mandat seulement',
    MGNT_TYPE_DISCRETIONARY: 'Services de gestion discrétionnaire',
    SFD_ACCOUNT_NUMBER: 'Numéro de compte SFD',
    SFD_ACCOUNT_NUMBER_CAD: 'Numéro de compte SFD (CAD)',
    SFD_ACCOUNT_NUMBER_USD: 'Numéro de compte SFD (USD)',
    SFD_PRODUCT_NUMBER: 'Numéro de produit',

    // Type of management
    ODC_STEP_TYPE_OF_MANAGEMENT_TITLE: 'Type de gestion',
    INVESTMENT_ADVICE: 'Conseils en placement',
    PORTFOLIO_MANAGEMENT: 'Gestion de portefeuille',
    INVESTMENT_ADVICE_COMMISSION_BASED: 'Services-conseils à commissions',
    INVESTMENT_ADVICE_FEE_BASED: 'Services-conseils à honoraires',
    INVESTMENT_ADVICE_ELITE_BASIC: 'ÉLITE de base',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE: 'ÉLITE intermédiaire',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE: 'ÉLITE FLEXible',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM: 'Programme Conseiller Gestionnaire de portefeuille (PCG)',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_GPD: 'Service de gestion discrétionnaire',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_SSD: 'Portefeuilles en fonds',
    PORTFOLIO_MANAGEMENT_DISCRETIONARY_SERVICE: 'Services de gestion discrétionnaire',
    SECURITIES_PORTFOLIOS: 'Portefeuilles en titres',
    PORTFOLIO_MANAGEMENT_MANDATE_ONLY: 'Mandat seulement',
    INVESTMENT_ADVICE_COMMISSION_BASED_FORM: 'V101',
    INVESTMENT_ADVICE_COMMISSION_BASED_FORM_SSD: 'SD101',
    INVESTMENT_ADVICE_ELITE_BASIC_FORM: 'V101 + V1066',
    INVESTMENT_ADVICE_ELITE_BASIC_PCG_FORM: 'V101 + V101PCG + V101PCG AR + V1066',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE_FORM: 'V101 + V1067',
    INVESTMENT_ADVICE_ELITE_INTERMEDIATE_PCG_FORM: 'V101 + V101PCG + V101PCG AR + V1067',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM: 'V101 + V1069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_PCG_FORM: 'V101 + V101PCG + V101PCG AR + V1069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_GDF_FORM: 'SD101 + SD101GDF + V1069',
    INVESTMENT_ADVICE_ELITE_FLEXIBLE_FORM_SSD: 'SD101 + V1069',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM: 'V101 + V101PCG + V101PCG AR',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD: 'GPD101 + GPD101.1 + GPD101P',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_ENTITY: 'GPD101E + GPD101.1 + GPD101P',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_OLD: 'GPD101 + GPD101.1 + GPD101Q',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_GPD_ENTITY_OLD: 'GPD101E + GPD101.1 + GPD101Q',
    PORTFOLIO_MANAGEMENT_ADVISOR_PROGRAM_FORM_SSD: 'SD101 + SD101GDF',
    PORTFOLIO_MANAGEMENT_MANDATE_ONLY_FORM: 'V133',
    SECURITIES_PORTFOLIOS_FORM: 'SD133',

    // Personal Information
    ODC_STEP_PERSONAL_INFORMATION_TITLE: 'Informations personnelles du client',
    ODC_STEP_PERSONAL_INFORMATION_HOLDER_TITLE: 'Identification du titulaire de compte',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE: 'Langue du client',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_FR: 'Français',
    ODC_STEP_PERSONAL_INFORMATION_LANGUAGE_EN: 'Anglais',
    ODC_STEP_PERSONAL_INFORMATION_GENDER: 'Titre',
    ODC_STEP_PERSONAL_INFORMATION_MRS: 'Madame',
    ODC_STEP_PERSONAL_INFORMATION_MR: 'Monsieur',
    ODC_STEP_PERSONAL_INFORMATION_FIRST_NAME: 'Prénom',
    ODC_STEP_PERSONAL_INFORMATION_LAST_NAME: 'Nom',
    ODC_STEP_PERSONAL_INFORMATION_DOB: 'Date de naissance',
    ODC_STEP_PERSONAL_INFORMATION_DOB_HELP: 'aaaa-mm-jj',
    ODC_STEP_PERSONAL_INFORMATION_SIN: 'Numéro d\'assurance sociale',
    ODC_STEP_PERSONAL_INFORMATION_SIN_OPTIONAL: 'Numéro d\'assurance sociale (facultatif)',
    ODC_STEP_PERSONAL_INFORMATION_HOME_PHONE: 'Téléphone domicile',
    ODC_STEP_PERSONAL_INFORMATION_OTHER_PHONE: 'Téléphone autre',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_HOME: 'Téléphone (domicile)',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_BUSINESS: 'Téléphone (travail)',
    ODC_STEP_PERSONAL_INFORMATION_PHONE_MOBILE: 'Téléphone (mobile)',
    ODC_STEP_PERSONAL_INFORMATION_EMAIL: 'Adresse électronique',
    ODC_STEP_PERSONAL_INFORMATION_HOME_ADDRESS_TITLE: 'Adresse de résidence',
    ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TITLE: 'Adresse',
    ODC_STEP_PERSONAL_INFORMATION_OSS_ADDRESS_TYPE: 'Veuillez indiquer quel est le type d’adresse :',
    ODC_STEP_PERSONAL_INFORMATION_OSS_IS_RESIDENTIAL_ADDRESS: 'Adresse de résidence',
    ODC_STEP_PERSONAL_INFORMATION_OSS_IS_MAILING_ADDRESS: 'Adresse de correspondance',
    ODC_STEP_PERSONAL_INFORMATION_COUNTRY: 'Pays',
    ODC_STEP_PERSONAL_INFORMATION_STREET: 'N° et rue',
    ODC_STEP_PERSONAL_INFORMATION_UNIT: 'Unité/App.',
    ODC_STEP_PERSONAL_INFORMATION_CITY: 'Ville',
    ODC_STEP_PERSONAL_INFORMATION_PROVINCE: 'Province',
    ODC_STEP_PERSONAL_INFORMATION_STATE: 'État',
    ODC_STEP_PERSONAL_INFORMATION_POSTAL: 'Code postal',
    ODC_STEP_PERSONAL_INFORMATION_IS_ADRESS_DIFFERENT_AS_FIRST_ACCOUNT_HOLDER: 'Est-ce que votre adresse est différente de celle du premier titulaire?',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_TITLE: 'Adresse de correspondance',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_ADD: 'Ajouter une adresse de correspondance différente de l\'adresse postale',
    ODC_STEP_PERSONAL_INFORMATION_MAIL_ADDRESS_REMOVE: 'Supprimer cette adresse de correspondance',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC: 'Un compte FRV ou FRVR ne peut être ouvert si vous n\'avez pas atteint l\'âge de 50 ans. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la date de naissance.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4AB_BC_TITLE: 'Âge limite pour l\'ouverture d’un compte FRV ou FRVR',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON: 'Un compte FRV ou FRVR ne peut être ouvert si vous n\'avez pas atteint l\'âge de 55 ans. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la date de naissance.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FRV4NS_ON_TITLE: 'Âge limite pour l\'ouverture d’un compte FRV ou FRVR',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI: 'Un compte CRI, CRIF, RERI, REIR ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la date de naissance.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_CRI_TITLE: 'Âge limite pour l\'ouverture d’un compte CRI, CRIF, RERI ou REIR',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_REER: 'Un compte REER ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la date de naissance.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_REER_TITLE: 'Âge limite pour l\'ouverture d’un compte REER',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA: 'Un compte CELIAPP ne peut être ouvert après le 31 décembre de l\'année où vous atteignez l\'âge de 71 ans. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la date de naissance.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_TITLE: 'Âge limite pour l\'ouverture d’un compte CELIAPP',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY_TITLE: 'Non résident du Canada',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_FHSA_COUNTRY: 'Un compte CELIAPP ne peut être ouvert pour un client non résident du Canada. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger l\'adresse de résidence.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV: 'Un compte RRNV ne peut être ouvert si vous n\'êtes pas résident de la province de Québec. Cliquez sur <strong>Continuer</strong> afin de retirer ce type de compte de la demande ou sur <strong>Annuler</strong> si vous désirez corriger la province.',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RRNV_TITLE: 'Province pour l\'ouverture d’un compte RRNV',
    ODC_STEP_PERSONAL_INFORMATION_MODAL_RESP_JOINT_SECOND_ACCOUNTHOLDER_SPOUSE: 'Pour ouvrir un compte REEE conjoint, le second titulaire doit être votre conjoint.',
    ODC_STEP_PERSONAL_INFORMATION_IS_SECOND_ACCOUNTHOLDER_SPOUSE: 'Est-ce que le second titulaire du compte est votre conjoint&nbsp;?',
    ODC_STEP_CONTACT_INFORMATION_TITLE: 'Coordonnées de la personne-contact',
    ODC_STEP_PERSONAL_INFORMATION_ADDRESS_RECOVERED: 'Adresse à votre dossier :',
    ODC_STEP_PERSONAL_INFORMATION_IS_CURRENT_ADDRESS_QUESTION: 'S\'agit-il de votre adresse de résidence actuelle ?',


    ODC_STEP_SPOUSE_INFORMATION_CLIENT_MARITAL_STATUS: 'État civil du client',
    ODC_STEP_SPOUSE_INFORMATION_TITLE: 'Informations sur le conjoint',
    ODC_STEP_SPOUSE_INFORMATION_MARITAL_STATUS: 'État civil',
    ODC_STEP_SPOUSE_EMPLOYER_NAME: 'Nom de l\'employeur',
    ODC_STEP_SPOUSE_JOB_TITLE: 'Titre de l\'emploi',
    ODC_STEP_SPOUSE_ACTIVITY_AREA: 'Secteur d\'activité',
    ODC_STEP_CONTRIBUTING_SPOUSE_INFORMATION_TITLE: 'Informations sur le conjoint cotisant',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_LABEL: 'Est-ce que le conjoint cotisant à votre {{order}} {{type}} est votre conjoint actuel ?',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_FIRST: 'premier',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_SECOND: 'second',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_THIRD: 'troisième',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_RRSP: 'REER',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_RRIF: 'FERR',
    CONTRIBUTING_SPOUSE_IS_CURRENT_SPOUSE_FTARRSP: 'RRNV',
    ODC_STEP_MARITAL_STATUS_VERIFICATION_TITTLE: 'État civil',
    ODC_STEP_MARITAL_STATUS_VERIFICATION_MESSAGE: 'Vous avez indiqué que le second titulaire du compte est votre conjoint.<br/> Sélectionnez l\'état civil approprié à la section État civil du client.',

    ODC_STEP_REFERRED_ACCOUNT_TITLE: 'Caisse Desjardins de référence',
    ODC_STEP_REFERRED_ACCOUNT_INSTITUTION: 'Numéro de l\'institution',
    ODC_STEP_REFERRED_ACCOUNT_TRANSIT: 'Numéro de transit',
    ODC_STEP_REFERRED_ACCOUNT_FirstName: 'Prénom',
    ODC_STEP_REFERRED_ACCOUNT_LastName: 'Nom',


    OCCUPATION_TITLE: 'Occupation et employeur',
    EMPLOYMENT_STATUS_LABEL: 'Statut d\'emploi',
    EMPLOYMENT_STATUS_SELF_EMPLOYED: 'Travailleur(e) autonome',
    ODC_STEP_OCCUPATION_MODAL_TITTLE: 'Modification du statut d\'emploi',
    ODC_STEP_OCCUPATION_MODAL_TEXT: 'La mise à jour ne peut être effectuée, car une modification du statut d\'emploi a été identifiée à la question <em> Êtes-vous à l\'emploi d\'un courtier en valeurs mobilières?.</em> Pour continuer, révisez votre réponse à cette question. S\'il y a un changement au statut d\'emploi, veuillez ouvrir une nouvelle racine de compte.',
    USER_EMPLOYER_IS_BROKER_LABEL: 'Êtes-vous à l\'emploi d\'un courtier en valeurs mobilières&nbsp;?',
    USER_EMPLOYER_IS_BROKER_NO: 'Non',
    USER_EMPLOYER_IS_BROKER_VMD: 'Oui: je travaille pour Valeurs mobilières Desjardins',
    USER_EMPLOYER_IS_BROKER_OTHER_BROKER: 'Oui: je travaille pour une autre firme que Valeurs mobilières Desjardins',
    USER_EMPLOYER_AUTORIZATION: 'Veuillez joindre une autorisation de votre employeur.',

    USER_IS_INVESTMENT_ADVISOR: 'Êtes-vous un conseiller&nbsp;?',

    USER_EMPLOYER_NAME: 'Nom de l\'employeur',
    USER_EMPLOYER_VMD: 'Valeurs mobilières Desjardins Inc.',
    USER_JOB_TITLE: 'Titre de l\'emploi',
    USER_JOB_TITLE_INVESTMENT_ADVISOR: 'Conseiller',
    USER_EMPLOYER_ACTIVITY: 'Secteur d\'activité',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE: 'Demeurez-vous à la même adresse qu\'un employé d\'une firme de courtage en valeurs mobilières&nbsp;?',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_VMD: 'Est-ce qu\'il s\'agit d\'un employé de VMD&nbsp;?',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRM_NAME: 'Nom de la firme',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_FIRSTNAME: 'Prénom de l\'employé',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LASTNAME: 'Nom de l\'employé',
    USER_SAME_ADDRESS_WITH_BROKER_EMPLOYEE_LINK: 'Lien',

    PERCENTAGE_OWNERSHIP_CLIENT: 'Pourcentage de détention du client',

    EMPLOYER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT: 'Les questions de cette section concernent les personnes physiques contrôlant ou étant bénéficiaires de plus de 25% de l’entité (VD162 section 4).',
    BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_VDX62_25_PERCENT: 'Les questions débutant par « Êtes-vous », concernent l’entité (V101 section 2) et/ou les personnes physiques contrôlant ou étant bénéficiaires de plus de 25% de l’entité (VD162 section 4).',
    BROKER_QUESTIONS_NATURAL_PERSONS_HOLDING_25_PERCENT: 'Les questions débutant par « Êtes-vous », concernent l’entité (GPD101 section 2) et/ou les personnes physiques contrôlant ou étant bénéficiaires de plus de 25% de l’entité (VD162 section 4).',

    // tax
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN: 'Numéro d\'identification fiscale (NIF)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TITLE: 'Lieu de résidence aux fins de l\'impôt',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_QUESTION: 'Quelle est votre citoyenneté ?',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA: 'Canadienne',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES: 'Américaine',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER: 'Autre',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_OTHER_CITIZENSHIP: 'Autre citoyenneté',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_RESIDENCE_QUESTION: 'Êtes-vous un visiteur temporaire des États-Unis (Snowbird)?',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_QUESTION: 'Veuillez cocher la ou les cases applicables à votre situation.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1: 'Je suis résident du Canada aux fins de l\'impôt.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2: 'Je suis résident des États-Unis aux fins de l\'impôt ou de citoyenneté américaine.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_TIN: 'Numéro d\'identification fiscale (NIF)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2_REASON: 'Raison si pas de NIF',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3: 'Je suis résident d\'une juridiction autre que le Canada ou les États-Unis aux fins de l\'impôt.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_COUNTRY: 'Pays',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_TIN: 'Numéro d\'identification fiscale (NIF)',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3_REASON: 'Raison si pas de NIF',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_TITTLE: 'Vérification d\'adresse',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_ADDRESS_VERIFICATION_MESSAGE: 'Le pays de votre adresse personnelle doit correspondre à votre pays de résidence pour fins fiscales.',
    ODC_STEP_RESIDENTIAL_ADDRESS_TAX_US_TIN_QUESTION: 'Si vous n\'avez pas de NIF américain, en avez-vous demandé un?',

    ODC_STEP_RESIDENTIAL_ADDRESS_ADD_2ND_COUNTRY: 'Ajouter un autre pays de résidence fiscale.',
    ODC_STEP_RESIDENTIAL_ADDRESS_REMOVE_2ND_COUNTRY: 'Retirer le 2ième pays',

    ATTORNEY_TITLE: 'Identification du mandataire',
    USER_OTHER_TRADING_PERSON_TITLE: 'Voulez-vous autoriser une autre personne que vous à négocier dans ce compte?',
    ATTORNEY_CLIENT_NUMBER: 'Numéro de client',
    ATTORNEY_CLIENT_RELATIONSHIP: 'Relation',
    ATTORNEY_CLIENT_RELATIONSHIP_TOOLTIP: 'Le mandataire doit être un membre de la famille immédiate, ce qui inclut : les conjoints, parents, enfants, frères, sœurs et tout membre de la famille vivant sous le même toit que le titulaire de compte.',
    ATTORNEY_ADDRESS_TITLE: 'Adresse du mandataire',
    ATTORNEY_FINANCE_TITLE: 'Institution financière',
    ATTORNEY_FINANCE_INSTITUTION: 'Quel est le nom de l\'institution financière du mandataire ?',
    ATTORNEY_FINANCE_TRANSIT: 'Numéro de transit',
    ATTORNEY_FINANCE_ACCOUNT: 'Numéro de compte',
    ATTORNEY_FINANCE_PHONE: 'Numéro de téléphone (facultatif)',
    ATTORNEY_INITIE: 'Est-ce que le mandataire est un initié assujetti d\'une compagnie dont les actions sont négociées en bourse ou sur un marché hors cote ?',
    ATTORNEY_INITIE_COMPANY_NAME: 'Nom de la compagnie',
    ATTORNEY_INITIE_COMPANY_SYMBOL: 'Symbole boursier',
    ATTORNEY_INITIE_MARKET: 'Marché',
    ATTORNEY_INITIE_MARKET_CAN: 'Canadien',
    ATTORNEY_INITIE_MARKET_US: 'Américain',
    ATTORNEY_MAINSHAREHOLDER: 'Est-ce que le mandataire est un actionnaire important d\'une compagnie dont les actions sont négociées en bourse ou sur un marché hors cote ?',
    ATTORNEY_MAINSHAREHOLDER_TOOLTIP: 'Selon les Règles Universelles d\'Intégrité du Marché (RUIM) de l\'OCRCVM, un Actionnaire important est une personne physique ou morale détenant, seule ou avec d\'autres, plus de 20 % des titres en circulation comportant droit de vote d\'un émetteur.',
    ATTORNEY_MAINSHAREHOLDER_COMPANY_NAME: 'Nom de la compagnie',
    ATTORNEY_MAINSHAREHOLDER_COMPANY_SYMBOL: 'Symbole boursier',
    ATTORNEY_MAINSHAREHOLDER_MARKET: 'Marché',
    ATTORNEY_MAINSHAREHOLDER_MARKET_CAN: 'Canadien',
    ATTORNEY_MAINSHAREHOLDER_MARKET_US: 'Américain',
    ATTORNEY_CHOICE_TITLE: 'Vous avez choisi d\'ajouter un mandataire',
    ATTORNEY_CHOICE_MESSAGE: 'Veuillez prendre note que le mandataire doit obligatoirement détenir un compte chez Desjardins Courtage en ligne pour être accepté.<br/><br/>Pour poursuivre l’identification du mandataire, cliquez sur <strong>Continuer</strong>. Si vous souhaitez poursuivre sans désignation de mandataire, cliquez sur <strong>Annuler</strong>. La réponse à la question précédente sera alors changée pour « Non ».',

    USER_FINANCE_INFO_TITLE: 'Informations financières',
    USER_FINANCE_INFO_ANNUAL_REVENU: 'Quel est votre revenu annuel de toutes sources ?',
    USER_FINANCE_INFO_TOTAL_NET_WORTH: 'Valeur nette totale',
    USER_FINANCE_INFO_TOTAL_NET_WORTH_INVALID: 'La valeur nette totale n\'a pu être récupérée. Remplissez les champs A et B pour l’obtenir.',
    USER_FINANCE_INFO_NEW_TOTAL_NET_WORTH: 'Nouvelle valeur nette totale (A + B)',
    USER_FINANCE_INFO_EDIT_TOTAL_NET_WORTH: 'Modifier la valeur nette totale',
    USER_FINANCE_INFO_EDIT_LOAN: 'Modifier l\'emprunt',
    USER_FINANCE_INFO_DELETE_TOTAL_NET_WORTH: 'Supprimer les champs de la nouvelle valeur nette totale',
    USER_FINANCE_INFO_DELETE_LOAN: 'Supprimer les champs de l\'emprunt',
    USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP_TITLE: 'Revenu annuel de toutes sources',
    USER_FINANCE_INFO_ANNUAL_REVENU_TOOLTIP: 'Le montant global des revenus d\'emploi, de placement ou autre source.',
    USER_FINANCE_INFO_IMMO_ASSETS: 'Quel est votre actif immobilisé net approximatif ?',
    USER_FINANCE_INFO_IMMO_ASSETS_A: 'Quel est votre actif immobilisé net approximatif ? (A)',
    USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP_TITLE: 'Actif immobilisé net approximatif',
    USER_FINANCE_INFO_IMMO_ASSETS_TOOLTIP: 'Les immobilisations comprennent les biens immobiliers et les biens commerciaux déduction faite des engagements (garantis et non garantis).',
    USER_FINANCE_INFO_LIQUID_ASSETS: 'Quel est votre actif liquide net approximatif ?',
    USER_FINANCE_INFO_LIQUID_ASSETS_B: 'Quel est votre actif liquide net approximatif ? (B)',
    USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP_TITLE: 'Actif liquide net approximatif',
    USER_FINANCE_INFO_LIQUID_ASSETS_TOOLTIP: 'Les actifs liquides sont ceux qui peuvent être facilement convertis en espèce.',
    USER_FINANCE_INFO_INSTITUTION: 'Quel est le nom de votre institution financière ?',
    USER_FINANCE_INFO_TRANSIT: 'Numéro de transit',
    USER_FINANCE_INFO_ACCOUNT: 'Numéro de compte',
    USER_FINANCE_INFO_PHONE: 'Téléphone institution financière (facultatif)',
    USER_FINANCE_INFO_OPTION_ACCOUNT_TITLE: 'Compte d\'options',
    USER_FINANCE_INFO_OPTION_CODE: 'Code d\'option autorisé pour le compte non enregistré',
    USER_FINANCE_INFO_REGISTERED_OPTION_CODE: 'Code d\'option autorisé pour le compte enregistré',

    ODC_STEP_FINANCIAL_INFORMATION_DATE_HELP: 'aaaa-mm-jj',

    USER_BORROWING_FOR_INVESTMENT_PURPOSE_TITLE: 'Emprunts à des fins de placement',
    USER_BORROWING_FOR_INVESTMENT_PURPOSE_QUESTION: 'Avez-vous emprunté des fonds pour financer vos achats de produits de placement par le biais de prêts avancés par des tiers?',
    USER_BORROWING_AMOUNT_OF_LOAN: 'Montant du prêt',
    USER_BORROWING_INTEREST_RATE: 'Taux d\'intérêt',
    USER_BORROWING_LOAN_STARTING_DATE: 'Date de début du prêt',
    USER_BORROWING_LOAN_MATURITY_DATE: 'Date d\'échéance du prêt',
    USER_BORROWING_AMOUNT_INVESTED: 'Montant investi à titre d’emprunts à des fins de placement',
    USER_BORROWING_RECEIVED_AND_READ_BORROWING_RISKS_INDICATOR: 'J’ai reçu de mon conseiller des explications à l’égard des risques liés à l’effet de levier de l’emprunt à des fins de placement. J’ai obtenu et compris l’information de la section « Mises en garde – Emprunts à des fins de placement sans inscription aux livres » du Document d’information.',

    USER_INVESTMENT_OBJECTIVES_INTENDED_USE_TITLE: 'Utilisation prévue du compte',
    USER_INVESTMENT_OBJECTIVES_INVESTMENT_HORIZON_TITLE: 'Horizon de placement',
    USER_INVESTMENT_OBJECTIVES_RISK_TOLERANCE_TITLE: 'Profil de risque',
    USER_INVESTMENT_OBJECTIVES_TITLE: 'Objectifs de placements consolidés',
    USER_INVESTMENT_OBJECTIVES_INTENDED_USE: 'Utilisation prévue',
    USER_INVESTMENT_OBJECTIVES_NON_REG_INV_HORIZON: 'Horizon de placement',
    USER_INVESTMENT_OBJECTIVES_REG_INV_HORIZON: 'Horizon de placement',
    USER_INVESTMENT_OBJECTIVES_RSK_TOL: 'Profil de risque',
    USER_INVESTMENT_OBJECTIVES_TABLE_COL1: 'Objectifs de placements',
    USER_INVESTMENT_OBJECTIVES_TABLE_COL2: 'Comptes enregistrés',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW1: 'Titres à revenus à faible risque',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW2: 'Titres à revenus à risque modéré',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW3: 'Titres à revenus et titres de croissance, de risque modéré à risque élevé',
    USER_INVESTMENT_OBJECTIVES_TABLE_ROW4: 'Titres à risque très élevé',
    USER_INVESTMENT_INCLUDE_INVESTOR_PROFILE: 'Inclure le Profil de l\'investisseur',

    BROKERAGE_TITLE: 'Comptes de courtage',
    BROKERAGE_OTHER_ACCOUNTS_TITLE: 'Détenez-vous ou contrôlez-vous la négociation d\'autres comptes de courtage?',
    BROKERAGE_FIRM_NAME: 'Nom de la firme',
    BROKERAGE_ACCOUNT_TYPE: 'Type de compte',
    BROKERAGE_USER_REPORTING_INSIDER_TITLE: 'Êtes-vous un initié assujetti d\'une compagnie dont les actions sont négociées en Bourse ou sur un marché hors cote?',
    BROKERAGE_MAIN_SHAREHOLDER_TITLE: 'Êtes-vous un actionnaire important d\'une compagnie dont les actions sont négociées en Bourse ou sur un marché hors cote?',
    BROKERAGE_COMPAGNY_NAME: 'Nom de la compagnie',
    BROKERAGE_COMPANY_SYMBOL: 'Symbole boursier',
    BROKERAGE_MARKET: 'Marché',
    BROKERAGE_MARKET_CAN: 'Canadien',
    BROKERAGE_MARKET_US: 'Américain',
    BROKERAGE_EPV_NPV_TITLE: 'Êtes-vous, ou avez-vous été, un <i>National politiquement vulnérable</i> (NPV) (au cours des 5 dernières années), un <i>Étranger politiquement vulnérable</i> (EPV) ou un <i>Dirigeant d\'organisation internationale</i> (DOI)? Ou bien, êtes-vous une <i>Personne étroitement associée</i> (PEA) à une personne appartenant à l\'une de ces catégories (membre de la famille ou personne étroitement associée) ?',
    BROKERAGE_FINANCIAL_INTERESET_TITLE: 'Le compte est-il destiné à être utilisé par un tiers (personne ou entité, autre que le détenteur du compte ou la personne autorisée à y donner des instructions) ou en son nom ?',
    BROKERAGE_TRUSTED_PERSON: 'Voulez-vous désigner une personne de confiance dans ce compte ?',
    BROKERAGE_PURPOSE: 'Quelle est l\'utilisation prévue du compte non enregistré?',
    BROKERAGE_PURPOSE_OTHER: 'Veuillez préciser',
    BROKERAGE_KNOWLEDGE: 'Quelles sont vos connaissances en matière de placement?',
    BROKERAGE_CREDIT_BALANCE_TITLE: 'Soldes créditeurs',
    BROKERAGE_CREDIT_BALANCE: 'Soldes créditeurs',
    BROKERAGE_CREDIT_BALANCE_OPTIONAL: 'Soldes créditeurs (facultatif)',
    BROKERAGE_DIRECT_DEPOSIT_AUTH: 'J\'autorise, par la présente, Valeurs mobilières Desjardins inc. à effectuer des dépôts directs dans mon compte.',
    DIRECT_DEPOSIT: 'Dépôts directs',
    DIRECT_DEPOSIT_DIRECT_WITHDRAWAL: 'J’autorise par la présente Fiducie Desjardins inc. à effectuer des:',
    DIRECT_DEPOSIT_YES: 'Oui, joindre un spécimen de chèque.',
    DIRECT_DEPOSIT_TO_ACCOUNT: 'Autorisation de retrait / dépôt direct au compte bancaire',
    DIRECT_WITHDRAWAL: 'Retraits directs',

    // legal
    ODC_STEP_LEGAL_SHAREHOLDER_DESC_GPD: 'Je reconnais avoir lu et compris les explications concernant ' +
        'le « Règlement sur la communication avec les propriétaires véritables des titres d\'un émetteur assujetti » ' +
        'inclus dans le Document d\'information sur la relation avec les clients et Conventions. Je reconnais que les choix indiqués ' +
        'ci-après s\'appliqueront à tous les titres détenus dans mes comptes chez Gestion Placements Desjardins inc. (GPD), à moins ' +
        'd\'indication contraire de ma part.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_GPD_1: 'à ce que GPD communique mon nom, mes adresses postale et électronique, ' +
        'mon choix de langue de communication ainsi que les titres que je détiens aux émetteurs de ces titres et à ' +
        'd\'autres personnes ou sociétés, conformément aux lois applicables au domaine des valeurs mobilières.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_1: 'Le service «Présence» est un service d\'assistance ' +
        'téléphonique fourni par Sigma Assistel inc., une compagnie externe à GPD, avec laquelle GPD a conclu ' +
        'une entente afin que ses clients puissent bénéficier de l\'offre décrite dans la brochure intitulée ' +
        'Le service Présence qui a été remise au client lors de l\'ouverture de compte.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_2: 'à ce que GPD transmette les renseignements suivants ' +
        'me concernant: nom, adresse, numéro de téléphone et date de naissance à Sigma Assistel inc. afin que ' +
        'cette dernière puisse m\'identifier. Je comprends que pour me prévaloir de ce service, je dois consentir ' +
        'à ce que GPD transmette les renseignements mentionnés.',
    ODC_STEP_LEGAL_SHAREHOLDER_PresenceService_DESC_GPD_3: 'Dans le cas où je ne consens pas, je comprends et ' +
        'accepte que je ne puisse bénéficier de l\'offre du service «Présence».',

    SIDEBAR_SHAREHOLDER_TITLE: 'À consulter',
    SIDEBAR_SHAREHOLDER_CONVENTION: 'Convention générale de compte et Conventions spécifiques (PDF, 546 ko)',
    SIDEBAR_SHAREHOLDER_CONVENTION_LINK: 'https://secure.disnat.com/xd/fr/help/pdf/conventions_de_comptes.pdf',

    ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING_TITLE: 'Avertissement',
    ODC_STEP_LEGAL_SHAREHOLDER_MSG_WARNING: 'Les instructions sélectionnées diffèrent de celles des autres comptes détenus' +
        ' par le client. En confirmant cette sélection, elle s\ ’appliquera maintenant à l\ ’ensemble des comptes.',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE: 'Communication avec les propriétaires véritables',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE_GPD: 'Autorisation à la transmission des renseignements personnels du client ' +
        ' dans le cadre du service Présence',
    ODC_STEP_LEGAL_SHAREHOLDER_TITLE_Communication_GPD: 'Communication avec les propriétaires véritables',
    ODC_STEP_LEGAL_SHAREHOLDER_DESC: 'Je reconnais avoir lu et compris les explications concernant le « Règlement sur la ' +
        'communication avec les propriétaires véritables des titres d\'un émetteur assujetti » inclus dans le Document ' +
        'd\'information sur la relation avec les clients et Conventions. Je reconnais que les choix indiqués ci-après ' +
        's\'appliqueront à tous les titres détenus dans mes comptes chez Desjardins Courtage en ligne (DCL), à moins ' +
        'd\'indication contraire de ma part.',
    ODC_STEP_LEGAL_SHAREHOLDER_DESC_PLEIN_EX: 'Je reconnais avoir lu et compris les explications concernant le ' +
        '« Règlement sur la communication avec les propriétaires véritables des titres d\'un émetteur assujetti » inclus ' +
        'dans le Document d\'information sur la relation avec les clients et Conventions. Je reconnais que les choix ' +
        'indiqués ci-après s\'appliqueront à tous les titres détenus dans mes comptes chez Valeurs mobilières ' +
        'Desjardins inc. (VMD), à moins d\'indication contraire de ma part.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_LABEL: 'Je soussigné :',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_QUESTION: 'Partie 1 – Communication de renseignements sur la propriété véritable',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC: 'à ce que DCL communique mon nom, mes adresses postale et électronique, mon choix ' +
        'de langue de communication ainsi que les titres que je détiens aux émetteurs de ces titres et à d\'autres personnes ou ' +
        'sociétés, conformément aux lois applicables au domaine des valeurs mobilières.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_DESC_PLEIN_EX: 'à ce que VMD communique mon nom, mes adresses postale et ' +
        'électronique, mon choix de langue de communication ainsi que les titres que je détiens aux émetteurs de ces ' +
        'titres et à d\'autres personnes ou sociétés, conformément aux lois applicables au domaine des valeurs mobilières.',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT: 'Je consens',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT: 'Je ne consens pas',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_CONSENT_SHORT: 'consens',
    ODC_STEP_LEGAL_SHAREHOLDER_BENEFICIAL_NOCONSENT_SHORT: 'ne consens pas',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_QUESTION: 'Partie 2 – Réception de documents pour les porteurs de titres',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1: 'Je souhaite recevoir <strong>TOUS</strong> les documents pour les porteurs de titres transmis aux propriétaires véritables de titres.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2: 'Je ne souhaite recevoir <strong>AUCUN</strong> des documents pour les porteurs de titres transmis aux propriétaires véritables de titres.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3: 'Je ne souhaite recevoir <strong>QUE</strong> les documents reliés aux procurations transmis en vue des assemblées extraordinaires.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1_FORMATTED: 'Je souhaite recevoir TOUS les documents pour les porteurs de titres transmis aux propriétaires véritables de titres.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2_FORMATTED: 'Je ne souhaite recevoir AUCUN des documents pour les porteurs de titres transmis aux propriétaires véritables de titres.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3_FORMATTED: 'Je ne souhaite recevoir QUE les documents reliés aux procurations transmis en vue des assemblées extraordinaires.',
    ODC_STEP_LEGAL_SHAREHOLDER_RECEIPT_DESC: 'Même si je ne souhaite pas recevoir ces types de documents, je comprends que l\'émetteur assujetti ou une autre personne ou société a le droit de me les faire parvenir à ses frais.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_QUESTION: 'Partie 3 – Consentement à la transmission électronique de documents.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE1: 'Je souhaite recevoir les documents par voie électronique.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_CHOICE2: 'Je ne souhaite pas recevoir les documents par voie électronique.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1: 'Je souhaite recevoir les documents par voie électronique.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE2: 'Je ne souhaite pas recevoir les documents par voie électronique.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_DESC: 'Consentement relatif à la transmission électronique de documents ' +
        'selon les normes prévues au Consentement à la transmission électronique de documents. Mon adresse ' +
        'électronique est indiquée à l\'étape 2 Identification du titulaire de compte.',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_TITLE: 'Adresse électronique requise',
    ODC_STEP_LEGAL_SHAREHOLDER_ELECTRONIC_REVIEW_CHOICE1_MODAL_DESC: 'Vous avez sélectionné « Je souhaite recevoir les documents par voie électronique ». Il semble toutefois qu’aucune adresse courriel n’ait été indiquée précédemment. Cliquez sur  <strong>Continuer</strong> pour poursuivre sans que les documents ne soient envoyés par voie électronique ou sur <strong>Annuler</strong> si vous désirez ajouter une adresse électronique.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TITLE: 'COMPTE D\'OPTIONS',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER: 'Détenez-vous un compte d\'options auprès d\'un autre courtier en valeurs mobilières?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_BROKER_FIRM: 'Nom de la firme',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE: 'Avez-vous de l\'expérience dans la négociation de produits dérivés?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_YEAR: 'Nombre d\'année(s)',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_QUESTION_EXPERIENCE_KNOWLEDGE: 'Possédez-vous des connaissances théoriques en matière de négociation des produits dérivés?',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_SPECIFY_EXPERIENCE: 'Veuillez préciser',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_SELECTION: 'Veuillez sélectionner les situations applicables',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE: 'Types d\'opérations',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_TYPE_HELP: 'Les types d’opérations sur options, comme la vente d’options à découvert ou les opérations mixtes, nécessitent une expérience en négociation d’options. Lorsque vous sélectionnez un type d’opération sur options, veuillez sélectionner les types d’opérations précédentes.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_EXPERIENCE: 'J\'ai de l\'expérience',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_HEADER_PLANNING: 'Je prévois faire ces types d\'opérations',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1: 'Acquisition d\'options d\'achat et d\'options de vente',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL1_HELP: 'Achat d’une option donnant le droit d’acheter ou vendre une quantité d’actions sous-jacentes à un prix fixé d’avance au vendeur de l’option jusqu’à l’expiration de celle-ci.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2: 'Vente d\'options couvertes',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL2_HELP: 'Vente d’une option d’achat et détention simultanée d’un nombre équivalent du titre sous-jacent.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3: 'Opérations mixtes',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL3_HELP: 'Achat ou vente de deux contrats d’options ou plus sur un titre sous-jacent en fonction du prix de levée et de la date d’expiration de l’option afin de bénéficier de la hausse ou de la baisse du titre.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4: 'Vente d\'options à découvert',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_TABLE_ROW_LEVEL4_HELP: 'Vente d’une option d’achat ou de vente sans détenir la quantité correspondante du titre sous-jacent ou les fonds nécessaires pour satisfaire à l’obligation de vente ou d’achat.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_TITLE: 'Compte d\'options',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_CONFIRM_MODAL_BODY: 'Vous devez avoir des connaissances théoriques pour transiger des options. Cliquez sur <b>Continuer</b> afin de retirer ce type de compte de la demande ou sur <b>Annuler</b> si vous désirez corriger votre demande.',
    ODC_STEP_LEGAL_OPTION_ACCOUNT_MODAL_BODY_TYPES_OF_OPTION_TRADES: 'Le type d\'opérations que vous prévoyez faire implique les types d\'opération suivants :',
    ODC_STEP_LEGAL_USER_OTHER_TRADING_PERSON_TITLE: 'Est-ce que d\'autres personnes ont une autorisation de négociation dans ce compte?',
    ODC_STEP_LEGAL_USER_OTHER_GUARANTING_PERSON_TITLE: 'Est-ce que d\'autres personnes garantissent ce compte?',

    ODC_BENEFICIARY_RRSP_TITLE: 'Compte REER',
    ODC_BENEFICIARY_TFSA_TITLE: 'Compte CELI',
    ODC_BENEFICIARY_FHSA_TITLE: 'CELIAPP',
    ODC_BENEFICIARY_RESP_TITLE: 'Compte REEE',
    ODC_BENEFICIARY_RRSP_QUESTION: 'Souhaitez-vous nommer un bénéficiaire au produit de votre régime?',
    ODC_BENEFICIARY_TFSA_QUESTION: 'Voulez-vous désigner votre conjoint comme titulaire remplaçant de votre compte advenant votre décès?',
    ODC_BENEFICIARY_FHSA_QUESTION: 'Souhaitez-vous désigner votre époux ou conjoint de fait à titre de titulaire remplaçant à votre décès ?',
    ODC_BENEFICIARY_FHSA_QUESTION_2: 'Souhaitez-vous désigner une ou des personnes comme bénéficiaire(s) des prestations exigibles à votre décès ?',
    ODC_BENEFICIARY_RESP_QUESTION: 'Tous les bénéficiaires sont-ils frères et soeurs ?',
    ODC_BENEFICIARY_RESP_BENEFICIARY_ADDRESS_QUESTION: 'Est-ce que l\'adresse du bénéficiaire est différente de celle du souscripteur ?',
    ODC_BENEFICIARY_TITLE: 'Bénéficiaire',
    ODC_SUCCESSOR_TITLE: 'Titulaire Remplaçant',
    ODC_STEP_BENEFICIARY_PARENT_LINK: 'Lien de parenté',
    ODC_STEP_BENEFICIARY_PART: 'Part (%)',
    ODC_STEP_BENEFICIARY_DELETE: 'Supprimer ce bénéficiaire',
    ODC_STEP_BENEFICIARY_ADD: 'Ajouter un bénéficiaire',
    ODC_STEP_PARENT_ADD: 'Ajouter un parent',
    ODC_STEP_PARENT_DELETE: 'Supprimer le parent',
    ODC_BENEFICIARY_RRSP_SHARE_WITHOUT_BENEFICIARY: 'Veuillez prendre note que si les pourcentages ne totalisent pas 100%, la portion restante sera versée à votre succession.',
    ODC_BENEFICIARY_RESP_REASON: 'Raison',
    ODC_BENEFICIARY_SIN: 'N.A.S. (facultatif)',
    ODC_BENEFICIARY_SIN_PLEINEX: 'N.A.S.',
    ODC_BENEFICIARY_CONTRACT_OPENING_DATE: 'Date d\'ouverture du contrat',
    ODC_BENEFICIARY_CONTRACT_OPENING_DATE_HELP: 'aaaa-mm-jj',
    ODC_BENEFICIARY_CESG_FOR_RESP: 'Ne pas demander la SCEE supplémentaire pour le présent REEE',
    ODC_BENEFICIARY_CLB_FOR_RESP: 'Ne pas demander la BEC pour le présent REEE',
    ODC_BENEFICIARY_CUSTODIAL_PARENT_IDENTIFICATION: 'Identification du parent ayant la garde',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_FIRST_NAME: 'Prénom du responsable',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_LAST_NAME: 'Nom du responsable',
    ODC_BENEFICIARY_PRIMARY_CAREGIVER_SIN: 'NAS du responsable',
    ODC_BENEFICIARY_DISTRIBUTION_RATIO: 'Ratio de distribution',

    // transfer choice
    TRANSFER_REQUEST_CHOICE_TITLE: 'Transfert depuis une autre institution financière',
    TRANSFER_REQUEST_CHOICE_LABEL: ' Désirez-vous effectuer un transfert d’un compte de placement provenant de chez Desjardins ou d\'une autre institution financière?',
    TRANSFER_REQUEST_CHOICE_LIST_LABEL: 'Veuillez cocher les comptes pour lesquels un transfert est nécessaire',
    TRANSFER_REQUEST_CHOICE_COPY_LABEL: 'copie(s)',
    ACCOUNT_TYPE_LABEL_CASH: 'Comptant',
    ACCOUNT_TYPE_LABEL_MARGIN: 'Marge',
    ACCOUNT_TYPE_LABEL_MARGIN_OPTION: 'Marge avec options',
    ACCOUNT_TYPE_LABEL_MARGIN_SHORT_SELL: 'Marge avec ventes à découvert',
    ACCOUNT_TYPE_LABEL_MARGIN_OPTION_SHORT_SELL: 'Marge avec options et ventes à découvert',
    ACCOUNT_TYPE_LABEL_RRSP: 'REER',
    ACCOUNT_TYPE_LABEL_TFSA: 'CELI',
    ACCOUNT_TYPE_LABEL_FHSA: 'CELIAPP',
    ACCOUNT_TYPE_LABEL_RESP: 'REEE',
    ACCOUNT_TYPE_LABEL_LIRA: 'CRI',
    ACCOUNT_TYPE_LABEL_RRIF: 'FERR',
    ACCOUNT_TYPE_LABEL_LIF: 'FRV',
    ACCOUNT_TYPE_LABEL_FTARRSP: 'RRNV',
    ACCOUNT_TYPE_LABEL_JOINT: 'Compte conjoint',
    ACCOUNT_TYPE_LABEL_ASSOCIATION: 'Compte autre (Association de personnes)',
    ACCOUNT_TYPE_LABEL_CLUB: 'Compte autre (Club de placement)',
    ACCOUNT_TYPE_LABEL_COOP: 'Compte autre (Coopérative ou syndicat de copropriété)',
    ACCOUNT_TYPE_LABEL_CORPO: 'Compte autre (Corporation (inc.), Compagnie (ltée), Société par actions (compagnie))',
    ACCOUNT_TYPE_LABEL_CO_UNIQUE: 'Compte autre (Entreprise individuelle)',
    ACCOUNT_TYPE_LABEL_CURATELLE: 'Compte autre (Curatelle (ne s\'applique pas aux résidents du Québec))',
    ACCOUNT_TYPE_LABEL_ENF_ES_QUALITES: 'Compte autre (Enfant mineur (ès qualités de tuteur - sans jugement))',
    ACCOUNT_TYPE_LABEL_ENF_REF: 'Compte autre (Enfant mineur en référence)',
    ACCOUNT_TYPE_LABEL_FIDUCIE: 'Compte autre (Fiducie)',
    ACCOUNT_TYPE_LABEL_MANDATAIRE: 'Compte autre (Personne inapte (ès qualités de mandataire - Québec))',
    ACCOUNT_TYPE_LABEL_OSBL: 'Compte autre (Organisme sans but lucratif (OSBL), incluant les fondations)',
    ACCOUNT_TYPE_LABEL_SENC: 'Compte autre (Société en nom collectif (S.E.N.C.) ou à responsabilité limitée (S.E.N.C.R.L))',
    ACCOUNT_TYPE_LABEL_SOC_COMM: 'Compte autre (Société en commandite (S.E.C.))',
    ACCOUNT_TYPE_LABEL_SUCC: 'Compte autre (Succession)',
    ACCOUNT_TYPE_LABEL_TUTELLE: 'Compte autre (Tutelle)',
    ACCOUNT_TYPE_LABEL_RRI: 'Régime de retraite individuel (RRI)',
    ACCOUNT_TYPE_LABEL_ORG_GOUV: 'Compte autre (Organisme gouvernemental)',

    // transfer request
    TRANSFER_REQUEST_TITLE: 'Demande de transfert',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_CASH: 'Compte Comptant',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_MRGN: 'Compte Marge',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RRSP: 'Compte REER',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_TFSA: 'Compte CELI',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_FHSA: 'Compte CELIAPP',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RESP: 'Compte REEE',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_LIRA: 'Compte CRI',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_RRIF: 'Compte FERR',
    TRANSFER_FORM_TITLE_ACCOUNT_TYPE_LIF: 'Compte FRV',
    TRANSFER_FORM_BANK_TITLE: 'Identification de l\'institution cédante',
    TRANSFER_FORM_ADD: 'Ajouter une institution cédante',
    TRANSFER_FORM_REMOVE: 'Supprimer cette institution cédante',
    TRANSFER_FORM_ACCOUNT_TYPE: 'Type de compte',
    TRANSFER_FORM_BANK_NAME: 'Nom de l\'institution cédante',
    TRANSFER_FORM_BANK_RES_FIRST_NAME: 'Prénom de la personne ressource',
    TRANSFER_FORM_BANK_RES_LAST_NAME: 'Nom de la personne ressource',
    TRANSFER_FORM_BANK_PHONE: 'Numéro de téléphone',
    TRANSFER_FORM_ADDRESS_TITLE: 'Adresse de l\'institution cédante',
    TRANSFER_FORM_ACCOUNT_TITLE: 'Compte chez le cédant',
    TRANSFER_FORM_ACCOUNT_ADD: 'Ajouter un deuxième compte chez le cédant',
    TRANSFER_FORM_ACCOUNT_REMOVE: 'Supprimer ce compte',
    TRANSFER_FORM_REQUEST_TITLE: 'Transfert demandé',
    TRANSFER_FORM_REQUEST_TYPE: 'Type de transfert',
    TRANSFER_FORM_REQUEST_TYPE_TOTAL: 'Total',
    TRANSFER_FORM_REQUEST_TYPE_PARTIAL: 'Partiel',
    TRANSFER_FORM_REQUEST_INSTRUCTION: 'Instructions de transfert',
    TRANSFER_FORM_REQUEST_INSTRUCTION_CASH: 'En argent',
    TRANSFER_FORM_REQUEST_INSTRUCTION_KIND: 'En titres',
    TRANSFER_FORM_REQUEST_INSTRUCTION_MIXED: 'Mixte',
    TRANSFER_FORM_REQUEST_TRANSFER_CASH: 'Montant à transférer',
    TRANSFER_FORM_SECURITY_TITLE: 'Liste des titres à transférer',
    TRANSFER_FORM_SECURITY_ADD: 'Ajouter des titres à transférer',
    TRANSFER_FORM_SECURITY_DESCRIPTION: 'Description',
    TRANSFER_FORM_SECURITY_SYMBOL: 'Symbole',
    TRANSFER_FORM_SECURITY_QUANTITY: 'Quantité',
    TRANSFER_FORM_SECURITY_TYPE: 'Type',
    TRANSFER_FORM_SECURITY_TYPE_KIND: 'En titre',
    TRANSFER_FORM_SECURITY_TYPE_SELL: 'Titre à vendre',
    TRANSFER_FORM_ACCOUNTSTATEMENT: 'Veuillez fournir une copie de votre plus récent relevé de compte afin de faciliter le processus de transfert.',
    TRANSFER_FORM_UPLOAD: 'Joindre',

    UNSTRUCTURED_ADDRESS_TITLE: 'Adresse non structurée',
    UNSTRUCTURED_ADDRESS_NOTICE: 'L’adresse récupérée dans OSS n’a pas été reconnue, car sa structure diffère de celle du système de Postes Canada. Pour compléter la mise à jour de votre client, veuillez la saisir de nouveau en remplissant tous les champs prévus à cet effet.',

    ACCOUNT_FORM_ACCOUNT_NUMBER: 'Numéro de compte chez le cédant',
    ACCOUNT_FORM_ACCOUNT_TYPE: 'Type de compte',
    ACCOUNT_FORM_ACCOUNT_CURRENCY: 'Devise du compte',

    CURATORSHIP_RESIDENT_OF_QUEBEC_TITLE: 'Curatelle - Résidents du Québec',
    CURATORSHIP_RESIDENT_OF_QUEBEC_BODY: 'Vous avez sélectionné Québec comme province de résidence. Depuis le 1er novembre 2022, il n’est plus possible d’ouvrir un compte de curatelle au Québec. Veuillez modifier la province de résidence ou changer le type de compte pour Tutelle.',

    ACCOUNT_FORM_ACCOUNT_OPTION_CASH: 'Comptant',
    ACCOUNT_FORM_ACCOUNT_OPTION_MARGIN: 'Marge',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_OPT: 'Marge avec options',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_SHT_SEL: 'Marge avec ventes à découvert',
    ACCOUNT_FORM_ACCOUNT_OPTION_MRG_OPT_SHT: 'Marge avec options et ventes à découvert',
    ACCOUNT_FORM_ACCOUNT_OPTION_RRSP: 'REER',
    ACCOUNT_FORM_ACCOUNT_OPTION_TFSA: 'CELI',
    ACCOUNT_FORM_ACCOUNT_OPTION_FHSA: 'CELIAPP',
    ACCOUNT_FORM_ACCOUNT_OPTION_RESP: 'REEE',
    ACCOUNT_FORM_ACCOUNT_OPTION_LIRA: 'CRI',
    ACCOUNT_FORM_ACCOUNT_OPTION_RRIF: 'FERR',
    ACCOUNT_FORM_ACCOUNT_OPTION_LIF: 'FRV',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_TITLE: 'Compte d\'options',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_MSG: 'La vente d’options à découvert nécessite l’ouverture d’un compte marge à découvert. Cliquez sur <strong>Continuer</strong> afin de poursuivre et demander l’ouverture d’un compte marge à découvert. Cliquez sur <strong>Annuler</strong> pour retirer la demande de vente d\'options à découvert.',
    ACCOUNT_FORM_ACCOUNT_OPTIONS_SPREADS_MSG: 'Les opérations mixtes nécessitent l’ouverture d’un compte marge à découvert. Cliquez sur <strong>Continuer</strong> afin de poursuivre et demander l’ouverture d’un compte marge à découvert. Cliquez sur <strong>Annuler</strong> pour retirer la demande d\'opérations mixtes.',

    // Agreement
    AGREEMENT_COLLECTION_AND_USE_TITLE: 'Collecte, utilisation et partage de vos renseignements personnels',
    AGREEMENT_DISCLOSURE_AND_ACKNOWLEDGEMENT_TITLE: 'Déclaration et consentements du client',
    AGREEMENT_CONSENT_AND_DELIVERY_ELECTRONIC_DOC_TITLE: 'Consentement à la transmission électronique de document et conditions d’utilisation',
    AGREEMENT_CONSENT_HELP_LABEL: 'Résumé',
    SPECIFIC_CONSENT_TITLE: 'Consentement spécifique',

    TRANSMISSION_METHOD_ESIGN: 'En ligne',
    TRANSMISSION_METHOD_ESIGN_TITLE: 'Pour effectuer la signature électronique:',
    TRANSMISSION_METHOD_ESIGN_LINE1: 'Le client devra utiliser son propre téléphone cellulaire.',
    TRANSMISSION_METHOD_ESIGN_LINE2: 'Il devra avoir accès à sa boîte de courriel.',
    TRANSMISSION_METHOD_ESIGN_LINE3: 'Vous devrez joindre une copie <strong>numérisée</strong> de la pièce d’identité et du spécimen de chèque du client.',
    TRANSMISSION_METHOD_MAIL: 'Par la poste',
    TRANSMISSION_METHOD_MAIL_CAISSE: 'Par courrier',
    TRANSMISSION_METHOD_MAIL_TITLE: 'Pour signer les documents papier:',
    TRANSMISSION_METHOD_MAIL_LINE1: 'Les documents originaux doivent être envoyés par courrier dans les plus brefs délais.',
    TRANSMISSION_METHOD_MAIL_LINE2: 'Vous devrez joindre une <strong>photocopie</strong> de la pièce d’identité et du spécimen de chèque du client.',
    TRANSMISSION_MAIL_STEP1: 'Téléchargez la trousse de formulaires.',
    TRANSMISSION_MAIL_JOINT_STEP1: 'Cliquez sur <strong>Continuer</strong> pour accéder à la prochaine étape afin de recevoir la trousse de' +
        ' formulaires par courriel.',
    TRANSMISSION_MAIL_STEP2: 'Référez-vous à l\'aide mémoire pour la liste des documents à joindre aux formulaires.',
    TRANSMISSION_MAIL_STEP3: 'Imprimez, remplissez les formulaires non pris en charge par le formulaire interactif web et signez tous les champs requis.',
    TRANSMISSION_MAIL_JOINT_STEP3: 'Imprimez, remplissez et signez tous les champs requis de chaque formulaire.',
    TRANSMISSION_MAIL_STEP4: 'Envoyez tous les documents et formulaires originaux dûment remplis et signés par la poste dans les 40 prochains jours.',
    TRANSMISSION_MAIL_STEP4_CAISSE: 'Envoyez tous les documents et formulaires originaux dûment remplis et signés par courrier dans les plus brefs délais.',
    TRANSMISSION_MAIL_JOINT_STEP4: 'Envoyez tous les documents et formulaires originaux dûment remplis et signés par la poste.',
    TRANSMISSION_WHAT_NEXT: 'Que faire maintenant?',
    TRANSMISSION_MAIL_DOWNLOAD_BTN: 'Imprimer la trousse de formulaires',
    TRANSMISSION_ESIGN_MAIL_DOWNLOAD_BTN: 'Imprimer la trousse de formulaires',
    TRANSMISSION_ESIGN_MAIL_EMAIL_BTN: 'Recevez cette trousse par courriel',
    TRANSMISSION_MAIL_DOWNLOAD_WEIGHT: '(PDF, 3 Mo)',
    TRANSMISSION_MAIL_ADDRESS_TITLE: 'Adresses d\'envoi',
    TRANSMISSION_MAIL_ADDRESS_TITLE_CAISSE: 'Adresse d\'envoi',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL1: 'Montréal',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL2: '1170, rue Peel, bureau 105',
    TRANSMISSION_MAIL_ADDRESS_MONTREAL3: 'Montréal (Québec) H3B 0A9',
    TRANSMISSION_MAIL_ADRESS_QUEBEC1: 'Québec',
    TRANSMISSION_MAIL_ADRESS_QUEBEC2: 'Place de la Cité',
    TRANSMISSION_MAIL_ADRESS_QUEBEC3: '2600, boul. Laurier, bureau 130',
    TRANSMISSION_MAIL_ADRESS_QUEBEC4: 'Québec (Québec) G1V 4T3',

    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL: 'Veuillez fournir une copie numérisée des documents suivants en format JPEG, PNG ou PDF.&nbsp;:',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_CHECK_SAMPLE_LABEL: 'Spécimen de chèque personnalisé',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL: 'Pièce d\'identité gouvernementale, avec photo et valide, parmi les choix suivants&nbsp;:',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_LABEL_CAISSE: 'Veuillez fournir une copie numérisée des documents suivants en format PDF, PNG ou JPEG&nbsp;:',
    TRANSMISSION_ATTACHED_DOCUMENT_ITEM_IDENTITY_PIECE_LABEL_CAISSE: 'Pièce d’identité gouvernementale avec photo non expirée (sélectionnée à l\'étape 8)',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_INSTRUCTIONS: 'Pour ouvrir un compte, vous devez joindre à votre demande un spécimen de chèque et 2 documents différents permettant de vérifier' +
        ' votre identité.<br> Seuls les documents en format PDF, PNG ou JPEG sont acceptés. Une traduction certifiée des documents pourrait' +
        ' être exigée au besoin. Le document utilisé ne peut provenir de Desjardins.',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE: 'Type de document',
    TRANSMISSION_ATTACHED_DOCUMENT_IDENTITY_TYPE_INDEX: 'Type de document',
    TRANSMISSION_ATTACHED_DOCUMENT_TYPE: 'Type de pièce jointe',
    TRANSMISSION_ATTACHED_DOCUMENT_GENERIC_LABEL: 'Pièce jointe',
    TRANSMISSION_ATTACHED_DOCUMENT_ID_DOC: 'Pièce d\'identité',
    TRANSMISSION_ATTACHED_DOCUMENT_CHECK_LABEL: 'Spécimen de chèque',
    TRANSMISSION_ATTACHED_DOCUMENT_CHECK_US_LABEL: 'Spécimen de chèque US',
    TRANSMISSION_ATTACHED_DOCUMENT_ALL_FILES_LABEL: 'Tous les documents',
    TRANSMISSION_ATTACHED_DOCUMENT_UPLOAD: 'Joindre',
    TRANSMISSION_ATTACHED_DOCUMENT_ADD: 'Ajouter un fichier',
    TRANSMISSION_ATTACHED_DOCUMENT_REMOVE: 'Supprimer ce fichier',
    TRANSMISSION_ATTACHED_DOCUMENT_LABEL: 'Document {{index}}',
    TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL: 'Document {{index}} (recto)',
    TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL: 'Document {{index}} (verso)',
    TRANSMISSION_ATTACHED_DOCUMENT_FRONT_LABEL_CAISSE: 'Pièce d\'identité (recto)',
    TRANSMISSION_ATTACHED_DOCUMENT_BACK_LABEL_CAISSE: 'Pièce d\'identité  (verso)',

    USER_IDENTITY_TITLE: 'Vérification d\'identité',
    USER_IDENTITY_MEETING: 'Le client a-t-il été rencontré en personne ?',
    USER_IDENTITY_PAPER: 'Pièce d\'identité',
    USER_IDENTITY_NUMBER: 'Numéro d\'identité unique (numéro du document)',
    USER_IDENTITY_DATE: 'Date d\'expiration (AAAA-MM-JJ)',
    USER_IDENTITY_ISSUE_EXPIRY_DATE: 'Date d\'expiration ou d\'émission, selon le cas',
    USER_IDENTITY_PROVINCE: 'Province ou territoire émettrice-teur du Canada',
    USER_IDENTITY_COUNTRY: 'Pays de délivrance',
    USER_IDENTITY_CAISSE_ERROR_MESSAGE: 'Afin de poursuivre cette demande, vous devez être en présence du client. Si ce n’est pas le cas, vous pouvez l’enregistrer, et y revenir lorsque le client sera en votre présence.',
    USER_IDENTITY_ADVISOR_FAMILY_NAME: 'Nom du conseiller de la Caisse',
    USER_IDENTITY_ADVISOR_FIRST_NAME: 'Prénom du conseiller de la Caisse',
    USER_IDENTITY_ADVISOR_BRANCH_NAME: 'Nom de la Caisse Desjardins',
    USER_IDENTITY_ADVISOR_INSTITUTION: 'Institution',
    USER_IDENTITY_ADVISOR_TRANSIT: 'Transit',
    USER_IDENTITY_ADVISOR_VERIFICATION_DATE: 'Date de la vérification',
    USER_IDENTITY_ADDRESS_DIFFERENT: 'L\'adresse qui figure au recto de cette pièce est différente de l\'adresse actuelle.',

    ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL: 'Vous pouvez signer en ligne, mais veuillez nous faire parvenir le(s) document(s) suivant(s) dûment signé(s) par la poste.',
    ADDITIONAL_FORM_INFORMATION_TITLE_PERSONAL_CAISSE: 'Pour compléter ce type de demande, des formulaires additionnels dûment signés et envoyés par courrier sont nécessaires. Veuillez nous faire parvenir le(s) document(s) suivant(s):',
    ADDITIONAL_FORM_INFORMATION_TITLE_JOINT: 'Vous avez accédé à la trousse de formulaires avec succès',
    ADDITIONAL_FORM_VD150: 'Demande d’adhésion REEE- VD150',
    ADDITIONAL_FORM_VD150F: 'Demande d’adhésion REEE familial - VD150F',
    ADDITIONAL_FORM_SDE0093: 'Subvention canadienne pour l’épargne-études (SCEE) et Bon d’études canadien (BEC) - SDE0093',
    ADDITIONAL_FORM_SDE0093A: 'Bénéficiaires supplémentaires – SDE0093-A',
    ADDITIONAL_FORM_SDE0093B: 'Responsable ou son conjoint, ou parent ayant la garde ou tuteur légal – SDE0093-B',
    ADDITIONAL_FORM_SDE0100A: 'Transfert d’un REEE – Renseignements sur le souscripteur – SDE0100-A',
    ADDITIONAL_FORM_TP1029_8_IQ: 'Transfert de l’incitatif québécois à l’épargne-études – TP1029.8.IQ',
    ADDITIONAL_FORM_RRIF_VD155: 'Contrat d\'adhésion FERR - VD155',
    ADDITIONAL_FORM_LIF_VD155: 'Contrat d\'adhésion FRV ou FRVR – VD155',
    ADDITIONAL_FORM_VD155FED: 'Avenant Fédéral – VD155FED',
    ADDITIONAL_FORM_VD155MB: 'Avenant Manitoba – VD155MB',
    ADDITIONAL_FORM_VD155NB: 'Avenant Nouveau-Brunswick – VD155NB',
    ADDITIONAL_FORM_VD155ONTN: 'Avenant Ontario – VD155ONTN',
    ADDITIONAL_FORM_VD155QC: 'Avenant Quebec – VD155QC',
    ADDITIONAL_FORM_VD117: 'Contrat d\'adhésion CRI, CRIF, RERI ou REIR - VD117',
    ADDITIONAL_FORM_VD117CB: 'Avenant Colombie-Britannique – VD217BC',
    ADDITIONAL_FORM_VD117FED: 'Avenant Fédéral – VD117FED',
    ADDITIONAL_FORM_VD117FEDR: 'Avenant Fédéral restreint – VD117FEDR',
    ADDITIONAL_FORM_VD117MB: 'Avenant Manitoba – VD117MB',
    ADDITIONAL_FORM_VD117NB: 'Avenant Nouveau-Brunswick – VD117NB',
    ADDITIONAL_FORM_VD117ONT: 'Avenant Ontario – VD117ONT',
    ADDITIONAL_FORM_VD117QC: 'Avenant Québec – VD117QC',
    ADDITIONAL_FORM_VD217AB: 'Avenant Alberta – VD217AB',
    ADDITIONAL_FORM_VD217NS: 'Avenant Nouvelle-Écosse – VD217NS',
    ADDITIONAL_FORM_VD255AB: 'Avenant Alberta – VD255AB',
    ADDITIONAL_FORM_VD255CB: 'Avenant Colombie-Britannique – VD255BC',
    ADDITIONAL_FORM_VD255NS: 'Avenant Nouvelle-Écosse – VD255NS',
    ADDITIONAL_FORM_VD255FEDR: 'Avenant Fédéral restreint – VD255FEDR',
    ADDITIONAL_FORM_VD170: 'Demande de numéro d\'identification fiscale et d\'attestation - VD170',
    ADDITIONAL_FORM_VD1050: 'Étrangers politiquement vulnérables - VD1050',
    ADDITIONAL_FORM_CF00002027: 'Étrangers politiquement vulnérables - CF-00002-027',
    ADDITIONAL_FORM_VD1051: 'Détermination quant aux tiers - VD1051',
    ADDITIONAL_FORM_VD1061_REER: 'Avis de désignation de bénéficiaire - VD1061 (REER)',
    ADDITIONAL_FORM_VD1061_CELI: 'Avis de désignation de bénéficiaire - VD1061 (CELI)',
    ADDITIONAL_FORM_ABFORM10: 'Avenant Alberta – AB Form 10',
    ADDITIONAL_FORM_BCFORM3: 'Avenant Colombie-Britannique – BC Form 3',
    ADDITIONAL_FORM_FORM9: 'Avenant Nouvelle-Écosse – Form 9',
    ADDITIONAL_FORM_D104: 'Procuration générale - D104',
    ADDITIONAL_FORM_VD104A: 'Personne de confiance - VD104A',
    ADDITIONAL_FORM_VD104: 'Procuration générale - VD104',

    ESIGN_CONTACT_INFORMATION_TITLE: 'Veuillez indiquer les coordonnées nécessaires au processus d\'authentification. Un numéro de téléphone cellulaire est requis puisqu\'il servira à la transmission d\'un code d\'accès par SMS.',
    ESIGN_CONTACT_INFORMATION_USER_PHONE: 'Veuillez faire un choix',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER: 'Autre',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_SPECIFY_OTHER: 'Veuillez préciser',
    ESIGN_GUIDE: 'La signature des documents sera effectuée dans un nouvel onglet. Veuillez n\'effectuer cette opération qu\'une seule fois.',
    ESIGN_GUIDE2: 'Si vous souhaitez procéder à la signature ultérieurement, veuillez prendre en note vos identifiants afin d\'être en mesure de reprendre votre session au moment désiré.',
    ESIGN_GUIDE3: 'Merci d\'avoir utilisé les services d\'ouverture de compte en ligne!',
    ESIGN_MODAL_TITLE: 'Signature électronique',
    ESIGN_MODAL_PAGE_TITLE: 'Veuillez patienter...',
    ESIGN_MODAL_WAITING: 'Vous serez redirigé dans quelques instants sur la plateforme sécurisée de signature électronique.',
    ESIGN_MODAL_BTN_NEXT_STEP: 'Fermer',
    ESIGN_MODAL_BTN_NEXT_STEP_TOOLTIP: 'Fermer la fenêtre une fois que vous avez terminé la signature des documents.',
    ESIGN_MODAL_CONFIRM_CLOSE_BODY: 'La signature électronique du document n\'a pas été complétée. Voulez-vous vraiment quitter?',
    ESIGN_UNAVAILABLE: 'Votre demande a déjà été signée électroniquement. Aucune action supplémentaire n’est requise de votre part.',
    ESIGN_CONTACT_INFORMATION_TITLE1_CAISSE: '1-Un courriel sera envoyé au client afin de lui permettre d’accéder au site sécurisé pour le processus d’authentification.\n' +
        'Le courriel sera envoyé à l’adresse électronique suivante : ',
    ESIGN_CONTACT_INFORMATION_TITLE2_CAISSE: '2-Un code d\'accès par texto sera envoyé sur le téléphone cellulaire du client afin de compléter l’authentification.',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_CAISSE: 'Veuillez faire un choix:',
    ESIGN_CONTACT_INFORMATION_USER_PHONE_OTHER_CAISSE: 'Autre numéro',
    ESIGN_GUIDE_CAISSE: 'Si vous souhaitez procéder à la signature électronique ultérieurement, veuillez l’enregistrer et vous pourrez la reprendre dans le suivi des demandes.',

    PDF_MODAL_TITLE: 'Trousse de formulaires d\'ouverture de compte',
    PDF_MODAL_PAGE_TITLE: 'Veuillez patienter...',
    PDF_MODAL_NAV_TAB_FRENCH_LABEL: 'Français',
    PDF_MODAL_NAV_TAB_ENGLISH_LABEL: 'Anglais',

    REVIEW_INFORMATION_ACCOUNT_TYPE: 'Information sur le(s) compte(s)',
    REVIEW_TRANSACTIONAL_PLATFORM: 'Plateforme transactionnelle',
    REVIEW_PLATFORM: 'Type de plateforme',
    REVIEW_ACCOUNT_TYPE: 'Type de compte',
    REVIEW_REGISTERED_ACCOUNT: 'Compte enregistré',
    REVIEW_PERSONAL_INFORMATION: 'Informations personnelles',
    REVIEW_PERSONAL_INFORMATION_OF_THE_CLIENT: 'Identification du titulaire de compte',
    REVIEW_NAME: 'Nom',
    REVIEW_DOB: 'Date de naissance',
    REVIEW_SIN: 'Numéro d\'assurance sociale',
    REVIEW_TELEPHONE: 'Téléphone (domicile)',
    REVIEW_BUSINESS_TELEPHONE: 'Téléphone (travail)',
    REVIEW_MOBILE_TELEPHONE: 'Téléphone (mobile)',
    REVIEW_HOME_ADDRESS: 'Adresse de résidence',
    REVIEW_MAILING_ADDRESS: 'Adresse de correspondance',
    REVIEW_RESIDENTIAL_ADDRESS_FOR_TAX_PURPOSES: 'Lieu de résidence aux fins de l\'impôt',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP: 'Citoyenneté',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_CANADA: 'Canadienne',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_CITIZENSHIP_UNITED_STATES: 'Américaine',
    REVIEW_SNOWBIRD: 'Êtes-vous un visiteur temporaire des États-Unis (Snowbird)?',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE1: 'Êtes-vous un résident du Canada à des fins fiscales?',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE2: 'Résident des États-Unis à des fins fiscales',
    REVIEW_RESIDENTIAL_ADDRESS_TAX_TIN_CHOICE3: 'Résident d\'un autre pays à des fins fiscales',
    REVIEW_OCCUPATION: 'Occupation',
    REVIEW_OCCUPATION_AND_EMPLOYER: 'Occupation et employeur',
    REVIEW_EMPLOYMENT_STATUS: 'Statut d\'emploi',
    REVIEW_EMPLOYMENT_SECURITY_DEALERS: 'Êtes-vous à l\'emploi d\'un courtier en valeurs mobilières?',
    REVIEW_EMPLOYER_NAME: 'Nom de l\'employeur',
    REVIEW_EMPLOYMENT_JOB_TITLE: 'Titre de l\'emploi',
    REVIEW_EMPLOYMENT_SECTOR_ACTIVITY: 'Secteur d\'activité',
    REVIEW_EMPLOYMENT_SAME_ADDRESS: 'Demeurez-vous à la même adresse qu\'un employé d\'une firme de courtage en valeurs mobilières?',
    REVIEW_FIRM_NAME: 'Nom de la firme',
    REVIEW_FIRM_EMPLOYEE_NAME: 'Nom de l\'employé',
    REVIEW_FIRM_LINK: 'Lien avec la firme',
    REVIEW_MARITAL_STATUS_AND_DESIGNATION: 'État civil et désignation',
    REVIEW_SPOUSAL_INFORMATION: 'Informations sur le conjoint',
    REVIEW_MARITAL_STATUS: 'État civil',
    REVIEW_BENEFICIARY: 'Bénéficiaire',
    REVIEW_HAS_BENEFICIARY: 'Souhaitez-vous nommer un bénéficiaire au produit de votre régime?',
    REVIEW_HAS_BENEFICIARY_FHSA: 'Souhaitez-vous désigner une ou des personnes comme bénéficiaire(s) des prestations exigibles à votre décès ?',
    REVIEW_SUB_BENEFICIARY: 'Bénéficiaire',
    REVIEW_SUCCESSOR_BENEFICIARY_TFSA: 'Voulez-vous désigner votre conjoint comme titulaire remplaçant de votre compte advenant votre décès?',
    REVIEW_SUCCESSOR_BENEFICIARY_FHSA: 'Souhaitez-vous désigner votre époux ou conjoint de fait à titre de titulaire remplaçant à votre décès ?',
    REVIEW_SUCCESSOR: 'Titulaire remplaçant',
    REVIEW_ATTORNEY: 'Mandataire',
    REVIEW_ATTORNEY_CLIENT_NUMBER: 'Numéro de client',
    REVIEW_ATTORNEY_RELATIONSHIP: 'Relation',
    REVIEW_ADDRESS: 'Adresse',
    REVIEW_BANK: 'Institution financière',
    REVIEW_TRANSIT_NUMBER: 'Numéro de transit',
    REVIEW_ACCOUNT_NUMBER: 'Numéro de compte',
    REVIEW_BANK_TELEPHONE: 'Numéro de téléphone de l\'institution financière',
    REVIEW_ATTORNEY_REPORTING_INSIDER: 'Est-ce que le mandataire est un initié assujetti d\'une compagnie dont les actions sont négociées en bourse ou sur un marché hors cote?',
    REVIEW_COMPANY_NAME: 'Nom de la compagnie',
    REVIEW_STOCK_SYMBOL: 'Symbole boursier',
    REVIEW_MARKET: 'Marché',
    REVIEW_ATTORNEY_SHAREHOLDER: 'Est-ce que le mandataire est un actionnaire important d\'une compagnie dont les actions sont négociées en bourse ou sur un marché hors cote?',
    REVIEW_REGULATORY_QUESTIONS: 'Questions réglementaires',
    REVIEW_BROKERAGE_ACCOUNTS: 'Comptes de courtage',
    REVIEW_BROKERAGE_OTHER_ACCOUNTS: 'Détenez-vous ou contrôlez-vous la négociation d\'autres comptes de courtage?',
    REVIEW_REPORTING_INSIDER: 'Êtes-vous un initié assujetti?',
    REVIEW_SHAREHOLDER: 'Êtes-vous un actionnaire important?',
    REVIEW_EPV: 'Êtes-vous une personne politiquement vulnérable?',
    REVIEW_THIRD_PARTY_USE: 'Le compte est-il destiné à être utilisé par un tiers ou en son nom?',
    REVIEW_BROKERAGE_PURPOSE: 'Utilisation prévue du compte non enregistré',
    REVIEW_BROKERAGE_KNOWLEDGE: 'Connaissances en matière de placement',
    REVIEW_COMMUNICATION_BENEFICAL_OWNERS: 'Communication avec les propriétaires véritables',
    REVIEW_CONSENTMENT: 'Communication de renseignements sur la propriété véritable',
    REVIEW_RECEIPT_METHOD: 'Réception de documents pour les porteurs de titres',
    REVIEW_ELECTRONIC_METHOD: 'Consentement à la transmission électronique de documents',
    REVIEW_OPTION_ACCOUNT: 'Compte d\'options',
    REVIEW_OPTION_ACCOUNT_ANOTHER_DEALER: 'Détenez-vous un compte d\'options auprès d\'un autre courtier en valeurs mobilières?',
    REVIEW_OPTION_ACCOUNT_EXPERIENCE_DERIVATIVES: 'Avez-vous de l\'expérience dans la négociation de produits dérivés?',
    REVIEW_YEARS_EXPERIENCE: 'Nombre d\'année(s) d\'expérience',
    REVIEW_OPTION_ACCOUNT_THEORICAL_KNOWLEDGE: 'Possédez-vous des connaissances théoriques en matière de négociation des produits dérivés?',
    REVIEW_OPTION_ACCOUNT_EXPERIENCE_TRADE: 'Expérience sur le(s) type(s) d\'opération',
    REVIEW_OPTION_ACCOUNT_ANTICIPATION_TRADE: 'Type(s) d\'opération sur options prévu(s)',
    REVIEW_FINANCIAL_INFORMATION_TITLE: 'Informations financières',
    REVIEW_FINANCIAL_INFORMATION: 'Informations financières',
    REVIEW_ANNUAL_REVENUE: 'Revenu annuel de toutes sources',
    REVIEW_NET_CAPITAL: 'Actif immobilisé net approximatif',
    REVIEW_NET_ASSETS: 'Actif liquide net approximatif',
    REVIEW_TRANSFER_REQUEST_TITLE: 'Demande de transfert',
    REVIEW_TRANSFER_REQUEST_OPT: 'Transfert provenant d\'une autre institution financière',
    REVIEW_TRANSFER_REQUEST_FROM_ANOTHER_FINANCIAL_INSTITUTION: 'Désirez-vous effectuer un transfert d\'un compte de placement provenant de chez Desjardins ou d’une autre institution financière?',
    REVIEW_TRANSFER_REQUEST: 'Demande de transfert',
    REVIEW_DELIVERING_BANK: 'Nom de l\'institution cédante',
    REVIEW_TRANSFER_RESSOURCE_NAME: 'Nom de la personne ressource',
    REVIEW_DELIVERING_BANK_TELEPHONE: 'Numéro de téléphone',
    REVIEW_TRANSFER_ADDRESS: 'Adresse de l\'institution cédante',
    REVIEW_ACCOUNT_NUMBER_SEC: 'Deuxième numéro de compte',
    REVIEW_ACCOUNT_TYPE_SEC: 'Deuxième type de compte',
    REVIEW_TRANSFER_TYPE: 'Type de transfert',
    REVIEW_TRANSFER_INSTRUCTION: 'Instructions de transfert',
    REVIEW_TRANSFER_CASH_AMOUNT: 'Montant à transférer',
    REVIEW_TRANSFER_SECURITY: 'Titre à transférer',
    REVIEW_BENEFICIARY_LINK: 'Lien de parenté',
    REVIEW_BENEFICIARY_PART: 'Part',
    REVIEW_ADDING_A_NEW_ACCOUNT: 'Nouveau compte',
    REVIEW_ADDING_A_NEW_ACCOUNT_TO_AN_EXISTING_USER: 'Ajout d\'un nouveau compte à un utilisateur',
    REVIEW_OTHER_REASON_NIF: 'Aucun numéro d\'identification fiscale pour autre raison',
    REVIEW_NIF: 'Numéro d\'identification fiscale',
    REVIEW_PLATFORM_DIRECT: 'Disnat Direct',
    REVIEW_PLATFORM_CLASSIC: 'Disnat Classique',
    REVIEW_JURIDICTION: 'Juridiction : ',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE1: 'Je souhaite recevoir <strong>TOUS</strong> les documents',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE2: 'Je ne souhaite recevoir <strong>AUCUN</strong> des documents',
    REVIEW_LEGAL_SHAREHOLDER_RECEIPT_CHOICE3: 'Je ne souhaite recevoir <strong>QUE</strong> les documents reliés aux procurations transmis en vue des assemblées extraordinaires',
    REVIEW_MARKET_CAN: 'Canadien',
    REVIEW_MARKET_US: 'Américain',
    REVIEW_RESP_BENEFICIARY: 'bénéficiaire(s)',
    REVIEW_RESP_SUBSCRIBER_RESPONSIBLE: 'Parent responsable',
    REVIEW_RESP_SUBSCRIBER_NO_RESPONSIBLE: 'N\'est pas le parent responsable',
    REVIEW_ANNUITANT_PERSONAL: 'Rentier cotisant',
    REVIEW_ANNUITANT_SPOUSAL: 'Conjoint cotisant',
    REVIEW_ATTORNEY_QUESTION: 'Voulez-vous autoriser une autre personne que vous à négocier dans ce compte?',
    REVIEW_FROM_LIRA_OR_PENSION: 'Les fonds proviennent d\'un compte CRI ou d\'un régime de pension',
    REVIEW_EMAIL: 'Adresse électronique',
    REVIEW_ACCOUNT_RRSP: 'Compte REER',
    REVIEW_ACCOUNT_TFSA: 'Compte CELI',
    REVIEW_ACCOUNT_FHSA: 'Compte CELIAPP',
    REVIEW_ACCOUNT_STATEMENT: 'Relevé de compte',
    REVIEW_BENEFICIARY_MODAL_TITLE: 'Demande du Bon d\'étude canadien (BEC)',
    REVIEW_BENEFICIARY_MODAL_BODY: 'Une limitation de nos systèmes nous empêche de demander le BEC pour un ou plusieurs bénéficiaire(s) puisque la case <strong>Ne pas demander la SCEE supplémentaire pour le présent REEE</strong> est cochée. Cliquez sur <strong>Continuer</strong> afin de demander la SCEE supplémentaire ou sur <strong>Annuler</strong> si vous ne souhaitez  plus demander le BEC.',
    REVIEW_BENEFICIARY_INFO_ADDITIONAL_CESG_CLB: 'La SCEE supplémentaire et le BEC sont versés seulement dans un REEE familial dont tous les bénéficiaires sont d\'une même fratrie.',
    SPECIFY_EXPERIENCE: 'Veuillez préciser',
    REVIEW_PREVIOUS_VALUES: 'Avant',
    REVIEW_NEW_VALUES: 'Après',
    REVIEW_MISSING_DATA: 'Information manquante ou irrécupérable',
    REVIEW_SPECIFIC_CONSENT_OPT: 'Consentement spécifique',
    REVIEW_SPECIFIC_CONSENT: 'Autorisation du client pour le partage de ses renseignements personnels pour l\'offre 360 de services :',

    ACCOUNT_TYPE_RELATED_QUESTIONS_TITLE: 'Questions relatives au type de compte',
    ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACT_PEOPLE: 'Combien de personnes ?',

    ACCOUNT_TYPE_RELATED_QUESTIONS_RES_QC: 'Êtes-vous résident de la province de Québec ?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SURVIVORSHIP: 'Gain de survie',
    ACCOUNT_TYPE_RELATED_QUESTIONS_WITH_SURVIVORSHIP: 'Avec gain de survie (Section 5.2 paraphée)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_WITHOUT_SURVIVORSHIP: 'Sans gain de survie (Section 5.1 paraphée)',

    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_PERSONAL: 'Oui : Compte personnel',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_JOINT: 'Oui : Compte conjoint',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_LINKED_ACCOUNT_NONE: 'Aucun compte',

    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_ONE_SHAREHOLDER: 'Actionnaire unique',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OTHER_MANY_SHAREHOLDERS: 'Plusieurs actionnaires',

    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE_COMPLEX: '"Complex"',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE_GRANTOR_SIMPLE: 'De constituant "Grantor/Simple"',

    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_QC: 'Québec',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE_ON: 'Ontario',

    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_TITLE: 'Parmi les associés, quel est le nombre de :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACT_AMERICAN_PEOPLE: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACT_PEOPLE: 'Personnes physiques autres qu\'américaines',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_HOLDING_ACCOUNT: 'Est-ce que l\'un des membres qui effectuera des transactions dans le compte de l\'association détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_TITLE: 'Parmi les membres, quel est le nombre de :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACT_AMERICAN_PEOPLE: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACT_PEOPLE: 'Personnes physiques autres qu\'américaines',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_HOLDING_ACCOUNT: 'Est-ce qu\'un des membres du club détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_HOLDING_ACCOUNT: 'Est-ce qu\'un des membres qui transigera au compte de la coopérative détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ONE_SHAREHOLDER: 'La corporation est-elle à actionnaire unique ou compte-t-elle plusieurs actionnaires&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_HOLDING_ACCOUNT: 'Est-ce qu\'un des actionnaires qui transigera au compte de la corporation détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_ACT_PEOPLE: 'Quel est le nombre de membres&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_HOLDING_ACCOUNT: 'Est-ce que le propriétaire détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_COUNIQUE_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ENFESQUALITES_ADD_SPOUSE: 'Voulez-vous ajouter votre conjoint au compte&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_ENFREF_ADD_SPOUSE: 'Voulez-vous ajouter votre conjoint au compte&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_GRANTOR_TITLE: 'Parmi les bénéficiaires et les constituants (grantor), quel est le nombre de :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_TYPE: 'De quel type de fiducie s\'agit-il&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_IS_THERE_AMERICAN_RESIDENT: 'Parmi les fiduciaires, est-ce qu\'il y a un résident américain ?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_AMERICAN_PEOPLE: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACT_PEOPLE: 'Personnes physiques autres qu\'américaines',
    ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_REGISTRATION_ACCOUNT: 'Numéro d\'enregistrement pour RRI (compte de placement)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_TRADE_AUTHORIZED_PEOPLE: 'Nombre de personnes autorisées à transiger',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_HOLDING_ACCOUNT: 'Est-ce qu\'un des administrateurs qui transigera au compte de l\'OSBL détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_TITLE: 'Parmi les associés, quel est le nombre de :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACT_AMERICAN_PEOPLE: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACT_PEOPLE: 'Personnes physiques autres qu\'américaines',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_LEG_ACT_PEOPLE: 'Nombre de personnes morales',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_HOLDING_ACCOUNT: 'Est-ce qu\'un des sociétaires qui transigera au compte de la société détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_TITLE: 'Parmi les associés (commandités et commanditaires), quel est le nombre de :',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_ACT_AMERICAN_PEOPLE: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_ACT_PEOPLE: 'Personnes physiques autres qu\'américaines',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_LEG_ACT_PEOPLE: 'Nombre de personnes morales',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_HOLDING_ACCOUNT: 'Est-ce qu\'un des sociétaires qui transigera au compte de la société détient un compte personnel ou conjoint Desjardins Courtage en ligne&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SOCCOMM_LINKED_ACCOUNT: 'Souhaitez-vous également ouvrir un compte personnel ou conjoint&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_DECEASED_PROVINCE: 'Quelle est la province de résidence du défunt&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_VALUE_GREATER_THAN_LIMIT: 'La valeur du compte est-elle supérieure à 50 000 $&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_WILL: 'Le défunt lègue-t-il un testament ou un contrat de mariage&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACT_PEOPLE: 'Combien y a-t-il de liquidateurs à la succession&nbsp;?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_IS_THERE_AMERICAN_RESIDENT: 'Parmi les liquidateurs, est-ce qu\'il y a un résident américain ?',
    ACCOUNT_TYPE_RELATED_QUESTIONS_GOUV_ORG_IS_IT_A_CITY: 'S\'agit-il d\'une ville ?',

    ACCOUNT_TYPE_ENTITIES_QUESTIONS_TITLE: 'Entités',
    ENTITY_TYPE: 'Type d\'entité',
    ENTITY_NAME: 'Nom de l\'entité',
    AMERICAN_INDIVIDUALS: 'Personnes physiques américaines (Citoyen ou résident des États-Unis)',
    OTHER_THAN_AMERICAN_INDIVIDUALS: 'Personnes physiques autres qu\'américaines',
    AMERICAN_INDIVIDUALS_TABLE: 'Américains',
    OTHER_THAN_AMERICAN_INDIVIDUALS_TABLE: 'Autres',
    AMERICAN_RESIDENT: 'Résident américain',
    AMERICAN_RESIDENT_FIDUCIE_C: 'Parmi les fiduciaires, est-ce qu\'il y a un résident américain ?',
    AMERICAN_RESIDENT_SUCC: 'Parmi les liquidateurs, est-ce qu\'il y a un résident américain ?',
    AMERICAN_RESIDENT_CORPO_OSBL_RRI: 'Est-ce qu\'il y a un résident américain ?',
    // Entity Type
    ENTITY_TYPE_ASSOCIATION: 'Association de personnes',
    ENTITY_TYPE_SOC_COMM: 'Société en commandite (S.E.C.)',
    ENTITY_TYPE_SENC: 'Société en nom collectif (S.E.N.C.) ou à responsabilité limitée (S.E.N.C.R.L)',
    ENTITY_TYPE_FIDUCIE_S: 'Fiducie «&nbsp;Grantor/Simple&nbsp;»',
    ENTITY_TYPE_FIDUCIE_C: 'Fiducie «&nbsp;Complex&nbsp;»',
    ENTITY_TYPE_CORPO: 'Corporation (inc.), Compagnie (ltée), Société par actions (compagnie)',
    ENTITY_TYPE_OSBL: 'Organisme sans but lucratif (OSBL), incluant les fondations',
    ENTITY_TYPE_SUCC: 'Succession',
    ENTITY_TYPE_RRI: 'Régime de retraite individuelle (RRI)',

    BTN_OPEN_ADD_ENTITY: 'Ajouter une entité',
    BTN_OPEN_DELETE_ENTITY: 'Supprimer l\'entité',
    BTN_OPEN_ADD_SUB_ENTITY: 'Ajouter une sous-entité',
    BTN_OPEN_DELETE_SUB_ENTITY: 'Supprimer la sous-entité',

    ENTITY_MSG_DISNAT_CONTACT_CUSTOMER_SERVICE: 'Si vous devez identifier plus de personnes ou d\'entités détenant le contrôle du client, veuillez contacter le Service à la clientèle.',
    ENTITY_MSG_PLEINEX_CONTACT_DISTRIBUTOR_SERVICE: 'Si vous devez identifier plus de personnes ou d\'entités détenant le contrôle du client, veuillez contacter le Service aux distributeurs.',

    CONFIRMATION_TITLE: 'Confirmation',
    CONFIRMATION_TEXT_ESIGN: 'Vous avez complété votre demande d’ouverture de compte avec succès. Vous recevrez votre code d’accès par courriel au cours des 2 prochains jours ouvrables.',
    CONFIRMATION_TEXT_ESIGN_MAIL: 'Vous avez complété votre demande d’ouverture de compte avec succès. Veuillez nous faire parvenir tous les documents additionnels, dûment remplis et signés, d’ici 40 jours.',
    CONFIRMATION_TEXT_MAIL: 'Votre trousse de formulaires d’ouverture de compte est maintenant complète. Veuillez en consulter la première page afin de connaître la liste des documents à fournir et les instructions qui vous aideront à les remplir.',
    CONFIRMATION_TEXT_TRANSFER: 'Il est possible que les titres ou les fonds d’un transfert en provenance d’une autre institution financière que Desjardins prennent jusqu’à deux semaines avant d’être disponibles dans votre compte de courtage en ligne.',

    CONFIRMATION_FUND_TITLE: 'Approvisionnez votre compte',
    CONFIRMATION_FUND_AS_FOLLOW: 'Une fois votre compte ouvert vous pourrez l’approvisionner de la façon suivante',
    CONFIRMATION_FUND_OPTION1: 'Si vous êtes membre Desjardins:',
    CONFIRMATION_FUND_OPTION1_TEXT: 'Dans AccèsD, utilisez la fonction « Virer » et choisissez « Virements entre comptes ».',
    CONFIRMATION_FUND_OPTION2: 'Si vous n’êtes pas membre Desjardins:',
    CONFIRMATION_FUND_OPTION2_TEXT1: 'Utilisez la fonction « Paiement de factures » de votre institution financière et ajoutez « Valeurs mobilières Desjardins » à votre liste.',
    CONFIRMATION_FUND_OPTION2_TEXT2: 'Inscrivez le numéro de votre compte à sept caractères dans la case « Numéro de compte ».',
    CONFIRMATION_FUND_OPTION2_TEXT3: 'Créez ensuite une facture pour chaque compte ou régime dans lequel vous désirez effectuer des transferts.',

    CONFIRMATION_DISNAT_MOBILE1: 'Négociez où que vous soyez avec l’application disnat!',
    CONFIRMATION_DISNAT_MOBILE2: 'En savoir plus sur Disnat Mobile',
    CONFIRMATION_DISNAT_MOBILE_LINK: 'https://www.disnat.com/avantages/service-mobile',

    CONFIRMATION_EMAIL_SUBSCRIPTION1: 'Abonnements aux courriels',
    CONFIRMATION_EMAIL_SUBSCRIPTION2: 'En savoir plus',
    CONFIRMATION_EMAIL_SUBSCRIPTION_LINK: 'https://www.disnat.com/centre-apprentissage/abonnement',
    CONFIRMATION_CAISSE_VIDEO_DEMONSTRATION_LINK: 'https://web.microsoftstream.com/video/25602dfd-13b5-4206-904c-685ce001759f',

    PROFIL_ENTER_A_PASSWORD: 'Vous avez besoin d’un mot de passe pour enregistrer votre demande d\'ouverture de compte. Cette étape est obligatoire' +
        ' pour soumettre la demande.',
    PROFIL_TMP_PASSWORD_ALREADY_A_PROFIL: 'Veuillez saisir un nouveau mot de passe et le confirmer.',
    PROFIL_PASSWORD_AUTHENTICATE_PASSWORD: 'Mot de passe',
    PROFIL_PASSWORD_FORGOT_LINK: 'Mot de passe oublié',
    PROFIL_OLD_PASSWORD: 'Mot de passe temporaire',
    PROFIL_NEW_PASSWORD: 'Nouveau mot de passe',
    PROFIL_CONFIRM_PASSWORD: 'Confirmer le nouveau mot de passe',
    PROFIL_SECURITY_QUESTION_TITLE: 'Veuillez sélectionner une question de sécurité.',
    PROFIL_SECURITY_QUESTION: 'Question de sécurité',
    PROFIL_REGISTRATION: 'Pour créer votre profil, veuillez sélectionner une question de sécurité et définir un mot de passe.',
    PROFIL_SECURITY_ANSWER: 'Réponse',
    PROFIL_SERVICE_CLIENTELE: 'Veuillez communiquer avec le service à la clientèle.',
    PROFIL_QUESTION_SERVICE_CLIENTELE: 'Vous ne pouvez pas réinitialiser votre mot de passe en ligne. Veuillez communiquer avec le service à la clientèle.',
    PROFIL_PASSWORD_EMAIL_SEND: 'Si vous avez déjà un profil, un courriel va être envoyé à l’adresse électronique suivante : ',
    PROFIL_CREATE_LINK: 'Créer un mot de passe',
    PROFIL_CREATE_EMAIL_SEND: 'Un courriel va être envoyé à l\'adresse électronique suivante : ',
    PROFIL_CREATE_EMAIL_SENT: 'Un courriel a été envoyé à l\'adresse électronique suivante : ',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_TITLE: 'Renseignement personnels',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LANGUAGE: 'Langue de correspondance',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FIRST_NAME: 'Prénom de la personne-contact',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_LAST_NAME: 'Nom de la personne-contact',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_SOLE_PROPRIETOR_SIN: 'NAS de la personne-contact',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_EMAIL: 'Adresse électronique',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_NAME: 'Nom de la société',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PRIMARY_PHONE: 'Téléphone (domicile)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_OTHER_PHONE: 'Téléphone (travail)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MOBILE_PHONE: 'Téléphone (mobile)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_ADDRESS_TITLE: 'Adresse',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_COMPANY_ADDRESS_TITLE: 'Adresse de la société',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_ADDRESS_TITLE: 'Adresse du parent',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CONTACT_ADDRESS_TITLE: 'Adresse de la personne-contact',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_ADDRESS_TITLE: 'Adresse du tuteur',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_ADDRESS_TITLE: 'Adresse du curateur',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MAIL_ADDRESS_TITLE: 'Adresse de correspondance',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER: 'Numéro d\'entreprise au fédéral (NE)',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCIAL_BUSINESS_NUMBER: 'Numéro d\'entreprise au provincial',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PROVINCE: 'Province ou territoire',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FEDERAL_BUSINESS_NUMBER_HELP: '<h2></h2>\n' +
        '    <div>Un numéro de compte de programme de l\'ARC comprend les trois parties suivantes :</div>\n' +
        '    <ol>\n' +
        '        <li>le <strong>numéro d\'entreprise</strong> de neuf chiffres pour identifier l\'entreprise;</li>\n' +
        '        <li>un <strong>identificateur de programme</strong> de deux lettres pour identifier le compte de programme;</li>\n' +
        '        <li>un <strong>numéro de référence</strong> de quatre chiffres pour identifier un compte de programme particulier\n' +
        '            (puisque les entreprises peuvent avoir plus d\'un compte de programme du même type).</li>\n' +
        '    </ol>',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_FISCAL_PERIOD_END_DATE: 'Date de fin d\'exercice',

    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_FIRST_NAME: 'Prénom du défunt',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_DECEASED_LAST_NAME: 'Nom du défunt',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_FIRST_NAME: 'Prénom du parent',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_LAST_NAME: 'Nom du parent',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_DOB: 'Date de naissance du parent',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_PARENT_SIN: 'NAS du parent',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_FIRST_NAME: 'Prénom de l\'enfant',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_LAST_NAME: 'Nom de l\'enfant',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_DOB: 'Date de naissance de l\'enfant',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CHILD_SIN: 'NAS de l\'enfant',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_FIRST_NAME: 'Prénom du client sous tutelle',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_GUARDIANSHIP_LAST_NAME: 'Nom du client sous tutelle',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_FIRST_NAME: 'Prénom du client sous curatelle',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_CURATORSHIP_LAST_NAME: 'Nom du client sous curatelle',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_FIRST_NAME: 'Prénom du client inapte',
    ODC_STEP_ACCOUNT_HOLDER_INFORMATION_MANDATE_LAST_NAME: 'Nom du client inapte',

    COUNTRY_ADDRESS_CAN: 'Canada',
    COUNTRY_ADDRESS_USA: 'États-Unis',

    CURRENT_MODE: 'Mode de saisie actuel :',
    LIST_MODE_PRINT: 'Impression des formulaires',
    LIST_MODE_AUTO: 'Ouverture automatisée',
    LIST_MODE_UPD_AUTO: 'Mise à jour automatisée',

    OTHER_ACCOUNT_ONLY_CANADIAN_ENTITIES: 'Seule l’ouverture de compte pour les entités canadiennes est disponible.',

    OSS_QUERY_CLIENT_CODE_LABEL: 'Code du client',
    OSS_QUERY_LANGUAGE_LABEL: 'Langue',

    NO_REQUESTS_FOUND: 'Aucune demande ne correspond aux critères sélectionnés.',
    MORE_THAN_500_REQUESTS: 'La recherche a généré plus de 500 résultats.  Veuillez affiner votre recherche et essayer à nouveau.',
    BRANCH_LATEST_REQUESTS: 'Les demandes des 40 derniers jours sont affichées.',
    BRANCH_ALL_REQUESTS: 'Les demandes des 3 dernières années sont affichées.',
    BRANCH_ADDITIONAL_CRITERIA: 'Critères additionnels',

    BRANCH_LIST_REQUESTS_FIELD_CREATION: 'Créée le',
    BRANCH_LIST_REQUESTS_FIELD_REQUEST_NUMBER: 'No',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_TYPE: 'Type',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_NAME: 'Nom du client',
    BRANCH_LIST_REQUESTS_FIELD_CLIENT_NUMBER: 'No de client',
    BRANCH_LIST_REQUESTS_FIELD_REQUEST_STATUS: 'Statut',
    BRANCH_LIST_REQUESTS_FIELD_TRANSMISSION_MTHD: 'Transmission',
    BRANCH_LIST_REQUESTS_FIELD_ADVISOR_CODE: 'Conseiller',
    BRANCH_LIST_REQUESTS_MODIFY_BTN: 'Modifier',
    BRANCH_LIST_REQUESTS_PRINT_BTN: 'Imprimer',

    CAISSE_INPROGRESS: 'En cours de traitement',
    CAISSE_CANCELLED: 'Annulée',
    CAISSE_COMPLETE: 'Remplie',
    CAISSE_FINALIZED: 'Finalisée',
    CAISSE_SAVED: 'Sauvegardée',
    CAISSE_TRANSMIT: 'Transmise',
    CAISSE_DECLINED: 'Refusée',
    CAISSE_MANUAL: 'Traitement manuel',

    ESIGN: 'Signature électronique',
    ESIGN_MAIL: 'Signature électronique et Poste',
    MAIL: 'Poste',
    PRINT: 'Impression',

    INDIVIDU: 'Individu',
    CONJOINT: 'Conjoint',
    ASSOCIATION: 'Association de personnes',
    COOP: 'Coopérative ou syndicat de copropriété',
    ENF_REF: 'Enfant mineur en référence',
    CORPO: 'Corporation (inc.), Compagnie (ltée), Société par actions (compagnie)',
    CURATELLE: 'Curatelle (ne s\'applique pas aux résidents du Québec)',
    SUCC: 'Succession',
    ORG_GOUV: 'Organisme gouvernemental',
    TUTELLE: 'Tutelle',
    ENF_ES_QUALITES: 'Enfant mineur (ès qualités de tuteur - sans jugement)',
    RRI: 'Régime de retraite individuel (RRI)',
    CLUB: 'Club de placement',
    SOC_COMM: 'Société en commandite (S.E.C.)',
    OSBL: 'Organisme sans but lucratif (OSBL), incluant les fondations',
    SENC: 'Société en nom collectif (S.E.N.C.) ou à responsabilité limitée (S.E.N.C.R.L)',
    MANDATAIRE: 'Personne inapte (ès qualités de mandataire - Québec)',
    CO_UNIQUE: 'Entreprise individuelle',
    FIDUCIE: 'Fiducie',

    BRANCH_SEARCH_FIELD_CLIENT_NAME_LABEL: 'Nom du client',

    BRANCH_SEARCH_FIELD_TYPE_REQUESTS_LABEL: 'Demandes',
    BRANCH_SEARCH_OPTION_TRANSIT_REQUESTS_LABEL: 'Du transit',
    BRANCH_SEARCH_OPTION_ADVISOR_REQUESTS_LABEL: 'Mes demandes',

    BRANCH_SEARCH_FIELD_DATE_REQUESTS_LABEL: 'Periode',
    BRANCH_SEARCH_OPTION_LATEST_REQUESTS_LABEL: '40 derniers jours',
    BRANCH_SEARCH_OPTION_ALL_REQUESTS_LABEL: 'Toutes les demandes',

    BRANCH_SEARCH_FIELD_ADVISOR_CODE_LABEL: 'Code du conseiller',
    BRANCH_SEARCH_FIELD_STATUS_LABEL: 'Statut',

    PERSONAL_INFORMATION_FAILED_MODAL_TITLE: 'Récupération des données du client',
    PERSONAL_INFORMATION_FAILED_MODAL_BODY: 'Il n\'a pas été possible de récupérer les données du client.',

    PERSONAL_INFORMATION_UPDATE_FORM_MODAL_TITLE: 'Mise à jour des données du client',
    PERSONAL_INFORMATION_UPDATE_FORM_MODAL_BODY: 'Le questionnaire a été mis à jour avec les nouvelles informations du client.',

    MODAL_AUTOSAVE_ERROR_TITLE: 'La sauvegarde n\'a pas pu être effectuée',
    MODAL_AUTOSAVE_ERROR_BODY: 'Une demande d\'ouverture de compte est en cours de modification dans votre navigateur, mais la sauvegarde n\'a pas pu être effectuée.  Cliquer sur <b>Annuler</b> pour accéder à la demande en cours, ou sur <b>Continuer</b> pour l\'ignorer et poursuivre avec l\'action demandée.',


    CAISSE_LIST_ACCOUNTS: 'Comptes',

    MOST_RECENT_FORM_MODAL_TITLE: 'Date du plus récent formulaire',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_1: 'Date de réception de la dernière ouverture ou mise à jour de compte :',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_1_BEFORE: 'Date de réception de la dernière ouverture ou mise à jour de compte :',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_2: 'Conformément au Règlement 31-103, vous devez compléter le formulaire d’ouverture de compte et le questionnaire profil de l’investisseur (VD101P) et les faire signer par votre client. ',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_2_BEFORE: 'En raison des exigences rehaussées de KYC et de mise en jour en vigueur depuis le 1er janvier 2022, vous devez remplir le formulaire d’ouverture de compte et le questionnaire du profil de l’investisseur (VD101P), puis les faire signer par votre client.',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_2_NO_DATE: 'En raison des exigences rehaussées de KYC et de mise en jour en vigueur depuis le 1er janvier 2022, vous devez remplir le formulaire d’ouverture de compte et le questionnaire du profil de l’investisseur (VD101P), puis les faire signer par votre client. Suivez ensuite chacune des étapes du questionnaire Web.',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_3: 'Veuillez suivre chacune des étapes de ce questionnaire.',
    MOST_RECENT_FORM_MODAL_PARAGRAPH_3_BEFORE: 'Suivez ensuite chacune des étapes du questionnaire Web.',
    NO_AS400_DATE: 'La date n’a pas pu être récupérée dans AS/400.',

    MOST_RECENT_FORM_MODAL_PARAGRAPH_AFTER: 'Si aucun changement ne figure dans la mise à jour du formulaire d’ouverture de compte, il sera possible de soumettre la demande sans demander la signature du client. Cependant, si des changements figurent dans la mise à jour du formulaire d’ouverture de compte, le formulaire devra être signé par le client.',


    /* Account add */
    ADD_ACCOUNT_CLIENT_TITLE: 'Client :',
    ADD_ACCOUNT_MESSAGE_TITLE: 'Important:',
    ADD_ACCOUNT_MESSAGE_0: 'Vous aurez besoin d\'ajouter un formulaire V101 si vous êtes dans l\'une des situations suivantes:',
    ADD_ACCOUNT_MESSAGE_1: 'Un changement majeur est survenu au dossier du client',
    ADD_ACCOUNT_MESSAGE_2: 'Le client ouvre pour la première fois un compte enregistré',
    ADD_ACCOUNT_MESSAGE_3: 'Une mise-à-jour du dossier est requise',
    ADD_ACCOUNT_REGISTERED_ACCOUNT_CHOICE_TITLE: 'Ajouter un compte enregistré',
    ADD_ACCOUNT_CURRENCY_MODAL_TITLE: 'Ajout de compte non enregistré',
    ADD_ACCOUNT_CURRENCY_MODAL_BODY_TO_USD: 'Pour vous permettre d\'ouvrir des comptes enregistrés en dollars canadiens et américains, vous devez absolument détenir des comptes non enregistrés dans la même devise. Par conséquent, un compte non enregistré a automatiquement été ajouté.',
    ADD_ACCOUNT_IRS_EXP_DATE_MSG: 'Pour faire l\'ouverture du compte CELI, vous devez fournir une copie lisible d’une pièce d\'identité gouvernementale avec photo valide.',

    /* Help warning messages */
    INFORMATION_FETCHED_FROM_CUSTOMER: 'Les informations de cette section doivent avoir été obtenues par le conseiller\u00B9 auprès du client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE: 'Les informations de cette section doivent avoir été obtenues par le conseiller auprès du client. Cette section doit être remplie <u>après</u> avoir obtenu la signature du client sur le formulaire.',
    INFORMATION_FETCHED_FROM_CUSTOMER_GPD: 'Les informations de cette section doivent avoir été obtenues par le gestionnaire privé auprès du client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE_GPD: 'Les informations de cette section doivent avoir été obtenues par le gestionnaire privé auprès du client. Cette section doit être remplie <u>après</u> avoir obtenu la signature du client sur le formulaire.',
    INFORMATION_FETCHED_FROM_CUSTOMER_SFD: 'Les informations de cette section doivent avoir été obtenues par le gestionnaire privé auprès du client.',
    INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE_SFD: 'Les informations de cette section doivent avoir été obtenues par le gestionnaire privé auprès du client. Cette section doit être remplie <u>après</u> avoir obtenu la signature du client sur le formulaire.',

    TRANSFER_REQUEST_CHOICE_MODAL_TITLE: 'Avis important concernant les demandes de transferts de fonds communs de placement',
    TRANSFER_REQUEST_CHOICE_CHOICE_MESSAGE: 'Depuis le 1er juin 2022, les nouvelles dispositions réglementaires des Autorités canadiennes en valeurs mobilières prévoient l’interdiction, par les gestionnaires de fonds d’investissement, de verser des commissions de suivi aux courtiers exécutants n’offrant pas de conseils, comme Desjardins Courtage en ligne (DCL).\n' +
        '<br/><br/>' +
        'En raison de cette interdiction, les fonds communs de placement avec commissions de suivis ne peuvent plus être détenus dans des comptes sans conseils ou transférés à de tels comptes.\n' +
        '<br/><br/>' +
        'Ainsi, sur réception d\'un formulaire de transfert, DCL échangera automatiquement votre fonds vers une catégorie ou série de fonds identique ou similaire sans commissions de suivi, sans autre avis préalable.\n' +
        '<br/><br/>' +
        'Si aucune catégorie ou série identique ou similaire n’est disponible, un représentant de DCL communiquera alors avec vous afin d’évaluer les options possibles.\n' +
        '<br/><br/>' +
        'Le lien suivant vous donnera des explications détaillées de ce qu’il adviendra de toute commission de suivi que DCL pourrait recevoir entre le moment du transfert de votre fonds et son échange vers une catégorie sans commissions de suivi.\n' +
        '<br/><br/> ' +
        '<a href="https://www.disnat.com/plateformes-et-frais/titres-negociables" class="lien-externe" target="_blank">Plateformes et frais - Titres négociables | Desjardins Courtage en ligne (disnat.com)',

    ODC_SEARCH_CLIENT_INFORMATION_OSS_ERROR: 'Une erreur est survenue lors de la récupération de l’information du client. Veuillez réessayer un peu plus tard.',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX: 'La signature de votre client est requise pour la mise à jour de ce compte. Pour procéder, veuillez :',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_1: '1. Imprimer votre trousse de formulaires et lui faire parvenir pour obtenir sa signature;',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_2: '2. Faire approuver le document dûment signé par le ou la conseiller-ère et surveillant-e de succursale;',
    TRANSMISSION_KIT_DOWNLOAD_PLEIN_EX_POINT_3: '3. Retourner dans la demande, puis vous rendre à la dernière étape du questionnaire Web pour initier l’injection des informations dans les systèmes.',

    // SUBMISSION
    SUBMITTED_LABEL: 'La demande a été soumise pour l\'ouverture automatisée.  Pour en faire le suivi, veuillez vous référer au bureau Ouverture de compte dans SmartD.',
    SUBMIT_CONFIRM: 'Je déclare avoir communiqué, révisé et mis à jour avec le client l\'ensemble des renseignements ' +
        'qui figurent sur le questionnaire d’ouverture de compte. Depuis la dernière mise à jour du dossier du client, aucun changement significatif n’est survenu ' +
        'qui a affecté sa situation personnelle ou financière, ses besoins et objectifs de placement, son profil de risque ou encore, ' +
        'qui affecterait, de façon importante, sa valeur nette ou son revenu. J’ai également procédé à l’évaluation de la convenance des placements du client.',
    SUBMIT_PLEIN_EX_CONFIRM: 'J’atteste avoir vérifié la note dans le système métier Croesus/CRM sur la rencontre du conseiller avec son client. Cette note confirme que le conseiller a communiqué, révisé et mis à jour avec le client l’ensemble des renseignements qui figurent sur la documentation d’ouverture de compte. Il est noté que depuis la dernière mise à jour du dossier client, aucun changement significatif n’est survenu qui a affecté la situation personnelle ou financière, les besoins et objectifs de placement, le profil de risque ou encore, qui affecterait, de façon importante, la valeur nette ou le revenu du client. Le conseiller confirme qu’il a procédé à l’évaluation de la convenance des placements du client.',
    SUBMITTED_UPDATE_LABEL_WITH_NAME: 'La demande de mise à jour du client a été soumise le {{date}} par {{name}}. Pour en faire le suivi, référez-vous au bureau Ouverture de compte dans Smart D.',
    SUBMITTED_UPDATE_LABEL: 'La demande de mise à jour du client a été soumise. Pour en faire le suivi, référez-vous au bureau Ouverture de compte dans Smart D.',
    ODC_STEP_SUBMISSION_TITLE: 'Nom du conseiller qui a effectué la mise à jour avec le client',
    ODC_STEP_SUBMISSION_MRS_ADVISOR: 'Madame',
    ODC_STEP_SUBMISSION_MR_ADVISOR: 'Monsieur',
    ODC_STEP_SUBMISSION_TITLE_ADVISOR: 'Titre',
    ODC_STEP_SUBMISSION_FIRST_NAME_ADVISOR: 'Prénom',
    ODC_STEP_SUBMISSION_LAST_NAME_ADVISOR: 'Nom',
    ODC_STEP_SUBMISSION_DATE_MEETING_ADVISOR: 'Date de la rencontre avec le client',

    NO_CHANGES_DETECTED: 'Aucun changement n\'a été détecté dans votre demande de mise à jour.',

    SPECIFIC_CONSENT_COMPARISON: 'Autorisation du client pour le partage de ses renseignements personnels pour l’offre 360 de services.',

    PASSWORD_LENGTH_STRENGTH_WEAK: 'Faible',
    PASSWORD_LENGTH_STRENGTH_MEDIUM: 'Moyen',
    PASSWORD_LENGTH_STRENGTH_GOOD: 'Bon',
    PASSWORD_LENGTH_STRENGTH_STRONG: 'Fort',

    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_MIN_8: '8 caractères minimum',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_UPPERCASE_LETTER: 'Une lettre majuscule',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_LOWERCASE_LETTER: 'Une lettre minuscule',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_SPECIAL_CHARACTER: 'Un caractère spécial',
    PASSWORD_LENGTH_STRENGTH_SUGGESTIONS_DIGIT: 'Un chiffre',

    ODC_COMPARISON_FATAL_ERROR: 'Une ou des informations non standard récupérées de nos systèmes métiers nous empêchent d\'afficher le sommaire des' +
        ' changements.',

    ODC_MODAL_CONNEXION_TITLE: 'Enregistrer la demande',
    ODC_MODAL_CONNEXION_CREATE_PASSWORD_TITLE: 'Créer un mot de passe',
    ODC_MODAL_CONNEXION_CREATE_PASSWORD_DESC: 'Choisissez une question de sécurité, puis entrez la réponse. Vous recevrez un mot de passe' +
        ' temporaire à <b class="email">{{email}}<b/>',
    ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_TITLE: 'J\'ai déjà un mot de passe',
    ODC_MODAL_CONNEXION_ALREADY_HAVE_PASSWORD_DESC: 'Utilisez votre mot de passe pour enregistrer une nouvelle demande d\'ouverture de compte.',
    ODC_MODAL_CONNEXION_NOTE: '<b>Note</b> : Ce mot de passe est utilisé uniquement pour l\'ouverture de votre compte en ligne. Il est différent de' +
        ' celui utilisé pour la connexion à la plateforme Disnat.',
    ODC_MODAL_CONNEXION_ENTER_PASSWORD_LINK: 'Entrer votre mot de passe',

    ODC_MODAL_CONNEXION_TEMP_PASSWORD_TITLE: 'Entrer le mot de passe temporaire',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_DESC: 'Un mot de passe temporaire a été envoyé à <b class="email">{{email}}</b>. Il est valable' +
        ' 10&nbsp;minutes.',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_CHECK_SPAM_DESC: 'Si vous n\'avez pas reçu de mot de passe par courriel, vous pouvez en demander un nouveau.' +
        ' Pensez à vérifier vos courriels indésirables.',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_LINK: 'Renvoyer un courriel',
    ODC_MODAL_CONNEXION_TEMP_PASSWORD_GET_NEW_EMAIL_ALERT_DESC: 'Un nouveau courriel a été envoyé.',

    ODC_MODAL_CONNEXION_AUTHENTICATION_TITLE: 'Entrer votre mot de passe',
    ODC_MODAL_CONNEXION_AUTHENTICATION_DESC: 'Entrer votre mot de passe pour enregistrer une demande d’ouverture de compte.',

    ODC_MODAL_DEFINE_NEW_PASSWORD_CONFIRMATION_BUTTON_LABEL: 'Enregistrer la demande',

    ODC_MODAL_CREATE_PROFILE_NEW_PASSWORD_TITLE: 'Créer un nouveau mot de passe',

    ODC_MODAL_SAVED_APPLICATION_BODY: 'Votre demande a été enregistrée.',

    ODC_MODAL_FORGOT_PASSWORD_TITLE: 'Réinitialiser votre mot de passe',
    ODC_MODAL_FORGOT_PASSWORD_DESC: 'Voulez-vous réinitialiser le mot de passe associé à <b class="email">{{email}}</b>?',
    ODC_MODAL_FORGOT_PASSWORD_CONFIRMATION_BUTTON_LABEL: 'Réinitialiser',
    ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_DESC: 'Aucune demande n\'existe pour l\'adresse courriel <b class="email">{{email}}</b>.<br>Veuillez créer un nouveau' +
        ' mot de passe.',
    ODC_MODAL_FORGOT_PASSWORD_NO_EMAIL_FOUND_CONFIRMATION_BUTTON_LABEL: 'Créer un mot de passe',

    FORGOT_PASSWORD_EMAIL_SENT: 'Un lien de réinitialisation a été envoyé par courriel à <b class="email">{{email}}</b>. Il est valable' +
        ' 10&nbsp;minutes.',

    ODC_MODAL_SAVED_APPLICATION_CONFIRM_BUTTON_LABEL: 'Fermer',

    SIGNIFICANT_CHANGE: 'Changement significatif',

    ODC_MODAL_CHANGING_SIGNIFICANT_FIELD: 'Êtes-vous sûr de vouloir modifier le champ "{{field}}" ? Cliquez sur <strong>Continuer</strong> pour poursuivre ou sur <strong>Annuler</strong> pour retirer la saisie.',
    ODC_MODAL_CHANGING_SIGNIFICANT_FIELD_TITLE: 'Modification d\'un champ significatif',

    /* Help Transfer request*/
    HELP_TRANSFER_REQUEST_TITLE: 'Compte de placement',
    HELP_TRANSFER_REQUEST_PARAGRAPH: 'Les comptes de placement incluent les comptes détenant des titres et les comptes enregistrés, et excluent les comptes chèques et les comptes d’opérations courantes. Le transfert d’argent en provenance de votre compte d’opérations courantes à votre caisse Desjardins se fait à l’aide de la fonction de <b>Virements entre comptes</b> sur AccèsD.',

    INVALIDATED_SESSION_TITLE: 'Session expirée',
    INVALIDATED_SESSION_DESCRIPTION: 'Votre session a expiré en raison d\'une période d\'inactivité prolongée.<br>Veuillez recommencer votre demande d\'ouverture de compte.',

    HELP_WARNING_FOOTNOTE: '\u00B9Le terme conseiller désigne toutes les catégories de conseillers inscrits auprès de l’OCRI, soit le représentant inscrit, le gestionnaire de portefeuille et le gestionnaire de portefeuille adjoint.'
};
