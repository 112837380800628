import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {FormService} from './form.service';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {ProfileBean} from '../models/ProfileBean';
import {CreateProfileBean} from '../models/createProfileBean';
import {ApiEndpoints} from '../enums/api-endpoints.enum';

@Injectable()
export class ProfileService {

    private emitSaveSource = new Subject<any>();

    constructor(private translate: TranslateService, private formService: FormService,
                private router: Router, private http: HttpClient,
                @Inject(ODC_CONFIG) private config: IOdcConfig) {

    }

    public initiateSave(): Observable<any> {
        return this.emitSaveSource.asObservable();
    }

    public createProfile(createProfileBean: CreateProfileBean): Observable<any> {
        return this.http.post('api/createProfile', createProfileBean, {withCredentials: true});
    }

    public resetTempPassword(lang: string, email: string): Observable<any> {
        return this.http.post(ApiEndpoints.ExternalResetTempPassword, {lang, email}, {withCredentials: true});
    }

    public definePassword(newPassword: string, confirmNewPassword: string): Observable<any> {
        const profile: ProfileBean = new ProfileBean();
        profile.password = newPassword;
        profile.confirmPassword = confirmNewPassword;
        return this.http.post('api/define-password', profile, {withCredentials: true});
    }

    public forgotPassword(lang: string, email: string, reCaptchaResponse: string): Observable<any> {
        const body = {lang: lang, email: email, reCaptchaResponse};
        return this.http.post('api/forgot-password', body, {withCredentials: true});
    }

    public getAccountOpeningRequestList(): Observable<any> {
        return this.http.get('api/getProfileAccountOpeningRequestList', {withCredentials: true});
    }
}
