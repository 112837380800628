export class RequestFollowUpSearchCriteria {

    act: string;
    noPdo: string;
    typeMembre: string;
    noInst: string;
    noTransit: string;
    advLstNam: string;
    advFstNam: string;
    transitOrig: string;
    instOrig: string;
    langue: string;
    codeUsager: string;

    transitRequests: boolean;
    latestRequests: boolean;
    clientName: string;
    requestStatus: string;

    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {

            this.act = this.init(obj.act);
            this.noPdo = this.init(obj.noPdo);
            this.typeMembre = this.init(obj.typeMembre);
            this.noInst = this.init(obj.noInst);
            this.noTransit = this.init(obj.noTransit);
            this.advLstNam = this.init(obj.advLstNam);
            this.advFstNam = this.init(obj.advFstNam);
            this.transitOrig = this.init(obj.transitOrig);
            this.instOrig = this.init(obj.instOrig);
            this.langue = this.init(obj.langue);
            this.codeUsager = this.init(obj.codeUsager);
            this.transitRequests = this.init(obj.transitRequests);
            this.latestRequests = this.init(obj.latestRequests);
            this.clientName = this.init(obj.clientName);
            this.requestStatus = this.init(obj.requestStatus);
        }
    }
}
