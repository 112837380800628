import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-review-field-question',
  templateUrl: './review-field-question.component.html'
})
export class ReviewFieldQuestionComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;

  ngOnInit() {

  }

}
