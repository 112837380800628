import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RecaptchaComponent} from '../../../login/recaptcha.component';
import {TranslateService} from '@ngx-translate/core';
import {FormService} from '../../../../components/services/form.service';
import {ModalService} from '../../../../components/services/modal.service';
import {ProfileService} from '../../../../components/services/profile.service';
import {AbstractControl, FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {VmdValidators} from '../../../../components/validators/vmd-validators';
import {VmdConstants} from '../../../../components/constants/vmd-constants';
import {AuthService} from '../../../../components/services/auth.service';
import {ModalSelectors} from '../../../../components/constants/modal-selectors';
import {ClientLoginBean} from '../../../../components/models/clientLoginBean';
import {UserLogin} from '../../../../components/models/user-login';
import {WebException} from '../../../../components/models';

declare let $: any;

@Component({
    selector: 'app-modal-create-profile',
    templateUrl: './modal-create-profile.component.html'
})
export class ModalCreateProfileComponent implements OnInit {

    @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;
    @ViewChild('httpError') httpErrorDiv!: ElementRef;
    @ViewChild(NgForm) ngForm: NgForm;

    constants = VmdConstants;
    email: string;
    loading: boolean = false;
    httpErrors: any;
    renderRecaptcha: boolean = false;
    displayCreateProfileFormGroup: boolean = false;
    displayDefinePasswordGroup: boolean = false;
    showNewEmailSentAlert: boolean = false;

    form: FormGroup = new FormGroup({});

    constructor(public translate: TranslateService,
                protected fb: FormBuilder,
                private formService: FormService,
                private modalService: ModalService,
                private profileService: ProfileService,
                private authService: AuthService) {
    }

    ngOnInit(): void {

        this.form = this.fb.group({
            authenticatePasswordGroup: this.fb.group({
                password: [null, [Validators.required]]
            }),
            definePasswordGroup: this.fb.group({
                newPassword: [null, [Validators.required, Validators.minLength(VmdConstants.PASSWORD_LENGTH_MIN)]],
                confirmNewPassword: [null, [Validators.required]],
                passwordStrength: [null, [Validators.min(VmdConstants.PASSWORD_STRENGTH_MIN)]]
            }, {validator: VmdValidators.passwordMatchValidator()}),
        });

        this.modalService.showCreateProfile.subscribe(() => {
            this.resetModal();
            this.renderRecaptcha = true;
            this.displayCreateProfileFormGroup = true;
            this.email = this.formService.getForm().requesters[0].userEmail;
            this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), true);
        });

        this.modalService.showDefinePassword.subscribe(() => {
            this.resetModal();
            this.displayDefinePasswordGroup = true;
            this.updateControlEnabled(this.form.get('definePasswordGroup'), true);
        });
    }

    submitForm() {
        this.httpErrors = null;
        this.markFormFieldsAsTouched(this.form);
        if (this.form.valid) {
            if (this.displayCreateProfileFormGroup) {
                this.authenticatePassword(this.form.get('authenticatePasswordGroup'));
            } else if (this.displayDefinePasswordGroup) {
                this.definePassword(this.form.get('definePasswordGroup'));
            }
        }
    }

    definePassword(form: AbstractControl) {
        this.loading = true;
        this.profileService.definePassword(
            form.get('newPassword').value,
            form.get('confirmNewPassword').value
        ).subscribe(
            () => {
                this.onDefinePasswordSuccess();
            }, (error) => {
                this.onDefinePasswordError(error);
            }).add(() => {
            this.loading = false;
        });
    }

    onDefinePasswordSuccess() {
        this.loading = true;
        this.formService.saveForm().subscribe(
            () => {
                this.closeModal();
                this.modalService.openModal('ODC_MODAL_CONNEXION_TITLE', 'ODC_MODAL_SAVED_APPLICATION_BODY',
                    ModalSelectors.ALERT_MODAL, null, null, ['GLOBAL_CLOSE']);
            }, (error) => {
                this.onDefinePasswordError(error);
            }
        ).add(() => {
            this.loading = false;
        });
    }

    onDefinePasswordError(error) {
        this.httpErrors = error;
    }

    authenticatePassword(form: AbstractControl) {
        this.loading = true;

        this.authService.authenticate(ClientLoginBean.fromObject({
            email: this.formService.getForm().requesters[0].userEmail,
            password: form.get('password').value,
            reCaptchaResponse: this.recaptcha.getRecaptchaToken()
        })).subscribe(
            (data: UserLogin) => {
                if (data.temporaryPasswordIndicator) {
                    this.modalService.emitShowDefineNewPassword();
                }
            },
            (error: WebException) => {
                this.onAuthenticatePasswordError(error);
            }
        ).add(() => {
                this.loading = false;
            }
        );
    }

    onAuthenticatePasswordError(error) {
        this.httpErrors = error;
        this.resetRecaptcha();
    }

    closeModal() {
        if (!$(ModalSelectors.CREATE_PROFILE_MODAL).length) {
            return;
        }
        $(ModalSelectors.CREATE_PROFILE_MODAL).modal('hide');
    }

    resetModal() {
        this.httpErrors = null;

        this.showNewEmailSentAlert = false;
        this.displayCreateProfileFormGroup = false;
        this.updateControlEnabled(this.form.get('authenticatePasswordGroup'), false);
        this.displayDefinePasswordGroup = false;
        this.updateControlEnabled(this.form.get('definePasswordGroup'), false);

        this.form.reset();
        this.resetRecaptcha();
    }

    resetRecaptcha() {
        if (this.recaptcha) {
            this.recaptcha.reset();
        }
    }

    private markFormFieldsAsTouched(control: FormGroup): void {
        if (this.displayCreateProfileFormGroup) {
            control.get('authenticatePasswordGroup.password').markAsTouched();
        } else if (this.displayDefinePasswordGroup) {
            control.get('definePasswordGroup.newPassword').markAsTouched();
            control.get('definePasswordGroup.confirmNewPassword').markAsTouched();
        }
    }

    isFieldInError(fieldName: string) {
        const control: AbstractControl = this.form.get(fieldName);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid &&
            ((control.hasError(VmdValidators.required.name) && this.isFormSubmitted()) ||
                !control.hasError(VmdValidators.required.name));
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    onPasswordStrengthChanges(value: number) {
        if (value != null) {
            this.form.get('definePasswordGroup.passwordStrength').setValue(value);
        }
    }

    updateControlEnabled(control: AbstractControl, enabledCondition: boolean): void {
        if (enabledCondition) {
            if (control.disabled) {
                control.enable();
                control.markAsUntouched();
            }
        } else {
            if (control.enabled) {
                control.disable();

                // fix bug - sometimes, it is still enabled. Do it again
                if (control.enabled) {
                    control.disable();
                }
            }
        }
    }

    resetTempPassword() {
        this.httpErrors = null;
        this.loading = true;
        this.showNewEmailSentAlert = false;

        this.profileService.resetTempPassword(this.translate.currentLang, this.email).subscribe(
            () => {
                this.showNewEmailSentAlert = true;
            },
            (error: WebException) => {
                this.httpErrors = error;
            }
        ).add(() => {
                this.loading = false;
            }
        );

    }
}
