import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ClientLoginBean} from "../models/clientLoginBean";
import {ApiEndpoints} from "../enums/api-endpoints.enum";

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {
    }

    public authenticate(clientLoginBean: ClientLoginBean): Observable<any> {
        return this.http.post(ApiEndpoints.AuthenticationEntryPoint, clientLoginBean);
    }
}
