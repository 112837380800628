export enum SummaryFieldsTypeEnum {
    BOOLEAN,
    FREE_FORM,
    LANG,
    PHONE,
    NIF_LIST,
    COUNTRY_LIST,
    COUNTRIES_LIST,
    JOB_LIST,
    MARITAL_STATUS_LIST,
    INVESTMENT_HORIZON_LIST,
    RISK_TOLERANCE_LIST,
    RISK_CLASS_LIST,
    BROKERAGE_KNOWLEDGE_LIST,
    INTENDED_USAGE_LIST,
    DOCUMENT_SECURITY_HOLDER_LIST,
    FINANCIAL_FORM,
    PERCENTAGE_FORM
}
