import {ResidentialAddressThirdHolderComponent} from './modules/personal-information/residential-address-third-holder.component';

export const JOINT_ACCOUNT_SSD_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_JOINT_ACCOUNT_SSD_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'TypeOfManagementComponent',
                saveAvailable: false,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_JOINT_ACCOUNT_SSD_PATH',
                component: 'JointRelatedQuestionsComponent',
                saveAvailable: false
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_JOINT_ACCOUNT_SSD_PATH',
                component: 'AccountsChoiceComponent',
                disable: true,
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PERSONAL_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH',
                component: 'PersonalInformationComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1_JOINT_ACCOUNT_SSD_PATH',
                component: 'ResidentialAddressComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH',
                component: 'PersonalInformationSecondHolderComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2_JOINT_ACCOUNT_SSD_PATH',
                component: 'ResidentialAddressSecondHolderComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH',
                component: 'PersonalInformationThirdHolderComponent',
                disable: true,
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_3_JOINT_ACCOUNT_SSD_PATH',
                component: 'ResidentialAddressThirdHolderComponent',
                disable: true,
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_OCCUPATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_1',
                path: 'ODC_NAV_STEP_OCCUPATION_CLIENT_1_JOINT_ACCOUNT_SSD_PATH',
                component: 'OccupationComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_2',
                path: 'ODC_NAV_STEP_OCCUPATION_CLIENT_2_JOINT_ACCOUNT_SSD_PATH',
                component: 'OccupationComponentSecondHolderComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_PERSONAL_OCCUPATION_CLIENT_3',
                path: 'ODC_NAV_STEP_OCCUPATION_CLIENT_3_JOINT_ACCOUNT_SSD_PATH',
                component: 'OccupationComponentThirdHolderComponent',
                disable: true,
                saveAvailable: true
            }

        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_CIVIL_STATUS',
        items: [
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_JOINT_ACCOUNT_SSD_PATH',
                component: 'SpouseInformationComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH',
                path: 'ODC_NAV_STEP_REFERRED_ACCOUNT_JOINT_ACCOUNT_SSD_PATH',
                component: 'ReferredAccountComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_BENEFICIARY_RESP_JOINT_ACCOUNT_SSD_PATH',
                component: 'BeneficiaryRespComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_LEGAL',
        items: [
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_JOINT_ACCOUNT_SSD_PATH',
                component: 'BrokerageAccountsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_JOINT_ACCOUNT_SSD_PATH',
                component: 'BrokerageAccountsComponentSecondHolderComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_3_JOINT_ACCOUNT_SSD_PATH',
                component: 'BrokerageAccountsComponentThirdHolderComponent',
                disable: true,
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_JOINT_ACCOUNT_SSD_PATH',
                component: 'AttorneyComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX',
        items: [
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_JOINT_ACCOUNT_SSD_PATH',
                component: 'FinancialInformationComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_JOINT_ACCOUNT_SSD_PATH',
                component: 'InvestmentObjectivesAndRiskFactorsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER',
                path: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_JOINT_ACCOUNT_SSD_PATH',
                component: 'ShareholderComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_JOINT_ACCOUNT_SSD_PATH',
                component: 'OptionAccountComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_JOINT_ACCOUNT_SSD_PATH',
                component: 'TransferRequestChoiceComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_1',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_JOINT_ACCOUNT_SSD_PATH',
                component: 'ReviewClientIdentityComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_2',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_JOINT_ACCOUNT_SSD_PATH',
                component: 'ReviewClientIdentitySecondHolderComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PRINT',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_JOINT_ACCOUNT_SSD_PATH',
                component: 'TransmissionKitDownloadComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_SUBMIT',
        items: [
            {
                label: 'ODC_NAV_STEP_SUBMIT',
                path: 'ODC_NAV_STEP_SUBMIT_JOINT_ACCOUNT_SSD_PATH',
                component: 'SubmissionComponent',
                saveAvailable: false,
                disableMode: true
            }
        ]
    }
];
