export class OssQuery {

    private static instance: OssQuery = null;

    public clientCode: string;
    public queryLanguage: string;

    constructor(obj?: any) {
        if (obj) {
            this.clientCode = OssQuery.init(obj.clientCode);
            this.queryLanguage = OssQuery.init(obj.queryLanguage);
        }
    }

    static init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    static create(clientCode: string, queryLanguage: string) {
        OssQuery.instance = new OssQuery();

        OssQuery.instance.clientCode = clientCode;
        OssQuery.instance.queryLanguage = queryLanguage;
    }

    static getInstance(): OssQuery {
        return OssQuery.instance;
    }

    static clear() {
        OssQuery.instance = null;
    }
}
