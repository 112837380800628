import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';


import {VmdKeyboard} from '../utils/vmd-keyboard';
import {VmdFormat} from '../formats/vmd-format';
import {TranslateService} from '@ngx-translate/core';

@Directive({
    selector: '[appPercentFormat]',
    providers: [
        VmdKeyboard
    ]
})
export class PercentFormatDirective implements ControlValueAccessor, OnInit {

    modelValue: string;
    select = false;

    constructor(private el: ElementRef, private keyboard: VmdKeyboard, private translate: TranslateService) {
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.writeValue(this.modelValue);
        });
    }


    @HostListener('select', ['$event'])
    onSelect(event: MouseEvent) {
        this.select = true;
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        const originalValue = VmdFormat.numeric.undoFormatAsNumeric(VmdFormat.numeric.formatAsNumeric(this.el.nativeElement.value));

        this.el.nativeElement.value = VmdFormat.numeric.formatAsNumeric(originalValue);

        this.writeValue(originalValue);
        this.onTouched();
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    @HostListener('focus', ['$event'])
    onFocus(event: KeyboardEvent) {
        const value = '' + VmdFormat.numeric.undoFormatAsNumeric(this.el.nativeElement.value);
        if (!!value) {
            this.el.nativeElement.value = VmdFormat.numeric.undoFormatAsNumeric(this.el.nativeElement.value);
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (!this.keyboard.isKeyDownAllowed(event)) {
            event.preventDefault();
        }
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if ((this.keyboard.isKeyPressNumeric(event) && this.el.nativeElement.value >= 0 && this.el.nativeElement.value < 100) || (this.keyboard.isKeyPressNumeric(event) && this.select)) {
            return;
        }

        if (this.el.nativeElement.value === 10 && VmdKeyboard.getKeyCode(event) === 48) {
            return;
        }

        event.preventDefault();
        this.el.nativeElement.value = VmdFormat.numeric.undoFormatAsNumeric(this.el.nativeElement.value);
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (!this.keyboard.isKeyArrow(event) && !this.keyboard.isKeyPressNumeric(event)) {
            this.el.nativeElement.value = VmdFormat.numeric.undoFormatAsNumeric(this.el.nativeElement.value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {

        if (obj !== undefined) {

            this.modelValue = obj;
            this.el.nativeElement.value = VmdFormat.numeric.formatAsNumeric(obj);
            this.onChange(obj);
        }
    }
}
