import {Component, Inject, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {FormBuilder, Validators} from '@angular/forms';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {ListModelService} from '../../components/services/list-model.service';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {VmdConstants} from '../../components/constants/vmd-constants';

@Component({
    selector: 'app-financial-informations',
    templateUrl: './financial-information.component.html'
})
export class FinancialInformationComponent extends BaseNavComponent implements OnInit {

    bankList: HtmlSelectKV[];
    showEditTotalNetWorth = false;
    showEditLoan = false;
    isMandatoryReDocumentation: boolean = this.isReDocumentation();
    isReadOnlyAndUpdateRequest: boolean = this.readOnlyAndUpdateRequest();

    constructor(@Inject(ODC_CONFIG) public config: IOdcConfig,
                public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            incomes: this.fb.group({
                userAnnualIncome: [this.formService.getForm().requesters[this.requesterIndex].userAnnualIncome, [this.requiredValidator('userAnnualIncome'), VmdValidators.digit, Validators.maxLength(9)]],
                userTotalNetWorth: [this.formService.getForm().requesters[this.requesterIndex].userTotalNetWorth],
                userNewTotalNetWorth: ['', [VmdValidators.digit, Validators.maxLength(9)]],
                userNetCapitalAsset: [this.formService.getForm().requesters[this.requesterIndex].userNetCapitalAsset, [this.requiredValidator('userNetCapitalAsset'), VmdValidators.digit, Validators.maxLength(9)]],
                userNetLiquidityAsset: [this.formService.getForm().requesters[this.requesterIndex].userNetLiquidityAsset, [this.requiredValidator('userNetLiquidityAsset'), VmdValidators.digit, Validators.maxLength(9)]]
            }),
            userBankId: [this.formService.getForm().requesters[this.requesterIndex].userBankId, [this.requiredValidator('userBankId')]],
            userBankBranchNumber: [this.formService.getForm().requesters[this.requesterIndex].userBankBranchNumber, [this.requiredValidator('userBankBranchNumber'), VmdValidators.transit]],
            userBankAccountNumber: [this.formService.getForm().requesters[this.requesterIndex].userBankAccountNumber, [this.requiredValidator('userBankAccountNumber'), VmdValidators.digit, Validators.maxLength(12)]],
            userBankPhone: [this.formService.getForm().requesters[this.requesterIndex].userBankPhone, [this.requiredValidator('userBankPhone'), VmdValidators.phone]],
            borrowedForInvestmentPurposeIndicator: [this.formService.getForm().borrowedForInvestmentPurposeIndicator, [this.requiredValidator('borrowedForInvestmentPurposeIndicator')]],
            borrowing: this.fb.group({
                borrowingAmountOfLoan: [this.formService.getForm().borrowingAmountOfLoan, [this.requiredValidator('borrowingAmountOfLoan')]],
                borrowingInterestRate: [this.formService.getForm().borrowingInterestRate, [this.requiredValidator('borrowingInterestRate'), Validators.max(999.99)]],
                borrowingLoanStartingDate: [this.formService.getForm().borrowingLoanStartingDate, [this.requiredValidator('borrowingLoanStartingDate'), VmdValidators.date, VmdValidators.dateRange('1900-01-01', '2999-12-31')]],
                borrowingLoanMaturityDate: [this.formService.getForm().borrowingLoanMaturityDate, [this.requiredValidator('borrowingLoanMaturityDate'), VmdValidators.date, VmdValidators.dateRange('1900-01-01', '2999-12-31')]],
                borrowingAmountInvested: [this.formService.getForm().borrowingAmountInvested, [this.requiredValidator('borrowingAmountInvested')]],
                borrowingReceivedAndReadBorrowingRisksIndicator: [this.formService.getForm().borrowingReceivedAndReadBorrowingRisksIndicator, [this.requiredValidator('borrowingReceivedAndReadBorrowingRisksIndicator')]],
            }, {validator: [VmdValidators.loanDateValidator]})
        });

        this.listModelService.getListModel('bankIdList', (data: any) => this.bankList = data);

        this.updateControlEnabled(this.form.get('incomes'), this.showIncomes());

        this.updateControlEnabled(this.form.get('userBankId'), !this.isPleinEx());
        this.updateControlEnabled(this.form.get('userBankBranchNumber'), !this.isPleinEx());
        this.updateControlEnabled(this.form.get('userBankAccountNumber'), !this.isPleinEx());
        this.updateControlEnabled(this.form.get('userBankPhone'), !this.isPleinEx());

        this.updateControlEnabled(this.form.get('incomes.userTotalNetWorth'), this.isPilotMemberOrModeUpdateAutomatedOpeningActive() && this.showUserTotalNetWorthSection());
        if (this.isPilotMemberOrModeUpdateAutomatedOpeningActive()) {
            if (!this.isPleinEx()) {
                this.disableDisnatControlsForUpdate();
            }
            if (this.showIncomes()) {
                const userAssetHasValues: boolean = this.form.get('incomes.userNetCapitalAsset').value ||
                    this.form.get('incomes.userNetLiquidityAsset').value ||
                    this.showEditTotalNetWorth;

                if (this.isMandatoryReDocumentation || !this.showUserTotalNetWorthSection()) {
                    this.updateNetCapitalAndNetLdtyControls(true);
                    this.sumNetCapitalAndNetLiquidityAsset();
                } else {
                    this.toggleEditTotalNetWorth(userAssetHasValues);
                }

                this.form.get('incomes.userNetCapitalAsset').valueChanges.subscribe(() => {
                    this.sumNetCapitalAndNetLiquidityAsset();
                });
                this.form.get('incomes.userNetLiquidityAsset').valueChanges.subscribe(() => {
                    this.sumNetCapitalAndNetLiquidityAsset();
                });
            }
        }

        if (this.readOnlyAndUpdateRequest()) {
            if (this.form.get('incomes.userNetCapitalAsset').value !== null &&
                this.form.get('incomes.userNetLiquidityAsset').value !== null) {
                const sum: number = Number(this.form.get('incomes.userNetCapitalAsset').value) +
                    Number(this.form.get('incomes.userNetLiquidityAsset').value);
                this.form.get('incomes.userTotalNetWorth').setValue(sum);
            } else {
                this.updateControlEnabled(this.form.get('incomes.userNetCapitalAsset'), false);
                this.updateControlEnabled(this.form.get('incomes.userNetLiquidityAsset'), false);
            }
        }

        this.form.get('borrowedForInvestmentPurposeIndicator').valueChanges.subscribe(value => {
            const borrowing = this.form.get('borrowing');
            this.updateControlEnabled(borrowing, value);
            this.formService.setFormProperty('borrowingAmountOfLoan', borrowing.get('borrowingAmountOfLoan').value);
            this.formService.setFormProperty('borrowingInterestRate', borrowing.get('borrowingInterestRate').value);
            this.formService.setFormProperty('borrowingLoanStartingDate', borrowing.get('borrowingLoanStartingDate').value);
            this.formService.setFormProperty('borrowingLoanMaturityDate', borrowing.get('borrowingLoanMaturityDate').value);
            this.formService.setFormProperty('borrowingAmountInvested', borrowing.get('borrowingAmountInvested').value);
            this.formService.setFormProperty('borrowingReceivedAndReadBorrowingRisksIndicator', borrowing.get('borrowingReceivedAndReadBorrowingRisksIndicator').value);
            this.showEditLoan = this.showLoan();
        });
        this.updateControlEnabled(this.form.get('borrowing'), this.form.get('borrowedForInvestmentPurposeIndicator').value);
        this.showEditLoan = this.showLoan();
    }

    showLoan() {
        const borrowedForInvestment = this.form.get('borrowedForInvestmentPurposeIndicator').value === true;
        const borrowing = this.form.get('borrowing');
        const isAnyFieldFilled = [
            borrowing.get('borrowingAmountOfLoan').value,
            borrowing.get('borrowingInterestRate').value,
            borrowing.get('borrowingLoanStartingDate').value,
            borrowing.get('borrowingLoanMaturityDate').value,
            borrowing.get('borrowingAmountInvested').value,
            borrowing.get('borrowingReceivedAndReadBorrowingRisksIndicator').value
        ].some(value => value !== null && value !== undefined && value !== "" && value !== false);
        return borrowedForInvestment && isAnyFieldFilled;
    }

    showIncomes(): boolean {
        const show = this.formService.getForm().requesters[this.requesterIndex].userEmployerIsBroker !== this.constants.USER_EMPLOYER_IS_BROKER_VMD;
        if (show) {
            this.initNetCapitalAndNetLqdtyAssetIfNull();
        }
        return show;
    }

    toggleEditTotalNetWorth(toggle: boolean) {
        this.showEditTotalNetWorth = toggle;
        this.updateNetCapitalAndNetLdtyControls(toggle);

        if (!this.showEditTotalNetWorth) {
            this.resetIncomesValue();
        } else {
            this.sumNetCapitalAndNetLiquidityAsset();
        }
        return false;
    }

    toggleEditLoan() {
        this.showEditLoan = !this.showEditLoan;
        if (!this.showEditLoan) {
            this.resetLoanValue();
        }
        return false;
    }

    sumNetCapitalAndNetLiquidityAsset() {
        const sum: number = Number(this.form.get('incomes.userNetCapitalAsset').value) + Number(this.form.get('incomes.userNetLiquidityAsset').value);
        this.form.get('incomes.userNewTotalNetWorth').setValue(sum);
        this.formService.setFormProperty(this.getRequesterPath('userNewTotalNetWorth'), sum === 0 ? null : sum);
    }

    updateNetCapitalAndNetLdtyControls(toogle: boolean) {
        this.updateControlEnabled(this.form.get('incomes.userNetCapitalAsset'), toogle);
        this.updateControlEnabled(this.form.get('incomes.userNetLiquidityAsset'), toogle);
    }

    resetIncomesValue() {
        this.form.get('incomes.userNetCapitalAsset').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userNetCapitalAsset'), null);
        this.form.get('incomes.userNetLiquidityAsset').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userNetLiquidityAsset'), null);
        this.form.get('incomes.userNewTotalNetWorth').setValue(null);
        this.formService.setFormProperty(this.getRequesterPath('userNewTotalNetWorth'), null);
    }

    resetLoanValue() {
        const borrowing = this.form.get('borrowing');
        borrowing.get('borrowingAmountOfLoan').setValue(null);
        borrowing.get('borrowingInterestRate').setValue(null);
        borrowing.get('borrowingLoanStartingDate').setValue(null);
        borrowing.get('borrowingLoanMaturityDate').setValue(null);
        borrowing.get('borrowingAmountInvested').setValue(null);
        borrowing.get('borrowingReceivedAndReadBorrowingRisksIndicator').setValue(null);
        this.formService.setFormProperty('borrowingAmountOfLoan', null);
        this.formService.setFormProperty('borrowingInterestRate', null);
        this.formService.setFormProperty('borrowingLoanStartingDate', null);
        this.formService.setFormProperty('borrowingLoanMaturityDate', null);
        this.formService.setFormProperty('borrowingAmountInvested', null);
        this.formService.setFormProperty('borrowingReceivedAndReadBorrowingRisksIndicator', false);
    }

    isReDocumentation(): boolean {
        const updateDate = new Date(this.formService.getForm().mostRecentFormDate);
        const maxUpdateDate = new Date(VmdConstants.LAST_UPDATE_DATE_MAX);
        return !(updateDate && updateDate > maxUpdateDate);
    }

    disableDisnatControlsForUpdate() {
        this.updateControlEnabled(this.form.get('userBankId'), false);
        this.updateControlEnabled(this.form.get('userBankBranchNumber'), false);
        this.updateControlEnabled(this.form.get('userBankAccountNumber'), false);
        this.updateControlEnabled(this.form.get('userBankPhone'), false);
    }

    showUserTotalNetWorthSection() {
        return this.form.get('incomes.userTotalNetWorth').value % 1 === 0;
    }

    readOnlyAndUpdateRequest(): boolean {
        return this.config.READ_ONLY && this.isUpdateAccount();
    }

    initNetCapitalAndNetLqdtyAssetIfNull() {
        if (this.form.get('incomes.userNetCapitalAsset').value === 'null') {
            this.form.get('incomes.userNetCapitalAsset').setValue(null);
        }
        if (this.form.get('incomes.userNetLiquidityAsset').value === 'null') {
            this.form.get('incomes.userNetLiquidityAsset').setValue(null);
        }
    }
}
