import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../../components/services/modal.service';
import {FormBuilder, Validators} from '@angular/forms';
import {HtmlSelectKV} from '../../../components/models/html-select-k-v';
import {ListModelService} from '../../../components/services/list-model.service';
import {FormService} from '../../../components/services/form.service';
import {BaseNavComponent} from '../questionnaire/base-nav.component';
import {NavigationService} from '../../../components/services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {Entity} from '../../../components/models/entity';
import {VmdValidators} from '../../../components/validators/vmd-validators';

declare let $: any;

const MODAL_CONTEXT_ENTITY = 'MODAL_CONTEXT_ENTITY';

@Component({
    selector: 'app-modal-add-entity',
    templateUrl: './modal-add-entity.component.html'
})
export class ModalAddEntityComponent extends BaseNavComponent implements OnInit {

    entityTypeList: HtmlSelectKV[];
    entityParent: Entity = null;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService,
                public modalService: ModalService,
                private listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {

        this.form = this.fb.group({

            userOtherEntityType: [null, Validators.required],
            userOtherEntityName: [null, Validators.required],
            userOtherAmericanIndividualsNumber: [null, [Validators.required, VmdValidators.digit, Validators.min(0), Validators.max(10)]],
            userOtherThanAmericanIndividualsNumber: [null, [Validators.required, VmdValidators.digit, Validators.min(0), Validators.max(10)]],
            userOtherAmericanResidentIndicator: [null, Validators.required]
        });

        this.subscriptions.push(this.modalService.openAddEntityPopupEmitted.subscribe((entity: Entity) => {
            this.entityParent = entity;
            if (this.entityParent !== null && this.entityParent.entities === null) {
                this.entityParent.entities = [];
            }
            this.resetForm();
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice1Emitted.subscribe((context: string) => {
            // Cancel, nothing to do
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe((context: string) => {
            if (MODAL_CONTEXT_ENTITY === context) {

                const list = (null === this.entityParent ? this.formService.getForm().entities : this.entityParent.entities);

                // Add entity or sub-entity
                this.pushNewEntity(
                    list,
                    this.form.get('userOtherEntityType').value,
                    this.form.get('userOtherEntityName').value,
                    this.form.get('userOtherAmericanIndividualsNumber').value,
                    this.form.get('userOtherThanAmericanIndividualsNumber').value,
                    this.form.get('userOtherAmericanResidentIndicator').value);
            }
        }));


        this.form.get('userOtherEntityType').valueChanges.subscribe(value => {
            this.ngForm.resetForm();


            this.updateControlEnabled(this.form.get('userOtherEntityName'), this.entityNameIsShowable());
            this.updateControlEnabled(this.form.get('userOtherAmericanIndividualsNumber'), this.physicalPersonsUsIsShowable());
            this.updateControlEnabled(this.form.get('userOtherThanAmericanIndividualsNumber'), this.physicalPersonsOtherThanUsIsShowable());
            this.updateControlEnabled(this.form.get('userOtherAmericanResidentIndicator'), this.isUsResidentIsShowable());
        });

        this.listModelService.getListModel('entityTypeList', data => this.entityTypeList = this.removeRRI(data));

    }

    closeModal() {
        if (!$('#modal-add-entity').length) {
            return;
        }
        this.ngForm.resetForm();
        $('#modal-add-entity').modal('hide');
    }

    resetForm(): void {
        this.form.get('userOtherEntityType').reset();
        this.form.get('userOtherEntityName').reset();
        this.form.get('userOtherAmericanIndividualsNumber').reset();
        this.form.get('userOtherThanAmericanIndividualsNumber').reset();
        this.form.get('userOtherAmericanResidentIndicator').reset();
    }

    private pushNewEntity(list: Entity[], entityType: string, entityName: string, physicalPersonsUs: number, physicalPersonsOtherThanUs: number, isUsResident: boolean) {
        list.push(
            new Entity({
                entityType,
                entityName,
                physicalPersonsUs,
                physicalPersonsOtherThanUs,
                isUsResident
            })
        );
    }

    submit(): void {
        this.submitForm(false);
        if (this.form.valid) {
            this.modalService.emitValidClickOnChoice2(MODAL_CONTEXT_ENTITY);
            this.closeModal();
        }
    }

    entityNameIsShowable(): boolean {
        const userOtherEntityTypeValue = this.form.get('userOtherEntityType').value;
        let showAbility: boolean;

        showAbility = (userOtherEntityTypeValue === this.constants.ASSOCIATION ||
            userOtherEntityTypeValue === this.constants.SOC_COMM ||
            userOtherEntityTypeValue === this.constants.SENC ||
            userOtherEntityTypeValue === this.constants.FIDUCIE_S ||
            userOtherEntityTypeValue === this.constants.FIDUCIE_C ||
            userOtherEntityTypeValue === this.constants.SUCC ||
            userOtherEntityTypeValue === this.constants.CORPO ||
            userOtherEntityTypeValue === this.constants.OSBL ||
            userOtherEntityTypeValue === this.constants.RRI);

        return showAbility;
    }

    physicalPersonsUsIsShowable(): boolean {
        const userOtherEntityTypeValue = this.form.get('userOtherEntityType').value;
        let showAbility: boolean;

        showAbility = (userOtherEntityTypeValue === this.constants.ASSOCIATION ||
            userOtherEntityTypeValue === this.constants.SOC_COMM ||
            userOtherEntityTypeValue === this.constants.SENC ||
            userOtherEntityTypeValue === this.constants.FIDUCIE_S);

        return showAbility;
    }

    physicalPersonsOtherThanUsIsShowable(): boolean {
        const userOtherEntityTypeValue = this.form.get('userOtherEntityType').value;
        let showAbility: boolean;

        showAbility = (userOtherEntityTypeValue === this.constants.ASSOCIATION ||
            userOtherEntityTypeValue === this.constants.SOC_COMM ||
            userOtherEntityTypeValue === this.constants.SENC ||
            userOtherEntityTypeValue === this.constants.FIDUCIE_S);

        return showAbility;
    }

    isUsResidentIsShowable(): boolean {
        const userOtherEntityTypeValue = this.form.get('userOtherEntityType').value;
        let showAbility: boolean;

        showAbility = (userOtherEntityTypeValue === this.constants.FIDUCIE_C ||
            userOtherEntityTypeValue === this.constants.SUCC ||
            userOtherEntityTypeValue === this.constants.CORPO ||
            userOtherEntityTypeValue === this.constants.OSBL ||
            userOtherEntityTypeValue === this.constants.RRI);


        return showAbility;

    }

    getAmericanResidentLabel(): string {
        const userOtherEntityTypeValue = this.form.get('userOtherEntityType').value;
        let americanResidentLabel = '';

        if (userOtherEntityTypeValue === this.constants.FIDUCIE_C) {
            americanResidentLabel = 'AMERICAN_RESIDENT_FIDUCIE_C';
        } else if (userOtherEntityTypeValue === this.constants.SUCC) {
            americanResidentLabel = 'AMERICAN_RESIDENT_SUCC';
        } else if (userOtherEntityTypeValue === this.constants.CORPO ||
            userOtherEntityTypeValue === this.constants.OSBL ||
            userOtherEntityTypeValue === this.constants.RRI) {
            americanResidentLabel = 'AMERICAN_RESIDENT_CORPO_OSBL_RRI';
        }

        return americanResidentLabel;
    }

    removeRRI(entityTypeList: HtmlSelectKV[]): any {

        if (!this.isPleinEx()) {
            for (let i = 0; i < entityTypeList.length; i++) {
                if (entityTypeList[i].itemValue === this.constants.RRI) {
                    entityTypeList.splice(i, 1);
                }
            }
        }
        return entityTypeList;
    }
}
