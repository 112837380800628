import * as numeral from 'numeral';
import {VmdConstants} from '../constants/vmd-constants';

export class VmdFormatNumeric {

    isEnglish(): boolean {
        return moment.locale() === VmdConstants.LANG_EN;
    }

    formatAsNumeric(valueToFormat: string | number, formatEn: string = VmdConstants.INTEGER_FORMAT_EN, formatFr: string = VmdConstants.INTEGER_FORMAT_FR): string {
        let formatToApply = '';

        if (this.isEnglish()) {
            formatToApply = formatEn;
        } else {
            formatToApply = formatFr;
            if (typeof valueToFormat === 'string') {
                if (valueToFormat.indexOf(',') > -1 && valueToFormat.indexOf('.') === -1) {
                    // allow using , as decimal separator
                    valueToFormat = valueToFormat.replace(/,/g, '.');
                }
            }
        }

        if ((!!valueToFormat) || valueToFormat === 0) {
            if (this.isEnglish()) {
                return numeral(valueToFormat).format(formatToApply);
            } else {
                return numeral(valueToFormat).format(formatToApply).replace(/,/g, ' ').replace(/\./g, ',');
            }
        }

        return '';
    }

    formatAsPercentage(valueToFormat: string, formatEn: string = VmdConstants.PERCENTAGE_FORMAT_EN, formatFr: string = VmdConstants.PERCENTAGE_FORMAT_FR): string {

        return this.isEnglish() ?
            valueToFormat.concat(formatEn) :
            valueToFormat.concat(formatFr);
    }

    formatAsInterestRate(valueToFormat: string, interestRateFormatEn: string = VmdConstants.INTEREST_RATE_FORMAT_EN, interestRateFormatFr: string = VmdConstants.INTEREST_RATE_FORMAT_FR): string {
        return this.formatAsNumeric(valueToFormat, VmdConstants.INTEREST_RATE_FORMAT_EN, VmdConstants.INTEREST_RATE_FORMAT_FR);
    }

    formatAsCurrency(valueToFormat: string, currencyFormatEn: string = VmdConstants.CURRENCY_FORMAT_EN, currencyFormatFr: string = VmdConstants.CURRENCY_FORMAT_FR): string {
        return this.formatAsNumeric(valueToFormat, VmdConstants.CURRENCY_FORMAT_EN, VmdConstants.CURRENCY_FORMAT_FR);
    }

    formatAsDecimalCurrency(valueToFormat: string): string {
        return this.formatAsNumeric(valueToFormat, VmdConstants.CURRENCY_DECIMAL_FORMAT_EN, VmdConstants.CURRENCY_DECIMAL_FORMAT_FR);
    }

    formatAsSecurityQuantity(valueToFormat: string): string {
        return this.formatAsNumeric(valueToFormat, VmdConstants.SECURITY_QUANTITY_FORMAT_EN, VmdConstants.SECURITY_QUANTITY_FORMAT_FR);
    }

    undoFormatAsNumeric(valueToUnformat: string): string {
        if (!!valueToUnformat) {
            if (this.isEnglish()) {
                return numeral(valueToUnformat).value();
            } else {
                return numeral(valueToUnformat.toString().replace(/,/g, '.').replace(/ /g, ',')).value();
            }

        }

        return '';
    }

    undoFormatAsInterestRate(valueToUnformat: string): string {
        return this.undoFormatAsNumeric(valueToUnformat);
    }

    undoFormatAsCurrency(valueToUnformat: string): string {
        return this.undoFormatAsNumeric(valueToUnformat);
    }

    undoFormatAsSecurityQuantity(valueToUnformat: string): string {
        return this.undoFormatAsNumeric(valueToUnformat);
    }
}
