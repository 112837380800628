import {Directive, ElementRef, forwardRef, HostListener, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {TranslateService} from '@ngx-translate/core';

import {VmdKeyboard} from '../utils/vmd-keyboard';
import {VmdFormat} from '../formats/vmd-format';

@Directive({
    selector: '[appDateFormat]',
    providers: [
        VmdKeyboard,
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateFormatDirective), multi: true}
    ]
})
export class DateFormatDirective implements ControlValueAccessor, OnInit {

    @Input() appAddressField = true;
    modelValue: string;
    select = false;

    constructor(private el: ElementRef, private keyboard: VmdKeyboard, private translate: TranslateService) {
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(() => {
            this.writeValue(this.modelValue);
        });
    }

    @HostListener('blur', ['$event'])
    onBlur(event: KeyboardEvent) {
        const originalValue = this.el.nativeElement.value;
        this.el.nativeElement.value = VmdFormat.date.formatAsDate(originalValue);

        this.writeValue(originalValue);
        this.onTouched();
    }

    onChange(obj: any) {
    }

    onTouched() {
    }

    @HostListener('focus', ['$event'])
    onFocus(event: KeyboardEvent) {
        const value = VmdFormat.date.undoFormatAsDate(this.el.nativeElement.value);
        if (!!value) {
            this.el.nativeElement.value = VmdFormat.date.formatAsInputDate(VmdFormat.date.undoFormatAsDate(this.el.nativeElement.value));
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (!this.keyboard.isKeyDownAllowed(event) || (((this.el.nativeElement.value.length === 10) && !this.keyboard.isKeyPressSpecial(event) && !this.select)
            && ((this.el.nativeElement.value.length === 10) && !this.keyboard.isKeyArrow(event) && !this.select))) {
            event.preventDefault();
        }
        if (this.select === true) { this.select = false; }
    }

    @HostListener('select', ['$event'])
    onSelect(event: MouseEvent) {
        this.select = true;
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (this.keyboard.isKeyPressNumeric(event)) {
            return;
        }

        event.preventDefault();
        if (VmdFormat.date.undoFormatAsDate(this.el.nativeElement.value).length > 10) {
            this.el.nativeElement.value = VmdFormat.date.undoFormatAsDate(this.el.nativeElement.value).substr(0, 10);
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(event: KeyboardEvent) {
        if (!this.keyboard.isKeyArrow(event)) {
            if (this.el.nativeElement.value.length > 10) {
                this.el.nativeElement.value = VmdFormat.date.undoFormatAsDate(this.el.nativeElement.value);
            }
            this.el.nativeElement.value = VmdFormat.date.formatAsInputDate(this.el.nativeElement.value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.modelValue = obj;
        this.el.nativeElement.value = VmdFormat.date.formatAsDate(obj);
        this.onChange(obj);
    }

}
