import {Component} from '@angular/core';
import {BaseHoldingAccountRelatedQuestionsComponent} from './base-holding-account-related-questions.component';

@Component({
    selector: 'app-soc-comm-related-questions',
    templateUrl: './base-holding-account-related-questions.component.html'
})
export class SocCommRelatedQuestionsComponent extends BaseHoldingAccountRelatedQuestionsComponent {

    public getFieldPrefix(): string {
        return 'soccomm';
    }

    public getMaxActPeople(): number {
        return 10;
    }

    public hasActPeople(): boolean {
        return true;
    }

    public hasLegActPeople(): boolean {
        return true;
    }

    public hasShareholders(): boolean {
        return false;
    }

}
