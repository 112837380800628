import {Component, inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {VmdConstants} from '../../components/constants/vmd-constants';
import {HtmlSelectKV} from '../../components/models/html-select-k-v';
import {AccountRulesService} from '../../components/services/account-rules.service';
import {FormService} from '../../components/services/form.service';
import {ListModelService} from '../../components/services/list-model.service';
import {ModalService} from '../../components/services/modal.service';
import {NavigationService} from '../../components/services/navigation.service';
import {VmdValidators} from '../../components/validators/vmd-validators';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';

const MODAL_NAME_REEE = 'REEE';

@Component({
    selector: 'app-accounts-choice',
    templateUrl: './accounts-choice.component.html'
})
export class AccountsChoiceComponent extends BaseNavComponent implements OnInit {

    jurisdictions: HtmlSelectKV[];
    modalName: string;
    isNotConjointCtlType: boolean = false;
    config: IOdcConfig;
    showRegisteredAccountTitleForGpd: boolean = false;
    showREEEOptionForGpd: boolean = false

    private modalList: { titleKey: string, bodyKey: string }[] = [];

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                protected router: Router,
                public formService: FormService,
                public translate: TranslateService,
                protected accountRulesService: AccountRulesService,
                protected modalService: ModalService,
                protected listModelService: ListModelService) {
        super(navigationService, fb, formService, translate);
        this.config = inject(ODC_CONFIG);
    }

    isPrintMode(): boolean {
        return this.formService.isPrintMode();
    }

    ngOnInit() {
        this.setPleinExDefaultValues();
        this.isNotConjointCtlType = this.isNotConjoint();

        this.subscriptions.push(this.modalService.closeModalAlertEmitted.subscribe(() => {
            this.openModal();
        }));

        this.subscriptions.push(this.modalService.validClickOnChoice2Emitted.subscribe(() => {

            if ((this.modalName === MODAL_NAME_REEE) && this.formService.getForm().addRESP) {
                this.getForm().get('addRESP').setValue(false);
                this.formService.setFormProperty('addRESP', false);
                this.formService.setFormProperty('respTransfer', false);
                if (!this.isPleinEx() || this.processRules()) {
                    this.submit();
                }
            }

        }));

        this.form = this.fb.group({
            newExistAct: [this.formService.getForm().newExistAct,
                [this.isUpdateAccount() ? VmdValidators.updateContext(this.formService.isPrintMode(), this.isPleinEx())
                    : VmdValidators.modeIsPrint(this.formService.isPrintMode(), this.isPleinEx()),
                    VmdValidators.required(!this.isPleinEx())]],
            addAccount: this.fb.group({
                cltNo: [this.formService.getForm().cltNo, [this.requiredValidator('cltNo'), VmdValidators.username]]
            }),
            actType: [this.formService.getForm().actType, this.isUpdateAccount() ?
                Validators.nullValidator : this.requiredValidator('actType')],
            marginAccount: this.fb.group({
                optionAct: [this.formService.getForm().optionAct],
                shortSellAct: [this.formService.getForm().shortSellAct]
            }),
            cashAccountMgntType: [{value: this.formService.getForm().cashAccountMgntType, disabled: this.isGpdOrSfd()}],
            currency: [this.formService.getForm().currency, [this.requiredValidator('currency')]],
            fiduciaryTransactionAccountsCad: this.fb.group({
                accountNumberCad: [this.formService.getForm().accountNumberCad, [this.requiredValidator('accountNumberCad'), VmdValidators.lengthValidator(7, 7)]],
                productNumberCad: [this.formService.getForm().productNumberCad, [this.requiredValidator('productNumberCad'), VmdValidators.lengthValidator(2, 2)]]
            }),
            fiduciaryTransactionAccountsUsd: this.fb.group({
                accountNumberUsd: [this.formService.getForm().accountNumberUsd, [this.requiredValidator('accountNumberUsd'), VmdValidators.lengthValidator(7, 7)]],
                productNumberUsd: [this.formService.getForm().productNumberUsd, [this.requiredValidator('productNumberUsd'), VmdValidators.lengthValidator(2, 2)]]
            }),

            addTFSA: [this.formService.getForm().addTFSA],
            tfsaAccount: this.fb.group({
                tfsaAccountMgntType: [this.formService.getForm().tfsaAccountMgntType],
                tfsaCurrency: [this.formService.getForm().tfsaCurrency, this.requiredValidator('tfsaCurrency')]
            }),

            addFHSA: [this.formService.getForm().addFHSA],
            fhsaAccount: this.fb.group({
                fhsaAccountMgntType: [this.formService.getForm().fhsaAccountMgntType],
                fhsaCurrency: [this.formService.getForm().fhsaCurrency, this.requiredValidator('fhsaCurrency')],
                fhsaEligibility: [this.formService.getForm().fhsaEligibility, [this.requiredValidator('fhsaEligibility'), Validators.requiredTrue]]
            }),

            addRRSP: [this.formService.getForm().addRRSP],
            rrspAccount: this.fb.group({
                rrspAccountMgntType: [this.formService.getForm().rrspAccountMgntType],
                rrspCurrency: [this.formService.getForm().rrspCurrency, this.requiredValidator('rrspCurrency')],
                rrspAnnuitant: [this.formService.getForm().rrspAnnuitant, this.requiredValidator('rrspAnnuitant')]
            }),

            addRRSPSec: [this.formService.getForm().addRRSPSec],
            rrspAccountSec: this.fb.group({
                rrspAccountMgntTypeSec: [this.formService.getForm().rrspAccountMgntTypeSec],
                rrspCurrencySec: [this.formService.getForm().rrspCurrencySec, this.requiredValidator('rrspCurrency')],
                rrspAnnuitantSec: [this.formService.getForm().rrspAnnuitantSec, this.requiredValidator('rrspAnnuitant')]
            }),

            addRRSPTer: [this.formService.getForm().addRRSPTer],
            rrspAccountTer: this.fb.group({
                rrspAccountMgntTypeTer: [this.formService.getForm().rrspAccountMgntTypeTer],
                rrspCurrencyTer: [this.formService.getForm().rrspCurrencyTer, this.requiredValidator('rrspCurrency')],
                rrspAnnuitantTer: [this.formService.getForm().rrspAnnuitantTer, this.requiredValidator('rrspAnnuitant')]
            }),

            addLIRA: [this.formService.getForm().addLIRA],
            liraAccount: this.fb.group({
                liraAccountMgntType: [this.formService.getForm().liraAccountMgntType],
                liraCurrency: [this.formService.getForm().liraCurrency, this.requiredValidator('liraCurrency')],
                liraJurisdiction: [this.formService.getForm().liraJurisdiction, this.requiredValidator('liraJurisdiction')]
            }),

            addLIRASec: [this.formService.getForm().addLIRASec],
            liraAccountSec: this.fb.group({
                liraAccountMgntTypeSec: [this.formService.getForm().liraAccountMgntTypeSec],
                liraCurrencySec: [this.formService.getForm().liraCurrencySec, this.requiredValidator('liraCurrency')],
                liraJurisdictionSec: [this.formService.getForm().liraJurisdictionSec, this.requiredValidator('liraJurisdiction')]
            }),

            addRRIF: [this.formService.getForm().addRRIF],
            rrifAccount: this.fb.group({
                rrifAccountMgntType: [this.formService.getForm().rrifAccountMgntType],
                rrifCurrency: [this.formService.getForm().rrifCurrency, this.requiredValidator('rrifCurrency')],
                rrifAnnuitant: [this.formService.getForm().rrifAnnuitant, this.requiredValidator('rrifAnnuitant')]
            }),

            addRRIFSec: [this.formService.getForm().addRRIFSec],
            rrifAccountSec: this.fb.group({
                rrifAccountMgntTypeSec: [this.formService.getForm().rrifAccountMgntTypeSec],
                rrifCurrencySec: [this.formService.getForm().rrifCurrencySec, this.requiredValidator('rrifCurrency')],
                rrifAnnuitantSec: [this.formService.getForm().rrifAnnuitantSec, this.requiredValidator('rrifAnnuitant')]
            }),

            addLIF: [this.formService.getForm().addLIF],
            lifAccount: this.fb.group({
                lifAccountMgntType: [this.formService.getForm().lifAccountMgntType],
                lifJurisdiction: [this.formService.getForm().lifJurisdiction, this.requiredValidator('lifJurisdiction')],
                lifCurrency: [this.formService.getForm().lifCurrency, this.requiredValidator('lifCurrency')]
            }),

            addFTARRSP: [this.formService.getForm().addFTARRSP],
            ftarrspAccount: this.fb.group({
                ftarrspAccountMgntType: [this.formService.getForm().ftarrspAccountMgntType],
                ftarrspAnnuitant: [this.formService.getForm().ftarrspAnnuitant, this.requiredValidator('ftarrspAnnuitant')],
                ftarrspRevocableBeneficiaryIndicator: [this.formService.getForm().ftarrspRevocableBeneficiaryIndicator, this.requiredValidator('ftarrspRevocableBeneficiaryIndicator')]
            }),

            addFTARRSPSec: [this.formService.getForm().addFTARRSPSec],
            ftarrspAccountSec: this.fb.group({
                ftarrspAccountMgntTypeSec: [this.formService.getForm().ftarrspAccountMgntTypeSec],
                ftarrspAnnuitantSec: [this.formService.getForm().ftarrspAnnuitantSec, this.requiredValidator('ftarrspAnnuitant')],
                ftarrspRevocableBeneficiaryIndicatorSec: [this.formService.getForm().ftarrspRevocableBeneficiaryIndicatorSec, this.requiredValidator('ftarrspRevocableBeneficiaryIndicator')]
            }),

            addFTARRSPTer: [this.formService.getForm().addFTARRSPTer],
            ftarrspAccountTer: this.fb.group({
                ftarrspAccountMgntTypeTer: [this.formService.getForm().ftarrspAccountMgntTypeTer],
                ftarrspAnnuitantTer: [this.formService.getForm().ftarrspAnnuitantTer, this.requiredValidator('ftarrspAnnuitant')],
                ftarrspRevocableBeneficiaryIndicatorTer: [this.formService.getForm().ftarrspRevocableBeneficiaryIndicatorTer, this.requiredValidator('ftarrspRevocableBeneficiaryIndicator')]
            }),

            addRESP: [this.formService.getForm().addRESP],
            respAccount: this.fb.group({
                respAccountMgntType: [this.formService.getForm().respAccountMgntType],
                respType: [this.formService.getForm().respType, this.requiredValidator('respType')],
                respTypeFamilyPleinEx: [this.isRespTypeFamily(), this.requiredValidator('respType')],
                respTypeIndividualPleinEx: [this.isRespTypeIndividual(), this.requiredValidator('respType')],
                respTypeFamily: this.fb.group({
                    respNumberOfBeneficiaries: [this.formService.getForm().respNumberOfBeneficiaries, [this.requiredValidator('respNumberOfBeneficiaries'), VmdValidators.digit, Validators.min(1), Validators.max(6)]]
                }),
                respIsSubscriberResponsible: [this.formService.getForm().respIsSubscriberResponsible, this.requiredValidator('respNumberOfBeneficiaries')]
            }),

            addDiscretionary: [false, VmdValidators.requiredFalse]
        }, {
            validator: [
                VmdValidators.onlyOneAccountTypeInUSD(this.isPleinEx()),
                VmdValidators.notSameJuridiction(this.isPleinEx()),
                VmdValidators.maxTwoSameDesignationOfBeneficiary(this.isPleinEx()),
                VmdValidators.invalidMgntType(this.isPleinEx(), this.isPrintMode())
            ]
        });

        this.initManagementForRegisteredAccounts();

        let jurisdictionsList = 'jurisdictionsList';
        if (this.isGpdOrSfd()) {

            jurisdictionsList = 'jurisdictionsGPDList';
        }

        this.listModelService.getListModel(jurisdictionsList, (data: any) => this.jurisdictions = data);

        this.checkForceCurrencyToCAD();
        this.checkForceCurrencyToUSD('rrspAccount.rrspCurrency');
        this.checkForceCurrencyToUSD('rrspAccountSec.rrspCurrencySec');
        this.checkForceCurrencyToUSD('rrspAccountTer.rrspCurrencyTer');
        this.checkForceCurrencyToUSD('tfsaAccount.tfsaCurrency');
        this.checkForceCurrencyToUSD('fhsaAccount.fhsaCurrency');
        this.checkForceCurrencyToUSD('liraAccount.liraCurrency');
        this.checkForceCurrencyToUSD('liraAccountSec.liraCurrencySec');
        this.checkForceCurrencyToUSD('lifAccount.lifCurrency');
        this.checkForceCurrencyToUSD('rrifAccount.rrifCurrency');
        this.checkForceCurrencyToUSD('rrifAccountSec.rrifCurrencySec');

        this.checkCurrencyForSfdFields();

        this.form.get('newExistAct').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('addAccount'), value === this.constants.ACCOUNT_ADD_ACCOUNT && !this.isPleinEx());

            if (value === this.constants.ACCOUNT_ADD_ACCOUNT && this.isPleinEx()) {

                this.formService.setMode(VmdConstants.MODES.PRINT);
                this.formService.applyRules();

                this.router.navigated = false;
                this.router.navigate([this.router.url]);
            }
        });
        this.updateControlEnabled(this.form.get('addAccount'), this.form.get('newExistAct').value === this.constants.ACCOUNT_ADD_ACCOUNT && !this.isPleinEx());

        if (this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT) {
            if (!this.isPleinEx()) {
                this.updateControlEnabled(this.form.get('newExistAct'), false);
                this.updateControlEnabled(this.form.get('addAccount'), false);
                this.updateControlEnabled(this.form.get('actType'), false);
                this.updateControlEnabled(this.form.get('marginAccount'), false);
                this.updateControlEnabled(this.form.get('currency'), false);
            }
        }

        if (this.formService.getForm().actType === this.constants.ACCOUNT_TYPE_MARGIN
            && !this.showMarginAccount()
            && !this.isStatusInjection()) {
            this.resetMarginAccountAndMgntType();
            this.form.get('actType').setValue(this.constants.ACCOUNT_TYPE_CASH);
            this.formService.setFormProperty('actType', this.constants.ACCOUNT_TYPE_CASH);
        } else if (this.formService.getForm().actType === this.constants.ACCOUNT_TYPE_MARGIN && this.showMarginAccountWithShortSelling() && !this.isStatusInjection()) {
            this.form.get('marginAccount.shortSellAct').setValue(false);
            this.formService.setFormProperty('shortSellAct', false);
        }

        this.form.get('actType').valueChanges.subscribe(value => {
            if (value !== this.constants.ACCOUNT_TYPE_MARGIN) {
                this.resetMarginAccountAndMgntType();
            } else { // margin
                this.form.get('cashAccountMgntType').setValue(null);
                this.formService.setFormProperty('cashAccountMgntType', null);
                this.formService.setFormProperty('portfolioManagementMandateOnly', null);
            }
            this.initManagementForRegisteredAccounts();
        });

        this.form.get('cashAccountMgntType').valueChanges.subscribe(value => {
            this.setPortfolioManagementPleinEx(value);
        });

        if (!this.isUpdateAccount() && this.isPleinEx() && !this.formService.isPrintMode()) {
            if (this.formService.getForm().portfolioManagementAdvisorProgram === true) {
                this.formService.setFormProperty('investmentAdviceComissionBased', true);
                this.formService.setFormProperty('portfolioManagementAdvisorProgram', null);
            }
        }

        this.form.get('addRRSP').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('rrspAccount'), value);
            if (this.isPleinEx() && value && this.formService.getForm().rrspCurrency === null) {
                this.form.get('rrspAccount.rrspCurrency').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrspCurrency', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().rrspAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('rrspAccount.rrspAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('rrspAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('rrspAccount.rrspAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('rrspAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            if (this.isPleinEx() && !value) {
                this.form.get('addRRSPSec').setValue(false);
                this.form.get('addRRSPTer').setValue(false);
            }

            this.checkTransfer(value, 'rrspTransfer');
        });
        this.updateControlEnabled(this.form.get('rrspAccount'), this.form.get('addRRSP').value);

        this.form.get('addRRSPSec').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addRRSPSec', value);
            this.updateControlEnabled(this.form.get('rrspAccountSec'), value);
            if (this.isPleinEx() && value && this.formService.getForm().rrspCurrencySec === null) {
                this.form.get('rrspAccountSec.rrspCurrencySec').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrspCurrencySec', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().rrspAccountMgntTypeSec === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('rrspAccountSec.rrspAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('rrspAccountMgntTypeSec', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('rrspAccountSec.rrspAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('rrspAccountMgntTypeSec', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('rrspAccountSec'), this.form.get('addRRSPSec').value);

        this.form.get('addRRSPTer').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addRRSPTer', value);
            this.updateControlEnabled(this.form.get('rrspAccountTer'), value);
            if (this.isPleinEx() && value && this.formService.getForm().rrspCurrencyTer === null) {
                this.form.get('rrspAccountTer.rrspCurrencyTer').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrspCurrencyTer', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().rrspAccountMgntTypeTer === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('rrspAccountTer.rrspAccountMgntTypeTer').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('rrspAccountMgntTypeTer', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('rrspAccountTer.rrspAccountMgntTypeTer').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('rrspAccountMgntTypeTer', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('rrspAccountTer'), this.form.get('addRRSPTer').value);

        this.form.get('addTFSA').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('tfsaAccount'), value);
            if (this.isPleinEx() && value && this.formService.getForm().tfsaCurrency === null) {
                this.form.get('tfsaAccount.tfsaCurrency').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('tfsaCurrency', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().tfsaAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('tfsaAccount.tfsaAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('tfsaAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('tfsaAccount.tfsaAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('tfsaAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            this.checkTransfer(value, 'tfsaTransfer');
        });
        this.updateControlEnabled(this.form.get('tfsaAccount'), this.form.get('addTFSA').value);

        if (this.useSwitchCeliApp()) {
            this.form.get('addFHSA').valueChanges.subscribe(value => {
                this.updateControlEnabled(this.form.get('fhsaAccount'), value);
                if (this.isPleinEx() && value && this.formService.getForm().fhsaCurrency === null) {
                    this.form.get('fhsaAccount.fhsaCurrency').setValue(this.constants.CURRENCY_CAD);
                    this.formService.setFormProperty('fhsaCurrency', this.constants.CURRENCY_CAD);
                }
                if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().fhsaAccountMgntType === null) {
                    if (!this.formService.isPrintMode()) {
                        this.form.get('fhsaAccount.fhsaAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                        this.formService.setFormProperty('fhsaAccountMgntType', this.constants.MGNT_TYPE_COM);
                    } else if (this.isGpdOrSfd()) {
                        this.form.get('fhsaAccount.fhsaAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                        this.formService.setFormProperty('fhsaAccountMgntType', this.constants.MGNT_TYPE_MAND);
                    }
                }
                this.formService.setFormProperty('fhsaEligibility', this.form.get('fhsaAccount.fhsaEligibility').value);
                this.checkTransfer(value, 'fhsaTransfer');
            });
            this.updateControlEnabled(this.form.get('fhsaAccount'), this.form.get('addFHSA').value);
        } else {
            this.resetFhsaValuesWhenSwitchOff();
        }

        this.form.get('addRESP').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('respAccount'), value);
            this.updateControlEnabled(this.form.get('respAccount.respTypeFamily'), this.isEnabledRespTypeFamily());

            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().respAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('respAccount.respAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('respAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('respAccount.respAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('respAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            this.checkTransfer(value, 'respTransfer');
        });
        this.updateControlEnabled(this.form.get('respAccount'), this.form.get('addRESP').value);

        this.form.get('respAccount.respType').valueChanges.subscribe(() => {
            this.updateControlEnabled(this.form.get('respAccount.respTypeFamily'), this.isEnabledRespTypeFamily());
            this.formService.getForm().beneficiaryRespList = [];
            this.formService.getForm().requestCESGForResp = null;
        });
        this.updateControlEnabled(this.form.get('respAccount.respTypeFamily'), this.isEnabledRespTypeFamily());

        this.form.get('respAccount.respTypeFamilyPleinEx').valueChanges.subscribe(() => {
            this.setRespTypePleinEx();
        });
        this.form.get('respAccount.respTypeIndividualPleinEx').valueChanges.subscribe(() => {
            this.setRespTypePleinEx();
        });

        this.form.get('addLIRA').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('liraAccount'), value);
            if (this.isPleinEx() && value && this.formService.getForm().liraCurrency === null) {
                this.form.get('liraAccount.liraCurrency').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('liraCurrency', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().liraAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('liraAccount.liraAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('liraAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('liraAccount.liraAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('liraAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            if (this.isPleinEx() && !value) {
                this.form.get('addLIRASec').setValue(false);
            }

            this.checkTransfer(value, 'liraTransfer');
        });
        this.updateControlEnabled(this.form.get('liraAccount'), this.form.get('addLIRA').value);

        this.form.get('addLIRASec').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addLIRASec', value);
            this.updateControlEnabled(this.form.get('liraAccountSec'), value);
            if (this.isPleinEx() && value && this.formService.getForm().liraCurrencySec === null) {
                this.form.get('liraAccountSec.liraCurrencySec').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('liraCurrencySec', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().liraAccountMgntTypeSec === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('liraAccountSec.liraAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('liraAccountMgntTypeSec', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('liraAccountSec.liraAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('liraAccountMgntTypeSec', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('liraAccountSec'), this.form.get('addLIRASec').value);

        this.form.get('addRRIF').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('rrifAccount'), value);
            if (this.isPleinEx() && value && this.formService.getForm().rrifCurrency === null) {
                this.form.get('rrifAccount.rrifCurrency').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrifCurrency', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().rrifAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('rrifAccount.rrifAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('rrifAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('rrifAccount.rrifAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('rrifAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            if (this.isPleinEx() && !value) {
                this.form.get('addRRIFSec').setValue(false);
            }

            this.checkTransfer(value, 'rrifTransfer');
        });
        this.updateControlEnabled(this.form.get('rrifAccount'), this.form.get('addRRIF').value);

        this.form.get('addRRIFSec').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addRRIFSec', value);
            this.updateControlEnabled(this.form.get('rrifAccountSec'), value);
            if (this.isPleinEx() && value && this.formService.getForm().rrifCurrencySec === null) {
                this.form.get('rrifAccountSec.rrifCurrencySec').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrifCurrencySec', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().rrifAccountMgntTypeSec === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('rrifAccountSec.rrifAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('rrifAccountMgntTypeSec', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('rrifAccountSec.rrifAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('rrifAccountMgntTypeSec', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('rrifAccountSec'), this.form.get('addRRIFSec').value);

        this.form.get('addLIF').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('lifAccount'), value);
            if (this.isPleinEx() && value && this.formService.getForm().lifCurrency === null) {
                this.form.get('lifAccount.lifCurrency').setValue(this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('lifCurrency', this.constants.CURRENCY_CAD);
            }
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().lifAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('lifAccount.lifAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('lifAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('lifAccount.lifAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('lifAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            this.checkTransfer(value, 'lifTransfer');
        });
        this.updateControlEnabled(this.form.get('lifAccount'), this.form.get('addLIF').value);

        this.form.get('addFTARRSP').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('ftarrspAccount'), value);

            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().ftarrspAccountMgntType === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('ftarrspAccount.ftarrspAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('ftarrspAccountMgntType', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('ftarrspAccount.ftarrspAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('ftarrspAccountMgntType', this.constants.MGNT_TYPE_MAND);
                }
            }

            this.checkTransfer(value, 'ftarrspTransfer');

            if (this.isPleinEx() && !value) {
                this.form.get('addFTARRSPSec').setValue(false);
                this.form.get('addFTARRSPTer').setValue(false);
            }
        });
        this.updateControlEnabled(this.form.get('ftarrspAccount'), this.form.get('addFTARRSP').value);

        this.form.get('addFTARRSPSec').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addFTARRSPSec', value);
            this.updateControlEnabled(this.form.get('ftarrspAccountSec'), value);
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().ftarrspAccountMgntTypeSec === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('ftarrspAccountSec.ftarrspAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('ftarrspAccountMgntTypeSec', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('ftarrspAccountSec.ftarrspAccountMgntTypeSec').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('ftarrspAccountMgntTypeSec', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('ftarrspAccountSec'), this.form.get('addFTARRSPSec').value);

        this.form.get('addFTARRSPTer').valueChanges.subscribe(value => {
            this.formService.setFormProperty('addFTARRSPTer', value);
            this.updateControlEnabled(this.form.get('ftarrspAccountTer'), value);
            if (this.isPleinEx() && this.showManagementTypeChoices() && value && this.formService.getForm().ftarrspAccountMgntTypeTer === null) {
                if (!this.formService.isPrintMode()) {
                    this.form.get('ftarrspAccountTer.ftarrspAccountMgntTypeTer').setValue(this.constants.MGNT_TYPE_COM);
                    this.formService.setFormProperty('ftarrspAccountMgntTypeTer', this.constants.MGNT_TYPE_COM);
                } else if (this.isGpdOrSfd()) {
                    this.form.get('ftarrspAccountTer.ftarrspAccountMgntTypeTer').setValue(this.constants.MGNT_TYPE_MAND);
                    this.formService.setFormProperty('ftarrspAccountMgntTypeTer', this.constants.MGNT_TYPE_MAND);
                }
            }
        });
        this.updateControlEnabled(this.form.get('ftarrspAccountTer'), this.form.get('addFTARRSPTer').value);

        if (!this.isPrintMode()) {
            this.setManagementTypeDefaultValue();
        }

        this.showREEEOptionForGpd = (!this.isGpd() && !this.isSfd()) || ((this.isGpd() || this.isSfd()) && this.config.TOGGLE_GPD_REEE);
        this.showRegisteredAccountTitleForGpd = this.isNotConjoint() || this.showREEEOptionForGpd;
    }

    showMarginAccountForOtherClientType(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CORPORATION ||
            this.formService.getForm().cltType === this.constants.CLT_TYPE_SOC_COMM ||
            this.formService.getForm().cltType === this.constants.CLT_TYPE_SENC ||
            this.formService.getForm().cltType === this.constants.CLT_TYPE_CO_UNIQUE;
    }

    checkForceCurrencyToUSD(path: string) {
        const control: AbstractControl = this.getControl(path);
        control.valueChanges.subscribe(value => {
            if (value === this.constants.CURRENCY_USD && this.getControl('currency').value === this.constants.CURRENCY_CAD) {
                this.modalService.openModal('CURRENCY_MODAL_TITLE', 'CURRENCY_MODAL_BODY_TO_USD');
                this.form.patchValue({
                    currency: this.constants.CURRENCY_USD
                });
                this.formService.setFormProperty('currency', this.constants.CURRENCY_USD);
            }
        });
    }

    isCad(): boolean {

        return this.getControl('currency').value === this.constants.CURRENCY_CAD;
    }

    isUsd(): boolean {

        return this.getControl('currency').value === this.constants.CURRENCY_USD;
    }

    initManagementForRegisteredAccounts() {

        // load initial values for account type management for PleinEx Auto mode
        if (this.isPleinEx() && !this.formService.isPrintMode() && this.showManagementTypeChoices()) {

            this.setDefaultManagementTypeValueForRegisteredAccounts(this.constants.MGNT_TYPE_COM);

        } else {
            this.form.get('tfsaAccount.tfsaAccountMgntType').setValue(null);
            this.formService.setFormProperty('tfsaAccountMgntType', null);

            if (this.useSwitchCeliApp()) {
                this.form.get('fhsaAccount.fhsaAccountMgntType').setValue(null);
                this.formService.setFormProperty('fhsaAccountMgntType', null);
            }

            this.form.get('rrspAccount.rrspAccountMgntType').setValue(null);
            this.formService.setFormProperty('rrspAccountMgntType', null);

            this.form.get('rrspAccountSec.rrspAccountMgntTypeSec').setValue(null);
            this.formService.setFormProperty('rrspAccountMgntTypeSec', null);

            this.form.get('rrspAccountTer.rrspAccountMgntTypeTer').setValue(null);
            this.formService.setFormProperty('rrspAccountMgntTypeTer', null);

            this.form.get('liraAccount.liraAccountMgntType').setValue(null);
            this.formService.setFormProperty('liraAccountMgntType', null);

            this.form.get('liraAccountSec.liraAccountMgntTypeSec').setValue(null);
            this.formService.setFormProperty('liraAccountMgntTypeSec', null);

            this.form.get('rrifAccount.rrifAccountMgntType').setValue(null);
            this.formService.setFormProperty('rrifAccountMgntType', null);

            this.form.get('rrifAccountSec.rrifAccountMgntTypeSec').setValue(null);
            this.formService.setFormProperty('rrifAccountMgntTypeSec', null);

            this.form.get('lifAccount.lifAccountMgntType').setValue(null);
            this.formService.setFormProperty('lifAccountMgntType', null);

            this.form.get('respAccount.respAccountMgntType').setValue(null);
            this.formService.setFormProperty('respAccountMgntType', null);

            this.form.get('ftarrspAccount.ftarrspAccountMgntType').setValue(null);
            this.formService.setFormProperty('ftarrspAccountMgntType', null);

            this.form.get('ftarrspAccountSec.ftarrspAccountMgntTypeSec').setValue(null);
            this.formService.setFormProperty('ftarrspAccountMgntTypeSec', null);

            this.form.get('ftarrspAccountTer.ftarrspAccountMgntTypeTer').setValue(null);
            this.formService.setFormProperty('ftarrspAccountMgntTypeTer', null);
        }
    }

    checkForceCurrencyToCAD() {
        this.getControl('currency').valueChanges.subscribe(value => {
            if (value === this.constants.CURRENCY_CAD && (
                this.getControl('rrspAccount.rrspCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('rrspAccountSec.rrspCurrencySec').value === this.constants.CURRENCY_USD
                || this.getControl('rrspAccountTer.rrspCurrencyTer').value === this.constants.CURRENCY_USD
                || this.getControl('tfsaAccount.tfsaCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('fhsaAccount.fhsaCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('liraAccount.liraCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('liraAccountSec.liraCurrencySec').value === this.constants.CURRENCY_USD
                || this.getControl('lifAccount.lifCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('rrifAccount.rrifCurrency').value === this.constants.CURRENCY_USD
                || this.getControl('rrifAccountSec.rrifCurrencySec').value === this.constants.CURRENCY_USD
            )) {
                if (
                    (this.getControl('addRRSP').value && this.getControl('rrspAccount.rrspCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addRRSPSec').value && this.getControl('rrspAccountSec.rrspCurrencySec').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addRRSPTer').value && this.getControl('rrspAccountTer.rrspCurrencyTer').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addTFSA').value && this.getControl('tfsaAccount.tfsaCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addFHSA').value && this.getControl('fhsaAccount.fhsaCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addLIRA').value && this.getControl('liraAccount.liraCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addLIRASec').value && this.getControl('liraAccountSec.liraCurrencySec').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addLIF').value && this.getControl('lifAccount.lifCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addRRIF').value && this.getControl('rrifAccount.rrifCurrency').value === this.constants.CURRENCY_USD)
                    || (this.getControl('addRRIFSec').value && this.getControl('rrifAccountSec.rrifCurrencySec').value === this.constants.CURRENCY_USD)
                ) {
                    // Display modal only if account is selected
                    this.modalService.openModal('CURRENCY_MODAL_TITLE', 'CURRENCY_MODAL_BODY_TO_CAD');
                }

                // change value even if account is not selected because no currency validation on submit
                this.form.get('rrspAccount.rrspCurrency').setValue(this.constants.CURRENCY_CAD);
                this.form.get('rrspAccountSec.rrspCurrencySec').setValue(this.constants.CURRENCY_CAD);
                this.form.get('rrspAccountTer.rrspCurrencyTer').setValue(this.constants.CURRENCY_CAD);
                this.form.get('tfsaAccount.tfsaCurrency').setValue(this.constants.CURRENCY_CAD);
                this.form.get('liraAccount.liraCurrency').setValue(this.constants.CURRENCY_CAD);
                this.form.get('liraAccountSec.liraCurrencySec').setValue(this.constants.CURRENCY_CAD);
                this.form.get('lifAccount.lifCurrency').setValue(this.constants.CURRENCY_CAD);
                this.form.get('rrifAccount.rrifCurrency').setValue(this.constants.CURRENCY_CAD);
                this.form.get('rrifAccountSec.rrifCurrencySec').setValue(this.constants.CURRENCY_CAD);

                this.formService.setFormProperty('rrspCurrency', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrspCurrencySec', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrspCurrencyTer', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('tfsaCurrency', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('liraCurrency', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('liraCurrencySec', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('lifCurrency', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrifCurrency', this.constants.CURRENCY_CAD);
                this.formService.setFormProperty('rrifCurrencySec', this.constants.CURRENCY_CAD);

                if (this.useSwitchCeliApp()) {
                    this.form.get('fhsaAccount.fhsaCurrency').setValue(this.constants.CURRENCY_CAD);
                    this.formService.setFormProperty('fhsaCurrency', this.constants.CURRENCY_CAD);
                }
            }
        });
    }

    disableValidation(name: string, group: string = '') {

        const path = group !== '' ? group + '.' : group;

        this.form.get(path + name).setValue(null);
        this.updateControlEnabled(this.form.get(path + name), false);
        this.formService.setFormProperty(name, null);
    }

    checkCurrencyForSfdFields() {

        if (!this.isSfd()) {

            this.disableValidation('accountNumberCad', 'fiduciaryTransactionAccountsCad');
            this.disableValidation('productNumberCad', 'fiduciaryTransactionAccountsCad');
            this.disableValidation('accountNumberUsd', 'fiduciaryTransactionAccountsUsd');
            this.disableValidation('productNumberUsd', 'fiduciaryTransactionAccountsUsd');
        } else {

            if (!this.isUsd()) {

                this.disableValidation('accountNumberUsd', 'fiduciaryTransactionAccountsUsd');
                this.disableValidation('productNumberUsd', 'fiduciaryTransactionAccountsUsd');
            }

            this.getControl('currency').valueChanges.subscribe(value => {

                if (value === this.constants.CURRENCY_USD) {

                    this.updateControlEnabled(this.form.get('fiduciaryTransactionAccountsUsd.accountNumberUsd'), true);
                    this.updateControlEnabled(this.form.get('fiduciaryTransactionAccountsUsd.productNumberUsd'), true);
                } else {

                    this.disableValidation('accountNumberUsd', 'fiduciaryTransactionAccountsUsd');
                    this.disableValidation('productNumberUsd', 'fiduciaryTransactionAccountsUsd');
                }
            });
        }
    }

    isEnabledRespTypeFamily(): boolean {
        return this.form.get('addRESP').value
            && (
                this.form.get('respAccount.respType').value === this.constants.RESP_TYPE_FAMILY
                || this.form.get('respAccount.respType').value === this.constants.RESP_TYPE_FAMILY_INDIVIDUAL
                || this.form.get('respAccount.respTypeFamilyPleinEx').value
            );
    }

    isNotConjoint(): boolean {
        return this.formService.getForm().cltType !== this.constants.CLT_TYPE_CONJOINT;
    }

    isNotOther(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT || this.formService.getForm().cltType === this.constants.CLT_TYPE_INDIVIDU;
    }

    isConjointPleinEx(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && this.isPleinEx();
    }

    isConjointGpd(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CONJOINT && this.isGpdOrSfd();
    }

    isConjointPeopleNumberValid(): boolean {
        if (this.isPleinEx() && !this.isNotConjoint()) {
            return this.formService.getForm().jointActPeople === 2;
        }
        return true;
    }

    isRespTypeFamily(): boolean {
        return this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY
            || this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY_INDIVIDUAL;
    }

    isRespTypeIndividual(): boolean {
        return this.formService.getForm().respType === this.constants.RESP_TYPE_INDIVIDUAL
            || this.formService.getForm().respType === this.constants.RESP_TYPE_FAMILY_INDIVIDUAL;
    }

    processRules(): boolean {
        return this.isPleinEx()
            && (
                this.accountRulesService.processFRVRule4AB_BC()
                || this.accountRulesService.processFRVRule4NS_ON()
                || this.accountRulesService.processCRIRule()
                || this.accountRulesService.processREERRule()
                || this.accountRulesService.processFHSARule()
                || this.accountRulesService.processFHSARuleCountry()
                || this.accountRulesService.processRRNVRule()
                || this.accountRulesService.processRESPSecondAccountholderSpouseRule()
            );
    }

    processDCLRules(): boolean {
        return !this.isPleinEx()
            && (this.accountRulesService.processFHSARule()
                || this.accountRulesService.processFHSARuleCountry()
            );
    }

    setPleinExDefaultValues(): void {
        if (!this.isPleinEx()) {
            return;
        }

        if (this.formService.getForm().currency === null) {
            this.formService.setFormProperty('currency', this.constants.CURRENCY_CAD);
        }

        if (this.formService.getForm().actType === null) {
            this.formService.setFormProperty('actType', this.constants.ACCOUNT_TYPE_CASH);
        }

        if (!this.isPrintMode()) {
            if (this.formService.getForm().actType === this.constants.ACCOUNT_TYPE_CASH) {
                if (this.formService.getForm().cashAccountMgntType === null) {
                    if (this.isGpdOrSfd()) {
                        this.formService.setFormProperty('cashAccountMgntType', this.constants.MGNT_TYPE_COM);
                        this.setPortfolioManagementPleinEx(this.constants.MGNT_TYPE_COM);
                    }
                } else {
                    this.setPortfolioManagementPleinEx(this.formService.getForm().cashAccountMgntType);
                }
            }
        }
    }

    setPortfolioManagementPleinEx(cashAccountMgntType: string): void {
        if (!this.isUpdateAccount() && this.isPleinEx() && !this.isPrintMode()) {
            if (cashAccountMgntType === this.constants.MGNT_TYPE_COM) {
                this.formService.setFormProperty(this.isGpdOrSfd() ? 'portfolioManagementAdvisorProgram' : 'investmentAdviceComissionBased', true);
                this.formService.setFormProperty('portfolioManagementMandateOnly', null);
            } else if (cashAccountMgntType === this.constants.MGNT_TYPE_MAND) {
                if (!this.isGpdOrSfd()) {

                    this.formService.setFormProperty('investmentAdviceComissionBased', null);
                    this.formService.setFormProperty('portfolioManagementMandateOnly', true);
                }
            }
        }
        this.formService.applyRules('mgntType');
    }

    setRespTypePleinEx(): void {
        const familyValue = this.form.get('respAccount.respTypeFamilyPleinEx').value;
        const individualValue = this.form.get('respAccount.respTypeIndividualPleinEx').value;

        let respTypeValue: string = null;

        if (familyValue && individualValue) {
            respTypeValue = this.constants.RESP_TYPE_FAMILY_INDIVIDUAL;
        } else if (familyValue) {
            respTypeValue = this.constants.RESP_TYPE_FAMILY;
        } else if (individualValue) {
            respTypeValue = this.constants.RESP_TYPE_INDIVIDUAL;
        }

        this.form.get('respAccount.respType').setValue(respTypeValue);
        this.formService.setFormProperty('respType', respTypeValue);
    }

    showManagementTypeChoices(): boolean {
        return this.isPleinEx()
            && !this.isPrintMode()
            && this.form.get('actType').value === this.constants.ACCOUNT_TYPE_CASH;
    }

    showAddRrspSecButton(): boolean {
        return this.isPleinEx() && this.form.get('addRRSP').value && !this.form.get('addRRSPSec').value;
    }

    showAddRrspSecSection(): boolean {
        return this.form.get('addRRSPSec').value;
    }

    showAddRrspTerButton(): boolean {
        return this.isPleinEx() && this.form.get('addRRSP').value && this.form.get('addRRSPSec').value && !this.form.get('addRRSPTer').value;
    }

    showAddFhsaButton(): boolean {
        return this.form.get('addFHSA').value;
    }

    showAddRrspTerSection(): boolean {
        return this.form.get('addRRSPTer').value;
    }

    showAddLiraSecButton(): boolean {
        return this.isPleinEx() && this.form.get('addLIRA').value && !this.form.get('addLIRASec').value;
    }

    showAddLiraSecSection(): boolean {
        return this.form.get('addLIRASec').value;
    }

    showAddRrifSecButton(): boolean {
        return this.isPleinEx() && this.form.get('addRRIF').value && !this.form.get('addRRIFSec').value;
    }

    showAddRrifSecSection(): boolean {
        return this.form.get('addRRIFSec').value;
    }

    showAddFtarrspSecButton(): boolean {
        return this.isPleinEx() && this.form.get('addFTARRSP').value && !this.form.get('addFTARRSPSec').value;
    }

    showAddFtarrspSecSection(): boolean {
        return this.form.get('addFTARRSPSec').value;
    }

    showAddFtarrspTerButton(): boolean {
        return this.isPleinEx() && this.form.get('addFTARRSP').value && this.form.get('addFTARRSPSec').value && !this.form.get('addFTARRSPTer').value;
    }

    showAddFtarrspTerSection(): boolean {
        return this.form.get('addFTARRSPTer').value;
    }

    showRespNumberOfBeneficiariesField(): boolean {
        return this.form.get('respAccount.respType').value === this.constants.RESP_TYPE_FAMILY
            || this.form.get('respAccount.respType').value === this.constants.RESP_TYPE_FAMILY_INDIVIDUAL;
    }

    openModal(): void {
        this.modalName = null;
        const modalItem = this.modalList.shift();
        if (modalItem) {
            if (modalItem.titleKey === 'processRESPSecondAccountholderSpouseRule') {
                this.modalName = MODAL_NAME_REEE;
                this.modalService.openModal(null, null, '#modal-alert-personal-information-second-accountholder-spouse');
            } else {
                this.modalService.openModal(modalItem.titleKey, modalItem.bodyKey);
            }
        }
    }

    submit() {
        this.modalList = [];
        if (this.processRules()) {
            if (this.accountRulesService.processREERRule()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_REER_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_REER'
                });
            }
            if (this.accountRulesService.processFHSARule()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FHSA_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FHSA'
                });
            }
            if (this.accountRulesService.processFHSARuleCountry()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY'
                });
            }
            if (this.accountRulesService.processCRIRule()) {
                this.modalList.push({titleKey: 'ACCOUNT_CHOICE_MODAL_CRI_TITLE', bodyKey: 'ACCOUNT_CHOICE_MODAL_CRI'});
            }
            if (this.accountRulesService.processFRVRule4AB_BC()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FRV4AB_BC_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FRV4AB_BC'
                });
            }
            if (this.accountRulesService.processFRVRule4NS_ON()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FRV4NS_ON_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FRV4NS_ON'
                });
            }
            if (this.accountRulesService.processRRNVRule()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_RRNV_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_RRNV'
                });
            }
            if (this.accountRulesService.processRESPSecondAccountholderSpouseRule()) {
                this.modalList.push({titleKey: 'processRESPSecondAccountholderSpouseRule', bodyKey: null});
            }
            this.openModal();
        } else if (this.processDCLRules()) {
            if (this.accountRulesService.processFHSARule()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FHSA_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FHSA'
                });
            }
            if (this.accountRulesService.processFHSARuleCountry()) {
                this.modalList.push({
                    titleKey: 'ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY_TITLE',
                    bodyKey: 'ACCOUNT_CHOICE_MODAL_FHSA_COUNTRY'
                });
            }
            this.openModal();
        } else {
            this.submitForm();
        }
    }

    public hideBackBtn() {
        return (this.isUpdateAccount()
            && this.formService.isRecoveredRequest());
    }

    public resetMarginAccountAndMgntType(): void {
        this.form.get('marginAccount.optionAct').setValue(false);
        this.formService.setFormProperty('optionAct', false);
        this.form.get('marginAccount.shortSellAct').setValue(false);
        this.formService.setFormProperty('shortSellAct', false);
        this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
        this.formService.setFormProperty('cashAccountMgntType', this.constants.MGNT_TYPE_COM);
        this.setPortfolioManagementPleinEx(this.constants.MGNT_TYPE_COM);
    }

    public setManagementTypeDefaultValue(): void {
        const form = this.formService.getForm();
        if (this.isSsd() || (this.isPleinEx()) && !this.isGpdOrSfd()) {
            if (form.portfolioManagementMandateOnly) {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_MAND);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_MAND;
            } else if (form.portfolioManagementAdvisorProgram) {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_ASSET);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_ASSET;
            } else {
                this.form.get('cashAccountMgntType').setValue(this.constants.MGNT_TYPE_COM);
                form.cashAccountMgntType = this.constants.MGNT_TYPE_COM;
            }
        }
    }

    private setDefaultManagementTypeValueForRegisteredAccounts(value: string): void {
        if (this.formService.getForm().tfsaAccountMgntType === null) {
            this.form.get('tfsaAccount.tfsaAccountMgntType').setValue(value);
            this.formService.setFormProperty('tfsaAccountMgntType', value);
        }
        if (this.useSwitchCeliApp() && this.formService.getForm().fhsaAccountMgntType === null) {
            this.form.get('fhsaAccount.fhsaAccountMgntType').setValue(value);
            this.formService.setFormProperty('fhsaAccountMgntType', value);
        }
        if (this.formService.getForm().rrspAccountMgntType === null) {
            this.form.get('rrspAccount.rrspAccountMgntType').setValue(value);
            this.formService.setFormProperty('rrspAccountMgntType', value);
        }
        if (this.formService.getForm().rrspAccountMgntTypeSec === null) {
            this.form.get('rrspAccountSec.rrspAccountMgntTypeSec').setValue(value);
            this.formService.setFormProperty('rrspAccountMgntTypeSec', value);
        }
        if (this.formService.getForm().rrspAccountMgntTypeTer === null) {
            this.form.get('rrspAccountTer.rrspAccountMgntTypeTer').setValue(value);
            this.formService.setFormProperty('rrspAccountMgntTypeTer', value);
        }
        if (this.formService.getForm().liraAccountMgntType === null) {
            this.form.get('liraAccount.liraAccountMgntType').setValue(value);
            this.formService.setFormProperty('liraAccountMgntType', value);
        }
        if (this.formService.getForm().liraAccountMgntTypeSec === null) {
            this.form.get('liraAccountSec.liraAccountMgntTypeSec').setValue(value);
            this.formService.setFormProperty('liraAccountMgntTypeSec', value);
        }
        if (this.formService.getForm().rrifAccountMgntType === null) {
            this.form.get('rrifAccount.rrifAccountMgntType').setValue(value);
            this.formService.setFormProperty('rrifAccountMgntType', value);
        }
        if (this.formService.getForm().rrifAccountMgntTypeSec === null) {
            this.form.get('rrifAccountSec.rrifAccountMgntTypeSec').setValue(value);
            this.formService.setFormProperty('rrifAccountMgntTypeSec', value);
        }
        if (this.formService.getForm().lifAccountMgntType === null) {
            this.form.get('lifAccount.lifAccountMgntType').setValue(value);
            this.formService.setFormProperty('lifAccountMgntType', value);
        }
        if (this.formService.getForm().respAccountMgntType === null) {
            this.form.get('respAccount.respAccountMgntType').setValue(value);
            this.formService.setFormProperty('respAccountMgntType', value);
        }
        if (this.formService.getForm().ftarrspAccountMgntType === null) {
            this.form.get('ftarrspAccount.ftarrspAccountMgntType').setValue(value);
            this.formService.setFormProperty('ftarrspAccountMgntType', value);
        }
        if (this.formService.getForm().ftarrspAccountMgntTypeSec === null) {
            this.form.get('ftarrspAccountSec.ftarrspAccountMgntTypeSec').setValue(value);
            this.formService.setFormProperty('ftarrspAccountMgntTypeSec', value);
        }
        if (this.formService.getForm().ftarrspAccountMgntTypeTer === null) {
            this.form.get('ftarrspAccountTer.ftarrspAccountMgntTypeTer').setValue(value);
            this.formService.setFormProperty('ftarrspAccountMgntTypeTer', value);
        }
    }

    private checkTransfer(isChecked: boolean, path: string): void {

        if (!isChecked && this.formService.getForm()[path]) {
            this.formService.setFormProperty(path, false);
        }
    }

    private isStatusInjection(): boolean {
        return this.formService.isStatusInjection();
    }

    private resetFhsaValuesWhenSwitchOff() {
        this.form.get('fhsaAccount.fhsaAccountMgntType').setValue(null);
        this.formService.setFormProperty('fhsaAccountMgntType', null);
        this.form.get('fhsaAccount.fhsaCurrency').setValue(null);
        this.formService.setFormProperty('fhsaCurrency', null);
        this.form.get('fhsaAccount.fhsaEligibility').setValue(null);
        this.formService.setFormProperty('fhsaEligibility', null);
        this.form.get('addFHSA').setValue(null);
        this.formService.setFormProperty('addFHSA', null);
        this.updateControlEnabled(this.form.get('fhsaAccount'), this.form.get('addFHSA').value);
    }
}
