import {Injectable} from '@angular/core';

@Injectable()
export class PostLoginActionService {

    private pendingAction: Function | null = null;

    registerAction(action: Function) {
        this.pendingAction = action;
    }

    executePendingAction() {
        if (this.pendingAction) {
            this.pendingAction();
            this.pendingAction = null;
        }
    }
}
