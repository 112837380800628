export const I18N_BTN_MESSAGES_EN: any = {
    BTN_ABANDON: 'Abandon',
    BTN_BACK: 'Previous page',
    BTN_BACK_PLATFORM_DISNAT: 'Return to choice of platform',
    BTN_BEGIN: 'Submit an application',
    BTN_CANCEL: 'Cancel',
    BTN_CHOOSE_DISNAT_CLASSIC: 'Choose Disnat Classic',
    BTN_CHOOSE_DISNAT_DIRECT: 'Choose Disnat Direct',
    BTN_CLOSE: 'Close',
    BTN_CONTINUE: 'Continue',
    BTN_MODIFY: 'Modify',
    BTN_CHANGE: 'Change',
    BTN_EXTEND: 'Extend',
    BTN_MODIFY_ACCOUNT_TYPE: 'Modify - Account Type',
    BTN_MODIFY_FINANCIAL_INFORMATION: 'Modify - Financial Information',
    BTN_MODIFY_MARITAL_STATUS_AND_DESIGNATION: 'Modify - Marital Status and Designation',
    BTN_MODIFY_OCCUPATION: 'Modify - Occupation',
    BTN_MODIFY_PERSONAL_INFORMATION: 'Modify - Personal Information',
    BTN_MODIFY_REGULATORY_QUESTIONS: 'Modify - Regulatory Questions',
    BTN_MODIFY_REQUEST: 'Modify the request',
    BTN_MODIFY_TRANSFER_REQUEST: 'Modify - Transfer request',
    BTN_NEXT: 'Continue',
    BTN_PASSWORD_VALIDATION: 'Continue',
    BTN_REMOVE_ACCOUNT: 'Remove the account',
    BTN_REMOVE_FILE: 'Remove the file',
    BTN_SIGN: 'Sign the documents',
    BTN_SUBMIT: 'Submit',
    BTN_SEARCH: 'Search',
    BTN_HELP_WARNING: 'Warning',
    BTN_OK: 'OK'
};
