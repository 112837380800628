import {Directive, ElementRef, HostListener, Input} from '@angular/core';

import {VmdKeyboard} from '../utils/vmd-keyboard';
import {NgControl} from "@angular/forms";

@Directive({
    selector: '[appNameFormat]',
})
export class NameFormatDirective {
    @Input() appFormBinding: string;
    pattern = new RegExp(/^[\p{L}\p{M}\p{Zs}.'’-]+$/u)

    constructor(private el: ElementRef, private control: NgControl) {

    }

    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
        if (this.pattern.test(event.key)) {
            return;
        }

        event.preventDefault();
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent) {
        if (this.pattern.test((event.clipboardData.getData('text/plain')))) {
            return;
        }

        event.preventDefault();
    }

    @HostListener('blur', ['$event'])
    onBlur(event: MouseEvent) {
        const originalValue = this.el.nativeElement.value;
        const updatedValue = this.pattern.test(originalValue) ? originalValue : '';
        this.el.nativeElement.value = updatedValue;
        this.control.control.setValue(updatedValue);
    }
}
