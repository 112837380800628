import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {VmdValidators} from '../../../components/validators/vmd-validators';
import {BaseAccountTypeRelatedQuestionsComponent} from './base-account-type-related-questions.component';

@Component({
    selector: 'app-succession-related-questions',
    templateUrl: './succession-related-questions.component.html'
})
export class SuccessionRelatedQuestionsComponent extends BaseAccountTypeRelatedQuestionsComponent implements OnInit {

    ngOnInit() {
        this.form = this.fb.group({
            succDeceasedProvince: [this.formService.getForm().otherDeceasedProvince, [Validators.required]],
            succAccountValueGreaterLimit: [this.formService.getForm().otherAccountValueGreaterLimit, [Validators.required]],
            succWill: [this.formService.getForm().otherWill, [Validators.required]],
            succActPeople: [this.formService.getForm().otherActPeople, [Validators.required, VmdValidators.digit, Validators.min(0), Validators.max(10)]],
            succAccountHasAmericanResident: [this.formService.getForm().otherAccountHasAmericanResident, [Validators.required]],
        });

        this.form.get('succDeceasedProvince').valueChanges.subscribe(value => {
            this.updateControlEnabled(this.form.get('succAccountValueGreaterLimit'), value === this.constants.ONTARIO);
        });

        this.updateControlsAvailability();
    }

    private updateControlsAvailability() {

        this.updateControlEnabled(this.form.get('succAccountValueGreaterLimit'), this.form.get('succDeceasedProvince').value === this.constants.ONTARIO);
    }
}
