import {ResidentialAddressSecondHolderComponent} from './modules/personal-information/residential-address-second-holder.component';

export const OTHER_ACCOUNT_PLEIN_EX_ROUTES_CONFIG: any = [
    {
        showTab: false,
        label: 'ODC_NAV_STEP_PREPARATION',
        items: [
            {
                label: 'ODC_NAV_STEP_PREPARATION_BEGIN',
                path: 'ODC_NAV_STEP_PREPARATION_BEGIN_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'BeginComponent'
            }, {
                label: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE',
                path: 'ODC_NAV_STEP_PREPARATION_CLIENT_TYPE_PERSONAL_ACCOUNT_PATH',
                aside: 'ASIDE_PREPARATION',
                component: 'ClientTypeComponent'
            }
        ],
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_TYPE_OF_MANAGEMENT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                aside: 'ASIDE_PLATFORM',
                component: 'TypeOfManagementComponent',
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ASSOCIATION_ACCOUNT_PLEIN_EX_PATH',
                component: 'AssociationRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CLUB_ACCOUNT_PLEIN_EX_PATH',
                component: 'ClubRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_COOPERATIVE_ACCOUNT_PLEIN_EX_PATH',
                component: 'CooperativeRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CORPORATION_ACCOUNT_PLEIN_EX_PATH',
                component: 'CorporationRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_CO_UNIQUE_ACCOUNT_PLEIN_EX_PATH',
                component: 'CoUniqueRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_ES_QUALITES_ACCOUNT_PLEIN_EX_PATH',
                component: 'EnfantEsQualitesRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ENF_REF_ACCOUNT_PLEIN_EX_PATH',
                component: 'EnfantRefRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_FIDUCIE_ACCOUNT_PLEIN_EX_PATH',
                component: 'FiducieRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_IPP_ACCOUNT_PLEIN_EX_PATH',
                component: 'IppRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_OSBL_ACCOUNT_PLEIN_EX_PATH',
                component: 'OsblRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SENC_ACCOUNT_PLEIN_EX_PATH',
                component: 'SencRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SOC_COMM_ACCOUNT_PLEIN_EX_PATH',
                component: 'SocCommRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_ORG_GOUV_ACCOUNT_PLEIN_EX_PATH',
                component: 'GouvOrgRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_RELATED_QUESTIONS_SUCC_ACCOUNT_PLEIN_EX_PATH',
                component: 'SuccessionRelatedQuestionsComponent',
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNTS_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'AccountsChoiceComponent',
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS',
                path: 'ODC_NAV_STEP_ACCOUNTS_CHOICE_ACCOUNT_TYPE_ENTITIES_QUESTIONS_PLEIN_EX_PATH',
                component: 'EntitiesQuestionsComponent'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION',
        items: [
            {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'AccountHolderInformationComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_ATTORNEY_PLEIN_EX_PATH',
                component: 'AccountHolderInformationAttorneyComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_DECEASED_PLEIN_EX_PATH',
                component: 'AccountHolderInformationDeceasedComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_REF_PLEIN_EX_PATH',
                component: 'AccountHolderInformationChildRefComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CHILD_ES_QUALITES_PLEIN_EX_PATH',
                component: 'AccountHolderInformationChildEsQualitesComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_1',
                path: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_1',
                component: 'ResidentialAddressComponent',
                saveAvailable: true
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_2',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_2_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH',
                component: 'AccountHolderInformationSecondChildComponent',
                saveAvailable: true
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_PERSONAL_INFORMATION_RESIDENTIAL_ADDRESS_2',
                path: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_RESIDENTIAL_ADDRESS_OTHER_ACCOUNT_PLEIN_EX_PATH_2',
                component: 'ResidentialAddressSecondHolderComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_GUARDIANSHIP_PLEIN_EX_PATH',
                component: 'AccountHolderInformationGuardianshipComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_CURATORSHIP_PLEIN_EX_PATH',
                component: 'AccountHolderInformationCuratorshipComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_ACCOUNT_HOLDER_INFORMATION_CLIENT_1',
                path: 'ODC_NAV_STEP_PERSONAL_INFORMATION_CLIENT_OTHER_ACCOUNT_IPP_PLEIN_EX_PATH',
                component: 'AccountHolderInformationIppComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_OCCUPATION',
        items: [
            {
                label: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION',
                path: 'ODC_NAV_STEP_OCCUPATION_OCCUPATION_OTHER_ACCOUNT_PATH',
                component: 'OccupationComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_CIVIL_STATUS_OTHER_ACCOUNT_PLEINEX',
        items: [
            {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_SPOUSE_INFORMATION_OTHER_ACCOUNT_CHILD_PLEIN_EX_PATH',
                component: 'SpouseInformationComponent',
                saveAvailable: true
            }, {
                label: 'ODC_NAV_STEP_REFERRED_ACCOUNT_PLEIN_EX_PATH',
                path: 'ODC_NAV_STEP_REFERRED_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReferredAccountComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_LEGAL',
        items: [
            {
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'BrokerageAccountsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                disable: true,
                label: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2',
                path: 'ODC_NAV_STEP_LEGAL_BROKERAGE_ACCOUNTS_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'BrokerageAccountsComponentSecondHolderComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY',
                path: 'ODC_NAV_STEP_CIVIL_STATUS_ATTORNEY_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'AttorneyComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_PLEIN_EX',
        items: [
            {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_FINANCIAL_INFORMATION_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'FinancialInformationComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_INVESTMENT_OBJECTIVES_AND_RISK_FACTORS_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'InvestmentObjectivesAndRiskFactorsComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            }, {
                label: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT',
                path: 'ODC_NAV_STEP_FINANCIAL_INFORMATION_OPTION_ACCOUNT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'OptionAccountComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER'
            },
            {
                label: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER',
                path: 'ODC_NAV_STEP_LEGAL_SHAREHOLDER_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'ShareholderComponent',
                saveAvailable: true
            },{
                label: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT',
                path: 'ODC_NAV_STEP_CONSENT_SPECIFIC_CONSENT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'SpecificConsentsComponent',
                saveAvailable: true,
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_TRANSFER_REQUEST',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE',
                path: 'ODC_NAV_STEP_TRANSFER_REQUEST_TRANSFER_REQUEST_CHOICE_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'TransferRequestChoiceComponent',
                saveAvailable: true
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReviewClientIdentityComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_1',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_1_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReviewClientIdentityComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            },
            {
                label: 'ODC_NAV_STEP_REVIEW_REVIEW_IDENTITY_CLIENT_2',
                path: 'ODC_NAV_STEP_REVIEW_REVIEW_CLIENT_IDENTITY_CLIENT_2_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'ReviewClientIdentitySecondHolderComponent',
                saveAvailable: true,
                helpWarningMessage: 'INFORMATION_FETCHED_FROM_CUSTOMER_AND_SIGNATURE'
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_PRINT',
        items: [
            {
                label: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD',
                path: 'ODC_NAV_STEP_TRANSMISSION_TRANSMISSION_KIT_DOWNLOAD_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'TransmissionKitDownloadComponent',
                saveAvailable: true
            }
        ]
    }, {
        showTab: true,
        label: 'ODC_NAV_STEP_SUBMIT',
        items: [
            {
                label: 'ODC_NAV_STEP_SUBMIT',
                path: 'ODC_NAV_STEP_SUBMIT_OTHER_ACCOUNT_PLEIN_EX_PATH',
                component: 'SubmissionComponent',
                saveAvailable: false,
                disableMode: true
            }
        ]
    }
];
