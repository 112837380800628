<div class="section">
    <div *ngIf="loading" class="text-center">
        <img alt="" src="assets/img/loading.gif">
    </div>
    <div *ngIf="alreadySubmitError !== null" class="alert alert-danger">{{ alreadySubmitError | translate }}</div>
    <ng-container *ngIf="formDelta">
        <app-review-update [formDelta]="formDelta" [isPleinEx]="isPleinEx()"></app-review-update>
        <ng-container *ngIf="formDelta.length === 0">
            <p>{{ 'NO_CHANGES_DETECTED' | translate }}</p>
        </ng-container>
    </ng-container>

    <div *ngIf="!loading" class="btn-resp btn-resp-1colonne-inline m-t-4">
        <div class="btn-resp-col btn-resp-demi">
            <button type="button" class="btn btn-primary" (click)="navNext()">
                {{'BTN_NEXT' | translate}}
            </button>
        </div>
        <div class="btn-resp-col btn-resp-demi">
            <button *ngIf="showBack()" type="button" class="btn btn-default"
                    (click)="navBack()">{{'BTN_BACK' | translate}}</button>
        </div>
    </div>
</div>
