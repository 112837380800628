<div [class.has-error]="isFieldInError()" [formGroup]="form" class="form-group">

    <ng-container *ngIf="type === fieldTypes.TEXT || type === fieldTypes.SELECT || type === fieldTypes.PASSWORD">

        <label [attr.for]="id" [style.visibility]="getLabelVisibility()">
            <b [class.sr-only]="labelSrOnly" [innerHTML]="label"></b>
            <app-help [srOnly]="labelSrOnly" [templateRefEn]="templateRefEn" [templateRefFr]="templateRefFr"></app-help>
            <app-error [form]="form" [nameSuffix]="nameSuffix" [onSubmitOnly]="errorOnSubmitOnly" [path]="path"
                       [submitted]="submitted"></app-error>
            <app-error *ngIf="pathPasswordStrengthIndicator" [form]="form" [path]="pathPasswordStrengthIndicator"
                       [submitted]="submitted"
                       class="has-error"></app-error>
        </label>

        <ng-container *ngIf="type === fieldTypes.TEXT || type === fieldTypes.PASSWORD">

            <input
                #input
                *ngIf="noFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                class="form-control"
                formControlName="{{controlName}}"
                type="{{type === fieldTypes.PASSWORD ? 'password' : 'text'}}"
            >
            <app-password-strength
                (strengthChanges)="onPasswordStrengthChanges($event)"
                *ngIf="pathPasswordStrengthIndicator"
                [password]="this.form.get(name).value"
            ></app-password-strength>
            <div *ngIf="countryCompleterCountries" class="twitter-typeahead twitter-typeahead-odc">
                <input
                    #input
                    [appFormBinding]="binding"
                    [attr.disabled]="attrDisabled ? true : null"
                    [attr.id]="id"
                    [attr.name]="name"
                    [ngClass]="className"
                    [readonly]="isReadOnly()"
                    [style.background-color]="attrDisabled ? '#eee' : null"
                    appCountryCompleter="countryCompleterCountries"
                    class="form-control typeahead"
                    data-input-target="userIdentityCountry"
                    formControlName="{{controlName}}"
                    type="text"
                >
            </div>

            <input
                #input
                *ngIf="currencyFormat"
                [appFormBinding]="binding"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appCurrencyFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="interestRateFormat"
                [appFormBinding]="binding"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appInterestRateFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="dateFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [autocomplete]="autoComplete"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appDateFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <div *ngIf="datePicker" class="input-group date">
                <input
                    #input
                    [appFormBinding]="binding"
                    [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                    [attr.disabled]="attrDisabled ? true : null"
                    [attr.id]="id"
                    [attr.name]="name"
                    [autocomplete]="autoComplete"
                    [ngClass]="className"
                    [readonly]="isReadOnly()"
                    appDateFormat
                    class="form-control"
                    formControlName="{{controlName}}"
                    type="text"
                >
                <span class="input-group-addon input-group-addon-calendar">
                        <span class="fa fa-calendar"></span>
                </span>
            </div>

            <input
                #input
                *ngIf="decimalCurrencyFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appDecimalCurrencyFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="integerFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appIntegerFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="nameFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appNameFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="numericFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appNumericFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="percentFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [attr.placeholder]="value"
                [defaultValue]="defaultValue"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appPercentFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="phoneFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appPhoneFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="securityQuantityFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appSecurityQuantityFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >

            <input
                #input
                *ngIf="sinFormat"
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                [readonly]="isReadOnly()"
                appSinFormat
                class="form-control"
                formControlName="{{controlName}}"
                type="text"
            >
        </ng-container>

        <ng-container *ngIf="type === fieldTypes.SELECT">
            <select
                [appFormBinding]="binding"
                [attr.aria-describedby]="helpText ? (id + '-help-text') : null"
                [attr.disabled]="isReadOnly() || attrDisabled ? true : null"
                [attr.id]="id"
                [attr.name]="name"
                [ngClass]="className"
                class="custom-select form-control"
                formControlName="{{controlName}}"
            >
                <option [ngValue]="null">{{ defaultOptionLabel }}</option>
                <option *ngFor="let item of list" [attr.name]="item.itemLabel"
                        [disabled]="isOptionDisabled(item) ? true : null" [ngValue]="item.itemValue">{{ item.itemLabel }}
                </option>
            </select>
        </ng-container>

        <p *ngIf="helpText" [attr.id]="id + '-help-text'" class="petit">{{ helpText }}</p>
    </ng-container>

    <ng-container *ngIf="type === fieldTypes.RADIO">
        <fieldset [disabled]="isReadOnly() ? true : null" [ngClass]="className" class="form-group">
            <legend>
                <b [class.sr-only]="labelSrOnly" [innerHTML]="label"></b>
                <app-help [srOnly]="labelSrOnly" [templateRefEn]="templateRefEn"
                          [templateRefFr]="templateRefFr"></app-help>
            </legend>

            <app-error [form]="form" [onSubmitOnly]="errorOnSubmitOnly" [path]="path"
                       [submitted]="submitted"></app-error>

            <ng-container *ngIf="stacked">
                <div class="c-inputs-stacked">
                    <ng-container *ngFor="let choice of choices; let i = index">
                        <div *ngIf="!choice.hidden" class="form-group">
                            <label [class.disabled]="attrDisabled" class="c-input c-radio">
                                <input [appFormBinding]="binding"
                                       [attr.aria-describedby]="getAriaDescribedby()"
                                       [attr.disabled]="attrDisabled"
                                       [attr.id]="choice.id + i"
                                       [attr.name]="name"
                                       [attr.value]="choice.value"
                                       [class.disabled]="isReadOnly() ? true : null"
                                       [ngClass]="className"
                                       [value]="choice.value"
                                       formControlName="{{controlName}}"
                                       type="radio"
                                >
                                <span class="c-indicator"></span> <span [class.sr-only]="choice.labelSrOnly || false"
                                                                        [innerHTML]="choice.label"></span>
                                <app-help [templateRefEn]="choice.helpRefEn"
                                          [templateRefFr]="choice.helpRefFr"></app-help>
                            </label>
                        </div>

                        <ng-container *ngIf="choice.templateRef">
                            <ng-container *ngTemplateOutlet="choice.templateRef"></ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!stacked">
                <label *ngFor="let choice of choices; let i = index" [class.disabled]="attrDisabled"
                       class="c-input c-radio">
                    <ng-container *ngIf="!choice.hidden">
                        <input [appFormBinding]="binding"
                               [attr.aria-describedby]="getAriaDescribedby()"
                               [attr.disabled]="attrDisabled"
                               [attr.id]="id + i"
                               [attr.name]="name"
                               [ngClass]="className"
                               [value]="choice.value"
                               formControlName="{{controlName}}"
                               type="radio"
                        >
                    </ng-container>
                    <span class="c-indicator"></span> <span [class.sr-only]="choice.labelSrOnly || false"
                                                            [innerHTML]="choice.label"></span>
                </label>
            </ng-container>
        </fieldset>
    </ng-container>

    <ng-container *ngIf="type === fieldTypes.CHECKBOX">

        <fieldset [class.has-error]="isFieldInError()" [ngClass]="className" class="form-group">
            <legend>
                <b [class.sr-only]="labelSrOnly" [innerHTML]="label"></b>
                <app-help [srOnly]="labelSrOnly" [templateRefEn]="templateRefEn"
                          [templateRefFr]="templateRefFr"></app-help>
            </legend>

            <app-error [form]="form" [onSubmitOnly]="errorOnSubmitOnly" [path]="path"
                       [submitted]="submitted"></app-error>

            <ng-container *ngIf="stacked">
                <div class="c-inputs-stacked">
                    <ng-container *ngFor="let choice of choices">
                        <div *ngIf="!choice.hidden" class="form-group">
                            <label [class.disabled]="form.get(choice.id).disabled" [class.disabled]="attrDisabled"
                                   class="c-input c-checkbox">
                                <input [appFormBinding]="choice.binding != null ? choice.binding : choice.id"
                                       [attr.aria-describedby]="getAriaDescribedby()"
                                       [attr.disabled]="isDisabledChoice(choice.id) ? true : null"
                                       [attr.disabled]="attrDisabled"
                                       [attr.id]="choice.id"
                                       [attr.name]="choice.id"
                                       [ngClass]="className"
                                       [readonly]="isReadOnly()"
                                       formControlName="{{choice.id}}"
                                       type="checkbox"
                                >
                                <span [class.disabled]="form.get(choice.id).disabled" class="c-indicator"></span> <span
                                [class.sr-only]="choice.labelSrOnly || false" [innerHTML]="choice.label"></span>
                            </label>
                            <app-help [templateRefEn]="choice.helpRefEn" [templateRefFr]="choice.helpRefFr"></app-help>
                        </div>

                        <ng-container *ngIf="choice.templateRef">
                            <ng-container *ngTemplateOutlet="choice.templateRef"></ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="!stacked">
                <label *ngFor="let choice of choices" [class.disabled]="form.get(choice.id).disabled"
                       class="c-input c-checkbox">
                    <ng-container *ngIf="!choice.hidden">
                        <input [appFormBinding]="choice.binding != null ? choice.binding : choice.id"
                               [attr.aria-describedby]="getAriaDescribedby()"
                               [attr.disabled]="isDisabledChoice(choice.id) ? true : null"
                               [attr.id]="choice.id"
                               [attr.name]="choice.id"
                               [ngClass]="className"
                               formControlName="{{choice.id}}"
                               type="checkbox"
                        >
                    </ng-container>
                    <span [class.disabled]="form.get(choice.id).disabled" class="c-indicator"></span> <span
                    [class.sr-only]="choice.labelSrOnly || false" [innerHTML]="choice.label"></span>
                </label>
            </ng-container>
        </fieldset>
    </ng-container>

</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
