import {NavigationService} from '../../../components/services/navigation.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountRulesService} from '../../../components/services/account-rules.service';
import {ModalService} from '../../../components/services/modal.service';
import {PersonalInformationComponent} from '../personal-information.component';
import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {IOdcConfig, ODC_CONFIG} from '../../../odc-config';

declare let $: any;

@Component({
    selector: 'app-update-personal-information',
    templateUrl: './update-personal-information.component.html'
})
export class UpdatePersonalInformationComponent extends PersonalInformationComponent implements OnInit, AfterViewInit {

    addressForm: FormGroup = new FormGroup({});

    userAddressUnstructured: Array<string> = [];

    nextButton: HTMLElement;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService,
                protected accountRulesService: AccountRulesService,
                protected modalService: ModalService,
                @Inject(ODC_CONFIG) protected config: IOdcConfig) {
        super(navigationService, fb, formService, translate, accountRulesService, modalService, config);
    }

    ngOnInit() {
        super.ngOnInit();

        this.userAddressUnstructured = this.formService.getForm().requesters[0].userAddressUnstructured;

        this.addressForm = this.fb.group({
            userAddressType: [sessionStorage.getItem('ossAddressType')],
            userAddressStreetOSS: [sessionStorage.getItem('userAddressStreet')],
            userAddressUnitOSS: [sessionStorage.getItem('userAddressUnit')],
            userAddressCityOSS: [sessionStorage.getItem('userAddressCity')],
            userAddressProvOSS: [sessionStorage.getItem('userAddressProv')],
            userAddressPostalCodeOSS: [sessionStorage.getItem('userAddressPostalCode')],
            userAddressCountryOSS: [sessionStorage.getItem('userAddressCountry')]
        });

        this.addressForm.get('userAddressType').valueChanges.subscribe(value => {

            this.setSessionAddressType(value);

            this.formService.setFormProperty(this.getRequesterPath('userAddressTypeSelected'), this.addressForm.get('userAddressType').value);

            if (value === this.constants.OSS_HOME_ADDRESS) {
                this.showHomeAddress = true;
                this.toggleMailingAddress(false);
                this.assignHomeAddressInformation();
            }

            if (value === this.constants.OSS_MAILING_ADDRESS) {
                this.showHomeAddress = true;
                this.toggleMailingAddress(true);
                this.assignMailingAddressInformation();
            }

            this.setNextButtonDisableState();
        });

        this.form.get('addressGroup.homeAddress').valueChanges.subscribe(() => {
            if (this.hasUserAddressUnstructured()) {
                if (this.validateHomeAddressInformation()) {
                    this.setSessionAddressType(this.constants.OSS_HOME_ADDRESS);
                } else {
                    this.setSessionAddressType();
                }
                this.setNextButtonDisableAttribute(!this.validateHomeAddressInformation());
            }
        });

        this.form.get('addressGroup.mailingAddress').valueChanges.subscribe(() => {
            if (this.hasUserAddressUnstructured()) {
                if (this.validateMailingAddressInformation()) {
                    this.setSessionAddressType(this.constants.OSS_MAILING_ADDRESS);
                } else {
                    this.setSessionAddressType();
                }
                this.setNextButtonDisableAttribute(!this.validateMailingAddressInformation());
            }
        });

        if (this.addressForm.get('userAddressType').value === null) {
            this.showHomeAddress = false;
        }

        if (this.hasUserAddressUnstructured()) {
            this.modalService.openModal(null, 'UnstructuredAddress', '#modal-alert-user-address-unstructured', null, null);
        }
    }

    ngAfterViewInit() {
        this.nextButton = document.getElementById('nextButton');
        this.setNextButtonDisableState();
    }

    setSessionAddressType(addressType?: string) {
        if (addressType === this.constants.OSS_HOME_ADDRESS) {
            sessionStorage.setItem(this.constants.OSS_ADDRESS_TYPE_SESSION_KEY, this.constants.OSS_HOME_ADDRESS);
        } else if (addressType === this.constants.OSS_MAILING_ADDRESS) {
            sessionStorage.setItem(this.constants.OSS_ADDRESS_TYPE_SESSION_KEY, this.constants.OSS_MAILING_ADDRESS);
        } else {
            sessionStorage.removeItem(this.constants.OSS_ADDRESS_TYPE_SESSION_KEY);
        }
    }

    hasUserAddressUnstructured(): boolean {
        return this.isUpdateAccount() && (this.userAddressUnstructured && this.userAddressUnstructured.length !== 0);
    }

    setNextButtonDisableAttribute(disable: boolean) {
        if (this.nextButton) {
            if (disable) {
                this.nextButton.setAttribute('disabled', 'true');
            } else {
                this.nextButton.removeAttribute('disabled');
            }
        }
    }

    validateHomeAddressInformation(): boolean {
        return this.form.get('addressGroup.homeAddress').valid;
    }

    validateMailingAddressInformation(): boolean {
        return this.form.get('addressGroup.mailingAddress').valid;
    }

    assignHomeAddressInformation() {
        this.resetMailingAddressValue();

        this.form.get('addressGroup.homeAddress.userAddressStreet').setValue(this.addressForm.get('userAddressStreetOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressStreet'), this.addressForm.get('userAddressStreetOSS').value);
        this.form.get('addressGroup.homeAddress.userAddressUnit').setValue(this.addressForm.get('userAddressUnitOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressUnit'), this.addressForm.get('userAddressUnitOSS').value);
        this.form.get('addressGroup.homeAddress.userAddressCity').setValue(this.addressForm.get('userAddressCityOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCity'), this.addressForm.get('userAddressCityOSS').value);
        this.form.get('addressGroup.homeAddress.userAddressProv').setValue(this.addressForm.get('userAddressProvOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressProv'), this.addressForm.get('userAddressProvOSS').value);
        this.form.get('addressGroup.homeAddress.userAddressPostalCode').setValue(this.addressForm.get('userAddressPostalCodeOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCode'), this.addressForm.get('userAddressPostalCodeOSS').value);
        this.form.get('addressGroup.homeAddress.userAddressCountry').setValue(this.addressForm.get('userAddressCountryOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCountry'), this.addressForm.get('userAddressCountryOSS').value);

        setTimeout((val: any) => {
            $('#userAddressProv').val(val).change();
        }, 0, this.addressForm.get('userAddressProvOSS').value);
    }

    assignMailingAddressInformation() {
        this.clearHomeAddressForm();

        this.form.get('addressGroup.mailingAddress.userAddressStreetSec').setValue(this.addressForm.get('userAddressStreetOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressStreetSec'), this.addressForm.get('userAddressStreetOSS').value);
        this.form.get('addressGroup.mailingAddress.userAddressUnitSec').setValue(this.addressForm.get('userAddressUnitOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressUnitSec'), this.addressForm.get('userAddressUnitOSS').value);
        this.form.get('addressGroup.mailingAddress.userAddressCitySec').setValue(this.addressForm.get('userAddressCityOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCitySec'), this.addressForm.get('userAddressCityOSS').value);
        this.form.get('addressGroup.mailingAddress.userAddressProvSec').setValue(this.addressForm.get('userAddressProvOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressProvSec'), this.addressForm.get('userAddressProvOSS').value);
        this.form.get('addressGroup.mailingAddress.userAddressPostalCodeSec').setValue(this.addressForm.get('userAddressPostalCodeOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressPostalCodeSec'), this.addressForm.get('userAddressPostalCodeOSS').value);
        this.form.get('addressGroup.mailingAddress.userAddressCountrySec').setValue(this.addressForm.get('userAddressCountryOSS').value);
        this.formService.setFormProperty(this.getRequesterPath('userAddressCountrySec'), this.addressForm.get('userAddressCountryOSS').value);

        setTimeout((val: any) => {
            $('#userAddressProvSec').val(val).change();
        }, 0, this.addressForm.get('userAddressProvOSS').value);
    }

    isMandateOnlyAndUSAAddress(): boolean {
        return (this.isGpdOrSfd()
            || this.isSsdPortfolioManagementAdvisorProgram()
            || this.isVmdPortfolioManagementMandateOnly())
            && sessionStorage.getItem('userAddressCountry') === this.constants.COUNTRIES.UNITED_STATES;
    }

    setNextButtonDisableState() {
        const addressFieldsVisible: boolean = this.showHomeAddress || this.showMailingAddress;

        if (this.hasUserAddressUnstructured() && addressFieldsVisible) {
            this.setNextButtonDisableAttribute(!(this.validateHomeAddressInformation() || this.validateMailingAddressInformation()));
        } else {
            this.setNextButtonDisableAttribute(!addressFieldsVisible);
        }
    }

}
