import {Component, OnInit} from '@angular/core';
import {TransferRequestGenericTypeComponent} from './transfer-request-generic-type.component';

@Component({
    selector: 'app-transfer-request-non-registered',
    templateUrl: './transfer-request-generic-type.component.html'
})
export class TransferRequestNonRegisteredComponent extends TransferRequestGenericTypeComponent implements OnInit {

    accountTransferType: string = this.constants.ACCOUNT_TRANSFER_CASH;
    bindPath = 'accountTransferList';

    ngOnInit() {
        super.ngOnInit();
    }

}
