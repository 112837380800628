import {Component, OnInit} from '@angular/core';
import {BaseNavComponent} from '../layout/questionnaire/base-nav.component';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../components/services/navigation.service';
import {FormService} from '../../components/services/form.service';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {VmdValidators} from '../../components/validators/vmd-validators';

@Component({
    selector: 'app-beneficiary-rrsp',
    templateUrl: './beneficiary-rrsp.component.html'
})
export class BeneficiaryRrspComponent extends BaseNavComponent implements OnInit {

    isBeneficiaryPriIndicator = false;
    isBeneficiarySecIndicator = false;
    isBeneficiaryTerIndicator = false;

    constructor(public navigationService: NavigationService,
                protected fb: FormBuilder,
                public formService: FormService,
                public translate: TranslateService) {
        super(navigationService, fb, formService, translate);
    }

    ngOnInit() {
        this.form = this.fb.group({
            beneficiaryReerActiveIndicator: [this.formService.getForm().beneficiaryReerActiveIndicator, [this.requiredValidator('beneficiaryReerActiveIndicator')]],
            beneficiary: this.fb.group({
                beneficiaryReerGender: [this.formService.getForm().beneficiaryReerGender, [this.requiredValidator('beneficiaryReerGender')]],
                beneficiaryReerFirstName: [this.formService.getForm().beneficiaryReerFirstName, [this.requiredValidator('beneficiaryReerFirstName')]],
                beneficiaryReerLastName: [this.formService.getForm().beneficiaryReerLastName, [this.requiredValidator('beneficiaryReerLastName')]],
                beneficiaryReerDOB: [this.formService.getForm().beneficiaryReerDOB, [this.requiredValidator('beneficiaryReerDOB'), VmdValidators.dateOfBirthMajor, VmdValidators.date]],
                beneficiaryReerSIN: [this.formService.getForm().beneficiaryReerSIN, [this.requiredValidator('beneficiaryReerSIN'), VmdValidators.sin]],
                beneficiaryReerAdressStreet: [this.formService.getForm().beneficiaryReerAdressStreet, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryReerAdressStreet'))]],
                beneficiaryReerAdressUnit: [this.formService.getForm().beneficiaryReerAdressUnit, [VmdValidators.alphanumericWithSpace]],
                beneficiaryReerAdressCity: [this.formService.getForm().beneficiaryReerAdressCity, [this.requiredValidator('beneficiaryReerAdressCity')]],
                beneficiaryReerAdressProv: [this.formService.getForm().beneficiaryReerAdressProv, [this.requiredValidator('beneficiaryReerAdressProv')]],
                beneficiaryReerAdressPostalCode: [this.formService.getForm().beneficiaryReerAdressPostalCode, [this.requiredValidator('beneficiaryReerAdressPostalCode')]],
                beneficiaryReerAdressCountry: [this.formService.getForm().beneficiaryReerAdressCountry, [this.requiredValidator('beneficiaryReerAdressCountry')]],
                beneficiaryReerParentLink: [this.formService.getForm().beneficiaryReerParentLink, [this.requiredValidator('beneficiaryReerParentLink')]],
                beneficiaryReerPart: [this.formService.getForm().beneficiaryReerPart, [this.requiredValidator('beneficiaryReerPart')]]
            }, {validator: [VmdValidators.beneficiaryReerAdressZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryReerAddressZipCodeRequired)]}),
            beneficiarySec: this.fb.group({
                beneficiaryReerGenderSec: [this.formService.getForm().beneficiaryReerGenderSec, [this.requiredValidator('beneficiaryReerGenderSec')]],
                beneficiaryReerFirstNameSec: [this.formService.getForm().beneficiaryReerFirstNameSec, [this.requiredValidator('beneficiaryReerFirstNameSec')]],
                beneficiaryReerLastNameSec: [this.formService.getForm().beneficiaryReerLastNameSec, [this.requiredValidator('beneficiaryReerLastNameSec')]],
                beneficiaryReerDOBSec: [this.formService.getForm().beneficiaryReerDOBSec, [this.requiredValidator('beneficiaryReerDOBSec'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryReerSINSec: [this.formService.getForm().beneficiaryReerSINSec, [this.requiredValidator('beneficiaryReerSINSec'), VmdValidators.sin]],
                beneficiaryReerAdressStreetSec: [this.formService.getForm().beneficiaryReerAdressStreetSec, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryReerAdressStreetSec'))]],
                beneficiaryReerAdressUnitSec: [this.formService.getForm().beneficiaryReerAdressUnitSec, [VmdValidators.alphanumericWithSpace]],
                beneficiaryReerAdressCitySec: [this.formService.getForm().beneficiaryReerAdressCitySec, [this.requiredValidator('beneficiaryReerAdressCitySec')]],
                beneficiaryReerAdressProvSec: [this.formService.getForm().beneficiaryReerAdressProvSec, [this.requiredValidator('beneficiaryReerAdressProvSec')]],
                beneficiaryReerAdressPostalCodeSec: [this.formService.getForm().beneficiaryReerAdressPostalCodeSec, [this.requiredValidator('beneficiaryReerAdressPostalCodeSec')]],
                beneficiaryReerAdressCountrySec: [this.formService.getForm().beneficiaryReerAdressCountrySec, [this.requiredValidator('beneficiaryReerAdressCountrySec')]],
                beneficiaryReerParentLinkSec: [this.formService.getForm().beneficiaryReerParentLinkSec, [this.requiredValidator('beneficiaryReerParentLinkSec')]],
                beneficiaryReerPartSec: [this.formService.getForm().beneficiaryReerPartSec, [this.requiredValidator('beneficiaryReerPartSec')]]
            }, {validator: [VmdValidators.beneficiaryReerAdressSecZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryReerAdressSecZipCodeRequired)]}),
            beneficiaryTer: this.fb.group({
                beneficiaryReerGenderTer: [this.formService.getForm().beneficiaryReerGenderTer, [this.requiredValidator('beneficiaryReerGenderTer')]],
                beneficiaryReerFirstNameTer: [this.formService.getForm().beneficiaryReerFirstNameTer, [this.requiredValidator('beneficiaryReerFirstNameTer')]],
                beneficiaryReerLastNameTer: [this.formService.getForm().beneficiaryReerLastNameTer, [this.requiredValidator('beneficiaryReerLastNameTer')]],
                beneficiaryReerDOBTer: [this.formService.getForm().beneficiaryReerDOBTer, [this.requiredValidator('beneficiaryReerDOBTer'), VmdValidators.dateOfBirthAll, VmdValidators.date]],
                beneficiaryReerSINTer: [this.formService.getForm().beneficiaryReerSINTer, [this.requiredValidator('beneficiaryReerSINTer'), VmdValidators.sin]],
                beneficiaryReerAdressStreetTer: [this.formService.getForm().beneficiaryReerAdressStreetTer, [VmdValidators.addressStreetValidator(this.formService.isFieldRequired('beneficiaryReerAdressStreetTer'))]],
                beneficiaryReerAdressUnitTer: [this.formService.getForm().beneficiaryReerAdressUnitTer, [VmdValidators.alphanumericWithSpace]],
                beneficiaryReerAdressCityTer: [this.formService.getForm().beneficiaryReerAdressCityTer, [this.requiredValidator('beneficiaryReerAdressCityTer')]],
                beneficiaryReerAdressProvTer: [this.formService.getForm().beneficiaryReerAdressProvTer, [this.requiredValidator('beneficiaryReerAdressProvTer')]],
                beneficiaryReerAdressPostalCodeTer: [this.formService.getForm().beneficiaryReerAdressPostalCodeTer, [this.requiredValidator('beneficiaryReerAdressPostalCodeTer')]],
                beneficiaryReerAdressCountryTer: [this.formService.getForm().beneficiaryReerAdressCountryTer, [this.requiredValidator('beneficiaryReerAdressCountryTer')]],
                beneficiaryReerParentLinkTer: [this.formService.getForm().beneficiaryReerParentLinkTer, [this.requiredValidator('beneficiaryReerParentLinkTer')]],
                beneficiaryReerPartTer: [this.formService.getForm().beneficiaryReerPartTer, [this.requiredValidator('beneficiaryReerPartTer')]]
            }, {validator: [VmdValidators.beneficiaryReerAdressTerZipCodeValidator, this.requiredGroupValidator(VmdValidators.beneficiaryReerAdressTerZipCodeRequired)]})
        }, {validator: this.validRRSPShares});


        this.isBeneficiaryPriIndicator = this.form.get('beneficiaryReerActiveIndicator').value;
        this.form.get('beneficiaryReerActiveIndicator').valueChanges.subscribe(value => {
            if (value) {
                this.isBeneficiaryPriIndicator = value;
                this.updateControlEnabled(this.form.get('beneficiary'), value);
            } else {
                this.removeTerBeneficiary();
                this.removeSecBeneficiary();
                this.removePriBeneficiary();
            }
        });

        this.isBeneficiarySecIndicator = !!this.formService.getForm().beneficiaryReerFirstNameSec;
        this.isBeneficiaryTerIndicator = !!this.formService.getForm().beneficiaryReerFirstNameTer;

        this.updateControlEnabled(this.form.get('beneficiary'), this.isBeneficiaryPriIndicator);
        this.updateControlEnabled(this.form.get('beneficiarySec'), this.isBeneficiarySecIndicator);
        this.updateControlEnabled(this.form.get('beneficiaryTer'), this.isBeneficiaryTerIndicator);
    }

    validRRSPShares(form: FormGroup) {
        let controls: AbstractControl[];
        controls = [form.get('beneficiary.beneficiaryReerPart'),
            form.get('beneficiarySec.beneficiaryReerPartSec'),
            form.get('beneficiaryTer.beneficiaryReerPartTer')];

        return VmdValidators.validMinMaxCumul(controls, 1, 100);
    }

    removePriBeneficiary() {
        if (this.isBeneficiarySecIndicator) {
            this.copyBeneficiarySecToBeneficiaryPri();
            if (this.isBeneficiaryTerIndicator) {
                this.copyBeneficiaryTerToBeneficiarySec();
                this.removeTerBeneficiary();
            } else {
                this.removeSecBeneficiary();
            }
        } else {
            this.isBeneficiaryPriIndicator = false;
            this.form.get('beneficiary.beneficiaryReerGender').setValue(null);
            this.formService.setFormProperty('beneficiaryReerGender', null);
            this.form.get('beneficiary.beneficiaryReerFirstName').setValue(null);
            this.formService.setFormProperty('beneficiaryReerFirstName', null);
            this.form.get('beneficiary.beneficiaryReerLastName').setValue(null);
            this.formService.setFormProperty('beneficiaryReerLastName', null);
            this.form.get('beneficiary.beneficiaryReerDOB').setValue(null);
            this.formService.setFormProperty('beneficiaryReerDOB', null);
            this.form.get('beneficiary.beneficiaryReerSIN').setValue(null);
            this.formService.setFormProperty('beneficiaryReerSIN', null);
            this.form.get('beneficiary.beneficiaryReerAdressStreet').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressStreet', null);
            this.form.get('beneficiary.beneficiaryReerAdressUnit').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressUnit', null);
            this.form.get('beneficiary.beneficiaryReerAdressCity').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressCity', null);
            this.form.get('beneficiary.beneficiaryReerAdressProv').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressProv', null);
            this.form.get('beneficiary.beneficiaryReerAdressPostalCode').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressPostalCode', null);
            this.form.get('beneficiary.beneficiaryReerParentLink').setValue(null);
            this.formService.setFormProperty('beneficiaryReerParentLink', null);
            this.form.get('beneficiary.beneficiaryReerPart').setValue(null);
            this.formService.setFormProperty('beneficiaryReerPart', null);
            this.updateControlEnabled(this.form.get('beneficiary'), false);
        }
    }

    addBeneficiarySec() {
        this.isBeneficiarySecIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiarySec'), true);
        return false;
    }

    removeSecBeneficiary() {
        if (this.isBeneficiaryTerIndicator) {
            this.copyBeneficiaryTerToBeneficiarySec();
            this.removeTerBeneficiary();
        } else {
            this.isBeneficiarySecIndicator = false;
            this.form.get('beneficiarySec.beneficiaryReerGenderSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerGenderSec', null);

            this.form.get('beneficiarySec.beneficiaryReerFirstNameSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerFirstNameSec', null);

            this.form.get('beneficiarySec.beneficiaryReerLastNameSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerLastNameSec', null);

            this.form.get('beneficiarySec.beneficiaryReerDOBSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerDOBSec', null);

            this.form.get('beneficiarySec.beneficiaryReerSINSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerSINSec', null);

            this.form.get('beneficiarySec.beneficiaryReerAdressStreetSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressStreetSec', null);

            this.form.get('beneficiarySec.beneficiaryReerAdressUnitSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressUnitSec', null);

            this.form.get('beneficiarySec.beneficiaryReerAdressCitySec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressCitySec', null);

            this.form.get('beneficiarySec.beneficiaryReerAdressProvSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressProvSec', null);

            this.form.get('beneficiarySec.beneficiaryReerAdressPostalCodeSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerAdressPostalCodeSec', null);

            this.form.get('beneficiarySec.beneficiaryReerParentLinkSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerParentLinkSec', null);

            this.form.get('beneficiarySec.beneficiaryReerPartSec').setValue(null);
            this.formService.setFormProperty('beneficiaryReerPartSec', null);

            this.updateControlEnabled(this.form.get('beneficiarySec'), false);
        }
    }

    addBeneficiaryTer() {
        this.isBeneficiaryTerIndicator = true;
        this.updateControlEnabled(this.form.get('beneficiaryTer'), true);
        return false;
    }

    removeTerBeneficiary() {
        this.isBeneficiaryTerIndicator = false;
        this.form.get('beneficiaryTer.beneficiaryReerGenderTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerGenderTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerFirstNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerFirstNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerLastNameTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerLastNameTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerDOBTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerDOBTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerSINTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerSINTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerAdressStreetTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerAdressStreetTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerAdressUnitTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerAdressUnitTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerAdressCityTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerAdressCityTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerAdressProvTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerAdressProvTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerAdressPostalCodeTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerAdressPostalCodeTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerParentLinkTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerParentLinkTer', null);

        this.form.get('beneficiaryTer.beneficiaryReerPartTer').setValue(null);
        this.formService.setFormProperty('beneficiaryReerPartTer', null);

        this.updateControlEnabled(this.form.get('beneficiaryTer'), false);
    }

    copyBeneficiarySecToBeneficiaryPri() {
        this.form.get('beneficiary.beneficiaryReerGender').setValue(this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);
        this.formService.setFormProperty('beneficiaryReerGender', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerFirstName').setValue(this.form.get('beneficiarySec.beneficiaryReerFirstNameSec').value);
        this.formService.setFormProperty('beneficiaryReerFirstName', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerLastName').setValue(this.form.get('beneficiarySec.beneficiaryReerLastNameSec').value);
        this.formService.setFormProperty('beneficiaryReerLastName', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerDOB').setValue(this.form.get('beneficiarySec.beneficiaryReerDOBSec').value);
        this.formService.setFormProperty('beneficiaryReerDOB', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerSIN').setValue(this.form.get('beneficiarySec.beneficiaryReerSINSec').value);
        this.formService.setFormProperty('beneficiaryReerSIN', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressStreet').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressStreetSec').value);
        this.formService.setFormProperty('beneficiaryReerAdressStreet', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressUnit').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressUnitSec').value);
        this.formService.setFormProperty('beneficiaryReerAdressUnit', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressCity').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressCitySec').value);
        this.formService.setFormProperty('beneficiaryReerAdressCity', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressProv').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressProvSec').value);
        this.formService.setFormProperty('beneficiaryReerAdressProv', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressPostalCode').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressPostalCodeSec').value);
        this.formService.setFormProperty('beneficiaryReerAdressPostalCode', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerAdressCountry').setValue(this.form.get('beneficiarySec.beneficiaryReerAdressCountrySec').value);
        this.formService.setFormProperty('beneficiaryReerAdressCountry', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerParentLink').setValue(this.form.get('beneficiarySec.beneficiaryReerParentLinkSec').value);
        this.formService.setFormProperty('beneficiaryReerParentLink', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiary.beneficiaryReerPart').setValue(this.form.get('beneficiarySec.beneficiaryReerPartSec').value);
        this.formService.setFormProperty('beneficiaryReerPart', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

    }

    copyBeneficiaryTerToBeneficiarySec() {
        this.form.get('beneficiarySec.beneficiaryReerGenderSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerGenderTer').value);
        this.formService.setFormProperty('beneficiaryReerGenderSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerFirstNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerFirstNameTer').value);
        this.formService.setFormProperty('beneficiaryReerFirstNameSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerLastNameSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerLastNameTer').value);
        this.formService.setFormProperty('beneficiaryReerLastNameSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerDOBSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerDOBTer').value);
        this.formService.setFormProperty('beneficiaryReerDOBSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerSINSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerSINTer').value);
        this.formService.setFormProperty('beneficiaryReerSINSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressStreetSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressStreetTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressStreetSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressUnitSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressUnitTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressUnitSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressCitySec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressCityTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressCitySec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressProvSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressProvTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressProvSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressPostalCodeSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressPostalCodeTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressPostalCodeSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerAdressCountrySec').setValue(this.form.get('beneficiaryTer.beneficiaryReerAdressCountryTer').value);
        this.formService.setFormProperty('beneficiaryReerAdressCountrySec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerParentLinkSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerParentLinkTer').value);
        this.formService.setFormProperty('beneficiaryReerParentLinkSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

        this.form.get('beneficiarySec.beneficiaryReerPartSec').setValue(this.form.get('beneficiaryTer.beneficiaryReerPartTer').value);
        this.formService.setFormProperty('beneficiaryReerPartSec', this.form.get('beneficiarySec.beneficiaryReerGenderSec').value);

    }


}
